import { useInfiniteQuery, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import {
  fetchSitesFeedbacks,
  fetchSiteEmployees,
  fetchPotentialSiteMembers,
  fetchSiteManagers,
  fetchSiteById,
  fetchPotentialSwapMembers,
  fetchSwapOvertime
} from '../api/queries/Queries';

export const useFeedbacks = (from, to, site, employee) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(['feedbacks', from, to, site, employee], fetchSitesFeedbacks, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export const useSiteEmployees = (site, role, search, subrole, onlyUnassigned) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(
    ['site-employees', site, role, search, subrole, onlyUnassigned],
    fetchSiteEmployees,
    {
      enabled: !!site,
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};

export const useSiteManagers = (search, role) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(['site-managers', search, role], fetchSiteManagers, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export const usePotentialSiteMembers = (site, search, role, subrole, onlyUnassigned) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(
    ['potential-site-members', site, search, role, subrole, onlyUnassigned],
    fetchPotentialSiteMembers,
    {
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};

export const usePotentialSwapMembers = (id, today, search, role, subrole) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(
    ['potential-swap-members', id, today, search, role, subrole],
    fetchPotentialSwapMembers,
    {
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      enabled: id?.length > 0,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};

export const useSiteById = (site, token) => {
  const dispatch = useDispatch();
  return useQuery(
    // eslint-disable-next-line block-scoped-var
    ['site-profile', site, token],
    // eslint-disable-next-line block-scoped-var
    () => fetchSiteById(site, token),
    {
      // eslint-disable-next-line block-scoped-var
      enabled: !!site,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};

export const useSwapOvertime = (date, selectedShift, swapShift, employee) => {
  const dispatch = useDispatch();
  return useQuery(
    // eslint-disable-next-line block-scoped-var
    ['swap-overtime', date, selectedShift, swapShift, employee],
    // eslint-disable-next-line block-scoped-var
    () => fetchSwapOvertime(date, selectedShift, swapShift, employee),
    {
      // eslint-disable-next-line block-scoped-var
      enabled: employee?.length > 0 && selectedShift?.length > 0,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};
