import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Toolbar,
  Button,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
  Radio,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  // ListSubheader,
  Popover,
  Divider,
  Chip,
  MenuItem,
  InputLabel
} from '@mui/material';
// icons
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import DeleteIcon from '@mui/icons-material/Delete';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
// import AddModeratorIcon from '@mui/icons-material/AddModerator';
// import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
// import StickyNote2Icon from '@mui/icons-material/StickyNote2';
// import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
// date picker
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// queries
import { fetchSiteManagers } from '../../../api/queries/Queries';
// utils
import { convertUTCtoLocal, DD_MM_YYYY } from '../../../utils/functions';
// import INDIAN from '../../../utils/INDIAN_STATES.json'; // importing indian states and uts
// config
// import { COMPANY_PLACEHOLDER_LOGO } from '../../../utils/config';
// hooks
import { useRoles } from '../../../hooks/roles';
// redux
import { logOut } from '../../../redux/actions/adminActions';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 70,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 2)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 40,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));
// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  setFilters: PropTypes.func,
  filters: PropTypes.object,
  approveLeaves: PropTypes.func,
  rejectLeaves: PropTypes.func,
  cancelLeaves: PropTypes.func,
  deleteSites: PropTypes.func,
  profile: PropTypes.any
};

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  approveLeaves,
  rejectLeaves,
  cancelLeaves,
  setFilters,
  profile,
  filters
}) {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [, setRoleOptions] = React.useState(); // Roles initial State for select
  const [, setManagerOptions] = React.useState();
  const [filter, setFilter] = React.useState({
    from: null,
    to: null,
    status: filters?.status ?? '',
    reason: filters?.reason ?? 'All'
  });

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = React.useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  React.useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      allRoles.forEach((role) => formattedRoles.push([`${role._id},${role.name}`, role.name]));
      setRoleOptions([...formattedRoles]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allRoles]);
  /* ================= End Roles =============== */
  const {
    isLoading: isSiteManagersLoading,
    isError: isSiteManagersError,
    data: siteManagers
  } = useInfiniteQuery(
    ['site-managers', '', filter.role],
    fetchSiteManagers,
    {
      enabled: !!filter.role,
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        // console.log("Error :", error)
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    } // gettings sitemanagers
  );
  const SiteManagers = useMemo(() => {
    const listOfSiteManagers = [];
    // console.log("Add Site Managers :", siteManagers);
    if (!isSiteManagersLoading && !isSiteManagersError) {
      if (siteManagers?.pages?.length > 0) {
        siteManagers.pages.forEach((group) => listOfSiteManagers.push(group.employees));
      }
    }
    const flattened = listOfSiteManagers.flat();
    return flattened;
  }, [siteManagers, isSiteManagersLoading, isSiteManagersError]);

  useEffect(() => {
    const formattedEmployees = [];

    if (SiteManagers?.length > 0) {
      SiteManagers.forEach((manager) =>
        formattedEmployees.push([`${manager._id},${manager.name}`, manager.name, manager.avatar])
      );
    }
    setManagerOptions([...formattedEmployees]);
  }, [SiteManagers]);

  /* ================= End Managers =============== */

  return (
    <>
      <RootStyle
      // sx={{
      //   ...(numSelected > 0 && {
      //     color: 'primary.main',
      //     bgcolor: 'primary.lighter'
      //   })
      // }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Stack direction="row" spacing={1}>
            <FormControl sx={{ minWidth: 100 }} size="small">
              <InputLabel id="search-by-label">Search By</InputLabel>
              <Select
                labelId="search-by-label"
                id="demo-simple-select"
                value={filters.searchBy}
                label="Search By"
                onChange={(e) => setFilters({ ...filters, searchBy: e.target.value })}
              >
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="reference">Ref</MenuItem>
              </Select>
            </FormControl>
            <SearchStyle
              value={filterName}
              onChange={onFilterName}
              placeholder="Search Leave..."
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />
          </Stack>
        )}

        {numSelected > 0 ? (
          <Stack direction="row">
            <Tooltip title="Approve">
              <IconButton onClick={() => approveLeaves()}>
                <ThumbUpIcon sx={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
            &emsp;
            <Tooltip title="Reject">
              <IconButton onClick={() => rejectLeaves()}>
                <ThumbDownIcon sx={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
            &emsp;
            {profile && (
              <Tooltip title="Cancel">
                <IconButton onClick={() => cancelLeaves()}>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        ) : (
          <Tooltip title="Filter list">
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </RootStyle>
      <Stack direction="row" alignItems="center" sx={{ my: 1, px: 2 }}>
        <FilterListIcon sx={{ fontSize: 20 }} />
        &nbsp;
        <Typography>Filters</Typography>
        &nbsp;
        <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
        <Chip
          color="primary"
          label={
            <Stack direction="row" alignItems="center">
              Range :&nbsp;
              {filters.from === null
                ? 'All'
                : `${DD_MM_YYYY(convertUTCtoLocal(filters.from, true))} to ${DD_MM_YYYY(
                    convertUTCtoLocal(filters.to, true)
                  )}`}
              &nbsp;&nbsp;
              {filters.from !== null && (
                <CancelIcon
                  onClick={() => {
                    setFilter({ ...filter, from: null, to: null });
                    setFilters({ ...filters, from: null, to: null });
                    cache.invalidateQueries();
                  }}
                  sx={{ fontSize: 14, cursor: 'pointer' }}
                />
              )}
            </Stack>
          }
          size="small"
        />
        &nbsp;
        <Chip
          sx={{ backgroundColor: '#BA4A00', color: 'white' }}
          label={
            <Stack direction="row" alignItems="center">
              Status :&nbsp;
              {filters.status.length > 0 ? filters.status : 'All'}
              &nbsp;&nbsp;
              {filters.status && (
                <CancelIcon
                  onClick={() => {
                    setFilter({ ...filter, status: '' });
                    setFilters({ ...filters, status: '' });
                    cache.invalidateQueries();
                  }}
                  sx={{ fontSize: 14, cursor: 'pointer' }}
                />
              )}
            </Stack>
          }
          size="small"
        />
        &nbsp;
        <Chip
          sx={{ backgroundColor: '#2980B9', color: 'white' }}
          label={`Reason : ${filter.reason}`}
          size="small"
        />
        {/* &nbsp;
        <Chip
          color="secondary"
          label={`Role : ${filter.role.split(',')[1] ?? 'All'}`}
          size="small"
        />
        &nbsp;
        <Chip
          sx={{ backgroundColor: '#7B7D7D', color: 'white' }}
          label={`Gender : ${filter.gender.length > 0 ? filter.gender : 'All'}`}
          size="small"
        /> */}
      </Stack>
      <Popover
        id="filter_leaves"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack
          className="custom_scrollbar"
          spacing={1}
          width="320px"
          sx={{ px: 2, py: 3, position: 'relative', height: 'calc(100vh - 9rem)' }}
        >
          <Stack direction="row" alignItems="center">
            <FilterListIcon />
            <Typography sx={{ ml: 1 }}>Filters</Typography>
          </Stack>
          <Divider />
          <Stack spacing={2}>
            <FormControl>
              <FormLabel id="date-range-label">Date Range</FormLabel>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="From"
                    minDate={new Date()}
                    value={filter.from}
                    onChange={(newValue) => {
                      setFilter({ ...filter, from: newValue });
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="To"
                    minDate={new Date()}
                    value={filter.to}
                    onChange={(newValue) => {
                      setFilter({ ...filter, to: newValue });
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </FormControl>
            <FormControl>
              <FormLabel id="reason-label">Reason</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={filter.reason}
                onChange={(e) => setFilter({ ...filter, reason: e.target.value })}
                name="radio-buttons-group"
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel value="Sick" control={<Radio />} label="Sick" />
                <FormControlLabel value="Casual" control={<Radio />} label="Casual" />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel id="reason-label">Status</FormLabel>
              <RadioGroup
                aria-labelledby="status-label"
                defaultValue={filter.status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                name="radio-buttons-group"
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel value="Pending" control={<Radio />} label="Pending" />
                <FormControlLabel value="Approved" control={<Radio />} label="Approved" />
                <FormControlLabel value="Rejected" control={<Radio />} label="Rejected" />
                <FormControlLabel value="Cancelled" control={<Radio />} label="Cancelled" />
              </RadioGroup>
            </FormControl>
          </Stack>

          {/* <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
        <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="status_filter">
            Choose Status
          </InputLabel>
          <Select
            variant="standard"
            value={filter.status}
            inputProps={{
              name: 'status',
              id: 'status_filter'
            }}
            onChange={(e) => setFilter({ ...filter, status: e.target.value })}
            sx={{ mt: 2 }}
          >
            <MenuItem value="" disabled>
              Choose Status
            </MenuItem>
            <MenuItem value="Verified">Verified</MenuItem>
            <MenuItem value="Unverified">Not Verified</MenuItem>
          </Select>
        </FormControl>
        &nbsp;
        {filter.status ? (
          <Tooltip title="Clear">
            <IconButton onClick={() => setFilter({ ...filter, status: '' })}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Box> */}
          {/* <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
        <LocationCityIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="grouped-select">
            Choose State
          </InputLabel>
          <Select
            variant="standard"
            value={filter.state}
            onChange={(e) => setFilter({ ...filter, state: e.target.value })}
            id="grouped-select"
            label="Choose State"
          >
            <MenuItem value="" disabled>
              Choose State and Union Territories
            </MenuItem>
            <ListSubheader>States</ListSubheader>
            {INDIAN?.states?.length > 0 ? (
              INDIAN?.states.map((key) => (
                <MenuItem key={`${'state'}-${key}`} value={key}>
                  {key}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No State Available</em>
              </MenuItem>
            )}
            <ListSubheader>Union Territories</ListSubheader>
            {INDIAN.union_territories?.length > 0 ? (
              INDIAN.union_territories.map((key) => (
                <MenuItem key={`${'territory'}-${key}`} value={key}>
                  {key}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No Territories Available</em>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Box> */}
          {/* <Box sx={{ display: 'flex', mt: 1, alignItems: 'flex-end' }}>
        <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <LocalizationProvider dateAdapter={AdapterDate}>
          <DatePicker
            label="Avalability"
            value={filter.date ?? null}
            onChange={(dateOfAvailability) => {
              setFilter({
                ...filter,
                date: convertUTCtoLocal(dateOfAvailability, true)
              });
            }}
            renderInput={(params) => <TextField variant="standard" fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Box> */}
          {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="role_filter">
                Choose Role
              </InputLabel>
              <Select
                variant="standard"
                value={filter?.role}
                inputProps={{
                  name: 'role_filter',
                  id: 'role_filter'
                }}
                onChange={(e) => setFilter({ ...filter, role: e.target.value })}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Role
                </MenuItem>
                {roleOptions?.length > 0 ? (
                  roleOptions.map(([key, value, image]) => (
                    <MenuItem key={`${'role_filter'}-${key}`} value={key} data-icon={image}>
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <>
                    <MenuItem value="" disabled />
                  </>
                )}
              </Select>
            </FormControl>
            &nbsp;
            {filter.role ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, role: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box> */}
          {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="manager_filter">
                Choose Manager
              </InputLabel>
              <Select
                variant="standard"
                value={filter.manager}
                inputProps={{
                  name: 'manager_filter',
                  id: 'manager_filter'
                }}
                onChange={(e) => setFilter({ ...filter, manager: e.target.value })}
                disabled={!filter?.role}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Manager
                </MenuItem>
                {managerOptions?.length > 0 ? (
                  managerOptions.map(([key, value, image]) => (
                    <MenuItem
                      style={{ padding: '0.5rem' }}
                      key={`${'manager_filter'}-${key}`}
                      value={key}
                      data-icon={image}
                    >
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled />
                )}
              </Select>
            </FormControl>
            &nbsp;
            {filter.manager ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, manager: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box> */}
          <br />
          <Stack direction="row" spacing={1}>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              size="medium"
              onClick={() =>
                setFilters({
                  ...filters,
                  status: filter.status,
                  reason: filter.reason,
                  from: filter?.from,
                  to: filter?.to
                })
              }
            >
              Apply
            </Button>
            <Button
              sx={{ float: 'right' }}
              variant="text"
              size="medium"
              onClick={() =>
                setFilters({
                  ...filters,
                  status: 'Pending',
                  reason: '',
                  from: null,
                  to: null
                })
              }
            >
              Clear All
            </Button>
          </Stack>
          <br />
        </Stack>
      </Popover>
    </>
  );
}
