import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import { composeWithDevTools } from "redux-devtools-extension";
import { adminInfoReducer } from './reducers/adminReducers';
import { employeeInfoReducer } from './reducers/employeeReducers';

const reducer = combineReducers({
  adminInfo: adminInfoReducer,
  employeeInfo: employeeInfoReducer
});
const initialState = {};

const middleWare = [thunk];

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleWare)
  // composeWithDevTools(applyMiddleware(...middleWare))
);

export default store;
