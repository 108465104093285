/* eslint-disable no-nested-ternary */
import '../../../index.css';
import { memo, useMemo, useEffect, useState, Fragment } from 'react';
import * as Yup from 'yup';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import { useFormik, FormikProvider, Form } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import {
  Modal,
  Typography,
  Box,
  TextField,
  Stack,
  Select,
  MenuItem,
  // ListSubheader,
  FormControl,
  // FormControlLabel,
  InputLabel,
  InputAdornment,
  // Checkbox,
  Button,
  IconButton,
  // OutlinedInput,
  // Chip,
  CircularProgress
} from '@mui/material';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
// import { useTheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
// icons
import NumbersIcon from '@mui/icons-material/Numbers';
import DeleteIcon from '@mui/icons-material/Delete';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import WcIcon from '@mui/icons-material/Wc';
import EmailIcon from '@mui/icons-material/Email';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ContactsIcon from '@mui/icons-material/Contacts';
import PasswordIcon from '@mui/icons-material/Password';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import AddIcon from '@mui/icons-material/Add';
import FocusError from '../../FocusError';
// config
import { ADD_EMPLOYEE, COMPANY_PLACEHOLDER_LOGO, SWAL_TIME } from '../../../utils/config';
// hooks
import { useRoles } from '../../../hooks/roles';
import useSites from '../../../hooks/get-sites-query';
import usePolicies from '../../../hooks/get-policies';
import { useAllPayrollComponents } from '../../../hooks/get-all-payroll-templates';
import { useManagers } from '../../../hooks/get-managers';
import { useSubRoles } from '../../../hooks/subRoles';
import PaySlipTemplate from '../../../pages/PaySlipTemplate';
import { useAllDepartments } from '../../../api/queries/department';
import { addEmployee, calculatePayslip } from '../../../api/mutations/adminMutations';
// utils
import {
  getCurrentDate,
  getBase64,
  capitalizeEachWord,
  convertUTCtoLocal,
  uid
} from '../../../utils/functions';
// redux
import { logOut } from '../../../redux/actions/adminActions';
// import INDIAN from '../../../utils/INDIAN_STATES.json'; // importing indian states and uts
import { countries } from '../../../utils/countries';
import { getEmployeeProfileById, getAdminProfileById } from '../../../api/queries/profile';
// import { validate } from 'numeral';

const DEBUG = false;

const label = { inputProps: { 'aria-label': 'Switch demo' } };

function EmployeeForm({ step, formMutation, edit }) {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const [alertMsg, setAlertMsg] = useState();
  const [empChildren, setEmpChildren] = useState([
    {
      id: uid(),
      name: '',
      relation: '',
      age: ''
    }
  ]);
  const [smPriority, setSMPriority] = useState();
  const [calculateErrors, setCalculateErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]); // Roles initial State for select
  const [subRoleOptions, setSubRoleOptions] = useState([]); // Sub Roles initial State for select
  const [policyOptions, setPolicyOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [payrollTemplateOptions, setPayrollTemplateOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [siteType, setSiteType] = useState([]); // Select Multiple Sites
  const [pictureUploading, setPictureUploading] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [payslipData, setPayslipData] = useState();
  // const [submitClicked, setSubmitClicked] = useState(false);
  // const [offdays, setOffDays] = useState([]);
  const [pictureTouched, setpictureTouched] = useState(false); // Check Focused Profile Picture
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [dateOfJoining, setDateOfJoining] = useState(null);
  const [validFromPassport, setValidFromPassport] = useState('');
  const [validUptoPassport, setValidUptoPassport] = useState('');

  // Get Experience
  const [experience, setExperience] = useState([
    {
      id: uid(),
      from: null,
      to: null,
      certificate: '',
      organization_name: '',
      designation: '',
      ctc: ''
    }
  ]);
  // Get Education
  const [education, setEducation] = useState([
    {
      id: uid(),
      type: '',
      aggregate: '',
      certificate: ''
    }
  ]);

  useEffect(() => {
    setDateOfBirth(convertUTCtoLocal(edit?.dob, true));
    setDateOfJoining(convertUTCtoLocal(edit?.doj, true));
  }, [edit]);

  // Get Profile Data
  const { isAdminAuthenticated, info } = useSelector((state) => state.adminInfo);
  const {
    isLoading: isProfileLoading,
    isSuccess: isProfileSuccess,
    isError: isProfileError,
    data: profileData
  } = useQuery(
    [`${!info?.isMainAdmin ? 'employee' : 'admin'}-profile`, info?._id],
    () => (!info?.isMainAdmin ? getEmployeeProfileById(info?._id) : getAdminProfileById(info?._id)),
    {
      enabled: isAdminAuthenticated ?? false,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );

  useEffect(() => {
    const options = [];
    if (isProfileSuccess) {
      profileData?.admin?.group?.forEach((gp) => {
        options.push([gp._id, gp.name]);
      });
      setCompanyOptions([...options]);
    }
  }, [profileData, isProfileSuccess]);

  const { isLoading: isDeptLoading, isError: isDeptError, data } = useAllDepartments();

  const allDepartments = useMemo(() => {
    const DEPT_LIST = [];
    if (!isDeptLoading && !isDeptError) {
      data.departments.forEach((dep) => {
        DEPT_LIST.push([dep._id, dep.name]);
      });
    }
    return DEPT_LIST;
  }, [data, isDeptError, isDeptLoading]);

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      // console.log(allRoles);
      let priority = 0;
      allRoles.forEach((role) => {
        if (role.is_site_manager) {
          priority = role.priority;
          setSMPriority(priority);
        }
        if (role.priority > priority && priority > 0 && (step === 5 || step === null)) {
          formattedRoles.push([`${role._id},${true}`, role.name]);
        } else if (
          (priority === 0 || role.priority === priority) &&
          (step === 3 || step === null)
        ) {
          formattedRoles.push([`${role._id},${false}`, role.name]);
        }
      });
      setRoleOptions([...formattedRoles]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allRoles, step]);
  /* ================= End Roles =============== */

  const { mutate } = useMutation(formMutation);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  useEffect(() => {
    // console.log(pictureTouched);
  }, [pictureTouched]);

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    initialValues: {
      company: edit?.admin?._id ?? '',
      father_name: edit?.father_name ?? '',
      spouse_name: edit?.spouse_name ?? '',
      department: edit?.department ?? '',
      tag: '',
      name: edit?.name ?? '',
      gender: edit?.gender ?? '',
      marital_status: edit?.marital_status ?? '',
      blood_group: edit?.blood_group ?? '',
      email: edit?.email ?? '',
      mobile: edit?.contact ?? '',
      password: '',
      dateOfBirth: convertUTCtoLocal(edit?.dob, true) ?? '',
      profilePicture: '',
      country: edit?.country_code ?? '91',
      pan: edit?.pan?.value ?? '',
      pan_proof: edit?.pan?.data ?? '',
      aadhar: edit?.aadhar?.value ?? '',
      aadhar_proof: edit?.aadhar?.data ?? '',
      doj: convertUTCtoLocal(edit?.doj, true) ?? '',
      vehicle_type: edit?.vehicle_type ?? '',
      role_type: `${edit?.role?._id},${edit?.role?.priority > smPriority}` ?? '',
      sub_role_type: edit?.subrole?._id ?? '',
      payout_type: edit?.payout_type ?? '',
      payout_amount: edit?.payout_amount ?? '',
      manager_type: '',
      site_type: '',
      enroll_pf: edit?.pf_settings?.enable ?? false,
      pf_type: edit?.pf_settings?.pf_type ?? '',
      eps_employer_contribution: edit?.eps_employer_contribution ?? '',
      pf_employee_contribution: edit?.pf_settings?.pf_employee_contribution ?? '',
      add_employee_policy_type: edit?.policy?._id ?? '',
      payroll_template: edit?.payroll ?? '',
      holidays: [],
      reliving_certificate: edit?.reliving_certificate ?? '',
      bank_name: edit?.bank_details?.bank_name ?? '',
      bank_branch: edit?.bank_details?.bank_branch ?? '',
      bank_account_no: edit?.bank_details?.bank_account_no ?? '',
      bank_ifsc_code: edit?.bank_details?.bank_ifsc_code ?? '',
      uan: edit?.uan ?? '',
      epf_no: edit?.epf_no ?? '',
      esi_no: edit?.esi_no ?? '',
      passport_no: '',
      passport_valid_from: '',
      passport_valid_upto: '',
      permanent_house_no: edit?.permanent_address?.permanent_house_no ?? '',
      permanent_village_town_street: edit?.permanent_address?.permanent_village_town_street ?? '',
      permanent_pincode: edit?.permanent_address?.permanent_pincode ?? '',
      permanent_country: edit?.permanent_address?.permanent_country ?? '',
      permanent_state: edit?.permanent_address?.permanent_state ?? '',
      permanent_city: edit?.permanent_address?.permanent_city ?? '',
      same_as_permanent: false,
      correspondence_house_no: edit?.correspondence_address?.correspondence_house_no ?? '',
      correspondence_village_town_street:
        edit?.correspondence_address?.correspondence_village_town_street ?? '',
      correspondence_pincode: edit?.correspondence_address?.correspondence_pincode ?? '',
      correspondence_country: edit?.correspondence_address?.correspondence_country ?? '',
      correspondence_state: edit?.correspondence_address?.correspondence_state ?? '',
      correspondence_city: edit?.correspondence_address?.correspondence_city ?? '',
      emergency_contact_country: edit?.emergency_contact?.country_code ?? '91',
      emergency_contact_no: edit?.emergency_contact?.contact_no ?? ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name is required')
        .matches(
          /^[a-zA-Z][a-zA-Z\s]*$/,
          'Please enter a valid name (only alphabets & whitespaces are allowed)'
        ),
      father_name: Yup.string().required('Father Name is required'),
      spouse_name: Yup.string().notRequired(),
      company: Yup.string().required('Company is required'),
      department: Yup.string().required('Department is required'),
      gender: Yup.string().required('Gender is required'),
      marital_status: Yup.string().required('Marital Status is required'),
      blood_group: Yup.string().required('Blood group is required'),
      email: Yup.string().email('Invalid email address').notRequired(),
      mobile: Yup.string()
        .required('Mobile number is required')
        .matches(/^[6-9]\d{9}$/, 'Invalid mobile number'),
      password: Yup.string()
        .required('Password is required')
        .min(6, 'Minimum 6 characters')
        .max(15, 'Maximum 15 characters')
        .matches(
          /^[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{6,15}$/,
          'Password can only contain alphabets, numbers or special characters'
        ),
      // confirmPassword: Yup.string()
      //   .required('This field is required')
      //   .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      dateOfBirth: Yup.date()
        .max(new Date(getCurrentDate()), "Date of birth can't be a future date")
        .required('Date of birth is required'),
      doj: Yup.date()
        .max(new Date(getCurrentDate()), "Date of joining can't be a future date")
        .required('Date of joining is required'),
      // state: Yup.string().required('This field is required'),
      country: Yup.string().required('Country Code is required'),
      // address: Yup.string().required('Address is required'),
      vehicle_type: Yup.string()
        .required('This field is required')
        .oneOf(['Two-wheeler', 'Four-wheeler']),
      role_type: Yup.string().required('This Field is required'),
      manager_type: Yup.string().notRequired(),
      // site_type: Yup.string().notRequired(),
      add_employee_policy_type: Yup.string().required('This Filed is required'),
      emergency_contact_no: Yup.string().required('This Field is required'),
      emergency_contact_country: Yup.string().required('This Field is required'),
      permanent_house_no: Yup.string().required('This Field is required'),
      permanent_village_town_street: Yup.string().required('This Field is required'),
      permanent_pincode: Yup.string().required('This Field is required'),
      permanent_country: Yup.string().required('This Field is required'),
      permanent_state: Yup.string().required('This Field is required'),
      permanent_city: Yup.string().required('This Field is required'),
      correspondence_house_no: Yup.string().required('This Field is required'),
      correspondence_village_town_street: Yup.string().required('This Field is required'),
      correspondence_pincode: Yup.string().required('This Field is required'),
      correspondence_country: Yup.string().required('This Field is required'),
      correspondence_state: Yup.string().required('This Field is required'),
      correspondence_city: Yup.string().required('This Field is required'),
      payout_type: Yup.string().required('This Field is required'),
      payout_amount: Yup.string().required('This Field is required'),
      uan: Yup.string().required('This Field is required'),
      bank_name: Yup.string().required('This Field is required'),
      bank_account_no: Yup.string().required('This Field is required'),
      bank_ifsc_code: Yup.string().required('This Field is required')
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      // actions.setSubmitting(true);
      const data = {
        name: capitalizeEachWord(values.name),
        father_name: capitalizeEachWord(values.father_name),
        mother_name: capitalizeEachWord(values.mother_name),
        guardian_name: capitalizeEachWord(values.guardian_name),
        spouse_name: capitalizeEachWord(values.spouse_name),
        gender: values.gender,
        email: values.email.toLowerCase(),
        mobile: values.mobile,
        password: values.password,
        confirmPassword: values.password,
        dateOfBirth: convertUTCtoLocal(values.dateOfBirth, true),
        marital_status: values.marital_status,
        children: empChildren,
        pan: {
          value: values.pan,
          data: values.pan_proof
        },
        aadhar: {
          value: values.aadhar,
          data: values.aadhar_proof
        },
        blood_group: values.blood_group,
        // tag: values.tag,
        department: values.department,
        country_code: values.country,
        doj: convertUTCtoLocal(values.doj, true),
        address: capitalizeEachWord(values.address),
        role: values.role_type.split(',')[0],
        uan: values.uan,
        epf_no: values.epf_no,
        esi_no: values.esi_no,
        policy: values.add_employee_policy_type,
        vehicle_type: values.vehicle_type,
        payout_type: values.payout_type, // Type of pay
        payout_amount: values.payout_amount, // Amount of Pay
        company: values.company,
        payroll_template: values.payroll_template,
        permanent_address: {
          permanent_house_no: values.permanent_house_no,
          permanent_village_town_street: values.permanent_village_town_street,
          permanent_pincode: values.permanent_pincode,
          permanent_country: values.permanent_country,
          permanent_state: values.permanent_state,
          // permanent_district: values.permanent_district,
          permanent_city: values.permanent_city
        },
        correspondence_address: {
          correspondence_house_no: values.correspondence_house_no,
          correspondence_village_town_street: values.correspondence_village_town_street,
          correspondence_pincode: values.correspondence_pincode,
          correspondence_country: values.correspondence_country,
          correspondence_state: values.correspondence_state,
          // correspondence_district: values.correspondence_district,
          correspondence_city: values.correspondence_city
        },
        bank_details: {
          bank_name: values.bank_name,
          bank_branch: values.bank_branch,
          bank_account_no: values.bank_account_no,
          bank_ifsc_code: values.bank_ifsc_code
        },
        education_details: education.filter((edu) => edu.aggregate > 0),
        emergency_contact: {
          country_code: values.emergency_contact_country,
          contact_no: values.emergency_contact_no
        },
        pf_settings: {
          enable: values.enroll_pf,
          // pf_employer_contribution: values.pf_employer_contribution,
          pf_employee_contribution: values.pf_employee_contribution
        },
        passport: {
          number: values.passport_no,
          valid_from: values.passport_valid_from,
          valid_upto: values.passport_valid_upto
        },
        experience_details: experience.map((ep) => {
          // eslint-disable-next-line prefer-destructuring
          ep.from = convertUTCtoLocal(ep.from, true);
          // eslint-disable-next-line prefer-destructuring
          ep.to = convertUTCtoLocal(ep.to, true);
          return ep;
        }),
        relivingCertificate: values.reliving_certificate,
        profilePicture: values.profilePicture
      };

      if (values.sub_role_type.length > 0) {
        data.subrole = values.sub_role_type;
      }

      if (values.manager_type.length > 0) {
        data.manager = values.manager_type;
      }

      if (siteType.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        data.sites = siteType.map((site) => site.split(',')[0]);
      }

      if (DEBUG) {
        console.log('Employee Add Form :', data);
      } else {
        mutate(data, {
          onError: (error) => {
            // for unauthorized access
            if (error.response && error.response.status === 401) {
              dispatch(logOut());
            }

            const msg = error.response
              ? error.response.data.message || error.toString()
              : error.toString();

            actions.setStatus({
              type: 'error',
              response: msg
            });
            actions.setSubmitting(false);
          },
          onSuccess: (data) => {
            actions.setSubmitting(false);
            resetForm();
            values.profilePicture = '';
            setDateOfBirth('');
            setDateOfJoining('');
            actions.setStatus({
              type: 'success',
              response: data?.message
            });
            setTimeout(() => {
              actions.setStatus({
                type: undefined,
                response: undefined
              });
            }, SWAL_TIME);
          },
          onSettled: () => {
            cache.invalidateQueries('employees');
            cache.invalidateQueries('unmanaged-employees');
          }
        });
      }
    }
  });

  /* ===================== Sites ===================== */

  const {
    isLoading,
    isError,
    // fetchNextPage: fetchNextSites,
    // hasMoreData: hasNextSites,
    data: sites
    // refetch: refetchSites
  } = useSites(
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[0], true) : null, // from
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[1], true) : null, // to
    null, // filter.date.length > 0 ? filter.date : getCurrentDate(),
    null, // type
    null, // filter.state, // state
    null, // filter.status, // status
    null, // filter.manager, // manager
    null, // filter.role,
    null, // search
    null
  );

  const allSites = useMemo(() => {
    const listOfSites = [];
    if (!isLoading && !isError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Sites :', flattened);
    return flattened;
  }, [sites, isLoading, isError]);

  useEffect(() => {
    const formattedSites = [];
    if (allSites?.length > 0) {
      allSites.forEach((site) => {
        if (site.active === 'Active') {
          formattedSites.push([`${site._id},${site.name}`, site.name]);
        }
      });
      setSiteOptions([...formattedSites]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allSites]);

  /* ================= End Sites =============== */

  const { errors, touched, values, getFieldProps, resetForm } = formik;

  useEffect(() => {
    console.log('Values:', values);
  }, [values]);

  /* ================== Sub Roles ======================== */

  const {
    isLoading: isSubRolesLoading,
    isError: isSubRolesError,
    data: subRoles
  } = useSubRoles(values.role_type.split(',')[0]);

  useMemo(() => {
    const listOfSubRoles = [];
    if (!isSubRolesLoading && !isSubRolesError) {
      if (subRoles?.roles?.length > 0) {
        subRoles.roles[0].subroles.forEach((role) => listOfSubRoles.push([role._id, role.name]));
      }
    }
    // console.log('Sub Roles By Role:', listOfSubRoles);
    setSubRoleOptions([...listOfSubRoles]);
  }, [subRoles, isSubRolesLoading, isSubRolesError]);

  /* ================== End Sub Roles ====================== */

  // For Image Size : 2MB , Supported Formats
  const FILE_SIZE = 2 * 1024 * 1024;
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

  /* ===================== Policies =====================  */

  const {
    isPoliciesLoading,
    isPoliciesError,
    // fetchNextPage: fetchNextPolicies,
    // hasNextPage: hasNextPolicies,
    data: policies
  } = usePolicies(values?.role_type.split(',')[0]); // getting policies

  const Policies = useMemo(() => {
    const listOfPolicies = [];
    if (!isPoliciesLoading && !isPoliciesError) {
      if (policies?.pages?.length > 0) {
        policies.pages.forEach((group) => listOfPolicies.push(group.policies));
      }
    }
    const flattened = listOfPolicies.flat();
    return flattened;
  }, [policies, isPoliciesLoading, isPoliciesError]);

  useEffect(() => {
    // console.log(Policies);
    const formattedPolicies = [];
    if (Policies?.length > 0) {
      Policies.forEach((policy) => formattedPolicies.push([policy._id, policy.name]));
      setPolicyOptions(formattedPolicies);
    }
  }, [Policies]);

  /* ===================== End Policies ==================  */
  /* ===================== Managers ===================== */

  const {
    isLoading: isManagersLoading,
    isError: isManagersError,
    data: managers
  } = useManagers(values?.role_type.split(',')[0]); // gettings managers

  const Managers = useMemo(() => {
    // console.log('Managers :', managers);
    const listOfManagers = [];
    if (!isManagersLoading && !isManagersError) {
      if (managers?.pages?.length > 0) {
        managers.pages.forEach((group) => listOfManagers.push(group.managers));
      }
    }
    const flattened = listOfManagers.flat();
    return flattened;
  }, [managers, isManagersLoading, isManagersError]);

  useEffect(() => {
    const formattedManagers = [];
    if (Managers?.length > 0) {
      Managers.forEach((manager) =>
        formattedManagers.push([
          manager._id,
          manager.name,
          manager.avatar ?? COMPANY_PLACEHOLDER_LOGO
        ])
      );
    }
    // console.log('Managers Options', formattedManagers);
    setManagerOptions([...formattedManagers]);
  }, [Managers]);

  /* ================= End Managers =============== */

  const _countries = Country.getAllCountries().map((cn) => `${cn.isoCode}:${cn.name}`);
  const _permanentStates = State.getStatesOfCountry(values.permanent_country.split(':')[0]).map(
    (pst) => `${pst.isoCode}:${pst.name}`
  );
  const _permanentCities = City.getCitiesOfState(
    values.permanent_country.split(':')[0],
    values.permanent_state.split(':')[0]
  ).map((pct) => `${pct.name}`);
  const _correspondanceStates = State.getStatesOfCountry(
    values.correspondence_country.split(':')[0]
  ).map((pst) => `${pst.isoCode}:${pst.name}`);
  const _correspondanceCities = City.getCitiesOfState(
    values.correspondence_country.split(':')[0],
    values.correspondence_state.split(':')[0]
  ).map((pct) => `${pct.name}`);

  useEffect(() => {
    if (values.same_as_permanent) {
      values.correspondence_house_no = values.permanent_house_no;
      values.correspondence_village_town_street = values.permanent_village_town_street;
      values.correspondence_pincode = values.permanent_pincode;
      values.correspondence_country = values.permanent_country;
      values.correspondence_state = values.permanent_state;
      // values.correspondence_district = values.permanent_district;
      values.correspondence_city = values.permanent_city;
    } else {
      values.correspondence_house_no = '';
      values.correspondence_village_town_street = '';
      values.correspondence_pincode = '';
      values.correspondence_country = '';
      values.correspondence_state = '';
      values.correspondence_district = '';
      values.correspondence_city = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.same_as_permanent]);

  const {
    isLoading: isPayrollTemplatesLoading,
    isError: isPayrollTemplatesError,
    data: payrollTemplates
  } = useAllPayrollComponents(
    values?.role_type.split(',')[0],
    values?.subrole_type ? values?.subrole_type.split(',')[0] : undefined
  ); // gettings roles

  const allPayrollTemplates = useMemo(() => {
    if (!isPayrollTemplatesLoading && !isPayrollTemplatesError) {
      return payrollTemplates?.templates;
    }
    return []; // Needs to be changed
  }, [payrollTemplates, isPayrollTemplatesLoading, isPayrollTemplatesError]);

  useEffect(() => {
    const formattedTemplates = [];
    if (allPayrollTemplates?.length > 0) {
      allPayrollTemplates.forEach((payroll) => {
        formattedTemplates.push([payroll._id, payroll.name]);
      });
      setPayrollTemplateOptions([...formattedTemplates]);
    }
  }, [allPayrollTemplates]);

  return (
    <FormikProvider value={formik}>
      <Form id="add-employee-form" autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
        <Box
          className="custom_scrollbar"
          spacing={1}
          height={edit ? '' : 'calc(100vh - 5rem)'}
          sx={{ overflowY: edit ? '' : 'scroll', overflowX: 'hidden' }}
        >
          {formik?.status?.response && (
            <Alert
              severity={formik?.status?.type}
              sx={{ px: 2, position: 'sticky', top: 0, zIndex: 5 }}
            >
              {formik?.status?.response}
            </Alert>
          )}
          <Grid sx={{ p: 2 }} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Personal Details</Typography>
            </Grid>
            {!edit && (
              <Grid item xs={4}>
                {/* Profile Picture */}
                <Stack directiob="row" justifyContent="center" alignItems="center">
                  <IconButton color="primary" aria-label="upload picture" component="label">
                    <input
                      onChange={async (event) => {
                        setPictureUploading(true);
                        values.profilePicture = '';
                        const profilePicture = event.currentTarget.files[0];
                        if (profilePicture) {
                          const { type, size } = profilePicture;
                          if (!SUPPORTED_FORMATS.includes(type)) {
                            errors.profilePicture = 'Unsupported Format';
                          } else if (size > FILE_SIZE) {
                            errors.profilePicture = 'File too large (Max: 2MB)';
                          } else {
                            delete errors.profilePicture;
                            values.profilePicture = await getBase64(profilePicture);
                          }
                        }
                        if (!values.profilePicture) {
                          errors.profilePicture = 'Profile picture is required';
                        }
                        setPictureUploading(false);
                      }}
                      hidden
                      accept="image/*"
                      type="file"
                    />
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        pictureUploading ? (
                          <CircularProgress size={0.9 * 23} />
                        ) : values.profilePicture && errors.profilePicture === undefined ? (
                          <CheckCircleIcon size={14} sx={{ color: 'green' }} />
                        ) : (
                          <PhotoCameraIcon />
                        )
                      }
                    >
                      <Avatar alt="" sx={{ width: 100, height: 100 }} src={values.profilePicture} />
                    </Badge>
                  </IconButton>
                  {errors.profilePicture ? (
                    <Typography align="center" sx={{ color: 'red', fontSize: 12 }}>
                      {errors.profilePicture}
                    </Typography>
                  ) : null}
                </Stack>
              </Grid>
            )}
            <Grid item md={edit ? 12 : 8} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  {/* Name */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Name"
                      autoComplete="off"
                      required
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  {/* Father Name */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Father Name"
                      autoComplete="off"
                      required
                      {...getFieldProps('father_name')}
                      error={Boolean(touched.father_name && errors.father_name)}
                      helperText={touched.father_name && errors.father_name}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  {/* Mother Name */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Mother Name"
                      autoComplete="off"
                      {...getFieldProps('mother_name')}
                      error={Boolean(touched.mother_name && errors.mother_name)}
                      helperText={touched.mother_name && errors.mother_name}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  {/* Guardian Name */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Guardian Name"
                      autoComplete="off"
                      {...getFieldProps('guardian_name')}
                      error={Boolean(touched.guardian_name && errors.guardian_name)}
                      helperText={touched.guardian_name && errors.guardian_name}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  {/* Gender */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <FormControl required fullWidth>
                      <InputLabel variant="standard" htmlFor="employee_gender">
                        Choose Gender
                      </InputLabel>
                      <Select
                        variant="standard"
                        inputProps={{
                          name: 'gender',
                          id: 'employee_gender'
                        }}
                        sx={{ mt: 2 }}
                        {...getFieldProps('gender')}
                        error={Boolean(touched.gender && errors.gender)}
                        // helperText={touched.policy_role && errors.policy_role}
                      >
                        <MenuItem value="" disabled>
                          Choose Gender
                        </MenuItem>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  {/* Date Of Birth */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Date Of Birth"
                        disableFuture
                        value={dateOfBirth}
                        onChange={(dateOfBirth) => {
                          values.dateOfBirth = convertUTCtoLocal(dateOfBirth, true);
                          setDateOfBirth(values.dateOfBirth);
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            fullWidth
                            required
                            {...params}
                            error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                            helperText={touched.dateOfBirth && errors.dateOfBirth}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              {/* Date Of Joining */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date Of Joining"
                    value={dateOfJoining}
                    disableFuture
                    onChange={(dateOfJoining) => {
                      values.doj = convertUTCtoLocal(dateOfJoining, true);
                      setDateOfJoining(values.doj);
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        fullWidth
                        required
                        {...getFieldProps('doj')}
                        {...params}
                        error={Boolean(touched.doj && errors.doj)}
                        helperText={touched.doj && errors.doj}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              {/* Blood Group */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="employee_gender">
                    Choose Blood Group
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'blood_group',
                      id: 'employee_blood_group'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('blood_group')}
                    error={Boolean(touched.blood_group && errors.blood_group)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Choose Blood Group
                    </MenuItem>
                    <MenuItem value="A+">A+</MenuItem>
                    <MenuItem value="A-">A-</MenuItem>
                    <MenuItem value="B+">B+</MenuItem>
                    <MenuItem value="B-">B-</MenuItem>
                    <MenuItem value="O+">O+</MenuItem>
                    <MenuItem value="O-">O-</MenuItem>
                    <MenuItem value="AB+">AB+</MenuItem>
                    <MenuItem value="AB-">AB-</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              {/* Email */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <EmailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Email"
                  autoComplete="off"
                  // required
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="marital_status">
                    Marital Status
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'marital_status',
                      id: 'marital_status'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('marital_status')}
                    error={Boolean(touched.marital_status && errors.marital_status)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Marital Status
                    </MenuItem>
                    <MenuItem value="Married">Married</MenuItem>
                    <MenuItem value="Unmarried">Unmarried</MenuItem>
                    <MenuItem value="Widow">Widow</MenuItem>
                    <MenuItem value="Widower">Widower</MenuItem>
                    <MenuItem value="Divorced">Divorced</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {values.marital_status === 'Married' && (
              <Grid item md={4} xs={12}>
                {/* Spouse Name */}
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <TextField
                    variant="standard"
                    fullWidth
                    type="text"
                    label="Spouse Name"
                    autoComplete="off"
                    required
                    {...getFieldProps('spouse_name')}
                    error={Boolean(touched.spouse_name && errors.spouse_name)}
                    helperText={touched.spouse_name && errors.spouse_name}
                  />
                </Box>
              </Grid>
            )}
            {values.marital_status === 'Married' ? (
              <Grid item xs={12}>
                <Box sx={{ border: '1px solid #333', borderRadius: 2, px: 2, pb: 2 }}>
                  <Typography sx={{ my: 2 }}>Children</Typography>
                  <Grid container spacing={2}>
                    {empChildren.map((child, idx) => {
                      const chd = empChildren.find((ch) => ch.id === child.id);
                      return (
                        <Fragment key={`child-${idx}-${child?.id}`}>
                          <Grid item md={3} xs={12}>
                            <TextField
                              variant="standard"
                              fullWidth
                              type="text"
                              label="Name"
                              onChange={(e) => {
                                chd.name = e.target.value;
                                setEmpChildren([...empChildren]);
                              }}
                              autoComplete="off"
                              value={child?.name}
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <FormControl fullWidth>
                              <InputLabel variant="standard" htmlFor="employee_gender">
                                Relation
                              </InputLabel>
                              <Select
                                variant="standard"
                                sx={{ mt: 2 }}
                                value={child?.relation}
                                onChange={(e) => {
                                  chd.relation = e.target.value;
                                  setEmpChildren([...empChildren]);
                                }}
                              >
                                <MenuItem value="" disabled>
                                  Relation
                                </MenuItem>
                                <MenuItem value="Son">Son</MenuItem>
                                <MenuItem value="Daughter">Daughter</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <TextField
                              variant="standard"
                              fullWidth
                              type="text"
                              label="Age"
                              autoComplete="off"
                              value={child?.age}
                              onChange={(e) => {
                                chd.age = e.target.value;
                                setEmpChildren([...empChildren]);
                              }}
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <Button
                              variant="contained"
                              size="small"
                              color="error"
                              startIcon={<CloseIcon />}
                              style={{ minWidth: '33px' }}
                              sx={{
                                px: 0,
                                mt: 3,
                                '& span': { mr: 0 }
                              }}
                              onClick={() => {
                                const chd = empChildren.filter((ch) => ch.id !== child.id);
                                setEmpChildren(chd);
                              }}
                            />
                          </Grid>
                        </Fragment>
                      );
                    })}
                    <Grid item xs={12}>
                      <Button
                        startIcon={<AddIcon />}
                        onClick={() =>
                          setEmpChildren([
                            ...empChildren,
                            {
                              id: uid(),
                              name: '',
                              relation: '',
                              age: ''
                            }
                          ])
                        }
                        variant="contained"
                        size="small"
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} />
            )}
            <Grid item md={4} xs={12}>
              {/* PAN */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="PAN No"
                  autoComplete="off"
                  {...getFieldProps('pan')}
                  error={Boolean(touched.pan && errors.pan)}
                  helperText={touched.pan && errors.pan}
                />
              </Box>
            </Grid>
            <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={8} xs={12}>
              <Button
                color={values.pan_proof ? 'success' : 'primary'}
                sx={{ mt: 2 }}
                variant="contained"
                size="small"
                component="label"
              >
                Upload
                <input
                  hidden
                  accept=".jpg,.jpeg,.png"
                  id="panCard"
                  name="panCard"
                  // eslint-disable-next-line prefer-destructuring
                  onChange={async (event) => {
                    // setPictureUploading(true);
                    values.pan_proof = '';
                    const panCard = event.currentTarget.files[0];
                    if (panCard) {
                      const { type, size } = panCard;
                      if (!SUPPORTED_FORMATS.includes(type)) {
                        errors.pan_proof = 'Unsupported Format';
                      } else if (size > FILE_SIZE) {
                        errors.pan_proof = 'File too large (Max: 2MB)';
                      } else {
                        delete errors.pan;
                        values.pan_proof = await getBase64(panCard);
                      }
                    }
                    if (!values.pan_proof) {
                      errors.pan_proof = 'Profile picture is required';
                    }
                    // setPictureUploading(false);
                  }}
                  type="file"
                />
              </Button>
              <Typography sx={{ mt: 2, ml: 2, fontSize: 14 }}>jpg, not more than 2MB</Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              {/* Aadhar */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Aadhar No"
                  autoComplete="off"
                  {...getFieldProps('aadhar')}
                  error={Boolean(touched.aadhar && errors.aadhar)}
                  helperText={touched.aadhar && errors.aadhar}
                />
              </Box>
            </Grid>
            <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={8} xs={12}>
              <Button
                color={`${values?.aadhar_proof?.length > 0 ? 'success' : 'primary'}`}
                sx={{ mt: 2 }}
                variant="contained"
                size="small"
                component="label"
              >
                Upload
                <input
                  hidden
                  accept=".jpg,.jpeg,.png"
                  id="panCard"
                  name="panCard"
                  // eslint-disable-next-line prefer-destructuring
                  onChange={async (event) => {
                    // setPictureUploading(true);
                    values.aadhar_proof = '';
                    const aadharCard = event.currentTarget.files[0];
                    if (aadharCard) {
                      const { type, size } = aadharCard;
                      if (!SUPPORTED_FORMATS.includes(type)) {
                        errors.aadhar_proof = 'Unsupported Format';
                      } else if (size > FILE_SIZE) {
                        errors.aadhar_proof = 'File too large (Max: 2MB)';
                      } else {
                        delete errors.pan;
                        values.aadhar_proof = await getBase64(aadharCard);
                      }
                    }
                    if (!values.aadhar_proof) {
                      errors.aadhar_proof = 'Profile picture is required';
                    }
                    // setPictureUploading(false);
                  }}
                  type="file"
                />
              </Button>
              <Typography sx={{ mt: 2, ml: 2, fontSize: 14 }}>jpg, not more than 2MB</Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              {/* Mobile */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <ContactPhoneIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl>
                  {/* <InputLabel id="mobile-country-select-label">Country</InputLabel> */}
                  <Select
                    labelId="mobile-country-select-label"
                    id="mobile-country-select"
                    variant="standard"
                    label="Country"
                    {...getFieldProps('country')}
                  >
                    {countries.map((c) => (
                      <MenuItem key={c.code} value={c.phone}>
                        <img
                          loading="lazy"
                          width="23"
                          src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                          alt={`Flag of ${c.label}`}
                        />
                        &nbsp; +{c.phone}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* &nbsp;[ {c.label} ] */}
                <TextField
                  sx={{ width: 160 }}
                  variant="standard"
                  type="number"
                  label="Mobile"
                  autoComplete="off"
                  required
                  {...getFieldProps('mobile')}
                  error={Boolean(touched.mobile && errors.mobile)}
                />
              </Box>
              {errors.mobile && (
                <Typography align="center" sx={{ color: 'red', fontSize: 12 }}>
                  {errors.mobile}
                </Typography>
              )}
            </Grid>
            <Grid item md={4} xs={12}>
              {/* Password */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PasswordIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  autoComplete="off"
                  required
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Box>
            </Grid>
            <Grid item md={8} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <ContactPhoneIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl>
                  {/* <InputLabel id="mobile-country-select-label">Country</InputLabel> */}
                  <Select
                    labelId="emergency-contact-country"
                    id="emergency-contact-country"
                    variant="standard"
                    label="Country"
                    {...getFieldProps('emergency_contact_country')}
                  >
                    {countries.map((c) => (
                      <MenuItem key={c.code} value={c.phone}>
                        <img
                          loading="lazy"
                          width="23"
                          style={{ display: 'inline-block' }}
                          src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                          alt={`Flag of ${c.label}`}
                        />
                        &nbsp; +{c.phone}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* &nbsp;[ {c.label} ] */}
                <TextField
                  sx={{ width: 250 }}
                  variant="standard"
                  type="number"
                  label="Emergency Contact No"
                  autoComplete="off"
                  required
                  {...getFieldProps('emergency_contact_no')}
                  error={Boolean(touched.emergency_contact_no && errors.emergency_contact_no)}
                />
              </Box>
              {errors.emergency_contact_no && (
                <Typography align="center" sx={{ color: 'red', fontSize: 12 }}>
                  {errors.emergency_contact_no}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Permanent Address
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="House No. / Building Name"
                  autoComplete="off"
                  required
                  {...getFieldProps('permanent_house_no')}
                  error={Boolean(touched.permanent_house_no && errors.permanent_house_no)}
                  helperText={touched.permanent_house_no && errors.permanent_house_no}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Village / Town / Street"
                  autoComplete="off"
                  required
                  {...getFieldProps('permanent_village_town_street')}
                  error={Boolean(
                    touched.permanent_village_town_street && errors.permanent_village_town_street
                  )}
                  helperText={
                    touched.permanent_village_town_street && errors.permanent_village_town_street
                  }
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Pincode"
                  autoComplete="off"
                  required
                  {...getFieldProps('permanent_pincode')}
                  error={Boolean(touched.permanent_pincode && errors.permanent_pincode)}
                  helperText={touched.permanent_pincode && errors.permanent_pincode}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="permanent_country">
                    Choose Country
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'permanent_country',
                      id: 'permanent_country'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('permanent_country')}
                    error={Boolean(touched.permanent_country && errors.permanent_country)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Choose Country
                    </MenuItem>
                    {_countries.map((cn) => (
                      <MenuItem key={cn.toLowerCase()} value={cn}>
                        {cn.split(':')[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="permanent_state">
                    State
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'permanent_state',
                      id: 'permanent_state'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('permanent_state')}
                    error={Boolean(touched.permanent_state && errors.permanent_state)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      State
                    </MenuItem>
                    {_permanentStates.map((pst) => (
                      <MenuItem key={pst.toLowerCase()} value={pst}>
                        {pst.split(':')[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="permanent_city">
                    City
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'permanent_city',
                      id: 'permanent_city'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('permanent_city')}
                    error={Boolean(touched.permanent_city && errors.permanent_city)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      City
                    </MenuItem>
                    {_permanentCities.map((pct) => (
                      <MenuItem key={pct.toLowerCase()} value={pct}>
                        {pct}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Correspondence Details
              </Typography>
            </Grid>
            <Grid xs={12} />
            <Grid item md={3} xs={12}>
              <Typography sx={{ mt: 1 }}>Save as Permanent</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Switch {...label} {...getFieldProps('same_as_permanent')} />
            </Grid>
            <Grid xs={12} />
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="House No. / Building Name"
                  autoComplete="off"
                  required
                  {...getFieldProps('correspondence_house_no')}
                  error={Boolean(touched.correspondence_house_no && errors.correspondence_house_no)}
                  helperText={touched.correspondence_house_no && errors.correspondence_house_no}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Village / Town / Street"
                  autoComplete="off"
                  required
                  {...getFieldProps('correspondence_village_town_street')}
                  error={Boolean(
                    touched.correspondence_village_town_street &&
                      errors.correspondence_village_town_street
                  )}
                  helperText={
                    touched.correspondence_village_town_street &&
                    errors.correspondence_village_town_street
                  }
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Pincode"
                  autoComplete="off"
                  required
                  {...getFieldProps('correspondence_pincode')}
                  error={Boolean(touched.correspondence_pincode && errors.correspondence_pincode)}
                  helperText={touched.correspondence_pincode && errors.correspondence_pincode}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="correspondence_country">
                    Country
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'correspondence_country',
                      id: 'correspondence_country'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('correspondence_country')}
                    error={Boolean(touched.correspondence_country && errors.correspondence_country)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Country
                    </MenuItem>
                    {_countries.map((cn) => (
                      <MenuItem key={cn.toLowerCase()} value={cn}>
                        {cn.split(':')[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="correspondence_state">
                    State
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'correspondence_state',
                      id: 'correspondence_state'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('correspondence_state')}
                    error={Boolean(touched.correspondence_state && errors.correspondence_state)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      State
                    </MenuItem>
                    {_correspondanceStates.map((cst) => (
                      <MenuItem key={cst.toLowerCase()} value={cst}>
                        {cst.split(':')[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="correspondence_city">
                    Choose City
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'correspondence_city',
                      id: 'correspondence_city'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('correspondence_city')}
                    error={Boolean(touched.correspondence_city && errors.correspondence_city)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Choose City
                    </MenuItem>
                    {_correspondanceCities.map((cct) => (
                      <MenuItem key={cct.toLowerCase()} value={cct}>
                        {cct}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Educational Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2}>
                {education.map((edu, idx) => (
                  <Box
                    sx={{
                      border: '1px solid #333',
                      p: 2,
                      my: 1,
                      borderRadius: 1,
                      position: 'relative'
                    }}
                    key={edu?.id}
                  >
                    {idx > 0 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        startIcon={<CloseIcon />}
                        style={{ minWidth: '33px' }}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 4,
                          mt: -2,
                          px: 0,
                          '& span': { mr: 0 }
                        }}
                        onClick={() => {
                          const ed = education.filter((du) => du.id !== edu.id);
                          setEducation(ed);
                        }}
                      />
                    )}
                    <Grid container spacing={2}>
                      <Grid item md={4} xs={12}>
                        {/* Education */}
                        <FormControl fullWidth>
                          <InputLabel variant="standard" htmlFor="employee_educatio">
                            Choose Education
                          </InputLabel>
                          <Select
                            variant="standard"
                            inputProps={{
                              name: 'education',
                              id: 'employee_education'
                            }}
                            sx={{ mt: 2 }}
                            onChange={(e) => {
                              const ed = education.find((du) => du.id === edu.id);
                              ed.type = e.target.value;
                              setEducation([...education]);
                            }}
                            error={Boolean(touched.education && errors.education)}
                            // helperText={touched.policy_role && errors.policy_role}
                          >
                            <MenuItem value="" disabled>
                              Choose Education
                            </MenuItem>
                            <MenuItem value="10th">10th</MenuItem>
                            <MenuItem value="12th">12th</MenuItem>
                            <MenuItem value="bachelor">Bachelor</MenuItem>
                            <MenuItem value="master">Master</MenuItem>
                            <MenuItem value="phd">PHD</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        {/* Course Title */}
                        <TextField
                          variant="standard"
                          fullWidth
                          type="text"
                          label="Course"
                          autoComplete="off"
                          onChange={(e) => {
                            const ed = education.find((du) => du.id === edu.id);
                            ed.course_title = e.target.value;
                            setEducation([...education]);
                          }}
                          error={Boolean(touched.course_title && errors.course_title)}
                          helperText={
                            (touched.course_title && errors.course_title) ??
                            'Enter title of your course'
                          }
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        {/* Univesity or Board */}
                        <TextField
                          variant="standard"
                          fullWidth
                          type="text"
                          label="Instituion"
                          autoComplete="off"
                          onChange={(e) => {
                            const ed = education.find((du) => du.id === edu.id);
                            ed.institution = e.target.value;
                            setEducation([...education]);
                          }}
                          error={Boolean(touched.institution && errors.institution)}
                          helperText={
                            (touched.institution && errors.institution) ??
                            'Enter Name of University or Board'
                          }
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        {/* Aggregate */}
                        <TextField
                          variant="standard"
                          fullWidth
                          type="text"
                          label="Aggregate"
                          autoComplete="off"
                          onChange={(e) => {
                            const ed = education.find((du) => du.id === edu.id);
                            ed.aggregate = e.target.value;
                            setEducation([...education]);
                          }}
                          error={Boolean(touched.aggregate && errors.aggregate)}
                          helperText={
                            (touched.aggregate && errors.aggregate) ?? 'Enter Percentage or CGPA'
                          }
                        />
                      </Grid>
                      <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={4} xs={12}>
                        <Typography sx={{ mr: 2, mt: 1 }}>Certificate</Typography>
                        <Button
                          sx={{ mt: 1 }}
                          variant="contained"
                          color={`${edu?.certificate?.length > 0 ? 'success' : 'primary'}`}
                          size="small"
                          component="label"
                        >
                          Upload
                          <input
                            hidden
                            accept=".jpg,.jpeg,.png"
                            id={`certificate-${idx}-${edu?.id}`}
                            name={`certificate-${idx}-${edu?.id}`}
                            // eslint-disable-next-line prefer-destructuring
                            onChange={async (event) => {
                              // setPictureUploading(true);
                              const ed = education.find((du) => du.id === edu.id);
                              // eslint-disable-next-line prefer-destructuring
                              ed.certificate = event.currentTarget.files[0];
                              if (ed.certificate) {
                                const { type, size } = ed.certificate;
                                if (!SUPPORTED_FORMATS.includes(type)) {
                                  errors[`certificate-${edu.id}`] = 'Unsupported Format';
                                } else if (size > FILE_SIZE) {
                                  errors[`certificate-${edu.id}`] = 'File too large (Max: 2MB)';
                                } else {
                                  delete errors[`certificate-${edu.id}`];
                                  ed.certificate = await getBase64(ed.certificate);
                                }
                              }
                              if (!ed.certificate) {
                                errors[`certificate-${edu.id}`] = 'Certificate is required';
                              }
                              // setPictureUploading(false);
                            }}
                            type="file"
                          />
                        </Button>
                        <Typography sx={{ mt: 1, ml: 2, fontSize: 12 }}>
                          jpg, not more than 2MB
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<AddIcon />}
                onClick={() =>
                  setEducation([
                    ...education,
                    {
                      id: uid(),
                      type: '',
                      aggregate: '',
                      certificate: ''
                    }
                  ])
                }
                variant="contained"
                size="small"
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Previous Employment Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2}>
                {experience.map((exp, idx) => (
                  <Box
                    sx={{
                      border: '1px solid #333',
                      p: 2,
                      my: 1,
                      borderRadius: 1,
                      position: 'relative'
                    }}
                    key={exp.id}
                  >
                    {idx > 0 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        startIcon={<CloseIcon />}
                        style={{ minWidth: '33px' }}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 4,
                          mt: -2,
                          px: 0,
                          '& span': { mr: 0 }
                        }}
                        onClick={() => {
                          const xp = experience.filter((xp) => xp.id !== exp.id);
                          setExperience(xp);
                        }}
                      />
                    )}
                    <Grid container spacing={2}>
                      <Grid item md={4} xs={12}>
                        <DatePicker
                          label="From"
                          value={exp.from}
                          onChange={(newValue) => {
                            const xp = experience.find((xp) => xp.id === exp.id);
                            xp.from = newValue;
                            setExperience([...experience]);
                          }}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} helperText={null} />
                          )}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <DatePicker
                          label="To"
                          value={exp.to}
                          onChange={(newValue) => {
                            const xp = experience.find((xp) => xp.id === exp.id);
                            xp.to = newValue;
                            setExperience([...experience]);
                          }}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} helperText={null} />
                          )}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                          <TextField
                            variant="standard"
                            fullWidth
                            type="text"
                            label="Organization Name"
                            autoComplete="off"
                            value={exp.organization_name}
                            onChange={(e) => {
                              const xp = experience.find((xp) => xp.id === exp.id);
                              xp.organization_name = e.target.value;
                              setExperience([...experience]);
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                          <TextField
                            variant="standard"
                            fullWidth
                            type="text"
                            label="Designation"
                            autoComplete="off"
                            value={exp.designation}
                            onChange={(e) => {
                              const xp = experience.find((xp) => xp.id === exp.id);
                              xp.designation = e.target.value;
                              setExperience([...experience]);
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                          <TextField
                            variant="standard"
                            fullWidth
                            type="text"
                            label="CTC"
                            autoComplete="off"
                            value={exp.ctc}
                            onChange={(e) => {
                              const xp = experience.find((xp) => xp.id === exp.id);
                              xp.ctc = e.target.value;
                              setExperience([...experience]);
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() =>
                  setExperience([
                    ...experience,
                    {
                      id: uid(),
                      from: undefined,
                      to: undefined,
                      certificate: ''
                    }
                  ])
                }
                startIcon={<AddIcon />}
                variant="contained"
                size="small"
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Past Experience Certificate
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={6} xs={12}>
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  color={`${values?.exp_certificate?.length > 0 ? 'success' : 'primary'}`}
                  size="small"
                  component="label"
                >
                  Upload
                  <input
                    hidden
                    accept=".jpg,.jpeg,.png"
                    id="exp-certificate"
                    name="exp-certificate"
                    // eslint-disable-next-line prefer-destructuring
                    onChange={async (event) => {
                      values.exp_certificate = '';
                      const expCertificate = event.currentTarget.files[0];
                      if (expCertificate) {
                        const { type, size } = expCertificate;
                        if (!SUPPORTED_FORMATS.includes(type)) {
                          errors.exp_certificate = 'Unsupported Format';
                        } else if (size > FILE_SIZE) {
                          errors.exp_certificate = 'File too large (Max: 2MB)';
                        } else {
                          delete errors.exp_certificate;
                          values.exp_certificate = await getBase64(expCertificate);
                        }
                      }
                      // if (!values.exp_certificate) {
                      //   errors.exp_certificate = 'Profile picture is required';
                      // }
                    }}
                    type="file"
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Reliving Certificate ( if Required )</Typography>
            </Grid>
            <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={8} xs={12}>
              <Button
                variant="contained"
                color={`${values?.reliving_certificate?.length > 0 ? 'success' : 'primary'}`}
                size="small"
                component="label"
              >
                Upload
                <input
                  hidden
                  accept=".jpg,.jpeg,.png"
                  id="reliving_certificate"
                  name="reliving_certificate"
                  // eslint-disable-next-line prefer-destructuring
                  onChange={async (event) => {
                    // setPictureUploading(true);
                    values.reliving_certificate = '';
                    const relivingCertificate = event.currentTarget.files[0];
                    if (relivingCertificate) {
                      const { type, size } = relivingCertificate;
                      if (!SUPPORTED_FORMATS.includes(type)) {
                        errors.reliving_certificate = 'Unsupported Format';
                      } else if (size > FILE_SIZE) {
                        errors.reliving_certificate = 'File too large (Max: 2MB)';
                      } else {
                        delete errors.reliving_certificate;
                        values.reliving_certificate = await getBase64(relivingCertificate);
                      }
                    }
                    if (!values.reliving_certificate) {
                      errors.reliving_certificate = 'Profile picture is required';
                    }
                    // setPictureUploading(false);
                  }}
                  type="file"
                />
              </Button>
              <Typography sx={{ mt: 1, ml: 2, fontSize: 14 }}>jpg, not more than 2MB</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 1 }}>
                Passport Details ( if available )
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              {/* Passport No */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Passport No"
                  autoComplete="off"
                  {...getFieldProps('passport_no')}
                  error={Boolean(touched.passport_no && errors.passport_no)}
                  helperText={touched.passport_no && errors.passport_no}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              {/* Passport Valid From */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Valid From"
                    disableFuture
                    value={validFromPassport}
                    onChange={(newValue) => {
                      values.passport_valid_from = convertUTCtoLocal(newValue, true);
                      setValidFromPassport(values.passport_valid_from);
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        fullWidth
                        {...params}
                        error={Boolean(touched.passport_valid_from && errors.passport_valid_from)}
                        helperText={touched.passport_valid_from && errors.passport_valid_from}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              {/* Passport Valid Upto */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Valid Upto"
                    disableFuture
                    value={validUptoPassport}
                    onChange={(newValue) => {
                      values.passport_valid_upto = convertUTCtoLocal(newValue, true);
                      setValidUptoPassport(values.passport_valid_upto);
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        fullWidth
                        {...params}
                        error={Boolean(touched.passport_valid_upto && errors.passport_valid_upto)}
                        helperText={touched.passport_valid_upto && errors.passport_valid_upto}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 1 }}>
                Bank Details
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              {/* Bank Name */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Bank Name"
                  autoComplete="off"
                  required
                  {...getFieldProps('bank_name')}
                  error={Boolean(touched.bank_name && errors.bank_name)}
                  helperText={touched.bank_name && errors.bank_name}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              {/* Bank Branch */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Bank Branch"
                  autoComplete="off"
                  required
                  {...getFieldProps('bank_branch')}
                  error={Boolean(touched.bank_branch && errors.bank_branch)}
                  helperText={touched.bank_branch && errors.bank_branch}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              {/* Bank Account No */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Bank Account No"
                  autoComplete="off"
                  required
                  {...getFieldProps('bank_account_no')}
                  error={Boolean(touched.bank_account_no && errors.bank_account_no)}
                  helperText={touched.bank_account_no && errors.bank_account_no}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              {/* Bank IFSC Code */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="IFSC Code"
                  autoComplete="off"
                  required
                  {...getFieldProps('bank_ifsc_code')}
                  error={Boolean(touched.bank_ifsc_code && errors.bank_ifsc_code)}
                  helperText={touched.bank_ifsc_code && errors.bank_ifsc_code}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Company Details</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              {/* Company Name */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="employee_company_name">
                    Choose Company
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'company',
                      id: 'employee_comapny_name'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('company')}
                    error={Boolean(touched.company && errors.company)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Choose Company
                    </MenuItem>
                    {companyOptions.map((cmp) => (
                      <MenuItem key={`company-options-${cmp[0]}`} value={cmp[0]}>
                        {cmp[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              {/* Department */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="department">
                    Department
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'department',
                      id: 'department'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('department')}
                    error={Boolean(touched.department && errors.department)}
                    // helperText={touched.deparment && errors.department}
                  >
                    <MenuItem value="" disabled>
                      Department
                    </MenuItem>
                    {allDepartments?.length > 0 ? (
                      allDepartments.map(([key, value]) => (
                        <MenuItem key={`${'department_type'}-${key}`} value={key}>
                          {value}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled />
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              {/* Role */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="role_type">
                    Choose Role
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'role_type',
                      id: 'role_type'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('role_type')}
                    error={Boolean(touched.role_type && errors.role_type)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Choose Role
                    </MenuItem>
                    {roleOptions?.length > 0 ? (
                      roleOptions.map(([key, value, image]) => (
                        <MenuItem key={`${'role_type'}-${key}`} value={key} data-icon={image}>
                          {value}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled />
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {values.role_type && subRoleOptions.length > 0 ? (
              <Grid item md={4} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="role_type">
                      Choose Sub Role
                    </InputLabel>
                    <Select
                      variant="standard"
                      disabled={!(values.role_type && subRoleOptions.length > 0)}
                      value={!subRoleOptions && ''}
                      inputProps={{
                        name: 'sub_role_type',
                        id: 'sub_role_type'
                      }}
                      sx={{ mt: 2 }}
                      {...getFieldProps('sub_role_type')}
                      error={Boolean(touched.sub_role_type && errors.sub_role_type)}
                      // helperText={touched.policy_role && errors.policy_role}
                    >
                      <MenuItem value="" disabled>
                        Choose Sub Role
                      </MenuItem>
                      {subRoleOptions?.length > 0 ? (
                        subRoleOptions.map(([key, value, image]) => (
                          <MenuItem key={`${'sub_role_type'}-${key}`} value={key} data-icon={image}>
                            {value}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" disabled />
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            ) : null}
            <Grid item md={4} xs={12}>
              {/* Tag */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="tag">
                    Choose Tag
                  </InputLabel>
                  <Select
                    variant="standard"
                    disabled={!(values.role_type && tagOptions.length > 0)}
                    value={!tagOptions && ''}
                    inputProps={{
                      name: 'tag',
                      id: 'tag'
                    }}
                    required
                    sx={{ mt: 2 }}
                    {...getFieldProps('tag')}
                    error={Boolean(touched.tag && errors.tag)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Choose Tag
                    </MenuItem>
                    {tagOptions?.length > 0 ? (
                      tagOptions.map(([key, value, image]) => (
                        <MenuItem key={`${'tag'}-${key}`} value={key} data-icon={image}>
                          {value}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled />
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {values.role_type.split(',')[1] === 'false' && (
              <Grid item md={4} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="manager_type">
                      Choose Manager
                    </InputLabel>
                    <Select
                      variant="standard"
                      inputProps={{
                        name: 'manager_type',
                        id: 'manager_type'
                      }}
                      required
                      disabled={!(values?.role_type?.length > 0)}
                      sx={{ mt: 2 }}
                      {...getFieldProps('manager_type')}
                      error={Boolean(touched.manager_type && errors.manager_type)}
                      // helperText={touched.policy_role && errors.policy_role}
                    >
                      <MenuItem value="" disabled>
                        Choose Manager
                      </MenuItem>
                      {managerOptions?.length > 0 ? (
                        managerOptions.map(([key, value, image]) => (
                          <MenuItem
                            style={{ padding: '0.5rem' }}
                            key={`${'manager_type'}-${key}`}
                            value={key}
                            data-icon={image}
                          >
                            {value}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" disabled />
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            )}
            {values.role_type.split(',')[1] === 'true' && (
              <Grid item md={4} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="assign_manager">
                      Choose Sites
                    </InputLabel>
                    <Select
                      variant="standard"
                      multiple
                      value={siteType}
                      inputProps={{
                        name: 'site_type',
                        id: `site_type`
                      }}
                      sx={{ mt: 2 }}
                      onChange={(event) => {
                        const {
                          target: { value }
                        } = event;
                        setSiteType(
                          // On autofill we get a stringified value.
                          typeof value === 'string' ? value.split(',') : value
                        );
                      }}
                      // {...getFieldProps('site_type')}
                      // error={Boolean(touched.site_type && errors.site_type)}
                    >
                      <MenuItem value="" disabled>
                        Choose Sites
                      </MenuItem>
                      {siteOptions?.length > 0 ? (
                        siteOptions.map(([key, value]) => (
                          <MenuItem
                            style={{ padding: '0.5rem' }}
                            key={`${'assign_site'}-${key}`}
                            value={key}
                          >
                            {value}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" disabled />
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            )}
            <Grid item md={4} xs={12}>
              {/* Policy */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <StickyNote2Icon sx={{ color: 'action.active', mr: 1 }} />
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="add_employee_policy_type">
                    Choose Policy
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'add_employee_policy_type',
                      id: 'add_employee_policy_type'
                    }}
                    disabled={!(values?.role_type?.length > 0)}
                    sx={{ mt: 2 }}
                    {...getFieldProps('add_employee_policy_type')}
                    error={Boolean(
                      touched.add_employee_policy_type && errors.add_employee_policy_type
                    )}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Choose Policy
                    </MenuItem>
                    {policyOptions?.length > 0 ? (
                      policyOptions.map(([key, value, image]) => (
                        <MenuItem
                          style={{ padding: '0.5rem' }}
                          key={`${'policy_type_employee'}-${key}`}
                          value={key}
                          data-icon={image}
                        >
                          {value}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled />
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              {/* Vehicle */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <EmojiTransportationIcon sx={{ color: 'action.active', mr: 1 }} />
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="vehicle_type">
                    Choose Vehicle
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'vehicle_type',
                      id: 'vehicle_type'
                    }}
                    required
                    sx={{ mt: 2 }}
                    {...getFieldProps('vehicle_type')}
                    error={Boolean(touched.vehicle_type && errors.vehicle_type)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Choose Vehicle
                    </MenuItem>
                    <MenuItem value="Two-wheeler">Two Wheeler</MenuItem>
                    <MenuItem value="Four-wheeler">Four Wheeler</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 1 }}>
                Statutory Details
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <FormControl required fullWidth>
                  <InputLabel variant="standard" htmlFor="payout_type">
                    Payout Type
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'payout_type',
                      id: 'payout_type'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('payout_type')}
                    error={Boolean(touched.payout_type && errors.payout_type)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Payout Type
                    </MenuItem>
                    <MenuItem value="CTC">CTC</MenuItem>
                    <MenuItem value="PPD">Pay Per Day</MenuItem>
                    <MenuItem value="PPH">Pay Per Hour</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField
                  variant="standard"
                  fullWidth
                  disabled={values?.payout_type?.length === 0}
                  type="text"
                  label="Take Home Amount"
                  autoComplete="off"
                  required
                  {...getFieldProps('payout_amount')}
                  error={Boolean(touched.payout_amount && errors.payout_amount)}
                  helperText={touched.payout_amount && errors.payout_amount}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <NumbersIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="UAN"
                  autoComplete="off"
                  required
                  {...getFieldProps('uan')}
                  error={Boolean(touched.uan && errors.uan)}
                  helperText={touched.uan && errors.uan}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <NumbersIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="PF No."
                  autoComplete="off"
                  {...getFieldProps('epf_no')}
                  error={Boolean(touched.epf_no && errors.epf_no)}
                  helperText={touched.epf_no && errors.epf_no}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <NumbersIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="ESI No."
                  autoComplete="off"
                  {...getFieldProps('esi_no')}
                  error={Boolean(touched.esi_no && errors.esi_no)}
                  helperText={touched.esi_no && errors.esi_no}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={6}>
              <Stack sx={{ height: 65 }} spacing={2} direction="row" alignItems="center">
                <Typography sx={{ mt: 1 }}>Enable PF</Typography>
                <Switch {...label} {...getFieldProps('enroll_pf')} />
              </Stack>
            </Grid>
            <Grid item md={3} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField
                  variant="standard"
                  disabled={!values.enroll_pf}
                  // sx={{ width: 40 }}
                  type="text"
                  label="PF Contribution"
                  autoComplete="off"
                  {...getFieldProps('pf_employee_contribution')}
                  error={Boolean(
                    touched.pf_employee_contribution && errors.pf_employee_contribution
                  )}
                  helperText={touched.pf_employee_contribution && errors.pf_employee_contribution}
                />
                <Typography>%</Typography>
              </Box>
            </Grid>
            {/* <Grid item md={3} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField
                      variant="standard"
                      // sx={{ width: 40 }}
                      type="number"
                      max={values.pf_employee_contribution}
                      autoComplete="off"
                      label="EPS Employer Contribution"
                      {...getFieldProps('eps_employer_contribution')}
                      error={Boolean(
                        touched.eps_employer_contribution && errors.eps_employer_contribution
                      )}
                      helperText={
                        touched.eps_employer_contribution && errors.eps_employer_contribution
                      }
                    />
                    <Typography>%</Typography>
                  </Box>
                </Grid> */}
            <Grid xs={12} />
            <Grid item md={4} xs={12}>
              {/* Payroll Template */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <StickyNote2Icon sx={{ color: 'action.active', mr: 1 }} />
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="payroll_template">
                    Payroll Template
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'payroll_template',
                      id: 'payroll_template'
                    }}
                    required
                    disabled={!(values?.role_type?.length > 0)}
                    sx={{ mt: 2 }}
                    {...getFieldProps('payroll_template')}
                    error={Boolean(touched.payroll_template && errors.payroll_template)}
                    helperText={
                      (touched.payroll_template && errors.payroll_template) ??
                      'Select Role to choose template'
                    }
                  >
                    <MenuItem value="" disabled>
                      Payroll Template
                    </MenuItem>
                    {payrollTemplateOptions?.length > 0 ? (
                      payrollTemplateOptions.map(([key, value]) => (
                        <MenuItem
                          style={{ padding: '0.5rem' }}
                          key={`${'payroll-template-option'}-${key}`}
                          value={key}
                        >
                          {value}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled />
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Button
                sx={{ mt: 2 }}
                onClick={async () => {
                  const data = {
                    today: getCurrentDate(),
                    company: values.company,
                    template: values.payroll_template,
                    payout_type: values.payout_type,
                    payout_amount: values.payout_amount,
                    pf_settings: {
                      enable: values.enroll_pf,
                      // pf_type: values.pf_type,
                      // pf_employer_contribution: values.pf_employer_contribution,
                      pf_employee_contribution: values.pf_employee_contribution
                    }
                  };
                  if (
                    values.company &&
                    values.payroll_template &&
                    values.payout_type &&
                    values.payout_amount &&
                    values.pf_employee_contribution
                  ) {
                    setCalculateErrors([]);
                  }
                  if (!values.company) {
                    setCalculateErrors((prev) => [...prev, 'Company']);
                  }
                  if (!values.payroll_template) {
                    setCalculateErrors((prev) => [...prev, 'Payroll template']);
                  }
                  if (!values.payout_type) {
                    setCalculateErrors((prev) => [...prev, 'Payout type']);
                  }
                  if (!values.payout_amount) {
                    setCalculateErrors((prev) => [...prev, 'Payout amount']);
                  }
                  if (!values.enroll_pf) {
                    setCalculateErrors((prev) => [...prev, 'Enable PF']);
                  }
                  if (!values.pf_employee_contribution) {
                    setCalculateErrors((prev) => [...prev, 'PF contribution']);
                  }
                  // console.log('Data to be send', data);
                  const result = await calculatePayslip(data);
                  console.log(result?.payslip);
                  setPayslipData(result?.payslip);
                }}
                size="small"
                variant="contained"
              >
                StackUp Sheet
              </Button>
            </Grid>
            {calculateErrors.length > 0 && (
              <Grid item xs={12}>
                <Alert severity="error">
                  To calculate payslip&nbsp;
                  {calculateErrors.map((errs, idx) => (
                    <Typography
                      sx={{ fontSize: 'inherit' }}
                      component="span"
                      key={`cal-error-${idx}`}
                    >
                      {errs}
                      {idx < calculateErrors.length - 1 ? ', ' : ''}
                    </Typography>
                  ))}
                  &nbsp;{calculateErrors.length > 0 ? 'are' : 'is'} required
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              {payslipData !== undefined && <PaySlipTemplate short data={payslipData} />}
            </Grid>
          </Grid>
          <Typography>{formik.isSubmitting}</Typography>
          <LoadingButton
            type="button"
            variant="contained"
            sx={{ m: 2 }}
            loading={formik.isSubmitting}
            onClick={() => {
              formik.setSubmitting(true);
              formik.handleSubmit();
            }}
          >
            Submit
          </LoadingButton>
        </Box>
        <FocusError />
      </Form>
    </FormikProvider>
  );
}

EmployeeForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  step: PropTypes.number
};

export default memo(EmployeeForm);
