/* eslint-disable func-names */
import '../../../index.css';
import { memo, useMemo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useDebounce } from 'use-debounce';
import { useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider, Form } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import InfiniteScroll from 'react-infinite-scroll-component';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
// icons
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PersonIcon from '@mui/icons-material/Person';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CloseIcon from '@mui/icons-material/Close';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import AddIcon from '@mui/icons-material/Add';
// config
import {
  ADD_EMPLOYEE
  // COMPANY_PLACEHOLDER_LOGO
} from '../../../utils/config';
// hooks
import { fetchSiteManagers } from '../../../api/queries/Queries';
import { addSite } from '../../../api/mutations/adminMutations';
// utils
import { getBase64, capitalizeEachWord } from '../../../utils/functions';
// redux
import { logOut } from '../../../redux/actions/adminActions';
// import INDIAN from '../../../utils/INDIAN_STATES.json'; // importing indian states and uts
import { countries } from '../../../utils/countries';

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250
//     }
//   }
// };

// const gender = ['Male', 'Female', 'InterSex', 'Trans'];

function AddSite({ open, onClose }) {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const { info } = useSelector((state) => state.adminInfo);
  // const [personName, setPersonName] = useState([]);
  // const [showPassword, setShowPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [roleOptions, setRoleOptions] = useState(); // Roles initial State for select
  const [managerOptions, setManagerOptions] = useState([]);
  // const [submitClicked, setSubmitClicked] = useState(false);
  // const [, setpictureTouched] = useState(false); // pictureTouched
  const [siteManagerSearch, setSiteManagerSearch] = useState();
  const [addConstraints, setAddConstraints] = useState(false); // Add Constraints
  const { _siteManagerSearch } = useDebounce(siteManagerSearch, 1000);

  // For Image Size : 2MB , Supported Formats
  // const FILE_SIZE = 2 * 1024 * 1024;
  // const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

  /* ===================== Roles ===================== */
  // const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  // const allRoles = useMemo(() => {
  //   if (!isRolesLoading && !isRolesError) {
  //     return roles.heirarchy;
  //   }
  //   return ''; // Needs to be changed
  // }, [roles, isRolesLoading, isRolesError]);

  // useEffect(() => {
  //   const formattedRoles = [];
  //   if (allRoles?.length > 0) {
  //     allRoles.forEach((role) => formattedRoles.push([role._id, role.name]));
  //     formattedRoles.pop();
  //     setRoleOptions([...formattedRoles]);
  //     // console.log('Role Options :', formattedRoles);
  //   }
  // }, [allRoles]);
  // /* ================= End Roles =============== */

  const { mutate } = useMutation(addSite);

  // Manager Id if loggedin user is a site manager
  const managerId =
    info?.role?.priority === info?.site_manager_priority && !info?.isMainAdmin ? info?._id : '';
  // console.log('Manager ID:', managerId);

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobile: '',
      country: '91',
      site_category: '',
      fence_radius: 100,
      profilePicture: '',
      // role_type: '',
      manager_type: managerId,
      work_days_per_week: 5,
      work_hours_per_day: 9,
      site_weekly_working_days: '',
      site_weekly_days_off: []
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Site Name is required')
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9\s\-&.']*[a-zA-Z0-9]+$/,
          "Invalid client name. ( Minimum 2 characters, must start & end with alphanumeric characters, allowed special characters  - & . ' )"
        ),
      email: Yup.string().email('Invalid email address').notRequired(),
      mobile: Yup.string()
        .required('Mobile number is required')
        .matches(/^[6-9]\d{9}$/, 'Invalid mobile number'),
      country: Yup.string().required('Country Code is Required'),
      // workforce_required: Yup.string().required('Minimum workforce is required'),
      fence_radius: Yup.number().required('This Field is Required').min(100).max(1000),
      // role_type: Yup.string().required('This Field is required'),
      manager_type: Yup.string().required('This Field is required'),
      profilePicture: Yup.string().notRequired(),
      site_weekly_working_days: Yup.number().required('This Field is required'),
      site_weekly_days_off: Yup.array().test({
        name: 'max',
        exclusive: false,
        params: {},
        // eslint-disable-next-line no-template-curly-in-string
        // eslint-disable-next-line react/no-this-in-sfc
        // message: `DayOff ${Yup.ref('site_weekly_working_days').getValue()}must be given`,
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          // You can access the price field with `this.parent`.
          // eslint-disable-next-line react/no-this-in-sfc
          const swd = this.parent.site_weekly_working_days;
          const diff = 7 - swd;
          const des = value.length === diff;
          // eslint-disable-next-line no-unneeded-ternary
          if (swd) {
            if (des) {
              return des;
            }
            // eslint-disable-next-line react/no-this-in-sfc
            return this.createError({
              message: `${diff} Day Off must be given`,
              path: 'site_weekly_days_off'
            });
          }
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            message: `Please select weekly working days for a site`,
            path: 'site_weekly_days_off'
          });
        }
      }),
      work_days_per_week: Yup.number().test({
        name: 'max',
        exclusive: false,
        params: {},
        // eslint-disable-next-line no-template-curly-in-string
        // eslint-disable-next-line react/no-this-in-sfc
        // message: `DayOff ${Yup.ref('site_weekly_working_days').getValue()}must be given`,
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          // You can access the price field with `this.parent`.
          // eslint-disable-next-line react/no-this-in-sfc
          const swd = this.parent.site_weekly_working_days;
          const des = value <= swd;
          // eslint-disable-next-line no-unneeded-ternary
          if (swd) {
            if (des) {
              return des;
            }
            // eslint-disable-next-line react/no-this-in-sfc
            return this.createError({
              message: `Employee working days must be less than or equal to ${swd}`,
              path: 'work_days_per_week'
            });
          }
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            message: `Please select weekly working days for a site`,
            path: 'work_days_per_week'
          });
        }
      })
    }),
    onSubmit: (values, actions) => {
      actions.setStatus(null);
      const data = {
        name: capitalizeEachWord(values.name),
        email: values.email.toLowerCase(),
        mobile: values.mobile,
        country_code: values.country,
        fence_radius: values.fence_radius,
        manager: values.manager_type,
        category: values.site_category ?? undefined,
        image: values.profilePicture,
        constraints: {
          site_weekly_working_days: values.site_weekly_working_days,
          employee_weekly_working_days: values.work_days_per_week,
          daily_working_hours: values.work_hours_per_day,
          weekly_working_hours: values.work_days_per_week * values.work_hours_per_day,
          weekly_off: values.site_weekly_days_off
        }
      };

      // console.log('Site Add Form :', data);

      mutate(data, {
        onMutate: () => {
          Swal.fire({
            icon: 'info',
            title: 'Hold on....',
            text: 'Adding new site 😃',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading();
            }
          });
        },
        onError: (error) => {
          onClose();
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          onClose();
          actions.setSubmitting(false);
          document.getElementById('profilePicture').value = null;
          resetForm();
          values.profilePicture = '';
          Swal.fire({
            icon: 'success',
            title: 'Site added!',
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          cache.invalidateQueries('sites');
        }
      });
    }
  });

  const { errors, touched, values, isSubmitting, getFieldProps, handleSubmit, resetForm } = formik;

  const {
    isLoading: isSiteManagersLoading,
    isError: isSiteManagersError,
    data: siteManagers,
    fetchNextPage: fetchNextSiteManagers,
    hasNextPage: hasNextSiteManagers
  } = useInfiniteQuery(
    ['site-managers', _siteManagerSearch, null],
    fetchSiteManagers,
    {
      // enabled: !!values.role_type,
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        // console.log("Error :", error)
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    } // gettings sitemanagers
  );
  const SiteManagers = useMemo(() => {
    const listOfSiteManagers = [];
    // console.log("Add Site Managers :", siteManagers);
    if (!isSiteManagersLoading && !isSiteManagersError) {
      if (siteManagers?.pages?.length > 0) {
        siteManagers.pages.forEach((group) => listOfSiteManagers.push(group.employees));
      }
    }
    const flattened = listOfSiteManagers.flat();
    // console.log('Manager Options:', siteManagers);
    return flattened;
  }, [siteManagers, isSiteManagersLoading, isSiteManagersError]);

  useEffect(() => {
    const formattedEmployees = [];

    if (SiteManagers?.length > 0) {
      SiteManagers.forEach((manager) =>
        formattedEmployees.push([manager._id, manager.name, manager.avatar])
      );
    }
    // console.log(formattedEmployees);
    setManagerOptions([...formattedEmployees]);
  }, [SiteManagers]);

  /* ================= End Managers =============== */
  return (
    <Modal
      open={open ?? false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 1,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          {/* <img src={ADD_EMPLOYEE} width="40" height="40" alt="edit_icon" /> */}
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            Add Site
          </Typography>
          <IconButton
            onClick={() => onClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <FormikProvider value={formik}>
          <Form id="add-employee-form" autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack
              className="custom_scrollbar"
              spacing={1}
              height="calc(100vh - 135px)"
              sx={{ px: 2, py: 1, overflowY: 'scroll' }}
            >
              {managerOptions.length === 0 && !info?.role?.is_site_manager && (
                <Alert severity="info">
                  Site Can't be added, as no manager is available, Please assign atleast one manager
                </Alert>
              )}
              <Stack spacing={2} sx={{ display: `${addConstraints ? 'none' : ''}` }}>
                {/* Profile Picture */}
                <Box sx={{ display: 'flex', mt: 1, alignItems: 'flex-end' }}>
                  <InsertPhotoIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <Stack>
                    <InputLabel
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}
                      variant="standard"
                      htmlFor="profilePicture"
                    >
                      <Button variant="raised" component="span">
                        {!values.profilePicture && <AddIcon />} Site Picture
                      </Button>
                      &nbsp;
                      {values.profilePicture && <CheckCircleIcon sx={{ color: 'green' }} />}
                    </InputLabel>
                    {errors.profilePicture && (
                      <Typography sx={{ color: 'red', fontSize: 12 }}>
                        {errors.profilePicture}
                      </Typography>
                    )}
                  </Stack>
                  <TextField
                    variant="standard"
                    sx={{ display: 'none' }}
                    fullWidth
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    id="profilePicture"
                    name="profilePicture"
                    // eslint-disable-next-line prefer-destructuring
                    onChange={async (event) => {
                      values.profilePicture = await getBase64(event.currentTarget.files[0]);
                      if (values.profilePicture) {
                        delete errors.profilePicture;
                      }
                    }}
                  />
                </Box>
                {/* Name */}
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <TextField
                    variant="standard"
                    fullWidth
                    type="text"
                    label="Site Name"
                    autoComplete="off"
                    required
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Box>
                {/* Email */}
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <EmailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <TextField
                    variant="standard"
                    fullWidth
                    type="text"
                    label="Email"
                    autoComplete="off"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Box>
                {/* Mobile */}
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <ContactPhoneIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <FormControl sx={{ width: 100 }}>
                    <InputLabel id="mobile-country-select-label">Country</InputLabel>
                    <Select
                      labelId="mobile-country-select-label"
                      id="mobile-country-select"
                      variant="standard"
                      label="Country"
                      {...getFieldProps('country')}
                    >
                      <MenuItem value="" disabled>
                        Choose Country
                      </MenuItem>
                      {countries.map((c) => (
                        <MenuItem key={c.code} value={c.phone}>
                          <img
                            loading="lazy"
                            width="23"
                            src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                            alt={`Flag of ${c.label}`}
                          />
                          &nbsp; +{c.phone}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    variant="standard"
                    sx={{ width: 160 }}
                    type="number"
                    label="Mobile ( +91 )"
                    autoComplete="off"
                    required
                    {...getFieldProps('mobile')}
                    error={Boolean(touched.mobile && errors.mobile)}
                    // helperText={touched.mobile && errors.mobile}
                  />
                </Box>
                {errors.mobile && (
                  <Typography align="center" sx={{ color: 'red', fontSize: 12 }}>
                    {errors.mobile}
                  </Typography>
                )}
                {/*  Geo Fence Radius */}
                <Box sx={{ display: 'flex', mt: 1, alignItems: 'flex-end' }}>
                  <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <TextField
                    variant="standard"
                    fullWidth
                    type="number"
                    inputProps={{ min: 100, max: 1000 }}
                    label="Geo Fence Radius (In meters)"
                    autoComplete="off"
                    required
                    {...getFieldProps('fence_radius')}
                    error={Boolean(touched.fence_radius && errors.fence_radius)}
                    helperText={touched.fence_radius && errors.fence_radius}
                  />
                </Box>
                {/* Role
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl fullWidth>
                  <InputLabel variant="standard" required htmlFor="role_type">
                    Choose Manager Role
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'role_type',
                      id: 'role_type'
                    }}
                    sx={{ mt: 2 }}
                    {...getFieldProps('role_type')}
                    error={Boolean(touched.role_type && errors.role_type)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Choose Role
                    </MenuItem>
                    {roleOptions?.length > 0 ? (
                      roleOptions.map(([key, value, image]) => (
                        <MenuItem key={`${'role_type'}-${key}`} value={key} data-icon={image}>
                          {value}
                        </MenuItem>
                      ))
                    ) : (
                      <>
                        <MenuItem value="" disabled />
                      </>
                    )}
                  </Select>
                </FormControl>
                </Box> */}
                {/* Manager */}
                {(info?.role?.priority < info?.site_manager_priority || info?.isMainAdmin) && (
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <FormControl fullWidth>
                      <InputLabel variant="standard" required htmlFor="manager_type">
                        Choose Manager
                      </InputLabel>
                      <Select
                        variant="standard"
                        inputProps={{
                          name: 'manager_type',
                          id: 'manager_type'
                        }}
                        disabled={!(managerOptions.length > 0)}
                        required
                        sx={{ mt: 2, '& .MuiSelect-select': { display: 'flex' } }}
                        {...getFieldProps('manager_type')}
                        error={Boolean(touched.manager_type && errors.manager_type)}
                        // helperText={touched.policy_role && errors.policy_role}
                      >
                        <MenuItem value="" disabled>
                          Choose Manager
                        </MenuItem>
                        <ClickAwayListener onClickAway={() => null}>
                          <TextField
                            fullWidth
                            sx={{ pl: 1 }}
                            variant="standard"
                            placeholder="Search..."
                            onChange={(e) => {
                              setSiteManagerSearch(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              // Prevent MUI-Autoselect while typing
                              e.stopPropagation();
                            }}
                          />
                        </ClickAwayListener>
                        {managerOptions?.length > 0 ? (
                          managerOptions.map(([key, value, image]) => (
                            <MenuItem
                              style={{ padding: '0.5rem' }}
                              key={`${'manager_type'}-${key}`}
                              value={key}
                              data-icon={image}
                            >
                              <Avatar sx={{ width: 24, height: 24 }} src={image} />
                              <Stack sx={{ ml: 1 }}>
                                <Typography>{value}</Typography>
                              </Stack>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" disabled />
                        )}
                      </Select>
                      {/* Put the scroll bar always on the bottom */}
                      <InfiniteScroll
                        dataLength={managerOptions?.length}
                        next={fetchNextSiteManagers}
                        // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
                        // inverse //
                        hasMore={hasNextSiteManagers}
                        // loader={<MenuItem>Loading...</MenuItem>}
                        scrollableTarget="manager_type"
                        // endMessage={
                        //   <p style={{ textAlign: 'center' }}>
                        //     <b>Yay! You have seen it all</b>
                        //   </p>
                        // }
                      />
                    </FormControl>
                  </Box>
                )}
                <Typography align="center" fontSize="0.8rem" color="red">
                  {errors.manager_type && touched.manager_type}
                </Typography>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => setAddConstraints(true)}
                >
                  Constraints
                </Button>
                {errors.site_weekly_days_off && (
                  <Typography align="center" sx={{ fontSize: 12, color: 'red' }}>
                    Constraints are Required
                  </Typography>
                )}
                <br />
              </Stack>
              <Stack sx={{ display: `${addConstraints ? '' : 'none'}` }}>
                <Stack direction="row" alignItems="center">
                  <IconButton onClick={() => setAddConstraints(false)}>
                    <ArrowBackIcon />
                  </IconButton>
                  &emsp;
                  <Typography sx={{ color: '#0897FF' }}>
                    <b>All Constraints</b>
                  </Typography>
                </Stack>
                <Divider />
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Site Weekly Working Days</TableCell>
                      <TableCell sx={{ minWidth: 40 }}>
                        <FormControl fullWidth>
                          <Select
                            variant="standard"
                            sx={{ width: 70 }}
                            // inputProps={{
                            //   name: 'role_type',
                            //   id: 'role_type'
                            // }}
                            {...getFieldProps('site_weekly_working_days')}
                            // error={Boolean(touched.role_type && errors.role_type)}
                            // helperText={touched.policy_role && errors.policy_role}
                          >
                            <MenuItem value="" disabled>
                              Days
                            </MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Site Weekly Days Off</TableCell>
                      <TableCell sx={{ minWidth: 60, maxWidth: 70 }}>
                        <FormControl fullWidth>
                          <Select
                            variant="standard"
                            multiple
                            sx={{ minWidth: 70 }}
                            disabled={values.site_weekly_working_days === 7}
                            // inputProps={{
                            //   name: 'role_type',
                            //   id: 'role_type'
                            // }}
                            {...getFieldProps('site_weekly_days_off')}
                            error={Boolean(
                              touched.site_weekly_days_off && errors.site_weekly_days_off
                            )}
                            // helperText={touched.policy_role && errors.policy_role}
                          >
                            <MenuItem value="" disabled>
                              Days
                            </MenuItem>
                            <MenuItem value={0}>Sunday</MenuItem>
                            <MenuItem value={1}>Monday</MenuItem>
                            <MenuItem value={2}>Tuesday</MenuItem>
                            <MenuItem value={3}>Wednesday</MenuItem>
                            <MenuItem value={4}>Thrusday</MenuItem>
                            <MenuItem value={5}>Friday</MenuItem>
                            <MenuItem value={6}>Saturday</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {errors?.site_weekly_days_off && (
                      <TableRow>
                        <TableCell colSpan="2" align="center">
                          <Typography component="span" sx={{ fontSize: 11, color: 'red' }}>
                            {errors?.site_weekly_days_off}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>
                        Employee Working
                        <br /> days / week
                      </TableCell>
                      <TableCell sx={{ minWidth: 40 }}>
                        <FormControl fullWidth>
                          <Select
                            variant="standard"
                            // inputProps={{
                            //   name: 'role_type',
                            //   id: 'role_type'
                            // }}
                            {...getFieldProps('work_days_per_week')}
                            // error={Boolean(touched.role_type && errors.role_type)}
                            // helperText={touched.policy_role && errors.policy_role}
                          >
                            <MenuItem value="" disabled>
                              Days
                            </MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {errors?.work_days_per_week && (
                      <TableRow>
                        <TableCell colSpan="2" align="center">
                          <Typography component="span" sx={{ fontSize: 11, color: 'red' }}>
                            {errors?.work_days_per_week}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>
                        Employee Working
                        <br /> hours / day
                      </TableCell>
                      <TableCell sx={{ minWidth: 40 }}>
                        <FormControl fullWidth>
                          <Select
                            variant="standard"
                            // inputProps={{
                            //   name: 'role_type',
                            //   id: 'role_type'
                            // }}
                            {...getFieldProps('work_hours_per_day')}
                            // error={Boolean(touched.role_type && errors.role_type)}
                            // helperText={touched.policy_role && errors.policy_role}
                          >
                            <MenuItem value="" disabled>
                              Hours
                            </MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Working hours / week</TableCell>
                      <TableCell sx={{ minWidth: 40, maxWidth: 40 }}>
                        <FormControl fullWidth>
                          <TextField
                            readOnly
                            variant="standard"
                            value={values.work_days_per_week * values.work_hours_per_day}
                          />
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Stack>
            </Stack>
            <Stack
              sx={{ position: 'absolute', backgroundColor: 'white', bottom: 0, px: 2, pb: 1.2 }}
            >
              <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                Submit
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Modal>
  );
}

AddSite.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default memo(AddSite);
