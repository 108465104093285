/* eslint-disable no-nested-ternary */
import React, { useState, useMemo } from 'react';
import { useDebounce } from 'use-debounce';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import LinearProgress from '@mui/material/LinearProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
// icons
import InfoIcon from '@mui/icons-material/Info';
import AndroidIcon from '@mui/icons-material/Android';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ContactsIcon from '@mui/icons-material/Contacts';
import {
  AttendanceListHead,
  AttendanceListToolbar,
  AttendanceMoreMenu
} from '../components/_dashboard/attendance';
import ImageZoom from '../components/ImageZoom';
// components
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
// hooks
import { useNormalAttendance } from '../hooks/get-attendence-query';
// utils
import { _12hourTimeFormat, DD_MM_YYYY, convertUTCtoLocal } from '../utils/functions';

// ---------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'employee', label: 'Employee', alignRight: false },
  { id: 'attendances', label: 'Attendances', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// --------------------------------------------------------------

function Attendance() {
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [src, setSrc] = useState();
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [search] = useDebounce(filterName, 1200); // Debounce value 1.2 sec
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [filter, setFilter] = useState({
    from: new Date()
    // to: '',
  });

  const {
    isAttendanceLoading,
    isAttendanceError,
    data: attendance,
    fetchNextPage: fetchNextAttendance,
    hasNextPage: hasNextAttendance
    // refetch: refetchAttendace,
  } = useNormalAttendance(convertUTCtoLocal(filter.from, true), search);

  const rows = useMemo(() => {
    const listOfAttendance = [];
    if (!isAttendanceLoading && !isAttendanceError) {
      if (attendance?.pages?.length > 0) {
        attendance.pages.forEach((group) => listOfAttendance.push(group.employees));
      }
    }
    const flattened = listOfAttendance.flat();
    return flattened;
  }, [attendance, isAttendanceLoading, isAttendanceError]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const filteredUsers = rows;

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Attendance | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1.5}>
          <Typography variant="h4" gutterBottom>
            Attendance
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => setOpenAddEmployee(true)}
          >
            title
          </Button> */}
        </Stack>
        <Card sx={{ boxShadow: 'none' }}>
          <AttendanceListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
            filters={filter}
          />

          <TableContainer
            sx={{
              minWidth: 800,
              minHeight: 'calc(100vh - 275px)',
              maxHeight: 'calc(100vh - 275px)'
            }}
          >
            <Table>
              <AttendanceListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {isAttendanceLoading && (
                  <TableRow>
                    <TableCell colSpan="9">
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )}
                {isAttendanceError && (
                  <TableRow>
                    <TableCell>Something Went Wrong</TableCell>
                  </TableRow>
                )}
                {!isAttendanceLoading &&
                  filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row?.name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row?._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                          </TableCell> */}
                          <TableCell>{(index += 1)}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar
                                alt={row?.name}
                                onClick={() => setSrc(row?.avatar)}
                                src={row?.avatar}
                                sx={{ cursor: 'pointer' }}
                              />
                              <Stack>
                                <Typography variant="subtitle2" noWrap>
                                  {row?.name}
                                </Typography>
                                <Box>
                                  <Typography sx={{ color: '#3366FF', fontSize: 11 }}>
                                    <b>{row.role.name}</b>
                                  </Typography>
                                  {row?.subrole ? (
                                    <Typography sx={{ color: '#DC7633', fontSize: 11 }}>
                                      <b>{row.subrole.name}</b>
                                    </Typography>
                                  ) : null}
                                </Box>
                              </Stack>
                              {/* <InfoIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={(event) =>
                                  setAnchorEl({
                                    id: `info_${row?._id}`,
                                    target: event.currentTarget
                                  })
                                }
                              /> */}
                              {/* <Popover
                                id={`info_${row?._id}`}
                                open={anchorEl.id === `info_${row?._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <List>
                                  <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <PhoneAndroidIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`+91 ${row?.employee?.contact}`} />
                                    </ListItemButton>
                                  </ListItem>
                                  {/* <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <AlternateEmailIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`${row?.email}`} />
                                    </ListItemButton>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <LocationCityIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`${row?.state}`} />
                                    </ListItemButton>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemButton component="a" href="#simple-list">
                                      <ListItemIcon>
                                        <ContactsIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`${row?.address}`} />
                                    </ListItemButton>
                                  </ListItem>
                                </List>
                              </Popover> */}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {row?.attendances?.length > 0 ? (
                              row?.attendances?.map((att, index) => (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={3}
                                  key={att?._id}
                                >
                                  {row?.attendances.length > 0 && (
                                    <Typography>{index + 1}.</Typography>
                                  )}
                                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                    IN: {att?.in?.time ? _12hourTimeFormat(att?.in?.time) : 'N/A'}
                                    &nbsp;
                                    {att?.in?.time && att?.platform?.in === 'web' ? (
                                      <LanguageIcon sx={{ fontSize: 18 }} />
                                    ) : (
                                      <AndroidIcon sx={{ fontSize: 18 }} />
                                    )}
                                  </Typography>
                                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                    OUT:{' '}
                                    {att?.out?.time ? _12hourTimeFormat(att?.out?.time) : 'N/A'}
                                    &nbsp;
                                    {att?.out?.time && att?.platform?.out === 'web' ? (
                                      <LanguageIcon sx={{ fontSize: 18 }} />
                                    ) : (
                                      <AndroidIcon sx={{ fontSize: 18 }} />
                                    )}
                                  </Typography>
                                  {att?.shift && (
                                    <Typography>
                                      Shift: {att?.shift?.name}&nbsp;
                                      <Typography component="span">
                                        (&nbsp;
                                        {`${
                                          att?.shift?.start
                                            ? _12hourTimeFormat(att?.shift?.start)
                                            : 'N/A'
                                        }
                                    - ${
                                      att?.shift?.end ? _12hourTimeFormat(att?.shift?.end) : 'N/A'
                                    }`}
                                        &nbsp;)
                                      </Typography>
                                    </Typography>
                                  )}
                                </Stack>
                              ))
                            ) : (
                              <Typography variant="subtitles">
                                {row?.name} not marked attendance Yet
                              </Typography>
                            )}
                          </TableCell>
                          {/* <TableCell align="left">{DD_MM_YYYY(row.date)}</TableCell> */}
                          {/* <TableCell align="center">
                            {row?.in ? (
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Avatar
                                  alt={row?.name}
                                  src={row?.in?.image}
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => setSrc(row?.in?.image)}
                                />
                                <Typography variant="subtitle2" noWrap>
                                  {row?.in?.time ? _12hourTimeFormat(row.in.time) : '-'}
                                </Typography>
                              </Stack>
                            ) : (
                              '-'
                            )}
                          </TableCell> */}
                          {/* <TableCell align="center">
                            {row?.in ? (
                              row?.in?.markedBy?._id !== row?.employee?._id ? (
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  <Avatar
                                    alt={row?.in?.markedBy?.name}
                                    src={row?.in?.markedBy?.avatar}
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {row?.in?.markedBy?.name}
                                  </Typography>
                                </Stack>
                              ) : (
                                'Self'
                              )
                            ) : (
                              '-'
                            )}
                          </TableCell> */}
                          {/* <TableCell align="center">
                            {row?.out ? (
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Avatar
                                  alt={row?.name}
                                  src={row?.out?.image}
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => setSrc(row?.out?.image)}
                                />
                                <Typography variant="subtitle2" noWrap>
                                  {row?.out?.time ? _12hourTimeFormat(row.out.time) : '_ _ : _ _'}
                                </Typography>
                              </Stack>
                            ) : (
                              '-'
                            )}
                          </TableCell> */}
                          {/* <TableCell align="center">
                            {row?.out ? (
                              row?.out?.markedBy?._id !== row?.employee?._id ? (
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  <Avatar
                                    alt={row?.out?.markedBy?.name}
                                    src={row?.out?.markedBy?.avatar}
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {row?.out?.markedBy?.name}
                                  </Typography>
                                </Stack>
                              ) : (
                                'Self'
                              )
                            ) : (
                              '-'
                            )}
                          </TableCell> */}
                          {/* <TableCell align="right">
                            <AttendanceMoreMenu />
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            {/* Put the scroll bar always on the bottom */}
            <InfiniteScroll
              dataLength={rows?.length}
              next={fetchNextAttendance}
              // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
              // inverse //
              hasMore={hasNextAttendance}
              loader={<h4>Loading...</h4>}
              scrollableTarget="attendance-table-container"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            />
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
      <ImageZoom src={src} onClose={() => setSrc('')} />
    </Page>
  );
}

export default Attendance;
