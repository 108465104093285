/* eslint-disable no-nested-ternary */
import * as React from 'react';
import * as Yup from 'yup';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik, FormikProvider, Form } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { usePayrollSettings } from '../hooks/get-payroll-settings';
import NotAvailable from '../components/NotAvailable';
import { useRoles } from '../hooks/roles';
import { convertUTCtoLocal, getCurrentDate } from '../utils/functions';
import { updatePayrollSettings } from '../api/mutations/adminMutations';
// redux
import { logOut } from '../redux/actions/adminActions';
import { getAdminProfileById } from '../api/queries/profile';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function LabTabs() {
  const cache = useQueryClient();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('1');
  const [esiEffectiveDate, setESIEffectiveDate] = React.useState();
  const [pfEffectiveDate, setPFEffectiveDate] = React.useState();
  // const [roleOptions, setRoleOptions] = React.useState([]);
  const [rolePFSettings, setRolePFSettings] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { isAdminAuthenticated, info } = useSelector((state) => state.adminInfo);

  const {
    isLoading: isProfileLoading,
    isSuccess: isProfileSuccess,
    isError: isProfileError,
    data: profileData
  } = useQuery(
    [`admin-profile`, info?.isMainAdmin ? info?._id : info?.admin?._id],
    () =>
      info?.isMainAdmin ? getAdminProfileById(info?._id) : getAdminProfileById(info?.admin?._id),
    {
      enabled: isAdminAuthenticated ?? false,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = React.useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      const roleSettings = [];
      roles.heirarchy.forEach((h) => {
        roleSettings.push({
          id: h._id
        });
      });
      setRolePFSettings([...roleSettings]);
      return roles.heirarchy;
    }
    return []; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  const {
    isLoading: isSettingsLoading,
    isError: isSettingsError,
    data: payrollSettings
  } = usePayrollSettings();
  // eslint-disable-next-line camelcase
  const { pfSettings, esiSettings, roleSettings } = React.useMemo(() => {
    if (!isSettingsLoading && !isSettingsError) {
      // eslint-disable-next-line camelcase
      const _pfSettings = payrollSettings?.settings?.find((ps) => ps.type === 'epf');
      const _esiSettings = payrollSettings?.settings?.find((es) => es.type === 'esi');
      const _roleSettings = payrollSettings?.settings?.find((ps) => !!ps.role);
      return {
        pfSettings: _pfSettings ?? {},
        esiSettings: _esiSettings ?? {},
        roleSettings: _roleSettings ?? {}
      };
    }
    return ''; // Needs to be changed
  }, [payrollSettings, isSettingsLoading, isSettingsError]);

  // React.useEffect(() => {
  //   const formattedRoles = [];
  //   if (allRoles?.length > 0) {
  //     // console.log(allRoles);
  //     let priority = 0;
  //     allRoles.forEach((role) => {
  //       if (role.is_site_manager) {
  //         priority = role.priority;
  //       }
  //       if (role.priority > priority && priority > 0) {
  //         formattedRoles.push([role._id, role.name]);
  //       } else if (priority === 0 || role.priority === priority) {
  //         formattedRoles.push([role._id, role.name]);
  //       }
  //     });
  //     setRoleOptions([...formattedRoles]);
  //     // console.log('Role Options :', formattedRoles);
  //   }
  // }, [allRoles]);

  // React.useEffect(() => {
  //   console.log(rolePFSettings);
  // }, [rolePFSettings]);

  const { mutate } = useMutation(updatePayrollSettings);

  const formikESI = useFormik({
    enableReinitialize: true,
    initialValues: {
      esi_ceiling_at: esiSettings?.esi_ceiling_at ?? 21000,
      esi_employer_contrib: esiSettings?.employer ?? 3.25,
      esi_employee_contrib: esiSettings?.employee ?? 0.75
    },
    validationSchema: Yup.object({
      esi_ceiling_at: Yup.bool().required('ESI ceiling is required'),
      esi_employer_contrib: Yup.string().required('ESI employer contribution is required'),
      esi_employee_contrib: Yup.string().required('ESI employee contribution is required')
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        settings_type: 'esi',
        esi_effective_date: convertUTCtoLocal(esiEffectiveDate, true),
        esi_ceiling_at: values.esi_ceiling_at,
        esi_employer_contrib: values.esi_employer_contrib,
        esi_employee_contrib: values.esi_employee_contrib
      };

      console.log('ESI Settings Form :', data);

      mutate(data, {
        onMutate: () => {
          Swal.fire({
            icon: 'info',
            title: 'Hold on....',
            text: 'Updating ESI settings 😃',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading();
            }
          });
        },
        onError: (error) => {
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          actions.setSubmitting(false);
          resetFormESI();
          values.profilePicture = '';
          Swal.fire({
            icon: 'success',
            title: 'ESI settings updated!',
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          cache.invalidateQueries('esi-settings');
        }
      });
    }
  });

  const {
    errors: errorsESI,
    touched: touchedESI,
    values: valuesESI,
    // isSubmitting,
    handleSubmit: handleSubmitESI,
    getFieldProps: getFieldPropsESI,
    resetForm: resetFormESI
  } = formikESI;

  const formikPF = useFormik({
    enableReinitialize: true,
    initialValues: {
      pf_enable: profileData?.admin?.pf_settings?.enable ?? false,
      // pf_type: profileData?.admin?.pf_settings?.pf_type ?? '',
      eps_employer_contrib: profileData?.admin?.pf_settings?.eps_employer_contribution ?? '',
      pf_employee_contrib: profileData?.admin?.pf_settings?.pf_employee_contribution ?? '',
      dearness_allowance: profileData?.admin?.pf_settings?.allowance ?? ''
    },
    validationSchema: Yup.object({
      eps_employer_contrib: Yup.string().required(),
      pf_employee_contrib: Yup.string().required(),
      dearness_allowance: Yup.string()
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        settings_type: 'epf',
        enable: values.pf_enable,
        pf_effective_date: getCurrentDate(),
        eps_employer_contrib: values.eps_employer_contrib,
        pf_employee_contrib: values.pf_employee_contrib
      };

      console.log('PF Update Setting :', data);

      mutate(data, {
        onMutate: () => {
          Swal.fire({
            icon: 'info',
            title: 'Hold on....',
            text: 'Updating PF settings 😃',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading();
            }
          });
        },
        onError: (error) => {
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          actions.setSubmitting(false);
          resetFormPF();
          values.profilePicture = '';
          Swal.fire({
            icon: 'success',
            title: 'PF settings updated!',
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          cache.invalidateQueries('pf-settings');
        }
      });
    }
  });

  const {
    errors: errorsPF,
    touched: touchedPF,
    values: valuesPF,
    // isSubmitting,
    handleSubmit: handleSubmitPF,
    getFieldProps: getFieldPropsPF,
    resetForm: resetFormPF
  } = formikPF;

  React.useEffect(() => {
    console.log(errorsPF);
    console.log(errorsPF);
  }, [errorsPF, errorsESI]);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {/* <Tab label="Role Settings" value="1" /> */}
            <Tab label="PF Settings" value="1" />
            <Tab label="ESI Settings" value="2" />
            {/* <Tab label="TDS" value="3" /> */}
            {/* <Tab label="Professional Tax" value="3" /> */}
          </TabList>
        </Box>
        <TabPanel value="1">
          {isProfileLoading ? (
            <NotAvailable type="loading" left msg="PF Settings..." />
          ) : isProfileError ? (
            <NotAvailable type="na" />
          ) : (
            <>
              <Typography sx={{ py: 2 }} variant="h6">
                Global Settings
              </Typography>
              <FormikProvider value={formikPF}>
                <Form id="pf-settings-form" autoComplete="off" noValidate onSubmit={handleSubmitPF}>
                  <Grid container spacing={1}>
                    <Grid item md={2} xs={12}>
                      <Typography sx={{ mt: 2 }}>
                        Ceiling at {profileData?.admin?.pf_settings?.ceiling_at}
                      </Typography>
                    </Grid>
                    {/* <Grid item md={2} xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <FormControl sx={{ width: 120 }}>
                          <InputLabel variant="standard" htmlFor="dearness_allowance">
                            Choose Type
                          </InputLabel>
                          <Select
                            variant="standard"
                            inputProps={{
                              name: 'pf_type',
                              id: 'pf_type'
                            }}
                            {...getFieldPropsPF('pf_type')}
                            error={Boolean(touchedPF.pf_type && errorsPF.pf_type)}
                            // helperText={employeeTouched.policy_role && employeeErrors.policy_role}
                          >
                            <MenuItem value="basic">Basic</MenuItem>
                            <MenuItem value="basic_da">Basic + DA</MenuItem>
                            {/* <MenuItem value="fixed_amount">Fixed Amount</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    {valuesPF.pf_type === 'basic_da' && (
                      <Grid item md={3} xs={12}>
                        <FormControl sx={{ minWidth: 220 }}>
                          <InputLabel variant="standard" htmlFor="dearness_allowance">
                            Choose Allowance
                          </InputLabel>
                          <Select
                            variant="standard"
                            inputProps={{
                              name: 'dearness_allowance',
                              id: 'dearness_allowance'
                            }}
                            required
                            {...getFieldPropsPF('dearness_allowance')}
                            error={Boolean(
                              touchedPF.dearness_allowance && errorsPF.dearness_allowance
                            )}
                            // helperText={touched.policy_role && errors.policy_role}
                          >
                            <MenuItem value="" disabled>
                              Choose Allowance
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )} */}
                    <Grid item md={4} xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          variant="standard"
                          type="text"
                          autoComplete="off"
                          // label=""
                          {...getFieldPropsPF('pf_employee_contrib')}
                          error={Boolean(
                            touchedPF.pf_employee_contrib && errorsPF.pf_employee_contrib
                          )}
                          helperText={
                            (touchedPF.pf_employee_contrib && errorsPF.pf_employee_contrib) ??
                            'PF Employee Contribution'
                          }
                        />
                        <Typography>%</Typography>
                      </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          variant="standard"
                          type="text"
                          // label=""
                          autoComplete="off"
                          {...getFieldPropsPF('eps_employer_contrib')}
                          error={Boolean(
                            touchedPF.eps_employer_contrib && errorsPF.eps_employer_contrib
                          )}
                          helperText={
                            (touchedPF.eps_employer_contrib && errorsPF.eps_employer_contrib) ??
                            'EPS Employer Contribution'
                          }
                        />
                        <Typography>%</Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            {...label}
                            defaultChecked={profileData?.admin?.pf_settings?.enable ?? false}
                            {...getFieldPropsPF('pf_enable')}
                          />
                        }
                        label="Enable"
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <Button sx={{ mt: 3 }} type="submit" variant="contained" size="small">
                      Save
                    </Button>
                  </Grid>
                </Form>
              </FormikProvider>
              <br />
              <Typography sx={{ py: 2 }} variant="h6">
                Role Based Settings
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Role</TableCell>
                    {/* <TableCell>Type</TableCell> */}
                    <TableCell>EPS Employer</TableCell>
                    <TableCell>EPF Employee</TableCell>
                    <TableCell>Enable PF</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isRolesLoading &&
                    allRoles.map((rl, indx) => {
                      const r = rolePFSettings.find((rps) => rps.id === rl._id);
                      const settings = rl.pf_settings;
                      // const sr = roleSettings.find((rps) => rps.role === rl._id);
                      return (
                        <TableRow key={rl._id}>
                          <TableCell>{indx + 1}</TableCell>
                          <TableCell>{rl?.name}</TableCell>
                          {/* <TableCell>
                            <FormControl sx={{ width: 120 }}>
                              <InputLabel variant="standard" htmlFor="dearness_allowance">
                                Choose Type
                              </InputLabel>
                              <Select
                                variant="standard"
                                // inputProps={{
                                //   name: 'pf_type',
                                //   id: 'pf_type'
                                // }}
                                value={r?.pf_type ?? settings?.pf_type ?? ''}
                                onChange={(e) => {
                                  r.pf_type = e.target.value;
                                  setRolePFSettings([...rolePFSettings]);
                                }}
                                // error={Boolean(touchedPF.pf_type && errorsPF.pf_type)}
                                // helperText={employeeTouched.policy_role && employeeErrors.policy_role}
                              >
                                <MenuItem value="basic">Basic</MenuItem>
                                <MenuItem value="basic_da">Basic + DA</MenuItem>
                                {/* <MenuItem value="fixed_amount">Fixed Amount</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell> */}
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <TextField
                                variant="standard"
                                sx={{ width: 40 }}
                                type="text"
                                autoComplete="off"
                                value={
                                  r?.eps_employer_contrib ??
                                  settings?.eps_employer_contribution ??
                                  ''
                                }
                                onChange={(e) => {
                                  r.eps_employer_contrib = e.target.value;
                                  setRolePFSettings([...rolePFSettings]);
                                }}
                              />
                              <Typography>%</Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <TextField
                                variant="standard"
                                sx={{ width: 40 }}
                                type="text"
                                autoComplete="off"
                                value={
                                  r?.pf_employee_contrib ?? settings?.pf_employee_contribution ?? ''
                                }
                                onChange={(e) => {
                                  r.pf_employee_contrib = e.target.value;
                                  setRolePFSettings([...rolePFSettings]);
                                }}
                              />
                              <Typography>%</Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Switch
                              {...label}
                              checked={r?.enable ?? settings?.enable ?? false}
                              onChange={(e) => {
                                r.enable = e.target.checked;
                                setRolePFSettings([...rolePFSettings]);
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => {
                                Swal.fire({
                                  title: 'Are you sure?',
                                  text: `Payroll settings will be Updated`,
                                  icon: 'question',
                                  showCancelButton: true,
                                  confirmButtonText: 'Yes, Proceed!',
                                  allowOutsideClick: false
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    mutate(
                                      {
                                        settings_type: 'epf',
                                        role: rl._id,
                                        enable: r.enable ?? settings?.enable,
                                        eps_employer_contrib:
                                          r.eps_employer_contrib ??
                                          settings?.eps_employer_contribution,
                                        pf_employee_contrib:
                                          r.pf_employee_contrib ??
                                          settings?.pf_employee_contribution
                                        // pf_type: r.pf_type ?? settings?.pf_type
                                      },
                                      {
                                        onMutate: () => {
                                          Swal.fire({
                                            icon: 'info',
                                            title: 'Hold on....',
                                            text: 'Updating ESI settings 😃',
                                            allowOutsideClick: false,
                                            showConfirmButton: false,
                                            willOpen: () => {
                                              Swal.showLoading();
                                            }
                                          });
                                        },
                                        onError: (error) => {
                                          // for unauthorized access
                                          if (error.response && error.response.status === 401) {
                                            dispatch(logOut());
                                          }

                                          const msg = error.response
                                            ? error.response.data.message || error.toString()
                                            : error.toString();

                                          Swal.fire({
                                            icon: 'error',
                                            title: 'Something went wrong!',
                                            text: msg,
                                            showConfirmButton: false,
                                            timer: 2000,
                                            timerProgressBar: true
                                          });
                                        },
                                        onSuccess: (data) => {
                                          Swal.fire({
                                            icon: 'success',
                                            title: 'ROLE EPF settings updated!',
                                            text: data.message,
                                            showConfirmButton: false,
                                            timer: 2000,
                                            timerProgressBar: true
                                          });
                                        },
                                        onSettled: () => {
                                          cache.invalidateQueries('roles');
                                        }
                                      }
                                    );
                                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                                    cache.invalidateQueries('payroll');
                                  }
                                });
                              }}
                              sx={{ my: 2 }}
                              variant="contained"
                              size="small"
                            >
                              Save
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </>
          )}
        </TabPanel>
        <TabPanel value="2">
          <FormikProvider value={formikESI}>
            <Form id="pf-settings-form" autoComplete="off" noValidate onSubmit={handleSubmitESI}>
              <Grid container spacing={1}>
                {/* <Grid item md={6} xs={12}>
                  <Typography>Effective Date</Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label=""
                      value={esiEffectiveDate}
                      disableFuture
                      onChange={(newValue) => {
                        setESIEffectiveDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          fullWidth
                          {...params}
                          error={Boolean(
                            touchedESI.esi_effective_date && errorsESI.esi_effective_date
                          )}
                          helperText={touchedESI.esi_effective_date && errorsESI.esi_effective_date}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid> */}
                {/* <Grid item xs={12}>
                  <Divider />
                </Grid> */}
                <Grid item md={6} xs={12}>
                  <Typography>Monthly Gross Salary Ceiling For ESI is 21000</Typography>
                </Grid>
                {/* <Grid item md={6} xs={12}>
                  <TextField
                    variant="standard"
                    sx={{ minWidth: 100 }}
                    type="number"
                    autoComplete="off"
                    {...getFieldPropsESI('esi_ceiling_at')}
                    error={Boolean(touchedESI.esi_ceiling_at && errorsESI.esi_ceiling_at)}
                    helperText={touchedESI.esi_ceiling_at && errorsESI.esi_ceiling_at}
                  />
                </Grid> */}
                <Grid item xs={12} />
                <Grid item md={6} xs={12}>
                  <Typography>Employer Contribution</Typography>
                </Grid>
                <Grid sx={{ display: 'flex' }} item md={6} xs={12}>
                  {/* <TextField
                    defaultValue={3.25}
                    variant="standard"
                    sx={{ minWidth: 100 }}
                    type="number"
                    autoComplete="off"
                    {...getFieldPropsESI('esi_employer_contrib')}
                    error={Boolean(
                      touchedESI.esi_employer_contrib && errorsESI.esi_employer_contrib
                    )}
                    helperText={touchedESI.esi_employer_contrib && errorsESI.esi_employer_contrib}
                  /> */}
                  <Typography>3.25 % of Gross Salary</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography>Employee Contribution</Typography>
                </Grid>
                <Grid sx={{ display: 'flex' }} item md={6} xs={12}>
                  {/* <TextField
                    defaultValue={}
                    variant="standard"
                    sx={{ minWidth: 100 }}
                    type="number"
                    autoComplete="off"
                    {...getFieldPropsESI('esi_employee_contrib')}
                    error={Boolean(
                      touchedESI.esi_employee_contrib && errorsESI.esi_employee_contrib
                    )}
                    helperText={touchedESI.esi_employee_contrib && errorsESI.esi_employee_contrib}
                  /> */}
                  <Typography> 0.75 % of Gross Salary</Typography>
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
                <br />
              </Grid> */}
              {/* <Grid item md={6}>
                <Button type="submit" variant="contained" size="small">
                  Save
                </Button>
              </Grid> */}
            </Form>
          </FormikProvider>
        </TabPanel>
        {/* <TabPanel value="3">Item Three</TabPanel> */}
        {/* <TabPanel value="3">Item Three</TabPanel> */}
      </TabContext>
    </Box>
  );
}
