import axios from 'axios';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../../redux/actions/adminActions';
import { baseApiURL } from '../../utils/config';
import { getDataFromStorage } from '../../utils/functions';
/**
 * Function to get departments
 */

export const fetchDepartments = async (search) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      search
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/department`, config);
  return data;
};

export const useAllDepartments = (search) => {
  const dispatch = useDispatch();
  return useQuery(['all-departments', search], () => fetchDepartments(search), {
    // getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};
