/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
// import { Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CryptoJS from 'crypto-js';
// import Routes from './routing/Route';
import store from './redux/Store';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

// ----------------------------------------------------------------------

// Create a client for react-query
const queryClient = new QueryClient();
export default function App() {
  useEffect(() => {
    // if user logs out from a different tab, reload the other open tabs to logout user from all the open tabs
    function logOutListener(event) {
      if (event.key === CryptoJS.SHA256('adminAuthToken').toString()) {
        // console.log(event);
        if (event.oldValue || event.newValue) {
          window.location.reload();
        }
      }
    }

    // listen to the storage change for logOut event
    window.addEventListener('storage', logOutListener);

    return function cleanup() {
      window.removeEventListener('storage', logOutListener);
    };
  }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeConfig>
            <ScrollToTop />
            <GlobalStyles />
            <BaseOptionChartStyle />
            <Router />
          </ThemeConfig>
        </LocalizationProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
}
