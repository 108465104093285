import PropTypes from 'prop-types';
// import CryptoJS from 'crypto-js';
import {
  useMemo,
  useEffect
  // useState
} from 'react';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  // Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Tooltip,
  IconButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PieChartIcon from '@mui/icons-material/PieChart';
// import peopleFill from '@iconify/icons-eva/people-fill';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
// components
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import { fetchSiteAttendance } from '../../api/queries/Queries';
import {
  getCurrentDate,
  _12hourTimeFormat,
  convertUTCtoLocal
  // getEncryptionKey
} from '../../utils/functions';
// redux
import { logOut } from '../../redux/actions/adminActions';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

AttendanceSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function AttendanceSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { site } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const today = getCurrentDate();
  // const [shifts, setShifts] = useState();

  const {
    // isAdminAuthenticated,
    info
  } = useSelector((state) => state.adminInfo);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  /* ============================= Fetch All Shifts =============================== */
  const {
    isLoading,
    isError,
    data: siteOverview
  } = useQuery(['site-overview', site, today], () => fetchSiteAttendance(site, today), {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });

  const sidebarConfig = useMemo(() => {
    // console.log('Site Overview', siteOverview);
    const listOfShifts = [];
    if (!isLoading && !isError) {
      if (siteOverview?.site?.shifts.length > 0) {
        siteOverview?.site?.shifts.forEach((shift) => {
          // const cipherData = CryptoJS.AES.encrypt(
          //   JSON.stringify(shift._id),
          //   'my-secret-key@123'
          // ).toString();
          listOfShifts.push({
            title: `${shift?.name}`,
            path: `/attendance/site/${site}/${shift._id}`,
            icon: <PieChartIcon />,
            status: shift?.status,
            info: `${_12hourTimeFormat(shift?.start)} - ${_12hourTimeFormat(shift?.end)}`
          });
        });
      }
    }
    const flattened = listOfShifts.flat();
    // console.log('Side Index :', flattened);
    return flattened.sort((a, b) => (a.status.toLowerCase() > b.status.toLowerCase() ? 1 : -1));
  }, [siteOverview, site, isLoading, isError]);

  /* ============================ Fetch All SHifts End ============================== */

  const renderContent = (
    <Stack>
      <Box sx={{ px: 2.5, py: 3 }}>
        <Stack direction="row" alignItems="center">
          {/* <Tooltip title="Go Back">
            <IconButton component={RouterLink} to="/dashboard/roster">
              <ArrowBackIcon />
            </IconButton>
          </Tooltip> */}
          {/* &emsp; */}
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo size="large" image={info?.avatar} />
          </Box>
        </Stack>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={info?.avatar} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {info?.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {info?.role?.name}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
      {siteOverview?.site?.shifts.length > 0 ? (
        <NavSection navConfig={sidebarConfig} />
      ) : (
        <Stack sx={{ mt: 5, height: '300px' }} justifyContent="center" alignItems="center">
          <Typography>No Shifts Available for this Site</Typography>
        </Stack>
      )}
      <Box sx={{ flexGrow: 1 }} />
    </Stack>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
