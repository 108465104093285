import PieChartIcon from '@mui/icons-material/PieChart';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

// ----------------------------------------------------------------------

const sidebarConfig = [
  {
    title: 'Site',
    path: '/site/profile',
    icon: <PieChartIcon width={22} height={22} />
  },
  {
    title: 'employees',
    path: '/site/employees',
    icon: <PeopleIcon width={22} height={22} />
  },
  {
    title: 'shifts',
    path: '/site/shifts',
    icon: <PersonAddIcon width={22} height={22} />
  },
  {
    title: 'feedback',
    path: '/site/feedback',
    icon: <PeopleIcon width={22} height={22} />
  }
];

export default sidebarConfig;
