/* eslint-disable func-names */
import '../../../index.css';
import { memo, useMemo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useDebounce } from 'use-debounce';
import { useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider, Form } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import InfiniteScroll from 'react-infinite-scroll-component';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
// icons
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
// config
// hooks
import { addPayrollComponent } from '../../../api/mutations/adminMutations';
// utils
import { uid } from '../../../utils/functions';
import { months } from '../../../utils/config';
// redux
import { logOut } from '../../../redux/actions/adminActions';
// import INDIAN from '../../../utils/INDIAN_STATES.json'; // importing indian states and uts

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 'calc(100vw - 20rem)',
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250
//     }
//   }
// };

// const gender = ['Male', 'Female', 'InterSex', 'Trans'];

function CreateComponent({ open, handleClose }) {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const { info } = useSelector((state) => state.adminInfo);
  const [slab, setSlab] = useState([
    {
      id: uid(),
      from: '',
      to: '',
      amount: ''
    }
  ]);

  const { mutate } = useMutation(addPayrollComponent);
  // console.log('Manager ID:', managerId);

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    initialValues: {
      name: '',
      component_type: '',
      calculation_type: '',
      flat: '',
      payout_frequency: '',
      included_in_ctc: false,
      proof_required: false,
      month_due_on: '',
      loan: false,
      taxable: false
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Component Name is required')
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9\s\-&.']*[a-zA-Z0-9]+$/,
          "Invalid client name. ( Minimum 2 characters, must start & end with alphanumeric characters, allowed special characters  - & . ' )"
        )
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        name: values.name,
        component_type: values.component_type,
        calculation_type: values.calculation_type,
        annual_amount: values.annual_amount,
        loan: values.loan,
        payout_frequency: values.payout_frequency,
        included_in_ctc: values.included_in_ctc,
        proof_required: values.proof_required,
        month_due_on: values.month_due_on,
        taxable: values.taxable
      };

      if (data?.calculation_type === 'slab_wise') {
        data.annual_slab = slab;
      }

      console.log('Component Add Form:', data);
      handleClose();

      mutate(data, {
        onMutate: () => {
          Swal.fire({
            icon: 'info',
            title: 'Hold on....',
            text: 'Adding New Component 😃',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading();
            }
          });
        },
        onError: (error) => {
          handleClose();
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          handleClose();
          actions.setSubmitting(false);
          Swal.fire({
            icon: 'success',
            title: 'Sucessfully Created Component!!',
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          cache.invalidateQueries('payroll-components');
        }
      });
    }
  });

  const {
    errors,
    touched,
    values,
    // isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm
  } = formik;

  /* ================= End Managers =============== */
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 1,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          {/* <img src={ADD_EMPLOYEE} width="40" height="40" alt="edit_icon" /> */}
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            Create Component
          </Typography>
          <IconButton
            onClick={() => handleClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <FormikProvider value={formik}>
          <Form id="add-employee-form" autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack
              className="custom_scrollbar"
              spacing={2}
              height="calc(100vh - 135px)"
              sx={{ px: 2, py: 1, overflowY: 'scroll' }}
            >
              {/* Name */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                {/* <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Component Name"
                  autoComplete="off"
                  required
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                {/* <EmailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="component_type">
                    Component Type
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'component_type',
                      id: 'component_type'
                    }}
                    {...getFieldProps('component_type')}
                    // error={Boolean(touched.role_type && errors.role_type)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Component Type
                    </MenuItem>
                    <MenuItem value="allowance">Allowance</MenuItem>
                    <MenuItem value="bonus">Bonus</MenuItem>
                    <MenuItem value="deduction">Deduction</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                {/* <EmailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="payout_frequency">
                    Payout Frequency
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'payout_frequency',
                      id: 'payout_frequency'
                    }}
                    {...getFieldProps('payout_frequency')}
                    // error={Boolean(touched.role_type && errors.role_type)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Payout Frequency
                    </MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="annually">Annually</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {values.payout_frequency === 'annually' && (
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="month_due_on">
                      Due on
                    </InputLabel>
                    <Select
                      variant="standard"
                      inputProps={{
                        name: 'month_due_on',
                        id: 'month_due_on'
                      }}
                      {...getFieldProps('month_due_on')}
                      // error={Boolean(touched.role_type && errors.role_type)}
                      // helperText={touched.policy_role && errors.policy_role}
                    >
                      <MenuItem value="" disabled>
                        Due on
                      </MenuItem>
                      {months.map((month, idx) => (
                        <MenuItem key={`due-${idx}-${month}`} value={month}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
              <Box>
                <FormControlLabel
                  control={<Checkbox {...getFieldProps('included_in_ctc')} />}
                  label=" Included in CTC"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={<Checkbox {...getFieldProps('proof_required')} />}
                  label=" Proof Required"
                />
              </Box>
              {/* <Box>
                <FormControlLabel
                  control={<Checkbox {...getFieldProps('lop_dependant')} />}
                  label=" LOP Dependant"
                />
              </Box> */}
              <Box>
                <FormControlLabel
                  control={<Checkbox {...getFieldProps('taxable')} />}
                  label=" Taxable"
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                {/* <EmailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="calculation_type">
                    Calculation Type
                  </InputLabel>
                  <Select
                    variant="standard"
                    inputProps={{
                      name: 'calculation_type',
                      id: 'calculation_type'
                    }}
                    {...getFieldProps('calculation_type')}
                    // error={Boolean(touched.role_type && errors.role_type)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Calculation Type
                    </MenuItem>
                    <MenuItem value="flat">Flat</MenuItem>
                    <MenuItem value="slab_wise">Slab Wise</MenuItem>
                    <MenuItem value="contribution">Contribution</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {values.calculation_type === 'flat' && (
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField
                    variant="standard"
                    fullWidth
                    type="text"
                    label="Enter Annual Amount"
                    autoComplete="off"
                    required
                    {...getFieldProps('annual_amount')}
                    error={Boolean(touched.annual_amount && errors.annual_amount)}
                    helperText={touched.annual_amount && errors.annual_amount}
                  />
                </Box>
              )}
              {values.calculation_type === 'slab_wise' && (
                <Box>
                  {slab.map((sb) => (
                    <Stack
                      key={`slab-${sb.id}`}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Enter Amount"
                        autoComplete="off"
                        onChange={(e) => {
                          const slb = slab.find((s) => s.id === sb.id);
                          slb.from = e.target.value;
                          setSlab([...slab]);
                        }}
                        error={Boolean(touched.annual_amount && errors.annual_amount)}
                        helperText={touched.annual_amount && errors.annual_amount}
                      />
                      <Typography sx={{ mx: 1, pt: 2 }} align="center">
                        to
                      </Typography>
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Enter Amount"
                        autoComplete="off"
                        onChange={(e) => {
                          const slb = slab.find((s) => s.id === sb.id);
                          slb.to = e.target.value;
                          setSlab([...slab]);
                        }}
                        error={Boolean(touched.annual_amount && errors.annual_amount)}
                        helperText={touched.annual_amount && errors.annual_amount}
                      />
                      <Typography sx={{ mx: 1, pt: 2 }} align="center">
                        =
                      </Typography>
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Enter Amount"
                        autoComplete="off"
                        onChange={(e) => {
                          const slb = slab.find((s) => s.id === sb.id);
                          slb.amount = e.target.value;
                          setSlab([...slab]);
                        }}
                        error={Boolean(touched.annual_amount && errors.annual_amount)}
                        helperText={touched.annual_amount && errors.annual_amount}
                      />
                      <Typography sx={{ mx: 1, pt: 2 }} align="center">
                        /annum
                      </Typography>
                    </Stack>
                  ))}
                  <Button
                    onClick={() =>
                      setSlab([
                        ...slab,
                        {
                          id: uid(),
                          from: '',
                          to: '',
                          amount: ''
                        }
                      ])
                    }
                    variant="contained"
                    startIcon={<AddIcon />}
                  >
                    Add
                  </Button>
                </Box>
              )}
              {values.calculation_type === 'contribution' && (
                <Box>
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <Typography noWrap sx={{ mx: 1, pt: 2, width: '5rem' }} align="center">
                      CTC *
                    </Typography>
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Enter Amount"
                      autoComplete="off"
                      {...getFieldProps('annual_amount')}
                      error={Boolean(touched.annual_amount && errors.annual_amount)}
                      helperText={touched.annual_amount && errors.annual_amount}
                    />
                  </Stack>
                </Box>
              )}
            </Stack>
            <Stack
              sx={{ position: 'absolute', backgroundColor: 'white', bottom: 0, px: 2, pb: 1.2 }}
            >
              <Button type="submit" variant="contained">
                Create
              </Button>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Modal>
  );
}

CreateComponent.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default memo(CreateComponent);
