import { useQuery, useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchRoleManagers } from '../api/queries/Queries';
import { fetchHeirarchy } from '../api/queries/roles';

export const useRoles = (all, token) => {
  const dispatch = useDispatch();
  // console.log('isCalled');
  return useQuery(['heirarchy', all, token], fetchHeirarchy, {
    retry: false,
    onError: (error) => {
      // status: 401 unauthorized
      // console.log(error.response.status);
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export const useStaticRoles = (all) => {
  const dispatch = useDispatch();
  // console.log('isCalled');
  return useQuery(['heirarchy', all], fetchHeirarchy, {
    retry: false,
    refetchOnWindowFocus: false,
    onError: (error) => {
      // status: 401 unauthorized
      // console.log(error.response.status);
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export const useHeirarchy = () => {
  const dispatch = useDispatch();
  // console.log('isCalled');
  return useInfiniteQuery('privileges', fetchHeirarchy, {
    retry: false,
    onError: (error) => {
      // status: 401 unauthorized
      // console.log(error.response.status);
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export const useRoleManagers = (roles) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(['role-managers', roles], fetchRoleManagers, {
    enabled: !!roles,
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};
