import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#0897FF', // #00AB55
  dark: '#0067b3', // #007B55
  darker: '#005249',
  contrastText: '#fff'
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff'
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: '#fff'
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  blue: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  role: {
    main: '#3366FF',
    contrastText: '#fff'
  },
  subrole: {
    main: '#DC7633',
    contrastText: '#fff'
  },
  lightgreen: {
    main: PRIMARY.lighter,
    contrastText: '#33'
  },
  lightred: {
    main: ERROR.lighter,
    contrastText: '#333'
  },
  lightblue: {
    main: INFO.lighter,
    contrastText: '#333'
  },
  lightyellow: {
    main: WARNING.lighter,
    contrastText: '#333'
  },
  lightgrey: {
    main: GREY[500],
    contrastText: '#fff'
  },
  lighterblue: {
    main: '#F0F7FF',
    constrastText: '#fff'
  },
  default_white: {
    main: '#fff',
    contrastText: '#333'
  },
  site_weekly_working_days: {
    main: '#008080',
    constrastText: '#fff'
  },
  employee_weekly_working_days: {
    main: '#A569BD',
    constrastText: '#fff'
  },
  daily_working_hours: {
    main: '#EB984E',
    constrastText: '#fff'
  },
  weekly_working_hours: {
    main: '#EC7063',
    constrastText: '#fff'
  },
  neutral: {
    main: '#64748B',
    contrastText: '#fff'
  },
  rose: {
    main: '#F31275',
    contrastText: '#fff'
  },
  lpurple: {
    main: '#9B59B6',
    contrastText: '#ff'
  },
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: {
    paper: '#fff',
    default: '#fff',
    neutral: GREY[200]
  },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default palette;
