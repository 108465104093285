import * as Yup from 'yup';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { registerAdmin } from '../../../api/mutations/auth';
import { capitalizeEachWord } from '../../../utils/functions';
// import { verifyOTP } from '../../../api/mutations/adminMutations';
// import useMutationFunc from '../../../api/mutations/mutation';
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  // const [isEmailVerified, setIsEmailVerified] = useState(false);
  // const [isVerifying, setIsVerifying] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // const verifyOTPMutation = useMutationFunc(
  //   verifyOTP,
  //   {
  //     onMutate: {
  //       default: false
  //     }
  //   },
  //   () => {
  //     setIsEmailVerified(true);
  //   },
  //   null,
  //   () => {
  //     setIsVerifying(true);
  //   },
  //   () => {
  //     setIsVerifying(false);
  //   }
  // );

  // to send POST request to server using axios & react-query
  const { mutate } = useMutation(registerAdmin, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Registering you on Trakkar 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    }
  });

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Organization name required')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Please enter a valid organization name'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{6,15}$/,
        'Password can only contain alphabets, numbers or special characters'
      ),
    confirmPassword: Yup.string()
      .required('This field is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, actions) => {
      const data = {
        name: capitalizeEachWord(values.name),
        email: values.email.toLowerCase(),
        password: values.password,
        confirmPassword: values.confirmPassword
      };
      // console.log('Register Form :', data);
      mutate(data, {
        onError: (error) => {
          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();
          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong !',
            text: msg
          });
        },
        onSuccess: (data) => {
          actions.setSubmitting(false);
          Swal.fire({
            icon: 'success',
            title: 'Onboarding Success 😃!',
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            willClose: () => navigate('/login', { replace: true })
          });
        }
      });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={1}>
          {/* {isEmailVerified ? (
            <TextField
              fullWidth
              variant="standard"
              label="OTP"
              type="password"
              {...getFieldProps('otp')}
              error={Boolean(touched.otp && errors.otp)}
              helperText={touched.otp && errors.otp}
            />
          ) : ( */}
          <>
            <TextField
              fullWidth
              variant="standard"
              label="Organization name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              variant="standard"
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              variant="standard"
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <TextField
              fullWidth
              variant="standard"
              autoComplete="current-password"
              type={showConfirmPassword ? 'text' : 'password'}
              label="Confirm Password"
              {...getFieldProps('confirmPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowConfirmPassword((prev) => !prev)}>
                      {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />
          </>
          {/* )}
          {isEmailVerified ? ( */}
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
          {/* ) : (
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isVerifying}
              onClick={() =>
                verifyOTPMutation({
                  email: values.email.toLowerCase()
                })
              }
            >
              Sign Up
            </LoadingButton>
          )} */}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
