/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
// import { Icon } from '@iconify/react';
// import * as Yup from 'yup';
// import { sentenceCase } from 'change-case';
import { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { useDebounce } from 'debounce';
import { useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// import plusFill from '@iconify/icons-eva/plus-fill';
// import { Link as RouterLink } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// material
import {
  Card,
  Box,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  // TablePagination,
  Select,
  InputLabel,
  FormControl,
  // TextField,
  LinearProgress,
  MenuItem
} from '@mui/material';
// icons
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
// import ContactsIcon from '@mui/icons-material/Contacts';
// import classes from './styles/ImportEmployees.module.css';
// hooks
// import { useEmployees } from '../hooks/employees';
// import { useRoles } from '../hooks/roles';
import { fetchSiteManagers } from '../api/queries/Queries';
import { useUnmanagedSites } from '../hooks/get-sites-unmanaged';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
import { SiteListHead } from '../components/_dashboard/site';
import { ImportSiteListToolbar } from '../components/_dashboard/import_sites';
import AddSite from '../components/_dashboard/site/AddSite';
// utils
import { SWAL_TIME } from '../utils/config';
// Mutations
import AddEmployee from '../components/_dashboard/employee/AddEmployee';
import UploadSites from '../components/_dashboard/import_sites/UploadSites';
// import { assignPolicy } from '../api/mutations/policy';
import { assignSiteManager } from '../api/mutations/adminMutations';
// redux
import { logOut } from '../redux/actions/adminActions';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'site', label: 'Site', alignRight: false },
  { id: 'assign_manager', label: 'Assign Manager', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ImportEmployees() {
  const [
    page
    // setPage
  ] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [managerOptions, setManagerOptions] = useState([]);
  // const [inMutate, setInMutate] = useState(false);
  // const [isValidating, setIsValidating] = useState(''); // Upon excel validation
  // const [importCheck, setImportCheck] = useState({
  //   importId: getDataFromStorage('importId') ?? null,
  //   importLength: getDataFromStorage('importLength') ?? null
  // });
  // const { info } = useSelector((state) => state.adminInfo);
  const [
    rowsPerPage
    // setRowsPerPage
  ] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  // const [role, setRole] = useState({
  //   id: null,
  //   role: null
  // });
  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const [openAddSite, setOpenAddSite] = useState(false);

  const dispatch = useDispatch();
  const cache = useQueryClient();

  // const selectRoleRef = useRef();

  // const [isImportingSites, setIsImportingSites] = useState(false);
  // const [roleOptions, setRoleOptions] = useState(); // Roles initial State for select
  // const [selectEmployeesOptions, setSelectEmployeesOptions] = useState({}); // Managers initial State for select
  // const [searchValue, setSearchValue] = useState();
  // const { search } = useDebounce(searchValue, 1000); // Debounce value 1 sec

  // const [filter, setFilter] = useState({
  //   doj: '',
  //   state: '',
  //   gender: '',
  //   role: '',
  //   manager: '',
  //   policy: ''
  // });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  /* ===================== Roles ===================== */
  // const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  // const allRoles = useMemo(() => {
  //   if (!isRolesLoading && !isRolesError) {
  //     return roles.heirarchy;
  //   }
  //   return ''; // Needs to be changed
  // }, [roles, isRolesLoading, isRolesError]);

  // useEffect(() => {
  //   const formattedRoles = [];
  //   if (allRoles?.length > 0) {
  //     allRoles.forEach((role) => formattedRoles.push([role._id, role.name]));
  //     setRoleOptions([...formattedRoles]);
  //     // console.log('Role Options :', formattedRoles);
  //   }
  // }, [allRoles]);
  /* ================= End Roles =============== */

  /* ================= Unmanaged Sites ================= */

  const {
    isLoading: isImportSitesLoading,
    isError: isImportSitesError,
    data: unsites,
    fetchNextPage: fetchNextImportSites,
    hasNextPage: hasNextImportSites
    // refetch: refetchUnsites
  } = useUnmanagedSites(
    null,
    null,
    null,
    null,
    null,
    null, // Manager
    null, // Female
    true // to tell function to fetch policies for each employee too
  ); // getting Unmanaged sites

  //   useEffect(() => {
  //     if (unsitesearch) {
  //       refetchUnsites();
  //     }
  //   }, [unsitesearch, refetchUnsites]);

  const rows = useMemo(() => {
    const listOfSites = [];
    if (!isImportSitesLoading && !isImportSitesError) {
      if (unsites?.pages?.length > 0) {
        unsites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Unsites :', flattened);
    return flattened;
  }, [unsites, isImportSitesLoading, isImportSitesError]);

  /* ================= End Unmanaged Sites =============== */
  /* ===================== Employees ===================== */

  // const {
  //   isLoading: isLoadingEmployees,
  //   isError: isErrorEmployees,
  //   data: employees
  //   // fetchNextPage: fetchNextEmployees,
  //   // hasNextPage: hasNextEmployees,
  //   // refetch: refetchEmployees,
  // } = useEmployees('', '', '', '', role.id, '', ''); // getting employyes

  // const Employees = useMemo(() => {
  //   const listOfEmployees = [];
  //   // console.log("Fetched Employees",employees);
  //   if (!isLoadingEmployees && !isErrorEmployees) {
  //     if (employees?.pages?.length > 0) {
  //       employees.pages.forEach((group) => listOfEmployees.push(group.employees));
  //     }
  //   }
  //   const flattened = listOfEmployees.flat();
  //   return flattened;
  // }, [employees, isLoadingEmployees, isErrorEmployees]);

  // useEffect(() => {
  //   const formattedEmployees = [];
  //   // console.log("Sites Managers Upon Role", Employees);
  //   if (Employees?.length > 0) {
  //     Employees.forEach((emp) => formattedEmployees.push([emp._id, emp.name]));
  //   }
  //   // if (role.id || actualFilter.role) {
  //   //   // selectEmployeesOptions[role.id] = [...formattedEmployees]
  //   //   const id = role.id || actualFilter.role;
  //   //   setSelectEmployeesOptions((prev) => {
  //   //     return { ...prev, [id]: [...formattedEmployees] }; // Normal array select
  //   //   });
  //   // }
  //   if (role.id) {
  //     // selectEmployeesOptions[role.id] = [...formattedEmployees]
  //     setSelectEmployeesOptions(
  //       (prev) => ({ ...prev, [role.id]: [...formattedEmployees] }) // Normal array select
  //     );
  //   }
  //   // console.log(selectEmployeesOptions);
  // }, [rows, Employees, role]);

  /* ================= End Employees =============== */

  // /* ================= Assign Policy Mutation ============== */
  // const assignPolicyMutation = useMutation(assignPolicy, {
  //   onError: (error) => {
  //     // status: 401 unauthorized
  //     if (error.response && error.response.status === 401) {
  //       dispatch(logOut());
  //     }
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Something went wrong!',
  //       text: error.response ? error.response.data.message || error.toString() : error.toString(),
  //       timer: 2000,
  //       timerProgressBar: true
  //     });
  //   },
  //   onSuccess: () => {
  //     Swal.fire({
  //       icon: 'success',
  //       title: `Successfully assigned policy`,
  //       showConfirmButton: false,
  //       timer: 2000,
  //       timerProgressBar: true
  //     });
  //   },
  //   onSettled: () => {
  //     cache.invalidateQueries('employees');
  //   }
  // });

  /* ===================== Assign Role Mutations ==============  */

  //   const assignRoleMutation = useMutation(assignRole, {
  //     onError: (error) => {
  //       // status: 401 unauthorized
  //       if (error.response && error.response.status === 401) {
  //         dispatch(logOut());
  //       }

  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Something went wrong!',
  //         text: error.response ? error.response.data.message || error.toString() : error.toString(),
  //         timer: 2000,
  //         timerProgressBar: true
  //       });
  //     },
  //     onSuccess: () => {
  //       Swal.fire({
  //         icon: 'success',
  //         title: `Successfully assigned role`,
  //         showConfirmButton: false,
  //         timer: 2000,
  //         timerProgressBar: true
  //       });
  //     },
  //     onSettled: () => {
  //       cache.invalidateQueries('unmanaged-employees');
  //     }
  //   });

  /* ===================== Assign Role Mutations END  ==============  */

  const {
    isLoading: isSiteManagersLoading,
    isError: isSiteManagersError,
    data: siteManagers
    // fetchNextPage: fetchNextSiteManagers,
    // hasNextPage: hasNextSiteManagers
  } = useInfiniteQuery(
    ['site-managers', null, null],
    fetchSiteManagers,
    {
      // enabled: !!values.role_type,
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        // console.log("Error :", error)
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    } // gettings sitemanagers
  );

  const SiteManagers = useMemo(() => {
    const listOfSiteManagers = [];
    // console.log("Add Site Managers :", siteManagers);
    if (!isSiteManagersLoading && !isSiteManagersError) {
      if (siteManagers?.pages?.length > 0) {
        siteManagers.pages.forEach((group) => listOfSiteManagers.push(group.employees));
      }
    }
    const flattened = listOfSiteManagers.flat();
    return flattened;
  }, [siteManagers, isSiteManagersLoading, isSiteManagersError]);

  useEffect(() => {
    const formattedEmployees = [];

    if (SiteManagers?.length > 0) {
      SiteManagers.forEach((emp) =>
        formattedEmployees.push([
          `${emp._id}, ${emp.name}`,
          emp.avater,
          emp.role.name,
          emp?.subrole?.name
        ])
      );
    }
    setManagerOptions([...formattedEmployees]);
  }, [SiteManagers]);

  /* ================= End Managers =============== */

  /* ===================== Assign Manager Mutations ==============  */

  const assignManagerMutation = useMutation(assignSiteManager, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      setSelected([]);
      Swal.fire({
        icon: 'success',
        title: `Successfully assigned manager`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('unmanaged-sites');
    }
  });

  /* ===================== Assign Manager Mutations END ==============  */

  /* ==================== Import Sites Mutations ================== */

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Sites | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1.5}>
          <Typography variant="h4" gutterBottom>
            Un-Assigned Sites&nbsp;
            <Typography component="span" sx={{ height: 20, ml: 1, fontSize: 13 }}>
              ( {rows.length} )
            </Typography>
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button
              startIcon={<AddIcon />}
              onClick={() => setOpenAddSite(true)}
              variant="contained"
            >
              Site
            </Button>
            <UploadSites />
          </Stack>
        </Stack>
        <Card sx={{ boxShadow: 'none' }}>
          <ImportSiteListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            isSelected={selected}
            assignManager={assignManagerMutation}
          />

          <TableContainer
            id="import-sites-table-container"
            sx={{ minWidth: 800, height: 'calc(100vh - 240px)' }}
          >
            <Table>
              <SiteListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {isImportSitesLoading && (
                  <TableRow>
                    <TableCell colSpan="9">
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )}
                {isImportSitesError && (
                  <TableRow>
                    <TableCell>Something Went Wrong</TableCell>
                  </TableRow>
                )}
                {!isImportSitesLoading &&
                  filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const isItemSelected = selected.indexOf(row._id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row?._id)}
                            />
                          </TableCell>
                          {/* <TableCell>{(index += 1)}</TableCell> */}
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar alt={row?.name} src={row?.avatar} />
                              <Typography variant="subtitle2" noWrap>
                                {row?.name}
                              </Typography>
                              <InfoIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={(event) =>
                                  setAnchorEl({
                                    id: `info_${row._id}`,
                                    target: event.currentTarget
                                  })
                                }
                              />
                              <Popover
                                id={`info_${row._id}`}
                                open={anchorEl.id === `info_${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <List>
                                  <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <PhoneAndroidIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`+91 ${row?.mobile}`} />
                                    </ListItemButton>
                                  </ListItem>
                                  {row?.email && (
                                    <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <AlternateEmailIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`${row?.email}`} />
                                      </ListItemButton>
                                    </ListItem>
                                  )}
                                  {/* <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <LocationCityIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`${row?.state ?? 'Not Provided'}`} />
                                      </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                      <ListItemButton component="a" href="#simple-list">
                                        <ListItemIcon>
                                          <ContactsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`${row?.address}`} />
                                      </ListItemButton>
                                    </ListItem> */}
                                </List>
                              </Popover>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Stack sx={{ minWidth: '8rem' }}>
                              <FormControl fullWidth>
                                <InputLabel variant="standard" htmlFor="assign_manager">
                                  Choose Manager
                                </InputLabel>
                                <Select
                                  variant="standard"
                                  value=""
                                  inputProps={{
                                    name: 'assign_manager',
                                    id: `assign_manager_${row._id}`
                                  }}
                                  sx={{ mt: 2 }}
                                  onChange={(event) => {
                                    Swal.fire({
                                      title: 'Are you sure?',
                                      text: "You won't be able to revert this!",
                                      icon: 'question',
                                      showCancelButton: true,
                                      confirmButtonText: 'Yes, Proceed!',
                                      allowOutsideClick: false
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        assignManagerMutation.mutate({
                                          sites: [row._id],
                                          manager: event.target.value.split(',')[0]
                                        });
                                      } else if (
                                        /* Read more about handling dismissals below */
                                        result.dismiss === Swal.DismissReason.cancel
                                      ) {
                                        const select = document.querySelector(
                                          `#assign_manager_${row._id}`
                                        );
                                        select.selectedIndex = 0;
                                        cache.invalidateQueries('unmanaged-sites');
                                      }
                                    });
                                  }}
                                >
                                  <MenuItem value="" disabled>
                                    Choose Manager
                                  </MenuItem>
                                  {managerOptions.length > 0 ? (
                                    managerOptions.map(([key, image]) => (
                                      <MenuItem
                                        style={{ padding: '0.5rem' }}
                                        key={`${'assign_manager'}-${key}`}
                                        value={key}
                                      >
                                        <Avatar src={image} />
                                        <Stack sx={{ ml: 1 }}>
                                          <Typography>&nbsp;{key.split(',')[1]}</Typography>
                                          <Box display="flex">
                                            {/* <Chip
                                                fontSize={12}
                                                sx={{ height: 18, mb: 0.5 }}
                                                size="small"
                                                label={empR}
                                              />
                                              &nbsp;
                                              {empSR && (
                                                <Chip fontSize={12} sx={{ height: 18 }} size="small" label={empSR} />
                                              )} */}
                                          </Box>
                                        </Stack>
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem value="">No Data Found</MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Stack>
                          </TableCell>
                          {/* <TableCell align="right">
                              <SiteMoreMenu />
                            </TableCell> */}
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            {/* Put the scroll bar always on the bottom */}
            <InfiniteScroll
              dataLength={rows?.length}
              next={fetchNextImportSites}
              // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
              // inverse //
              hasMore={hasNextImportSites}
              loader={<h4>Loading...</h4>}
              scrollableTarget="import-sites-table-container"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            />
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
      <AddEmployee open={openAddEmployee} onClose={() => setOpenAddEmployee(false)} />
      <AddSite open={openAddSite} onClose={() => setOpenAddSite(false)} />
    </Page>
  );
}
