import { useInfiniteQuery, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import {
  fetchRoster,
  fetchSiteShifts,
  fetchAllReinforcement,
  workforceRequirementAnalyzer
} from '../api/queries/Queries';

export const useRoster = (
  date,
  search,
  state,
  gender,
  role,
  subRole,
  site,
  shift,
  token,
  weekStart
) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(
    ['roster', date, search, state, gender, role, subRole, site, shift, token, weekStart],
    fetchRoster,
    {
      enabled: !!site,
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        // console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};

export const useSiteShifts = (from, to, search, date, site, status, type) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(
    ['sites-shifts', from, to, search, date, site, status, type],
    fetchSiteShifts,
    {
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        // console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};

export const useReinforcement = (from, to, site, status) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(['reinforcement', from, to, site, status], fetchAllReinforcement, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export const useWorkforceRequirementAnalyzer = (site, date, rosterId) => {
  const dispatch = useDispatch();
  return useQuery(
    // eslint-disable-next-line block-scoped-var
    ['workforce-requirement-analyzer', site, date, rosterId],
    // eslint-disable-next-line block-scoped-var
    () => workforceRequirementAnalyzer(site, date, rosterId),
    {
      // eslint-disable-next-line block-scoped-var
      enabled: !!rosterId,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};
