// import { filter } from 'lodash';
import React, { useState, useMemo } from 'react';
import {
  Container,
  Stack,
  Typography,
  Card,
  // Button,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  // TablePagination,
  Avatar,
  ListItem,
  List,
  ListItemText,
  Popover
} from '@mui/material';
// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  SiteFeedbackListHead,
  SiteFeedbackListToolbar,
  SiteFeedbackMoreMenu
} from '../components/_dashboard/site_feedback';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
// hooks
import { useFeedbacks } from '../hooks/site';
// utils
import { DD_MM_YYYY } from '../utils/functions';

// ---------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'site', label: 'Site', alignRight: false },
  { id: 'feedback', label: 'Feedback', alignRight: false },
  { id: 'added_on', label: 'Added On', alignRight: false },
  { id: 'added_by', label: 'Added By', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// --------------------------------------------------------------

function SitesFeedbacks() {
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    site: '',
    employee: ''
  });

  const {
    isLoading: isFeedbacksLoading,
    isError: isFeedbacksError,
    // hasNextPage: hasNextFeedbacks,
    // fetchNextPage: fetchNextFeedbacks,
    data: feedbacks
  } = useFeedbacks(filter.from, filter.to, filter.site, filter.employee);

  const rows = useMemo(() => {
    const listOfFeedbacks = [];
    if (!isFeedbacksLoading && !isFeedbacksError) {
      if (feedbacks?.pages?.length > 0) {
        feedbacks.pages.forEach((group) => {
          listOfFeedbacks.push(group.feedbacks);
        });
      }
    }
    const flattened = listOfFeedbacks.flat();
    return flattened;
  }, [feedbacks, isFeedbacksLoading, isFeedbacksError]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const filteredUsers = rows;

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Site Feedback | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Sites Feedback&nbsp;
            <Typography component="span" sx={{ height: 20, ml: 1, fontSize: 13 }}>
              ( {rows.length} )
            </Typography>
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => setOpenAddEmployee(true)}
          >
            New Employee
          </Button> */}
        </Stack>
        <Card>
          <SiteFeedbackListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
          />

          <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 275px)' }}>
            <Table>
              <SiteFeedbackListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = selected.indexOf(row.name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={row._id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                          </TableCell> */}
                        <TableCell>{(index += 1)}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {row.site.name}
                        </TableCell>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="subtitle2" noWrap>
                              Show Feedback
                            </Typography>
                            <VisibilityIcon
                              sx={{ cursor: 'pointer' }}
                              onClick={(event) =>
                                setAnchorEl({
                                  id: `info_${row._id}`,
                                  target: event.currentTarget
                                })
                              }
                            />

                            <Popover
                              id={`info_${row._id}`}
                              open={anchorEl.id === `info_${row._id}`}
                              anchorEl={anchorEl.target}
                              onClose={() => setAnchorEl({ id: null, target: null })}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <List>
                                <ListItem disablePadding>
                                  <ListItemText primary={`+91 ${row.contact}`} />
                                </ListItem>
                                <ListItem disablePadding>
                                  <ListItemText primary={`${row.email}`} />
                                </ListItem>
                              </List>
                            </Popover>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar alt={row.name} src={row.avatar} />
                            <Typography variant="subtitle2" noWrap>
                              {row.name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{DD_MM_YYYY(row.createdAt)}</TableCell>
                        <TableCell align="left">{row.manager.name}</TableCell>
                        <TableCell align="left">Document</TableCell>
                        <TableCell align="right">
                          <SiteFeedbackMoreMenu />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
    </Page>
  );
}

export default SitesFeedbacks;
