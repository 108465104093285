import PropTypes from 'prop-types';
import { useRef, useState, useEffect, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { sub, formatDistanceToNow } from 'date-fns';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
// material
import { alpha } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  // Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  Stack,
  CircularProgress
} from '@mui/material';
// utils
// import { mockImgAvatar } from '../../utils/mockImages';
import { humanTime } from '../../utils/functions';
// components
import MenuPopover from '../../components/MenuPopover';
import { useNotifications } from '../../hooks/notifications';
import { requestForToken, onMessageListener } from '../../firebaseInit';

// ----------------------------------------------------------------------
function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  if (notification.type === 'Shift') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_shift.png" />,
      title
    };
  }
  if (notification.type === 'Site') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_site.png" />,
      title
    };
  }
  if (notification.type === 'Holiday') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_holiday.png" />,
      title
    };
  }
  if (notification.type === 'Leave') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_leave.png" />,
      title
    };
  }
  if (notification.type === 'Swap') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_swap.png" />,
      title
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
      title
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  return {
    avatar: <img alt={notification.title} src={notification.avatar} />,
    title
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      to="#"
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <AccessTimeIcon sx={{ mr: 0.5, width: 16, height: 16 }} />
            {formatDistanceToNow(new Date(notification?.createdAt))} ago
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { isAdminAuthenticated } = useSelector((state) => state.adminInfo);
  // const [notification, setNotification] = useState({ title: '', body: '' });
  const [notifications, setNotifications] = useState([]);
  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false
      }))
    );
  };

  const {
    isLoading: isNotificationsLoading,
    isError: isNotificationsError,
    fetchNextPage: fetchNextNotifications,
    hasNextPage: hasNextNotifications,
    data
  } = useNotifications();

  const allNotifications = useMemo(() => {
    const listOfNotifications = [];
    if (!isNotificationsLoading && !isNotificationsError) {
      if (data?.pages?.length > 0) {
        data.pages.forEach((group) => listOfNotifications.push(group.notifications));
      }
    }
    const flattened = listOfNotifications.flat();
    return flattened;
  }, [isNotificationsLoading, isNotificationsError, data]);

  useEffect(() => {
    const NOTIFICATIONS = [];
    if (allNotifications?.length > 0) {
      // console.log(allNotifications);
      allNotifications.forEach((notification) => {
        const time = humanTime(new Date(notification.createdAt));
        NOTIFICATIONS.push({
          id: notification._id,
          title: notification?.title,
          description: notification?.message,
          avatar: null,
          type: notification?.notificationType,
          createdAt: sub(new Date(), {
            days: time?.days,
            hours: time?.hours,
            minutes: time?.minutes
          }),
          isUnRead: notification.isUnread
        });
      });
    }
    // console.log('NOTIFICATIONS:', NOTIFICATIONS);
    setNotifications(NOTIFICATIONS);
  }, [allNotifications]);

  // Otherwise, we need to ask the user for permission
  if (Notification.permission !== 'granted') {
    Notification.requestPermission();
  }

  if (isAdminAuthenticated) {
    requestForToken();
    onMessageListener()
      .then((payload) => {
        console.log('Message:', payload);
        setNotifications([
          ...notifications,
          {
            title: payload?.notification?.title,
            description: payload?.notification?.body,
            avatar: null,
            type: 'order_shipped',
            createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
            isUnRead: true
          }
        ]);
      })
      .catch((err) => console.log('failed: ', err));
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <NotificationsIcon width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <DoneAllIcon width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        {notifications.length > 0 && (
          <Stack id="notifications-list-container" sx={{ height: { xs: 340, sm: 'auto' } }}>
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  New
                </ListSubheader>
              }
            >
              {notifications.slice(0, 2).map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
            </List>

            {notifications.length > 2 && (
              <List
                disablePadding
                subheader={
                  <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    Before that
                  </ListSubheader>
                }
              >
                {notifications.slice(2, notifications.length).map((notification) => (
                  <NotificationItem key={notification.id} notification={notification} />
                ))}
              </List>
            )}
            <InfiniteScroll
              dataLength={allNotifications?.length}
              next={fetchNextNotifications}
              // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
              // inverse //
              hasMore={hasNextNotifications}
              loader={
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Stack>
              }
              scrollableTarget="notifications-list-container"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            />
          </Stack>
        )}

        <Divider />

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}
