// import { filter } from 'lodash';
import { useState, useMemo } from 'react';
import { useDebounce } from 'use-debounce';
// import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
// import Swal from 'sweetalert2/src/sweetalert2';
// import '@sweetalert2/theme-material-ui/material-ui.css';
import AddIcon from '@mui/icons-material/Add';
import { Link as RouterLink } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// material
import {
  Card,
  Table,
  Stack,
  // Avatar,
  Button,
  // Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  // TablePagination,
  LinearProgress
  // Select,
  // InputLabel,
  // FormControl,
  // MenuItem
} from '@mui/material';
// icons
import InfoIcon from '@mui/icons-material/Info';
import SickIcon from '@mui/icons-material/Sick';
import NightlifeIcon from '@mui/icons-material/Nightlife';
// import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
// hooks
import usePolicies from '../hooks/get-policies';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
import { PolicyListHead, PolicyListToolbar, PolicyMoreMenu } from '../components/_dashboard/policy';
// utils
import { DD_MM_YYYY } from '../utils/functions';
// Mutations
import AddPolicy from '../components/_dashboard/policy/AddPolicy';
// import { assignPolicy } from '../api/mutations/policy';
// redux
// import { logOut } from '../redux/actions/adminActions';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'policy', label: 'Policy', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'total_leaves', label: 'Total Leaves', alignRight: false },
  { id: 'overime_policy', label: 'Overtime (₹ / hr)', alignRight: false },
  { id: 'travel_policy', label: 'Travel (₹ / km)', alignRight: false },
  { id: 'policy_created', label: 'Created on', alignRight: false },
  { id: 'policy_updated', label: 'Updated on', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export default function User() {
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [editPolicy, setEditPolicy] = useState();
  const [filterName, setFilterName] = useState();
  const [search] = useDebounce(filterName, 1200); // Debounce value 1.2 sec
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [openAddPolicy, setOpenAddPolicy] = useState(false);
  const { info } = useSelector((state) => state.adminInfo);

  // const dispatch = useDispatch();
  // const cache = useQueryClient();

  // const [searchValue, setSearchValue] = useState(''); // Search Filed state
  // const [search] = useDebounce(searchValue, 1000); // Debounce value 1 sec
  // const [from, setFrom] = useState();
  // const [to, setTo] = useState();

  const [filters, setFilters] = useState({
    role: ''
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  /* ===================== Policies ===================== */

  const {
    isPoliciesLoading,
    isPoliciesError,
    fetchNextPage: fetchNextPolicies,
    hasNextPage: hasNextPolicies,
    data: policies
  } = usePolicies(filters?.role.length > 0 ? filters?.role?.split(',')[0] : undefined, search); // getting policies Need to add search

  const rows = useMemo(() => {
    const listOfPolicies = [];
    if (!isPoliciesLoading && !isPoliciesError) {
      if (policies?.pages?.length > 0) {
        policies.pages.forEach((group) => listOfPolicies.push(group.policies));
      }
    }
    const flattened = listOfPolicies.flat();
    return flattened;
  }, [policies, isPoliciesLoading, isPoliciesError]);

  /* ================= End Policies =============== */

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = rows;

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Policies | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1.5}>
          <Typography variant="h4" gutterBottom>
            Policies&nbsp;
            <Typography component="span" sx={{ height: 20, ml: 1, fontSize: 13 }}>
              ( {rows.length} )
            </Typography>
          </Typography>
          {info?.isMainAdmin && (
            <Button
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<AddIcon />}
              onClick={() => {
                setEditPolicy(null);
                setOpenAddPolicy(true);
              }}
            >
              New Policy
            </Button>
          )}
        </Stack>

        <Card sx={{ boxShadow: 'none' }}>
          <PolicyListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            filters={filters}
            setFilters={setFilters}
          />

          <TableContainer
            id="policies-table-container"
            sx={{
              minWidth: 800,
              minHeight: 'calc(100vh - 275px)',
              maxHeight: 'calc(100vh - 275px)'
            }}
          >
            <Table stickyHeader>
              <PolicyListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {isPoliciesLoading && (
                  <TableRow>
                    <TableCell colSpan="9">
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )}
                {isPoliciesError && (
                  <TableRow>
                    <TableCell>Something Went Wrong</TableCell>
                  </TableRow>
                )}
                {!isPoliciesLoading &&
                  filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                          </TableCell> */}
                          <TableCell>{(index += 1)}</TableCell>
                          <TableCell>{row?.name}</TableCell>
                          <TableCell align="left">{row?.role?.name}</TableCell>
                          <TableCell align="left">
                            <Stack direction="row">
                              <Typography sx={{ mr: 2 }}>{row.total_leaves}</Typography>
                              <InfoIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={(event) =>
                                  setAnchorEl({
                                    id: `leaves_${row._id}`,
                                    target: event.currentTarget
                                  })
                                }
                              />
                              <Popover
                                id={`leaves_${row._id}`}
                                open={anchorEl.id === `leaves_${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <List>
                                  <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <SickIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`Sick : ${row.sick_leaves}`} />
                                    </ListItemButton>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <NightlifeIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`Casual : ${row.casual_leaves}`} />
                                    </ListItemButton>
                                  </ListItem>
                                  {/* <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <MilitaryTechIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={`Privilege : ${row.privilege_leaves}`}
                                        />
                                      </ListItemButton>
                                    </ListItem> */}
                                </List>
                              </Popover>
                            </Stack>
                          </TableCell>
                          <TableCell>{row.overtime_amount ?? 0}</TableCell>
                          <TableCell>
                            {row.two_wheeler_amount}
                            {row.four_wheeler_amount}
                          </TableCell>
                          <TableCell>{DD_MM_YYYY(row.createdAt)}</TableCell>
                          <TableCell>{DD_MM_YYYY(row.updatedAt)}</TableCell>
                          <TableCell align="right">
                            <PolicyMoreMenu
                              policy={row}
                              setEditPolicy={setEditPolicy}
                              setOpenPolicy={setOpenAddPolicy}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            {/* Put the scroll bar always on the bottom */}
            <InfiniteScroll
              dataLength={rows?.length}
              next={fetchNextPolicies}
              // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
              // inverse //
              hasMore={hasNextPolicies}
              loader={<h4>Loading...</h4>}
              scrollableTarget="policies-table-container"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            />
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
      <AddPolicy edit={editPolicy} open={openAddPolicy} onClose={() => setOpenAddPolicy(false)} />
    </Page>
  );
}
