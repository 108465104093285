import axios from 'axios';
import { baseApiURL } from '../../utils/config';
import { getDataFromStorage } from '../../utils/functions';

const getConfig = () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  return config;
};

export const addSubRole = async (values) => {
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/admin/subroles`, values, getConfig());
    return data;
  }
  throw new Error('No data to send');
};

export const updateSubRole = async (values) => {
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/employee-subrole`, values, getConfig());
    return data;
  }
  throw new Error('No data to send');
};
