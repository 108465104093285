/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prettier/prettier */
import { filter } from 'lodash';
// import * as Yup from 'yup';
// import { sentenceCase } from 'change-case';
import { useState, useMemo, useEffect } from 'react';
// import { styled } from '@mui/material/styles';
import { useDebounce } from 'use-debounce';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
// import { useFormik, FormikProvider, Form } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import AddIcon from '@mui/icons-material/Add';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// material
import {
  Card,
  // Chip,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  // Divider,
  // Modal,
  // Box,
  // TablePagination,
  LinearProgress,
  // MenuItem,
  Tooltip
} from '@mui/material';
// import MuiAccordion from '@mui/material/Accordion';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
// import MuiAccordionDetails from '@mui/material/AccordionDetails';
// // icons
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import InfoIcon from '@mui/icons-material/Info';
// import CheckIcon from '@mui/icons-material/Check';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import CloseIcon from '@mui/icons-material/Close';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ContactsIcon from '@mui/icons-material/Contacts';
// hooks
import useSites from '../hooks/get-sites-query';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
import { SiteListHead, SiteListToolbar } from '../components/_dashboard/site';
// utils
import {
  DD_MM_YYYY,
  convertUTCtoLocal,
  setDataInStorage,
  getCurrentDate
} from '../utils/functions';
import { SWAL_TIME } from '../utils/config';
// Mutations
import AddSite from '../components/_dashboard/site/AddSite';
// import { assignPolicy } from '../api/mutations/policy';
// redux
import { logOut } from '../redux/actions/adminActions';
import { deleteSite } from '../api/mutations/adminMutations';

// ----------------------------------------------------------------------

// const Accordion = styled((props) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   '&:not(:last-child)': {
//     borderBottom: 0
//   },
//   '&:before': {
//     display: 'none'
//   }
// }));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
//   flexDirection: 'row-reverse',
//   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//     transform: 'rotate(90deg)'
//   },
//   '& .MuiAccordionSummary-content': {
//     marginLeft: theme.spacing(1)
//   }
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderTop: '1px solid rgba(0, 0, 0, .125)'
// }));

const TABLE_HEAD = [
  { id: 'site', label: 'Site', alignRight: false },
  // { id: 'required_workforce', label: 'Requirement', alignRight: false },
  // { id: 'current_workforce', label: 'Available', alignRight: false },
  // { id: 'available_workforce', label: 'Vacant', alignRight: false },
  { id: 'shift', label: 'Shift', alignRight: false },
  { id: 'manager', label: 'Manager', alignRight: false },
  { id: 'added_on', label: 'Added On', alignRight: false, width: 130 },
  { id: 'added_by', label: 'Added By', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false }
  // { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const navigate = useNavigate();
  const [
    page
    // setPage
  ] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [search] = useDebounce(filterName, 1200); // Debounce value 1.2 sec
  const [
    rowsPerPage
    // setRowsPerPage
  ] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });

  const [openAddSite, setOpenAddSite] = useState(false);

  // const [expanded, setExpanded] = useState(); // 'panel1'

  // const [open, setOpen] = useState(false);
  // const [siteShifts, setSiteShifts] = useState();
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  // const [requirements, setRequirements] = useState();

  const dispatch = useDispatch();
  const cache = useQueryClient();
  // const [searchValue, setSearchValue] = useState();
  // const { search } = useDebounce(searchValue, 1000); // Debounce value 1 sec

  const [filter, setFilter] = useState({
    range: [null, null],
    state: '',
    status: '',
    role: '',
    manager: '',
    date: null
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  useEffect(() => {
    console.log(filter);
  }, [filter]);

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  /* ===================== Sites ===================== */

  const {
    isLoading,
    isError,
    data: sitesData,
    fetchNextPage: fetchNextSites,
    hasMoreData: hasNextSites
    // refetch: refetchSites
  } = useSites(
    filter.range[0] ? convertUTCtoLocal(filter.range[0], true) : null, // from
    filter.range[1] ? convertUTCtoLocal(filter.range[1], true) : null, // to
    filter.date === null ? getCurrentDate() : filter.date,
    null, // type
    filter.state, // state
    filter.status, // status
    filter.manager, // manager
    // filter.role,
    search, // search
    null
  );

  const rows = useMemo(() => {
    const listOfSites = [];
    console.log(sitesData);
    if (!isLoading && !isError) {
      if (sitesData?.pages?.length > 0) {
        sitesData.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Sites :', flattened);
    return flattened;
  }, [sitesData, isLoading, isError]);

  /* ================= End Sites =============== */
  /* ================== Delete Site ========================== */
  const deleteSitesMutation = useMutation(deleteSite, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Deleted!`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('sites');
    }
  });

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Sites | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1.5}>
          <Typography variant="h4" gutterBottom>
            Sites&nbsp;
            <Typography component="span" sx={{ height: 20, ml: 1, fontSize: 13 }}>
              ( {rows.length} )
            </Typography>
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<AddIcon />}
            onClick={() => setOpenAddSite(true)}
          >
            New Site
          </Button>
        </Stack>

        <Card sx={{ boxShadow: 'none' }}>
          <SiteListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
            filters={filter}
            deleteSites={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, Proceed!',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteSitesMutation.mutate({
                    sites: selected
                  });
                  setSelected([]);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  cache.invalidateQueries('sites');
                }
              });
            }}
          />

          <TableContainer
            id="sites-table-container"
            sx={{
              minWidth: 800,
              minHeight: 'calc(100vh - 275px)',
              maxHeight: 'calc(100vh - 275px)'
            }}
          >
            <Table stickyHeader>
              <SiteListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan="11">
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )}
                {isError && (
                  <TableRow>
                    <TableCell>Something Went Wrong</TableCell>
                  </TableRow>
                )}
                {!isLoading &&
                  filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const isItemSelected = selected.indexOf(row._id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row._id)}
                            />
                          </TableCell>
                          {/* <TableCell>{(index += 1)}</TableCell> */}
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar alt={row.name} src={row?.image} />
                              {/* <RouterLink
                                  style={{ textDecoration: 'none' }}
                                  // to="/site/profile"
                                  // state={{ site: row }}
                                  
                                > */}
                              <Tooltip
                                sx={{ fontSize: '1rem' }}
                                title={`Visit ${row.name}`}
                                placement="top"
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={{ cursor: 'pointer' }}
                                  noWrap
                                  onClick={() => {
                                    setDataInStorage('selected_site', row);
                                    navigate('/site/profile');
                                  }}
                                >
                                  {row.name}
                                </Typography>
                              </Tooltip>
                              {/* </RouterLink> */}
                              <InfoIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={(event) =>
                                  setAnchorEl({
                                    id: `info_${row._id}`,
                                    target: event.currentTarget
                                  })
                                }
                              />
                              <Popover
                                id={`info_${row._id}`}
                                open={anchorEl.id === `info_${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <List>
                                  {row.mobile ? (
                                    <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <PhoneAndroidIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`+91 ${row.mobile}`} />
                                      </ListItemButton>
                                    </ListItem>
                                  ) : null}
                                  {row.email ? (
                                    <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <AlternateEmailIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`${row.email}`} />
                                      </ListItemButton>
                                    </ListItem>
                                  ) : null}
                                  {row.state ? (
                                    <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <LocationCityIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`${row.state ?? 'Not Provided'}`} />
                                      </ListItemButton>
                                    </ListItem>
                                  ) : null}
                                  {row.address ? (
                                    <ListItem disablePadding>
                                      <ListItemButton component="a" href="#simple-list">
                                        <ListItemIcon>
                                          <ContactsIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={`${row.address ?? 'Not Provided'}`}
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  ) : null}
                                </List>
                              </Popover>
                            </Stack>
                          </TableCell>
                          {/* <TableCell> */}
                          {/* <Chip
                                label={`${row.total_requirement.reduce(
                                  (s, obj) => obj.total, 0)}`}
                                onClick={(event) =>
                                  setAnchorEl({
                                    id: `requirements_${row._id}`,
                                    target: event.currentTarget
                                  })
                                }
                                icon={<ArrowForwardIosIcon sx={{ fontSize: 14 }} />}
                              /> */}
                          {/* <Popover
                                id={`requirements_${row._id}`}
                                open={anchorEl.id === `requirements_${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <Stack sx={{ width: 300, p: 1 }}>
                                  <Typography sx={{ mb: 1 }}>
                                    Requirements ( {row.name} )
                                  </Typography>
                                  <Divider sx={{ mb: 1 }} />
                                  <Stack sx={{ height: 160, overflowY: 'auto' }}>
                                    {row.total_requirement.length > 0
                                      ? row.total_requirement.map((req, index) => (
                                          <Stack key={`requirement_${row._id}`}>
                                            <Accordion
                                              key={`accordian_${row._id}`}
                                              expanded={expanded === `panel${index}`}
                                              onChange={handleChange(`panel${index}`)}
                                            >
                                              <AccordionSummary
                                                aria-controls="panel1d-content"
                                                id="panel1d-header"
                                              >
                                                <Typography>{req.name} ( {req.total} )</Typography>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                {req.subroles.length > 0
                                                  ? req.subroles.map((sub) => (
                                                      <Stack
                                                        sx={{ px: 1, mt: 1 }}
                                                        key={`_${sub.subrole}`}
                                                        direction="row"
                                                        justifyContent="space-between"
                                                      >
                                                        <Typography>
                                                          {sub.name}
                                                        </Typography>
                                                        <Typography>{sub.total}</Typography>
                                                      </Stack>
                                                    ))
                                                  : null}
                                              </AccordionDetails>
                                            </Accordion>
                                          </Stack>
                                        ))
                                      : 'Requirements Not Added'}
                                  </Stack>
                                </Stack>
                              </Popover> */}
                          {/* </TableCell> */}
                          {/* <TableCell>
                              <Chip
                                label={`${row.employees.reduce(
                                  (s, obj) => s + obj.total, 0
                                )}`}
                                onClick={(event) =>
                                  setAnchorEl({
                                    id: `available_${row._id}`,
                                    target: event.currentTarget
                                  })
                                }
                                icon={<ArrowForwardIosIcon sx={{ fontSize: 14 }} />}
                              />
                              <Popover
                                id={`available_${row._id}`}
                                open={anchorEl.id === `available_${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <Stack sx={{ width: 300, p: 1 }}>
                                  <Typography sx={{ mb: 1 }}>
                                    Available ( {row.name} )
                                  </Typography>
                                  <Divider sx={{ mb: 1 }} />
                                  <Stack sx={{ height: 160, overflowY: 'auto' }}>
                                    {row.employees.length > 0
                                      ? row.employees.map((av, index) => (
                                          <Stack key={`available_${av.role._id}`}>
                                            <Accordion
                                              key={`accordian_avai_${av.role._id}`}
                                              expanded={av.subroles.length > 0 ? expanded === `_panel${index}` : false}
                                              onChange={handleChange(`_panel${index}`)}
                                            >
                                              <AccordionSummary
                                                aria-controls="panel1d-content"
                                                id="panel1d-header"
                                              >
                                                <Typography>{av.role.name} ( {av.total} )</Typography>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                {av.subroles.length > 0
                                                  ? av.subroles.map((sub) => (
                                                      <Stack
                                                        sx={{ px: 1, mt: 1 }}
                                                        key={`_${sub.subrole._id}`}
                                                        direction="row"
                                                        justifyContent="space-between"
                                                      >
                                                        <Typography>
                                                          {sub.subrole.name}
                                                        </Typography>
                                                        <Typography>{sub.total}</Typography>
                                                      </Stack>
                                                    ))
                                                  : null}
                                              </AccordionDetails>
                                            </Accordion>
                                          </Stack>
                                        ))
                                      : 'Available Not Found'}
                                  </Stack>
                                </Stack>
                              </Popover>
                            </TableCell> */}
                          {/* <TableCell>
                              <Chip
                                label={`${row.vacancy.reduce(
                                  (s, obj) => obj.total, 0)}`}
                                onClick={(event) =>
                                  setAnchorEl({
                                    id: `vacancy_${row._id}`,
                                    target: event.currentTarget
                                  })
                                }
                                icon={<ArrowForwardIosIcon sx={{ fontSize: 14 }} />}
                              />
                              <Popover
                                id={`vacancy_${row._id}`}
                                open={anchorEl.id === `vacancy_${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <Stack sx={{ width: 300, p: 1 }}>
                                  <Typography sx={{ mb: 1 }}>
                                    Vacant ( {row.name} )
                                  </Typography>
                                  <Divider sx={{ mb: 1 }} />
                                  <Stack sx={{ height: 160, overflowY: 'auto' }}>
                                    {row.vacancy.length > 0
                                      ? row.vacancy.map((av, index) => (
                                          <Stack key={`vacancy_${av.role}`}>
                                            <Accordion
                                              key={`accordian_vcan_${av.role}`}
                                              expanded={expanded === `__panel${index}`}
                                              onChange={handleChange(`__panel${index}`)}
                                            >
                                              <AccordionSummary
                                                aria-controls="panel1d-content"
                                                id="panel1d-header"
                                              >
                                                <Typography>{av.name} ( {av.total} )</Typography>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                {av.subroles.length > 0
                                                  ? av.subroles.map((sub) => (
                                                      <Stack
                                                        sx={{ px: 1, mt: 1 }}
                                                        key={`_${sub.subrole}`}
                                                        direction="row"
                                                        justifyContent="space-between"
                                                      >
                                                        <Typography>
                                                          {sub.name}
                                                        </Typography>
                                                        <Typography>{sub?.total}</Typography>
                                                      </Stack>
                                                    ))
                                                  : null}
                                              </AccordionDetails>
                                            </Accordion>
                                          </Stack>
                                        ))
                                      : 'Vacant Not Found'}
                                  </Stack>
                                </Stack>
                              </Popover>
                            </TableCell> */}
                          <TableCell align="left">{row?.shifts?.length ?? 0}</TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar alt={row.name} src={row.manager.avatar} />
                              <Typography variant="subtitle2" noWrap>
                                {row.manager.name}
                              </Typography>
                              {/* <InfoIcon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(event) =>
                                    setAnchorEl({
                                        id: `info_${row._id}`,
                                        target: event.currentTarget
                                    })
                                    }
                              /> */}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Stack sx={{ width: 100 }}>{DD_MM_YYYY(row.createdAt)}</Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar alt={row.name} src={row.addedBy.avatar} />
                              <Typography variant="subtitle2" noWrap>
                                {row.addedBy.name}
                              </Typography>
                              {/* <InfoIcon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(event) =>
                                    setAnchorEl({
                                        id: `info_${row._id}`,
                                        target: event.currentTarget
                                    })
                                    }
                                    /> */}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {row?.status === 'Verified' ? (
                              <Typography sx={{ color: 'green', fontWeight: 'bold' }}>
                                {row.status}
                              </Typography>
                            ) : (
                              <Typography sx={{ color: 'red', fontWeight: 'bold' }}>
                                {row.status}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell>
                            {row?.active === 'Active' ? (
                              <Typography sx={{ color: 'green', fontWeight: 'bold' }}>
                                {row.active}
                              </Typography>
                            ) : (
                              <Typography sx={{ color: 'red', fontWeight: 'bold' }}>
                                {row.active}
                              </Typography>
                            )}
                          </TableCell>
                          {/* <TableCell align="right">
                              <SiteMoreMenu />
                            </TableCell> */}
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            {/* Put the scroll bar always on the bottom */}
            <InfiniteScroll
              dataLength={rows?.length}
              next={fetchNextSites}
              // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
              // inverse //
              hasMore={hasNextSites}
              loader={<h4>Loading...</h4>}
              scrollableTarget="sites-table-container"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            />
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
      <AddSite open={openAddSite} onClose={() => setOpenAddSite(false)} />
    </Page>
  );
}
