/* eslint-disable no-nested-ternary */
// import { filter } from 'lodash';
import React, { useState, useMemo } from 'react';
// import { Icon } from '@iconify/react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import {
  Container,
  Stack,
  Typography,
  Card,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Tooltip,
  Avatar,
  Popover,
  LinearProgress
} from '@mui/material';
// import { useDebounce } from 'use-debounce';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import InfiniteScroll from 'react-infinite-scroll-component';
// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VerifiedIcon from '@mui/icons-material/Verified';
import { SiteFeedbackListHead } from '../components/_dashboard/site_feedback';
import { QueriesListToolbar, QueriesMoreMenu } from '../components/_dashboard/query';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
// hooks
import { useQueries } from '../hooks/queries';
// Mutations
import { updateQuery } from '../api/mutations/adminMutations';
// utils
import { DD_MM_YYYY } from '../utils/functions';
import { SWAL_TIME } from '../utils/config';
// redux
import { logOut } from '../redux/actions/adminActions';

// ---------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'employee', label: 'Employee', alignRight: false },
  { id: 'subject', label: 'Subject', alignRight: false },
  { id: 'query', label: 'Query', alignRight: false },
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'added_on', label: 'Added On', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// --------------------------------------------------------------

function Queries() {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  // const [search] = useDebounce(filterName, 1200); // Debounce value 1.2 sec
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [filter, setFilter] = useState({
    from: '',
    to: ''
  });

  const {
    isLoading: isQueriesLoading,
    isError: isQueriesError,
    hasNextPage: hasNextQueries,
    fetchNextPage: fetchNextQueries,
    data: queries
  } = useQueries(filter.from, filter.to);

  const rows = useMemo(() => {
    const listOfQueries = [];
    if (!isQueriesLoading && !isQueriesError) {
      if (queries?.pages?.length > 0) {
        queries.pages.forEach((group) => {
          listOfQueries.push(group.queries);
        });
      }
    }
    const flattened = listOfQueries.flat();
    // console.log(flattened);
    return flattened;
  }, [queries, isQueriesLoading, isQueriesError]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const { mutate: updateQueryMutation } = useMutation(updateQuery, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Updating Status',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // for unauthorized access
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString(),
        showConfirmButton: false
        // timer: 4000,
        // timerProgressBar: true
      });
    },
    onSuccess: (data) => {
      Swal.fire({
        icon: 'success',
        title: 'Successfull',
        text: data.message,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      setSelected([]);
      cache.invalidateQueries('role-manageremployees-queries');
    }
  });

  const resolveQueries = (sel) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'All Selected Queries will be marked as Resolved!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, approve!'
    }).then((result) => {
      if (result.isConfirmed) {
        if (sel.length > 0) {
          updateQueryMutation({
            status: 'Resolved',
            ids: sel
          });
        }
      }
    });
  };

  const rejectQueries = (sel) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'All Selected Queries will be marked as Rejected!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, reject selected!'
    }).then((result) => {
      if (result.isConfirmed) {
        if (sel.length > 0) {
          updateQueryMutation({
            status: 'Rejected',
            ids: sel
          });
        }
      }
    });
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const filteredUsers = rows;

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Queries | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1.5}>
          <Typography variant="h4" gutterBottom>
            Queries&nbsp;
            <Typography component="span" sx={{ height: 20, ml: 1, fontSize: 13 }}>
              ( {rows.length} )
            </Typography>
          </Typography>
        </Stack>
        <Card sx={{ boxShadow: 'none' }}>
          <QueriesListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
            resolveQueries={() => resolveQueries(selected)}
            rejectQueries={() => rejectQueries(selected)}
          />
          <TableContainer
            id="queries-table-container"
            sx={{
              minWidth: 800,
              minHeight: 'calc(100vh - 275px)',
              maxHeight: 'calc(100vh - 275px)'
            }}
          >
            <Table>
              <SiteFeedbackListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {isQueriesLoading && (
                  <TableRow>
                    <TableCell colSpan="9">
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )}
                {isQueriesError && (
                  <TableRow>
                    <TableCell>Something Went Wrong</TableCell>
                  </TableRow>
                )}
                {!isQueriesLoading &&
                  filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const isItemSelected = selected.indexOf(row._id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {row.status === 'Resolved' ? (
                              <Tooltip title="I am a Resolved Query">
                                <VerifiedIcon sx={{ color: 'green', mt: 1 }} />
                              </Tooltip>
                            ) : row.status === 'Rejected' ? (
                              <Tooltip title="I am a Rejected Query">
                                <VerifiedIcon sx={{ color: 'red', mt: 1 }} />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Select to Resolve / Reject">
                                <Checkbox
                                  checked={isItemSelected}
                                  disabled={!!(row.status === 'Resolved')}
                                  onChange={(event) => handleClick(event, row._id)}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell align="left" component="th" scope="row" padding="none">
                            {row.employee.name}
                          </TableCell>
                          <TableCell>{row.subject}</TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Typography variant="subtitle2" noWrap>
                                Show Query
                              </Typography>
                              <VisibilityIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={(event) =>
                                  setAnchorEl({
                                    id: `info_${row._id}`,
                                    target: event.currentTarget
                                  })
                                }
                              />

                              <Popover
                                id={`info_${row._id}`}
                                open={anchorEl.id === `info_${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <Typography sx={{ p: 2 }}>{row.description}</Typography>
                              </Popover>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar alt={`${row.name}_query`} src={row.image} />
                              <Typography variant="subtitle2" noWrap>
                                {row.name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{DD_MM_YYYY(row.createdAt)}</TableCell>
                          <TableCell align="left">
                            <Typography
                              // variant="outlined"
                              sx={{
                                // backgroundColor: `${row.status === 'Pending' ? '#FFCC00' : ''}`
                                color: `${
                                  row.status === 'Pending'
                                    ? '#FFCC00'
                                    : row.status === 'Resolved'
                                    ? 'green'
                                    : 'red'
                                }`,
                                fontWeight: 'bold'
                              }}
                            >
                              {row.status}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {row.status === 'Pending' && (
                              <QueriesMoreMenu
                                resolveQueries={() => resolveQueries([row._id])}
                                rejectQueries={() => rejectQueries([row._id])}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            {/* Put the scroll bar always on the bottom */}
            <InfiniteScroll
              dataLength={rows?.length}
              next={fetchNextQueries}
              // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
              // inverse //
              hasMore={hasNextQueries}
              loader={<h4>Loading...</h4>}
              scrollableTarget="queries-table-container"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            />
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
    </Page>
  );
}

export default Queries;
