import React from 'react';
import PropTypes from 'prop-types';
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot
} from '@mui/lab';
import { Box, Typography, Stack, Chip, Avatar, IconButton } from '@mui/material';
// import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { englishDateFormat, _12hourTimeFormat } from '../utils/functions';

ScheduleItem.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool
};

function ScheduleItem({ item, isLast }) {
  const { type, time, shifts } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            bgcolor: 'primary.main'
          }}
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="subtitle2">{time}</Typography>
        {shifts.map((shift) => (
          <Stack
            sx={{ border: '1px solid #BFC9CA', p: 1, mt: 1, borderRadius: 0.5 }}
            key={`timeline-${shift._id}`}
          >
            <Typography sx={{ fontSize: 13 }}>
              <b>
                {shift.name}, {shift.site.name}
              </b>
            </Typography>
            <Typography sx={{ fontSize: 11 }}>
              {_12hourTimeFormat(shift.start)} - {_12hourTimeFormat(shift.end)}
            </Typography>
          </Stack>
        ))}
      </TimelineContent>
    </TimelineItem>
  );
}

export default function ScheduleTimeline({ emp, setSchedule }) {
  const TIMELINES = React.useMemo(() => {
    const timeline = [];
    if (emp) {
      emp.schedule.forEach((sch) => {
        timeline.push({
          id: sch._id,
          time: englishDateFormat(sch.date),
          type: '',
          shifts: sch.shifts
        });
      });
    }
    return timeline;
  }, [emp]);

  return (
    <Stack
      className="custom_scrollbar"
      sx={{ overflowY: 'auto', px: 1, height: 'calc(100vh - 150px)' }}
    >
      <Stack
        sx={{ p: 1, position: 'relative' }}
        direction="row"
        justifyContent="center"
        alignContent="center"
      >
        {setSchedule && (
          <IconButton
            onClick={() => setSchedule(null)}
            sx={{ position: 'absolute', left: 0, top: 0 }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Stack>
          <Stack sx={{ mb: 0.5 }} direction="row" justifyContent="center">
            <Avatar alt={emp.name} src={emp.avatar} sx={{ mr: 1 }} />
          </Stack>
          <Typography>
            {emp.name}
            &nbsp;
            <Chip
              size="small"
              label={emp.role.name}
              sx={{ fontSize: 11, height: 18, backgroundColor: '#3366FF', color: 'white' }}
            />
          </Typography>
        </Stack>
      </Stack>
      <Typography sx={{ my: 1 }} align="center">
        Schedule
      </Typography>
      <Box
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none'
          }
        }}
      >
        <Timeline>
          {TIMELINES.map((item, index) => (
            <ScheduleItem key={item.id} item={item} isLast={index === TIMELINES.length - 1} />
          ))}
        </Timeline>
      </Box>
    </Stack>
  );
}

ScheduleTimeline.propTypes = {
  emp: PropTypes.object,
  setSchedule: PropTypes.func
};
