import { useInfiniteQuery, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchLeaves, fetchRemainingLeaves } from '../api/queries/Queries';

export const useLeaves = (from, to, reason, status, search, employee, type, searchBy) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(
    ['pending-leaves', from, to, reason, status, search, employee, type, searchBy],
    fetchLeaves,
    {
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};

export const useRemainingLeaves = (employee, years, admin) => {
  const dispatch = useDispatch();
  return useQuery(['remaining-leaves', employee, years, admin], fetchRemainingLeaves, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    enabled: years?.length > 0 && admin,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};
