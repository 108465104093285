import React from 'react';
// material
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
// import WorkOffIcon from '@mui/icons-material/WorkOff';
// import TextSnippetIcon from '@mui/icons-material/TextSnippet';
// import { useTheme } from '@mui/material/styles';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// components
import Page from '../components/Page';
import { useDashboardCards } from '../hooks/dashboard';
import { fetchNotificationsTopics } from '../api/queries/Queries';
// import GetReinforcement from '../components/_dashboard/app/GetReinforcement';
// import RequestReinforcement from '../components/_dashboard/app/RequestReinforcement';
// import { shortMonth } from '../utils/config';
import { convertUTCtoLocal, getDataFromStorage, setDataInStorage } from '../utils/functions';
import { subscribeTokenToTopic } from '../firebaseInit';
import Employbility from '../components/_dashboard/app/Employbility';
// import Reinforcement from '../components/Reinforcement';

// ----------------------------------------------------------------------
const AppWeeklySales = React.lazy(() => import('../components/_dashboard/app/AppWeeklySales'));
const AppNewUsers = React.lazy(() => import('../components/_dashboard/app/AppNewUsers'));
const AppItemOrders = React.lazy(() => import('../components/_dashboard/app/AppItemOrders'));
const AppBugReports = React.lazy(() => import('../components/_dashboard/app/AppBugReports'));
const AppHolidays = React.lazy(() => import('../components/_dashboard/app/AppHolidays'));
const AppCurrentVisits = React.lazy(() => import('../components/_dashboard/app/AppCurrentVisits'));
const ScheduleAttendance = React.lazy(() =>
  import('../components/_dashboard/app/ScheduleAttendance')
);
const AppConversionRates = React.lazy(() =>
  import('../components/_dashboard/app/AppConversionRates')
);
const Feed = React.lazy(() => import('../components/_dashboard/app/Feed'));

function Dashboard({ userPolicy, shiftAttendance, checkAttendance }) {
  const today = new Date();
  // const theme = useTheme();
  const { info } = useSelector((state) => state.adminInfo);
  const [dashboardDay, setDashboardDay] = React.useState(today);
  const [showReqMonthAlert, setShowReqMonthAler] = React.useState(
    getDataFromStorage('req_month_alert') ?? true
  );
  // const [showHolidays, setShowHolidays] = useState(false);
  // const [reinforcementRequest, setReinforcementRequest] = React.useState(false);
  const {
    isLoading: isDashboardLoading,
    // isError: isDashboardError,
    data: dashboardCards
  } = useDashboardCards();

  const notificationsTopics = React.useMemo(async () => {
    if (!info?.isMainAdmin) {
      const _topics = await fetchNotificationsTopics();
      return _topics;
    }
    return [];
  }, [info]);

  React.useEffect(() => {
    const registrationToken = getDataFromStorage('fcm_token');
    if (notificationsTopics?.topics?.length > 0) {
      // setDataInStorage('notification_topics', notificationsTopics?.topics);
      notificationsTopics?.topics.forEach((topic) => {
        subscribeTokenToTopic(registrationToken, topic);
      });
    }
  }, [notificationsTopics]);

  // useEffect(() => {
  //   console.log(monthDate);
  // }, [monthDate]); #637381

  return (
    <Page title="Dashboard | Trackkar">
      <Container maxWidth="xl" sx={{ pb: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ py: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack>
              <MuiDatePicker
                // views={['year', 'month']}
                // label="Year and Month"
                minDate={new Date('2012-03-01')}
                // maxDate={new Date('2023-06-01')}
                value={dashboardDay}
                onChange={(newValue) => {
                  setDashboardDay(newValue);
                }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} helperText={null} />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Stack>
        <Grid container spacing={3}>
          {new Date().getDate() === 1 && showReqMonthAlert && (
            <Grid item xs={12}>
              <Alert severity="info" onClose={() => setDataInStorage('req_month_alert', false)}>
                Yay!, New Month New Journey, Please do revise your shifts requirement in every sites
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales loading={isDashboardLoading} value={dashboardCards?.employees} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers loading={isDashboardLoading} value={dashboardCards?.sites} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders loading={isDashboardLoading} value={dashboardCards?.pendingLeaves} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports loading={isDashboardLoading} value={dashboardCards?.pendingQueries} />
          </Grid>
          {!info?.isMainAdmin && (
            <Grid item xs={12}>
              <ScheduleAttendance
                userPolicy={userPolicy}
                shiftAttendance={shiftAttendance}
                checkAttendance={checkAttendance}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6}>
            <AppCurrentVisits />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <AppConversionRates dayFilter={convertUTCtoLocal(dashboardDay, true)} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Employbility />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <AppHolidays list show listContainerHeight={390} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Feed />
          </Grid>
          {/* <Grid item xs={12}>
            <EmployeesWorkHours />
          </Grid> */}
          {/* <Grid item xs={12}>
            <Card>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ px: 2 }}
              >
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                  Reinforcement Requests&nbsp;
                  <InfoIcon sx={{ fontSize: 16, cursor: 'pointer' }} />
                </Typography>
                <Stack direction="row" spacing={1}>
                  {info?.role?.is_site_manager && (
                    <IconButton
                      sx={{ color: theme.palette.primary.main }}
                      onClick={() => setReinforcementRequest(true)}
                    >
                      <Tooltip title="Apply">
                        <AddCircleIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                  {reinforcementStatus === null ? (
                    <IconButton onClick={() => setReinforcementStatus('Pending')}>
                      <Tooltip title="Pending">
                        <PendingActionsIcon />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setReinforcementStatus(null)}>
                      <Tooltip title="History">
                        <HistoryIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                </Stack>
              </Stack>
              <Divider />
              <GetReinforcement status={reinforcementStatus} />
            </Card>
          </Grid> */}
        </Grid>
        {/* <RequestReinforcementDialog
          open={reinforcementRequest}
          onClose={() => setReinforcementRequest(false)}
        /> */}
      </Container>
    </Page>
  );
}

export default React.memo(Dashboard);
