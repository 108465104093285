import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { sendMobileOTP, sendEmailOTP } from '../api/queries/Queries';

export const useSendMobileOTP = (mobile, countryCode, resend) => {
  const dispatch = useDispatch();
  // console.log('isCalled');s
  return useQuery(['send-mobile-otp', mobile, countryCode, resend], sendMobileOTP, {
    enabled: !!mobile,
    onError: (error) => {
      // status: 401 unauthorized
      // console.log(error.response.status);
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export const useSendEmailOTP = (email, resend) => {
  const dispatch = useDispatch();
  // console.log('isCalled');s
  return useQuery(['send-email-otp', email, resend], sendEmailOTP, {
    enabled: !!email,
    onError: (error) => {
      // status: 401 unauthorized
      // console.log(error.response.status);
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};
