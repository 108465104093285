import PieChartIcon from '@mui/icons-material/PieChart';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

// ----------------------------------------------------------------------

const sidebarConfig = {
  admin: [
    {
      title: 'Company',
      path: '/profile/app',
      icon: <PieChartIcon />
    },
    {
      title: 'Heirarchy',
      path: '/profile/heirarchy',
      icon: <PeopleIcon />
    },
    {
      title: 'Change Password',
      path: '/profile/change_password',
      icon: <PeopleIcon />
    }
  ],
  employee: [
    {
      title: 'Profile',
      path: '/profile/app',
      icon: <PieChartIcon />
    },
    {
      title: 'Heirarchy',
      path: '/profile/heirarchy',
      icon: <PeopleIcon />
    },
    {
      title: 'Leaves',
      path: '/profile/leaves',
      icon: <PeopleIcon />
    },
    {
      title: 'Change Password',
      path: '/profile/change_password',
      icon: <PeopleIcon />
    }
  ]
};

export default sidebarConfig;
