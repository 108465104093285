import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
//
import RosterNavbar from './RosterNavbar';
import RosterSidebar from './RSidebar';
import { getDataFromStorage, convertUTCtoLocal } from '../../utils/functions';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 60;
const APP_BAR_DESKTOP = 80;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE, // + 24
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP, // + 24
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function RosterLayout() {
  // const params = useParams();
  // const selectedSite = params.site.replace(':', '');
  const [open, setOpen] = React.useState(false);
  const [showSidebar, setShowSidebar] = React.useState(false);
  const [rMonth, setRMonth] = React.useState(null);
  const { isAdminAuthenticated } = useSelector((state) => state.adminInfo);
  const updateProgress = () => {
    const pp = getDataFromStorage('roster_month');
    setRMonth(convertUTCtoLocal(pp, true));
  };

  if (!isAdminAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <RootStyle>
      <RosterNavbar
        onOpenSidebar={() => setOpen(true)}
        showSidebar={showSidebar}
        setShowSidebar={() => setShowSidebar(true)}
      />
      <RosterSidebar
        rMonth={rMonth}
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        show={showSidebar}
        setShowSidebar={setShowSidebar}
      />
      <Button
        hidden
        sx={{ display: 'none' }}
        id="update-sidebar-progress"
        onClick={updateProgress}
      />
      {/* Update Sidebar Availability */}
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
