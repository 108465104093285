/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
// import { filter } from 'lodash';
import React, { useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik, FormikProvider, Form } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TableBody from '@mui/material/TableBody';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import { CKEditor } from 'ckeditor4-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// icons
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SubjectIcon from '@mui/icons-material/Subject';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ImageZoom } from '../components';
// import {
//   SiteFeedbackListHead,
//   SiteFeedbackListToolbar,
//   SiteFeedbackMoreMenu
// } from '../components/_dashboard/site_feedback';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
// hooks
import { useRoles } from '../hooks/roles';
import { useOfferLetterTemplate } from '../hooks/get-offer-letter-template';
import useResumes from '../hooks/get-resumes';
import {
  createShortenURL,
  updateResumeStatus,
  makeEmployee,
  saveOfferLetterTemplate
} from '../api/mutations/adminMutations';
import useMutationFunc from '../api/mutations/mutation';
// utils
import { frontendBaseURL, SWAL_TIME } from '../utils/config';
import {
  DD_MM_YYYY,
  getDataFromStorage,
  convertUTCtoLocal,
  Encrypt,
  randomString,
  uid,
  getCurrentDate
} from '../utils/functions';
import NotAvailable from '../components/NotAvailable';
// redux
import { logOut } from '../redux/actions/adminActions';

// ---------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'employee', label: 'Employee', alignRight: false },
  { id: 'contact', label: 'Contact', alignRight: false },
  { id: 'dob', label: 'DOB', alignRight: false },
  { id: 'added_by', label: 'Added By', alignRight: false },
  { id: '' }
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  //   border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 2,
  p: 4
};

const styleOfferLetter = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 'calc(100vw - 20rem)',
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
};
// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// --------------------------------------------------------------

function Resumes() {
  // const [page, setPage] = useState(0);
  const cache = useQueryClient();
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [getResume, setGetResume] = useState(false);
  const [createOfferLetter, setCreateOfferLetter] = useState(false);

  // Roles initial State for select
  const [roleOptions, setRoleOptions] = useState();
  const [roleType, setRoleType] = useState();

  const [addEmployee, setAddEmployee] = useState(false);
  const { info } = useSelector((state) => state.adminInfo);

  // Change Tab Panel
  const [value, setValue] = React.useState('Recruit');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Get Generated Link for resumes
  const [copied, setCopied] = useState(false);
  const [resumeURL, setResumeURL] = useState();
  const [src, setSrc] = useState(); // To View Image

  const [generateOffer, setGenerateOffer] = useState(null);
  const [viewOffer, setViewOffer] = useState();

  // Offer Letter Data
  const [offerLetterData, setOfferLetterData] = useState('');

  // Store Remove Link Info
  const [offerLetterInfo, setOfferLetterInfo] = useState({
    offer_date: undefined,
    office_location: undefined,
    prohibition_period: undefined,
    stipend: undefined,
    ctc: undefined,
    offer_expiration_date: undefined,
    company_signer: undefined,
    signer_title: undefined,
    signer_email: undefined
  });
  const {
    isLoading: offerLetterLoading,
    isError: offerLetterError,
    data: initOfferLetterData
  } = useOfferLetterTemplate();
  const OFFER_LETTER_DATA = useMemo(() => {
    let TEMPLATE = '';
    if (!offerLetterLoading && !offerLetterError) {
      TEMPLATE = initOfferLetterData.template;
    }
    return TEMPLATE;
  }, [initOfferLetterData, offerLetterError, offerLetterLoading]);

  // Store Resume Link Info
  const [getResumeInfo, setGetResumeInfo] = useState({
    title: undefined,
    description: undefined,
    expiry: undefined,
    cipher: undefined,
    key: undefined
  });
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    site: '',
    employee: ''
  });

  const selectedSite = getDataFromStorage('selected_site');

  const {
    isLoading: isResumesLoading,
    isError: isResumesError,
    // hasNextPage: hasNextFeedbacks,
    // fetchNextPage: fetchNextFeedbacks,
    data: resumes
  } = useResumes(value);

  const {
    flattened: rows,
    recruit,
    shortlist,
    finalist,
    rejected,
    notInterested
  } = useMemo(() => {
    const listOfResumes = [];
    let shortlist = 0;
    let finalist = 0;
    let rejected = 0;
    let notInterested = 0;
    let recruit = 0;
    if (!isResumesLoading && !isResumesError) {
      if (resumes?.pages?.length > 0) {
        resumes.pages.forEach((group) => {
          listOfResumes.push(group.resumes);
          recruit = group.recruit;
          shortlist = group.shortlist;
          finalist = group.finalist;
          rejected = group.rejected;
          notInterested = group.notInterested;
        });
      }
    }
    const flattened = listOfResumes.flat();
    return {
      recruit,
      flattened,
      shortlist,
      finalist,
      rejected,
      notInterested
    };
  }, [resumes, isResumesLoading, isResumesError]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  // For Roster Sharing ====================================
  const tinyURLMutation = useMutationFunc(
    createShortenURL,
    {
      onMutate: { default: false },
      onSuccess: { default: false }
    },
    (response) => {
      // console.log('TinyURL Response:', response.data);
      setResumeURL(response.data.tiny_url);
    }
  );

  const { mutate: updateResumeMutation } = useMutation(updateResumeStatus, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('resumes');
    }
  });

  const makeEmployeeMutation = useMutation(makeEmployee, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Making Employee 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: (response) => {
      Swal.fire({
        icon: 'success',
        title: `Person selected is employed now!`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      setRoleType(null);
      cache.invalidateQueries('resumes');
    }
  });

  const createSharableLink = () => {
    const key = randomString(10);
    const resumeId = uid();
    const token = info?.isMainAdmin ? info?.username : info?.admin?.username;
    // console.log('Username:', token);
    const cipher = Encrypt(
      `${getResumeInfo?.title},${getResumeInfo?.description},${convertUTCtoLocal(
        getResumeInfo?.expiry,
        true
      )},${token},${resumeId}`,
      `sec ${key}`
    );
    const encryptCode = encodeURIComponent(cipher);
    setGetResumeInfo({ ...getResumeInfo, cipher: encryptCode, key });
  };

  React.useEffect(() => {
    // console.log('Code Info:', codeInfo);
    if (getResumeInfo?.key?.length > 0 && getResumeInfo?.cipher?.length > 0) {
      tinyURLMutation({
        url: `${frontendBaseURL}/sharing/get-resumes/:${getResumeInfo?.key}${getResumeInfo?.cipher}`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getResumeInfo, tinyURLMutation]);

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // Update Resume Status
  const updateResume = (_id, value) => {
    updateResumeMutation({
      resumeId: _id,
      status: value
    });
  };

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  React.useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      // console.log(allRoles);
      let priority = 0;
      allRoles.forEach((role) => {
        if (role.is_site_manager) {
          priority = role.priority;
        }
        if (role.priority > priority && priority > 0) {
          formattedRoles.push([role._id, role.name]);
        } else if (priority === 0 || role.priority === priority) {
          formattedRoles.push([role._id, role.name]);
        }
      });
      setRoleOptions([...formattedRoles]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allRoles]);

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const filteredUsers = rows;
  const isDataNotFound = filteredUsers.length === 0;

  const { mutate: saveOfferLetterMutation } = useMutation(saveOfferLetterTemplate, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Saved Offer Letter Template!`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    }
  });

  // Generate Offer Letter
  const generateOfferLetter = (data) => {
    let letterData = String(OFFER_LETTER_DATA);
    const obj = {
      company_name: info?.name,
      company_address: info?.address ?? '[company_address]',
      company_email_address: info?.email,
      employee_name: data?.name,
      employee_email: data?.email ?? '[employee_email]',
      offer_date: getCurrentDate() ?? '[offer_date]',
      office_location: offerLetterInfo?.office_location ?? '[office_location]',
      prohibition_period: offerLetterInfo?.prohibition_period ?? '[prohibition_period]',
      stipend: offerLetterInfo?.stipend ?? '[stipend]',
      ctc: offerLetterInfo?.ctc ?? '[ctc]',
      position: data?.job_title,
      offer_expiration_date: offerLetterInfo?.offer_expiration_date
        ? convertUTCtoLocal(offerLetterInfo?.offer_expiration_date)
        : '[offer_expiration_date]',
      company_signer: offerLetterInfo?.company_signer ?? '[company_signer]',
      signer_title: offerLetterInfo?.signer_title ?? '[signer_title]',
      signer_email: offerLetterInfo?.signer_email ?? '[signer_email]'
    };
    Object.keys(obj).forEach((key) => {
      const search = `[${key}]`;
      // console.log('Search Value:', search, obj[key]);
      letterData = letterData.replace(search, obj[key]);
    });
    setViewOffer(letterData);
  };

  return (
    <Page title="Resumes | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Resumes
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              variant="contained"
              to="#"
              startIcon={<SubjectIcon />}
              onClick={() => setCreateOfferLetter(true)}
            >
              Offer Letter Layout
            </Button>
            <Button
              variant="contained"
              to="#"
              startIcon={<SendTimeExtensionIcon />}
              onClick={() => setGetResume(true)}
            >
              Get Resumes
            </Button>
          </Stack>
        </Stack>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={`Recruit ( ${recruit} )`} value="Recruit" />
              <Tab label={`Shortlist ( ${shortlist} )`} value="Shortlist" />
              <Tab label={`Finalist ( ${finalist} )`} value="Finalist" />
              <Tab label={`Not interested ( ${notInterested} )`} value="Not interested" />
              <Tab label={`Rejected ( ${rejected} )`} value="Rejected" />
            </TabList>
          </Box>
          <TabPanel value={value}>
            <Stack spacing={2}>
              {isResumesLoading && <NotAvailable type="loading" />}
              {isResumesError && <NotAvailable type="na" />}
              {!isResumesLoading &&
                filteredUsers.map((row) => {
                  const isItemSelected = selected.indexOf(row?._id) !== -1;
                  // row.sites.forEach((site) => {
                  //   siteDict[site._id] = site.name;
                  // });
                  return (
                    <Card key={row._id} sx={{ p: 2 }}>
                      <CardHeader
                        avatar={
                          <Avatar src={row.avatar} sx={{ bgcolor: 'blue' }} aria-label="recipe">
                            {row.name.charAt(0)}
                          </Avatar>
                        }
                        // action={}
                        title={row.name}
                        subheader={`+${row.country_code} ${row.contact}`}
                      />
                      <Stack sx={{ mt: 2 }} direction="row" alignItems="center">
                        <Typography sx={{ mr: 2 }} variant="h6" color="primary">
                          Job Title
                        </Typography>
                        <Chip variant="contained" label={row.job_title} />
                      </Stack>
                      <Typography color="primary" sx={{ my: 2 }} variant="h6">
                        Personal Details
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item md={4} xs={12}>
                          Gender : {row.gender}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          DOB : {convertUTCtoLocal(row.dob, true)}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          Blood Group : {row.blood_group}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          Aadhar : {row?.aadhar?.value ?? 'XXXXXXXXXXX'}
                        </Grid>
                        <Grid item md={6} xs={12}>
                          Email : {row.email}
                        </Grid>
                        <Grid item md={6} xs={12}>
                          Marital Status : {row.marital_status}
                        </Grid>
                      </Grid>
                      <Typography color="primary" sx={{ my: 2 }} variant="h6">
                        Education Details
                      </Typography>
                      {row.education_details.map((educ) => (
                        <Grid key={educ.id} container spacing={1}>
                          <Grid item md={4} xs={12}>
                            Type : {educ.type}
                          </Grid>
                          <Grid item md={4} xs={12}>
                            Aggregate : {educ.aggregate} %
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Button
                              onClick={() => setSrc(educ.certificate)}
                              variant="text"
                              size="small"
                            >
                              View
                            </Button>
                          </Grid>
                        </Grid>
                      ))}
                      {row.education_details.length === 0 && 'XXXXXXXXXX'}
                      {/* <Typography color="primary" sx={{ my: 2 }} variant="h6">
                        Experience Details
                      </Typography>
                      {row.experience_details.map((expe) => (
                        <Grid key={expe.id} container spacing={1}>
                          <Grid item md={4} xs={12}>
                            From : {expe.from}
                          </Grid>
                          <Grid item md={4} xs={12}>
                            To : {expe.to}
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Button
                              onClick={() => setSrc(expe.certificate)}
                              variant="text"
                              size="small"
                            >
                              View
                            </Button>
                          </Grid>
                        </Grid>
                      ))} */}
                      <Typography color="primary" sx={{ my: 2 }} variant="h6">
                        Previous Employement Details
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item md={4} xs={12}>
                          Start : {row?.previous_employment_details?.previous_employ_start}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          To : {row?.previous_employment_details?.previous_employ_end}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          Organization :{' '}
                          {row?.previous_employment_details?.previous_organization_name}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          Designation : {row?.previous_employment_details?.previous_designation}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          CTC : {row?.previous_employment_details?.previous_ctc}
                        </Grid>
                      </Grid>
                      <Typography color="primary" sx={{ my: 2 }} variant="h6">
                        Permanent Address
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item md={4} xs={12}>
                          House No : {row?.permanent_address?.permanent_house_no}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          Village / Town : {row?.permanent_address?.permanent_village_town_street}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          City : {row?.permanent_address?.permanent_city.split(',')[1]}
                        </Grid>
                        {/* <Grid item md={4} xs={12}>
                          District : {row?.permanent_address?.permanent_district}
                        </Grid> */}
                        <Grid item md={4} xs={12}>
                          State : {row?.permanent_address?.permanent_state.split(',')[1]}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          Country : {row?.permanent_address?.permanent_country.split(',')[1]}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          Pincode : {row?.permanent_address?.permanent_pincode}
                        </Grid>
                      </Grid>
                      <Typography color="primary" sx={{ my: 2 }} variant="h6">
                        Correspondence Address
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item md={4} xs={12}>
                          House No : {row?.correspondence_address?.correspondence_house_no}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          Village / Town :{' '}
                          {row?.correspondence_address?.correspondence_village_town_street}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          City : {row?.correspondence_address?.correspondence_city.split(',')[1]}
                        </Grid>
                        {/* <Grid item md={4} xs={12}>
                          District : {row?.correspondence_address?.correspondence_district}
                        </Grid> */}
                        <Grid item md={4} xs={12}>
                          State : {row?.correspondence_address?.correspondence_state.split(',')[1]}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          Country :{' '}
                          {row?.correspondence_address?.correspondence_country.split(',')[1]}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          Pincode : {row?.correspondence_address?.correspondence_pincode}
                        </Grid>
                      </Grid>
                      <Typography color="primary" sx={{ my: 2 }} variant="h6">
                        Bank Details
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item md={4} xs={12}>
                          Bank Name : {row?.bank_details.bank_name ?? 'XXXXXXXXXX'}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          Bank Branch : {row?.bank_details.bank_branch ?? 'XXXXXXXXXX'}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          Bank IFSC : {row?.bank_details.bank_ifsc_code ?? 'XXXXX'}
                        </Grid>
                        <Grid item md={4} xs={12}>
                          A/C No : {row?.bank_details?.bank_account_no ?? 'XXXXXXXXX'}
                        </Grid>
                      </Grid>
                      <Grid item style={{ display: 'flex', alignItems: 'center' }} xs={12}>
                        <Typography color="primary" sx={{ my: 2, mr: 2 }} variant="h6">
                          Emergency Contact No.
                        </Typography>
                        +{row?.emergency_contact?.country_code ?? 'XXX'}{' '}
                        {row?.emergency_contact?.contact_no ?? 'XXXXXXXXXX'}
                      </Grid>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography color="primary" sx={{ my: 2 }} variant="h6">
                          Resume
                        </Typography>
                        {row.applicant_resume ? (
                          <Button
                            sx={{ ml: 2 }}
                            startIcon={<VisibilityOutlinedIcon />}
                            onClick={() => window.open(row?.applicant_resume, '_blank')}
                          >
                            View
                          </Button>
                        ) : (
                          <Typography variant="subtitles">File Not Available</Typography>
                        )}
                      </Stack>
                      <br />
                      <Divider />
                      <CardActions
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Box>
                          {value !== 'Rejected' && (
                            <Button
                              sx={{ mt: 2.1, mx: 1 }}
                              onClick={() => updateResume(row._id, 'Rejected')}
                              variant="outlined"
                              color="error"
                              size="small"
                            >
                              Reject
                            </Button>
                          )}
                          {value !== 'Not interested' && (
                            <Button
                              sx={{ mt: 2.1, mx: 1 }}
                              onClick={() => updateResume(row._id, 'Not interested')}
                              variant="outlined"
                              color="error"
                              size="small"
                            >
                              Not interested
                            </Button>
                          )}
                          {value !== 'Shortlist' && (
                            <Button
                              sx={{ mt: 2.1, mx: 1 }}
                              onClick={() => updateResume(row._id, 'Shortlist')}
                              variant="outlined"
                              color="secondary"
                              size="small"
                            >
                              Shortist
                            </Button>
                          )}
                          {value !== 'Finalist' && (
                            <Button
                              sx={{ mt: 2.1, mx: 1 }}
                              onClick={() => updateResume(row._id, 'Finalist')}
                              variant="outlined"
                              color="success"
                              size="small"
                            >
                              Finalist
                            </Button>
                          )}
                        </Box>
                        <Box>
                          <Button
                            sx={{ mt: 2.1, mx: 1 }}
                            onClick={() => setGenerateOffer(row)}
                            variant="outlined"
                            // color="black"
                            size="small"
                          >
                            Generate Offer Letter
                          </Button>
                          {addEmployee ? (
                            <>
                              <FormControl sx={{ minWidth: 200, mx: 2 }}>
                                <InputLabel variant="standard" htmlFor="role_type">
                                  Choose Role
                                </InputLabel>
                                <Select
                                  variant="standard"
                                  inputProps={{
                                    name: 'role_type',
                                    id: 'role_type'
                                  }}
                                  required
                                  onChange={(e) => setRoleType(e.target.value)}
                                >
                                  <MenuItem value="" disabled>
                                    Choose Role
                                  </MenuItem>
                                  {roleOptions?.length > 0 ? (
                                    roleOptions.map(([key, value, image]) => (
                                      <MenuItem
                                        key={`${'role_type'}-${key}`}
                                        value={key}
                                        data-icon={image}
                                      >
                                        {value}
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem value="" disabled />
                                  )}
                                </Select>
                              </FormControl>
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                sx={{ mt: 2.1 }}
                                onClick={() => {
                                  Swal.fire({
                                    title: 'Are you sure?',
                                    text: `Person will become an employee`,
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, Proceed!',
                                    allowOutsideClick: false
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      makeEmployeeMutation.mutate({
                                        resumeId: row._id,
                                        role: roleType,
                                        doj: getCurrentDate()
                                      });
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                      cache.invalidateQueries('resumes');
                                    }
                                  });
                                }}
                              >
                                Make
                              </Button>
                            </>
                          ) : (
                            <Button
                              onClick={() => setAddEmployee(true)}
                              sx={{ mt: 2.1 }}
                              variant="outlined"
                              color="primary"
                              size="small"
                            >
                              Make Employee
                            </Button>
                          )}
                        </Box>
                      </CardActions>
                    </Card>
                  );
                })}
              {isDataNotFound && <NotAvailable type="na" msg="No Resume Yet Available" />}
            </Stack>
          </TabPanel>
        </TabContext>
      </Container>
      <Modal
        keepMounted
        open={getResume}
        onClose={() => setGetResume(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Stack spacing={2}>
            <TextField
              variant="standard"
              type="text"
              onChange={(e) => {
                setGetResumeInfo({ ...getResumeInfo, title: e.target.value });
              }}
              label="Job Title"
            />
            <TextField
              type="text"
              multiline
              rows="6"
              onChange={(e) => {
                setGetResumeInfo({ ...getResumeInfo, description: e.target.value });
              }}
              sx={{ width: '100%' }}
              label="Job Description"
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Expiry Date"
                value={getResumeInfo?.expiry}
                onChange={(newValue) => {
                  setGetResumeInfo({ ...getResumeInfo, expiry: newValue });
                }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
            {resumeURL ? (
              <Stack direction="row" justifyContent="center" alignItems="center">
                <TextField readOnly sx={{ minWidth: 300 }} value={`${resumeURL ?? 'Loading...'}`} />
                <Stack sx={{ p: 2 }}>
                  <CopyToClipboard text={`${resumeURL}`} onCopy={() => setCopied(true)}>
                    <IconButton disabled={!resumeURL}>
                      <ContentCopyIcon sx={{ cursor: 'pointer' }} />
                    </IconButton>
                  </CopyToClipboard>
                </Stack>
              </Stack>
            ) : (
              <Button onClick={() => createSharableLink()}>Generate Link</Button>
            )}
            {copied && <Alert severity="info">Copied To Clipboard!!</Alert>}
          </Stack>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={createOfferLetter}
        onClose={() => setCreateOfferLetter(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleOfferLetter}>
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{
              p: 2,
              backgroundColor: '#2a2a72',
              backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
            }}
          >
            <Typography
              id="modal-modal-title"
              color="white"
              variant="h6"
              sx={{ ml: 2 }}
              component="h2"
            >
              Offer Letter Layout
            </Typography>
            <IconButton
              onClick={() => setCreateOfferLetter(false)}
              sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack sx={{ p: 2 }} spacing={1}>
            <Typography>
              <b>Company Details</b>
              <Typography sx={{ ml: 2 }} component="span">
                [company_name], [company_address], [company_email_address]
              </Typography>
            </Typography>
            <Typography>
              <b>Offer Details</b>
              <Typography sx={{ ml: 2 }} component="span">
                [employee_name], [employee_email], [offer_date], [office_location],
                [prohibition_period], [stipend], [ctc], [position], [offer_expiration_date],
                [company_signer], [signer_title], [signer_email]
              </Typography>
            </Typography>
          </Stack>
          <Box sx={{ p: 2 }}>
            {!offerLetterLoading && !offerLetterError ? (
              <CKEditor
                style={{ height: 800 }}
                onReady={(editor) => {
                  editor.ui.view.editable.element.style.minHeight = 'calc(100vh - 10rem)';
                }}
                onChange={(e) => setOfferLetterData(e.editor.getData())}
                type="classic"
                initData={OFFER_LETTER_DATA}
              />
            ) : null}
          </Box>
          <Button
            onClick={() => {
              setCreateOfferLetter(false);
              Swal.fire({
                title: 'Are you sure?',
                text: `Changes will be updated to the template!`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, Proceed!',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                  saveOfferLetterMutation({
                    template: offerLetterData.length > 0 ? offerLetterData : OFFER_LETTER_DATA
                  });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  // cache.invalidateQueries('potential-site-members');
                }
              });
            }}
            sx={{ m: 2 }}
            variant="contained"
            size="small"
          >
            Save
          </Button>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={generateOffer !== null}
        onClose={() => setGenerateOffer(null)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleOfferLetter}>
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{
              p: 2,
              backgroundColor: '#2a2a72',
              backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
            }}
          >
            <Typography
              id="modal-modal-title"
              color="white"
              variant="h6"
              sx={{ ml: 2 }}
              component="h2"
            >
              Generate Offer Letter
            </Typography>
            <IconButton
              onClick={() => setGenerateOffer(null)}
              sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box sx={{ overflow: 'auto', height: 'calc(100vh - 5rem)' }}>
            <Stack sx={{ p: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      setOfferLetterInfo({ ...offerLetterInfo, office_location: e.target.value });
                    }}
                    sx={{ width: '100%' }}
                    label="Office Location"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      setOfferLetterInfo({
                        ...offerLetterInfo,
                        prohibition_period: e.target.value
                      });
                    }}
                    sx={{ width: '100%' }}
                    label="Prohibition Period ( In Months )"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      setOfferLetterInfo({ ...offerLetterInfo, stipend: e.target.value });
                    }}
                    sx={{ width: '100%' }}
                    label="Stipend"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      setOfferLetterInfo({ ...offerLetterInfo, ctc: e.target.value });
                    }}
                    sx={{ width: '100%' }}
                    label="CTC"
                  />
                </Grid>
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Offer Expiration Date"
                      value={offerLetterInfo?.offer_expiration_date}
                      onChange={(newValue) => {
                        setOfferLetterInfo({ ...offerLetterInfo, offer_expiration_date: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} helperText={null} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      setOfferLetterInfo({ ...offerLetterInfo, company_signer: e.target.value });
                    }}
                    sx={{ width: '100%' }}
                    label="Company Signer"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      setOfferLetterInfo({ ...offerLetterInfo, signer_title: e.target.value });
                    }}
                    sx={{ width: '100%' }}
                    label="Signer's Title"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      setOfferLetterInfo({ ...offerLetterInfo, signer_email: e.target.value });
                    }}
                    sx={{ width: '100%' }}
                    label="Signer's Email"
                  />
                </Grid>
              </Grid>
            </Stack>
            <Button
              variant="contained"
              size="small"
              sx={{ mx: 1.5 }}
              onClick={() => generateOfferLetter(generateOffer)}
            >
              View
            </Button>
            {viewOffer && (
              <Box sx={{ p: 1, m: 1 }}>
                <Typography variant="h6" sx={{ my: 1 }}>
                  Offer Letter
                </Typography>
                <Box sx={{ border: '1px solid #333', my: 1, p: 1, borderRadius: 1 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: viewOffer
                    }}
                  />
                </Box>
                <Button variant="contained" size="small">
                  Download
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
      <ImageZoom src={src} onClose={() => setSrc(null)} />
    </Page>
  );
}

export default Resumes;
