import { useQuery, useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchDashboardCards, fetchHolidays, fetchSwaps } from '../api/queries/Queries';

export const useDashboardCards = (date) => {
  const dispatch = useDispatch();
  return useQuery(['dashboard-cards', date], fetchDashboardCards, {
    // getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export const useHolidays = (date, site, status) => {
  const dispatch = useDispatch();
  return useQuery(['holidays', date, site, status], fetchHolidays, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export const useFeeds = (status) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(['employees-swaps', status], fetchSwaps, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};
