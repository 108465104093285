import React, { useMemo } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useFormik, FormikProvider, Form } from 'formik';
import {
  Modal,
  IconButton,
  Stack,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Box,
  Button,
  Typography,
  Divider,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { useTheme } from '@mui/material/styles';
// import Swal from 'sweetalert2/src/sweetalert2';
// import '@sweetalert2/theme-material-ui/material-ui.css';
// icons
import useMutationFunc from '../../../api/mutations/mutation';
import { useRemainingLeaves } from '../../../hooks/leaves';
import { applyLeave } from '../../../api/mutations/adminMutations';
// components
import {
  convertUTCtoLocal,
  getCurrentDate,
  getLeaveYearsFromDates
} from '../../../utils/functions';
// import { SWAL_TIME, ADD_EMPLOYEE } from '../../../utils/config';
// redux
// import { logOut } from '../../../redux/actions/adminActions';
// ---------------------------------------------------------------

// const TABLE_HEAD = [
//   { id: 'site', label: 'Site', alignRight: false },
//   { id: 'feedback', label: 'Feedback', alignRight: false },
//   { id: 'added_on', label: 'Added On', alignRight: false },
//   { id: 'added_by', label: 'Added By', alignRight: false },
//   { id: '' }
// ];

// --------------------------------------------------------------

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

LeaveApply.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  userPolicy: PropTypes.object
};

function LeaveApply({ open, onClose, userPolicy }) {
  // const dispatch = useDispatch();
  const today = new Date();
  const cache = useQueryClient();
  // const theme = useTheme();
  // eslint-disable-next-line prettier/prettier
  const { info } = useSelector((state) => state.adminInfo);
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const maxDate = new Date(today.getFullYear() + 1, userPolicy?.startMonth - 1, 1, 0, 0, 0, 0);
  const applyLeaveMutation = useMutationFunc(
    applyLeave,
    {
      onMutate: {
        title: 'Applying Leave...',
        text: 'this may take a while...'
      },
      onSuccess: {
        title: 'Successfully Applied!!'
      }
    },
    null,
    () => {
      cache.invalidateQueries();
    },
    () => onClose(),
    null
  );

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: '',
      from: fromDate ?? null,
      to: toDate ?? null
    },
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason is required'),
      from: Yup.string().required('From Date is required'),
      to: Yup.string().required('To Date is Required')
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        reason: values.reason,
        from: convertUTCtoLocal(fromDate, true),
        to: convertUTCtoLocal(toDate, true),
        today: getCurrentDate(),
        employee: info._id
      };

      // console.log('Apply For Leave Form :', data);
      applyLeaveMutation(data);
    }
  });

  const {
    // errors,
    // touched,
    values,
    // isSubmitting,
    handleSubmit,
    // handleReset,
    getFieldProps
  } = formik;

  const {
    isLoading: isRLeavesLoading,
    isError: isRLeavesError,
    data: rLeaves
  } = useRemainingLeaves(
    info._id,
    getLeaveYearsFromDates([fromDate, toDate], userPolicy?.startMonth).toString(),
    !info?.isMainAdmin
  );

  const TotalLeave = useMemo(() => {
    let sickTotal = 0;
    let casualTotal = 0;
    if (!isRLeavesLoading && !isRLeavesError && rLeaves) {
      Object.values(rLeaves?.remaining).forEach((lv) => {
        sickTotal += lv.sick;
        casualTotal += lv.casual;
      });
    }
    return { sick: sickTotal, casual: casualTotal };
  }, [isRLeavesLoading, isRLeavesError, rLeaves]);

  return (
    <Modal
      open={open ?? false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} container>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 2,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          {/* <img src={ADD_EMPLOYEE} width="40" height="40" alt="edit_icon" /> */}
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            Apply Leave
          </Typography>
          <IconButton
            onClick={() => onClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <Stack
              spacing={2}
              sx={{ py: 2, px: 1.5, position: 'relative', height: 'calc(100vh - 5rem)' }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="From"
                  minDate={new Date()}
                  value={fromDate}
                  onChange={(newValue) => {
                    setFromDate(newValue);
                  }}
                  renderInput={(params) => <TextField variant="standard" {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="To"
                  disabled={!fromDate}
                  minDate={fromDate}
                  maxDate={maxDate}
                  value={toDate}
                  onChange={(newValue) => {
                    setToDate(newValue);
                  }}
                  renderInput={(params) => <TextField variant="standard" {...params} />}
                />
              </LocalizationProvider>
              <FormControl sx={{ mt: 2.5 }}>
                <InputLabel sx={{ ml: -1.5 }} id="leave-type">
                  Type
                </InputLabel>
                <Select
                  labelId="leave-type"
                  id="leave-select"
                  variant="standard"
                  required
                  label=""
                  {...getFieldProps('reason')}
                >
                  <MenuItem value="Casual">Casual</MenuItem>
                  <MenuItem value="Sick">Sick</MenuItem>
                </Select>
              </FormControl>
              <br />
              {!isRLeavesLoading && !isRLeavesError && toDate && (
                <>
                  <Stack spacing={1}>
                    <Typography>Remaining Leaves</Typography>
                    <Divider />
                    {Object.entries(rLeaves?.remaining ?? {}).map(([key, value]) => (
                      <Stack key={key}>
                        <Typography sx={{ fontWeight: 'bold' }}>{key}</Typography>
                        <Typography>Sick : {value?.sick}</Typography>
                        <Typography>Casual : {value?.casual}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                  {TotalLeave?.sick === 0 ||
                    (TotalLeave?.casual === 0 && (
                      <Alert sx={{ position: 'absolute', bottom: 0 }} severity="info">
                        No Leaves Left to Apply!
                      </Alert>
                    ))}
                </>
              )}
              <Button
                type="submit"
                disabled={
                  !toDate &&
                  !(
                    (TotalLeave?.sick > 0 && values.reason === 'Sick') ||
                    (TotalLeave?.casual > 0 && values.reason === 'Casual')
                  )
                }
                sx={{ maxWidth: 490, position: 'absolute', bottom: 0 }}
                variant="contained"
              >
                Apply
              </Button>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Modal>
  );
}

export default LeaveApply;
