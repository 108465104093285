import React, { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FilterListIcon from '@mui/icons-material/FilterList';
import Swal from 'sweetalert2/src/sweetalert2';
import AddIcon from '@mui/icons-material/Add';
import '@sweetalert2/theme-material-ui/material-ui.css';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Toolbar,
  Button,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Chip,
  Avatar,
  ListSubheader,
  Popover,
  Divider
} from '@mui/material';
// icons
import EventIcon from '@mui/icons-material/Event';
import WcIcon from '@mui/icons-material/Wc';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PieChartIcon from '@mui/icons-material/PieChart';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import CancelIcon from '@mui/icons-material/Cancel';
// date picker
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  convertUTCtoLocal,
  // getUTCDate,
  getCurrentDate
  // getRandomColor
} from '../../../utils/functions';
import INDIAN from '../../../utils/INDIAN_STATES.json'; // importing indian states and uts
// hooks
import { useRoles } from '../../../hooks/roles';
import { useSubRoles } from '../../../hooks/subRoles';
import usePolicies from '../../../hooks/get-policies';
import useSites from '../../../hooks/get-sites-query';
import { useEmployeesCards } from '../../../hooks/employees';
import { useManagers } from '../../../hooks/get-managers';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 75,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 40,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  heading: PropTypes.string,
  actionButton: PropTypes.func,
  onFilterName: PropTypes.func,
  setFilters: PropTypes.func,
  filters: PropTypes.object,
  deleteEmployees: PropTypes.func,
  refetch: PropTypes.func
};

export default function UserListToolbar({
  heading,
  actionButton,
  numSelected,
  filterName,
  onFilterName,
  setFilters,
  filters,
  deleteEmployees
}) {
  const [anchorEl, setAnchorEl] = React.useState({
    id: '',
    target: ''
  });
  // const [anchorAddToSite, setAnchorAddToSite] = React.useState(null);
  const [roleOptions, setRoleOptions] = React.useState(); // Roles initial State for select
  const [subRoleOptions, setSubRoleOptions] = React.useState(); // Sub Roles initial State for select
  const [policyOptions, setPolicyOptions] = React.useState();
  const [managerOptions, setManagerOptions] = React.useState();
  const [, setSiteOptions] = React.useState();
  // const [subrolesCardsData, setSubrolesCardsData] = React.useState([]);
  const cache = useQueryClient();
  const [filter, setFilter] = React.useState({
    doj: '',
    state: '',
    gender: '',
    role: '',
    subRole: '',
    manager: '',
    policy: ''
  });

  /* ================= Employees Cards ==================== */

  const { data: employeesCards } = useEmployeesCards('assigned'); // getting employees cards

  /* ===================== Sites ===================== */

  const {
    isLoading,
    isError,
    data: sites
    // fetchNextPage: fetchNextSites,
    // hasMoreData: hasMoreSites,
    // refetch: refetchSites
  } = useSites();

  const allSites = useMemo(() => {
    const listOfSites = [];
    if (!isLoading && !isError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    return flattened;
  }, [sites, isLoading, isError]);

  React.useEffect(() => {
    const formattedSites = [];
    if (allSites?.length > 0) {
      allSites.forEach((site) => formattedSites.push([`${site._id},${site.name}`, site.name]));
      setSiteOptions([...formattedSites]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allSites, setSiteOptions]);

  /* ================= End Sites =============== */

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = React.useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  React.useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      allRoles.forEach((role) => formattedRoles.push([`${role._id},${role.name}`, role.name]));
      setRoleOptions([...formattedRoles]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allRoles]);
  /* ================= End Roles =============== */

  /* ================== Sub Roles ======================== */

  const {
    isLoading: isSubRolesLoading,
    isError: isSubRolesError,
    data: subRoles
  } = useSubRoles(filter.role.split(',')[0]);

  useMemo(() => {
    const listOfSubRoles = [];
    if (!isSubRolesLoading && !isSubRolesError) {
      if (subRoles?.roles?.length > 0) {
        subRoles.roles[0].subroles.forEach((role) =>
          listOfSubRoles.push([`${role._id},${role.name}`, role.name])
        );
      }
    }
    // console.log('Sub Roles By Role:', listOfSubRoles);
    setSubRoleOptions([...listOfSubRoles]);
  }, [subRoles, isSubRolesLoading, isSubRolesError]);

  /* ================== End Sub Roles ====================== */

  /* ===================== Policies =====================  */
  const {
    isPoliciesLoading,
    isPoliciesError,
    // fetchNextPage: fetchNextPolicies,
    // hasNextPage: hasNextPolicies,
    data: policies
  } = usePolicies(filter.role.split(',')[0]); // getting policies

  const Policies = React.useMemo(() => {
    const listOfPolicies = [];
    if (!isPoliciesLoading && !isPoliciesError) {
      if (policies?.pages?.length > 0) {
        policies.pages.forEach((group) => listOfPolicies.push(group.policies));
      }
    }
    const flattened = listOfPolicies.flat();
    return flattened;
  }, [policies, isPoliciesLoading, isPoliciesError]);

  React.useEffect(() => {
    // console.log(Policies);
    const formattedPolicies = [];
    if (Policies?.length > 0) {
      Policies.forEach((policy) =>
        formattedPolicies.push([`${policy._id},${policy.name}`, policy.name])
      );
      setPolicyOptions([...formattedPolicies]);
    }
  }, [Policies]);

  /* ===================== End Policies ==================  */
  /* ===================== Managers ===================== */

  const {
    isLoading: isManagersLoading,
    isError: isManagersError,
    data: managers
  } = useManagers(filter?.role.split(',')[0]); // gettings managers

  const Managers = React.useMemo(() => {
    // console.log('Managers :', managers);
    const listOfManagers = [];
    if (!isManagersLoading && !isManagersError) {
      if (managers?.pages?.length > 0) {
        managers.pages.forEach((group) => listOfManagers.push(group.managers));
      }
    }
    const flattened = listOfManagers.flat();
    return flattened;
  }, [managers, isManagersLoading, isManagersError]);

  React.useEffect(() => {
    const formattedManagers = [];
    if (Managers?.length > 0) {
      Managers.forEach((emp) =>
        formattedManagers.push([
          `${emp._id}, ${emp.name}`,
          emp?.avater,
          emp?.role?.name,
          emp?.subrole?.name
        ])
      );
    }
    // console.log('Managers Options', formattedManagers);
    setManagerOptions([...formattedManagers]);
  }, [Managers]);

  /* ================= End Managers =============== */

  // const defaultLabelStyle = {
  //   fontSize: '6px',
  //   fontFamily: 'sans-serif'
  // };

  const options = {
    title: 'Roles',
    legend: { position: 'bottom' },
    is3D: true,
    pieSliceText: 'label',
    slices: {
      4: { offset: 0.2 },
      12: { offset: 0.3 },
      14: { offset: 0.4 },
      15: { offset: 0.5 }
    }
  };

  const soptions = {
    title: 'Subroles',
    legend: { position: 'bottom' },
    is3D: true,
    pieSliceText: 'label',
    slices: {
      4: { offset: 0.2 },
      12: { offset: 0.3 },
      14: { offset: 0.4 },
      15: { offset: 0.5 }
    }
  };

  const cardsData = useMemo(() => {
    const data = [['Roles', 'Count']];
    const sdata = [['Subroles', 'Count']];
    if (allRoles.length > 0 && employeesCards?.employees.length > 0) {
      allRoles.forEach((role) => {
        const r = employeesCards.employees.find((rl) => rl.role === role._id) ?? {};
        data.push([role.name, r?.total ?? 0]);
        role?.subroles?.forEach((sr) => {
          sdata.push([
            `${sr?.name} ( ${role.name} )`,
            r?.subroles?.find((svr) => sr?._id === svr?.subrole)?.total ?? 0
          ]);
        });
      });
    }
    return { data, sdata };
  }, [allRoles, employeesCards]);

  return (
    <>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main'
            // bgcolor: 'primary.lighter'
          })
        }}
      >
        <Typography variant="h4" gutterBottom>
          {heading.split(',')[0]}
          <Typography component="span" sx={{ height: 20, ml: 1, fontSize: 13, fontWeight: 'bold' }}>
            ( {heading.split(',')[1]} )
          </Typography>
        </Typography>
        <Button variant="contained" startIcon={<AddIcon />} onClick={actionButton}>
          Employee
        </Button>
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Search Employee..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
        )}

        {numSelected > 0 ? (
          <Stack direction="row">
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Proceed!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteEmployees();
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      cache.invalidateQueries('employees');
                    }
                  });
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : (
          <Stack direction="row">
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  setAnchorEl({ ...anchorEl, id: 'filter_employees', target: event.currentTarget })
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            &nbsp;
            <Tooltip title="Employees">
              <IconButton
                onClick={(event) =>
                  setAnchorEl({ ...anchorEl, id: 'employees_cards', target: event.target })
                }
              >
                <PieChartIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </RootStyle>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 1, px: 2 }}
      >
        <Stack direction="row" alignItems="center">
          <FilterListIcon sx={{ fontSize: 20 }} />
          &nbsp;
          <Typography>Filters</Typography>
          &nbsp;
          <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
          &nbsp;
          <Chip
            sx={{ backgroundColor: '#1ABC9C', color: 'white' }}
            label={
              <Stack direction="row" alignItems="center">
                Joining :{' '}
                {filter.doj.length > 0 ? filter.doj.split('-').reverse().join('-') : 'All'}
                &nbsp;&nbsp;
                {filters.doj && (
                  <CancelIcon
                    onClick={() => {
                      setFilter({ ...filter, doj: '' });
                      setFilters({ ...filters, doj: '' });
                      cache.invalidateQueries('employees');
                    }}
                    sx={{ fontSize: 14, cursor: 'pointer' }}
                  />
                )}
              </Stack>
            }
            size="small"
          />
          &nbsp;
          <Chip
            sx={{ backgroundColor: '#8E44AD', color: 'white' }}
            label={
              <Stack direction="row" alignItems="center">
                State : {filters.state.length > 0 ? filter.state : 'All'}
                &nbsp;&nbsp;
                {filters.state && filter.state && (
                  <CancelIcon
                    onClick={() => {
                      setFilter({ ...filter, state: '' });
                      setFilters({ ...filters, state: '' });
                      cache.invalidateQueries('employees');
                    }}
                    sx={{ fontSize: 14, cursor: 'pointer' }}
                  />
                )}
              </Stack>
            }
            size="small"
          />
          &nbsp;
          <Chip
            sx={{ backgroundColor: '#2980B9', color: 'white' }}
            label={
              <Stack direction="row" alignItems="center">
                Policy : {filters.policy.split(',')[1] ?? 'All'}
                &nbsp;&nbsp;
                {filters.policy && (
                  <CancelIcon
                    onClick={() => {
                      setFilter({ ...filter, policy: '' });
                      setFilters({ ...filters, policy: '' });
                      cache.invalidateQueries('employees');
                    }}
                    sx={{ fontSize: 14, cursor: 'pointer' }}
                  />
                )}
              </Stack>
            }
            size="small"
          />
          &nbsp;
          <Chip
            color="secondary"
            label={
              <Stack direction="row" alignItems="center">
                Role : {filters.role.split(',')[1] ?? 'All'}
                &nbsp;&nbsp;
                {filters.role && (
                  <CancelIcon
                    onClick={() => {
                      setFilter({ ...filter, role: '' });
                      setFilters({ ...filters, role: '' });
                      cache.invalidateQueries('employees');
                    }}
                    sx={{ fontSize: 14, cursor: 'pointer' }}
                  />
                )}
              </Stack>
            }
            size="small"
          />
          &nbsp;
          <Chip
            sx={{ backgroundColor: '#DC7633', color: 'white', fontSize: 11 }}
            label={
              <Stack direction="row" alignItems="center">
                Sub Role : {filters.subRole.split(',')[1] ?? 'All'}
                &nbsp;&nbsp;
                {filters.subRole && (
                  <CancelIcon
                    onClick={() => {
                      setFilter({ ...filter, subRole: '' });
                      setFilters({ ...filters, subRole: '' });
                      cache.invalidateQueries('employees');
                    }}
                    sx={{ fontSize: 14, cursor: 'pointer' }}
                  />
                )}
              </Stack>
            }
            size="small"
          />
          &nbsp;
          <Chip
            sx={{ backgroundColor: '#D68910', color: 'white' }}
            label={
              <Stack direction="row" alignItems="center">
                Manager : {filters.manager.split(',')[1] ?? 'All'}
                &nbsp;&nbsp;
                {filters.manager && (
                  <CancelIcon
                    onClick={() => {
                      setFilter({ ...filter, manager: '' });
                      setFilters({ ...filters, manager: '' });
                      cache.invalidateQueries('employees');
                    }}
                    sx={{ fontSize: 14, cursor: 'pointer' }}
                  />
                )}
              </Stack>
            }
            size="small"
          />
          &nbsp;
          <Chip
            sx={{ backgroundColor: '#7B7D7D', color: 'white' }}
            label={
              <Stack direction="row" alignItems="center">
                Gender : {filter.gender.length > 0 ? filter.gender : 'All'}
                &nbsp;&nbsp;
                {filters.gender && (
                  <CancelIcon
                    onClick={() => {
                      setFilter({ ...filter, gender: '' });
                      setFilters({ ...filters, gender: '' });
                      cache.invalidateQueries('employees');
                    }}
                    sx={{ fontSize: 14, cursor: 'pointer' }}
                  />
                )}
              </Stack>
            }
            size="small"
          />
        </Stack>
        {filters.role ||
        filters.state ||
        filters.gender ||
        filters.manager ||
        filters.policy ||
        filters.doj ||
        filters.subRole ? (
          <Button
            variant="text"
            size="small"
            onClick={() => {
              setFilter({
                ...filter,
                doj: '',
                state: '',
                gender: '',
                role: '',
                subRole: '',
                manager: '',
                policy: ''
              });
              setFilters({
                ...filters,
                doj: '',
                state: '',
                gender: '',
                role: '',
                subRole: '',
                manager: '',
                policy: ''
              });
            }}
          >
            Clear All
          </Button>
        ) : null}
      </Stack>
      <Popover
        id="filter_employees"
        open={anchorEl.id === 'filter_employees'}
        anchorEl={anchorEl.target}
        onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack spacing={1} width="320px" sx={{ p: 2 }}>
          <Stack direction="row" alignItems="center">
            <FilterListIcon />
            <Typography sx={{ ml: 1 }}>Filters</Typography>
          </Stack>
          <Divider />
          {/* Date Of Joining */}
          <Box sx={{ display: 'flex', mt: 1, alignItems: 'flex-end' }}>
            <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date Of Joining"
                value={filter.doj.length > 0 ? filter.doj : getCurrentDate()}
                onChange={(dateOfJoining) => {
                  setFilter({ ...filter, doj: convertUTCtoLocal(dateOfJoining, true) });
                }}
                renderInput={(params) => <TextField variant="standard" fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Box>
          {/* Gender */}
          <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
            <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="gender_filter">
                Choose Gender
              </InputLabel>
              <Select
                variant="standard"
                value={filter.gender}
                inputProps={{
                  name: 'gender',
                  id: 'gender_filter'
                }}
                onChange={(e) => {
                  setFilter({ ...filter, gender: e.target.value });
                }}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Gender
                </MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/* State */}
          <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
            <LocationCityIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="grouped-select">
                Choose State
              </InputLabel>
              <Select
                variant="standard"
                value={filter.state}
                onChange={(e) => setFilter({ ...filter, state: e.target.value })}
                id="grouped-select"
                label="Choose State"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <ListSubheader>States</ListSubheader>
                {INDIAN?.states?.length > 0 ? (
                  INDIAN?.states.map((key) => (
                    <MenuItem key={`${'state'}-${key}`} value={key}>
                      {key}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
                <ListSubheader>Union Territories</ListSubheader>
                {INDIAN.union_territories?.length > 0 ? (
                  INDIAN.union_territories.map((key) => (
                    <MenuItem key={`${'territory'}-${key}`} value={key}>
                      {key}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          {/* Role */}
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="role_filter">
                Choose Role
              </InputLabel>
              <Select
                variant="standard"
                value={filter?.role}
                inputProps={{
                  name: 'role_filter',
                  id: 'role_filter'
                }}
                onChange={(e) => setFilter({ ...filter, role: e.target.value })}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Role
                </MenuItem>
                {roleOptions?.length > 0 ? (
                  roleOptions.map(([key, value, image]) => (
                    <MenuItem key={`${'role_filter'}-${key}`} value={key} data-icon={image}>
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <>
                    <MenuItem value="" disabled />
                  </>
                )}
              </Select>
            </FormControl>
          </Box>
          {/* Sub Role */}
          {filter.role && subRoleOptions?.length > 0 ? (
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="role_type">
                  Choose Sub Role
                </InputLabel>
                <Select
                  variant="standard"
                  disabled={!(filter.role && subRoleOptions.length > 0)}
                  value={filter.subRole}
                  inputProps={{
                    name: 'sub_role_type',
                    id: 'sub_role_type'
                  }}
                  required
                  sx={{ mt: 2 }}
                  onChange={(e) => setFilter({ ...filter, subRole: e.target.value })}
                  // error={Boolean(touched.sub_role_type && errors.sub_role_type)}
                  // helperText={touched.policy_role && errors.policy_role}
                >
                  <MenuItem value="" disabled>
                    Choose Sub Role
                  </MenuItem>
                  {subRoleOptions?.length > 0 ? (
                    subRoleOptions.map(([key, value, image]) => (
                      <MenuItem key={`${'sub_role_type'}-${key}`} value={key} data-icon={image}>
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled />
                  )}
                </Select>
              </FormControl>
            </Box>
          ) : null}
          {/* Manager */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              '& #mui-component-select-manager_filter': {
                display: 'flex',
                alignItems: 'center',
                pl: 1
              }
            }}
          >
            <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="manager_filter">
                Choose Manager
              </InputLabel>
              <Select
                variant="standard"
                value={filter.manager}
                inputProps={{
                  name: 'manager_filter',
                  id: 'manager_filter'
                }}
                onChange={(e) => setFilter({ ...filter, manager: e.target.value })}
                disabled={!filter?.role}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Manager
                </MenuItem>
                {managerOptions?.length > 0 ? (
                  managerOptions.map(([key, image, empR, empSR]) => (
                    <MenuItem
                      style={{ padding: '0.5rem' }}
                      key={`${'manager_filter'}-${key}`}
                      value={key}
                      data-icon={image}
                    >
                      <Avatar src={image} />
                      <Stack sx={{ ml: 1 }}>
                        <Typography>&nbsp;{key.split(',')[1]}</Typography>
                        <Box display="flex">
                          <Chip
                            fontSize={12}
                            sx={{ height: 18, mb: 0.5 }}
                            size="small"
                            label={empR}
                          />
                          &nbsp;
                          {empSR && (
                            <Chip fontSize={12} sx={{ height: 18 }} size="small" label={empSR} />
                          )}
                        </Box>
                      </Stack>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled />
                )}
              </Select>
            </FormControl>
          </Box>
          {/* Policy */}
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <StickyNote2Icon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="policy_filter">
                Choose Policy
              </InputLabel>
              <Select
                variant="standard"
                value={filter.policy}
                inputProps={{
                  name: 'policy_filter',
                  id: 'policy_filter'
                }}
                onChange={(e) => setFilter({ ...filter, policy: e.target.value })}
                disabled={!filter?.role}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Policy
                </MenuItem>
                {policyOptions?.length > 0 ? (
                  policyOptions.map(([key, value, image]) => (
                    <MenuItem
                      style={{ padding: '0.5rem' }}
                      key={`${'policy_type'}-${key}`}
                      value={key}
                      data-icon={image}
                    >
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled />
                )}
              </Select>
            </FormControl>
          </Box>
          <br />
          <Box>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              id="employee_apply_filter_button"
              size="medium"
              onClick={() => setFilters(filter)}
            >
              Apply
            </Button>
          </Box>
        </Stack>
      </Popover>
      <Popover
        id="employees_cards"
        open={anchorEl.id === 'employees_cards'}
        anchorEl={anchorEl.target}
        onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
      >
        <Stack sx={{ p: 2 }}>
          <Typography sx={{ p: 2 }} align="center">
            Employees Role Wise Categorization
          </Typography>
          <Stack
            className="hide_scrollbar"
            direction="row"
            alignContent="center"
            sx={{ overflowX: 'scroll' }}
          >
            {cardsData.data.length > 0 && (
              <Stack sx={{ minWidth: 400 }}>
                <Chart
                  chartType="PieChart"
                  data={cardsData.data}
                  options={options}
                  width="100%"
                  height="350px"
                />
              </Stack>
            )}
            &emsp;
            {cardsData.sdata.length > 0 && (
              <Stack sx={{ width: 400 }}>
                <Chart
                  chartType="PieChart"
                  data={cardsData.sdata}
                  options={soptions}
                  width="100%"
                  height="350px"
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}
