import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
// material
import {
  // Link,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  // Checkbox,
  TextField,
  // IconButton,
  // InputAdornment,
  // FormControlLabel,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Mutations
import { logInAdmin, logInEmployee } from '../../../api/mutations/auth';
// utils
import { getDataFromStorage } from '../../../utils/functions';
// redux
import { storeAdminInfo } from '../../../redux/actions/adminActions';
import { countries } from '../../../utils/countries';

// ----------------------------------------------------------------------
ResetPasswordForm.propTypes = {
  option: PropTypes.object
};

export default function ResetPasswordForm({ option }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [showPassword, setShowPassword] = useState(false);

  const { mutate: adminLoginMutation } = useMutation(logInAdmin); // for admin login
  const { mutate: employeeLoginMutation } = useMutation(logInEmployee); // for employee login

  // getting state of authenticated from redux store
  const { isAdminAuthenticated, info } = useSelector((state) => state.adminInfo);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address'),
    mobile: Yup.string().notRequired('Mobile Number is Required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      mobile: '',
      type: 'Employee',
      country: 91
      // remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      let authToken = null;
      try {
        authToken = getDataFromStorage('adminAuthToken');
      } catch (err) {
        console.log('error getting admin auth token');
      }

      // if token doesn't exist already, only then send login req to server
      if (!authToken) {
        // login as Organization (Admin)
        if (values.type === 'Organization') {
          adminLoginMutation(values, {
            onError: (error) => {
              const msg = error.response
                ? error.response.data.message || error.toString()
                : error.toString();

              // Swal.fire({
              //   icon: 'error',
              //   title: 'Something went wrong!',
              //   text: msg,
              //   showConfirmButton: false,
              //   timer: 2000,
              //   timerProgressBar: true
              // });

              actions.setStatus({
                errorResponse: msg
              });

              actions.setSubmitting(false);
            },
            onSuccess: (data) => {
              actions.setSubmitting(false);
              dispatch(storeAdminInfo(data)); // storing user payload from req response in redux store
            }
          });
        } else {
          // login as Employee
          employeeLoginMutation(values, {
            onError: (error) => {
              const msg = error.response
                ? error.response.data.message || error.toString()
                : error.toString();

              // Swal.fire({
              //   icon: 'error',
              //   title: 'Something went wrong!',
              //   text: msg,
              //   showConfirmButton: false,
              //   timer: 2000,
              //   timerProgressBar: true
              // });

              actions.setStatus({
                errorResponse: msg
              });

              actions.setSubmitting(false);
            },
            onSuccess: (data) => {
              actions.setSubmitting(false);
              dispatch(storeAdminInfo(data)); // storing user payload from req response in redux store
            }
          });
        }
      } else {
        formik.setSubmitting(false);
        Swal.fire({
          icon: 'info',
          title: 'Another account is already logged in',
          text: 'We found another account logged in the same browser profile. Please log out of any other accounts before logging again in different one.',
          showConfirmButton: true
        });
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  // const handleShowPassword = () => {
  //   setShowPassword((show) => !show);
  // };

  useEffect(() => {
    if (isAdminAuthenticated) {
      if (info.is_setup_completed) {
        navigate('/dashboard/app', { replace: true });
      } else {
        navigate('/setup', { replace: true });
      }
    }
  }, [info, isAdminAuthenticated, navigate]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {formik?.status?.errorResponse && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {formik?.status?.errorResponse}
          </Alert>
        )}
        <Stack sx={{ mb: 2 }} spacing={1}>
          <Stack spacing={2} direction="row">
            {option === 'mobile' && (
              <FormControl fullWidth>
                <InputLabel id="mobile-country-select-label">Country</InputLabel>
                <Select
                  labelId="mobile-country-select-label"
                  id="mobile-country-select"
                  variant="standard"
                  label="Country"
                  {...getFieldProps('country')}
                >
                  {countries.map((c) => (
                    <MenuItem key={c.code} value={c.phone}>
                      <img
                        loading="lazy"
                        width="23"
                        src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                        alt={`Flag of ${c.label}`}
                      />
                      &nbsp; +{c.phone}&nbsp;[ {c.label} ]
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              fullWidth
              variant="standard"
              autoComplete={option === 'email' ? 'email' : 'number'}
              required
              type={option === 'email' ? 'email' : 'number'}
              label={option === 'email' ? 'Email Address' : 'Mobile Number'}
              {...getFieldProps(option === 'email' ? 'email' : 'mobile')}
              error={Boolean(
                option === 'email' ? touched.email && errors.email : touched.mobile && errors.mobile
              )}
              helperText={
                option === 'email' ? touched.email && errors.email : touched.mobile && errors.mobile
              }
            />
          </Stack>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {option === 'email' ? 'Send Link' : 'Send OTP'}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
