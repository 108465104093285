import React, { useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import {
  Card,
  Typography,
  Stack,
  Chip,
  Box,
  Popover,
  TextField,
  Button,
  Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
// import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { addSubrole, editRole, deleteSubrole } from '../api/mutations/adminMutations';
// utils
import { SWAL_TIME } from '../utils/config';
import { capitalizeEachWord } from '../utils/functions';
// hooks
// import { useRoles } from '../hooks/roles';
// redux
import { logOut } from '../redux/actions/adminActions';

EditHeirarchy.propTypes = {
  heirarchy: PropTypes.array,
  open: PropTypes.bool,
  close: PropTypes.func
};

export default function EditHeirarchy({ heirarchy, open, close }) {
  const [edit, setEdit] = useState(); // Edit Role
  const [eR, setER] = useState();
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [subrole, setSubrole] = useState();

  const editRoleMutation = useMutation(editRole, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Updated Role`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
      setEdit('');
    },
    onSettled: () => {
      cache.invalidateQueries('heirarchy');
      cache.invalidateQueries('subroles');
    }
  });

  const addSubroleMutation = useMutation(addSubrole, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Subrole Added Successfully`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('heirarchy');
      cache.invalidateQueries('subroles');
    }
  });

  const deleteSubroleMutation = useMutation(deleteSubrole, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Subrole deleted Successfully`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('heirarchy');
      cache.invalidateQueries('subroles');
    }
  });

  return (
    <Card
      hidden={open}
      sx={{ height: 390, width: 300, position: 'absolute', top: 0, mt: 9, zIndex: 9 }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        sx={{ color: 'white' }}
        style={{ backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)' }}
      >
        <Typography sx={{ py: 1, px: 2, fontSize: 'bold', color: 'white' }}>Heirarchy</Typography>
        <CloseIcon onClick={() => close()} sx={{ m: 1, cursor: 'pointer' }} />
      </Stack>
      <Stack className="custom_scrollbar" sx={{ p: 2, pl: 3, overflowY: 'auto', height: 320 }}>
        <ol style={{ paddingLeft: 10 }}>
          {heirarchy.length > 0
            ? heirarchy.map((role) => (
                <li key={`${role._id}`} style={{ marginBottom: 3 }}>
                  <Stack direction="row" justifyContent="space-between" alignContent="center">
                    {edit === `${role._id}-edit` ? (
                      <input
                        id={`${role._id}-edit`}
                        value={eR}
                        onChange={(e) => setER(e.target.value)}
                        style={{
                          border: 'none',
                          borderBottom: '1.5px solid #333',
                          outline: 'none'
                        }}
                      />
                    ) : (
                      <Box>
                        {role.name}
                        {role.is_site_manager && (
                          <Chip sx={{ ml: 1 }} color="secondary" label="SM" size="small" />
                        )}
                      </Box>
                    )}
                    {edit === `${role._id}-edit` ? (
                      <Stack direction="row">
                        <Tooltip title="Save">
                          <SaveIcon
                            onClick={() =>
                              editRoleMutation.mutate({
                                type: 'Role',
                                id: role._id,
                                name: document.getElementById(`${role._id}-edit`).value
                              })
                            }
                            sx={{ cursor: 'pointer', color: '#6495ED' }}
                          />
                        </Tooltip>
                        <CancelIcon
                          onClick={() => setEdit('')}
                          sx={{ color: '#C0392B', cursor: 'pointer' }}
                        />
                      </Stack>
                    ) : (
                      <Stack direction="row">
                        <AddCircleIcon
                          sx={{ color: '#5DADE2', cursor: 'pointer' }}
                          onClick={(e) =>
                            setAnchorEl({
                              ...anchorEl,
                              id: `add_subrole_${role._id}`,
                              target: e.currentTarget
                            })
                          }
                        />
                        <Popover
                          id={`add_subrole_${role._id}`}
                          open={anchorEl.id === `add_subrole_${role._id}`}
                          anchorEl={anchorEl.target}
                          onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                        >
                          <Stack sx={{ p: 2 }}>
                            <TextField
                              sx={{ mb: 2 }}
                              variant="standard"
                              value={subrole}
                              placeholder="Add Subrole"
                              onChange={(e) => setSubrole(capitalizeEachWord(e.target.value))}
                            />
                            <Button
                              variant="contained"
                              onClick={() => {
                                setAnchorEl({ ...anchorEl, id: null, target: null });
                                addSubroleMutation.mutate({
                                  role: role._id,
                                  subroles: [subrole]
                                });
                              }}
                            >
                              Add
                            </Button>
                          </Stack>
                        </Popover>
                        <EditIcon
                          onClick={() => {
                            setEdit(`${role._id}-edit`);
                            setER(role.name);
                          }}
                          sx={{ cursor: 'pointer', color: '#95A5A6' }}
                        />
                      </Stack>
                    )}
                  </Stack>
                  <ul style={{ listStyle: 'square', marginLeft: 14 }}>
                    {role.subroles.map((sub) => (
                      <li key={`${sub._id}`} style={{ marginBottom: 2 }}>
                        <Stack direction="row" justifyContent="space-between" alignContent="center">
                          {edit === `${sub._id}-edit` ? (
                            <input
                              id={`${sub._id}-edit`}
                              value={eR}
                              onChange={(e) => setER(e.target.value)}
                              style={{
                                border: 'none',
                                borderBottom: '1.5px solid #333',
                                outline: 'none'
                              }}
                            />
                          ) : (
                            <Typography component="span" sx={{ fontSize: 14 }}>
                              {sub.name}
                            </Typography>
                          )}
                          {edit === `${sub._id}-edit` ? (
                            <Stack direction="row">
                              <SaveIcon
                                onClick={() =>
                                  editRoleMutation.mutate({
                                    type: 'Subrole',
                                    id: sub._id,
                                    name: document.getElementById(`${sub._id}-edit`).value
                                  })
                                }
                                sx={{ cursor: 'pointer', color: '#6495ED' }}
                              />
                              <CancelIcon
                                onClick={() => setEdit('')}
                                sx={{ color: '#C0392B', cursor: 'pointer' }}
                              />
                            </Stack>
                          ) : (
                            <Stack direction="row">
                              <EditIcon
                                onClick={() => {
                                  setEdit(`${sub._id}-edit`);
                                  setER(sub.name);
                                }}
                                sx={{ cursor: 'pointer', color: '#95A5A6' }}
                              />
                              <RemoveCircleIcon
                                sx={{ color: '#E74C3C', cursor: 'pointer' }}
                                onClick={() =>
                                  Swal.fire({
                                    title: `Are you sure ?`,
                                    text: `${sub.name} will be Removed?`,
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, Proceed!',
                                    allowOutsideClick: false
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      deleteSubroleMutation.mutate({
                                        subroles: [sub._id]
                                      });
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                      cache.invalidateQueries('roster');
                                    }
                                  })
                                }
                              />
                            </Stack>
                          )}
                        </Stack>
                      </li>
                    ))}
                  </ul>
                </li>
              ))
            : 'No Heirarchy to View'}
        </ol>
      </Stack>
    </Card>
  );
}
