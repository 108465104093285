import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchOfferLetterTemplate } from '../api/queries/Queries';

export const useOfferLetterTemplate = () => {
  // console.log("Site Search", search)
  const dispatch = useDispatch();
  return useQuery(
    // eslint-disable-next-line block-scoped-var
    ['fetch-offer-letter-template'],
    // eslint-disable-next-line block-scoped-var
    () => fetchOfferLetterTemplate(),
    {
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};
