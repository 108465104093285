/* eslint-disable no-nested-ternary */
// import { filter } from 'lodash';
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import {
  Container,
  Stack,
  Typography,
  Card,
  Button,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  ListItem,
  List,
  ListItemText,
  Popover,
  Chip,
  Checkbox,
  CircularProgress
} from '@mui/material';
// icons
// import VisibilityIcon from '@mui/icons-material/Visibility';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import { SiteFeedbackListHead } from '../components/_dashboard/site_feedback';
import { LeaveListToolbar } from '../components/_dashboard/leave';
// components
import LeaveApply from '../components/_dashboard/leave/LeaveApply';
import Page from '../components/Page';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
import useMutationFunc from '../api/mutations/mutation';
import { updateLeave, cancelLeave } from '../api/mutations/adminMutations';
// hooks
import { useLeaves, useRemainingLeaves } from '../hooks/leaves';
// utils
import { DD_MM_YYYY, convertUTCtoLocal, getCurrentDate } from '../utils/functions';

// ---------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'employee', label: 'Employee', alignRight: false },
  { id: 'remaining', label: 'Remaining', alignRight: false },
  { id: 'reason', label: 'Reason', alignRight: false },
  { id: 'days', label: 'Days', alignRight: false },
  { id: 'applied_on', label: 'Applied On', alignRight: false },
  { id: 'updated_on', label: 'Updated On', alignRight: false },
  { id: 'handled_by', label: 'Handled By', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false }
];

// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// --------------------------------------------------------------

Leaves.propTypes = {
  profile: PropTypes.bool,
  userPolicy: PropTypes.object
};

function Leaves({ profile, userPolicy }) {
  const cache = useQueryClient();
  const theme = useTheme();
  const currentYear = `${new Date().getFullYear()}`;
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [applyLeave, setApplyLeave] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [search] = useDebounce(filterName, 1200); // Debounce value 1.2 sec
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const { info } = useSelector((state) => state.adminInfo);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [filter, setFilter] = useState({
    from: null,
    to: null,
    status: 'Pending',
    reason: null,
    employee: '',
    searchBy: 'name'
  });

  if (profile && TABLE_HEAD.length > 7) {
    TABLE_HEAD.splice(1, 1);
  }

  const {
    isLoading: isLeavesLoading,
    isError: isLeavesError,
    hasNextPage: hasNextLeaves,
    fetchNextPage: fetchNextLeaves,
    data: leaves
  } = useLeaves(
    filter.from,
    filter.to,
    filter.reason,
    filter.status,
    search,
    filter.employee,
    profile ? 'own' : 'others',
    filter.searchBy
  );

  const rows = useMemo(() => {
    const listOfLeaves = [];
    if (!isLeavesLoading && !isLeavesError) {
      if (leaves?.pages?.length > 0) {
        leaves.pages.forEach((group) => {
          listOfLeaves.push(group.leaves);
        });
      }
    }
    const flattened = listOfLeaves.flat();
    return flattened;
  }, [leaves, isLeavesLoading, isLeavesError]);

  const {
    // isLoading: isRLeavesLoading,
    // isError: isRLeavesError,
    data: rLeaves
  } = useRemainingLeaves(info._id, currentYear, !info?.isMainAdmin);

  /* ================== Auto Shift Replace ========================== */
  const updateLeaveMutation = useMutationFunc(
    updateLeave,
    {
      onMutate: {
        title: 'Updating Leave',
        text: 'this may take a while...'
      },
      onSuccess: {
        title: 'Successfully Completed!!'
      }
    },
    null,
    () => {
      cache.invalidateQueries();
    },
    null,
    null
  );

  const cancelLeaveMutation = useMutationFunc(
    cancelLeave,
    {
      onMutate: {
        title: 'Cancelling Leave',
        text: 'this may take a while...'
      },
      onSuccess: {
        title: 'Successfully Cancelled!!'
      }
    },
    null,
    () => {
      cache.invalidateQueries();
    },
    null,
    null
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);
  const filteredUsers = rows;

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Leaves | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            {profile ? 'Leaves' : 'Leave Requests'}
          </Typography>
          {!info?.isMainAdmin && profile && (
            <Button variant="contained" onClick={() => setApplyLeave(true)}>
              Apply For Leave
            </Button>
          )}
        </Stack>
        {!info?.isMainAdmin && profile && (
          <Stack direction="row" justifyContent="left" alignItems="center" mb={2} spacing={1}>
            <Typography sx={{ fontWeight: 'bold' }}>
              {currentYear}-{Number(currentYear.toString().substring(2, currentYear.length)) + 1}
            </Typography>
            <Stack
              sx={{
                boxShadow: theme.customShadows.bloated,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 0.5,
                py: 0.5,
                color: 'white',
                px: 1
              }}
              spacing={1}
              direction="row"
              alignItems="center"
            >
              <Typography>Sick</Typography>
              <Chip
                sx={{ backgroundColor: 'white' }}
                size="small"
                label={rLeaves?.remaining[currentYear]?.sick ?? 0}
              />
            </Stack>
            <Stack
              sx={{
                boxShadow: theme.customShadows.bloated,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 0.5,
                py: 0.5,
                color: 'white',
                px: 1
              }}
              spacing={1}
              direction="row"
              alignItems="center"
            >
              <Typography>Casual</Typography>
              <Chip
                sx={{ backgroundColor: 'white' }}
                size="small"
                label={rLeaves?.remaining[currentYear]?.casual ?? 0}
              />
            </Stack>
          </Stack>
        )}
        <Card sx={{ boxShadow: 'none' }}>
          <LeaveListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
            filters={filter}
            profile={profile ?? false}
            approveLeaves={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: 'Approve selected leaves ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, approve!'
              }).then((result) => {
                if (result.isConfirmed) {
                  if (selected.length > 0) {
                    updateLeaveMutation({
                      status: 'Approved',
                      leaves: selected,
                      today: getCurrentDate()
                    });
                  }
                }
              });
            }}
            rejectLeaves={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: 'Reject all selected leavess ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, reject selected!'
              }).then((result) => {
                if (result.isConfirmed) {
                  if (selected.length > 0) {
                    updateLeaveMutation({
                      status: 'Rejected',
                      leaves: selected,
                      today: getCurrentDate()
                    });
                  }
                }
              });
            }}
            cancelLeaves={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: 'Cancel all selected leaves ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, cancel selected!'
              }).then((result) => {
                if (result.isConfirmed) {
                  if (selected.length > 0) {
                    cancelLeaveMutation({
                      leaves: selected,
                      today: getCurrentDate()
                    });
                  }
                }
              });
            }}
          />

          <TableContainer
            id="employees-leaves-table-container"
            sx={{
              minWidth: 800,
              minHeight: 'calc(100vh - 285px)',
              maxHeight: 'calc(100vh - 285px)'
            }}
          >
            <Table stickyHeader>
              <SiteFeedbackListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = selected.indexOf(row._id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={row._id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {(row?.employee?.managers.includes(info?._id) || info?.isMainAdmin) &&
                        row?.status === 'Pending' ? (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row._id)}
                            />
                          </TableCell>
                        ) : (
                          <TableCell>{(index += 1)}</TableCell>
                        )}
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar alt={row?.employee?.name} src={row?.employee?.avatar} />
                            <Typography variant="subtitle2" noWrap>
                              {row?.employee?.name} <br />
                              <Typography sx={{ color: '#333', fontSize: 12 }} component="span">
                                Ref: {row?.reference}
                              </Typography>
                            </Typography>
                          </Stack>
                        </TableCell>
                        {!profile && (
                          <TableCell>
                            {Object.entries(row?.remaining ?? {}).map(([key, value]) => (
                              <>
                                <Typography noWrap sx={{ fontWeight: 'bold', fontSize: 14 }}>
                                  {key}-{Number(key.toString().substring(2, key.length)) + 1}
                                </Typography>
                                <Stack direction="row" alignItems="center" spacing={1} key={key}>
                                  <Chip
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    avatar={<Avatar>{value?.sick}</Avatar>}
                                    label="Sick&emsp;&nbsp;"
                                  />
                                  <Chip
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    avatar={<Avatar>{value?.casual}</Avatar>}
                                    label="Casual"
                                  />
                                </Stack>
                              </>
                            ))}
                          </TableCell>
                        )}
                        <TableCell align="left">{row?.reason}</TableCell>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography
                              sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
                              onClick={(event) =>
                                setAnchorEl({
                                  id: `info_${row._id}`,
                                  target: event.currentTarget
                                })
                              }
                              variant="subtitle2"
                              noWrap
                            >
                              {row?.dates?.length} Days
                            </Typography>

                            <Popover
                              id={`info_${row._id}`}
                              open={anchorEl.id === `info_${row._id}`}
                              anchorEl={anchorEl.target}
                              onClose={() => setAnchorEl({ id: null, target: null })}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <List>
                                {row?.dates?.map((dt, idx) => (
                                  <ListItem key={`${dt}, ${idx}`}>
                                    <ListItemText primary={convertUTCtoLocal(dt, true)} />
                                  </ListItem>
                                ))}
                              </List>
                            </Popover>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Typography noWrap>{DD_MM_YYYY(row.createdAt)}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          {row.status !== 'Pending' ? DD_MM_YYYY(row.updatedAt) : 'NA'}
                        </TableCell>
                        <TableCell align="center">
                          {row?.handledBy ? (
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar alt={row?.handledBy?.name} src={row?.handledBy?.avatar} />
                              <Typography variant="subtitle2" noWrap>
                                {row?.handledBy?.name}
                              </Typography>
                            </Stack>
                          ) : (
                            'NA'
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            sx={{
                              color: `${
                                row.status === 'Approved'
                                  ? 'green'
                                  : row.status === 'Rejected'
                                  ? 'red'
                                  : '#FFCC00'
                              }`,
                              fontWeight: 'bold'
                            }}
                          >
                            {row.status.toUpperCase()}
                          </Typography>
                        </TableCell>
                        {/* <TableCell align="right">
                          <SiteFeedbackMoreMenu />
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            {/* Put the scroll bar always on the bottom */}
            <InfiniteScroll
              dataLength={rows?.length}
              next={fetchNextLeaves}
              // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
              // inverse //
              hasMore={hasNextLeaves}
              loader={
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Stack>
              }
              scrollableTarget="employees-leaves-table-container"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            />
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
      <LeaveApply open={applyLeave} userPolicy={userPolicy} onClose={() => setApplyLeave(false)} />
    </Page>
  );
}

export default Leaves;
