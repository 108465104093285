import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchSubRoles } from '../api/queries/Queries';

export const useSubRoles = (roleId, token) => {
  const dispatch = useDispatch();
  return useQuery(['subroles', roleId, token], fetchSubRoles, {
    enabled: roleId !== null,
    // getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};
