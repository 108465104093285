// This file contains all the POST API REQUESTS of ADMIN
import axios from 'axios';
import { baseApiURL, tinyURLApiKey, tinyBaseURL } from '../../utils/config';
import { getDataFromStorage } from '../../utils/functions';

export const addEmployee = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/employee`, values, config);
    return data;
  }
  throw new Error('No data to send');
};
/* =============================== Assign Site ====================================== */

export const assignSite = async (values) => {
  // console.log('Assign Site :', values);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/assign-site`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ============================ End Assign Site ===================================== */

/* =============================== Assign Shift ====================================== */

export const assignShift = async (values) => {
  // console.log('Assign Shift :', values);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const result = await axios.put(`${baseApiURL}/common/assign-shift`, values, config);
    // console.log('Assign Shift Data:', data);
    return result;
  }
  throw new Error('No data to send');
};

/* ============================ End Assign Shift ===================================== */
/* =============================== Delete Site ====================================== */

export const deleteSite = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/site-status`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ============================ End Delete Site  ===================================== */
/* =============================== Assign Managers ====================================== */

export const assignManager = async (values) => {
  console.log('Assign Managers :', values);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/employee-manager`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ============================ End Assign Managers ===================================== */

/* =============================== Assign Roles ====================================== */

export const assignRole = async (values) => {
  console.log('Assign Roles :', values);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/employee-role`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ============================ End Assign Roles ===================================== */

/* ============================ Import Employees ======================================= */

export const importEmployees = async (values) => {
  console.log('Imported Employees', values);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    // console.log('values: ', JSON.stringify(values, null, '\t'));
    const { data } = await axios.post(`${baseApiURL}/common/import-employees`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ============================ End Import Employees ================================= */

export const addSite = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/site`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* =============================== Sites Mutations ====================================== */

export const assignSiteManager = async (values) => {
  // console.log('Assign Managers :',values);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/site-manager`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const importSites = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    // console.log('I went, values: ', JSON.stringify(values, null, '\t'));
    const { data } = await axios.post(`${baseApiURL}/common/import-sites`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const updateSitePicture = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    // console.log('values: ', JSON.stringify(values, null, '\t'));
    const { data } = await axios.put(`${baseApiURL}/common/site-image`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const updateSiteDetails = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    // console.log('values: ', JSON.stringify(values, null, '\t'));
    const { data } = await axios.put(`${baseApiURL}/common/site`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ========================= Add Lead =================================================== */

export const addLead = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/lead`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const updateLeads = async (values) => {
  // console.log('Update Leads:',values);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/leads`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const deleteLead = async (values) => {
  // console.log('Delete Lead:', values);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    data: values
  };
  if (values) {
    const { data } = await axios.delete(`${baseApiURL}/common/lead`, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ========================= Shifts Mutations =================================================== */

export const addShiftMembers = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/shift-members`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ========================== Set Overtime Mutation =============================== */

export const addOvertime = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/overtime`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ============================== Add Attendance ================================ */

export const MarkAttendance = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/employee/attendance`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* =============== Delete Shift ====================== */

export const deleteShift = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    data: values
  };
  const { data } = await axios.delete(`${baseApiURL}/common/shift`, config);
  return data;
};

/* ========================= Shifts Mutations =================================================== */

export const finalStep = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/admin/final-step`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ================= Edit Role ========================================================= */

export const editRole = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/admin/edit-role-subrole`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ==================== delete SubRole ============================= */

export const deleteSubrole = async (values) => {
  // console.log('Delete Lead:', values);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    data: values
  };
  if (values) {
    const { data } = await axios.delete(`${baseApiURL}/admin/subroles`, config);
    return data;
  }
  throw new Error('No data to send');
};

/* =================== add Subrole =============================== */

export const addSubrole = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/admin/subroles `, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* =========================== Update Query Status ======================== */
export const updateQuery = async (values) => {
  // console.log('Update Leads:',values);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/queries`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ==================== Leaves ============================== */

export const applyLeave = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/leaves`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const updateLeave = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/leaves`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const cancelLeave = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/cancel-leaves`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ==================== Request Reinforcement ============================== */

export const requestReinforcement = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/reinforcements `, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* =========================== Update Query Status ======================== */
export const updateReinforcement = async (values) => {
  // console.log('Update Leads:',values);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/reinforcement`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ========================= Create Shorten Url ========================= */

export const createShortenURL = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tinyURLApiKey}`
    }
  };
  if (values) {
    values.domain = 'tinyurl.com';
    values.alias = '';
    values.tags = '';
    // console.log(values);
    const { data } = await axios.post(`${tinyBaseURL}/create`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ====================== Auto Roster ============================== */

export const autoRoster = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/auto-roster`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ================== Clear Roster ========================= */

export const clearRoster = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/clear-roster`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ================== Add Holiday ========================== */

export const forwardReinforcement = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/forward-reinforcement`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ================== Add Holiday ========================== */

export const addHoliday = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/holidays`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const editHoliday = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/holidays`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const deleteHoliday = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    data: values
  };
  if (values) {
    const { data } = await axios.delete(`${baseApiURL}/common/holidays`, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ====================== SWAPs ====================== */

export const updateSwap = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/swap`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* ============ Update Firebase Token ============= */

export const updateFirebaseToken = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/fcm-token`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/* Logout User */

export const logoutUser = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/logout`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/**
 * Verify OTP
 * @param {string} type - Mobile or Email
 * @param {string} otp - OTP send to the number or email
 * */
export const verifyOTP = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/verify-otp`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/** Update Contact
 * @param {string} otp - OTP Send to number or Email
 * @param {string} type - Mobile or E-mail
 * @param {string} email - email to be updated
 * @param {string} mobile - mobile to be updated
 * @param {string} countryCode - country code of mobile number
 */

export const updateContact = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/contact`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/** Force Assign Shift
 * @param {string} shift - shift for assignment
 * @param {string} today - YYYY-MM-DD
 * @param {array} employees - { id: //employee id, workdays: ['YYYY-MM-DD'] }
 */
export const forceAssignShift = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/force-assign-shift`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/** Auto Shift Replace
 * @param {string} shift - shift to be replaced
 * @param {string} employee - employee assigned to the shift to be replaced
 * @param {string} status - Active / Overtime status of assigned shift
 * @param {string} time - HH:MM // current time in 24 hour HH:MM format
 * @param {string} date - date of the assigne shift to be replace
 * @param {string} today - today date
 */
export const autoShiftReplace = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/auto-shift-replace`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/** ================== Create Site Bucket =======================
 * @param {string} name - Name for Bucket
 * @param {string} image - Optional image for bucket
 * @param {array} sites - Array of sites, index will be priority of sites
 */
export const createBucket = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/bucket`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/** ================ Update Bucket
 * @param {string} name - Name for Bucket
 * @param {string} image - Optional image for bucket
 * @param {string} sites - Array of sites, index will be priority of sites
 * @param {string} manager - manager of all Sites under this bucket
 */
export const updateBucket = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/bucket`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/**
 * @param {string} id - Id of Bucket to be deleted
 * @param {bool} shouldDeleteSites - true to delete all sites in the bucket
 */
export const deleteBucket = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    data: values
  };
  if (values) {
    const { data } = await axios.delete(`${baseApiURL}/common/bucket`, config);
    return data;
  }
  throw new Error('No data to send');
};

/** ================ Update Employee Ids
 * @param {string} employees - Employees Ids
 * @param {string} preferences - Preferences Ids
 */
export const updateShiftPreferences = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/shift-preferences`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/** ================ Mark Attendance
 * @value category: "Day" or "Shift", // whether it's a shift attendance or normal attendance
 * @vaue employee:
 * @value image:
 * @value type: "IN" or "OUT"
 * @value attendance: // _id of the IN attendance document, ONLY SEND THIS WHEN TYPE == OUT
 * @value date: "YYYY-MM-DD"
 * @value time: "HH:MM"
 * @value platform: "web" or "android"
 * @value shift: , // _id of the shift, only send if it's a shift attendance
 * @value location: { latitude, longitude, accuracy }
 */
export const markAttendance = async (employeeData) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (employeeData) {
    const { attendtype } = employeeData;

    let { data } = await axios.post(`${baseApiURL}/employee/attendance`, employeeData, config);

    data = { ...data, attendtype };
    return data;
  }
  throw new Error('No data to send');
};

export const addResume = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/resume`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const addPayrollComponent = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/admin/add-payroll-component`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/**
 * @param {string} id - Id of component to be deleted
 */
export const deleteComponent = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    data: values
  };
  if (values) {
    const { data } = await axios.delete(`${baseApiURL}/common/payroll-components`, config);
    return data;
  }
  throw new Error('No data to send');
};

/** ================ Update Employee Ids
 * @param {array} shifts - Array of Shifts with in and out
 */
export const updatePastAttendance = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/update-attendance`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const addPayrollTemplate = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/payroll-template`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const deletePayrollTemplate = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    data: values
  };
  if (values) {
    const { data } = await axios.delete(`${baseApiURL}/common/payroll-template`, config);
    return data;
  }
  throw new Error('No data to send');
};

/**
 * Resume Status
 */
export const updatePayrollTemplate = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/payroll-template`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/**
 * Assign Payroll
 */
export const assignPayroll = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/employee-payroll`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/**
 * Resume Status
 */
export const updateResumeStatus = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/resume-status`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/**
 * Make Employee
 */
export const makeEmployee = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/make-employee`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/** ================ Update Employee Ids
 * @param {array} data - Array of Shifts with in and out
 */
export const updatePayrollSettings = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/payroll-settings`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/** ====== Generate Pay Slips
 * @param {array} data - data to send for payslip
 */
export const generatePaySlip = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/generate-payslip`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

/**
 * Save Offer Letter Template
 */
export const saveOfferLetterTemplate = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/offer-letter`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const addLoan = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/loan`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const calculatePayslip = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/calculate-payslip`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const runPayroll = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/run-payroll`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const connectAccounts = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/admin/connect`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const closeAccount = async (values) => {
  // setting custom header for this endpoint
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/admin/close-account`, values, config);
    return data;
  }
  throw new Error('No data to send');
};

export const updateWeekOff = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/update-weekoff`, values, config);
    return data;
  }
  throw new Error('No data to send');
};
