import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchShiftAttendance } from '../api/queries/Queries';

export const useShiftAttendance = (shift, date) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(['shift-attendance', shift, date], fetchShiftAttendance, {
    enabled: !!shift,
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};
