/* eslint-disable no-nested-ternary */
// import { filter } from 'lodash';
import React, { useState, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Avatar from '@mui/material/Avatar';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import NotAvailable from '../components/NotAvailable';
// icons
import {
  SiteFeedbackListHead,
  SiteFeedbackListToolbar,
  SiteFeedbackMoreMenu
} from '../components/_dashboard/site_feedback';
import { LoanListToolbar } from '../components/_dashboard/loan';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
// hooks
import { useLoans } from '../hooks/get-loans';
// utils
import { DD_MM_YYYY, calculateLoanInstallment } from '../utils/functions';

// ---------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'employee-id', label: 'Employee', alignRight: false },
  { id: 'interest', label: 'Interest', alignRight: false },
  { id: 'loan-amount', label: 'Amount', alignRight: false },
  { id: 'tenure', label: 'Tenure', alignRight: false },
  { id: 'installment', label: 'Installment', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'end_date', label: 'End Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false }
];

// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// --------------------------------------------------------------

function Loan() {
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [anchorEl, setAnchorEl] = useState({
  //   id: '',
  //   target: ''
  // });
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    site: '',
    employee: ''
  });

  const {
    isLoading: isLoansLoading,
    isError: isLoansError,
    hasNextPage: hasNextLoans,
    fetchNextPage: fetchNextLoans,
    data: loans
  } = useLoans();

  const rows = useMemo(() => {
    const listOfLoans = [];
    if (!isLoansLoading && !isLoansError) {
      if (loans?.pages?.length > 0) {
        loans.pages.forEach((group) => {
          listOfLoans.push(group.loans);
        });
      }
    }
    const flattened = listOfLoans.flat();
    return flattened;
  }, [loans, isLoansLoading, isLoansError]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const filteredUsers = rows;

  return (
    <Page title="Loan | Trackkar">
      <Container>
        <LoanListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          setFilters={setFilter}
        />

        <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 275px)' }}>
          <Table size="small" stickyHeader>
            <SiteFeedbackListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {isLoansLoading ? (
                <TableRow>
                  <TableCell colSpan="7">
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              ) : isLoansError ? (
                <TableRow>
                  <TableCell>
                    <NotAvailable type="na" msg="Something Went Wrong!" />
                  </TableCell>
                </TableRow>
              ) : (
                filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = selected.indexOf(row?._id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={row._id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, row.name)}
                              />
                            </TableCell> */}
                        <TableCell>{(index += 1)}</TableCell>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar
                              sx={{ width: 26, height: 26 }}
                              alt={row?.employee?.name}
                              src={row?.employee?.avatar}
                            />
                            <Box>
                              <Typography variant="subtitle2" noWrap>
                                {row?.employee?.name}
                              </Typography>
                              <Typography sx={{ color: '#3366FF', fontSize: 11 }}>
                                <b>{row?.employee?.role?.name}</b>&emsp;
                                {row?.employee?.subrole ? (
                                  <Typography
                                    component="span"
                                    sx={{ color: '#DC7633', fontSize: 11 }}
                                  >
                                    <b>{row?.employee?.subrole?.name}</b>
                                  </Typography>
                                ) : null}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell>{row?.interest} %</TableCell>
                        <TableCell align="left">{row?.amount}</TableCell>
                        <TableCell>{row?.tenure} months</TableCell>
                        <TableCell>
                          {calculateLoanInstallment(row?.amount, row?.tenure, row?.interest)} /
                          month
                        </TableCell>
                        <TableCell align="left">{DD_MM_YYYY(row?.createdAt)}</TableCell>
                        <TableCell align="left">{DD_MM_YYYY(row?.due_date) ?? '-'}</TableCell>
                        <TableCell>{row?.status}</TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Page>
  );
}

export default Loan;
