import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Toolbar,
  Button,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
  // MenuItem,
  // InputLabel,
  // FormControl,
  // Select,
  // ListSubheader,
  Popover,
  Divider
} from '@mui/material';
// icons
import EventIcon from '@mui/icons-material/Event';
// import WcIcon from '@mui/icons-material/Wc';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
// import AddModeratorIcon from '@mui/icons-material/AddModerator';
// import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
// import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
// date picker
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// queries
import { fetchSiteManagers } from '../../../api/queries/Queries';
// utils
import {
  // convertUTCtoLocal,
  getUTCDate
} from '../../../utils/functions';
// import INDIAN from '../../../utils/INDIAN_STATES.json'; // importing indian states and uts
// config
// import { COMPANY_PLACEHOLDER_LOGO } from '../../../utils/config';
// hooks
import { useRoles } from '../../../hooks/roles';
// redux
import { logOut } from '../../../redux/actions/adminActions';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 70,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 2)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 40,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  setFilters: PropTypes.func
};

export default function UserListToolbar({ numSelected, filterName, onFilterName, setFilters }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [, setRoleOptions] = React.useState(); // Roles initial State for select
  const [, setManagerOptions] = React.useState();
  const [filter, setFilter] = React.useState({
    range: [getUTCDate(), getUTCDate()],
    state: '',
    status: '',
    role: '',
    manager: ''
  });

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = React.useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  React.useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      allRoles.forEach((role) => formattedRoles.push([role._id, role.name]));
      setRoleOptions([...formattedRoles]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allRoles]);
  /* ================= End Roles =============== */
  const {
    isLoading: isSiteManagersLoading,
    isError: isSiteManagersError,
    data: siteManagers
  } = useInfiniteQuery(
    ['site-managers', '', filter.role],
    fetchSiteManagers,
    {
      enabled: !!filter.role,
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        // console.log("Error :", error)
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    } // gettings sitemanagers
  );
  const SiteManagers = useMemo(() => {
    const listOfSiteManagers = [];
    // console.log("Add Site Managers :", siteManagers);
    if (!isSiteManagersLoading && !isSiteManagersError) {
      if (siteManagers?.pages?.length > 0) {
        siteManagers.pages.forEach((group) => listOfSiteManagers.push(group.employees));
      }
    }
    const flattened = listOfSiteManagers.flat();
    return flattened;
  }, [siteManagers, isSiteManagersLoading, isSiteManagersError]);

  useEffect(() => {
    const formattedEmployees = [];

    if (SiteManagers?.length > 0) {
      SiteManagers.forEach((manager) =>
        formattedEmployees.push([manager._id, manager.name, manager.avatar])
      );
    }
    setManagerOptions([...formattedEmployees]);
  }, [SiteManagers]);

  /* ================= End Managers =============== */

  return (
    <>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter'
          })
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Search Employee..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
        )}
        {/* 
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <Icon icon={trash2Fill} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
              <Icon icon={roundFilterList} />
            </IconButton>
          </Tooltip>
        )} */}
      </RootStyle>
      <Popover
        id="filter_employees"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack spacing={1} width="320px" sx={{ p: 2 }}>
          <Stack direction="row" alignItems="center">
            <FilterListIcon />
            <Typography sx={{ ml: 1 }}>Filters</Typography>
          </Stack>
          <Divider />
          {/* From Date */}
          <Box sx={{ display: 'flex', mt: 1, alignItems: 'flex-end' }}>
            <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                startText="From"
                endText="To"
                value={filter?.range}
                onChange={(date) => {
                  setFilter({ ...filter, range: date });
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField variant="standard" {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField variant="standard" {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider> */}
            &nbsp;
            {filter.from ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, from: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box>
          {/* Status
          <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
            <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="status_filter">
                Choose Status
              </InputLabel>
              <Select
                variant="standard"
                value={filter.status}
                inputProps={{
                  name: 'status',
                  id: 'status_filter'
                }}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Status
                </MenuItem>
                <MenuItem value="Verified">Verified</MenuItem>
                <MenuItem value="Unverified">Not Verified</MenuItem>
              </Select>
            </FormControl>
            &nbsp;
            {filter.status ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, status: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box>
          <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
            <LocationCityIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="grouped-select">
                Choose State
              </InputLabel>
              <Select
                variant="standard"
                value={filter.state}
                onChange={(e) => setFilter({ ...filter, state: e.target.value })}
                id="grouped-select"
                label="Choose State"
              >
                <MenuItem value="" disabled>
                  Choose State and Union Territories
                </MenuItem>
                <ListSubheader>States</ListSubheader>
                {INDIAN?.states?.length > 0 ? (
                  INDIAN?.states.map((key) => (
                    <MenuItem key={`${'state'}-${key}`} value={key}>
                      {key}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <em>No State Available</em>
                  </MenuItem>
                )}
                <ListSubheader>Union Territories</ListSubheader>
                {INDIAN.union_territories?.length > 0 ? (
                  INDIAN.union_territories.map((key) => (
                    <MenuItem key={`${'territory'}-${key}`} value={key}>
                      {key}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <em>No Territories Available</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            &nbsp;
            {filter.state ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, state: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="role_filter">
                Choose Role
              </InputLabel>
              <Select
                variant="standard"
                value={filter?.role}
                inputProps={{
                  name: 'role_filter',
                  id: 'role_filter'
                }}
                onChange={(e) => setFilter({ ...filter, role: e.target.value })}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Role
                </MenuItem>
                {roleOptions?.length > 0 ? (
                  roleOptions.map(([key, value, image]) => (
                    <MenuItem key={`${'role_filter'}-${key}`} value={key} data-icon={image}>
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <>
                    <MenuItem value="" disabled />
                  </>
                )}
              </Select>
            </FormControl>
            &nbsp;
            {filter.role ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, role: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="manager_filter">
                Choose Manager
              </InputLabel>
              <Select
                variant="standard"
                value={filter.manager}
                inputProps={{
                  name: 'manager_filter',
                  id: 'manager_filter'
                }}
                onChange={(e) => setFilter({ ...filter, manager: e.target.value })}
                disabled={!filter?.role}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Manager
                </MenuItem>
                {managerOptions?.length > 0 ? (
                  managerOptions.map(([key, value, image]) => (
                    <MenuItem
                      style={{ padding: '0.5rem' }}
                      key={`${'manager_filter'}-${key}`}
                      value={key}
                      data-icon={image}
                    >
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled />
                )}
              </Select>
            </FormControl>
            &nbsp;
            {filter.manager ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, manager: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box> */}
          <br />
          <Box>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              size="medium"
              onClick={() => setFilters(filter)}
            >
              Apply
            </Button>
          </Box>
        </Stack>
      </Popover>
    </>
  );
}
