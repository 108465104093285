import { useEffect, useState } from 'react';
import {
  // Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient, useMutation } from 'react-query';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import {
  // Link,
  Stack,
  // TextField,
  // Tooltip,
  // Grid,
  // Card,
  IconButton
  // Container,
  // Tooltip
} from '@mui/material';
// material
import { styled, useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import LogoutIcon from '@mui/icons-material/Logout';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
// hooks
import { useRoles } from '../hooks/roles';
// components
import Page from '../components/Page';
// import { MHidden } from '../components/@material-extend';
import { TRACKKAR_LOGO, SWAL_TIME } from '../utils/config';
// import Hierarchy from './Hierarchy';
import HeirarchyTree from './HeirarchyTree';
import Privileges from './Privileges';
import Step3 from './Step_3';
import Step4 from './Step_4';
import Step5 from './Step_5';
import { finalStep } from '../api/mutations/adminMutations';
// redux
import { logOut } from '../redux/actions/adminActions';
import { getDataFromStorage } from '../utils/functions';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  // margin: 'auto',
  display: 'flex',
  // minHeight: '100vh',
  // overflow: 'hidden',
  // maxHeight: 'calc(100vh - 300px)',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 0) // Content Padding Change
}));

export default function VerticalLinearStepper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const [disableNext, setDisableNext] = useState(false);
  const [finalStatus, setFinalStatus] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [finishedStep, setFinishedStep] = useState();
  const { isLoading, isError, data } = useRoles();
  const { info } = useSelector((state) => state.adminInfo);
  // console.log('Info:', info);
  const navigate = useNavigate();

  useEffect(() => {
    // console.log('Role Heirarchy :', data);
    if (!isLoading && !isError) {
      // eslint-disable-next-line camelcase
      const { heirarchy, is_setup_completed, is_heirarchy_completed } = data;
      // console.log('Heirarchy :', heirarchy);
      // eslint-disable-next-line camelcase
      if (is_setup_completed) {
        navigate('/dashboard/app');
        // eslint-disable-next-line camelcase
      } else if (is_heirarchy_completed && !is_setup_completed) {
        setActiveStep(2);
        setFinishedStep(2);
        // eslint-disable-next-line camelcase
      } else if (heirarchy.length > 0 && !is_heirarchy_completed) {
        setActiveStep(1);
        setFinishedStep(1);
      }
      // console.log(data);
    }
  }, [data, isLoading, isError, navigate]);

  const steps = [
    {
      label: `Now, let's create a heirarchy structure for your company`,
      description: <HeirarchyTree finishStep={setFinishedStep} edit />
    },
    {
      label: `Now, let's decide privileges for each role in heirarchy`,
      description: <Privileges activeStep={setActiveStep} finishStep={setFinishedStep} />
    },
    {
      label: `Import employees & Assign managers`,
      description: <Step3 heirarchy={data?.heirarchy} disableNext={() => setDisableNext(true)} />
    },
    {
      label: 'Import Sites',
      description: (
        <Step4 heirarchy={data?.heirarchy} disableNext={(disable) => setDisableNext(disable)} />
      )
    },
    {
      label: `Assign Sites to Guard's`,
      description: <Step5 heirarchy={data?.heirarchy} />
    }
  ];

  const maxSteps = steps.length;

  const handleNext = () => {
    if (activeStep === 1) {
      document.querySelector('#privileges_submit').click();
    } else if (activeStep === 0) {
      document.querySelector('#hierarchy_submit').click();
    }
    if (finishedStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 3) {
      setDisableNext(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { mutate: finalStepMutation } = useMutation(finalStep, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Intializing your company on Trakkar 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `${finalStatus === 'finish' ? 'Initial Setup' : 'Reset'} Completed!`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
      if (finalStatus === 'finish') {
        navigate('/dashboard/app');
      } else if (finalStatus === 'reset') {
        window.location.reload();
      }
    },
    onSettled: () => {
      cache.invalidateQueries('potential-site-members');
      cache.invalidateQueries('heirarchy');
    }
  });

  const handleFinish = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Finish Initial Setup for your Company',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Proceed!',
      allowOutsideClick: false
    }).then((result) => {
      setFinalStatus('finish');
      if (result.isConfirmed) {
        finalStepMutation({
          isFinalStep: true
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        cache.invalidateQueries('unmanaged-employees');
      }
    });
  };

  const handleReset = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Reset Setup for your Company',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Proceed!',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        setFinalStatus('reset');
        finalStepMutation({
          shouldReset: true,
          isFinalStep: false
        });
        navigate('/', { replace: true });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        cache.invalidateQueries('unmanaged-employees');
        cache.invalidateQueries('heirarchy');
      }
    });
  };

  return (
    <RootStyle title="Hierarchy | Trackkar">
      {/* <MHidden width="mdDown">
        <SectionStyle sx={{ px: 2, m: 0, borderRadius: 0, backgroundColor: '#0897FF' }}>
          <Typography
            variant="h3"
            sx={{ px: 5, mt: 0, mb: 5, color: 'white', textAlign: 'center' }}
          >
            Trackkar
          </Typography>
          <img src={`${BG_HEIRARCHY_IMG}`} alt="login" />
          <Typography variant="h4" sx={{ mt: 4, color: 'white', textAlign: 'center' }}>
            Adjust Trackkar for your buisness and fix your company structure that way information
            trickles up and down the proper way
          </Typography>
        </SectionStyle>
      </MHidden> */}
      <Stack
        style={{ height: 50, px: 2 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row">
          &emsp;
          <img src={TRACKKAR_LOGO} height="40" width="130" alt="" />
        </Stack>
        <Stack direction="row" alignItems="center">
          <Avatar src={info?.avatar} />
          &nbsp;
          {info?.name}
          &nbsp;&nbsp;
          <IconButton sx={{ cursor: 'pointer' }} onClick={() => dispatch(logOut())}>
            <LogoutIcon />
          </IconButton>
          &nbsp;
        </Stack>
      </Stack>
      <ContentStyle>
        <Box sx={{ flexGrow: 1, position: 'relative' }}>
          <Typography
            className="desktop"
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 0,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 5,
              mr: 2,
              ml: 3,
              mt: 1,
              fontWeight: 'bold'
            }}
          >
            Step&nbsp;{activeStep + 1}&nbsp;
            <ArrowForwardIosSharpIcon sx={{ fontSize: 14 }} />
          </Typography>
          <Paper
            square
            elevation={0}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              pl: 2,
              pr: 1,
              mt: 1,
              mr: 4,
              ml: 10,
              // border: '1px solid #333',
              bgcolor: 'background.default'
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>{steps[activeStep].label}</Typography>
          </Paper>
          {activeStep === 0 && (
            <Stack
              style={{ position: 'absolute', right: 0, top: 0 }}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Chip style={{ height: 20, width: 20, backgroundColor: '#0094ff' }} label="" />
              &nbsp;
              <Typography>Role</Typography>
              &nbsp;
              <Chip style={{ height: 20, width: 20, backgroundColor: '#f38d09' }} label="" />
              &nbsp;
              <Typography>Sub Role</Typography>
              &emsp;
            </Stack>
          )}
          <Box sx={{ width: '100%', height: 'calc(100vh - 148px)', overflowY: 'auto', p: 2 }}>
            {steps[activeStep].description}
          </Box>
          <MobileStepper
            variant="progress"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              activeStep === maxSteps - 1 ? (
                <Stack direction="row" alignContent="center">
                  <Button size="small" onClick={handleReset}>
                    Reset
                  </Button>
                  &nbsp;
                  <Button size="small" onClick={handleFinish}>
                    Finish
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </Button>
                </Stack>
              ) : (
                <Stack direction="row" alignContent="center">
                  <Button size="small" onClick={handleReset}>
                    Reset
                  </Button>
                  &nbsp;
                  <Button disabled={disableNext} size="small" onClick={handleNext}>
                    Next
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </Button>
                </Stack>
              )
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0 || activeStep === 1 || activeStep === 2}
              >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
              </Button>
            }
          />
        </Box>
      </ContentStyle>
    </RootStyle>
  );
}
