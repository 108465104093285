/* eslint-disable no-nested-ternary */
import { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  // Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
// material
import { styled } from '@mui/material/styles';
import {
  // Link,
  Box,
  Stack,
  // TextField,
  Button,
  // Tooltip,
  Paper,
  Switch,
  // Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  // Card,
  // Container,
  Typography,
  Tooltip
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// hooks
import { useHeirarchy } from '../hooks/roles';
import { updateRolesPrivileges } from '../api/mutations/hierarchy';
// layouts
// components
import AddPolicy from '../components/_dashboard/policy/AddPolicy';
import AddSubRole from '../components/_dashboard/policy/AddSubRole';
import { logOut } from '../redux/actions/adminActions';
import { SWAL_TIME } from '../utils/config';

// ----------------------------------------------------------------------

// const RootStyle = styled(Page)(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex'
//   }
// }));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2)
// }));

// const ContentStyle = styled('div')(({ theme }) => ({
//   maxWidth: 680,
//   margin: 'auto',
//   display: 'flex',
//   minHeight: '100vh',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: theme.spacing(8, 0) // Content Padding Change
// }));

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary
// }));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));

// ----------------------------------------------------------------------

Hierarchy.propTypes = {
  activeStep: PropTypes.any
};

export default function Hierarchy({ activeStep }) {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const { info } = useSelector((state) => state.adminInfo);
  const navigate = useNavigate();
  const [arePoliciesAssigned, setArePoliciesAssigned] = useState(false);
  const [postData, setPostData] = useState([]);
  const [openAddPolicy, setOpenAddPolicy] = useState(false);
  const [openAddSubRole, setOpenAddSubRole] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});
  const [managerAssigned, setManagerAssigned] = useState(false); // Check Site Manager Assigned or not
  const [ma, setMA] = useState(false);
  const { isLoading, isError, data } = useHeirarchy();

  useEffect(() => {
    if (typeof info.isMainAdmin === 'boolean' && !info.isMainAdmin) {
      navigate('/dashboard/app', { replace: true });
    }
  }, [info, navigate]);

  // const rows = useMemo(() => {
  //   console.log('Role Heirarchy :', data);
  //   if (!isLoading && !isError) {
  //     // eslint-disable-next-line camelcase
  //     const { heirarchy, is_setup_completed } = data;
  //     console.log('Heirarchy :', data);
  //     // eslint-disable-next-line camelcase
  //     return heirarchy;
  //   }
  //   return [];
  // }, [data, isLoading, isError, navigate]);

  const rows = useMemo(() => {
    const Heirarchy = [];
    if (!isLoading && !isError) {
      if (data?.pages?.length > 0) {
        data.pages.forEach((group) => {
          Heirarchy.push(group.heirarchy);
        });
      }
      const flattened = Heirarchy.flat();
      // console.log(flattened);
      return flattened;
      // eslint-disable-next-line camelcase
      // const { heirarchy, is_setup_completed } = data;
      // // eslint-disable-next-line camelcase
      // if (is_setup_completed) {
      //   navigate('/dashboard/app', { replace: true });
      // }
      // return heirarchy;
    }
    return [];
  }, [data, isLoading, isError]);

  useEffect(() => {
    if (rows?.length > 0) {
      setArePoliciesAssigned(rows.every((role) => role.default_policy));

      // const tooltipped = document.querySelectorAll('.heirarchy-tooltipped');

      // M.Tooltip.init(tooltipped, {
      //   html: true
      // });

      // if (postData.length === 0) {
      //   const arr = rows.map((role) => ({
      //     _id: role._id,
      //     tracking: role.tracking,
      //     isSiteManager: false
      //     // attendance: role.attendance
      //   }));
      //   setPostData(arr);
      // }
      // console.log(postData); // For Testing
      // console.log('Row', rows);
    }
  }, [rows, postData]);

  const { mutate: stepTwoMutation } = useMutation(updateRolesPrivileges, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Finishing up few things 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    }
  });

  const handleStep2 = useCallback(() => {
    // console.log('Manager Assigned :', managerAssigned);
    if (arePoliciesAssigned) {
      Swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert set privileges for roles later on, so please make sure everything is set as you wanted!`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, apply!'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(postData);
          stepTwoMutation(
            { roles: postData },
            {
              onError: (error) => {
                const msg = error?.response?.data?.message || error.toString();

                Swal.fire({
                  icon: 'error',
                  title: 'Something went wrong !',
                  text: msg
                });
              },
              onSuccess: (data) => {
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: data.message,
                  showConfirmButton: false,
                  timer: SWAL_TIME,
                  timerProgressBar: true,
                  willClose: () => window.location.reload()
                });
              },
              onSettled: () => {
                cache.invalidateQueries('roles');
              }
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          activeStep(1);
        }
      });
    } else {
      if (!arePoliciesAssigned) {
        Swal.fire({
          title: 'Policy is missing for some roles',
          text: `You can't finish set up if policy is not added for every role. Kindly, add policy for every role & then continue.`,
          icon: 'warning'
        });
      }
      activeStep(0);
    }
  }, [arePoliciesAssigned, stepTwoMutation, postData, cache, activeStep]);

  // const handleTracking = (e, id) => {
  //   const isOn = e.target.checked;

  //   const arr = [...postData];
  //   const role = arr.find((role) => role._id === id);

  //   if (isOn) {
  //     role.tracking = 'Enabled';
  //   } else {
  //     role.tracking = 'Disabled';
  //   }

  //   setPostData(arr);
  // };

  // const handleSiteManager = (e, id) => {
  //   const isSiteManager = e.target.checked;
  //   const arr = [...postData];
  //   console.log(arr);
  //   arr.forEach((role) => {
  //     if (role._id === id) {
  //       if (role.isSiteManager) {
  //         role.isSiteManager = false;
  //         setManagerAssigned(false);
  //       } else {
  //         role.isSiteManager = isSiteManager;
  //         setManagerAssigned(true);
  //         setMA(true);
  //       }
  //     } else {
  //       role.isSiteManager = false;
  //       setManagerAssigned(false);
  //     }
  //   });
  //   // const role = arr.forEach((role) => {
  //   //   role._id === id;
  //   // });

  //   setPostData(arr);
  // };

  // const handleAttendance = (e, id) => {
  //   const isSiteBased = e.target.checked;

  //   const incomingType = isSiteBased ? 'Site-based' : 'Normal';

  //   const arr = [...postData];
  //   const role = arr.find((role) => role._id === id);

  //   role.attendance = incomingType;

  //   setPostData(arr);
  // };

  return (
    <>
      <Stack style={{ position: 'relative' }}>
        <Tooltip title="Log Out from current session" placement="left">
          <Button
            sx={{ mt: -6 }}
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={() => dispatch(logOut())}
          >
            <LogoutIcon />
          </Button>
        </Tooltip>
        <Stack style={{ border: '2px solid #0897FF', borderRadius: 8 }} sx={{ p: 1, mt: 1 }}>
          <Typography
            sx={{
              mt: -2.5,
              px: 2,
              backgroundColor: 'white',
              width: 'calc(100vh - 25rem)',
              color: '#0897FF'
            }}
          >
            <b>Hierarchy Structure</b>
          </Typography>
          <TableContainer
            className="custom_scrollbar"
            sx={{ height: 'calc(100vh - 220px)' }}
            component={Paper}
          >
            <Table size="small" stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="center">Role</TableCell>
                  {/* <TableCell align="center">
                    <Stack direction="row" justifyContent="center" alignItems="center">
                      Tags&nbsp;&nbsp;
                      <HtmlTooltip
                        className="tooltip"
                        title={
                          <>
                            <Typography>
                              Tags represents the working area of the people working under that
                              role. You can asssign different tags to different peoples.
                            </Typography>
                          </>
                        }
                        placement="top"
                        arrow
                      >
                        <InfoIcon />
                      </HtmlTooltip>
                    </Stack>
                  </TableCell> */}
                  {/* <TableCell align="center">
                    <Stack direction="row" justifyContent="center" alignItems="center">
                      Is Site Manager ?&nbsp;&nbsp;
                      <HtmlTooltip
                        className="tooltip"
                        arrow
                        title={
                          <>
                            <Typography>
                              Assign a role for Site Manager, Member under this role will manage
                              workforce in a particular site assigned to them.
                            </Typography>
                          </>
                        }
                        placement="top"
                      >
                        <InfoIcon />
                      </HtmlTooltip>
                    </Stack>
                  </TableCell> */}
                  {/* <TableCell align="center">
                    <Stack direction="row" justifyContent="center" alignItems="center">
                      <Typography noWrap>Sub Role</Typography>&nbsp;&nbsp;
                      <HtmlTooltip
                        className="tooltip"
                        title={
                          <>
                            <Typography>
                              Add a{' '}
                              <Typography color="primary" component="span">
                                <q>Sub Role</q>
                              </Typography>{' '}
                              for each role which will be used to breakdown role with different
                              types for all the employees of that role."
                            </Typography>
                          </>
                        }
                        placement="top"
                        arrow
                      >
                        <InfoIcon />
                      </HtmlTooltip>
                    </Stack>
                  </TableCell> */}
                  <TableCell align="center">
                    <Stack direction="row" justifyContent="center" alignItems="center">
                      Policy&nbsp;&nbsp;
                      <HtmlTooltip
                        className="tooltip"
                        title={
                          <>
                            <Typography>
                              Add a default{' '}
                              <Typography color="primary" component="span">
                                <q>Policy</q>
                              </Typography>{' '}
                              for each role which will be used to manage leaves &amp; reimbursements
                              for all the employees of that role."
                            </Typography>
                          </>
                        }
                        placement="top"
                        arrow
                      >
                        <InfoIcon />
                      </HtmlTooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell align="center" colSpan="7">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : isError ? (
                  <TableRow>
                    <TableCell align="center" colSpan="7">
                      Something Went Wrong
                    </TableCell>
                  </TableRow>
                ) : rows?.length > 0 ? (
                  rows.map((role, index) => (
                    <TableRow
                      key={role._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}.
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap>{role.name}</Typography>
                      </TableCell>
                      {/* <TableCell align="center">
                        <Box
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            display: 'flex'
                          }}
                        >
                          <Typography style={{ fontSize: '0.8rem' }}>Off</Typography>
                          <Switch
                            defaultChecked={role.tracking === 'Enabled'}
                            onChange={(e) => handleTracking(e, role._id)}
                          />
                          <Typography style={{ fontSize: '0.8rem' }}>On</Typography>
                        </Box>
                      </TableCell> */}
                      {/* <TableCell align="center" style={{ minWidth: '2rem' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Typography style={{ fontSize: '0.8rem' }}>No</Typography>
                          <Switch
                            checked={
                              postData.find((r) => r._id === role._id)?.isSiteManager ?? false
                            }
                            // eslint-disable-next-line no-unneeded-ternary
                            disabled={
                              managerAssigned
                                ? !postData.find((r) => r._id === role._id)?.isSiteManager
                                : false
                            }
                            onChange={(e) => handleSiteManager(e, role._id)}
                          />
                          <Typography style={{ fontSize: '0.8rem' }}>Yes</Typography>
                        </Box>
                      </TableCell> */}
                      {/* <TableCell align="center">
                        {role?.tags?.length > 0 ? (
                          <HtmlTooltip
                            arrow
                            placement="right"
                            title={
                              <Stack>
                                {role.tags.map((sub) => (
                                  <Typography key={sub._id}>{sub.name}</Typography>
                                ))}
                              </Stack>
                            }
                          >
                            <Typography color="green" sx={{ cursor: 'pointer' }}>
                              <b>View ({role.tags.length})</b>
                            </Typography>
                          </HtmlTooltip>
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => {
                              setSelectedRole({ id: role._id, name: role.name });
                              setOpenAddSubRole(true);
                            }}
                          >
                            Add
                          </Button>
                        )}
                      </TableCell> */}
                      <TableCell align="center">
                        {role.default_policy ? (
                          <Typography color="green">
                            <b>Assigned</b>
                          </Typography>
                        ) : (
                          <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => {
                              setSelectedRole({ id: role._id, name: role.name });
                              setOpenAddPolicy(true);
                            }}
                          >
                            Add
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  window.location.reload()
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Box sx={{ display: 'none' }}>
          <Button
            variant="contained"
            id="privileges_submit"
            size="large"
            type="submit"
            endIcon={<CheckCircleIcon />}
            onClick={handleStep2}
            // disabled={!arePoliciesAssigned}
            sx={{ float: 'right', mt: 1 }}
          >
            <b>Finish</b>
          </Button>
        </Box>
      </Stack>
      <AddPolicy
        open={openAddPolicy}
        onClose={() => setOpenAddPolicy(false)}
        defaultPolicy
        selectedRole={selectedRole.id}
      />
      <AddSubRole
        open={openAddSubRole}
        onClose={() => setOpenAddSubRole(false)}
        selectedRole={selectedRole}
      />
    </>
  );
}
