import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import {
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Divider,
  Modal,
  Box,
  DialogTitle,
  DialogContent
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import HistoryIcon from '@mui/icons-material/History';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const GetReinforcement = React.lazy(() => import('../components/_dashboard/app/GetReinforcement'));
const RequestReinforcement = React.lazy(() =>
  import('../components/_dashboard/app/RequestReinforcement')
);

function RequestReinforcementDialog(props) {
  const theme = useTheme();
  const { onClose, selectedValue, open } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };

  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100vh',
    minWidth: 320,
    maxWidth: 400,
    backgroundColor: 'white',
    border: 'none',
    // borderRadius: 1,
    boxShadow: 24
    // p: 4
  };

  return (
    <Modal open={open}>
      <Box style={style}>
        <DialogTitle
          sx={{
            display: 'flex',
            backgroundImage: theme.palette.gradients.blue,
            color: theme.palette.common.white,
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography>Request for Reinforcement</Typography>
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
        </DialogTitle>
        <DialogContent sx={{ px: 0 }}>
          <RequestReinforcement handleClose={handleClose} />
        </DialogContent>
      </Box>
    </Modal>
  );
}

RequestReinforcementDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string
};

export default function Reinforcement() {
  const theme = useTheme();
  const [reinforcementStatus, setReinforcementStatus] = React.useState('Pending');
  const [reinforcementRequest, setReinforcementRequest] = React.useState(false);
  const { info } = useSelector((state) => state.adminInfo);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 2 }}>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
          Reinforcement Requests&nbsp;
          <InfoIcon sx={{ fontSize: 16, cursor: 'pointer' }} />
        </Typography>
        <Stack direction="row" spacing={1}>
          {info?.role?.is_site_manager && (
            <IconButton
              sx={{ color: theme.palette.primary.main }}
              onClick={() => setReinforcementRequest(true)}
            >
              <Tooltip title="Apply">
                <AddCircleIcon />
              </Tooltip>
            </IconButton>
          )}
          {reinforcementStatus === null ? (
            <IconButton onClick={() => setReinforcementStatus('Pending')}>
              <Tooltip title="Pending">
                <PendingActionsIcon />
              </Tooltip>
            </IconButton>
          ) : (
            <IconButton onClick={() => setReinforcementStatus(null)}>
              <Tooltip title="History">
                <HistoryIcon />
              </Tooltip>
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Divider />
      <GetReinforcement status={reinforcementStatus} />
      <RequestReinforcementDialog
        open={reinforcementRequest}
        onClose={() => setReinforcementRequest(false)}
      />
    </>
  );
}
