/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
// import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useDebounce } from 'use-debounce';
// import CalendarDates from 'calendar-dates';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-multi-date-picker';
import InfiniteScroll from 'react-infinite-scroll-component';
// material
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  AppBar,
  Checkbox,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  OutlinedInput,
  InputAdornment,
  LinearProgress,
  Drawer,
  Typography,
  Avatar,
  Chip,
  Divider,
  Popover,
  Tabs,
  Tab,
  Stack,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Alert,
  AlertTitle,
  CircularProgress
} from '@mui/material';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import GroupIcon from '@mui/icons-material/Group';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuIcon from '@mui/icons-material/Menu';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
// import peopleFill from '@iconify/icons-eva/people-fill';
// hooks
import useSites from '../../hooks/get-sites-query';
// hooks
import { usePotentialSiteMembers, useSiteEmployees, useSiteById } from '../../hooks/site';
import { useRoles } from '../../hooks/roles';
import { useSubRoles } from '../../hooks/subRoles';
import { useShifts } from '../../hooks/get-shifts-query';
import { useMonthAvailability } from '../../hooks/get-site-month-availability';
import useMutationFunc from '../../api/mutations/mutation';
import {
  assignSite,
  assignShift as assignShiftMutate,
  forceAssignShift
} from '../../api/mutations/adminMutations';
import { removeFromSite } from '../../api/mutations/site'; // Site Mutations
// components
import { ImageZoom } from '../../components';
import NotAvailable from '../../components/NotAvailable';
import Timeline from '../../components/Timeline';
// import NavSection from '../../components/NavSection';
import AppHolidays from '../../components/_dashboard/app/AppHolidays';
import { MHidden } from '../../components/@material-extend';
//
// import sidebarConfig from './SidebarConfig';
// utils
import { SWAL_TIME } from '../../utils/config';
import { getCurrentDate, truncateString, formatDigit } from '../../utils/functions';
// redux
import { logOut } from '../../redux/actions/adminActions';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// const AccountStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   borderRadius: theme.shape.borderRadiusSm,
//   backgroundColor: theme.palette.grey[200]
// }));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 240, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function a11yEProps(index) {
  return {
    id: `employees-tab-${index}`,
    'aria-controls': `employees-tabpanel-${index}`
  };
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100vw',
  height: '100vh',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24
};

ErrorsAlert.propTypes = {
  errorsData: PropTypes.any,
  setErrors: PropTypes.any,
  shiftId: PropTypes.any
};

export function ErrorsAlert({ errorsData, setErrors, shiftId }) {
  const [errorSelectedEmployees, setErrorSelectedEmployees] = useState([]);
  const theme = useTheme();
  const cache = useQueryClient();
  const handleClick = (event, _d) => {
    const id = {
      id: _d._id,
      workdays: _d.dates
    };
    const selectedIndex = errorSelectedEmployees.findIndex((p) => p.id === _d?._id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(errorSelectedEmployees, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(errorSelectedEmployees.slice(1));
    } else if (selectedIndex === errorSelectedEmployees.length - 1) {
      newSelected = newSelected.concat(errorSelectedEmployees.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        errorSelectedEmployees.slice(0, selectedIndex),
        errorSelectedEmployees.slice(selectedIndex + 1)
      );
    }
    setErrorSelectedEmployees(newSelected);
  };
  const handleWorkdaysChange = (dates, id) => {
    const DATES = [];
    dates.forEach((date) => {
      const mm = date?.month?.number < 10 ? `0${date?.month?.number}` : date?.month?.number;
      const dd = date?.day < 10 ? `0${date?.day}` : date?.day;
      const YYYY_MM_DD = `${date?.year}-${mm}-${dd}`;
      DATES.push(YYYY_MM_DD);
    });
    const epI = errorSelectedEmployees.findIndex((p) => p.id === id);
    setErrorSelectedEmployees([{ ...errorSelectedEmployees[epI], workdays: DATES }]);
  };

  // useEffect(() => {
  //   console.log('Error Selected:', errorSelectedEmployees);
  // }, [errorSelectedEmployees]);

  const forceAssignShiftMutation = useMutationFunc(
    forceAssignShift,
    {
      onMutate: {
        default: false
      },
      onSuccess: {
        title: 'Successfully Completed!!'
      }
    },
    null,
    () => {
      cache.invalidateQueries('sites');
      cache.invalidateQueries('roster');
      cache.invalidateQueries('shifts');
      cache.invalidateQueries('roster-availability');
    }
  );

  return (
    <Modal
      open={Object.keys(errorsData).length > 0}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Stack
          direction="row"
          sx={{ p: 2, backgroundImage: theme.palette.gradients.blue }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography sx={{ color: 'white' }}>Shift Assignment Errors</Typography>
          <CloseIcon sx={{ cursor: 'pointer', color: 'white' }} onClick={() => setErrors({})} />
        </Stack>
        <Typography sx={{ p: 2 }}>{errorsData?.message}</Typography>
        {errorsData?.holidays?.length > 0 && (
          <Alert severity="info">
            <AlertTitle>No Shifts can be assign on these dates due to holidays.</AlertTitle>
            {errorsData?.holidays?.toString().replace(',', ', ')}
          </Alert>
        )}
        <Box sx={{ overflowY: 'auto', overflowX: 'auto', height: 'calc(100vh - 18rem)', px: 2 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Employee</TableCell>
                <TableCell sx={{ minWidth: 300 }}>Errors</TableCell>
                <TableCell>Re-Assign Days</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errorsData?.errors?.length > 0
                ? errorsData?.errors.map((ep, index) => {
                    const isItemSelected =
                      errorSelectedEmployees.findIndex((p) => p?.id === ep?._id) !== -1;
                    const ascDate = ep?.dates[0]?.split('-');
                    const dscDate = ep?.dates[ep?.dates?.length - 1]?.split('-');
                    let minDate = null;
                    let maxDate = null;
                    if (ascDate && dscDate) {
                      minDate = new Date(
                        Number(ascDate[0]),
                        Number(formatDigit(ascDate[1] - 1)),
                        Number(formatDigit(ascDate[2])),
                        0,
                        0,
                        0,
                        0
                      );
                      maxDate = new Date(
                        Number(dscDate[0]),
                        Number(formatDigit(dscDate[1] - 1)),
                        Number(formatDigit(dscDate[2])),
                        0,
                        0,
                        0,
                        0
                      );
                    }

                    return (
                      <TableRow key={ep._id}>
                        <TableCell padding="checkbox">
                          {ep?.overlaps?.length === 0 ? (
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, ep)}
                            />
                          ) : (
                            index + 1
                          )}
                        </TableCell>
                        <TableCell>{ep.name}</TableCell>
                        <TableCell>
                          <ol>
                            {ep.breached.map((brh) => (
                              <li style={{ color: 'green' }} key={brh._id}>
                                {brh}
                              </li>
                            ))}
                            {ep?.overlaps?.length > 0 &&
                              ep.overlaps.map((ovr) => (
                                <li style={{ color: 'red' }} key={ovr._id}>
                                  Timing Overlap of{' '}
                                  <b>
                                    {ovr.shift} ({ovr.start} - {ovr.end})
                                  </b>{' '}
                                  in site <b>{ovr.site}</b>
                                </li>
                              ))}
                          </ol>
                          {ep?.offClash !== undefined && `${ep.offClash}`}
                        </TableCell>
                        {errorsData?.overlaps?.length === 0 ? (
                          <TableCell>
                            <DatePicker
                              calendarPosition="bottom-center"
                              inputClass="custom-input"
                              placeholder="Select Work Days"
                              format="YYYY-MM-DD"
                              sort
                              disabled={
                                errorSelectedEmployees.findIndex((p) => p.id === ep?._id) === -1
                              }
                              minDate={minDate}
                              maxDate={maxDate}
                              // eslint-disable-next-line consistent-return
                              mapDays={({ date }) => {
                                const mm =
                                  date.month.number < 10
                                    ? `0${date.month.number}`
                                    : date.month.number;
                                const dd = date.day < 10 ? `0${date.day}` : date.day;
                                const notWorkday = ep?.dates?.includes(`${date?.year}-${mm}-${dd}`);
                                if (!notWorkday)
                                  return {
                                    disabled: true,
                                    style: { color: '#ccc' },
                                    onClick: () => alert('weekends are disabled')
                                  };
                              }}
                              multiple
                              value={
                                errorSelectedEmployees?.find((emp) => emp?.id === ep?._id)
                                  ?.workdays ?? ep?.dates
                              }
                              onChange={(value) => {
                                console.log(value);
                                handleWorkdaysChange(value, ep?._id);
                              }}
                            />
                          </TableCell>
                        ) : (
                          <Typography>NA</Typography>
                        )}
                      </TableRow>
                    );
                  })
                : 'No Error'}
            </TableBody>
          </Table>
        </Box>
        <Stack sx={{ mt: 1 }} direction="row" justifyContent="center">
          {errorSelectedEmployees.length > 0 && (
            <Typography sx={{ color: '#0897FF', fontWeight: 'bold' }}>
              {errorSelectedEmployees.length} Selected
            </Typography>
          )}
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 1, p: 1 }}
          alignItems="center"
          justifyContent="center"
        >
          {errorSelectedEmployees?.length > 0 ? (
            <Button
              variant="contained"
              onClick={() => {
                Swal.fire({
                  title: 'Are you sure?',
                  html: `All Selected employees having issues due to constraints or holidays in assigning shifts will be assigned forcefully!!`,
                  icon: 'question',
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonText: 'Yes, Assign Shifts!'
                }).then((result) => {
                  if (result.isConfirmed) {
                    forceAssignShiftMutation({
                      shift: shiftId,
                      employees: errorSelectedEmployees,
                      // shouldCheckConstraints: false,
                      // workdays: workDays.map(
                      //   (d) =>
                      //     `${d.year}-${
                      //       d.month.number < 10 ? `0${d.month.number}` : d.month.number
                      //     }-${d.day < 10 ? `0${d.day}` : d.day}`
                      // ),
                      today: getCurrentDate()
                    });
                  }
                });
              }}
            >
              Re assign
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                Swal.fire({
                  title: 'Are you sure?',
                  html: `All employees having issues due to constraints or holidays in assigning shifts will be assigned forcefully!!`,
                  icon: 'question',
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonText: 'Yes, Assign Shifts!'
                }).then((result) => {
                  if (result.isConfirmed) {
                    forceAssignShiftMutation({
                      shift: shiftId,
                      // eslint-disable-next-line array-callback-return
                      // eslint-disable-next-line consistent-return
                      employees: errorsData?.errors
                        .map((ep) => {
                          if (ep?.dates?.length > 0) {
                            return {
                              id: ep._id,
                              workdays: ep.dates
                            };
                          }
                          return null;
                        })
                        .filter((ep) => ep !== null),
                      // shouldCheckConstraints: false,
                      // workdays: workDays.map(
                      //   (d) =>
                      //     `${d.year}-${d.month.number < 10 ? `0${d.month.number}` : d.month.number}-${
                      //       d.day < 10 ? `0${d.day}` : d.day
                      //     }`
                      // ),
                      today: getCurrentDate()
                    });
                  }
                });
              }}
            >
              Re assign All
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  rMonth: PropTypes.any,
  show: PropTypes.any,
  setShowSidebar: PropTypes.any
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  rMonth,
  show,
  setShowSidebar
}) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  // const [currentMonth, setCurrentMonth] = useState([]);
  // const [rosterMonth, setRosterMonth] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [imageSrc, setImageSrc] = useState();
  const [assignShiftOpen, setAssignShiftOpen] = useState(false);
  // const [addToSiteOpen, setAddToSiteOpen] = useState(false);
  const [shiftId, setShiftId] = useState();
  // const [siteOptions, setSiteOptions] = useState([]);
  const [siteDict, setSiteDict] = useState({});
  const [workDays, setWorkDays] = useState([]);
  const [shiftOptions, setShiftOptions] = useState();
  const [filterName, setFilterName] = useState();
  // const [siteName, setSiteName] = useState([]); // Store Add to Site Data
  const [openSchedule, setOpenSchedule] = useState(); // Open Schedule
  const [openSites, setOpenSites] = useState(); // Open Sites
  const [search] = useDebounce(filterName, 1000);
  // Selected Employee Arrays
  const [selected, setSelected] = useState([]);
  const [selectedE, setSelectedE] = useState([]);
  const [roleOptions, setRoleOptions] = useState(); // Roles initial State for select
  const [subRoleOptions, setSubRoleOptions] = useState(); // Sub Roles initial State for select
  const [assignError, setAssignError] = useState({});
  const {
    // isAdminAuthenticated,
    info
  } = useSelector((state) => state.adminInfo);
  // useEffect(() => {
  //   const mainAsync = async () => {
  //     const calendarDates = new CalendarDates();
  //     const mayDates = await calendarDates.getDates(rosterMonth);
  //     // const mayMatrix = await calendarDates.getMatrix(may2018);
  //     const newarray = [].concat(...Array(7).fill(DayOfWeeks));
  //     mayDates.map((date, index) => (date.day = newarray[index]));
  //     const currentMonthWeekdays = mayDates.filter((date) => date.type === 'current');
  //     setCurrentMonth([...currentMonthWeekdays]);
  //   };
  //   mainAsync();
  // }, [rosterMonth]);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const [value, setValue] = useState(0);
  const [evalue, setEValue] = useState(0);
  const [filter, setFilter] = useState({
    role: '',
    subRole: '',
    type: null
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleEChange = (event, newValue) => {
    setEValue(newValue);
  };

  const cache = useQueryClient();
  const selectedSite = params.site.replace(':', '');
  const monthDate = params.date.replace(':', '');
  const {
    // isLoading: isSiteLoading,
    // isError: isSiteError,
    data: _siteData
  } = useSiteById(selectedSite);

  const handleAClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleEClick = (event, name) => {
    const selectedIndex = selectedE.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedE, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedE.slice(1));
    } else if (selectedIndex === selectedE.length - 1) {
      newSelected = newSelected.concat(selectedE.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedE.slice(0, selectedIndex),
        selectedE.slice(selectedIndex + 1)
      );
    }
    setSelectedE(newSelected);
  };

  /* For Sweet ALert Error Display */
  // const [errorSelected, setErrorSelected] = useState([]);

  /* ===================== Roles ===================== */

  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      allRoles.forEach((role) => formattedRoles.push([`${role._id},${role.name}`, role.name]));
      setRoleOptions([...formattedRoles]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allRoles]);

  /* ================= End Roles =============== */

  /* ================== Sub Roles ======================== */

  const {
    isLoading: isSubRolesLoading,
    isError: isSubRolesError,
    data: subRoles
  } = useSubRoles(filter.role.split(',')[0]);

  useMemo(() => {
    const listOfSubRoles = [];
    if (!isSubRolesLoading && !isSubRolesError) {
      if (subRoles?.roles?.length > 0) {
        subRoles.roles[0].subroles.forEach((role) =>
          listOfSubRoles.push([`${role._id},${role.name}`, role.name])
        );
      }
    }
    // console.log('Sub Roles By Role:', listOfSubRoles);
    setSubRoleOptions([...listOfSubRoles]);
  }, [subRoles, isSubRolesLoading, isSubRolesError]);

  /* ================== End Sub Roles ====================== */

  /* ===================== Sites ===================== */

  const {
    isLoading,
    isError,
    data: sites
    // fetchNextPage: fetchNextSites,
    // hasMoreData: hasNextSites
    // refetch: refetchSites
  } = useSites(
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[0], true) : null, // from
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[1], true) : null, // to
    getCurrentDate(), // filter.date.length > 0 ? filter.date : getCurrentDate(),
    null, // type
    null, // filter.state, // state
    null, // filter.status, // status
    null, // filter.manager, // manager
    // filter.role,
    null, // search
    null
  );

  const rows = useMemo(() => {
    const listOfSites = [];
    if (!isLoading && !isError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    return flattened;
  }, [sites, isLoading, isError]);

  /* ===================== Employees ===================== */

  const {
    isPotentialLoading,
    isPotentialError,
    data,
    fetchNextPage: fetchNextPotentialSiteMembers,
    hasNextPage: hasNextPotentialSiteMembers
  } = usePotentialSiteMembers(
    selectedSite,
    search, // search
    filter.role.split(',')[0],
    filter.subRole.split(',')[0],
    filter.type === 1
  ); // getting employees

  const potentialEmployees = useMemo(() => {
    if (!isPotentialLoading && !isPotentialError) {
      const listOfEmployees = [];
      if (data?.pages?.length > 0) {
        data.pages.forEach((group) => listOfEmployees.push(group.employees));
      }
      const flattened = listOfEmployees.flat();
      // console.log('Sites Members', flattened);
      return flattened;
    }
    return [];
  }, [data, isPotentialLoading, isPotentialError]);

  /* ==================== Site Employees ================= */

  const {
    isLoading: isSiteEmployeesLoading,
    isError: isSiteEmployeesError,
    hasNextPage: hasNextSiteEmployees,
    fetchNextPage: fetchNextSiteEmployees,
    data: siteEmployees
  } = useSiteEmployees(
    selectedSite,
    filter.role.split(',')[0],
    search,
    filter.subRole.split(',')[0],
    filter.type === 1
  );

  const SiteEmployees = useMemo(() => {
    const listOfSiteEmployees = [];
    if (!isSiteEmployeesLoading && !isSiteEmployeesError) {
      if (siteEmployees?.pages?.length > 0) {
        siteEmployees.pages.forEach((group) => {
          listOfSiteEmployees.push(group.employees);
        });
      }
    }
    const flattened = listOfSiteEmployees.flat();
    // console.log('Sites Employees:', flattened);
    // return flattened;
    const filtered = [];
    flattened.forEach((emp) => {
      if (!(_siteData?.site?.manager?._id === emp._id)) {
        filtered.push(emp);
      }
    });
    // console.log('Site Employees :', filtered);
    return filtered;
  }, [siteEmployees, isSiteEmployeesLoading, isSiteEmployeesError, _siteData]);

  /* ============================= Get All Shifts Options =============================== */

  const {
    isShiftsLoading,
    isShiftsError,
    data: shifts
    // fetchNextPage: fetchNextShifts,
    // hasMoreData: hasMoreShifts,
    // refetch: refetchShifts
  } = useShifts('', selectedSite, null, null);

  const allShifts = useMemo(() => {
    // console.log('All Shifts Sidebar :', shifts);
    const listOfShifts = [];
    if (!isShiftsLoading && !isShiftsError) {
      if (shifts?.pages?.length > 0) {
        shifts.pages.forEach((group) => listOfShifts.push(group.shifts));
      }
    }
    const flattened = listOfShifts.flat();
    // console.log('All Shifts Sidebar :', flattened);
    return flattened;
  }, [shifts, isShiftsLoading, isShiftsError]);

  useEffect(() => {
    const formattedShifts = [];
    if (allShifts?.length > 0) {
      allShifts.forEach((shift) => {
        if (shift.status === 'Active') {
          formattedShifts.push([`${shift._id},${shift.name}`, shift.name]);
        }
      });
      setShiftOptions([...formattedShifts]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allShifts]);

  /* ======================= End Get All Shifts Options ======================= */

  /* ===================== Sites ===================== */

  const allSites = useMemo(() => {
    const listOfSites = [];
    if (!isLoading && !isError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Sites :', flattened);
    return flattened;
  }, [sites, isLoading, isError]);

  useEffect(() => {
    const formattedSites = [];
    if (allSites?.length > 0) {
      allSites.forEach((site) => {
        // setSiteManagerPriority(site.manager.role.priority);
        if (site.active === 'Active') {
          // _siteManager[site._id] = site.manager._id;
          siteDict[site._id] = site.name;
          formattedSites.push([site._id, site.name, site.manager._id]);
        }
      });
      // _setSiteManager({ ..._siteManager });
      setSiteDict({ ...siteDict }); // Site Value Names
      // setSiteOptions([...formattedSites]);
      // console.log('Role Options :', formattedRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSites]);

  /* ==================== Remove Site Mutation ============================== */

  const { mutate: removeFromSiteMutation } = useMutation(removeFromSite, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Removing Members from site 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Removed from ${_siteData?.site?.name}`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('potential-site-members');
      cache.invalidateQueries('site-employees');
      cache.invalidateQueries('roster');
    }
  });

  /* =================== End Site Employees =========================== */

  const { mutate: assignSiteMutation } = useMutation(assignSite, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Adding Members to site 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Added ${selected.length > 1 ? 'Employees' : 'Employee'} to ${
          _siteData?.site?.name
        }`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
      setSelected([]);
      setSelectedE([]);
    },
    onSettled: () => {
      cache.invalidateQueries();
      // cache.invalidateQueries('potential-site-members');
      // cache.invalidateQueries('site-employees');
      // cache.invalidateQueries('roster');
    }
  });

  /* ================= Assign Shift Mutation ================ */
  const { mutate: assignShiftMutation } = useMutation(assignShiftMutate, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Assigning Shift :)',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // for unauthorized access
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      const msg = error.response
        ? error.response.data.message || error.toString()
        : error.toString();

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: msg,
        showConfirmButton: false
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: (response) => {
      if (response.status === 201) {
        // Overlaps when timings of assigning shifts are same
        setAssignError(response.data);
        Swal.close();
        // Swal.fire({
        //   icon: 'warning',
        //   title: `Following Errors Found!`,
        //   width: '35rem',
        //   allowOutsideClick: false,
        //   html: `
        //     <table border="0" style="white-space: nowrap;border-collapse: collapse;display:block;max-height:20rem;overflow:auto;position:relative;">
        //     <caption>${response.data.message}</caption>
        //     <thead>
        //           <tr>
        //           <th style="position: sticky;top: 0;background: white;">#</th>
        //           <th style="position: sticky;top: 0;background: white;">Employee</th>
        //           <th style="position: sticky;top: 0;background: white;">Errors</th>
        //           </tr>
        //         </thead>
        //         <tbody>
        //         ${response?.data?.errors.map(
        //           (ep, i) => `<tr class="${
        //             errorSelected.includes(ep._id) ? 'error-selected' : ''
        //           }" onclick="handleAlertClick(${ep._id})">
        //           <td>${i + 1}</td>
        //         <td>${ep.name}</td>
        //         <td>
        //           <ul style="list-style:none;">
        //           ${ep.breached.map((brh) => `<li>${brh}</li>`)}
        //           ${ep.offClash !== null && `<li>${ep.offClash}</li>`}
        //           ${ep.overlaps.map((ovr) => `${ovr.site}${ovr.shift} (${ovr.start} - ${ovr.end})`)}
        //           </ul>
        //         </td>
        //         </tr>`
        //         )}
        //         <tbody>
        //         </table>
        //     `,
        //   showConfirmButton: true,
        //   showCancelButton: true,
        //   cancelButtonText: 'Override Constraints'
        // }).then((after) => {
        //   if (after.dismiss === 'cancel') {
        //     // eslint-disable-next-line consistent-return
        //     // eslint-disable-next-line array-callback-return
        //     const fmp = response?.data?.errors.map((fl) => {
        //       if (fl.offClash === null && fl.overlaps.length === 0) {
        //         return fl._id;
        //       }
        //     });
        //     console.log(fmp);
        //     const ell = selected.filter((emp) => fmp.includes(emp));
        //     console.log(ell);
        //     Swal.fire({
        //       title: 'Are you sure?',
        //       html: `All employees having issues only due to constraints in assigning shifts will be assigned forcefully!!`,
        //       icon: 'question',
        //       showCancelButton: true,
        //       allowOutsideClick: false,
        //       confirmButtonText: 'Yes, Assign Shifts!'
        //     }).then((result) => {
        //       if (result.isConfirmed && ell.length > 0) {
        //         assignShiftMutation({
        //           shift: shiftId.split(',')[0],
        //           employees: ell,
        //           shouldCheckConstraints: false,
        //           workdays: workDays.map(
        //             (d) =>
        //               `${d.year}-${d.month.number < 10 ? `0${d.month.number}` : d.month.number}-${
        //                 d.day < 10 ? `0${d.day}` : d.day
        //               }`
        //           ),
        //           today: getCurrentDate()
        //         });
        //       }
        //     });
        //   }
        // });
      } else if (response.status === 200) {
        setSelected([]);
        setSelectedE([]);
        setAssignError({});
        Swal.fire({
          icon: 'success',
          title: `Assigned Shift Successfully`,
          text: response.data.message,
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true
        });
      }
    },
    onSettled: () => {
      cache.invalidateQueries();
      // cache.invalidateQueries('roster');
      // cache.invalidateQueries('shifts');
      // cache.invalidateQueries('sites');
      // cache.invalidateQueries('potential-site-members');
      // cache.invalidateQueries('site-employees');
      // cache.invalidateQueries('roster-availability');
    }
  });

  ShowSites.propTypes = {
    emp: PropTypes.object,
    setSites: PropTypes.bool
  };

  function ShowSites({ emp, setSites }) {
    return (
      <Stack spacing={1}>
        <Stack
          sx={{ p: 1, position: 'relative' }}
          direction="row"
          justifyContent="center"
          alignContent="center"
        >
          {setSites && (
            <IconButton
              onClick={() => setSites(null)}
              sx={{ position: 'absolute', left: 0, top: 0 }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Stack>
            <Stack sx={{ mb: 0.5 }} direction="row" justifyContent="center">
              <Avatar alt={emp.name} src={emp.avatar} sx={{ mr: 1 }} />
            </Stack>
            <Typography>
              {emp.name}
              &nbsp;
              <Chip
                size="small"
                label={emp.role.name}
                sx={{ fontSize: 11, height: 18, backgroundColor: '#3366FF', color: 'white' }}
              />
            </Typography>
          </Stack>
        </Stack>
        <br />
        <Typography sx={{ fontWeight: 'bold', mb: 1 }}>Assigned Sites</Typography>
        <Divider />
        {emp?.sites?.length > 0 ? (
          emp?.sites?.map((site) => (
            <Chip sx={{ borderRadius: 0.5 }} key={`emp-sites-${site._id}`} label={site.name} />
          ))
        ) : (
          <Typography>No Sites</Typography>
        )}
      </Stack>
    );
  }

  GetAvailability.propTypes = {
    rDate: PropTypes.any,
    site: PropTypes.any
  };

  function GetAvailability({ rDate, site }) {
    /* ========================= Get Month Availability ====================== */
    const {
      isLoading: isAvailabilityLoading,
      isError: isAvailabilityError,
      data: siteAvailability
    } = useMonthAvailability(site._id, rDate ?? getCurrentDate());

    const { todayGap } = useMemo(() => {
      // const out = {}; // Shift Availability , Gaps
      // let availability = [];
      if (!isAvailabilityLoading && !isAvailabilityError) {
        if (siteAvailability) {
          return siteAvailability;
        }
      }
      return { sit: [], GAPS: [] };
    }, [siteAvailability, isAvailabilityLoading, isAvailabilityError]);

    const _per = isNaN(todayGap?.percentage) ? 0 : todayGap?.percentage;

    return (
      <>
        <Stack
          sx={{
            border: `${selectedSite === site._id ? '2px solid #0897FF' : '1px solid #D5DBDF'}`,
            // backgroundColor: '#5BA6D8',
            // color: 'white',
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
            borderRadius: 1,
            p: 1,
            m: 1,
            cursor: 'pointer'
          }}
          onClick={() => {
            navigate(`/roster/app/:${site._id}/:${monthDate}`);
            // cache.invalidateQueries('roster');
            // cache.invalidateQueries('shifts');
            // cache.invalidateQueries('roster-availability');
            // cache.invalidateQueries('potential-site-members');
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <b>{site.name}</b>
            </Typography>
            <Typography>
              {todayGap?.available ?? 0}&nbsp;/&nbsp;{todayGap?.requirement ?? 0}
            </Typography>
          </Stack>
          <Stack direction="row" alignContent="center">
            <Typography sx={{ width: '25%' }}>Today</Typography>
            <LinearProgress
              sx={{
                mt: 1,
                height: 10,
                borderRadius: 5,
                width: '45%',
                bgcolor: 'white !important',
                border: '1px solid #E7EBEE'
              }}
              color={
                _per === 100 ? 'success' : _per < 50 ? 'warning' : _per > 100 ? 'error' : 'info'
              }
              variant="determinate"
              value={_per === Infinity ? 0 : _per > 100 ? 100 : Number(parseFloat(_per).toFixed(2))}
            />
            <Typography sx={{ width: '30%', px: 1 }} align="center">
              {_per === Infinity ? 0.0 : parseFloat(_per).toFixed(2)}
              &nbsp;%
            </Typography>
          </Stack>
        </Stack>
      </>
    );
  }

  const renderContent = (
    <Stack>
      {/* <Box sx={{ px: 2.5, py: 3 }}>
        <Stack direction="row" alignItems="center">
          {/* <Tooltip title="Go Back">
            <IconButton component={RouterLink} to="/dashboard/roster">
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Box> */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
        <AppBar sx={{ flexDirection: 'row' }} position="static">
          <Tooltip title="Hide Sidebar">
            <IconButton onClick={() => setShowSidebar(false)}>
              <MenuIcon sx={{ color: 'white' }} />
            </IconButton>
          </Tooltip>
          <Tabs
            value={value}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={`Sites ( ${rows.length} )`} {...a11yProps(0)} />
            <Tab label="Employees" {...a11yProps(1)} />
            {/* <Tab label="Shifts" {...a11yProps(2)} /> */}
          </Tabs>
        </AppBar>
      </Box>
      {/* // const reqp =
//   row.total_requirement.length > 0
//     ? Math.floor(
//         (100 *
//           row.employees.reduce(
//             (s, obj) => s + (obj.role._id !== row.manager.role._id ? obj.total : 0),
//             0
//           )) /
//           row.total_requirement.reduce((s, obj) => s + obj.total, 0)
//       )
//     : 0; */}
      <TabPanel value={value} index={0}>
        {!isLoading && !isError ? (
          rows.length > 0 ? (
            rows.map((row) => <GetAvailability key={`side-${row._id}`} site={row} rDate={rMonth} />)
          ) : (
            <NotAvailable type="na" msg="No Data Available" />
          )
        ) : (
          <NotAvailable type="loading" msg="Loading..." />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {openSchedule ? (
          <Timeline emp={openSchedule} setSchedule={setOpenSchedule} /> // Timeline for Non Existing Employees
        ) : openSites ? (
          <ShowSites emp={openSites} setSites={setOpenSites} />
        ) : (
          <>
            <Stack sx={{ display: `${assignShiftOpen ? 'none' : ''}` }}>
              {selected.concat(selectedE).length > 0 ? (
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ px: 1, color: '#0897FF', fontWeight: 'bold' }}>{`${
                    selected.concat(selectedE).length
                  } Selected`}</Typography>
                  {info._id === _siteData?.site?.manager._id && (
                    <Tooltip title="Add Member to Shift">
                      <Button
                        sx={{ minWidth: 40, fontSize: 12 }}
                        size="small"
                        variant="contained"
                        onClick={() => setAssignShiftOpen(true)}
                      >
                        Assign
                      </Button>
                    </Tooltip>
                  )}
                  {info?.role?.priority <= _siteData?.site?.manager?.role?.priority ||
                  info.isMainAdmin ? (
                    <Tooltip title="Add to Site">
                      <Button
                        sx={{
                          minWidth: 40,
                          fontSize: 12,
                          display: `${selectedE.length > 0 ? 'none' : ''}`
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                          Swal.fire({
                            title: 'Are you sure?',
                            text: `All Selected Employees will get added to ${_siteData?.site?.name}`,
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Yes, Proceed!',
                            allowOutsideClick: false
                          }).then((result) => {
                            if (result.isConfirmed) {
                              assignSiteMutation({
                                sites: [selectedSite],
                                employees: selected
                                // isMultiple: true
                              });
                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                              cache.invalidateQueries('unmanaged-employees');
                            }
                          });
                        }}
                      >
                        <AddIcon sx={{ fontSize: 16 }} />
                      </Button>
                    </Tooltip>
                  ) : null}
                  {info?.role?.priority <= _siteData?.site?.manager?.role?.priority ||
                  info.isMainAdmin ? (
                    <Tooltip title="Remove from Site">
                      <Button
                        sx={{
                          minWidth: 40,
                          fontSize: 12,
                          display: `${selected.length > 0 ? 'none' : ''}`
                        }}
                        size="small"
                        color="error"
                        variant="contained"
                        onClick={() => {
                          Swal.fire({
                            title: 'Are you sure?',
                            text: `All Selected Employees will get removed from ${_siteData?.site?.name}`,
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Yes, Proceed!',
                            allowOutsideClick: false
                          }).then((result) => {
                            if (result.isConfirmed) {
                              removeFromSiteMutation({
                                site: selectedSite,
                                employees: selectedE,
                                today: getCurrentDate()
                              });
                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                              cache.invalidateQueries('unmanaged-employees');
                            }
                          });
                        }}
                      >
                        <RemoveIcon sx={{ fontSize: 16 }} />
                      </Button>
                    </Tooltip>
                  ) : null}
                </Stack>
              ) : null}
              <Stack direction="row" alignItems="center" sx={{ my: 1 }}>
                <IconButton
                  onClick={(event) =>
                    setAnchorEl({
                      id: `sidebar-employee-filter`,
                      target: event.currentTarget
                    })
                  }
                >
                  <FilterListIcon sx={{ fontSize: 20 }} />
                </IconButton>
                <Popover
                  id="sidebar-employee-filter"
                  open={anchorEl.id === `sidebar-employee-filter`}
                  anchorEl={anchorEl.target}
                  onClose={() => setAnchorEl({ id: null, target: null })}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <Stack spacing={1} width="320px" sx={{ p: 2 }}>
                    <Stack direction="row" alignItems="center">
                      <FilterListIcon />
                      <Typography sx={{ ml: 1 }}>Filters</Typography>
                    </Stack>
                    <Divider />
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="role_filter">
                          Choose Role
                        </InputLabel>
                        <Select
                          variant="standard"
                          value={filter?.role}
                          inputProps={{
                            name: 'role_filter',
                            id: 'role_filter'
                          }}
                          onChange={(e) =>
                            setFilter({ ...filter, role: e.target.value, subRole: '' })
                          }
                          sx={{ mt: 2 }}
                        >
                          <MenuItem value="" disabled>
                            Choose Role
                          </MenuItem>
                          {roleOptions?.length > 0 ? (
                            roleOptions.map(([key, value, image]) => (
                              <MenuItem
                                key={`${'role_filter'}-${key}`}
                                value={key}
                                data-icon={image}
                              >
                                {value}
                              </MenuItem>
                            ))
                          ) : (
                            <>
                              <MenuItem value="" disabled />
                            </>
                          )}
                        </Select>
                      </FormControl>
                      &nbsp;
                      {filter.role ? (
                        <Tooltip title="Clear">
                          <IconButton
                            onClick={() => setFilter({ ...filter, role: '', subRole: '' })}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                    </Box>
                    {/* Sub Role */}
                    {filter.role && subRoleOptions?.length > 0 ? (
                      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        <FormControl fullWidth>
                          <InputLabel variant="standard" htmlFor="role_type">
                            Choose Sub Role
                          </InputLabel>
                          <Select
                            variant="standard"
                            disabled={!(filter.role && subRoleOptions.length > 0)}
                            value={filter.subRole}
                            inputProps={{
                              name: 'sub_role_type',
                              id: 'sub_role_type'
                            }}
                            required
                            sx={{ mt: 2 }}
                            onChange={(e) => setFilter({ ...filter, subRole: e.target.value })}
                            // error={Boolean(touched.sub_role_type && errors.sub_role_type)}
                            // helperText={touched.policy_role && errors.policy_role}
                          >
                            <MenuItem value="" disabled>
                              Choose Sub Role
                            </MenuItem>
                            {subRoleOptions?.length > 0 ? (
                              subRoleOptions.map(([key, value, image]) => (
                                <MenuItem
                                  key={`${'sub_role_type'}-${key}`}
                                  value={key}
                                  data-icon={image}
                                >
                                  {value}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value="" disabled />
                            )}
                          </Select>
                        </FormControl>
                        &nbsp;
                        {filter.subRole ? (
                          <Tooltip title="Clear">
                            <IconButton onClick={() => setFilter({ ...filter, subRole: '' })}>
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </Box>
                    ) : null}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <GroupIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="gender_filter">
                          Choose Type
                        </InputLabel>
                        <Select
                          variant="standard"
                          value={filter.type}
                          inputProps={{
                            name: 'emp_type',
                            id: 'emp_type_filter'
                          }}
                          onChange={(e) => {
                            setFilter({ ...filter, type: e.target.value });
                          }}
                          sx={{ mt: 2 }}
                        >
                          <MenuItem value="" disabled>
                            Choose Type
                          </MenuItem>
                          <MenuItem value={0}>Assigned</MenuItem>
                          <MenuItem value={1}>Un Assigned</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    {/* <Box>
                  <Button
                    sx={{ float: 'right' }}
                    variant="contained"
                    size="medium"
                    onClick={() => setFilter(filter)}
                  >
                    Apply
                  </Button>
                </Box> */}
                  </Stack>
                </Popover>
                &nbsp;
                <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                &nbsp;
                <Chip
                  sx={{ fontSize: 11 }}
                  color="secondary"
                  label={`Role : ${filter.role.split(',')[1] ?? 'All'}`}
                  size="small"
                />
                &nbsp;
                <Chip
                  sx={{ backgroundColor: '#DC7633', color: 'white', fontSize: 11 }}
                  label={`Sub Role : ${filter.subRole.split(',')[1] ?? 'All'}`}
                  size="small"
                />
              </Stack>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={evalue}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="fullWidth"
                  onChange={handleEChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="All" {...a11yEProps(0)} />
                  <Tab label="Existing" {...a11yEProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={evalue} index={0}>
                <SearchStyle
                  sx={{ my: 1 }}
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  placeholder="Search Employee..."
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  }
                />
                <TableContainer
                  className="custom_scrollbar"
                  id="new-employee-table"
                  sx={{ height: 'calc(100vh - 255px)', overflowX: 'hidden' }}
                >
                  <Table sx={{ pr: 1, mt: 1 }}>
                    <TableBody>
                      {potentialEmployees.length > 0 ? (
                        potentialEmployees.map((row, idx) => {
                          // console.log(row);
                          const isItemSelected = selected.indexOf(row._id) !== -1;
                          return (
                            <TableRow
                              hover
                              key={row._id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Stack>
                                  <Stack direction="row" alignItems="center" spacing={1}>
                                    {info?.role?.priority <=
                                      _siteData?.site?.manager?.role?.priority ||
                                    info.isMainAdmin ? (
                                      <Checkbox
                                        checked={isItemSelected}
                                        onChange={(event) => {
                                          handleAClick(event, row._id);
                                        }}
                                      />
                                    ) : (
                                      idx + 1
                                    )}
                                    <Avatar
                                      onClick={() => setImageSrc(row.avatar)}
                                      alt={row.name}
                                      src={row.avatar}
                                      sx={{ mx: 1, cursor: 'pointer' }}
                                    />
                                    <Stack sx={{ mt: 0.4 }}>
                                      <Typography sx={{ display: 'flex', alignContent: 'center' }}>
                                        {truncateString(row.name, 9)}&nbsp;
                                      </Typography>
                                      <Typography sx={{ color: '#3366FF', fontSize: 11 }}>
                                        <b>{row.role.name}</b>
                                      </Typography>
                                      {row?.subrole ? (
                                        <Typography sx={{ color: '#DC7633', fontSize: 11 }}>
                                          <b>{row.subrole.name}</b>
                                        </Typography>
                                      ) : null}
                                    </Stack>
                                  </Stack>
                                  <Stack
                                    direction="row"
                                    justifyContent="right"
                                    spacing={1}
                                    sx={{ py: 1, px: 2 }}
                                  >
                                    {row.schedule.length > 0 && (
                                      <Tooltip title="View Schedule">
                                        <Chip
                                          icon={<ScheduleIcon />}
                                          color="secondary"
                                          variant="outlined"
                                          size="small"
                                          onClick={() => setOpenSchedule(row)}
                                          sx={{
                                            fontSize: 18,
                                            border: 'none',
                                            borderRadius: 1,
                                            '& .MuiChip-label': { pr: 0 },
                                            cursor: 'pointer'
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                    <Tooltip title="View Assigned Sites">
                                      <Chip
                                        sx={{ borderRadius: 1, border: 'none' }}
                                        onClick={() => setOpenSites(row)}
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        label={`Sites ( ${row.sites?.length} )`}
                                      />
                                    </Tooltip>
                                  </Stack>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <NotAvailable type="na" msg="No Employees Available" />
                      )}
                    </TableBody>
                  </Table>
                  {/* Put the scroll bar always on the bottom */}
                  <InfiniteScroll
                    dataLength={potentialEmployees?.length}
                    next={fetchNextPotentialSiteMembers}
                    // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
                    // inverse //
                    hasMore={hasNextPotentialSiteMembers}
                    loader={<CircularProgress />}
                    scrollableTarget="new-employee-table"
                    // endMessage={
                    //   <p style={{ textAlign: 'center' }}>
                    //     <b>Yay! You have seen it all</b>
                    //   </p>
                    // }
                  />
                </TableContainer>
              </TabPanel>
              <TabPanel value={evalue} index={1}>
                <SearchStyle
                  sx={{ my: 1 }}
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  placeholder="Search Employee..."
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  }
                />
                <TableContainer
                  className="custom_scrollbar"
                  id="existing-employee-table"
                  sx={{ height: 'calc(100vh - 255px)', overflowX: 'hidden' }}
                >
                  {!isSiteEmployeesLoading ? (
                    <Table sx={{ pr: 1, mt: 1 }}>
                      <TableBody>
                        {SiteEmployees.length > 0 ? (
                          SiteEmployees.map((row, idx) => {
                            // console.log('Employee Schedule:', row);
                            const isItemSelected = selectedE.indexOf(row._id) !== -1;
                            return (
                              <TableRow
                                // hover
                                key={`${row._id}-site-employees-existing`}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                      {info._id === _siteData?.site?.manager._id ||
                                      info?.isMainAdmin ? (
                                        <Checkbox
                                          checked={isItemSelected}
                                          onChange={(event) => handleEClick(event, row._id)}
                                        />
                                      ) : (
                                        idx + 1
                                      )}
                                      <Avatar
                                        onClick={() => setImageSrc(row.avatar)}
                                        alt={row.name}
                                        src={row.avatar}
                                        sx={{ mx: 1, cursor: 'pointer' }}
                                      />
                                      <Stack>
                                        <Typography
                                          sx={{ display: 'flex', alignContent: 'center' }}
                                        >
                                          {truncateString(row.name, 9)}&nbsp;
                                        </Typography>
                                        <Typography sx={{ color: '#3366FF', fontSize: 11 }}>
                                          <b>{row.role.name}</b>
                                        </Typography>
                                        {row?.subrole ? (
                                          <Typography sx={{ color: '#DC7633', fontSize: 11 }}>
                                            <b>{row.subrole.name}</b>
                                          </Typography>
                                        ) : null}
                                      </Stack>
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      justifyContent="right"
                                      spacing={1}
                                      sx={{ py: 1, px: 2 }}
                                    >
                                      {row.schedule.length > 0 && (
                                        <Tooltip title="View Schedule">
                                          <Chip
                                            icon={<ScheduleIcon />}
                                            color="secondary"
                                            variant="outlined"
                                            size="small"
                                            onClick={() => setOpenSchedule(row)}
                                            sx={{
                                              fontSize: 18,
                                              border: 'none',
                                              borderRadius: 1,
                                              '& .MuiChip-label': { pr: 0 },
                                              cursor: 'pointer'
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                      <Tooltip title="View Assigned Sites">
                                        <Chip
                                          sx={{ borderRadius: 1, border: 'none' }}
                                          onClick={() => setOpenSites(row)}
                                          color="primary"
                                          size="small"
                                          variant="outlined"
                                          label={`Sites ( ${row.sites?.length} )`}
                                        />
                                      </Tooltip>
                                      {info._id === _siteData?.site?.manager._id && (
                                        <Tooltip title="Remove From Site">
                                          <Chip
                                            icon={<DeleteOutlinedIcon />}
                                            sx={{
                                              borderRadius: 1,
                                              border: 'none',
                                              '& .MuiChip-label': { pr: 0 },
                                              cursor: 'pointer'
                                            }}
                                            color="error"
                                            size="small"
                                            variant="outlined"
                                            onClick={() => {
                                              Swal.fire({
                                                title: 'Are you sure?',
                                                text: `${row.name} will be removed from ${_siteData?.site?.name}`,
                                                icon: 'question',
                                                showCancelButton: true,
                                                confirmButtonText: 'Yes, Proceed!',
                                                allowOutsideClick: false
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  removeFromSiteMutation({
                                                    site: selectedSite,
                                                    employees: [row._id],
                                                    today: getCurrentDate()
                                                  });
                                                } else if (
                                                  result.dismiss === Swal.DismissReason.cancel
                                                ) {
                                                  cache.invalidateQueries('potential-site-members');
                                                }
                                              });
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </Stack>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <NotAvailable type="na" msg="No Employees Available" />
                        )}
                      </TableBody>
                    </Table>
                  ) : (
                    <NotAvailable type="loading" msg="Loading..." />
                  )}

                  {/* Put the scroll bar always on the bottom */}
                  <InfiniteScroll
                    dataLength={SiteEmployees?.length}
                    next={fetchNextSiteEmployees}
                    // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
                    // inverse //
                    hasMore={hasNextSiteEmployees}
                    loader={<CircularProgress />}
                    scrollableTarget="existing-employee-table"
                    // endMessage={
                    //   <p style={{ textAlign: 'center' }}>
                    //     <b>Yay! You have seen it all</b>
                    //   </p>
                    // }
                  />
                </TableContainer>
              </TabPanel>
            </Stack>
            <Stack
              spacing={1}
              sx={{ display: `${!assignShiftOpen ? 'none' : ''}`, position: 'relative' }}
            >
              <Stack direction="row" sx={{ p: 2 }} alignItems="center">
                <ArrowBackIcon
                  sx={{
                    cursor: 'pointer'
                  }}
                  onClick={() => setAssignShiftOpen(false)}
                />
                <Typography sx={{ ml: 2 }}>Assign shift</Typography>
              </Stack>
              <Box>
                {/* Shift */}
                <Box sx={{ display: 'flex', alignItems: 'flex-end', my: 2 }}>
                  <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="choose_shift">
                      Choose Shift
                    </InputLabel>
                    <Select
                      variant="standard"
                      value={shiftId}
                      inputProps={{
                        name: 'choose_shift',
                        id: 'choose_shift'
                      }}
                      onChange={(e) => setShiftId(e.target.value)}
                      sx={{ mt: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Choose Shift
                      </MenuItem>
                      {shiftOptions?.length > 0 ? (
                        shiftOptions.map(([key, value, image]) => (
                          <MenuItem key={`${'assign_shift'}-${key}`} value={key} data-icon={image}>
                            {value}
                          </MenuItem>
                        ))
                      ) : (
                        <>
                          <MenuItem value="" disabled />
                        </>
                      )}
                    </Select>
                  </FormControl>
                </Box>
                <Typography sx={{ mb: 2 }}>Choose Workdays</Typography>
                <DatePicker
                  calendarPosition="bottom-center"
                  inputClass="custom-input"
                  placeholder="Select Work Days"
                  format="YYYY-MM-DD"
                  sort
                  minDate={new Date()}
                  multiple
                  value={workDays}
                  onChange={setWorkDays}
                />
                <Box sx={{ mt: 3 }}>
                  <Button
                    sx={{ float: 'right' }}
                    variant="contained"
                    size="medium"
                    disabled={!(shiftId && workDays.length > 0)}
                    onClick={() => {
                      assignShiftMutation({
                        shift: shiftId.split(',')[0],
                        employees: selected.concat(selectedE),
                        shouldCheckConstraints: true,
                        workdays: workDays.map(
                          (d) =>
                            `${d.year}-${
                              d.month.number < 10 ? `0${d.month.number}` : d.month.number
                            }-${d.day < 10 ? `0${d.day}` : d.day}`
                        ),
                        today: getCurrentDate()
                      });
                      setAssignShiftOpen(false);
                    }}
                  >
                    Assign
                  </Button>
                </Box>
              </Box>
              <Box className="mobile">
                <AppHolidays noShadow small list mobile />
              </Box>
            </Stack>
          </>
        )}
      </TabPanel>
      {/* <Box sx={{ flexGrow: 1 }} /> */}
      <ErrorsAlert
        errorsData={assignError}
        setErrors={() => setAssignError({})}
        assignMutation={assignShiftMutation}
        shiftId={shiftId?.split(',')[0]}
        workDays={workDays}
      />
      <ImageZoom src={imageSrc} onClose={() => setImageSrc(null)} />
    </Stack>
  );

  return (
    <RootStyle sx={{ display: `${show ? '' : 'none'}` }}>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
