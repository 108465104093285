/* eslint-disable no-nested-ternary */
import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
// material
import { alpha, styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  LinearProgress,
  Typography,
  Tooltip,
  Button,
  Avatar,
  Chip
} from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// components
import { MHidden } from '../../components/@material-extend';
// utils
import {
  // _12hourTimeFormat,
  // ordinal,
  convertUTCtoLocal,
  getDataFromStorage,
  getCurrentDate,
  formatDigit
  // DD_MM_YYYY
} from '../../utils/functions';
import { shortMonth } from '../../utils/config';
//
// import Searchbar from '../dashboard/Searchbar';
import AccountPopover from '../dashboard/AccountPopover';
// import LanguagePopover from '../dashboard/LanguagePopover';
import { useSiteById } from '../../hooks/site';
import { useMonthAvailability } from '../../hooks/get-site-month-availability';
import NotificationsPopover from '../dashboard/NotificationsPopover';

// ----------------------------------------------------------------------

let DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 80;

const months = [
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

RosterNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
  setShowSidebar: PropTypes.func,
  showSidebar: PropTypes.bool
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', width: '75%', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1, position: 'relative' }}>
        <Typography
          align="center"
          className="desktop"
          sx={{ position: 'absolute', width: '100%', zIndex: 99 }}
        >
          {props.date ? (
            <Typography component="span">
              {months[Number(props.date?.split('-')[1]) - 1]},&nbsp;
              {Number(props.date?.split('-')[0])}
            </Typography>
          ) : (
            <Typography component="span">
              {months[new Date().getMonth()]},&nbsp;{new Date().getFullYear()}
            </Typography>
          )}
        </Typography>
        <LinearProgress sx={{ height: 23, borderRadius: 0.4 }} variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography id="total-progress" variant="h3" color="text.secondary">{`${
          Number.isNaN(props.value) || props.value === null ? 0 : parseFloat(props.value).toFixed(2)
        }%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  date: PropTypes.string
};

export default function RosterNavbar({ onOpenSidebar, showSidebar, setShowSidebar }) {
  // const cache = useQueryClient();
  const params = useParams();
  const theme = useTheme();
  const [rMonth, setRMonth] = React.useState(null);
  const selectedSite = params.site.replace(':', '');
  const { isLoading, isError, data } = useMonthAvailability(
    selectedSite,
    rMonth ?? getCurrentDate(),
    true
  );
  // const [anchorEl, setAnchorEl] = React.useState({
  //   id: '',
  //   target: ''
  // });
  const {
    isLoading: isSiteLoading,
    isError: isSiteError,
    data: _siteData
  } = useSiteById(selectedSite);

  const Progress = React.useMemo(() => {
    if (!isLoading && !isError) {
      // console.log('Global Progress Bar:', data);
      const progress = Number(data?.monthProgress);
      // eslint-disable-next-line no-restricted-globals
      return isNaN(progress) ? 0 : parseFloat(progress).toFixed(1);
    }
    return 0;
  }, [data, isError, isLoading]);

  const updateProgress = () => {
    const pp = getDataFromStorage('roster_month');
    setRMonth(convertUTCtoLocal(pp, true));
  };

  if (!showSidebar) {
    DRAWER_WIDTH = 0;
  } else {
    DRAWER_WIDTH = 280;
  }

  /* ========================= Get Month Availability ====================== */

  return (
    <RootStyle>
      <ToolbarStyle>
        <Stack direction="row">
          <MHidden width="lgUp">
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <MenuIcon />
            </IconButton>
          </MHidden>
          <MHidden width="lgDown">
            {/* <Tooltip title="Go Back">
              <IconButton component={RouterLink} to="/dashboard/roster">
                <ArrowBackIcon />
              </IconButton>
            </Tooltip> */}
            {!showSidebar && (
              <IconButton
                onClick={() => setShowSidebar(true)}
                sx={{ mr: 1, color: 'text.primary' }}
              >
                <MenuIcon />
              </IconButton>
            )}
          </MHidden>
          {!isSiteLoading && !isSiteError && (
            <Chip
              sx={{
                height: 50,
                borderRadius: 1,
                position: 'relative',
                border: `1px solid ${theme.palette.grey[200]}`
                // '&:hover': {
                //   backgroundColor: theme.palette.grey[200],
                //   border: `1px solid ${theme.palette.primary.main}`
                // }
              }}
              color={`${
                Progress === 100
                  ? 'lightgreen'
                  : Progress < 50 && Progress > 0
                  ? 'lightyellow'
                  : Progress > 100
                  ? 'lightred'
                  : Progress === 0
                  ? 'default_white'
                  : 'lightblue'
              }`}
              // variant="outlined"
              label={
                <Stack
                  onClick={() => document.getElementById('site-profile-open').click()}
                  direction="row"
                  alignItems="center"
                  // justifyContent="center"
                  spacing={1}
                  sx={{
                    minWidth: '10rem',
                    cursor: 'pointer'
                  }}
                >
                  <Box sx={{ display: 'inline-flex' }}>
                    <Avatar sx={{ width: 30, height: 30 }} src={_siteData?.site?.image} />
                  </Box>
                  <Stack sx={{ color: '#333', minWidth: '6rem' }}>
                    <Tooltip placement="right" title={_siteData?.site?.name}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                        {_siteData?.site?.name.length > 9
                          ? `${_siteData?.site?.name.substring(0, 9)}...`
                          : _siteData?.site?.name}
                      </Typography>
                    </Tooltip>
                    <Stack direction="row" alignItems="center">
                      <Typography sx={{ fontSize: 12, py: 0, mt: 0 }}>
                        {_siteData?.site?.manager?.name}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Chip
                    size="small"
                    color={
                      Progress === 100
                        ? 'success'
                        : Progress < 50 && Progress > 0
                        ? 'warning'
                        : Progress > 100
                        ? 'error'
                        : Progress === 0
                        ? 'lightgrey'
                        : 'secondary'
                    }
                    sx={{
                      height: 15,
                      fontSize: 10,
                      position: 'absolute',
                      right: 5,
                      top: -10,
                      boxShadow: theme.customShadows.bloated,
                      '& .MuiChip-label': { pr: 0 }
                    }}
                    label={
                      <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography sx={{ fontSize: 10 }}>
                          {shortMonth[formatDigit(rMonth?.split('-')[1]) - 1]}
                        </Typography>
                        <Chip
                          size="small"
                          sx={{
                            height: 15,
                            borderRadius: 0.7,
                            minWidth: 50,
                            fontSize: 10,
                            ml: 1,
                            border: `1px solid ${theme.palette.grey[100]}`,
                            backgroundColor: 'white',
                            color: '#333',
                            '& .MuiChip-label': { px: 0 }
                          }}
                          label={`${Progress} %`}
                        />
                      </Stack>
                    }
                  />
                </Stack>
              }
            />
          )}
        </Stack>
        {/* <Tooltip title="Refresh">
          <IconButton onClick={() => cache.invalidateQueries('roster-availability')}>
            <LoopIcon />
          </IconButton>
        </Tooltip> */}
        {/* <Searchbar /> */}
        {/* <Box sx={{ width: '100%', ml: 1 }}>
          <LinearProgressWithLabel value={Number(Progress) ?? 0} date={rMonth} />
        </Box>
        <Box sx={{ flexGrow: 1 }} /> */}
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          <Button sx={{ display: 'none' }} id="update-progress" onClick={updateProgress} />
          {/* Called to Update Progress Value */}
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
