/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, LinearProgress, Box } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Logo from './Logo';

function CustomCircularProgress(props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default function NotAvailable({ msg, type, linear, left, logo, imgSize, value }) {
  return (
    <Stack
      sx={{ height: '100%', width: '100%', py: 2 }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Stack>
        {logo && (
          <Stack sx={{ py: 2, my: 1 }} direction="row" justifyContent="center">
            <Logo size="large" />
          </Stack>
        )}
        <Stack direction="row" justifyContent="center" alignItems="center">
          {type === 'na' && (
            <img
              src="/static/illustrations/no_data_2.svg"
              width={imgSize ?? 150}
              height={imgSize ?? 150}
              alt="No Data"
            />
          )}
          {type === 'loading' && !linear && (
            <>
              <CustomCircularProgress className="loader-small" />
              {msg && left && (
                <Typography sx={{ ml: 2 }} align="center">
                  {msg}
                </Typography>
              )}
            </>
          )}
          {type === 'loading' && linear && <LinearProgress />}
        </Stack>
        {msg && !left && (
          <Typography sx={{ my: 1, color: `${type === 'error' ? 'red' : ''}` }} align="center">
            {msg}
          </Typography>
        )}
        {type === 'progress' && value && (
          <Stack direction="row" justifyContent="center" sx={{ minWidth: 300 }} alignItems="center">
            <Stack sx={{ width: 200 }}>
              <LinearProgress
                sx={{ minWidth: 200, border: '1px solid #C4CDD5' }}
                variant="determinate"
                value={Number(isNaN(value) ? 0 : parseFloat(value).toFixed(1))}
              />
            </Stack>
            <Typography sx={{ ml: 2, fontSize: 12 }} component="span">
              {`${isNaN(value) ? 0 : parseFloat(value).toFixed(0)} %`}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

NotAvailable.propTypes = {
  msg: PropTypes.string,
  type: PropTypes.string,
  linear: PropTypes.bool,
  left: PropTypes.bool,
  logo: PropTypes.bool,
  imgSize: PropTypes.number,
  value: PropTypes.number
};
