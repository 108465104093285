import React from 'react';
// material
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useSelector } from 'react-redux';
// components
import Page from '../components/Page';
import { fetchNotificationsTopics } from '../api/queries/Queries';
import { usePaySlipsByEmployee } from '../hooks/employees';
import { getDataFromStorage } from '../utils/functions';
import { subscribeTokenToTopic } from '../firebaseInit';

// ----------------------------------------------------------------------
const ScheduleAttendance = React.lazy(() =>
  import('../components/_dashboard/app/ScheduleAttendance')
);

function EDashboard({ userPolicy, shiftAttendance }) {
  // const theme = useTheme();
  const { info } = useSelector((state) => state.adminInfo);
  // const [showHolidays, setShowHolidays] = useState(false);
  // const [reinforcementRequest, setReinforcementRequest] = React.useState(false);

  const notificationsTopics = React.useMemo(async () => {
    if (!info?.isMainAdmin) {
      const _topics = await fetchNotificationsTopics();
      return _topics;
    }
    return [];
  }, [info]);

  React.useEffect(() => {
    const registrationToken = getDataFromStorage('fcm_token');
    if (notificationsTopics?.topics?.length > 0) {
      // setDataInStorage('notification_topics', notificationsTopics?.topics);
      notificationsTopics?.topics.forEach((topic) => {
        subscribeTokenToTopic(registrationToken, topic);
      });
    }
  }, [notificationsTopics]);

  const { data } = usePaySlipsByEmployee(info?._id);

  return (
    <Page title="Dashboard | Trackkar">
      <Container maxWidth="xl" sx={{ pb: 3 }}>
        {/* <Stack direction="row" justifyContent="space-between" sx={{ py: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack>
              <MuiDatePicker
                // views={['year', 'month']}
                // label="Year and Month"
                minDate={new Date('2012-03-01')}
                // maxDate={new Date('2023-06-01')}
                value={dashboardDay}
                onChange={(newValue) => {
                  setDashboardDay(newValue);
                }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} helperText={null} />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Stack> */}
        <Grid container spacing={3}>
          {!info?.isMainAdmin && (
            <Grid item xs={12}>
              <ScheduleAttendance userPolicy={userPolicy} shiftAttendance={shiftAttendance} />
            </Grid>
          )}
          {/* <Grid item xs={12}>
            <EmployeesWorkHours />
          </Grid> */}
          {/* <Grid item xs={12}>
            <Card>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ px: 2 }}
              >
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                  Reinforcement Requests&nbsp;
                  <InfoIcon sx={{ fontSize: 16, cursor: 'pointer' }} />
                </Typography>
                <Stack direction="row" spacing={1}>
                  {info?.role?.is_site_manager && (
                    <IconButton
                      sx={{ color: theme.palette.primary.main }}
                      onClick={() => setReinforcementRequest(true)}
                    >
                      <Tooltip title="Apply">
                        <AddCircleIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                  {reinforcementStatus === null ? (
                    <IconButton onClick={() => setReinforcementStatus('Pending')}>
                      <Tooltip title="Pending">
                        <PendingActionsIcon />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setReinforcementStatus(null)}>
                      <Tooltip title="History">
                        <HistoryIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                </Stack>
              </Stack>
              <Divider />
              <GetReinforcement status={reinforcementStatus} />
            </Card>
          </Grid> */}
        </Grid>
        {/* <RequestReinforcementDialog
          open={reinforcementRequest}
          onClose={() => setReinforcementRequest(false)}
        /> */}
      </Container>
    </Page>
  );
}

export default React.memo(EDashboard);
