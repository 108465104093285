/* eslint-disable react/self-closing-comp */
/* eslint-disable no-undef */
import React, {
  useState,
  // useEffect,
  useRef,
  // useCallback,
  useMemo,
  Fragment
} from 'react';
import { useNavigate } from 'react-router-dom';
// import {
//   // useSpring
//   // animated
// } from 'react-spring';
import InfiniteScroll from 'react-infinite-scroll-component';
// material
import {
  Box,
  // Grid,
  // Container,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Checkbox,
  Stack,
  Avatar,
  Tooltip,
  LinearProgress,
  Chip,
  Divider,
  Card
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
// import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// import InfoIcon from '@mui/icons-material/Info';
import LaunchIcon from '@mui/icons-material/Launch';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GroupIcon from '@mui/icons-material/Group';
// import ContactsIcon from '@mui/icons-material/Contacts';
// import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import EmailIcon from '@mui/icons-material/Email';
// import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FilterListIcon from '@mui/icons-material/FilterList';
// components
import {
  GoogleMap,
  useJsApiLoader,
  OverlayView,
  // Marker,
  // InfoWindow,
  Circle
} from '@react-google-maps/api';
import Page from '../components/Page';
import { ImageZoom } from '../components';
import SearchNotFound from '../components/SearchNotFound';
import {
  SiteMapListHead
  // SiteListToolbar,
  // SiteMoreMenu
} from '../components/_dashboard/site';
import {
  // checkForSameLocation,
  // englishDateFormat,
  convertUTCtoLocal,
  // getAddressFromCordinates,
  _12hourTimeFormat,
  getLastDayOfMonth,
  setDataInStorage
} from '../utils/functions';
// hooks
import useSites from '../hooks/get-sites-query';
import { useSiteEmployees } from '../hooks/site';
import { mapsApiKey, ERROR_IMG } from '../utils/config';
import '../index.css';

// ----------------------------------------------------------------------

const TABLE_HEAD = [{ id: 'site', label: 'Site', alignRight: false }];
const EMPLOYEE_TABLE_HEAD = [{ id: 'employee', label: 'Employee', alignRight: false }];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));

export default function Map() {
  // -------------------- MAP -------------------------------
  const mapRef = useRef();
  const [hide, setHide] = useState(true); // Toggle Show Sites Table
  const [imageSrc, setImageSrc] = useState(); // Url Change For Image Zooming
  const [employeeHide, setEmployeeHide] = useState(false); // Toggle Show Employees Table
  // const [mapRef, setMapRef] = useState(null);
  // const [selectedSite, setSelectedSite] = useState(null);
  // const [markerMap, setMarkerMap] = useState({});
  const [center] = useState({ lat: 28.5934, lng: 77.2223 });
  const [zoom] = useState(17); // Change Initial zoom of map
  const [siteClicked, setSiteClicked] = useState();
  // const [, setClickedLatLng] = useState(null); // clickedLatLng
  // const [infoOpen, setInfoOpen] = useState(false);

  // ------------------- SITES TABLE --------------------------------
  const [
    page
    // setPage
  ] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const navigate = useNavigate();
  const [
    filterName
    // setFilterName
  ] = useState('');
  const [
    rowsPerPage
    // setRowsPerPage
  ] = useState(3);
  const [filter, setFilter] = useState({
    range: [],
    state: null,
    status: null,
    role: '',
    manager: null
  });
  // const [anchorMapEl, setAnchorMapEl] = useState({
  //   id: '',
  //   target: ''
  // });

  const { isLoaded, loadError } = useJsApiLoader({
    // uncomment it in production and remove static api key string
    // googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    // googleMapsApiKey: "AIzaSyDciv-rx5ssqbEA99GvIcWeE_67Q41x0Ok",
    googleMapsApiKey: mapsApiKey
  });

  // const options = {
  //   zoomControlOptions: {
  //     position: google.maps.ControlPosition.RIGHT_CENTER // ,
  //     // ...otherOptions
  //   }
  // }

  if (loadError) {
    Swal.fire({
      // eslint-disable-next-line no-undef
      imageUrl: ERROR_IMG,
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'error',
      title: 'Problem while loading maps',
      text: 'Error loading maps. Maybe you are not connected to internet',
      showConfirmButton: true
    });
  }

  const mapContainerStyle = {
    width: '100%',
    height: 'calc(100vh - 125px)'
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: true
  };

  const getPixelPositionOffset = (width, height) => ({
    x: -width / 2,
    y: -height
  });

  /* ===================== Sites ===================== */

  const {
    isLoading,
    isError,
    data: sites,
    fetchNextPage: fetchNextSites,
    hasMoreData: hasNextSites
    // refetch: refetchSites
  } = useSites(
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[0], true) : null, // from
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[1], true) : null, // to
    null, // type
    filter.state,
    filter.status,
    filter.manager,
    // filter.role,
    null, // search
    null
  );

  const rows = useMemo(() => {
    const listOfSites = [];
    if (!isLoading && !isError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Sites :', flattened);
    return flattened;
  }, [sites, isLoading, isError]);

  /* ================= End Sites =============== */

  const SHOWSITES = () => (
    <Card
      sx={{
        position: 'absolute',
        zIndex: 1,
        top: 0,
        mt: 8,
        ml: 1.2,
        borderRadius: 0.5,
        height: 40,
        width: 127,
        backgroundColor: 'white'
      }}
      hidden={!hide}
    >
      <Tooltip title="Show Table">
        <Stack direction="row" sx={{ p: 1, cursor: 'pointer' }} onClick={() => setHide(false)}>
          <Typography>Show Sites</Typography>
          &nbsp;
          <LaunchIcon />
        </Stack>
      </Tooltip>
    </Card>
  );

  const SHOWEMPLOYEES = () => (
    <Card
      sx={{
        position: 'absolute',
        zIndex: 1,
        right: 0,
        mt: 1.2,
        mr: 1.2,
        borderRadius: 0.5,
        height: 40,
        width: 124,
        backgroundColor: 'white'
      }}
      hidden={!employeeHide}
    >
      <Tooltip title="Show Table">
        <Stack
          direction="row"
          sx={{ p: 1, cursor: 'pointer' }}
          onClick={() => setEmployeeHide(false)}
        >
          <Typography>Employees</Typography>
          &nbsp;
          <LaunchIcon />
        </Stack>
      </Tooltip>
    </Card>
  );

  const SITESHELPER = () => (
    <Card
      sx={{
        position: 'absolute',
        zIndex: 1,
        bottom: 0,
        mb: 1,
        ml: 1.2,
        borderRadius: 0,
        height: 40,
        width: 280,
        backgroundColor: 'white'
      }}
    >
      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ pt: 1 }}>
        <FilterListIcon size={16} />
        <Typography>&nbsp;&nbsp;|&nbsp;</Typography>
        <LocationOnIcon sx={{ color: 'green' }} size={16} />
        <Tooltip title="Filter Verified">
          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={() => setFilter({ ...filter, status: 'Verified' })}
          >
            Verified
          </Typography>
        </Tooltip>
        <Typography>&nbsp;&nbsp;|&nbsp;</Typography>
        <NotListedLocationIcon sx={{ color: 'red' }} size={16} />
        <Tooltip title="Filter Not Verified">
          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={() => setFilter({ ...filter, status: 'Unverified' })}
          >
            Not Verified
          </Typography>
        </Tooltip>
      </Stack>
    </Card>
  );

  const EMPLOYEETABLE = () => {
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleEmployeeClick = (event, name) => {
      // console.log('Selected Employees:', name); // Selected Employees
      const selectedIndex = selectedEmployee.indexOf(name);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedEmployee, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedEmployee.slice(1));
      } else if (selectedIndex === selectedEmployee.length - 1) {
        newSelected = newSelected.concat(selectedEmployee.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedEmployee.slice(0, selectedIndex),
          selectedEmployee.slice(selectedIndex + 1)
        );
      }
      setSelectedEmployee(newSelected);
      // if (selected.length > 1) {
      //   const bounds = new window.google.maps.LatLngBounds();
      //   selected.map((site) => {
      //     bounds.extend({
      //       lat: site.fence_location.coordinates[1],
      //       lng: site.fence_location.coordinates[0]
      //     });
      //     return site._id;
      //   });
      //   mapRef.current.state.map.fitBounds(bounds);
      // }
    };

    const {
      isLoading: isSiteEmployeesLoading,
      isError: isSiteEmployeesError,
      hasNextPage: hasNextSiteEmployees,
      fetchNextPage: fetchNextSiteEmployees,
      data: siteEmployees
    } = useSiteEmployees(siteClicked?._id, null); // Role Filter = null

    const AllSiteEmployees = useMemo(() => {
      const listOfSiteEmployees = [];
      if (!isSiteEmployeesLoading && !isSiteEmployeesError) {
        if (siteEmployees?.pages?.length > 0) {
          siteEmployees.pages.forEach((group) => {
            listOfSiteEmployees.push(group.employees);
          });
        }
      }
      const flattened = listOfSiteEmployees.flat();
      // console.log('Sites Employees:', flattened);
      return flattened;
      // const filtered = [];
      // flattened.forEach((emp) => {
      //   if (!selectedSite?.manager?._id === emp._id) {
      //     filtered.push(emp);
      //   }S
      // });
      // // console.log('Site Employees :', flattened);
      // return filtered;
    }, [siteEmployees, isSiteEmployeesLoading, isSiteEmployeesError]);

    const handleSelectAllEmployeeClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = AllSiteEmployees.map((n) => {
          if (n?.location[0]?.coordinates?.length > 0) {
            return n;
          }
          return null;
        }).filter((m) => m !== null);
        setSelectedEmployee(newSelecteds);
        // fire Sweet Alert here
        return;
      }
      setSelectedEmployee([]);
    };

    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - AllSiteEmployees.length) : 0;

    const filteredUsers = applySortFilter(
      AllSiteEmployees,
      getComparator(order, orderBy),
      filterName
    );

    const isUserNotFound = filteredUsers.length === 0;

    return (
      <Card
        sx={{
          position: 'absolute',
          zIndex: 2,
          right: 0,
          mb: 1,
          ml: 1.2,
          borderRadius: 0,
          height: 345,
          width: 300,
          backgroundColor: 'white'
        }}
        hidden={employeeHide}
      >
        <Typography align="center" sx={{ p: 1 }}>
          {siteClicked?.name}
          <Tooltip title="Hide Table">
            <ArrowForwardIcon
              sx={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0, mr: 3, mt: 1.1 }}
              onClick={() => setEmployeeHide(true)}
            />
          </Tooltip>
        </Typography>
        <Divider />
        <TableContainer
          className="custom_scrollbar"
          id="sites-employees-table-container"
          sx={{ minWidth: 200, height: 350 }}
        >
          <Table stickyHeader>
            <SiteMapListHead
              order={order}
              orderBy={orderBy}
              headLabel={EMPLOYEE_TABLE_HEAD}
              rowCount={AllSiteEmployees.filter((asm) => asm.location.length > 0).length}
              numSelected={selectedEmployee.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllEmployeeClick}
            />
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan="9">
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
              {isError && (
                <TableRow>
                  <TableCell>Something Went Wrong</TableCell>
                </TableRow>
              )}
              {!isLoading &&
                filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const isItemSelected = selectedEmployee.indexOf(row) !== -1;
                    return (
                      <TableRow
                        hover
                        key={row._id}
                        sx={{ py: 2 }}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            disabled={row?.location?.length === 0}
                            onChange={(event) => handleEmployeeClick(event, row)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar
                              onClick={() => setImageSrc(row?.avatar)}
                              alt={row.name}
                              src={row?.avatar}
                              sx={{ cursor: 'pointer' }}
                            />
                            <Tooltip
                              sx={{ fontSize: '1rem' }}
                              title={`Visit ${row.name}`}
                              placement="top"
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{ cursor: 'pointer' }}
                                noWrap
                                // onClick={(event) => markerClickHandler(event, row)}
                              >
                                {row.name}
                              </Typography>
                            </Tooltip>
                            <Tooltip title={`${row?.attendance?.in ? 'Present' : 'Absent'}`}>
                              <Typography
                                sx={{
                                  color: `${row?.attendance?.in ? 'green' : 'red'}`,
                                  fontWeight: 'bold'
                                }}
                              >
                                {row?.attendance?.in ? 'P' : 'A'}
                              </Typography>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          {/* Put the scroll bar always on the bottom */}
          <InfiniteScroll
            dataLength={rows?.length}
            next={fetchNextSiteEmployees}
            // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
            // inverse //
            hasMore={hasNextSiteEmployees}
            loader={<h4>Loading...</h4>}
            scrollableTarget="sites-employees-table-container"
            // endMessage={
            //   <p style={{ textAlign: 'center' }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
          />
        </TableContainer>
      </Card>
    );
  };

  const SITESTABLE = () => {
    // const [anchorEl, setAnchorEl] = useState({
    //   id: '',
    //   target: ''
    // });
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = rows
          .map((row) => {
            if (row?.fence_location?.coordinates?.length > 0) {
              return row;
            }
            return null;
          })
          .filter((m) => m !== null);
        setSelected(newSelecteds);
        // Alert for no location
        return;
      }
      setSelected([]);
    };

    const handleClick = (event, row) => {
      // console.log('Selected Site:', row);
      const newSelected = selected;
      const removeSelected = selected.find((f) => f._id === row._id);
      if (removeSelected) {
        const selectedIndex = selected.indexOf(removeSelected);
        if (selectedIndex > -1) {
          selected.splice(selectedIndex, 1);
        }
      } else {
        newSelected.push(row);
      }
      setSelected([...newSelected]);
      // console.log(selected);
      // console.log(mapRef.current);
      if (selected.length > 0 && mapRef.current) {
        // console.log(selected);
        const bounds = new window.google.maps.LatLngBounds();
        selected.forEach((site) => {
          bounds.extend({
            lat: site.fence_location.coordinates[1],
            lng: site.fence_location.coordinates[0]
          });
        });
        mapRef.current.fitBounds(bounds);
      }
    };

    // const handleFilterByName = (event) => {
    //   setFilterName(event.target.value);
    // };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    return (
      <Card
        sx={{
          position: 'absolute',
          zIndex: 9,
          top: 0,
          mt: 8,
          ml: 1.2,
          borderRadius: 1,
          height: 320,
          width: 300,
          backgroundColor: 'white'
        }}
        hidden={hide}
      >
        <Tooltip title="Hide Table">
          <ArrowBackIcon
            sx={{
              cursor: 'pointer',
              zIndex: 99,
              position: 'absolute',
              top: 0,
              right: 0,
              mr: 3,
              mt: 2.2
            }}
            onClick={() => setHide(true)}
          />
        </Tooltip>
        <TableContainer
          className="custom_scrollbar"
          id="sites-table-container"
          sx={{ minWidth: 200, height: 320, position: 'relative' }}
        >
          <Table stickyHeader>
            <SiteMapListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan="9">
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
              {isError && (
                <TableRow>
                  <TableCell>Something Went Wrong</TableCell>
                </TableRow>
              )}
              {!isLoading &&
                filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const isItemSelected = selected.indexOf(row) !== -1;

                    return (
                      <TableRow
                        hover
                        key={row._id}
                        sx={{ py: 2, cursor: 'pointer' }}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onChange={(event) => handleClick(event, row)}
                            checked={isItemSelected}
                            disabled={!row.fence_location}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar
                              onClick={() => setImageSrc(row?.image)}
                              alt={row.name}
                              src={row?.image}
                              sx={{ cursor: 'pointer' }}
                            />
                            <Tooltip
                              sx={{ fontSize: '1rem' }}
                              title={`View ${row.name}`}
                              placement="top"
                            >
                              <Typography
                                onClick={() => {
                                  navigate(
                                    `/roster/app/:${row._id}/:${convertUTCtoLocal(
                                      getLastDayOfMonth(new Date()),
                                      true
                                    )}`
                                  );
                                }}
                                variant="subtitle2"
                                sx={{ cursor: 'pointer' }}
                                noWrap
                              >
                                {row.name}
                              </Typography>
                            </Tooltip>
                            <Stack
                              direction="row"
                              sx={{ cursor: 'pointer', position: 'absolute', right: 8 }}
                            >
                              {row?.status === 'Verified' ? (
                                <Tooltip title="Verified">
                                  <LocationOnIcon sx={{ color: 'green' }} />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Not Verified">
                                  <NotListedLocationIcon sx={{ color: 'red' }} />
                                </Tooltip>
                              )}
                              &nbsp;
                              <Tooltip title="Show Employees">
                                <GroupIcon
                                  sx={{
                                    cursor: 'pointer',
                                    color: `${
                                      siteClicked && siteClicked._id === row._id ? '#D35400' : null
                                    }`
                                  }}
                                  onClick={() => setSiteClicked(row)}
                                />
                              </Tooltip>
                            </Stack>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          {/* Put the scroll bar always on the bottom */}
          <InfiniteScroll
            dataLength={rows?.length}
            next={fetchNextSites}
            // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
            // inverse //
            hasMore={hasNextSites}
            loader={<h4>Loading...</h4>}
            scrollableTarget="sites-table-container"
            // endMessage={
            //   <p style={{ textAlign: 'center' }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
          />
        </TableContainer>
      </Card>
    );
  };

  // refresh button rotation animation
  // const [
  //   rotateIcon
  //   // setRotateIcon
  // ] = useState(false);
  // const refreshBtnRotationProp = useSpring({
  //   to: { transform: rotateIcon ? 'rotate(0deg)' : 'rotate(360deg)' },
  //   from: { transform: 'rotate(360deg)' }
  // });

  // Iterate myPlaces to size, center, and zoom map to contain all markers

  // const loadHandler = (map) => {
  //   // Store a reference to the google map instance in state
  //   setMapRef(map);
  // };

  // We have to create a mapping of our places to actual Marker objects
  // const markerLoadHandler = (marker, site) => {
  //   console.log(site);
  //   setMarkerMap({ ...markerMap, [site._id]: marker });
  //   console.log('Marker Map:', markerMap);
  // };

  // const markerClickHandler = (event, site) => {
  //   // Remember which place was clicked
  //   if (zoom < 35) {
  //     setZoom(35);
  //   }
  //   // if you want to center the selected Marker
  //   // setCenter({
  //   //   lat: site.fence_location.coordinates[1],
  //   //   lng: site.fence_location.coordinates[0]
  //   // });
  //   setAnchorMapEl({
  //     id: `map_info_${site._id}`,
  //     target: event.currentTarget
  //   });
  // };

  const onMapLoad = React.useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds();
      // eslint-disable-next-line no-restricted-syntax
      for (const site of selected) {
        const pos = new window.google.maps.LatLng(
          site?.fence_location?.coordinates[1],
          site?.fence_location?.coordinates[0]
        );
        bounds.extend(pos);
      }
      map.fitBounds(bounds);
      mapRef.current = map;
    },
    [selected]
  );

  return (
    <Page sx={{ pb: 0 }} title="Sites Map | Trackkar">
      <Box sx={{ position: 'relative' }}>
        <SITESTABLE />
        {siteClicked && <EMPLOYEETABLE />}
        <SHOWSITES />
        {siteClicked && <SHOWEMPLOYEES />}
        <SITESHELPER />
        {isLoaded && selected.length > 0 ? (
          <GoogleMap
            // Do stuff on map initial laod
            ref={mapRef}
            onLoad={onMapLoad}
            mapContainerStyle={mapContainerStyle}
            // // Save the current center position in state
            // onCenterChanged={() => setCenter(mapRef.getCenter().toJSON())}
            // Save the user's map click position
            // onClick={(e) => setClickedLatLng(e.latLng.toJSON())}
            center={center}
            zoom={zoom}
            options={options}
          >
            {/* // ...Your map components */}
            {selectedEmployee.length > 0
              ? selectedEmployee.map((emp) => {
                  console.log();
                  return emp?.location?.length > 0 ? (
                    <OverlayView
                      key={emp._id}
                      position={{
                        lat: emp?.location[0]?.coordinates[1],
                        lng: emp?.location[0]?.coordinates[0]
                      }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      getPixelPositionOffset={getPixelPositionOffset}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#0094FF',
                          height: 50,
                          width: 50,
                          ml: 3,
                          mt: 3,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '50% 50% 50% 0',
                          transform: 'rotate(-45deg)',
                          position: 'relative',
                          boxShadow: '-8px 8px 25px rgba(0, 0, 0, 0.4)',
                          zIndex: 10
                        }}
                      >
                        <HtmlTooltip
                          placement="top"
                          arrow
                          key={`map_emp_${emp._id}`}
                          title={
                            <>
                              <Typography
                                align="center"
                                sx={{ fontSize: 14, pt: 2, fontWeight: 'bold' }}
                              >
                                {emp?.name}
                              </Typography>
                              <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
                                <Chip
                                  sx={{ fontSize: 13 }}
                                  label={`Role : ${emp?.role?.name} ( ${emp?.subrole?.name} )`}
                                />
                              </Stack>
                              <Divider sx={{ my: 1 }} />
                              <Typography
                                align="center"
                                sx={{ fontWeight: 'bold', fontSize: 13, mb: 1 }}
                              >
                                Today
                              </Typography>
                              <Stack direction="row" justifyContent="space-between">
                                {/* <Chip
                                  sx={{ fontSize: 13, borderRadius: 0 }}
                                  label={`Required : ${site.requirement}`}
                                />
                                <Chip
                                  sx={{ fontSize: 13, borderRadius: 0 }}
                                  label={`Employees : ${site.employees}`}
                                /> */}
                                <Chip
                                  sx={{ fontSize: 13, borderRadius: 0 }}
                                  label={`IN : ${
                                    emp?.attendance?.in?.time
                                      ? _12hourTimeFormat(emp.attendance?.in?.time)
                                      : 'Not Marked'
                                  }`}
                                />
                                <Chip
                                  sx={{ fontSize: 13, borderRadius: 0 }}
                                  label={`OUT : ${
                                    emp?.attendance?.out?.time
                                      ? _12hourTimeFormat(emp?.attendance?.out?.time)
                                      : 'Not Marked'
                                  }`}
                                />
                              </Stack>
                              <Stack spacing={1} sx={{ my: 1 }}>
                                <Chip
                                  sx={{ fontSize: 13, borderRadius: 0 }}
                                  label={`Mobile : ${emp.contact}`}
                                />
                                <Chip
                                  sx={{ fontSize: 13, borderRadius: 0 }}
                                  label={`Email : ${emp.email ?? 'Not Provided!'}`}
                                />
                              </Stack>
                            </>
                          }
                        >
                          <Avatar
                            sx={{
                              border: '1px solid grey',
                              transform: 'rotate(45deg)',
                              cursor: 'pointer'
                            }}
                            alt={emp.name}
                            src={emp.avatar}
                          />
                        </HtmlTooltip>
                      </Box>
                    </OverlayView>
                  ) : null;
                })
              : null}
            {selected.length > 0
              ? selected.map((site) => (
                  <Fragment key={site._id}>
                    {/* {console.log(site)} */}
                    <OverlayView
                      position={{
                        lat: site?.fence_location?.coordinates[1],
                        lng: site?.fence_location?.coordinates[0]
                      }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      getPixelPositionOffset={getPixelPositionOffset}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#d4af37',
                          height: 50,
                          width: 50,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '50% 50% 50% 0',
                          transform: 'rotate(-45deg)',
                          position: 'relative',
                          boxShadow: '-8px 8px 25px rgba(0, 0, 0, 0.4)',
                          zIndex: 10
                        }}
                      >
                        <HtmlTooltip
                          placement="top"
                          arrow
                          key={`map_site_${site._id}`}
                          title={
                            <>
                              <Typography
                                align="center"
                                sx={{ fontSize: 14, pt: 2, fontWeight: 'bold' }}
                              >
                                {site.name}
                              </Typography>
                              <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
                                <Chip
                                  sx={{ fontSize: 13 }}
                                  label={`Manager : ${site?.manager?.name}`}
                                />
                              </Stack>
                              <Divider sx={{ my: 1 }} />
                              <Stack direction="row" justifyContent="space-between">
                                <Chip
                                  sx={{ fontSize: 13, borderRadius: 0 }}
                                  label={`Required : ${site?.total_requirement?.reduce(
                                    (acc, srs) => acc + srs.total,
                                    0
                                  )}`}
                                />
                                <Chip
                                  sx={{ fontSize: 13, borderRadius: 0 }}
                                  label={`Employees : ${site?.total_employees}`}
                                />
                                <Chip
                                  sx={{ fontSize: 13, borderRadius: 0 }}
                                  label={`Shifts : ${site?.shifts.length}`}
                                />
                                <Chip
                                  sx={{ fontSize: 13, borderRadius: 0 }}
                                  label={`Status : ${site?.active}`}
                                />
                              </Stack>
                              <Typography align="center" sx={{ fontSize: 13, p: 2 }}>
                                Address
                                <br />
                                {site?.address}
                                {/* <PhoneAndroidIcon sx={{ fontSize: 12 }} />
                                &nbsp;&nbsp;{site.mobile}&emsp;
                                <EmailIcon sx={{ fontSize: 12 }} />
                                &nbsp;&nbsp;{site.email} */}
                              </Typography>
                              <Stack direction="row" justifyContent="center" sx={{ mb: 1 }}>
                                <Chip
                                  sx={{ fontSize: 13, borderRadius: 0 }}
                                  label={`Mobile : ${site.mobile ?? 'Not Provided'}`}
                                />
                                {site.email ? (
                                  <Chip
                                    sx={{ fontSize: 13, borderRadius: 0 }}
                                    label={`Email : ${site.email ?? 'Not Provided'}`}
                                  />
                                ) : null}
                              </Stack>
                            </>
                          }
                        >
                          <Avatar
                            sx={{
                              border: '1px solid grey',
                              transform: 'rotate(45deg)',
                              cursor: 'pointer'
                            }}
                            alt={site.name}
                            src={site.image}
                          />
                        </HtmlTooltip>
                      </Box>
                    </OverlayView>
                    <Circle
                      // required
                      center={{
                        lat: +site?.fence_location?.coordinates[1],
                        lng: +site?.fence_location?.coordinates[0]
                      }}
                      // required
                      options={{
                        strokeColor: '#0094FF',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: 'rgba(0, 148, 255, 0.5)',
                        fillOpacity: 0.35,
                        clickable: false,
                        draggable: false,
                        editable: false,
                        visible: true,
                        radius: site?.fence_radius,
                        zIndex: 1
                      }}
                    />
                  </Fragment>
                ))
              : null}
            )
          </GoogleMap>
        ) : (
          <Stack
            style={mapContainerStyle}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundImage: 'url(/static/illustrations/illustration_map.png)',
              backgroundSize: '35%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              position: 'relative',
              // border: '1px solid grey',
              borderRadius: 1
            }}
          >
            <Typography sx={{ position: 'absolute', bottom: 0, mb: 8 }}>
              Choose a Site to Show on the Map
            </Typography>
            {/* <CircularProgress /> */}
          </Stack>
        )}
      </Box>
      <ImageZoom src={imageSrc} onClose={() => setImageSrc(null)} />
    </Page>
  );
}
