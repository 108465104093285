/* eslint-disable no-nested-ternary */
// import { filter } from 'lodash';
import React, { useState, useMemo } from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
// icons
// import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { useDebounce } from 'use-debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  SiteFeedbackListHead,
  // SiteFeedbackListToolbar,
  SiteFeedbackMoreMenu
} from '../components/_dashboard/site_feedback';
import {
  PayrollTemplateMoreMenu,
  SalaryTemplateListToolbar
} from '../components/_dashboard/payroll';
// components
import Page from '../components/Page';
import NewTemplate from '../components/_dashboard/payroll/NewTemplate';
// import Label from '../components/Label';
import NotAvailable from '../components/NotAvailable';
// hooks
import usePayrollTemplates from '../hooks/get-payroll-templates';
// utils
// redux
import { SEARCH_DEBOUNCE, SWAL_TIME } from '../utils/config';

// ---------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Template', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'subrole', label: 'Sub Role', alignRight: false },
  { id: 'basic_percentage', label: 'Basic', alignRight: false },
  { id: 'hra_percentage', label: 'HRA', alignRight: false },
  { id: 'as_default', label: 'Is Default', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// --------------------------------------------------------------

function SalaryTemplate() {
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [search] = useDebounce(filterName, SEARCH_DEBOUNCE); // Debounce value 1.2 sec
  const [openNewTemplate, setOpenNewTemplate] = useState(false);
  const [editTemplate, setEditTemplate] = useState(null);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [anchorEl, setAnchorEl] = useState({
  //   id: '',
  //   target: ''
  // });
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    site: '',
    employee: ''
  });

  const {
    isLoading: isSalaryTemplateLoading,
    isError: isSalaryTemplateError,
    hasNextPage: hasNextTemplates,
    fetchNextPage: fetchNextTemplates,
    data: salaryTemplate
  } = usePayrollTemplates(null, search);

  const rows = useMemo(() => {
    const listOfSalaryTemplate = [];
    if (!isSalaryTemplateLoading && !isSalaryTemplateError) {
      if (salaryTemplate?.pages?.length > 0) {
        salaryTemplate.pages.forEach((group) => {
          listOfSalaryTemplate.push(group.templates);
        });
      }
    }
    const flattened = listOfSalaryTemplate.flat();
    return flattened;
  }, [salaryTemplate, isSalaryTemplateLoading, isSalaryTemplateError]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = rows;

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Salary Template | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <SalaryTemplateListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
          />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenNewTemplate(true)}
          >
            New Template
          </Button>
        </Stack>
        <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 11rem)' }}>
          <Table size="small" stickyHeader>
            <SiteFeedbackListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {isSalaryTemplateLoading ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              ) : isSalaryTemplateError ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <NotAvailable type="na" msg="Something Went Wrong!" />
                  </TableCell>
                </TableRow>
              ) : (
                filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = selected.indexOf(row.name) !== -1;

                    return (
                      <>
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          sx={{
                            '& td': {
                              backgroundColor: '#1F5A91',
                              color: 'white'
                            },
                            color: '#1F5A91'
                          }}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                          </TableCell> */}
                          <TableCell>{(index += 1)}</TableCell>
                          <TableCell scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.role.name}</TableCell>
                          <TableCell align="left">{row?.subrole?.name ?? '-'}</TableCell>
                          <TableCell align="left">{row.basic_salary_percentage} % of CTC</TableCell>
                          <TableCell align="left">{row.hra_percentage} % of Basic Salary</TableCell>
                          <TableCell>
                            {row.default ? (
                              <Chip label="Yes" color="primary" />
                            ) : (
                              <Chip label="No" color="error" />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <PayrollTemplateMoreMenu
                              template={row}
                              setEditTemplate={setEditTemplate}
                            />
                          </TableCell>
                        </TableRow>
                        {row?.components?.length > 0 && (
                          <TableRow>
                            <TableCell />
                            <TableCell component="th">Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Payment Type</TableCell>
                            <TableCell>Annual Limit</TableCell>
                            <TableCell>Taxable</TableCell>
                            <TableCell>LOP</TableCell>
                            <TableCell />
                          </TableRow>
                        )}
                        {row?.components.map((cmp) => (
                          <TableRow key={`${cmp._id}`}>
                            <TableCell />
                            <TableCell>{cmp?.name}</TableCell>
                            <TableCell>{cmp?.type}</TableCell>
                            <TableCell>{cmp?.ctype}</TableCell>
                            <TableCell>{cmp?.flat}</TableCell>
                            <TableCell>{cmp?.taxable ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{cmp?.lop ? 'Yes' : 'No'}</TableCell>
                            <TableCell />
                          </TableRow>
                        ))}
                      </>
                    );
                  })
              )}
            </TableBody>
          </Table>
          {/* Put the scroll bar always on the bottom */}
          <InfiniteScroll
            dataLength={rows?.length}
            next={fetchNextTemplates}
            // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
            // inverse //
            hasMore={hasNextTemplates}
            loader={
              <Stack direction="row" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Stack>
            }
            scrollableTarget="employees-table-container"
            // endMessage={
            //   <p style={{ textAlign: 'center' }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
          />
        </TableContainer>
      </Container>
      <NewTemplate
        edit={editTemplate}
        open={editTemplate ?? openNewTemplate}
        handleClose={() => (editTemplate ? setEditTemplate(null) : setOpenNewTemplate(false))}
      />
    </Page>
  );
}

export default SalaryTemplate;
