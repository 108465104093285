import { useState, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  Chip,
  Popover,
  FormControl,
  MenuItem,
  Divider,
  InputLabel,
  Select,
  Button,
  Avatar
} from '@mui/material';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PieChartIcon from '@mui/icons-material/PieChart';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
// import CloseIcon from '@mui/icons-material/Close';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// config
// import { getRandomColor } from '../../../utils/functions';
// import { COMPANY_PLACEHOLDER_LOGO } from '../../../utils/config';
// hooks
import { useRoles } from '../../../hooks/roles';
import { useSubRoles } from '../../../hooks/subRoles';
// import useSites from '../../../hooks/get-sites-query';
import { useManagers } from '../../../hooks/get-managers';
import { useEmployeesCards } from '../../../hooks/employees';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 75,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

ImportEmployeeListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  setFilters: PropTypes.func,
  filters: PropTypes.object,
  assignRole: PropTypes.object,
  assignManager: PropTypes.object,
  assignSite: PropTypes.func,
  // importEmployees: PropTypes.func,
  isSelected: PropTypes.array,
  selectedRole: PropTypes.string,
  siteOptions: PropTypes.array,
  selectedSite: PropTypes.string,
  siteManager: PropTypes.any,
  siteDict: PropTypes.any,
  smPriority: PropTypes.number
};

export default function ImportEmployeeListToolbar({
  numSelected,
  filterName,
  onFilterName,
  assignRole,
  assignManager,
  assignSite,
  setFilters,
  filters,
  // importEmployees,
  selectedRole,
  isSelected,
  siteOptions,
  selectedSite,
  siteManager,
  siteDict,
  smPriority
}) {
  const cache = useQueryClient();
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [roleOptions, setRoleOptions] = useState(); // Roles initial State for select
  const [subRoleOptions, setSubRoleOptions] = useState(); // Sub Roles initial State for select
  const [managerOptions, setManagerOptions] = useState();
  // const [subrolesCardsData, setSubrolesCardsData] = useState([]);
  const [assign, setAssign] = useState({
    role: selectedRole,
    manager: null
  });
  const [assignSites, setAssignSites] = useState([]);
  const [filter, setFilter] = useState({
    role: '',
    subRole: ''
  });

  // useEffect(() => {
  //   console.log('Filters:', filter);
  // }, [filter]);

  /* ================= Employees Cards ==================== */

  const {
    // isLoading: isCardsLoading,
    // isError: isCardsError,
    data: employeesCards
  } = useEmployeesCards('unassigned'); // getting employees cards

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      allRoles.forEach((role) => formattedRoles.push([`${role._id},${role.name}`, role.name]));
      // console.log('Role Options :', formattedRoles);
    }
    setRoleOptions([...formattedRoles]);
  }, [allRoles]);
  /* ================= End Roles =============== */

  /* ================== Sub Roles ======================== */

  const {
    isLoading: isSubRolesLoading,
    isError: isSubRolesError,
    data: subRoles
  } = useSubRoles(filter.role.split(',')[0]);

  useMemo(() => {
    const listOfSubRoles = [];
    if (!isSubRolesLoading && !isSubRolesError) {
      if (subRoles?.roles?.length > 0) {
        subRoles.roles[0].subroles.forEach((role) =>
          listOfSubRoles.push([`${role._id},${role.name}`, role.name])
        );
      }
    }
    // console.log('Sub Roles By Role:', listOfSubRoles);
    setSubRoleOptions([...listOfSubRoles]);
  }, [subRoles, isSubRolesLoading, isSubRolesError]);

  /* ================== End Sub Roles ====================== */

  /* ===================== Managers ===================== */
  const {
    isLoading: isManagersLoading,
    isError: isManagersError,
    data: managers
  } = useManagers(selectedRole); // gettings managers assiggn?.role

  const Managers = useMemo(() => {
    // console.log('Managers :', managers);
    const listOfManagers = [];
    if (!isManagersLoading && !isManagersError) {
      if (managers?.pages?.length > 0) {
        managers.pages.forEach((group) => listOfManagers.push(group.managers));
      }
    }
    const flattened = listOfManagers.flat();
    return flattened;
  }, [managers, isManagersLoading, isManagersError]);

  useEffect(() => {
    const formattedManagers = [];
    if (Managers?.length > 0) {
      Managers.forEach((emp) =>
        formattedManagers.push([
          `${emp._id}, ${emp.name}`,
          emp?.avater,
          emp?.role?.name,
          emp?.subrole?.name
        ])
      );
    }
    // console.log('Managers Options', formattedManagers);
    setManagerOptions([...formattedManagers]);
  }, [Managers]);

  /* ================= End Managers =============== */

  // const defaultLabelStyle = {
  //   fontSize: '6px',
  //   fontFamily: 'sans-serif'
  // };

  const options = {
    title: 'Roles',
    legend: { position: 'bottom' },
    is3D: true,
    pieSliceText: 'label',
    slices: {
      4: { offset: 0.2 },
      12: { offset: 0.3 },
      14: { offset: 0.4 },
      15: { offset: 0.5 }
    }
  };

  const soptions = {
    title: 'Subroles',
    legend: { position: 'bottom' },
    is3D: true,
    pieSliceText: 'label',
    slices: {
      4: { offset: 0.2 },
      12: { offset: 0.3 },
      14: { offset: 0.4 },
      15: { offset: 0.5 }
    }
  };

  const cardsData = useMemo(() => {
    const data = [['Roles', 'Count']];
    const sdata = [['Subroles', 'Count']];
    if (allRoles.length > 0 && employeesCards?.employees.length > 0) {
      allRoles.forEach((role) => {
        const r = employeesCards.employees.find((rl) => rl.role === role._id) ?? {};
        // console.log(r);
        data.push([role.name, r?.total ?? 0]);
        role?.subroles?.forEach((sr) => {
          // console.log('SR:', sr);
          sdata.push([
            `${sr?.name} ( ${role.name} )`,
            r?.subroles?.find((svr) => sr?._id === svr?.subrole)?.total ?? 0
          ]);
        });
      });
    }
    // console.log(sdata);
    return { data, sdata };
  }, [allRoles, employeesCards]);

  return (
    <>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main'
            // bgcolor: 'primary.lighter'
          })
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Search Employee..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
        )}

        {numSelected > 0 ? (
          <Stack direction="row">
            <Stack direction="row" alignContent="center">
              {isSelected.length > 0 && (
                <Tooltip title="Update Role">
                  <IconButton
                    onClick={(event) =>
                      setAnchorEl({
                        ...anchorEl,
                        id: 'change_role_of_employees',
                        target: event.currentTarget
                      })
                    }
                  >
                    <AddModeratorIcon />
                  </IconButton>
                </Tooltip>
              )}
              {isSelected.length > 0 &&
                allRoles.find((rl) => rl._id === selectedRole).priority <= smPriority && (
                  <Tooltip title="Assign Manager">
                    <IconButton
                      onClick={(event) =>
                        setAnchorEl({
                          ...anchorEl,
                          id: 'assign_role_manager_to_employees',
                          target: event.currentTarget
                        })
                      }
                    >
                      <AssignmentIndIcon />
                    </IconButton>
                  </Tooltip>
                )}
              {isSelected.length > 0 &&
                allRoles.find((rl) => rl._id === selectedRole).priority > smPriority && (
                  <Tooltip title="Assign Site">
                    <IconButton
                      onClick={(event) =>
                        setAnchorEl({
                          ...anchorEl,
                          id: 'assign_site_to_employees',
                          target: event.currentTarget
                        })
                      }
                    >
                      <StoreMallDirectoryIcon />
                    </IconButton>
                  </Tooltip>
                )}
            </Stack>
            &emsp;
            <Tooltip title="Delete">
              <IconButton>
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : (
          <Stack direction="row">
            {/* <Tooltip title="Import Employees">
            <IconButton onClick={importEmployees}>
              <Icon icon={roundFilterList} />
            </IconButton>
          </Tooltip> */}
            {/* &emsp; */}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(e) =>
                  setAnchorEl({ ...anchorEl, id: 'filter_unemployees', target: e.currentTarget })
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            &nbsp;
            <Tooltip title="Employees">
              <IconButton
                onClick={(event) =>
                  setAnchorEl({ ...anchorEl, id: 'employees_cards', target: event.target })
                }
              >
                <PieChartIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        <Popover
          id="change_role_of_employees"
          open={anchorEl.id === 'change_role_of_employees'}
          anchorEl={anchorEl.target}
          onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Stack spacing={1} width="320px" sx={{ p: 2 }}>
            <Stack direction="row" alignItems="center">
              <AddModeratorIcon />
              <Typography sx={{ ml: 1 }}>Update Role</Typography>
            </Stack>
            <Divider />
            {/* Role */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="role_filter">
                  Choose Role
                </InputLabel>
                <Select
                  variant="standard"
                  value={assign?.role ?? ''}
                  inputProps={{
                    name: 'role_filter',
                    id: 'role_filter'
                  }}
                  onChange={(e) => setAssign({ ...assign, role: e.target.value })}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Role
                  </MenuItem>
                  {roleOptions?.length > 0 ? (
                    roleOptions.map(([key, value, image]) => (
                      <MenuItem key={`${'role_filter'}-${key}`} value={key} data-icon={image}>
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <>
                      <MenuItem value="" disabled />
                    </>
                  )}
                </Select>
              </FormControl>
            </Box>
            <br />
            <Box>
              <Button
                sx={{ float: 'right' }}
                variant="contained"
                id="employee_apply_filter_button"
                size="medium"
                onClick={() => {
                  setAnchorEl({ ...anchorEl, id: null, target: null });
                  Swal.fire({
                    title: 'Are you sure?',
                    text: 'This will apply changes to all selected Employee!',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Proceed!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      assignRole.mutate({
                        employees: isSelected,
                        role: assign?.role.split(',')[0]
                      });
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      cache.invalidateQueries('employees');
                    }
                  });
                }}
              >
                Update
              </Button>
            </Box>
          </Stack>
        </Popover>
        <Popover
          id="assign_role_manager_to_employees"
          open={anchorEl.id === 'assign_role_manager_to_employees'}
          anchorEl={anchorEl.target}
          onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Stack spacing={1} width="320px" sx={{ p: 2 }}>
            <Stack direction="row" alignItems="center">
              <AddModeratorIcon />
              <Typography sx={{ ml: 1 }}>Assign Manager</Typography>
            </Stack>
            <Divider />
            {/* Manager */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                '& #mui-component-select-assign_manager': { display: 'flex', pl: 1 }
              }}
            >
              <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="assign_manager">
                  Choose Manager
                </InputLabel>
                <Select
                  variant="standard"
                  value={assign?.manager ?? ''}
                  inputProps={{
                    name: 'assign_manager',
                    id: 'assign_manager'
                  }}
                  onChange={(e) => setAssign({ ...assign, manager: e.target.value })}
                  // disabled={!assign?.role}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Manager
                  </MenuItem>
                  {managerOptions?.length > 0 ? (
                    managerOptions.map(([key, image, empR, empSR]) => (
                      <MenuItem
                        style={{ padding: '0.5rem' }}
                        key={`${'manager_filter'}-${key}`}
                        value={key}
                        data-icon={image}
                      >
                        <Avatar src={image} />
                        <Stack sx={{ ml: 1 }}>
                          <Typography>&nbsp;{key.split(',')[1]}</Typography>
                          <Box display="flex">
                            <Chip
                              fontSize={12}
                              sx={{ height: 18, mb: 0.5 }}
                              size="small"
                              label={empR}
                            />
                            &nbsp;
                            {empSR && (
                              <Chip fontSize={12} sx={{ height: 18 }} size="small" label={empSR} />
                            )}
                          </Box>
                        </Stack>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled />
                  )}
                </Select>
              </FormControl>
            </Box>
            <br />
            <Box>
              <Button
                sx={{ float: 'right' }}
                variant="contained"
                id="employee_apply_filter_button"
                size="medium"
                onClick={() => {
                  setAnchorEl({ ...anchorEl, id: null, target: null });
                  Swal.fire({
                    title: 'Are you sure?',
                    text: 'This will apply changes to all selected Employee!',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Proceed!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      assignManager.mutate({
                        employees: isSelected,
                        manager: assign?.manager.split(',')[0]
                      });
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      cache.invalidateQueries('employees');
                    }
                  });
                }}
              >
                Assign
              </Button>
            </Box>
          </Stack>
        </Popover>
        <Popover
          id="filter_unemployees"
          open={anchorEl.id === 'filter_unemployees'}
          anchorEl={anchorEl.target}
          onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Stack spacing={1} width="320px" sx={{ p: 2 }}>
            <Stack direction="row" alignItems="center">
              <FilterListIcon />
              <Typography sx={{ ml: 1 }}>Filters</Typography>
            </Stack>
            <Divider />
            {/* Gender
            <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
              <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="gender_filter">
                  Choose Gender
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter.gender}
                  inputProps={{
                    name: 'gender',
                    id: 'gender_filter'
                  }}
                  onChange={(e) => {
                    setFilter({ ...filter, gender: e.target.value });
                  }}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Gender
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              &nbsp;
              {filter.gender ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, gender: '' });
                      setFilters(filter);
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box> */}
            {/* State
            <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
              <LocationCityIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="grouped-select">
                  Choose State
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter.state}
                  onChange={(e) => setFilter({ ...filter, state: e.target.value })}
                  id="grouped-select"
                  label="Choose State"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <ListSubheader>States</ListSubheader>
                  {INDIAN?.states?.length > 0 ? (
                    INDIAN?.states.map((key) => (
                      <MenuItem key={`${'state'}-${key}`} value={key}>
                        {key}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                  <ListSubheader>Union Territories</ListSubheader>
                  {INDIAN.union_territories?.length > 0 ? (
                    INDIAN.union_territories.map((key) => (
                      <MenuItem key={`${'territory'}-${key}`} value={key}>
                        {key}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              &nbsp;
              {filter.state ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, state: '' });
                      setFilters(filter);
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box> */}
            {/* Role */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="role_filter">
                  Choose Role
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter?.role}
                  inputProps={{
                    name: 'role_filter',
                    id: 'role_filter'
                  }}
                  onChange={(e) => setFilter({ ...filter, role: e.target.value, subRole: '' })}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Role
                  </MenuItem>
                  {roleOptions?.length > 0 ? (
                    roleOptions.map(([key, value, image]) => (
                      <MenuItem key={`${'role_filter'}-${key}`} value={key} data-icon={image}>
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <>
                      <MenuItem value="" disabled />
                    </>
                  )}
                </Select>
              </FormControl>
            </Box>
            {/* Sub Role */}
            {filter.role && subRoleOptions?.length > 0 ? (
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="role_type">
                    Choose Sub Role
                  </InputLabel>
                  <Select
                    variant="standard"
                    disabled={!(filter.role && subRoleOptions.length > 0)}
                    value={filter.subRole}
                    inputProps={{
                      name: 'sub_role_type',
                      id: 'sub_role_type'
                    }}
                    required
                    sx={{ mt: 2 }}
                    onChange={(e) => setFilter({ ...filter, subRole: e.target.value })}
                    // error={Boolean(touched.sub_role_type && errors.sub_role_type)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Choose Sub Role
                    </MenuItem>
                    {subRoleOptions?.length > 0 ? (
                      subRoleOptions.map(([key, value, image]) => (
                        <MenuItem key={`${'sub_role_type'}-${key}`} value={key} data-icon={image}>
                          {value}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled />
                    )}
                  </Select>
                </FormControl>
              </Box>
            ) : null}
            {/* Manager
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="manager_filter">
                  Choose Manager
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter.manager}
                  inputProps={{
                    name: 'manager_filter',
                    id: 'manager_filter'
                  }}
                  onChange={(e) => setFilter({ ...filter, manager: e.target.value })}
                  disabled={!filter?.role}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Manager
                  </MenuItem>
                  {managerOptions?.length > 0 ? (
                    managerOptions.map(([key, value, image]) => (
                      <MenuItem
                        style={{ padding: '0.5rem' }}
                        key={`${'manager_filter'}-${key}`}
                        value={key}
                        data-icon={image}
                      >
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled />
                  )}
                </Select>
              </FormControl>
              &nbsp;
              {filter.manager ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, manager: '' });
                      setFilters(filter);
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box> */}
            {/* Policy
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <StickyNote2Icon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="policy_filter">
                  Choose Policy
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter.policy}
                  inputProps={{
                    name: 'policy_filter',
                    id: 'policy_filter'
                  }}
                  onChange={(e) => setFilter({ ...filter, policy: e.target.value })}
                  disabled={!filter?.role}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Policy
                  </MenuItem>
                  {policyOptions?.length > 0 ? (
                    policyOptions.map(([key, value, image]) => (
                      <MenuItem
                        style={{ padding: '0.5rem' }}
                        key={`${'policy_type'}-${key}`}
                        value={key}
                        data-icon={image}
                      >
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled />
                  )}
                </Select>
              </FormControl>
              &nbsp;
              {filter.policy ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, policy: '' });
                      setFilters(filter);
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box> */}
            <br />
            <Box>
              <Button
                sx={{ float: 'right' }}
                variant="contained"
                id="employee_apply_filter_button"
                size="medium"
                onClick={() => setFilters(filter)}
              >
                Apply
              </Button>
            </Box>
          </Stack>
        </Popover>
        <Popover
          id="employees_cards"
          open={anchorEl.id === 'employees_cards'}
          anchorEl={anchorEl.target}
          onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          keepMounted
        >
          <Stack sx={{ p: 2 }}>
            <Typography sx={{ p: 2 }} align="center">
              Employees Role Wise Categorization
            </Typography>
            <Stack
              className="hide_scrollbar"
              direction="row"
              alignContent="center"
              sx={{ overflowX: 'scroll' }}
            >
              {cardsData.data.length > 0 && (
                <Stack sx={{ minWidth: 400 }}>
                  <Chart
                    chartType="PieChart"
                    data={cardsData.data}
                    options={options}
                    width="100%"
                    height="350px"
                  />
                </Stack>
              )}
              &emsp;
              {cardsData.sdata.length > 0 && (
                <Stack sx={{ width: 400 }}>
                  <Chart
                    chartType="PieChart"
                    data={cardsData.sdata}
                    options={soptions}
                    width="100%"
                    height="350px"
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </Popover>
        <Popover
          id="assign_site_to_employees"
          open={anchorEl.id === 'assign_site_to_employees'}
          anchorEl={anchorEl.target}
          onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Stack spacing={1} width="320px" sx={{ p: 2 }}>
            <Stack direction="row" alignItems="center">
              <AddModeratorIcon />
              <Typography sx={{ ml: 1 }}>Assign Site</Typography>
            </Stack>
            <Divider />
            {/* Site */}
            <Stack sx={{ minWidth: '8rem' }}>
              <FormControl fullWidth>
                <InputLabel sx={{ ml: 2, mt: 1 }} variant="standard" htmlFor="assign_site">
                  Choose Site
                </InputLabel>
                <Select
                  labelId="assign_site_label_multiple"
                  variant="standard"
                  value={assignSites ?? []}
                  multiple
                  inputProps={{
                    name: 'assign_site_multiple',
                    id: `assign_site_multiple`
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={siteDict[value]} />
                      ))}
                    </Box>
                  )}
                  input={<OutlinedInput id="assign_site_label_multiple" label="Choose Site" />}
                  sx={{ mt: 2 }}
                  onChange={(event) => {
                    const {
                      target: { value }
                    } = event;
                    setAssignSites(
                      // On autofill we get a stringified value.
                      typeof value === 'string' ? value.split(',') : value
                    );
                  }}
                >
                  <MenuItem value="" disabled>
                    Choose Site
                  </MenuItem>
                  {siteOptions?.length > 0 ? (
                    siteOptions
                      .filter((site) => {
                        if (isSelected.length > 0 && siteManager[selectedSite]) {
                          return site[2] === siteManager[selectedSite];
                        }
                        if (
                          isSelected.length > 0 &&
                          assignSite.length > 0 &&
                          siteManager[assignSite[0]]
                        ) {
                          return site[2] === siteManager[assignSite[0]];
                        }
                        return true;
                      })
                      .map(([key, value]) => (
                        <MenuItem
                          style={{ padding: '0.5rem' }}
                          key={`${'assign_site'}-${key}`}
                          value={key}
                          name={value}
                        >
                          {value}
                        </MenuItem>
                      ))
                  ) : (
                    <MenuItem value="" disabled />
                  )}
                </Select>
              </FormControl>
            </Stack>
            <br />
            <Box>
              <Button
                sx={{ float: 'right' }}
                variant="contained"
                id="employee_apply_filter_button"
                size="medium"
                onClick={() => {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: `All selected employee will be added to Selected Sites!`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Proceed!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      assignSite({
                        sites: assignSites, // all Selected Sites
                        employees: isSelected,
                        isMultiple: true
                      });
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      cache.invalidateQueries('unmanaged-employees');
                    }
                  });
                }}
              >
                Assign
              </Button>
            </Box>
          </Stack>
        </Popover>
      </RootStyle>
      <Stack direction="row" alignItems="center" sx={{ my: 1, px: 2 }}>
        <FilterListIcon sx={{ fontSize: 20 }} />
        &nbsp;
        <Typography>Filters</Typography>
        &nbsp;
        <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
        &nbsp;
        {/* <Chip
          sx={{ backgroundColor: '#1ABC9C', color: 'white' }}
          label={`Date Of Joining : ${
            filter.doj.length > 0 ? filter.doj.split('-').reverse().join('-') : 'All'
          }`}
          size="small"
        />
        &nbsp;
        <Chip
          sx={{ backgroundColor: '#8E44AD', color: 'white' }}
          label={`State : ${filter.state.length > 0 ? filter.state : 'All'}`}
          size="small"
        />
        &nbsp;
        <Chip
          sx={{ backgroundColor: '#2980B9', color: 'white' }}
          label={`Policy : ${filter.policy.split(',')[1] ?? 'All'}`}
          size="small"
        />
        &nbsp; */}
        <Chip
          color="secondary"
          label={
            <Stack direction="row" alignItems="center">
              Role : {filters.role.split(',')[1] ?? 'All'}
              &nbsp;&nbsp;
              {filters.role && (
                <CancelIcon
                  onClick={() => {
                    setFilter({ ...filter, role: '' });
                    setFilters({ ...filters, role: '' });
                    cache.invalidateQueries('employees');
                  }}
                  sx={{ fontSize: 14, cursor: 'pointer' }}
                />
              )}
            </Stack>
          }
          size="small"
        />
        &nbsp;
        <Chip
          sx={{ backgroundColor: '#DC7633', color: 'white', fontSize: 11 }}
          label={
            <Stack direction="row" alignItems="center">
              Sub Role : {filters.subRole.split(',')[1] ?? 'All'}
              &nbsp;&nbsp;
              {filters.subRole && (
                <CancelIcon
                  onClick={() => {
                    setFilter({ ...filter, subRole: '' });
                    setFilters({ ...filters, subRole: '' });
                    cache.invalidateQueries('employees');
                  }}
                  sx={{ fontSize: 14, cursor: 'pointer' }}
                />
              )}
            </Stack>
          }
          size="small"
        />
        {/* &nbsp;
        <Chip
          sx={{ backgroundColor: '#D68910', color: 'white' }}
          label={`Manager : ${filter.manager.split(',')[1] ?? 'All'}`}
          size="small"
        />
        &nbsp;
        <Chip
          sx={{ backgroundColor: '#7B7D7D', color: 'white' }}
          label={`Gender : ${filter.gender.length > 0 ? filter.gender : 'All'}`}
          size="small"
        /> */}
        {filters.role ||
        // filters.state ||
        // filters.gender ||
        // filters.manager ||
        // filters.policy ||
        // filters.doj ||
        filters.subRole ? (
          <Button
            variant="text"
            size="small"
            sx={{ ml: 1 }}
            onClick={() => {
              setFilter({
                ...filter,
                // doj: '',
                // state: '',
                // gender: '',
                role: '',
                subRole: ''
                // manager: '',
                // policy: ''
              });
              setFilters({
                ...filters,
                // doj: '',
                // state: '',
                // gender: '',
                role: '',
                subRole: ''
                // manager: '',
                // policy: ''
              });
            }}
          >
            Clear All
          </Button>
        ) : null}
      </Stack>
    </>
  );
}
