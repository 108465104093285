/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import { useState, useEffect, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
// material
// import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import { TextField, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// utils
import {
  excelToJSON,
  capitalizeEachWord,
  setDataInStorage,
  getDataFromStorage,
  arraysEqual
} from '../../../utils/functions';
import classes from '../../../pages/styles/ImportEmployees.module.css';
import { TRACKKAR_BULK_UPLOAD_FILES, AVATAR } from '../../../utils/config';
import { importEmployees } from '../../../api/mutations/adminMutations';
import callSocket from '../../../utils/socket';
import { logOut } from '../../../redux/actions/adminActions';
import { countries } from '../../../utils/countries';

const DEBUG = false;

const EXCEL_HEADER = [
  'Name',
  'Father Name',
  'Mother Name (Optional)',
  'Spouse Name (Optional)',
  'Guardian Name (Optional)',
  'Gender',
  'Marital Status',
  'Blood Group',
  'Role',
  'Sub Role (Optional)',
  'Department',
  'PAN No.',
  'Aadhar No.',
  'Country Code',
  'Mobile',
  'E-mail (Optional)',
  'Date of birth (DD-MM-YYYY)',
  'Date of joining (DD-MM-YYYY)',
  'House No. / Building Name',
  'Village / Town / Street',
  'Country',
  'State',
  'City',
  'Pincode',
  'Vehicle Type',
  'Shift Preferences (Optional)',
  'Bank Name (Optional)',
  'Bank Branch (Optional)',
  'Bank IFSC Code (Optional)',
  'Bank A/C No (Optional)',
  'Payout Type (Optional)',
  'Payout Amount (Optional)',
  'UAN (Optional)',
  'PF No. (Optional)',
  'ESI No. (Optional)',
  'Emergency Country Code',
  'Emergency Contact'
];

const countryPhoneCodes = countries.map((country) => country.phone);

UploadEmployees.propTypes = {
  allRoles: PropTypes.any,
  subRoleOptions: PropTypes.any,
  allAvailableRoles: PropTypes.any,
  allDepartments: PropTypes.any
};

export default function UploadEmployees({
  allRoles,
  subRoleOptions,
  allAvailableRoles,
  allDepartments
}) {
  const [importCheck, setImportCheck] = useState({
    importId: getDataFromStorage('importId') ?? null,
    importLength: getDataFromStorage('importLength') ?? null
  });
  const [importErrors, setImportErrors] = useState(getDataFromStorage('importErrors') ?? []);
  const [isValidating, setIsValidating] = useState(null); // Upon excel validation
  const [, setRole] = useState(''); // role
  const { info } = useSelector((state) => state.adminInfo);
  const dispatch = useDispatch();
  const cache = useQueryClient();

  /* ==================== Import Employees Mutations ================== */
  const { mutate } = useMutation(importEmployees, {
    onMutate: (mutateData) => {
      setIsValidating('Upload');
      setDataInStorage('importId', '');
      setImportCheck({
        ...importCheck,
        importId: mutateData.importId,
        importLength: mutateData.employees.length
      });
      setDataInStorage('importId', mutateData.importId);
      setDataInStorage('importLength', mutateData.employees.length);
    }
  });

  useEffect(() => {
    if (isValidating !== null || importCheck?.importId?.length > 0) {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Onboarding employees :)',
        allowOutsideClick: false,
        showConfirmButton: true, // Changed
        html: `<div style="display:block;">
              <p style="text-align:center;margin-bottom:10px;"><strong id="alert_status_text">Starting...</strong><p>
              <div style="display:flex;justify-content:center;align-items:center;">
                <div class="custom_progress progress--horizontal-soft">
                  <div class="custom_progress__bar"></div>
                </div>
                <div style="margin-left:10px;margin-bottom:4px;" id="import_completed" class="import_percentage"></div>
              </div>
              <div class="import_progress">
                <p>Error</p><div id="import_error">0</div>
                <p>Existing</p><div id="import_existing">0</div>
                <p>Uploaded</p><div id="import_inserted">0</div>
              </div>
              </div>`,
        didOpen: () => {
          const content = Swal.getHtmlContainer();
          const progressBars = content?.querySelectorAll('.custom_progress__bar');
          function setProgress(progress) {
            progressBars.forEach((bar) => {
              Object.assign(bar.style, {
                transform: `scaleX(${progress / 100})`,
                /* standard & vertical */
                'background-position-y': `${progress.toString()}%`,
                /* horizontal */
                'background-position-x': `${progress.toString()}%`
              });
            });
          }
          const er = content?.querySelector('strong');
          if (isValidating === 'Read' && er) {
            er.textContent = 'Reading File... 😃';
          }
          if (isValidating === 'Validate' && er) {
            er.textContent = 'Validating File... 😃';
          }
          if (isValidating === 'Upload' || importCheck?.importId?.length > 0) {
            // client-side
            const socket = callSocket();
            socket.on('disconnect', (reason) => {
              if (!navigator.onLine) {
                er.textContent = `Connection Lost!!, reconnecting...`;
              } else if (
                reason === 'io server disconnect' ||
                reason === 'ping timeout' ||
                reason === 'transport error'
              ) {
                // the disconnection was initiated by the server, you need to reconnect manually
                socket.connect();
              } else if (reason === 'transport close' && navigator.onLine) {
                // er.textContent = `Error Uploading Data!!`;
                setDataInStorage('importId', '');
                setDataInStorage('importLength', '');
              }
            });
            // window.onbeforeunload = () => true;
            socket.on('progress', (status) => {
              if (
                status.type === 'importEmployees' &&
                status.user === info._id &&
                status.importId === importCheck.importId
              ) {
                // const $ = content.querySelector.bind(content);
                // console.log(status);
                const dataLength = importCheck.importLength;
                const sum = status.error + status.existing + status.inserted;
                const progress = (sum * 100) / dataLength;
                setProgress(progress);
                const err = status?.errorObj;
                if (err !== undefined) {
                  importErrors.push(err);
                  setImportErrors([...importErrors]);
                  setDataInStorage('importErrors', importErrors);
                }
                // console.log(importErrors);
                content.querySelector('strong').textContent = `${sum} / ${dataLength}`;
                content.querySelector('#import_error').textContent = `${status.error}`;
                content.querySelector('#import_existing').textContent = `${status.existing}`;
                content.querySelector('#import_inserted').textContent = `${status.inserted}`;
                content.querySelector('#import_completed').textContent = `${parseFloat(
                  progress
                ).toFixed(0)}%`;
                if (progress === 100) {
                  setIsValidating(null);
                  socket.disconnect();
                  setDataInStorage('importId', '');
                  setDataInStorage('importLength', '');
                  if (!(isValidating === 'Uploading')) {
                    if (importErrors?.length > 0) {
                      Swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        text: 'Following errors encounter while uploading!!',
                        confirmButtonText: 'Show details'
                      }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                          const errors = importErrors.filter((ir) => ir !== undefined);
                          Swal.fire({
                            didRender: (data) => {
                              // to remove "," inserted automatically by swal2 after each list item
                              const ul = data.querySelector(`.${classes.import__err_ul}`);
                              const nodes = ul.childNodes;
                              nodes.forEach((node) => {
                                if (node.nodeType === Node.TEXT_NODE) {
                                  node.parentNode.removeChild(node); // removing any text content ohter than list-item
                                }
                              });
                              // removing extra margin from action buttons
                              const actions = document.querySelector('.swal2-actions');
                              actions.style.marginTop = 0;
                            },
                            html: `<h5>Following issues were encountered while adding clients</h5>
                                      <ul class=${classes.import__err_ul}>
                                      ${errors.map(
                                        (resErr, index) =>
                                          `<li class=${classes.import__err_li}>
                                          <span>${(index += 1)}.&nbsp;</span>
                                          <span class=${classes.import__err_msg}>${
                                            resErr.error
                                          }</span>
                                          </li>`
                                      )}
                                      </ul>`
                          });
                        }
                      });
                    } else {
                      Swal.fire({
                        icon: 'success',
                        title: 'Successfull',
                        html: `<span>Successfully Completed!!<div style="color: red; font-weight: bold;margin: 0.5rem 0">IMPORTANT</div><mark>Please update the profile picture of the added employees with their real faces for our facial-recognition based attendace system to work properly. Otherwise, they won't be able to mark the attendance.</mark><br/><br/>
                                  Employees can login to their respective accounts by using mobile numbers as their initial password.\nFeel free to change it whenever you want from employee profile. <br /> <span style="color:red;">${status.existing} Employees Already Exists</span></span>`
                      });
                    }
                  }
                }
              } else {
                socket.disconnect();
                setDataInStorage('importId', '');
                setDataInStorage('importLength', '');
                setTimeout(() => {
                  Swal.close();
                }, 1000);
              }
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidating, importCheck, info]);

  const handleBulkUpload = useCallback(
    async (files, roleOptions, subRoleOptions) => {
      // console.log('All Departments', allDepartments);
      const roleNames = roleOptions?.map((role) => role[1].toLowerCase());
      // const subRoles = subRoleOptions?.map((srole) => srole);
      const Dict = {};
      // eslint-disable-next-line prefer-destructuring
      roleOptions?.forEach((role) => (Dict[role[1].toLowerCase().replace(' ', '-')] = role[0]));
      // console.log(files[0]);
      // console.log('Dict:', Dict);
      const subRoles = {};
      // console.log('Sub Role Options', subRoleOptions);
      subRoleOptions.forEach((sr) => (subRoles[sr.id] = Object.keys(sr.subroles)));
      // console.log('Subroles', subRoles);
      const excelFile = files[0];

      if (excelFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        // console.log("Only Excel files please");
        Swal.fire({
          icon: 'error',
          title: 'Unsupported Format!',
          text: 'Please upload a valid excel document with the proper format provided in the template.'
          // timer: 4000,
          // timerProgressBar: true
        });
      } else {
        // setIsValidating('Read');
        const excel = await excelToJSON(excelFile);
        const wrongHeaders = arraysEqual(excel.headers, EXCEL_HEADER, true);
        // console.log('Excel ROWS:->', excel.headers);
        // console.log('Excel HEADERS:->', EXCEL_HEADER);
        // eslint-disable-next-line eqeqeq
        // console.log(arraysEqual(excel.headers, EXCEL_HEADER));
        // eslint-disable-next-line prettier/prettier
        // console.log(!(arraysEqual(EXCEL_HEADER, excel.headers)));
        // !arraysEqual(excel.headers, EXCEL_HEADER)
        if (wrongHeaders.length > 0) {
          setIsValidating(null);
          Swal.fire({
            icon: 'error',
            title: 'Some of Headers are not Correct!',
            html: `<span style="color:red;">Wrong Headers are ${wrongHeaders.join(', ')}</span>
              <br />Correct Headers are ${EXCEL_HEADER.join(', ')}`
          });
          document.getElementById('import-employees-file').value = null;
        } else {
          const result = excel.rows.map((row) => {
            const temp = {
              // _id: Date.now().toString(),
              name: capitalizeEachWord(row.Name?.trim()),
              father_name: capitalizeEachWord(row['Father Name']?.trim()),
              mother_name: capitalizeEachWord(row['Mother Name (Optional)']?.trim()),
              spouse_name: capitalizeEachWord(row['Spouse Name (Optional)']?.trim()),
              guardian_name: capitalizeEachWord(row['Guardain Name (Optional)']?.trim()),
              gender: row.Gender?.toString().trim(),
              marital_status: row['Marital Status']?.toString().trim(),
              blood_group: row['Blood Group']?.toString().trim(),
              country_code: row['Country Code']?.toString()?.trim(),
              contact: row.Mobile?.toString()?.trim(),
              password: row.Mobile?.toString()?.trim(),
              department: capitalizeEachWord(row.Department)?.trim(),
              pan: {
                value: row['PAN No.']
              },
              aadhar: {
                value: row['Aadhar No.']
              },
              bank_details: {
                bank_account_no: row['Bank A/C No (Optional)'],
                bank_branch: row['Bank Branch (Optional)'],
                bank_name: row['Bank Name (Optional)'],
                bank_ifsc_code: row['Bank IFSC Code (Optional)']
              },
              emergency_contact: {
                country_code: row['Emergency Country Code'],
                contact_no: row['Emergency Contact']
              },
              payout_type: row['Payout Type (Optional)'],
              payout_amount: row['Payout Amount (Optional)'],
              uan: row['UAN (Optional)'],
              pf_no: row['PF No. (Optional)'],
              esi_no: row['ESI No. (Optional)'],
              email: row['E-mail (Optional)']?.toLowerCase()?.trim(),
              doj: row['Date of joining (DD-MM-YYYY)']
                ?.toString()
                .split('-')
                .reverse()
                .join('-')
                ?.trim(),
              dob: row['Date of birth (DD-MM-YYYY)']
                ?.toString()
                .split('-')
                .reverse()
                .join('-')
                ?.trim(),
              permanent_address: {
                permanent_house_no: capitalizeEachWord(row['House No. / Building Name'])?.trim(),
                permanent_village_town_street: capitalizeEachWord(
                  row['Village / Town / Street']
                )?.trim(),
                permanent_country: capitalizeEachWord(row.Country)?.trim(),
                permanent_state: capitalizeEachWord(row.State)?.trim(),
                permanent_city: capitalizeEachWord(row.City)?.trim(),
                permanent_pincode: row.Pincode
              },
              shift_preferences: row['Shift Preferences (Optional)']?.trim().split(','),
              admin: allRoles[0]?.admin,
              avatar: AVATAR,
              _role: row?.Role?.toString()?.toLowerCase().trim(),
              subrole: row['Sub Role (Optional)']?.trim().toLowerCase(),
              vehicle_type: capitalizeEachWord(row['Vehicle Type'])?.trim()
            };
            return temp;
          });

          // console.log('Result:', result);
          // console.log(roleNames);
          setIsValidating('Validate');
          if (isValidating === 'Validate') {
            document.getElementById('alert_status_text').textContent = 'Validating File...';
          }
          // console.log('Subroles', subRoles);
          const validationErrors = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const [index, record] of result.entries()) {
            // console.log(Dict[record._role.replace(' ', '-')]);
            let ro;
            if (record._role !== undefined) {
              ro = Dict[record._role.replace(' ', '-').toLowerCase()];
              setRole(Dict[record._role.toLowerCase().replace(' ', '-')]);
            }
            // excel file data validationSchema
            const validationSchema = Yup.object({
              name: Yup.string()
                .required('Name is required')
                .typeError("Please provide employee's name")
                .matches(
                  /^[a-zA-Z.][a-zA-Z.\s]*$/,
                  'Invalid employee name. ( Only alphabets, Period & whitespaces are allowed )'
                ),
              father_name: Yup.string()
                .required('Name is required')
                .typeError("Please provide employee's name")
                .matches(
                  /^[a-zA-Z.][a-zA-Z.\s]*$/,
                  'Invalid employee name. ( Only alphabets, Period & whitespaces are allowed )'
                ),
              spouse_name: Yup.string().notRequired(),
              gender: Yup.string()
                .required('Gender is required')
                .typeError('Please provide a gender for the employee')
                .oneOf(
                  ['Male', 'Female', 'Other'],
                  "Only 'Male', 'Female' and 'Other' are allowed as gender"
                ),
              marital_status: Yup.string()
                .required('Marital Status is required')
                .oneOf(
                  ['Married', 'Unmarried', 'Widow', 'Widower', 'Divorced'],
                  "Only 'Married', 'Unmarried', 'Widow', 'Widower', 'Divorced' are allowed as marital status"
                ),
              blood_group: Yup.string()
                .notRequired()
                .oneOf(
                  ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'],
                  "Only 'A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-' are allowed as blood group"
                ),
              email: Yup.string()
                .notRequired()
                .typeError("Please provide employee's valid email address")
                .email('Invalid email address.'),
              country_code: Yup.string()
                .required('Country Code is Required')
                .oneOf(countryPhoneCodes, 'Please Provide a valid country code'),
              contact: Yup.string().required('Mobile number is required'),
              // .typeError("Please provide employee's mobile number without country code"),
              // .matches(/^[6-9]\d{9}$/, 'Invalid mobile number, add number without country code'),
              dob: Yup.string()
                .required('Date of birth is required')
                .typeError("Please provide employee's date of birth in DD-MM-YYYY format")
                .matches(
                  /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/,
                  'Invalid date of birth, desired format is DD-MM-YYYY'
                ),
              doj: Yup.string()
                .required('Date of joining is required')
                .typeError("Please provide employee's date of joining in DD-MM-YYYY format")
                .matches(
                  /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/,
                  'Invalid date of joining, desired format is DD-MM-YYYY'
                ),
              // address: Yup.string()
              //   .required('Address is required')
              //   .typeError("Please provide employee's address"),
              vehicle_type: Yup.string()
                .notRequired()
                .typeError('Please provide a vehicle type for the employee')
                .oneOf(
                  ['Two-wheeler', 'Four-wheeler'],
                  "Only 'Two-wheeler' or 'Four-wheeler' is allowed as vehicle type"
                ),
              department: Yup.string()
                .notRequired()
                .typeError('Please provide a department for the employee')
                .oneOf(
                  Object.keys(allDepartments),
                  `Only "${Object.keys(allDepartments).join(', ')}" is allowed as Department`
                ),
              _role: Yup.string()
                .required('Role is required')
                .typeError('Please provide a role for the employee')
                .oneOf(roleNames, 'Only Sepcified roles in hirarchy is allowed as Role'),
              payout_type: Yup.string().oneOf(['CTC', 'PPD', 'PPH']),
              payout_amount: Yup.string(),
              subrole: Yup.string()
                .notRequired()
                .typeError('Please provide a sub role for the employee')
                .oneOf(
                  subRoles[ro] ?? [],
                  `This Sub Role is not valid, ${
                    subRoles[ro]?.length > 0
                      ? `Available Sub Roles are: ${subRoles[ro]?.toString().replaceAll(',', ', ')}`
                      : `No such subrole is present in Heirarchy`
                  }`
                )
              // .test(
              //   'subrole-requirement-test',
              //   `Requirement Must be less then Equal to, Or Increase Current Site requirement`,
              //   (value) => {
              //     console.log(Yup.ref('_role'));
              //     console.log(ro);
              //     console.log(subRoles[role]);
              //     if (!subRoles[role].includes(value)) {
              //       console.log(!subRoles[role].includes(value));
              //       // console.log(value);
              //       // console.log(subRoleOptions);
              //       // console.log(role);
              //       return true;
              //     }
              //     return false;
              //   }
              // )
            });
            // eslint-disable-next-line no-await-in-loop
            await validationSchema
              .validate(record, { abortEarly: false })
              .catch((validationError) => {
                // console.log("validation-error: ", validationError);
                validationErrors.push({
                  rowNumber: index + 2,
                  message: validationError.message,
                  errors: validationError.errors
                });
              });
          }

          if (validationErrors.length === 0) {
            const employees = [];
            const dict = {};
            subRoleOptions
              .map((sr) => sr.subroles)
              .forEach((sr) => {
                // eslint-disable-next-line no-restricted-syntax
                for (const [key, value] of Object.entries(sr)) {
                  dict[key] = value;
                }
              });
            // console.log('dict', dict[0]);
            result.forEach((res) => {
              res.role = Dict[res._role.replace(' ', '-')];
              delete res._role;
              const l = allRoles.find((rol) => rol._id === res.role);
              res.policy = l?.default_policy;
              res.subrole = dict[res.subrole];
              res.department = allDepartments[res.department];
              if (l?.priority === 1) {
                res.managerModel = 'Admin';
                res.managers = [info._id];
              }
              if (l?.priority > info?.role?.priority || info?.isMainAdmin) {
                employees.push(res);
              }
            });
            const data = {
              employees,
              importId: Date.now().toString()
            };
            if (DEBUG) {
              setIsValidating(null);
              Swal.close();
              console.log('Upload Employees Data:', data);
            } else {
              setIsValidating(null);
              mutate(data, {
                onError: (error) => {
                  setIsValidating(null);
                  setDataInStorage('importId', '');
                  setDataInStorage('importLength', '');
                  // for unauthorized access
                  if (error.response && error.response.status === 401) {
                    dispatch(logOut());
                  }
                  Swal.fire({
                    icon: 'error',
                    title: 'Something went wrong!',
                    text: error.response
                      ? error.response.data.message || error.toString()
                      : error.toString()
                  });
                },
                onSuccess: (data) => {
                  setIsValidating(null);
                  setImportCheck({
                    ...importCheck,
                    importId: null,
                    importLength: null
                  });
                  setDataInStorage('importId', '');
                  setDataInStorage('importLength', '');
                  // console.log(data);
                  // console.log(isValidating);
                  if (data.existing.length > 0) {
                    console.log('Existing...', data);
                    Swal.fire({
                      icon: 'warning',
                      title: 'Warning',
                      text: data.message,
                      confirmButtonText: 'Show details'
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        const errors = data?.existing;
                        Swal.fire({
                          didRender: (data) => {
                            // to remove "," inserted automatically by swal2 after each list item
                            const ul = data.querySelector(`.${classes.import__err_ul}`);
                            const nodes = ul.childNodes;
                            nodes.forEach((node) => {
                              if (node.nodeType === Node.TEXT_NODE) {
                                node.parentNode.removeChild(node); // removing any text content ohter than list-item
                              }
                            });
                            // removing extra margin from action buttons
                            const actions = document.querySelector('.swal2-actions');
                            actions.style.marginTop = 0;
                          },
                          html: `<h5>Following issues were encountered while adding clients</h5>
                                    <ul class=${classes.import__err_ul}>
                                    ${errors.map(
                                      (resErr, index) =>
                                        `<li class=${classes.import__err_li}>
                                        <span>${(index += 1)}.&nbsp;</span>
                                        <span class=${classes.import__err_msg}>${
                                          resErr.error
                                        }</span>
                                        </li>`
                                    )}
                                    </ul>`
                        });
                      }
                    });
                  } else {
                    Swal.fire({
                      icon: 'success',
                      title: 'Successfull',
                      html: `<span>${data.message}<div style="color: red; font-weight: bold;margin: 0.5rem 0">IMPORTANT</div><mark>Please update the profile picture of the added employees with their real faces for our facial-recognition based attendace system to work properly. Otherwise, they won't be able to mark the attendance.</mark><br/><br/>
                                Employees can login to their respective accounts by using mobile numbers as their initial password.\nFeel free to change it whenever you want from employee profile.</span>`
                    });
                  }
                },
                onSettled: () => {
                  setIsValidating(null);
                  document.getElementById('import-employees-file').value = null;
                  cache.invalidateQueries('unmanaged-employees');
                }
              });
            }
          } else {
            setIsValidating(null);
            document.getElementById('import-employees-file').value = null;
            Swal.fire({
              didRender: (data) => {
                // to remove "," inserted automatically by swal2 after each list item
                const ul = data.querySelector(`.${classes.import__err_ul}`);
                const innerUls = data.querySelectorAll(`.${classes.import__err_inner_ul}`);

                const nodes = ul.childNodes;

                nodes.forEach((node) => {
                  if (node.nodeType === Node.TEXT_NODE) {
                    node.parentNode.removeChild(node); // removing any text content ohter than list-item
                  }
                });

                innerUls.forEach((list) => {
                  const nodes = list.childNodes;

                  nodes.forEach((node) => {
                    if (node.nodeType === Node.TEXT_NODE) {
                      node.parentNode.removeChild(node); // removing any text content ohter than list-item
                    }
                  });
                });

                // removing extra margin from action buttons
                const actions = document.querySelector('.swal2-actions');
                actions.style.marginTop = 0;
              },
              html: `<h6>Fix the following issues in your excel file (see instructions file for more details)</h6>
                <ul class=${classes.import__err_ul}>
                ${validationErrors.map(
                  (resErr) =>
                    `<li class=${classes.import__err_li}>
                    <span class=${classes.import__err_number}>Row-${resErr.rowNumber}: </span>
                    <span class=${classes.import__err_msg}>
                    ${resErr.message}
                    ${
                      resErr?.errors?.length > 1
                        ? `
                      <ul class=${classes.import__err_inner_ul}>
                      ${resErr.errors.map(
                        (er) => `
                    <li>
                    ${er}
                    </li>
                    `
                      )}
                      </ul>
                      `
                        : ''
                    }
                    </span>
                    </li>`
                )}
                </ul>`
            });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, mutate, cache, info]
  );

  return (
    <>
      <TextField
        sx={{ display: 'none' }}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        id="import-employees-file"
        onChange={(event) =>
          handleBulkUpload(event.target.files, allAvailableRoles, subRoleOptions)
        }
        type="file"
      />
      <Button
        variant="contained"
        size="small"
        component="span"
        startIcon={<AddIcon />}
        sx={{ boxShadow: 'none' }}
        onClick={() => {
          Swal.fire({
            title: 'Import employees in bulk',
            html: `<span>From here you can import all of your employees in bulk, please refer <a href=${TRACKKAR_BULK_UPLOAD_FILES} download style='text-transform: underline'>this</a> for the upload format.</span>`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Upload file'
          }).then((result) => {
            if (result.isConfirmed) {
              document.getElementById('import-employees-file').click();
            }
          });
        }}
      >
        Import
      </Button>
    </>
  );
}
