/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Container,
  Card,
  Box,
  Paper,
  Grid,
  Stack,
  Avatar,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Select,
  FormLabel,
  MenuItem,
  CircularProgress
  // Alert
} from '@mui/material';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// icons
import PersonIcon from '@mui/icons-material/Person';
import WcIcon from '@mui/icons-material/Wc';
import PaymentIcon from '@mui/icons-material/Payment';
import EventIcon from '@mui/icons-material/Event';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
// import PasswordIcon from '@mui/icons-material/Password';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
import ContactsIcon from '@mui/icons-material/Contacts';
import { changeAvatar, updateAdminDetails, updateEmployeeDetails } from '../api/mutations/profile';
import { getEmployeeProfileById, getAdminProfileById } from '../api/queries/profile';
// utils
import {
  // getCurrentDate,
  getBase64,
  capitalizeEachWord,
  convertUTCtoLocal,
  DD_MM_YYYY
} from '../utils/functions';
// components
import Page from '../components/Page';
import { closeAccount } from '../api/mutations/adminMutations';
import { logOut, updateAdminInfo } from '../redux/actions/adminActions';
// import INDIAN from '../utils/INDIAN_STATES.json'; // importing indian states and uts
import { countries } from '../utils/countries';
import { useSendMobileOTP, useSendEmailOTP } from '../hooks/otp';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  borderBottom: '1px solid grey',
  color: theme.palette.text.secondary
}));

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250
//     }
//   }
// };

// const names = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Sunday'];

function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cache = useQueryClient();
  const [verifyMobile, setVerifyMobile] = useState(null);
  const [verifyEmail, setVerifyEmail] = useState(null);
  const { info } = useSelector((state) => state.adminInfo);
  const [, setProfilePictureError] = useState(false);
  // const [offdays, setOffDays] = useState([]);
  const [isUpdatingImg, setIsUpdatingImg] = useState(false); // to check if profile picture is updating
  const [isEdit, setIsEdit] = useState({
    employee: false,
    admin: false
  });
  const [dateOfIncorporation, setDateOfIncorporation] = useState();
  // useEffect(() => {
  //   console.log(info);
  // }, [info]);

  // const theme = useTheme();

  // const handleChange = (event) => {
  //   const {
  //     target: { value }
  //   } = event;
  //   setOffDays(
  //     // On autofill we get a the stringified value.
  //     typeof value === 'string' ? value.split(',') : value
  //   );
  // };

  // function getStyles(name, offdays, theme) {
  //   return {
  //     fontWeight:
  //       offdays.indexOf(name) === -1
  //         ? theme.typography.fontWeightRegular
  //         : theme.typography.fontWeightMedium
  //   };
  // }
  const { mutate: closeAccountMutation } = useMutation(closeAccount, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Deleting Account Data...',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: (response) => {
      dispatch(logOut());
    }
  });

  const { mutate: updatePhotoMutation } = useMutation(changeAvatar, {
    onMutate: () => {
      setIsUpdatingImg(true);
    }
  });

  const { data: profileData } = useQuery(
    [`${!info.isMainAdmin ? 'employee' : 'admin'}-profile`, info?._id],
    () => (!info.isMainAdmin ? getEmployeeProfileById(info._id) : getAdminProfileById(info._id)),
    {
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );

  const [dateOfBirth, setDateOfBirth] = useState(profileData?.employee?.dob);

  useEffect(() => {
    // console.log(profileData);
  }, [profileData]);

  const { mutate: adminDetailsMutation } = useMutation(updateAdminDetails);
  const { mutate: employeeDetailsMutation } = useMutation(updateEmployeeDetails);

  // 2MB
  const FILE_SIZE = 2 * 1024 * 1024;

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

  // const [pictureTouched, setpictureTouched] = useState(false);

  const validateProfilePicture = (profilePicture) => {
    let hasError = false;
    const { type, size } = profilePicture;
    const fileType = type;
    const fileSize = size;

    if (profilePicture && !SUPPORTED_FORMATS.includes(fileType)) {
      setProfilePictureError('Unsupported Format');
      hasError = true;
    } else if (profilePicture && fileSize > FILE_SIZE) {
      setProfilePictureError('File too large (Max: 2MB)');
      hasError = true;
    }
    return hasError;
  };

  const handleImageUpload = async (image) => {
    const imgError = validateProfilePicture(image);
    const data = {
      id: info._id,
      image: await getBase64(image),
      admin: !!info.isMainAdmin
    };
    if (!imgError) {
      updatePhotoMutation(data, {
        onMutate: () => {
          setIsUpdatingImg(true);
        },
        onError: (error) => {
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }
          // document.getElementById(
          //   'profile-picture-label'
          // ).style.backgroundImage = `url(${info?.avatar})`;

          Toast.fire({
            background: '#ec4646',
            html: `<h6 style='color: #ffffff; font-weight: bold;'>${
              error.response ? error.response.data.message || error.toString() : error.toString()
            }</h6>`
          });
        },
        onSuccess: (data) => {
          cache.invalidateQueries('admin-profile');
          cache.invalidateQueries('employee-profile');
          // console.log('Success Data', data.employee);
          if (data.employee) {
            dispatch(updateAdminInfo('avatar', data.employee.avatar));
          }
          Toast.fire({
            icon: 'success',
            iconColor: '#fff',
            background: '#16c79a',
            title: `<span style='color: #ffffff; font-weight: bold; padding-left: 10px'>${data.message}</span>`,
            timer: 3000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          setIsUpdatingImg(false);
          document.getElementById('profilePicture').value = null;
        }
      });
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    didRender: (data) => {
      // changing text alignment for alert and adding some padding
      const content = data.querySelector('#swal2-content');
      if (content) {
        content.style.paddingLeft = '1rem';
        content.style.paddingBottom = '.7rem';
        content.style.textAlign = 'left';
      }
    }
  });

  // ----------------------------- Employee Formik
  const employeeFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: profileData?.employee?.name ?? '',
      gender: profileData?.employee?.gender ?? '',
      // email: profileData?.employee?.email ?? '',
      // country_code: profileData?.employee?.country_code ?? '',
      // mobile: profileData?.employee?.contact ?? '',
      dateOfBirth: profileData?.employee?.dob ?? '',
      // state: profileData?.employee?.state ?? '',
      address: profileData?.employee?.address ?? '',
      vehicle_type: profileData?.employee?.vehicle_type ?? '',
      holidays: profileData?.employee?.day_off ?? []
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name is required')
        .matches(
          /^[a-zA-Z][a-zA-Z\s]*$/,
          'Please enter a valid name (only alphabets & whitespaces are allowed)'
        ),
      gender: Yup.string().required('Gender is required'),
      // email: Yup.string().email('Invalid email address').required('E-mail is required'),
      // mobile: Yup.string()
      //   .required('Mobile number is required')
      //   .matches(/^[6-9]\d{9}$/, 'Invalid mobile number'),
      // country_code: Yup.string().required('Country Code is required'),
      // dateOfBirth: Yup.date()
      //   .max(new Date(getCurrentDate()), "Date of birth can't be a future date")
      //   .required('Date of birth is required'),
      // doj: Yup.date()
      //   .max(new Date(getCurrentDate()), "Date of joining can't be a future date")
      //   .required('Date of joining is required'),
      // state: Yup.string().required('This field is required'),
      address: Yup.string().required('Address is required'),
      vehicle_type: Yup.string()
        .required('This field is required')
        .oneOf(['Two-wheeler', 'Four-wheeler']),
      holidays: Yup.array()
        .of(Yup.string())
        .required('This Field is Required')
        .typeError('Invalid Data')
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        id: profileData?.employee?._id,
        name: capitalizeEachWord(values.name),
        gender: values.gender,
        // email: values.email.toLowerCase(),
        // country_code: values.country_code,
        // contact: values.mobile,
        dob: convertUTCtoLocal(values.dateOfBirth ?? profileData?.employee?.dob, true),
        state: values.state,
        doj: profileData?.employee?.doj, // Same as profile Data convertUTCtoLocal(values.doj, true)
        address: capitalizeEachWord(values.address),
        vehicle_type: values.vehicle_type
        // day_off: values.holidays ?? profileData?.employee?.day_off
      };

      // console.log('Employee Profile Update Form :', data);

      employeeDetailsMutation(data, {
        onError: (error) => {
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          actions.setSubmitting(false);
          dispatch(updateAdminInfo('email', data?.employee?.email));
          dispatch(updateAdminInfo('name', data?.employee?.name));
          Swal.fire({
            icon: 'success',
            title: 'Profile Updated!',
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          setIsEdit({ ...isEdit, employee: false });
          cache.invalidateQueries('employee-profile');
        }
      });
    }
  });

  const {
    errors: employeeErrors,
    touched: employeeTouched,
    values: employeeValues,
    // isSubmitting,
    handleSubmit: handleEmployeeSubmit,
    getFieldProps: getEmployeeFieldProps
    // resetForm: resetEmployeeForm
  } = employeeFormik;

  // --------------------------- Mobile Formik

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const verifyMobileFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      country_code: profileData?.employee?.country_code ?? '',
      mobile: profileData?.employee?.contact ?? '',
      otp: ''
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .required('Mobile number is required')
        .matches(/^[6-9]\d{9}$/, 'Invalid mobile number'),
      country_code: Yup.string().required('Country Code is required')
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        id: profileData?.employee?._id,
        name: capitalizeEachWord(values.name),
        gender: values.gender,
        // email: values.email.toLowerCase(),
        // country_code: values.country_code,
        // contact: values.mobile,
        dob: convertUTCtoLocal(values.dateOfBirth ?? profileData?.employee?.dob, true),
        state: values.state,
        doj: profileData?.employee?.doj, // Same as profile Data convertUTCtoLocal(values.doj, true)
        address: capitalizeEachWord(values.address),
        vehicle_type: values.vehicle_type
        // day_off: values.holidays ?? profileData?.employee?.day_off
      };

      // console.log('Employee Profile Update Form :', data);

      employeeDetailsMutation(data, {
        onError: (error) => {
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          actions.setSubmitting(false);
          dispatch(updateAdminInfo('email', data?.employee?.email));
          dispatch(updateAdminInfo('name', data?.employee?.name));
          Swal.fire({
            icon: 'success',
            title: 'Profile Updated!',
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          setIsEdit({ ...isEdit, employee: false });
          cache.invalidateQueries('employee-profile');
        }
      });
    }
  });

  const {
    erros: verifyMobileErrors,
    touched: verifyMobileTouched,
    values: verifyMobileValues,
    // handleSubmit: handleVerifyMobileSubmit,
    getFieldProps: getVerifyMobileFieldProps
  } = verifyMobileFormik;

  // --------------------------- Mobile Formik

  const verifyEmailFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: profileData?.employee?.email ?? '',
      resend: false,
      otp: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('E-mail is required')
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        id: profileData?.employee?._id,
        name: capitalizeEachWord(values.name),
        gender: values.gender,
        // email: values.email.toLowerCase(),
        // country_code: values.country_code,
        // contact: values.mobile,
        dob: convertUTCtoLocal(values.dateOfBirth ?? profileData?.employee?.dob, true),
        state: values.state,
        doj: profileData?.employee?.doj, // Same as profile Data convertUTCtoLocal(values.doj, true)
        address: capitalizeEachWord(values.address),
        vehicle_type: values.vehicle_type
        // day_off: values.holidays ?? profileData?.employee?.day_off
      };

      // console.log('Employee Profile Update Form :', data);

      employeeDetailsMutation(data, {
        onError: (error) => {
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          actions.setSubmitting(false);
          dispatch(updateAdminInfo('email', data?.employee?.email));
          dispatch(updateAdminInfo('name', data?.employee?.name));
          Swal.fire({
            icon: 'success',
            title: 'Profile Updated!',
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          setIsEdit({ ...isEdit, employee: false });
          cache.invalidateQueries('employee-profile');
        }
      });
    }
  });

  const {
    erros: verifyEmailErrors,
    touched: verifyEmailTouched,
    values: verifyEmailValues,
    // handleSubmit: handleVerifyEmailSubmit,
    getFieldProps: getVerifyEmailFieldProps
  } = verifyEmailFormik;

  // -------------------------- Admin Formik
  const adminFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: profileData?.admin?.name ?? '',
      email: profileData?.admin?.email ?? '',
      date_of_incorp: profileData?.admin?.date_of_incorp ?? '',
      entity_type: profileData?.admin?.entity_type ?? '',
      entity_size: profileData?.admin?.entity_size ?? '',
      pan: profileData?.admin?.pan ?? '',
      gst_no: profileData?.admin?.gst_no ?? '',
      bank_name: profileData?.admin?.bank_details?.bank_name ?? '',
      bank_account_no: profileData?.admin?.bank_details?.bank_account_no ?? '',
      bank_ifsc_code: profileData?.admin?.bank_details?.bank_ifsc_code ?? '',
      bank_branch: profileData?.admin?.bank_details?.bank_branch ?? '',
      company_house: profileData?.admin?.address_details?.house ?? '',
      company_town: profileData?.admin?.address_details?.town ?? '',
      company_country: profileData?.admin?.address_details?.country ?? '',
      company_state: profileData?.admin?.address_details?.state ?? '',
      company_city: profileData?.admin?.address_details?.city ?? '',
      company_pincode: profileData?.admin?.address_details?.pincode ?? ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .notRequired()
        .matches(
          /^[a-zA-Z][a-zA-Z\s]*$/,
          'Please enter a valid name (only alphabets & whitespaces are allowed)'
        )
        .test(
          'empty-check',
          "Name can't be empty",
          (value) => value && value.length && value.length !== 0
        ),
      email: Yup.string()
        .notRequired()
        .email('Invalid email address')
        .test(
          'empty-check',
          "E-mail can't be empty",
          (value) => value && value.length && value.length !== 0
        ),
      date_of_incorp: Yup.string()
        .notRequired()
        .test(
          'empty-check',
          "Date of Incorporation can't be empty",
          (value) => value && value.length && value.length !== 0
        ),
      entity_type: Yup.string(),
      pan: Yup.string(),
      gst_no: Yup.string(),
      bank_name: Yup.string(),
      bank_account_no: Yup.string(),
      bank_ifsc_code: Yup.string(),
      bank_branch: Yup.string()
    }),
    // validate: (values) => {
    //   console.log('Profile Form Data :', values);
    // },
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        id: profileData?.admin?._id,
        name: capitalizeEachWord(values.name.trim()),
        email: values.email.toLowerCase().trim(),
        date_of_incorp: values.date_of_incorp,
        entity_type: values.entity_type,
        entity_size: values.entity_size,
        pan: values.pan,
        gst_no: values.gst_no,
        bank_details: {
          bank_name: values.bank_name,
          bank_account_no: values.bank_account_no,
          bank_ifsc_code: values.bank_ifsc_code,
          bank_branch: values.bank_branch
        },
        address_details: {
          house: values.company_house,
          town: values.company_town,
          country: values.company_country,
          state: values.company_state,
          city: values.company_city,
          pincode: values.company_pincode
        }
      };

      // console.log('Profile Update Form :', data);

      adminDetailsMutation(data, {
        onError: (error) => {
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false
            // timer: 2000,
            // timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          actions.setSubmitting(false);
          Swal.fire({
            icon: 'success',
            title: 'Profile Updated!',
            text: data.message,
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          setIsEdit({ ...isEdit, admin: false });
          cache.invalidateQueries('admin-profile');
        }
      });
    }
  });

  const {
    errors: adminErrors,
    touched: adminTouched,
    values: adminValues,
    // isSubmitting: adminIsSubmitting,
    handleSubmit: handleAdminSubmit,
    getFieldProps: getAdminFieldProps
    // resetForm: adminResetForm
  } = adminFormik;

  const { data: sendEmailMessage } = useSendEmailOTP(verifyEmail);
  const { data: sendMobileMessage } = useSendMobileOTP(verifyMobile);

  useEffect(() => {
    if (sendEmailMessage) {
      console.log('Send Email Message:', sendEmailMessage);
    }
  }, [sendEmailMessage]);

  useEffect(() => {
    if (sendMobileMessage) {
      console.log('Send Mobile Message:', sendMobileMessage);
    }
  }, [sendMobileMessage]);

  const _countries = Country.getAllCountries().map((cn) => `${cn.isoCode},${cn.name}`);
  const _states = State.getStatesOfCountry(adminValues.company_country.split(',')[0]).map(
    (pst) => `${pst.isoCode},${pst.name}`
  );
  const _cities = City.getCitiesOfState(
    adminValues.company_country.split(',')[0],
    adminValues.company_state.split(',')[0]
  ).map((pct) => `${pct.isoCode},${pct.name}`);

  return (
    <Page title="Profile | Trackkar">
      <Container sx={{ position: 'relative', pt: 1, pb: 2 }}>
        <Box sx={{ position: 'absolute', display: 'flex', p: 2, right: 0, top: 0 }}>
          {/* <Chip
              label={`Shifts : ${data?.site?.shifts?.lenght ?? 0}`}
              sx={{ mr: 2 }}
              component="a"
              href="#basic-chip"
            />
            <Chip
              label={`Feedback : ${data?.site?.feedback ?? 0}`}
              component="a"
              href="#basic-chip"
            /> */}
        </Box>
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              height: '120px',
              position: 'relative',
              px: 1,
              pb: 2,
              borderBottom: '2px solid grey'
            }}
          >
            <FormLabel
              htmlFor="profilePicture"
              sx={{ position: 'absolute', zIndex: 9, bottom: 12 }}
            >
              <TextField
                accept=".jpg,.jpeg,.png"
                id="profilePicture"
                type="file"
                sx={{ display: 'none' }}
                onChange={(event) => {
                  // previewImage(event, event.currentTarget.files[0]);
                  handleImageUpload(event.currentTarget.files[0]);
                }}
              />
              <IconButton color="primary" aria-label="upload picture" component="span">
                {isUpdatingImg ? <CircularProgress size={20} /> : <PhotoCameraIcon />}
              </IconButton>
            </FormLabel>
            <Avatar
              sx={{ width: 90, height: 90, mr: 3 }}
              alt={!info.isMainAdmin ? profileData?.employee?.name : profileData?.admin?.name}
              src={!info.isMainAdmin ? profileData?.employee?.avatar : profileData?.admin?.avatar}
            />
            <Typography sx={{ fontSize: '1rem' }}>
              {!info.isMainAdmin ? profileData?.employee?.name : profileData?.admin?.name}
            </Typography>
            {/* <Typography
                marginBottom="0.4rem"
                sx={{ position: 'absolute', fontSize: '0.9rem', right: 0, bottom: 0 }}
              >
                Email : {!info.isMainAdmin ? profileData?.employee?.email : profileData?.admin?.email}
              </Typography> */}
          </Stack>
        </Stack>
        <br />
        {!info.isMainAdmin ? (
          <Box sx={{ flexGrow: 1 }}>
            {isEdit.employee ? (
              <>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    {/* Name */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PersonIcon sx={{ color: 'action.active', mr: 1 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Name"
                        type="text"
                        autoComplete="off"
                        {...getEmployeeFieldProps('name')}
                        error={Boolean(employeeTouched.name && employeeErrors.name)}
                        helperText={employeeTouched.name && employeeErrors.name}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* Gender */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="employee_gender">
                          Choose Gender
                        </InputLabel>
                        <Select
                          variant="standard"
                          inputProps={{
                            name: 'gender',
                            id: 'employee_gender'
                          }}
                          required
                          sx={{ mt: 2 }}
                          {...getEmployeeFieldProps('gender')}
                          error={Boolean(employeeTouched.gender && employeeErrors.gender)}
                          // helperText={employeeTouched.gender && employeeErrors.gender}
                        >
                          <MenuItem value="" disabled>
                            Choose Gender
                          </MenuItem>
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* Date Of Birth */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date Of Birth"
                          value={dateOfBirth}
                          onChange={(dateOfBirth) => {
                            employeeValues.dateOfBirth = convertUTCtoLocal(dateOfBirth, true);
                            setDateOfBirth(employeeValues.dateOfBirth);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              fullWidth
                              {...params}
                              error={Boolean(
                                employeeTouched.dateOfBirth && employeeErrors.dateOfBirth
                              )}
                              helperText={employeeTouched.dateOfBirth && employeeErrors.dateOfBirth}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* Address */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <ContactsIcon sx={{ color: 'action.active', mr: 1 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        value={info?.address}
                        label="Address"
                        type="text"
                        placeholder="Address..."
                        autoComplete="off"
                        {...getEmployeeFieldProps('address')}
                        error={Boolean(employeeTouched.address && employeeErrors.address)}
                        helperText={employeeTouched.address && employeeErrors.address}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* Vehicle */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <EmojiTransportationIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="vehicle_type">
                          Choose Vehicle
                        </InputLabel>
                        <Select
                          variant="standard"
                          inputProps={{
                            name: 'vehicle_type',
                            id: 'vehicle_type'
                          }}
                          required
                          sx={{ mt: 2 }}
                          {...getEmployeeFieldProps('vehicle_type')}
                          error={Boolean(
                            employeeTouched.vehicle_type && employeeErrors.vehicle_type
                          )}
                          // helperText={employeeTouched.vehicle_type && employeeErrors.vehicle_type}
                        >
                          <MenuItem value="" disabled>
                            Choose Vehicle
                          </MenuItem>
                          <MenuItem value="Two-wheeler">Two Wheeler</MenuItem>
                          <MenuItem value="Four-wheeler">Four Wheeler</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  {/* <Grid item md={6} xs={12}>
                    // {/* Day Off's 
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id="demo-multiple-chip-label">Day Off</InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          name="holidays"
                          multiple
                          value={offdays}
                          onChange={handleChange}
                          {...getEmployeeFieldProps('holidays')}
                          input={<OutlinedInput id="select-multiple-chip" label="Day Off" />}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name} style={getStyles(name, offdays, theme)}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Typography align="center" fontSize="0.8rem" color="red">
                      {employeeErrors.holidays && employeeTouched.holidays}
                    </Typography>
                  </Grid> */}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={5} xs={12}>
                    <Box sx={{ display: 'flex', width: '100%', alignItems: 'flex-end' }}>
                      <PersonIcon sx={{ color: 'action.active', mr: 1 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Email"
                        type="text"
                        autoComplete="off"
                        {...getVerifyEmailFieldProps('email')}
                        error={Boolean(verifyEmailTouched.email && verifyEmailErrors?.email)}
                        helperText={verifyEmailTouched.email && verifyEmailErrors?.email}
                      />
                    </Box>
                  </Grid>
                  {profileData?.employee?.email !== verifyEmailValues?.email && (
                    <Grid item md={2} xs={2}>
                      <Button
                        sx={{ mt: 1 }}
                        variant="contained"
                        onClick={() => setVerifyEmail(verifyEmailValues?.email)}
                      >
                        Send OTP
                      </Button>
                    </Grid>
                  )}
                  <Grid item md={3} xs={3}>
                    <Box
                      style={{ display: `${verifyEmail ? '' : 'none'}` }}
                      sx={{ display: 'flex', alignItems: 'flex-end' }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        type="password"
                        label="OTP"
                        autoComplete="off"
                        {...getVerifyEmailFieldProps('otp')}
                        error={Boolean(verifyEmailTouched?.otp && verifyEmailErrors?.otp)}
                        helperText={verifyEmailTouched?.otp && verifyEmailErrors?.otp}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={1} xs={1}>
                    <Button
                      sx={{ display: `${verifyEmail ? '' : 'none'}`, mt: 1 }}
                      variant="contained"
                    >
                      Verify
                    </Button>
                  </Grid>
                  <Grid item md={1} xs={1}>
                    <Button
                      sx={{ display: `${verifyEmail ? '' : 'none'}`, mt: 1 }}
                      color="error"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    {/* Mobile */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <ContactPhoneIcon sx={{ color: 'action.active', mr: 1 }} />
                      <FormControl sx={{ width: 120 }}>
                        {/* <InputLabel id="mobile-country-select-label">Country</InputLabel> */}
                        <Select
                          labelId="mobile-country-select-label"
                          id="mobile-country-select"
                          variant="standard"
                          label="Country"
                          {...getVerifyMobileFieldProps('country_code')}
                        >
                          {countries.map((c) => (
                            <MenuItem key={c.code} value={c.phone}>
                              <img
                                loading="lazy"
                                width="23"
                                src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                                alt={`Flag of ${c.label}`}
                              />
                              &nbsp; +{c.phone}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        variant="standard"
                        fullWidth
                        type="number"
                        label="Mobile"
                        autoComplete="off"
                        {...getVerifyMobileFieldProps('mobile')}
                        error={Boolean(verifyMobileTouched?.mobile && verifyMobileErrors?.mobile)}
                        helperText={verifyMobileTouched?.mobile && verifyMobileErrors?.mobile}
                      />
                    </Box>
                    <Typography
                      sx={{
                        color: 'red',
                        display: `${verifyMobileErrors?.mobile ? '' : 'none'}`,
                        fontSize: 12
                      }}
                    >
                      {verifyMobileErrors?.mobile}
                    </Typography>
                  </Grid>
                  {profileData?.employee?.contact !== verifyMobileValues?.mobile && (
                    <Grid item md={2} xs={2}>
                      <Button
                        type="button"
                        sx={{ mt: 1 }}
                        onClick={() => setVerifyMobile(verifyMobileValues?.mobile)}
                        variant="contained"
                      >
                        Send OTP
                      </Button>
                    </Grid>
                  )}
                  <Grid item md={3} xs={3}>
                    <Box sx={{ display: `${verifyMobile ? '' : 'none'}`, alignItems: 'flex-end' }}>
                      <TextField
                        variant="standard"
                        fullWidth
                        type="password"
                        label="OTP"
                        autoComplete="off"
                        {...getVerifyMobileFieldProps('otp')}
                        error={Boolean(verifyMobileTouched?.mobile && verifyMobileErrors?.mobile)}
                        helperText={verifyMobileTouched?.mobile && verifyMobileErrors?.mobile}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={1} xs={1}>
                    <Button
                      sx={{ display: `${verifyMobile ? '' : 'none'}`, mt: 1 }}
                      variant="contained"
                    >
                      Verify
                    </Button>
                  </Grid>
                  <Grid item md={1} xs={1}>
                    <Button
                      sx={{ display: `${verifyMobile ? '' : 'none'}`, mt: 1 }}
                      color="error"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Item>Name : {profileData?.employee?.name}</Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>Gender : {profileData?.employee?.gender}</Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>Email : {profileData?.employee?.email}</Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>
                    Contact :{' '}
                    {`+${profileData?.employee?.country_code} ${profileData?.employee?.contact}`}
                  </Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>Date Of Birth : {DD_MM_YYYY(profileData?.employee?.dob)}</Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>Date Of Joining : {DD_MM_YYYY(profileData?.employee?.doj)}</Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>Address : {profileData?.employee?.address}</Item>
                </Grid>
                {/* <Grid item md={6} xs={12}>
                    <Item>
                      State : {profileData?.employee?.state}
                    </Item>
                  </Grid> */}
                <Grid item md={6} xs={12}>
                  <Item>Vehicle : {profileData?.employee?.vehicle_type}</Item>
                </Grid>
                {/* <Grid item md={6} xs={12}>
                    <Item>
                      Day Offs : {profileData?.employee?.day_off}
                    </Item>
                  </Grid> */}
              </Grid>
            )}
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            {isEdit.admin ? (
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  {/* Name */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <PersonIcon sx={{ color: 'action.active', mr: 1 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Name"
                      type="text"
                      autoComplete="off"
                      {...getAdminFieldProps('name')}
                      error={Boolean(adminTouched.name && adminErrors.name)}
                      helperText={adminTouched.name && adminErrors.name}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  {/* Email */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <PersonIcon sx={{ color: 'action.active', mr: 1 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Email"
                      type="text"
                      autoComplete="off"
                      {...getAdminFieldProps('email')}
                      error={Boolean(adminTouched.email && adminErrors.email)}
                      helperText={adminTouched.email && adminErrors.email}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <EventIcon sx={{ color: 'action.active', mr: 1 }} />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Date Incorporation"
                        disableFuture
                        value={dateOfIncorporation}
                        onChange={(newValue) => {
                          adminValues.date_of_incorp = convertUTCtoLocal(newValue, true);
                          setDateOfIncorporation(adminValues.date_of_incorp);
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            fullWidth
                            {...params}
                            error={Boolean(
                              adminTouched.date_of_incorp && adminErrors.date_of_incorp
                            )}
                            helperText={adminTouched.date_of_incorp && adminErrors.date_of_incorp}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  {/* Company Size */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="entity_size">
                        Choose Entity Type
                      </InputLabel>
                      <Select
                        variant="standard"
                        inputProps={{
                          name: 'entity_type',
                          id: 'entity_type'
                        }}
                        required
                        sx={{ mt: 2 }}
                        {...getAdminFieldProps('entity_type')}
                        error={Boolean(adminTouched.entity_type && adminErrors.entity_type)}
                        // helperText={touched.policy_role && errors.policy_role}
                      >
                        <MenuItem value="" disabled>
                          Choose Entity Type
                        </MenuItem>
                        <MenuItem value="Sole Proprietorship">Sole Proprietorship</MenuItem>
                        <MenuItem value="Partnership">Partnership</MenuItem>
                        <MenuItem value="Limited liability Partnership">
                          Limited liability Partnership
                        </MenuItem>
                        <MenuItem value="OPC">OPC</MenuItem>
                        <MenuItem value="Private Limited Company">Private Limited Company</MenuItem>
                        <MenuItem value="Public limited Company">Public limited Company</MenuItem>
                        <MenuItem value="Section 8 Company">Section 8 Company</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  {/* Company Size */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="entity_size">
                        Choose Entity Size
                      </InputLabel>
                      <Select
                        variant="standard"
                        inputProps={{
                          name: 'entity_size',
                          id: 'entity_size'
                        }}
                        required
                        sx={{ mt: 2 }}
                        {...getAdminFieldProps('entity_size')}
                        error={Boolean(adminTouched.entity_size && adminErrors.entity_size)}
                        // helperText={touched.policy_role && errors.policy_role}
                      >
                        <MenuItem value="" disabled>
                          Choose Entity Size
                        </MenuItem>
                        <MenuItem value="1-10">1-10</MenuItem>
                        <MenuItem value="11-20">11-20</MenuItem>
                        <MenuItem value="20-50">20-50</MenuItem>
                        <MenuItem value="50-100">50-100</MenuItem>
                        <MenuItem value="100-200">100-200</MenuItem>
                        <MenuItem value="200-500">200-500</MenuItem>
                        <MenuItem value="500-1000">500-1000</MenuItem>
                        <MenuItem value="1000+">1000+</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <PaymentIcon sx={{ color: 'action.active', mr: 1 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Company PAN"
                      autoComplete="off"
                      {...getAdminFieldProps('pan')}
                      error={Boolean(adminTouched.pan && adminErrors.pan)}
                      helperText={adminTouched.pan && adminErrors.pan}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <PaymentIcon sx={{ color: 'action.active', mr: 1 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="GST No"
                      autoComplete="off"
                      {...getAdminFieldProps('gst_no')}
                      error={Boolean(adminTouched.gst_no && adminErrors.gst_no)}
                      helperText={adminTouched.gst_no && adminErrors.gst_no}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Address Details
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="House No. / Building Name"
                      autoComplete="off"
                      required
                      {...getAdminFieldProps('company_house')}
                      error={Boolean(adminTouched.company_house && adminErrors.company_house)}
                      helperText={adminTouched.company_house && adminErrors.company_house}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Village / Town / Street"
                      autoComplete="off"
                      required
                      {...getAdminFieldProps('company_town')}
                      error={Boolean(adminTouched.company_town && adminErrors.company_town)}
                      helperText={adminTouched.company_town && adminErrors.company_town}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Pincode"
                      autoComplete="off"
                      required
                      {...getAdminFieldProps('company_pincode')}
                      error={Boolean(adminTouched.company_pincode && adminErrors.company_pincode)}
                      helperText={adminTouched.company_pincode && adminErrors.company_pincode}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <FormControl required fullWidth>
                      <InputLabel variant="standard" htmlFor="company_country">
                        Choose Country
                      </InputLabel>
                      <Select
                        variant="standard"
                        inputProps={{
                          name: 'company_country',
                          id: 'company_country'
                        }}
                        sx={{ mt: 2 }}
                        {...getAdminFieldProps('company_country')}
                        error={Boolean(adminTouched.company_country && adminErrors.company_country)}
                        // helperText={touched.policy_role && errors.policy_role}
                      >
                        <MenuItem value="" disabled>
                          Choose Country
                        </MenuItem>
                        {_countries.map((cn) => (
                          <MenuItem key={cn.toLowerCase()} value={cn}>
                            {cn.split(',')[1]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <FormControl required fullWidth>
                      <InputLabel variant="standard" htmlFor="company_state">
                        State
                      </InputLabel>
                      <Select
                        variant="standard"
                        inputProps={{
                          name: 'company_state',
                          id: 'company_state'
                        }}
                        sx={{ mt: 2 }}
                        {...getAdminFieldProps('company_state')}
                        error={Boolean(adminTouched.company_state && adminErrors.company_state)}
                        // helperText={touched.policy_role && errors.policy_role}
                      >
                        <MenuItem value="" disabled>
                          State
                        </MenuItem>
                        {_states.map((pst) => (
                          <MenuItem key={pst.toLowerCase()} value={pst}>
                            {pst.split(',')[1]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <FormControl required fullWidth>
                      <InputLabel variant="standard" htmlFor="company_city">
                        City
                      </InputLabel>
                      <Select
                        variant="standard"
                        inputProps={{
                          name: 'company_city',
                          id: 'company_city'
                        }}
                        sx={{ mt: 2 }}
                        {...getAdminFieldProps('company_city')}
                        error={Boolean(adminTouched.company_city && adminErrors.company_city)}
                        // helperText={touched.policy_role && errors.policy_role}
                      >
                        <MenuItem value="" disabled>
                          City
                        </MenuItem>
                        {_cities.map((pct) => (
                          <MenuItem key={pct.toLowerCase()} value={pct}>
                            {pct.split(',')[1]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Bank Details
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <AccountBalanceIcon sx={{ color: 'action.active', mr: 1 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Bank Name"
                      autoComplete="off"
                      {...getAdminFieldProps('bank_name')}
                      error={Boolean(adminTouched.bank_name && adminErrors.bank_name)}
                      helperText={adminTouched.bank_name && adminErrors.bank_name}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <AccountBalanceIcon sx={{ color: 'action.active', mr: 1 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="number"
                      label="Account No"
                      autoComplete="off"
                      {...getAdminFieldProps('bank_account_no')}
                      error={Boolean(adminTouched.bank_account_no && adminErrors.bank_account_no)}
                      helperText={adminTouched.bank_account_no && adminErrors.bank_account_no}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <AccountBalanceIcon sx={{ color: 'action.active', mr: 1 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="number"
                      label="IFSC Code"
                      autoComplete="off"
                      {...getAdminFieldProps('bank_ifsc_code')}
                      error={Boolean(adminTouched.bank_ifsc_code && adminErrors.bank_ifsc_code)}
                      helperText={adminTouched.bank_ifsc_code && adminErrors.bank_ifsc_code}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <AccountBalanceIcon sx={{ color: 'action.active', mr: 1 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Branch"
                      autoComplete="off"
                      {...getAdminFieldProps('bank_branch')}
                      error={Boolean(adminTouched.bank_branch && adminErrors.bank_branch)}
                      helperText={adminTouched.bank_branch && adminErrors.bank_branch}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Item>Name : {profileData?.admin?.name}</Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>Email : {profileData?.admin?.email}</Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>Entity Type : {profileData?.admin?.entity_type ?? 'XXXXXXXXXX'}</Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>Entity Size : {profileData?.admin?.entity_size ?? 'XXXXXXXXXX'}</Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>Company PAN : {profileData?.admin?.pan ?? 'XXXXXXXXXX'}</Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>
                    Date Of Incorporation : {profileData?.admin?.date_of_incorp ?? 'XXXX-XX-XX'}
                  </Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>GST Number : {profileData?.admin?.gst_no ?? 'XXXXXXXXXXX'}</Item>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 1 }}>
                    Bank Details
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>
                    Bank Name : {profileData?.admin?.bank_details?.bank_name ?? 'XXXXXXXXXXX'}
                  </Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>
                    Bank Account Number :{' '}
                    {profileData?.admin?.bank_details?.bank_account_no ?? 'XXXXXXXXXXXX'}
                  </Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>
                    IFSC : {profileData?.admin?.bank_details?.bank_ifsc_code ?? 'XXXXXXXX'}
                  </Item>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Item>
                    Branch : {profileData?.admin?.bank_details?.bank_branch ?? 'XXXXXXXXXXX'}
                  </Item>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
        <br />
        {isEdit.admin || isEdit.employee ? (
          <>
            <Button
              variant="contained"
              onClick={() => {
                if (!info.isMainAdmin) handleEmployeeSubmit();
                else handleAdminSubmit();
              }}
            >
              Update
            </Button>
            &emsp;
            <Button
              variant="contained"
              onClick={() => {
                setIsEdit({ employee: false, admin: false });
                setVerifyMobile(false);
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Button
              variant="contained"
              onClick={() => {
                if (!info.isMainAdmin) setIsEdit({ ...isEdit, employee: true });
                else setIsEdit({ ...isEdit, admin: true });
              }}
            >
              Edit
            </Button>
            {info?.isMainAdmin && (
              <Button
                onClick={() => {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: `All Account data will be deleted!`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Proceed!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      closeAccountMutation({ confirm: 'permanently delete' });
                    }
                  });
                }}
                variant="contained"
                color="error"
              >
                Close Account
              </Button>
            )}
          </Stack>
        )}
      </Container>
    </Page>
  );
}

export default Profile;
