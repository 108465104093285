/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import PropTypes from 'prop-types';
// import { sentenceCase } from 'change-case';
import React, { useState, useMemo, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
// import { useFormik, FormikProvider, Form } from 'formik';
import InfiniteScroll from 'react-infinite-scroll-component';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
// icons
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// import UploadIcon from '@mui/icons-material/Upload';
import SchoolIcon from '@mui/icons-material/School';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ContactsIcon from '@mui/icons-material/Contacts';
// hooks
import { useEmployees } from '../hooks/employees';
import { useManagers } from '../hooks/get-managers';
// import { useRoles } from '../hooks/roles';
// components
import Page from '../components/Page';
import { ImageZoom } from '../components';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
import {
  EmployeeListHead,
  EmployeeListToolbar,
  EmployeeMoreMenu
} from '../components/_dashboard/employee';
// utils
import { DD_MM_YYYY, setDataInStorage, getCurrentDate } from '../utils/functions';
import { SWAL_TIME } from '../utils/config';
// Mutations
import { assignSiteManager } from '../api/mutations/adminMutations';
import { deleteEmployees } from '../api/mutations/employee';
import { assignPolicy } from '../api/mutations/policy';
import AssignSiteEmployee from '../components/_dashboard/employee/AssignSiteEmployee';
import useSites from '../hooks/get-sites-query';
// redux
import { logOut } from '../redux/actions/adminActions';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'employee', label: 'Employee', alignRight: false },
  { id: 'tag', label: 'Tag', alignRight: false },
  { id: 'manager', label: 'Manager', alignRight: false },
  { id: 'sites', label: 'Sites', alignRight: false },
  { id: 'doj', label: 'Joining', alignRight: false },
  { id: 'documents', label: 'Documents', alignRight: false },
  { id: 'policy', label: 'Policy', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const AddEmployee = React.lazy(() => import('../components/_dashboard/employee/AddEmployee'));

UpdateEmpManagers.propTypes = {
  emp: PropTypes.object,
  anchorEl: PropTypes.bool,
  setAnchorEl: PropTypes.func
};

export function UpdateEmpManagers({ emp, anchorEl, setAnchorEl }) {
  const [empManagersOptions, setEmpManagersOptions] = useState();
  const [manager, setManager] = useState('');
  const cache = useQueryClient();
  const dispatch = useDispatch();
  /* ===================== Sites ===================== */

  const {
    isLoading: isEmpManagersLoading,
    isError: isEmpManagersError,
    data: empManagers
    // fetchNextPage: fetchNextEmpManagers,
    // hasMoreData: hasNextEmpManagers
    // refetch: refetchSites
  } = useManagers(emp?.role?._id);

  const allEmpManagers = useMemo(() => {
    const listOfEmpManagers = [];
    if (!isEmpManagersLoading && !isEmpManagersError) {
      if (empManagers?.pages?.length > 0) {
        empManagers.pages.forEach((group) => listOfEmpManagers.push(group.managers));
      }
    }
    const flattened = listOfEmpManagers.flat();
    // console.log('Employee Managers :', flattened);
    return flattened;
  }, [empManagers, isEmpManagersLoading, isEmpManagersError]);

  useEffect(() => {
    const formattedEmpManagers = [];
    if (allEmpManagers?.length > 0) {
      allEmpManagers.forEach((empl) => {
        if (empl._id !== emp?.managers[0]?._id) {
          formattedEmpManagers.push([empl._id, empl.name]);
        }
      });
      setEmpManagersOptions([...formattedEmpManagers]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allEmpManagers, emp]);

  /* ================= End Sites =============== */

  /* ===================== Assign Manager Mutations ==============  */

  const assignManagerMutation = useMutation(assignSiteManager, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Assiging New Manager 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: (response) => {
      // setSelected([]);
      // setAssign({ ...assign, manager: '' }); // Reset Select Value
      setDataInStorage('selected_site', response.sites[0]);
      Swal.fire({
        icon: 'success',
        title: `Successfully assigned manager`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      setAnchorEl({ ...anchorEl, id: null, target: null });
      cache.invalidateQueries('employees');
    }
  });

  /* ===================== Assign Manager Mutations END ==============  */

  return (
    <Popover
      id={`update_emp_manager_${emp._id}`}
      open={anchorEl.id === `update_emp_manager_${emp._id}`}
      anchorEl={anchorEl.target}
      onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Stack sx={{ p: 1, width: 200 }}>
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="emp_manager_type">
            Choose Manager
          </InputLabel>
          <Select
            variant="standard"
            inputProps={{
              name: 'emp_manager_type',
              id: 'emp_manager_type'
            }}
            required
            value={manager}
            sx={{ mt: 2 }}
            onChange={(e) => setManager(e.target.value)}
            // {...getFieldProps('role_type')}
            // error={Boolean(touched.role_type && errors.role_type)}
            // helperText={touched.policy_role && errors.policy_role}
          >
            <MenuItem value="" disabled>
              Choose Manager
            </MenuItem>
            {empManagersOptions?.length > 0 ? (
              empManagersOptions.map(([key, value]) => (
                <MenuItem key={`${'site_manager'}-${key}`} value={key}>
                  {value}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled />
            )}
          </Select>
        </FormControl>
        <Button
          sx={{ my: 1 }}
          onClick={() => {
            Swal.fire({
              title: 'Are you sure?',
              text: `Manager will be Updated`,
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Yes, Proceed!',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                assignManagerMutation.mutate({
                  // sites: [emp._id],
                  // oldManager: emp.manager._id,
                  // manager
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                cache.invalidateQueries('potential-site-members');
              }
            });
          }}
        >
          Update
        </Button>
      </Stack>
    </Popover>
  );
}

export default function User() {
  const [
    page
    // setPage
  ] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  // assign employee row for editing sites of employee
  const [assignSelectedEmp, setAssignSelectedEmp] = useState(null);
  const { info } = useSelector((state) => state.adminInfo);
  const [
    rowsPerPage
    // setRowsPerPage
  ] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [anchorDocumentsEl, setAnchorDocumentsEl] = useState({
    id: '',
    target: ''
  });
  const [openAddEmployee, setOpenAddEmployee] = useState(false);

  const dispatch = useDispatch();
  const cache = useQueryClient();
  const navigate = useNavigate();
  const [siteManagerPriority, setSiteManagerPriority] = useState();
  const [_siteManager, _setSiteManager] = useState({});
  const [, setSiteOptions] = useState([]);
  const [siteDict, setSiteDict] = useState({});
  // const [siteName, setSiteName] = useState({});
  const [search] = useDebounce(filterName, 1200); // Debounce value 1.2 sec
  const [filter, setFilter] = useState({
    doj: '',
    state: '',
    gender: '',
    role: '',
    subRole: '',
    manager: '',
    policy: ''
  });

  // Testing
  useEffect(() => {
    console.log(filter);
  }, [filter]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  /* ===================== Employees ===================== */

  const {
    isLoading,
    isError,
    data,
    fetchNextPage: fetchNextEmployees,
    hasNextPage: hasNextEmployees,
    refetch: refetchEmployees
  } = useEmployees(
    filter.doj, // doj
    search, // search
    filter.state, // state
    filter.policy.split(',')[0], // policy
    filter?.role.split(',')[0], // role
    filter.manager.split(',')[0], // manager
    filter.gender, // gender
    true, // to tell function to fetch policies for each employee too
    filter.subRole.split(',')[0]
  ); // getting employees

  const rows = useMemo(() => {
    if (!isLoading && !isError) {
      const listOfEmployees = [];
      if (data?.pages?.length > 0) {
        data.pages.forEach((group) => listOfEmployees.push(group.employees));
      }
      const flattened = listOfEmployees.flat();
      // console.log(flattened);
      return flattened;
    }
    return [];
  }, [data, isLoading, isError]);

  /* ================= End Employees =============== */

  /* ===================== Sites ===================== */

  const {
    // isLoading: isSitesLoading,
    // isError: isSitesError,
    data: sites
    // fetchNextPage: fetchNextSites,
    // hasMoreData: hasNextSites
    // refetch: refetchSites
  } = useSites(
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[0], true) : null, // from
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[1], true) : null, // to
    null, // filter.date.length > 0 ? filter.date : getCurrentDate(),
    null, // type
    null, // filter.state, // state
    null, // filter.status, // status
    null, // filter.manager, // manager
    null, // filter.role,
    search, // search
    null
  );

  const allSites = useMemo(() => {
    const listOfSites = [];
    if (!isLoading && !isError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Sites :', flattened);
    return flattened;
  }, [sites, isLoading, isError]);

  useEffect(() => {
    const formattedSites = [];
    if (allSites?.length > 0) {
      allSites.forEach((site) => {
        setSiteManagerPriority(site.manager.role.priority);
        if (site.active === 'Active') {
          _siteManager[site._id] = site.manager._id;
          siteDict[site._id] = site.name;
          formattedSites.push([site._id, site.name, site.manager._id]);
        }
      });
      _setSiteManager({ ..._siteManager });
      setSiteDict({ ...siteDict }); // Site Value Names
      setSiteOptions([...formattedSites]);
      // console.log('Role Options :', formattedRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSites]);

  /* ================== Delete Employee ========================== */
  const deleteEmployeesMutation = useMutation(deleteEmployees, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Deleted Employees`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('employees');
    }
  });

  /* ==================== Delete Employees End =========================== */

  /* ================= Assign Policy Mutation ============== */
  const assignPolicyMutation = useMutation(assignPolicy, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully assigned policy`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('employees');
    }
  });

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Employees | Trackkar">
      <Container>
        <EmployeeListToolbar
          heading={`Employees,${data?.pages[0]?.total ?? 0}`}
          actionButton={() => setOpenAddEmployee(true)}
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          setFilters={setFilter} // Set Filters for Table
          filters={filter} // Filter values for Table
          refetch={refetchEmployees}
          deleteEmployees={() =>
            Swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Yes, Proceed!',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                deleteEmployeesMutation.mutate({
                  employees: selected
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                cache.invalidateQueries('employees');
              }
            })
          }
        />
        <TableContainer
          // className="custom_scrollbar"
          id="employees-table-container"
          sx={{
            minWidth: 800,
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'scroll'
          }}
        >
          <Table size="small" stickyHeader>
            <EmployeeListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan="11">
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
              {isError && (
                <TableRow>
                  <TableCell>Something Went Wrong</TableCell>
                </TableRow>
              )}
              {!isLoading &&
                filteredUsers
                  .sort((a, b) => a.role.priority - b.role.priority)
                  .map((row) => {
                    const isItemSelected = selected.indexOf(row?._id) !== -1;
                    row.sites.forEach((site) => {
                      siteDict[site._id] = site.name;
                    });
                    return (
                      <TableRow
                        hover
                        key={row._id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, row._id)}
                          />
                        </TableCell>
                        {/* <TableCell>{(index += 1)}</TableCell> */}
                        <TableCell
                          xs={{
                            position: 'sticky',
                            left: 0,
                            backgroundColor: 'white',
                            zIndex: 8
                          }}
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar
                              onClick={() => setImageSrc(row.avatar)}
                              alt={row.name}
                              src={row.avatar}
                              sx={{ cursor: 'pointer' }}
                            />
                            <Stack>
                              <Stack direction="row" alignItems="center">
                                <Tooltip
                                  sx={{ fontSize: '1rem' }}
                                  title={`Visit ${row.name}`}
                                  placement="top"
                                >
                                  <Typography
                                    noWrap
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setDataInStorage('selected_employee', row);
                                      navigate('/employee/profile');
                                    }}
                                  >
                                    {row.name}
                                  </Typography>
                                </Tooltip>
                                &nbsp;
                                <Chip
                                  size="small"
                                  sx={{ fontSize: 12, height: 18 }}
                                  label={row.gender.charAt(0)}
                                />
                                &nbsp;
                                <InfoIcon
                                  sx={{ cursor: 'pointer', fontSize: 18 }}
                                  onClick={(event) =>
                                    setAnchorEl({
                                      id: `info_${row._id}`,
                                      target: event.currentTarget
                                    })
                                  }
                                />
                              </Stack>
                              <Box>
                                <Typography sx={{ color: '#3366FF', fontSize: 11 }}>
                                  <b>{row.role.name}</b>
                                </Typography>
                                {row?.subrole ? (
                                  <Typography sx={{ color: '#DC7633', fontSize: 11 }}>
                                    <b>{row.subrole.name}</b>
                                  </Typography>
                                ) : null}
                              </Box>
                            </Stack>
                            <Popover
                              id={`info_${row._id}`}
                              open={anchorEl.id === `info_${row._id}`}
                              anchorEl={anchorEl.target}
                              onClose={() => setAnchorEl({ id: null, target: null })}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <List>
                                {row.contact ? (
                                  <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <PhoneAndroidIcon />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={`+${row?.country_code} ${row.contact}`}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                ) : null}
                                {row.email ? (
                                  <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <AlternateEmailIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`${row.email}`} />
                                    </ListItemButton>
                                  </ListItem>
                                ) : null}
                                {/* {row.state ? (
                                      <ListItem disablePadding>
                                        <ListItemButton>
                                          <ListItemIcon>
                                            <LocationCityIcon />
                                          </ListItemIcon>
                                          <ListItemText primary={`${row.state}`} />
                                        </ListItemButton>
                                      </ListItem>
                                    ) : null} */}
                                {row.address ? (
                                  <ListItem disablePadding>
                                    <ListItemButton component="a" href="#simple-list">
                                      <ListItemIcon>
                                        <ContactsIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`${row.address}`} />
                                    </ListItemButton>
                                  </ListItem>
                                ) : null}
                              </List>
                            </Popover>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {row?.tag?.length > 0 ? row?.tag : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center">
                            <Avatar
                              onClick={() => setImageSrc(row?.managers[0]?.avatar)}
                              src={row?.managers[0]?.avatar}
                              sx={{ cursor: 'pointer' }}
                            />
                            &nbsp;
                            <Typography noWrap>
                              {row?.managers[0]?.name}&nbsp;
                              {row.role.priority !== 1 && row.role.priority <= siteManagerPriority && (
                                <EditIcon
                                  onClick={(e) =>
                                    setAnchorEl({
                                      ...anchorEl,
                                      id: `update_emp_manager_${row._id}`,
                                      target: e.currentTarget
                                    })
                                  }
                                  sx={{ fontSize: 15, cursor: 'pointer' }}
                                />
                              )}
                            </Typography>
                            <UpdateEmpManagers
                              emp={row}
                              anchorEl={anchorEl}
                              setAnchorEl={setAnchorEl}
                            />
                            &nbsp;
                            <Typography
                              onClick={(e) =>
                                setAnchorEl({
                                  ...anchorEl,
                                  id: `managers-${row._id}`,
                                  target: e.currentTarget
                                })
                              }
                              sx={{
                                color: '#0897FF',
                                fontSize: 12,
                                minWidth: 60,
                                cursor: 'pointer'
                              }}
                            >
                              <b>{row.managers.length > 1 && `+${row.managers.length - 1} more`}</b>
                            </Typography>
                          </Stack>
                          <Popover
                            id={`managers-${row._id}`}
                            open={anchorEl.id === `managers-${row._id}`}
                            anchorEl={anchorEl.target}
                            onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left'
                            }}
                          >
                            <Stack sx={{ p: 2 }}>
                              {row.managers.slice(1, row.managers.length).map((man) => (
                                <Stack
                                  direction="row"
                                  jusitfyContent="center"
                                  alignItems="center"
                                  key={man._id}
                                  sx={{ mb: 1 }}
                                >
                                  <Avatar src={man.avatar} />
                                  &nbsp;
                                  <Typography>{man.name}</Typography>
                                </Stack>
                              ))}
                            </Stack>
                          </Popover>
                        </TableCell>
                        <TableCell>
                          {info.isMainAdmin || info?.role?.priority <= siteManagerPriority ? (
                            row?.sites.length > 0 ? (
                              <Stack direction="row" alignItems="center" sx={{ minWidth: '5rem' }}>
                                <Typography noWrap sx={{ mr: 1 }}>
                                  {row.sites[0]?.name}
                                </Typography>
                                {/* <FormControl fullWidth>
                                      <InputLabel
                                        sx={{ ml: 2, mt: 1 }}
                                        variant="standard"
                                        htmlFor="assign_site"
                                      >
                                        Choose Site
                                      </InputLabel>
                                      <Select
                                        labelId="assign_site_label"
                                        value={
                                          siteName[row._id] ?? row.sites.map((site) => site._id)
                                        }
                                        multiple
                                        inputProps={{
                                          name: 'assign_site',
                                          id: `assign_site_${row._id}`
                                        }}
                                        renderValue={(selected) => (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              flexWrap: 'wrap',
                                              gap: 0.5,
                                              minWidth: 300
                                            }}
                                          >
                                            {selected.map((value) => (
                                              <Chip
                                                sx={{
                                                  color: `${
                                                    row.sites
                                                      .map((site) => site._id)
                                                      .includes(value)
                                                      ? 'green'
                                                      : 'red'
                                                  }`
                                                }}
                                                key={value}
                                                label={siteDict[value]}
                                              />
                                            ))}
                                          </Box>
                                        )}
                                        input={
                                          <OutlinedInput
                                            id="assign_site_label"
                                            label="Choose Site"
                                          />
                                        }
                                        sx={{ mt: 2 }}
                                        disabled={selected.length > 0}
                                        onChange={(event) => {
                                          const {
                                            target: { value }
                                          } = event;
                                          // setSiteName(
                                          //   // On autofill we get a stringified value.
                                          //   typeof value === 'string' ? value.split(',') : value
                                          // );
                                          siteName[row._id] = value;
                                          setSiteName({ ...siteName });
                                        }}
                                      >
                                        <MenuItem value="" disabled>
                                          Choose Site
                                        </MenuItem>
                                        {siteOptions?.length > 0 ? (
                                          siteOptions
                                            // .filter((site) => {
                                            //   if (row.sites.length > 0) {
                                            //     return site[2] === _siteManager[row.sites[0]];
                                            //   }
                                            //   if (siteName[row._id]?.length > 0) {
                                            //     return site[2] === _siteManager[siteName[row._id][0]];
                                            //   }
                                            //   return true;
                                            // })
                                            .map(([key, value]) => (
                                              <MenuItem
                                                style={{ padding: '0.5rem' }}
                                                key={`${'assign_site'}-${key}`}
                                                value={key}
                                                name={value}
                                              >
                                                {value}
                                              </MenuItem>
                                            ))
                                        ) : (
                                          <MenuItem value="" disabled />
                                        )}
                                        <Box value="" sx={{ mt: 1, px: 1 }}>
                                          <Button
                                            sx={{ width: '100%' }}
                                            // onClick={() => {
                                            //   Swal.fire({
                                            //     title: 'Are you sure?',
                                            //     text: `${row.name} will get added to Selected Sites!`,
                                            //     icon: 'question',
                                            //     showCancelButton: true,
                                            //     confirmButtonText: 'Yes, Proceed!',
                                            //     allowOutsideClick: false
                                            //   }).then((result) => {
                                            //     if (result.isConfirmed) {
                                            //       assignSiteMutation({
                                            //         sites: siteName[row._id],
                                            //         employees: [row._id],
                                            //         isMultiple: true
                                            //       });
                                            //     } else if (
                                            //       result.dismiss === Swal.DismissReason.cancel
                                            //     ) {
                                            //       cache.invalidateQueries();
                                            //     }
                                            //   });
                                            // }}
                                          >
                                            Assign
                                          </Button>
                                        </Box>
                                      </Select>
                                    </FormControl> */}
                                {row.sites.length > 1 && (
                                  <Typography
                                    sx={{
                                      color: '#0897FF',
                                      fontSize: 12,
                                      minWidth: 60,
                                      cursor: 'pointer'
                                    }}
                                  >
                                    <b>{row.sites.length > 1 && `+${row.sites.length - 1} more`}</b>
                                  </Typography>
                                )}
                                <Tooltip title="View / Update Sites">
                                  <IconButton>
                                    <PublishedWithChangesIcon
                                      onClick={() => setAssignSelectedEmp(row)}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            ) : (
                              <Typography>NA</Typography>
                            )
                          ) : (
                            <Stack sx={{ minWidth: 150 }} direction="row" alignItems="center">
                              {/* <Avatar src={row?.managers[0]?.avatar} />
                                &nbsp; */}
                              {row?.sites[0]?.name}
                              &nbsp;
                              <Typography
                                onClick={(e) =>
                                  setAnchorEl({
                                    ...anchorEl,
                                    id: `sites-${row._id}`,
                                    target: e.currentTarget
                                  })
                                }
                                sx={{
                                  color: '#0897FF',
                                  fontSize: 12,
                                  minWidth: 60,
                                  cursor: 'pointer'
                                }}
                              >
                                <b>{row.sites.length > 1 && `+${row.sites.length - 1} more`}</b>
                              </Typography>
                              <Popover
                                id={`sites-${row._id}`}
                                open={anchorEl.id === `sites-${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left'
                                }}
                              >
                                <Stack sx={{ p: 1 }}>
                                  {row.sites.slice(1, row.sites.length).map((site) => (
                                    <Stack
                                      direction="row"
                                      jusitfyContent="center"
                                      alignItems="center"
                                      key={`${site._id}-${row._id}-option`}
                                      sx={{ mb: 1 }}
                                    >
                                      {/* <Avatar src={site.avatar} />
                                      &nbsp; */}
                                      <Typography>{site.name}</Typography>
                                    </Stack>
                                  ))}
                                </Stack>
                              </Popover>
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell align="left" sx={{ minWidth: 120 }}>
                          {DD_MM_YYYY(row.doj)}
                        </TableCell>
                        <TableCell align="center">
                          <Stack direction="row" justifyContent="center" alignItems="center">
                            <DescriptionIcon
                              sx={{ cursor: 'pointer' }}
                              color="primary"
                              onClick={(event) =>
                                setAnchorDocumentsEl({
                                  id: `documents_${row._id}`,
                                  target: event.currentTarget
                                })
                              }
                            />
                            <Popover
                              id={`documents_${row._id}`}
                              open={anchorDocumentsEl.id === `documents_${row._id}`}
                              anchorEl={anchorDocumentsEl.target}
                              onClose={() => setAnchorDocumentsEl({ id: null, target: null })}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                              }}
                            >
                              <List>
                                <ListItem disablePadding>
                                  <Tooltip
                                    placement="left"
                                    title={`${row?.tenth ? 'Available' : 'Not Available'}`}
                                  >
                                    <ListItemButton component="a">
                                      <ListItemIcon>
                                        <InsertDriveFileIcon
                                          sx={{ color: `${row?.tenth ? 'green' : 'red'}` }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary="10TH CIRTIFICATE" />
                                    </ListItemButton>
                                  </Tooltip>
                                </ListItem>
                                <ListItem disablePadding>
                                  <Tooltip
                                    placement="left"
                                    title={`${row?.twelfth ? 'Available' : 'Not Available'}`}
                                  >
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <InsertDriveFileIcon
                                          sx={{ color: `${row?.twelfth ? 'green' : 'red'}` }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary="12TH CIRTIFICATE" />
                                    </ListItemButton>
                                  </Tooltip>
                                </ListItem>
                                <ListItem disablePadding>
                                  <Tooltip
                                    placement="left"
                                    title={`${row?.degree ? 'Available' : 'Not Available'}`}
                                  >
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <SchoolIcon
                                          sx={{ color: `${row?.degree ? 'green' : 'red'}` }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary="DEGREE" />
                                    </ListItemButton>
                                  </Tooltip>
                                </ListItem>
                                <ListItem disablePadding>
                                  <Tooltip
                                    placement="left"
                                    title={`${row?.pan ? 'Available' : 'Not Available'}`}
                                  >
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <ContactsIcon
                                          sx={{ color: `${row?.pan ? 'green' : 'red'}` }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary="PAN CARD" />
                                    </ListItemButton>
                                  </Tooltip>
                                </ListItem>
                                <ListItem disablePadding>
                                  <Tooltip
                                    placement="left"
                                    title={`${row?.aadhar ? 'Available' : 'Not Available'}`}
                                  >
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <ContactsIcon
                                          sx={{ color: `${row?.aadhar ? 'green' : 'red'}` }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary="AADHAR CARD" />
                                    </ListItemButton>
                                  </Tooltip>
                                </ListItem>
                                <ListItem disablePadding>
                                  <Tooltip
                                    placement="left"
                                    title={`${
                                      row?.driving_license ? 'Available' : 'Not Available'
                                    }`}
                                  >
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <ContactsIcon
                                          sx={{
                                            color: `${row?.driving_license ? 'green' : 'red'}`
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary="DRIVING LICENSE" />
                                    </ListItemButton>
                                  </Tooltip>
                                </ListItem>
                                <ListItem disablePadding>
                                  <Tooltip
                                    placement="left"
                                    title={`${row?.passport ? 'Available' : 'Not Available'}`}
                                  >
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <ContactsIcon
                                          sx={{ color: `${row?.passport ? 'green' : 'red'}` }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary="PASSPORT" />
                                    </ListItemButton>
                                  </Tooltip>
                                </ListItem>
                                <ListItem disablePadding>
                                  <Tooltip
                                    placement="left"
                                    title={`${row?.fitness ? 'Available' : 'Not Available'}`}
                                  >
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <LocalHospitalIcon
                                          sx={{ color: `${row?.fitness ? 'green' : 'red'}` }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary="MEDICAL CERTIFICATE" />
                                    </ListItemButton>
                                  </Tooltip>
                                </ListItem>
                              </List>
                            </Popover>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Stack sx={{ minWidth: 150 }}>
                            <FormControl fullWidth>
                              <InputLabel variant="standard" htmlFor="policy_type">
                                Choose Policy
                              </InputLabel>
                              <Select
                                defaultValue={row.policy ?? ''}
                                variant="standard"
                                inputProps={{
                                  name: 'policy_type',
                                  id: 'policy_type'
                                }}
                                sx={{ mt: 2 }}
                                onChange={(event) => {
                                  assignPolicyMutation.mutate({
                                    employee: row._id,
                                    today: getCurrentDate(),
                                    policy: event.target.value
                                  });
                                }}
                              >
                                <MenuItem value="" disabled>
                                  Choose Policy
                                </MenuItem>
                                {row.policyOptions?.length > 0 ? (
                                  row.policyOptions.map(([key, value]) => (
                                    <MenuItem
                                      style={{ padding: '0.5rem' }}
                                      key={`${'policy_type'}-${key}`}
                                      value={key}
                                    >
                                      {value}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem value="" disabled />
                                )}
                              </Select>
                            </FormControl>
                          </Stack>
                        </TableCell>
                        <TableCell align="right">
                          <EmployeeMoreMenu />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          {/* Put the scroll bar always on the bottom */}
          <InfiniteScroll
            dataLength={rows?.length}
            next={fetchNextEmployees}
            // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
            // inverse //
            hasMore={hasNextEmployees}
            loader={
              <Stack direction="row" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Stack>
            }
            scrollableTarget="employees-table-container"
            // endMessage={
            //   <p style={{ textAlign: 'center' }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
          />
        </TableContainer>
      </Container>
      <AddEmployee step={null} open={openAddEmployee} onClose={() => setOpenAddEmployee(false)} />
      <AssignSiteEmployee
        selectedEmp={assignSelectedEmp}
        open={assignSelectedEmp !== null}
        onClose={() => setAssignSelectedEmp(null)}
      />
      <ImageZoom src={imageSrc} onClose={() => setImageSrc(null)} />
    </Page>
  );
}
