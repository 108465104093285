import { filter } from 'lodash';
import * as Yup from 'yup';
// import { Icon } from '@iconify/react';
// import { sentenceCase } from 'change-case';
import {
  useState,
  useMemo
  // useEffect
} from 'react';
// import { useDebounce } from 'debounce';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import {
  useFormik
  // FormikProvider,
  // Form
} from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// import plusFill from '@iconify/icons-eva/plus-fill';
import {
  // Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
// icons
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
// import CloseIcon from '@mui/icons-material/Close';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ContactsIcon from '@mui/icons-material/Contacts';
// hooks
import useSites from '../hooks/get-sites-query';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
import { SiteListHead, SiteHistoryListToolbar } from '../components/_dashboard/site';
// utils
import {
  DD_MM_YYYY,
  convertUTCtoLocal,
  setDataInStorage,
  getDataFromStorage
  // getCurrentDate
} from '../utils/functions';
// Mutations
// import AddSite from '../components/_dashboard/site/AddSite';
// import { assignPolicy } from '../api/mutations/policy';
// redux
import { logOut } from '../redux/actions/adminActions';
import { deleteSite } from '../api/mutations/adminMutations';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'site', label: 'Site', alignRight: false },
  // { id: 'shift', label: 'Shift', alignRight: false },
  { id: 'manager', label: 'Manager', alignRight: false },
  { id: 'added_on', label: 'Added On', alignRight: false },
  { id: 'added_by', label: 'Added By', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false }
  // { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const navigate = useNavigate();
  const [
    page
    // setPage
  ] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [
    rowsPerPage
    // setRowsPerPage
  ] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });

  //   const [openAddSite, setOpenAddSite] = useState(false);
  const selectedEmployee = getDataFromStorage('selected_employee');

  const dispatch = useDispatch();
  const cache = useQueryClient();
  // const [searchValue, setSearchValue] = useState();
  // const { search } = useDebounce(searchValue, 1000); // Debounce value 1 sec

  const [filter, setFilter] = useState({
    range: [],
    state: null,
    status: null,
    role: '',
    manager: null
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  /* ===================== Sites ===================== */

  const {
    isLoading,
    isError,
    data: sites,
    fetchNextPage: fetchNextSites,
    hasMoreData: hasNextSites
    // refetch: refetchSites
  } = useSites(
    filter.range?.length > 0 ? convertUTCtoLocal(filter.range[0], true) : null, // from
    filter.range?.length > 0 ? convertUTCtoLocal(filter.range[1], true) : null, // to
    null, // type
    filter.state,
    filter.status,
    filter.manager,
    // filter.role,
    null,
    null, // search
    selectedEmployee._id
  );

  const rows = useMemo(() => {
    const listOfSites = [];
    if (!isLoading && !isError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Sites :', flattened);
    return flattened;
  }, [sites, isLoading, isError]);

  /* ================= End Sites =============== */

  const { mutate } = useMutation(deleteSite, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Deleting Sites 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    }
  });

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    initialValues: {
      sites: []
    },
    validationSchema: Yup.object(),
    onSubmit: async (values, actions) => {
      console.log(values);
      actions.setStatus(null);
      const data = {
        sites: selected
      };

      // console.log('Site Add Form :', data);

      mutate(data, {
        onError: (error) => {
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false
            // timer: 2000,
            // timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          actions.setSubmitting(false);
          resetForm();
          values.profilePicture = '';
          Swal.fire({
            icon: 'success',
            title: 'Sites Deleted!',
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          cache.invalidateQueries('sites');
        }
      });
    }
  });

  const {
    // errors,
    // touched,
    // values,
    // isSubmitting,
    // handleSubmit,
    // getFieldProps,
    resetForm
  } = formik;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Sites | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1.5}>
          <Typography variant="h4" gutterBottom>
            Site History
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => setOpenAddSite(true)}
          >
            New Site
          </Button> */}
        </Stack>
        <Card sx={{ boxShadow: 'none' }}>
          <SiteHistoryListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
          />
          <TableContainer
            id="sites-table-container"
            sx={{
              minWidth: 800,
              maxHeight: 'calc(100vh - 275px)',
              minHeight: 'calc(100vh - 275px)'
            }}
          >
            <Table>
              <SiteListHead
                selectAll={false}
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan="9">
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )}
                {isError && (
                  <TableRow>
                    <TableCell>Something Went Wrong</TableCell>
                  </TableRow>
                )}
                {!isLoading &&
                  filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row._id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, row._id)}
                              />
                            </TableCell> */}
                          <TableCell>{(index += 1)}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar alt={row.name} src={row?.image} />
                              {/* <RouterLink
                                  style={{ textDecoration: 'none' }}
                                  // to="/site/profile"
                                  // state={{ site: row }}
                                  
                                > */}
                              <Tooltip
                                sx={{ fontSize: '1rem' }}
                                title={`Visit ${row.name}`}
                                placement="top"
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={{ cursor: 'pointer' }}
                                  noWrap
                                  onClick={() => {
                                    setDataInStorage('selected_site', row);
                                    navigate('/site/profile');
                                  }}
                                >
                                  {row.name}
                                </Typography>
                              </Tooltip>
                              {/* </RouterLink> */}
                              <InfoIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={(event) =>
                                  setAnchorEl({
                                    id: `info_${row._id}`,
                                    target: event.currentTarget
                                  })
                                }
                              />
                              <Popover
                                id={`info_${row._id}`}
                                open={anchorEl.id === `info_${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <List>
                                  {row.mobile && (
                                    <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <PhoneAndroidIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`+91 ${row.mobile}`} />
                                      </ListItemButton>
                                    </ListItem>
                                  )}
                                  {row.email && (
                                    <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <AlternateEmailIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`${row.email}`} />
                                      </ListItemButton>
                                    </ListItem>
                                  )}
                                  {row.state ? (
                                    <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <LocationCityIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`${row.state ?? 'Not Provided'}`} />
                                      </ListItemButton>
                                    </ListItem>
                                  ) : null}
                                  {row.address ? (
                                    <ListItem disablePadding>
                                      <ListItemButton component="a" href="#simple-list">
                                        <ListItemIcon>
                                          <ContactsIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={`${row.address ?? 'Not Provided'}`}
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  ) : null}
                                </List>
                              </Popover>
                            </Stack>
                          </TableCell>
                          {/* <TableCell align="left">{row?.shift?.length ?? 0}</TableCell> */}
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar alt={row.name} src={row.manager.avatar} />
                              <Typography variant="subtitle2" noWrap>
                                {row.manager.name}
                              </Typography>
                              {/* <InfoIcon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(event) =>
                                    setAnchorEl({
                                        id: `info_${row._id}`,
                                        target: event.currentTarget
                                    })
                                    }
                              /> */}
                            </Stack>
                          </TableCell>
                          <TableCell>{DD_MM_YYYY(row.createdAt)}</TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar alt={row.name} src={row.addedBy.avatar} />
                              <Typography variant="subtitle2" noWrap>
                                {row.addedBy.name}
                              </Typography>
                              {/* <InfoIcon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(event) =>
                                    setAnchorEl({
                                        id: `info_${row._id}`,
                                        target: event.currentTarget
                                    })
                                    }
                              /> */}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {row?.status === 'Verified' ? (
                              <Chip icon={<CheckIcon />} label="Verified" />
                            ) : (
                              <Chip label="Not Verified" />
                            )}
                          </TableCell>
                          {/* <TableCell align="right">
                              <SiteMoreMenu />
                            </TableCell> */}
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            {/* Put the scroll bar always on the bottom */}
            <InfiniteScroll
              dataLength={rows?.length}
              next={fetchNextSites}
              // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
              // inverse //
              hasMore={hasNextSites}
              loader={<h4>Loading...</h4>}
              scrollableTarget="sites-table-container"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            />
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
      {/* <AddSite open={openAddSite} onClose={() => setOpenAddSite(false)} /> */}
    </Page>
  );
}
