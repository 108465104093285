/* eslint-disable no-nested-ternary */
// import { filter } from 'lodash';
import React, { useState, useMemo } from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useDebounce } from 'use-debounce';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// icons
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import { flatten } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import PaySlipTemplate from './PaySlipTemplate';
import NotAvailable from '../components/NotAvailable';
import { PaySlipListToolbar } from '../components/_dashboard/payslip';
// hooks
import { usePaySlips } from '../hooks/get-payslips';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
// import SearchNotFound from '../components/SearchNotFound';
// utils
import { SEARCH_DEBOUNCE } from '../utils/config';
import { convertUTCtoLocal } from '../utils/functions';

// ---------------------------------------------------------

const TABLE_HEAD = [
  { id: 'site', label: 'Site', alignRight: false },
  { id: 'feedback', label: 'Feedback', alignRight: false },
  { id: 'added_on', label: 'Added On', alignRight: false },
  { id: 'added_by', label: 'Added By', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// --------------------------------------------------------------

function SitesFeedbacks() {
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [search] = useDebounce(filterName, SEARCH_DEBOUNCE); // Debounce value 1.2 sec
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    site: '',
    date: new Date(),
    employee: ''
  });

  const {
    isLoading: isPaySlipsLoading,
    isError: isPaySlipsError,
    hasNextPage: hasNextPaySlips,
    fetchNextPage: fetchNextPaySlips,
    data: payslips
  } = usePaySlips(search, convertUTCtoLocal(filter?.date, true));

  const rows = useMemo(() => {
    const listOfPaySlips = [];
    if (!isPaySlipsLoading && !isPaySlipsError) {
      if (payslips?.pages?.length > 0) {
        payslips.pages.forEach((group) => {
          listOfPaySlips.push(group.payslips);
        });
      }
    }
    const flattened = listOfPaySlips.flat();
    return flattened;
  }, [payslips, isPaySlipsLoading, isPaySlipsError]);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const filteredUsers = rows;

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Pay Slip | Trackkar">
      <Container>
        <Stack direction="row" sx={{ px: 2 }} alignItems="center" justifyContent="space-between">
          <PaySlipListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiDatePicker
              views={['year', 'month']}
              label="Year and Month"
              minDate={new Date('2012-03-01')}
              // maxDate={new Date('2023-06-01')}
              value={filter.date}
              onChange={(newValue) => {
                setFilter({ ...filter, date: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ '& input': { padding: '9.5px 14px' } }}
                  {...params}
                  helperText={null}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
        <Stack
          id="payslips-container"
          sx={{ height: 'calc(100vh - 11rem)', overflow: 'auto', px: 2 }}
          spacing={1}
        >
          {isPaySlipsLoading ? (
            <NotAvailable type="loading" />
          ) : isPaySlipsError ? (
            <NotAvailable type="na" msg="Something went wrong!" />
          ) : rows?.length > 0 ? (
            rows.map((row, idx) => <PaySlipTemplate key={`${row._id}-${idx}`} data={row} />)
          ) : (
            <NotAvailable type="na" msg="No PaySlip available for this month" />
          )}
          {/* Put the scroll bar always on the bottom */}
          <InfiniteScroll
            dataLength={rows?.length}
            next={fetchNextPaySlips}
            // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
            // inverse //
            hasMore={hasNextPaySlips}
            loader={
              <Stack direction="row" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Stack>
            }
            scrollableTarget="payslips-container"
            // endMessage={
            //   <p style={{ textAlign: 'center' }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
          />
        </Stack>
      </Container>
    </Page>
  );
}

export default SitesFeedbacks;
