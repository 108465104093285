import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import { TRACKKAR_LOGO } from '../utils/config';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  size: PropTypes.string
};

export default function Logo({ sx, size, image }) {
  return size === 'large' ? (
    <Box
      component="img"
      src={image ?? '/static/images/trackkar_logo.png'}
      sx={{ width: 120, height: 40, ...sx }}
    />
  ) : (
    <Box component="img" src={image ?? '/static/logo.png'} sx={{ width: 40, height: 40, ...sx }} />
  );
}
