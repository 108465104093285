import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchAllLeads } from '../api/queries/Queries';

export const useLeads = (from, to, type, state, status, manager, search) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(
    ['leads', from, to, type, state, status, manager, search],
    fetchAllLeads,
    {
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};
