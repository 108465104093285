/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Stack,
  Link,
  Container,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { ResetPasswordForm } from '../components/authentication/login';
// import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  return (
    <RootStyle title="Reset Password | Trackkar">
      <AuthLayout>
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          Login
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle sx={{ px: 2, borderRadius: 0, m: 0 }}>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Reset Password
          </Typography>
          <img src="/static/illustrations/illustration_reset_password.png" alt="reset_password" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 2 }}>
            {query.get('with') === 'email' ? (
              <>
                <Typography variant="h4" gutterBottom>
                  Reset Password with Email
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Password reset link will be send through email
                </Typography>
              </>
            ) : query.get('with') === 'mobile' ? (
              <>
                <Typography variant="h4" gutterBottom>
                  Reset Password with Mobile
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  OTP will be send to registered mobile number
                </Typography>
              </>
            ) : (
              <Typography variant="h4" gutterBottom>
                Verification Required
              </Typography>
            )}
          </Stack>
          {/* <AuthSocial /> */}

          {query.get('with') ? (
            <ResetPasswordForm option={query.get('with')} />
          ) : (
            <List>
              <ListItem disablePadding>
                <ListItemButton component={RouterLink} to="/reset-password?with=email">
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary="Verify With Email" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={RouterLink} to="/reset-password?with=mobile">
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Verify With Mobile" />
                </ListItemButton>
              </ListItem>
            </List>
          )}

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              <Link variant="subtitle2" component={RouterLink} to="login">
                Login
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
