import { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
//
import DashboardNavbar from '../dashboard/DashboardNavbar';
import EmployeeSidebar from './EmployeeSidebar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 60;
const APP_BAR_DESKTOP = 80;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE, // + 24
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP, // + 24
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function SiteLayout() {
  const [open, setOpen] = useState(false);
  const { isAdminAuthenticated } = useSelector((state) => state.adminInfo);
  if (!isAdminAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <EmployeeSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
