/* eslint-disable no-nested-ternary */
import { useState, useEffect, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
// import * as Yup from 'yup';
import PropTypes from 'prop-types';
// material
import { styled, useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
  // Link,
  Box,
  Stack,
  // TextField,
  Button,
  Divider,
  // Tooltip,
  Paper,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  Chip,
  Grid,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  InputAdornment,
  Typography,
  OutlinedInput,
  Tooltip
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import EventIcon from '@mui/icons-material/Event';
import CloseIcon from '@mui/icons-material/Close';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
import FilterListIcon from '@mui/icons-material/FilterList';
import ContactsIcon from '@mui/icons-material/Contacts';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import { useDebounce } from 'use-debounce';
import NotAvailable from '../components/NotAvailable';
// import classes from './styles/ImportEmployees.module.css';
// hooks
import { useUnmanagedEmployees, useEmployeesCards } from '../hooks/employees';
import UploadEmployees from '../components/_dashboard/import_employee/UploadEmployees';
import { useRoles } from '../hooks/roles';
import useSites from '../hooks/get-sites-query';
// import { useManagers } from '../hooks/get-managers';
import { useSubRoles } from '../hooks/subRoles';
import AddEmployee from '../components/_dashboard/employee/AddEmployee';
import EditHeirarchy from '../components/EditHeirarchy';
// utils
import {
  DD_MM_YYYY,
  // excelToJSON,
  // capitalizeEachWord,
  getCurrentDate
  // getDataFromStorage
  // setDataInStorage
} from '../utils/functions';
import { SWAL_TIME } from '../utils/config';
import {
  // assignRole,
  assignSite
  // assignManager,
  // deleteSite,
  // importEmployees
} from '../api/mutations/adminMutations';
import { useSiteShifts } from '../hooks/roster';
// redux
// import callSocket from '../utils/socket';
import { logOut } from '../redux/actions/adminActions';

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 500,
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9'
//   }
// }));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 35,
  marginTop: 4,
  // transition: theme.transitions.create(['box-shadow', 'width'], {
  //   easing: theme.transitions.easing.easeInOut,
  //   duration: theme.transitions.duration.shorter
  // }),
  // '&.Mui-focused': { width: 240, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

Step5.propTypes = {
  heirarchy: PropTypes.array
};

export default function Step5({ heirarchy }) {
  const [filterName, setFilterName] = useState('');
  const [search] = useDebounce(filterName, 1000); // Debounce value 1 sec
  const [selected, setSelected] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [filter, setFilter] = useState({
    role: '',
    subRole: ''
  });
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const theme = useTheme();
  // const selectRoleRef = useRef();
  // const [isImportingEmployees, setIsImportingEmployees] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]); // Roles initial State for select
  const [siteOptions, setSiteOptions] = useState([]);
  const [allAvailableRoles, setAllAvailableRoles] = useState([]);
  // const [role, setRole] = useState('');
  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const [subFRoleOptions, setFSubRoleOptions] = useState([]);
  const [subRoleOptions, setSubRoleOptions] = useState([]);
  const [viewHeirarchy, setViewHeirarchy] = useState(true);
  // const [siteName, setSiteName] = useState({});
  const [siteDict, setSiteDict] = useState({});
  const [siteManager, setSiteManager] = useState({});

  // -----------------------------------------------------------------
  // const [importCheck, setImportCheck] = useState({
  //   importId: getDataFromStorage('importId') ?? null,
  //   importLength: getDataFromStorage('importLength') ?? null
  // });
  // const [isValidating, setIsValidating] = useState(''); // Upon excel validation
  // const [inMutate, setInMutate] = useState(false);
  // const { info } = useSelector((state) => state.adminInfo);
  // store site information
  const [siteSelected, setSiteSelected] = useState({
    indx: 0,
    id: ''
  });
  // ------------------------------------------------------------------

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const [assign, setAssign] = useState([]);

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  // ----------------------------------------------------------------

  const {
    isLoading: isSiteWorkforceLoading,
    isError: isSiteWorkforceError,
    data: siteWorkforce
  } = useEmployeesCards('sites', siteSelected?.id); // getting employees cards

  useEffect(() => {
    console.log('Site Workforce:', siteWorkforce);
    console.log('Site Selected:', siteSelected);
  }, [siteWorkforce, siteSelected]);

  const handleNext = (index) => {
    if (siteSelected.indx === AllSiteShifts[index + 1] && hasNextSiteShifts) {
      fetchNextSiteShifts();
    } else {
      setSiteSelected({
        ...siteSelected,
        indx: index + 1,
        id: AllSiteShifts[index + 1]?._id
      });
    }
  };

  const handlePrev = (index) => {
    if (index > 0) {
      setSiteSelected({
        ...siteSelected,
        indx: index - 1,
        id: AllSiteShifts[index - 1]?._id
      });
    }
  };

  // -----------------------------------------------------------------

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  useEffect(() => {
    const allAvailableRoles = [];
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      let priority = 0;
      allRoles.forEach((role) => {
        allAvailableRoles.push([role._id, role.name]);
        if (role.is_site_manager) {
          priority = role.priority;
        }
        if (role.priority > priority && priority > 0) {
          formattedRoles.push([role._id, role.name]);
        }
      });
    }
    setRoleOptions([...formattedRoles]);
    setAllAvailableRoles([...allAvailableRoles]); // All Available Roles in Heirarchy
    // console.log('Role Options :', formattedRoles);
  }, [allRoles]);

  /* ================= End Roles =============== */

  /* ================== Unemployees ========================== */
  const {
    isLoading: isImportEmployeesLoading,
    isError: isImportEmployeesError,
    data: unemployees,
    fetchNextPage: fetchNextImportemployees,
    hasNextPage: hasNextImportemployees
  } = useUnmanagedEmployees(
    null,
    search,
    filter.state, // unemployeeActualFilter.state,
    null,
    filter.role, // unemployeeActualFilter.role
    filter.subRole,
    false
  );

  const rows = useMemo(() => {
    const listOfEmployees = [];
    if (!isImportEmployeesLoading && !isImportEmployeesError) {
      if (unemployees?.pages?.length > 0) {
        unemployees.pages.forEach((group) => listOfEmployees.push(group.employees));
      }
    }
    const flattened = listOfEmployees.flat();
    // console.log('Import Employees:', flattened);
    return flattened;
  }, [unemployees, isImportEmployeesLoading, isImportEmployeesError]);

  const handleClick = (event, name) => {
    setSelectedRole(rows.find((r) => r._id === name).role._id);
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  /* ================= End Unmanaged Employees =============== */

  /* ===================== Sites ===================== */

  const {
    isLoading,
    isError,
    data: sites
    // fetchNextPage: fetchNextSites,
    // hasMoreData: hasNextSites
    // refetch: refetchSites
  } = useSites(
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[0], true) : null, // from
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[1], true) : null, // to
    null, // filter.date.length > 0 ? filter.date : getCurrentDate(),
    null, // type
    null, // filter.state, // state
    null, // filter.status, // status
    null, // filter.manager, // manager
    null, // filter.role,
    null, // search
    null
  );

  const allSites = useMemo(() => {
    const listOfSites = [];
    if (!isLoading && !isError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Sites :', flattened);
    return flattened;
  }, [sites, isLoading, isError]);

  useEffect(() => {
    const formattedSites = [];
    if (allSites?.length > 0) {
      allSites.forEach((site) => {
        if (site.active === 'Active') {
          siteManager[site._id] = site.manager._id;
          siteDict[site._id] = site.name;
          formattedSites.push([site._id, site.name, site.manager._id]);
        }
      });
      setSiteManager({ ...siteManager });
      setSiteDict({ ...siteDict }); // Site Value Names
      setSiteOptions([...formattedSites]);
      // console.log('Role Options :', formattedRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSites]);

  // eslint-disable-next-line consistent-return
  const selectedSite = useMemo(() => {
    if (selected.length > 0) {
      const sel = rows.find((emp) => emp._id === selected[0])?.sites[0] ?? '';
      return sel;
    }
  }, [rows, selected]);

  /* ================= End Sites =============== */

  /* ================= Site Shifts =================== */

  const {
    isLoading: isSiteShiftsLoading,
    isError: isSiteShiftsError,
    data: siteShifts,
    hasNextPage: hasNextSiteShifts,
    fetchNextPage: fetchNextSiteShifts
  } = useSiteShifts(null, null, null, getCurrentDate(), null, null);

  const AllSiteShifts = useMemo(() => {
    const listOfSiteShifts = [];
    if (!isSiteShiftsLoading && !isSiteShiftsError) {
      if (siteShifts?.pages?.length > 0) {
        siteShifts.pages.forEach((group) => listOfSiteShifts.push(group.sites));
      }
    }
    const flattened = listOfSiteShifts.flat();
    if (flattened.length > 0) {
      setSiteSelected({
        ...siteSelected,
        indx: 0,
        id: flattened[0]._id
      });
    }
    // console.log('Sites :', flattened);
    return flattened;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteShifts]);

  /* ================== Sub Roles ======================== */

  const {
    isLoading: isSubRolesLoading,
    isError: isSubRolesError,
    data: subRoles
  } = useSubRoles(filter?.role);

  useEffect(() => {
    const listOfSubRoles = [];
    const listOfFSubRoles = [];
    if (!isSubRolesLoading && !isSubRolesError) {
      if (subRoles?.roles?.length > 0) {
        subRoles.roles[0].subroles.forEach((role) => listOfFSubRoles.push([role._id, role.name]));
        subRoles.roles.forEach((role) => {
          const d = {};
          role.subroles.filter((s) => (d[s.name.toLowerCase()] = s._id));
          listOfSubRoles.push({
            id: role._id,
            subroles: d
          });
        });
      }
    }
    setSubRoleOptions([...listOfSubRoles]);
    setFSubRoleOptions([...listOfFSubRoles]); // sub role options for filter
    // console.log('Sub Roles By Role:', listOfSubRoles);
  }, [subRoles, isSubRolesLoading, isSubRolesError]);

  /* ================== End Sub Roles ====================== */

  /* ===================== Assign Role Mutations ==============  */
  // const assignRoleMutation = useMutation(assignRole, {
  //   onError: (error) => {
  //     // status: 401 unauthorized
  //     if (error.response && error.response.status === 401) {
  //       dispatch(logOut());
  //     }

  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Something went wrong!',
  //       text: error.response ? error.response.data.message || error.toString() : error.toString()
  //       // timer: 2000,
  //       // timerProgressBar: true
  //     });
  //   },
  //   onSuccess: () => {
  //     Swal.fire({
  //       icon: 'success',
  //       title: `Successfully assigned role`,
  //       showConfirmButton: false,
  //       timer: SWAL_TIME,
  //       timerProgressBar: true
  //     });
  //   },
  //   onSettled: () => {
  //     setSelected([]);
  //     cache.invalidateQueries('unmanaged-employees');
  //     cache.invalidateQueries('potential-site-members');
  //   }
  // });

  /* ===================== Assign Role Mutations END  ==============  */

  const { mutate: assignSiteMutation } = useMutation(assignSite, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Adding Members to site 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      setSelected([]);
      setAssign([]);
      Swal.fire({
        icon: 'success',
        title: `Successfully Added ${selected.length > 1 ? 'Employees' : 'Employee'} to Site`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries();
      // cache.invalidateQueries('unmanaged-employees');
    }
  });

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack direction="row" justifyContent="space-between" sx={{ px: 2, py: 1 }}>
        <Stack direction="row" alignContent="center">
          {rows.length > 0 && selected.length === 0 && (
            <UploadEmployees
              allRoles={allRoles}
              allAvailableRoles={allAvailableRoles}
              subRoleOptions={subRoleOptions}
            />
          )}
          &nbsp;&nbsp;
          {selected.length === 0 && (
            <Button
              variant="contained"
              component="span"
              startIcon={<AddIcon />}
              sx={{ boxShadow: 'none' }}
              onClick={() => setOpenAddEmployee(true)}
            >
              Employee
            </Button>
          )}
          &nbsp;
        </Stack>
        {selected.length > 0 && (
          <Typography sx={{ color: '#0897FF', fontWeight: 'bold' }}>
            {selected.length}&nbsp;Selected
          </Typography>
        )}
        <Stack direction="row">
          {selected.length > 0 && (
            // <Tooltip title="Assign Site">
            //   <IconButton
            //     onClick={(event) =>
            //       setAnchorEl({
            //         ...anchorEl,
            //         id: 'assign_role_manager_to_employees',
            //         target: event.currentTarget
            //       })
            //     }
            //   >
            //     <AddModeratorIcon />
            //   </IconButton>
            // </Tooltip>
            <Tooltip title="Assign to Site">
              <IconButton
                variant="contained"
                disabled={selected === 0}
                onClick={() => {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: `All selected employee will be added to ${
                      AllSiteShifts[siteSelected?.indx]?.name
                    }!`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Proceed!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setAnchorEl({ ...anchorEl, id: null, target: null });
                      assignSiteMutation({
                        sites: [siteSelected?.id], // all Selected Sites
                        employees: selected,
                        isMultiple: true
                      });
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      cache.invalidateQueries('unmanaged-employees');
                    }
                  });
                }}
              >
                <AddModeratorIcon />
              </IconButton>
            </Tooltip>
          )}
          &nbsp;
          {selected.length === 0 && (
            <IconButton
              onClick={(event) =>
                setAnchorEl({ ...anchorEl, id: 'filter_unemployees_step_3', target: event.target })
              }
            >
              <FilterListIcon />
            </IconButton>
          )}
          &nbsp;
          <Tooltip title="View Heirarchy">
            <IconButton onClick={() => setViewHeirarchy(!viewHeirarchy)}>
              <AccountTreeIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Popover
          id="filter_unemployees_step_3"
          open={anchorEl.id === 'filter_unemployees_step_3'}
          anchorEl={anchorEl.target}
          onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Stack spacing={1} width="320px" sx={{ p: 2 }}>
            <Stack direction="row" alignItems="center">
              <FilterListIcon />
              <Typography sx={{ ml: 1 }}>Filters</Typography>
            </Stack>
            <Divider />
            {/* Gender
            <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
              <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="gender_filter">
                  Choose Gender
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter.gender}
                  inputProps={{
                    name: 'gender',
                    id: 'gender_filter'
                  }}
                  onChange={(e) => {
                    setFilter({ ...filter, gender: e.target.value });
                  }}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Gender
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              &nbsp;
              {filter.gender ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, gender: '' });
                      setFilters(filter);
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box> */}
            {/* State
            <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
              <LocationCityIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="grouped-select">
                  Choose State
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter.state}
                  onChange={(e) => setFilter({ ...filter, state: e.target.value })}
                  id="grouped-select"
                  label="Choose State"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <ListSubheader>States</ListSubheader>
                  {INDIAN?.states?.length > 0 ? (
                    INDIAN?.states.map((key) => (
                      <MenuItem key={`${'state'}-${key}`} value={key}>
                        {key}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                  <ListSubheader>Union Territories</ListSubheader>
                  {INDIAN.union_territories?.length > 0 ? (
                    INDIAN.union_territories.map((key) => (
                      <MenuItem key={`${'territory'}-${key}`} value={key}>
                        {key}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              &nbsp;
              {filter.state ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, state: '' });
                      setFilters(filter);
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box> */}
            {/* Role */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="role_filter">
                  Choose Role
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter?.role}
                  inputProps={{
                    name: 'role_filter',
                    id: 'role_filter'
                  }}
                  onChange={(e) => setFilter({ ...filter, role: e.target.value })}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Role
                  </MenuItem>
                  {roleOptions?.length > 0 ? (
                    roleOptions.map(([key, value, image]) => (
                      <MenuItem key={`${'role_filter'}-${key}`} value={key} data-icon={image}>
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <>
                      <MenuItem value="" disabled />
                    </>
                  )}
                </Select>
              </FormControl>
              &nbsp;
              {filter.role ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, role: '' });
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box>
            {/* Sub Role */}
            {filter.role && subRoleOptions?.length > 0 ? (
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="role_type">
                    Choose Sub Role
                  </InputLabel>
                  <Select
                    variant="standard"
                    disabled={!(filter.role && subRoleOptions.length > 0)}
                    value={filter.subRole}
                    inputProps={{
                      name: 'sub_role_type',
                      id: 'sub_role_type'
                    }}
                    required
                    sx={{ mt: 2 }}
                    onChange={(e) => setFilter({ ...filter, subRole: e.target.value })}
                    // error={Boolean(touched.sub_role_type && errors.sub_role_type)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Choose Sub Role
                    </MenuItem>
                    {subFRoleOptions?.length > 0 ? (
                      subFRoleOptions.map(([key, value, image]) => (
                        <MenuItem key={`${'sub_role_type'}-${key}`} value={key} data-icon={image}>
                          {value}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled />
                    )}
                  </Select>
                </FormControl>
              </Box>
            ) : null}
            {/* Manager
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="manager_filter">
                  Choose Manager
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter.manager}
                  inputProps={{
                    name: 'manager_filter',
                    id: 'manager_filter'
                  }}
                  onChange={(e) => setFilter({ ...filter, manager: e.target.value })}
                  disabled={!filter?.role}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Manager
                  </MenuItem>
                  {managerOptions?.length > 0 ? (
                    managerOptions.map(([key, value, image]) => (
                      <MenuItem
                        style={{ padding: '0.5rem' }}
                        key={`${'manager_filter'}-${key}`}
                        value={key}
                        data-icon={image}
                      >
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled />
                  )}
                </Select>
              </FormControl>
              &nbsp;
              {filter.manager ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, manager: '' });
                      setFilters(filter);
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box> */}
            {/* Policy
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <StickyNote2Icon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="policy_filter">
                  Choose Policy
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter.policy}
                  inputProps={{
                    name: 'policy_filter',
                    id: 'policy_filter'
                  }}
                  onChange={(e) => setFilter({ ...filter, policy: e.target.value })}
                  disabled={!filter?.role}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Policy
                  </MenuItem>
                  {policyOptions?.length > 0 ? (
                    policyOptions.map(([key, value, image]) => (
                      <MenuItem
                        style={{ padding: '0.5rem' }}
                        key={`${'policy_type'}-${key}`}
                        value={key}
                        data-icon={image}
                      >
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled />
                  )}
                </Select>
              </FormControl>
              &nbsp;
              {filter.policy ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, policy: '' });
                      setFilters(filter);
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box> */}
          </Stack>
        </Popover>
      </Stack>
      {isSiteShiftsLoading && isSiteWorkforceLoading ? (
        <Stack sx={{ height: 'calc(100vh - 20rem)' }}>
          <NotAvailable type="loading" msg="Loading..." />
        </Stack>
      ) : isSiteShiftsError && isSiteWorkforceError ? (
        <Stack sx={{ height: 'calc(100vh - 20rem)' }}>
          <NotAvailable type="na" msg="Something Went Wrong!" />
        </Stack>
      ) : (
        <Stack>
          <Stack
            sx={{ width: '100%', py: 1 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              disabled={siteSelected?.indx === 0}
              onClick={() => handlePrev(siteSelected.indx)}
            >
              Prev Site
            </Button>
            <Typography>
              <b>{AllSiteShifts[siteSelected?.indx]?.name.toUpperCase()}</b>
            </Typography>
            <Button
              disabled={!(siteSelected?.indx < AllSiteShifts.length - 1)}
              onClick={() => handleNext(siteSelected?.indx)}
            >
              Next Site
            </Button>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Stack
                className="custom_acrollbar"
                style={{ border: '2px solid #0897FF', borderRadius: 8 }}
                sx={{ p: 1, mt: 1 }}
              >
                <Typography
                  sx={{
                    mt: -2.5,
                    px: 2,
                    backgroundColor: 'white',
                    width: 'calc(100vh - 25rem)',
                    color: '#0897FF'
                  }}
                >
                  <b>Requirements</b>
                </Typography>
                <Box
                  className="custom_scrollbar"
                  sx={{ height: 'calc(100vh - 19rem)', overflowY: 'auto' }}
                >
                  {AllSiteShifts[siteSelected?.indx]?.requirement?.length > 0 ? (
                    AllSiteShifts[siteSelected?.indx].requirement.map((rq) => {
                      const availRole = siteWorkforce?.employees?.find(
                        (emp) => emp.role === rq._id
                      );
                      return rq.subroles.length > 0 ? (
                        rq.subroles.map((srq) => {
                          const avail = availRole?.subroles?.find((sr) => sr.subrole === srq._id);
                          return (
                            <Stack
                              direction="row"
                              onClick={() =>
                                setFilter({ ...filter, role: rq._id, subRole: srq._id })
                              }
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{
                                p: 1,
                                my: 1,
                                cursor: 'pointer',
                                boxShadow: theme.customShadows.bloated,
                                border: `${
                                  filter.role === rq._id && filter.subRole === srq._id
                                    ? '1px solid #333'
                                    : ''
                                }`
                              }}
                              key={`${AllSiteShifts[siteSelected?.indx]._id}-${srq._id}`}
                            >
                              <Typography sx={{ color: theme.palette.role.main }}>
                                {rq.name}
                                <Typography
                                  sx={{ ml: 2, color: theme.palette.subrole.main }}
                                  component="span"
                                >
                                  {srq.name}
                                </Typography>
                              </Typography>
                              <Typography>
                                {avail?.total ?? 0} / {srq.value}
                              </Typography>
                            </Stack>
                          );
                        })
                      ) : (
                        <Stack
                          direction="row"
                          onClick={() => setFilter({ ...filter, role: rq._id, subRole: undefined })}
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ p: 1, boxShadow: theme.customShadows.bloated }}
                          key={`${AllSiteShifts[siteSelected?.indx]._id}-${rq._id}`}
                        >
                          <Typography>{rq.name}</Typography>
                          <Typography>
                            {availRole?.total ?? 0} / {rq.value}
                          </Typography>
                        </Stack>
                      );
                    })
                  ) : (
                    <NotAvailable type="na" msg="No Requirements Provided!" />
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Stack style={{ border: '2px solid #0897FF', borderRadius: 8 }} sx={{ p: 1, mt: 1 }}>
                <Typography
                  sx={{
                    mt: -2.5,
                    px: 2,
                    backgroundColor: 'white',
                    width: 'calc(100vh - 25rem)',
                    color: '#0897FF'
                  }}
                >
                  <b>Employees</b>
                </Typography>
                <SearchStyle
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  placeholder="Search Employee..."
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  }
                />
                <TableContainer
                  className="custom_scrollbar"
                  id="step_5_import_employees"
                  sx={{ height: 'calc(100vh - 21rem)' }}
                  component={Paper}
                >
                  <Table stickyHeader sx={{ minWidth: 450 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Employee</TableCell>
                        <TableCell>Gender</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isImportEmployeesLoading ? (
                        <TableRow>
                          <TableCell align="center" colSpan="3">
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      ) : isImportEmployeesError ? (
                        <TableRow>
                          <TableCell align="center" colSpan="3">
                            Something Went Wrong
                          </TableCell>
                        </TableRow>
                      ) : rows?.length > 0 ? (
                        rows
                          .sort((a, b) => a.role.priority - b.role.priority)
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            const isItemSelected = selected.indexOf(row?._id) !== -1;
                            return (
                              <TableRow
                                hover
                                key={row._id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    disabled={
                                      (selectedSite
                                        ? selected.length > 0 &&
                                          row.sites.length > 0 &&
                                          !row.sites.includes(selectedSite)
                                        : selected.length > 0 && row.sites.length > 0) ||
                                      (selected.length > 0 && selectedRole
                                        ? !(selectedRole === row.role._id)
                                        : false)
                                    }
                                    checked={isItemSelected}
                                    onChange={(event) => handleClick(event, row?._id)}
                                  />
                                </TableCell>
                                {/* <TableCell>{(index += 1)}</TableCell> */}
                                <TableCell component="th" scope="row" padding="none">
                                  <Stack direction="row" alignItems="center" spacing={1}>
                                    <Avatar alt={row.name} src={row.avatar} />
                                    <Stack>
                                      <Typography variant="subtitle2" noWrap>
                                        {row.name}&nbsp;
                                      </Typography>
                                      <Box display="flex">
                                        <Typography sx={{ color: '#3366FF', fontSize: 11 }}>
                                          <b>{row.role.name}</b>
                                        </Typography>
                                        {row?.subrole ? (
                                          <Typography
                                            sx={{ color: '#DC7633', ml: 1, fontSize: 11 }}
                                          >
                                            <b>{row.subrole.name}</b>
                                          </Typography>
                                        ) : null}
                                      </Box>
                                    </Stack>
                                    <InfoIcon
                                      sx={{ cursor: 'pointer' }}
                                      onClick={(event) =>
                                        setAnchorEl({
                                          id: `info_${row._id}`,
                                          target: event.currentTarget
                                        })
                                      }
                                    />
                                    <Popover
                                      id={`info_${row._id}`}
                                      open={anchorEl.id === `info_${row._id}`}
                                      anchorEl={anchorEl.target}
                                      onClose={() => setAnchorEl({ id: null, target: null })}
                                      anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                      }}
                                    >
                                      <List>
                                        {row.contact && (
                                          <ListItem disablePadding>
                                            <ListItemButton>
                                              <ListItemIcon>
                                                <PhoneAndroidIcon />
                                              </ListItemIcon>
                                              <ListItemText primary={`+91 ${row.contact}`} />
                                            </ListItemButton>
                                          </ListItem>
                                        )}
                                        {row.email && (
                                          <ListItem disablePadding>
                                            <ListItemButton>
                                              <ListItemIcon>
                                                <AlternateEmailIcon />
                                              </ListItemIcon>
                                              <ListItemText primary={`${row.email}`} />
                                            </ListItemButton>
                                          </ListItem>
                                        )}
                                        {/* {row.state && (
                                          <ListItem disablePadding>
                                            <ListItemButton>
                                              <ListItemIcon>
                                                <LocationCityIcon />
                                              </ListItemIcon>
                                              <ListItemText
                                                primary={`${row?.state ?? 'Not Provided'}`}
                                              />
                                            </ListItemButton>
                                          </ListItem>
                                        )} */}
                                        {row.address && (
                                          <ListItem disablePadding>
                                            <ListItemButton component="a" href="#simple-list">
                                              <ListItemIcon>
                                                <ContactsIcon />
                                              </ListItemIcon>
                                              <ListItemText primary={`${row.address}`} />
                                            </ListItemButton>
                                          </ListItem>
                                        )}
                                        {row.doj && (
                                          <ListItem disablePadding>
                                            <ListItemButton component="a" href="#simple-list">
                                              <ListItemIcon>
                                                <EventIcon />
                                              </ListItemIcon>
                                              <ListItemText primary={`${DD_MM_YYYY(row.doj)}`} />
                                            </ListItemButton>
                                          </ListItem>
                                        )}
                                      </List>
                                    </Popover>
                                  </Stack>
                                </TableCell>
                                <TableCell>{row.gender}</TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan="7">
                            <Box>
                              <UploadEmployees
                                allRoles={allRoles}
                                allAvailableRoles={allAvailableRoles}
                                subRoleOptions={subRoleOptions}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {/* Put the scroll bar always on the bottom */}
                  <InfiniteScroll
                    dataLength={rows?.length}
                    next={fetchNextImportemployees}
                    // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
                    // inverse //
                    hasMore={hasNextImportemployees}
                    loader={
                      <Stack
                        direction="row"
                        sx={{ my: 1.5 }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress />
                      </Stack>
                    }
                    scrollableTarget="step_5_import_employees"
                    // endMessage={
                    //   <p style={{ textAlign: 'center' }}>
                    //     <b>Yay! You have seen it all</b>
                    //   </p>
                    // }
                  />
                </TableContainer>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      )}
      <AddEmployee step={5} open={openAddEmployee} onClose={() => setOpenAddEmployee(false)} />
      <Popover
        id="assign_role_manager_to_employees"
        open={anchorEl.id === 'assign_role_manager_to_employees'}
        anchorEl={anchorEl.target}
        onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
      >
        <Stack spacing={1} width="320px" sx={{ p: 2 }}>
          <Stack direction="row" alignItems="center">
            <AddModeratorIcon />
            <Typography sx={{ ml: 1 }}>Assign Site</Typography>
          </Stack>
          <Divider />
          {/* Manager */}
          <Stack sx={{ minWidth: '8rem' }}>
            <FormControl fullWidth>
              <InputLabel sx={{ ml: 2, mt: 1 }} variant="standard" htmlFor="assign_site">
                Choose Site
              </InputLabel>
              <Select
                labelId="assign_site_label_multiple"
                variant="standard"
                value={assign ?? []}
                multiple
                inputProps={{
                  name: 'assign_site_multiple',
                  id: `assign_site_multiple`
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={siteDict[value]} />
                    ))}
                  </Box>
                )}
                input={<OutlinedInput id="assign_site_label_multiple" label="Choose Site" />}
                sx={{ mt: 2 }}
                onChange={(event) => {
                  const {
                    target: { value }
                  } = event;
                  setAssign(
                    // On autofill we get a stringified value.
                    typeof value === 'string' ? value.split(',') : value
                  );
                }}
              >
                <MenuItem value="" disabled>
                  Choose Site
                </MenuItem>
                {siteOptions?.length > 0 ? (
                  siteOptions
                    .filter((site) => {
                      if (selected.length > 0 && siteManager[selectedSite]) {
                        return site[2] === siteManager[selectedSite];
                      }
                      // if (selected.length > 0 && assign.length > 0 && siteManager[assign[0]]) {
                      //   return site[2] === siteManager[assign[0]];
                      // }
                      return true;
                    })
                    .map(([key, value]) => (
                      <MenuItem
                        style={{ padding: '0.5rem' }}
                        key={`${'assign_site'}-${key}`}
                        value={key}
                        name={value}
                      >
                        {value}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem value="" disabled />
                )}
              </Select>
            </FormControl>
          </Stack>
          {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="manager_filter">
                Choose Site
              </InputLabel>
              <Select
                variant="standard"
                value={assign?.site ?? ''}
                inputProps={{
                  name: 'site_filter',
                  id: 'site_filter'
                }}
                onChange={(e) => setAssign({ ...assign, site: e.target.value })}
                // disabled={!assign?.role}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Site
                </MenuItem>
                {siteOptions?.length > 0 ? (
                  siteOptions.map(([key, value, image]) => (
                    <MenuItem
                      style={{ padding: '0.5rem' }}
                      key={`${'site_filter'}-${key}`}
                      value={key}
                      name={value}
                      data-icon={image}
                    >
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled />
                )}
              </Select>
            </FormControl>
          </Box> */}
          <br />
          <Box>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              id="employee_apply_filter_button"
              size="medium"
              onClick={() => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: `All selected employee will be added to Selected Sites!`,
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, Proceed!',
                  allowOutsideClick: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    setAnchorEl({ ...anchorEl, id: null, target: null });
                    assignSiteMutation({
                      sites: assign, // all Selected Sites
                      employees: selected,
                      isMultiple: true
                    });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    cache.invalidateQueries('unmanaged-employees');
                  }
                });
              }}
            >
              Assign
            </Button>
          </Box>
        </Stack>
      </Popover>
      {/* Edit Heirarchy Modal */}
      <EditHeirarchy
        heirarchy={heirarchy}
        open={viewHeirarchy}
        close={() => setViewHeirarchy(true)}
      />
    </Box>
  );
}
