/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable object-shorthand */
/* eslint-disable no-restricted-globals */
/* eslint-disable use-isnan */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import CalendarDates from 'calendar-dates';
import ReactApexChart from 'react-apexcharts';
import { useParams } from 'react-router-dom';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import EditIcon from '@mui/icons-material/Edit';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import { styled, useTheme } from '@mui/material/styles';
import { ErrorsAlert } from '../layouts/roster/RSidebar';
import { updateSubRole } from '../api/mutations/subRole';
import { BaseOptionChart } from '../components/charts';
import {
  assignShift as assignShiftMutate,
  deleteShift,
  autoRoster,
  autoShiftReplace,
  addOvertime,
  updateShiftPreferences,
  updatePastAttendance,
  updateWeekOff
} from '../api/mutations/adminMutations';
import useMutationFunc from '../api/mutations/mutation';
import { removeFromShift } from '../api/mutations/shift';
// components
import SearchNotFound from '../components/SearchNotFound';
// hooks
import { useShiftPreferences } from '../hooks/get-shift-preferences';
import { useRoster, useWorkforceRequirementAnalyzer } from '../hooks/roster';
import { useRoles } from '../hooks/roles';
import { useMonthAvailability } from '../hooks/get-site-month-availability';
import { useSubRoles } from '../hooks/subRoles';
import { useShifts } from '../hooks/get-shifts-query';
import useSites from '../hooks/get-sites-query';
import { useSiteById } from '../hooks/site';
import { checkRosterBackwardPropagation } from '../api/queries/Queries';
// utils
import {
  _12hourTimeFormat,
  ordinal,
  convertUTCtoLocal,
  getDataFromStorage,
  getCurrentDate,
  getCurrentTime,
  YYYY_MM_DD,
  setDataInStorage,
  formatDigit,
  stringToDate,
  uid,
  _24toUTC,
  convertTime12to24,
  formatTime
} from '../utils/functions';
import { fNumber } from '../utils/formatNumber';
import { useEmployeesCards } from '../hooks/employees';
// Mutations
import { SWAL_TIME, months, DayOfWeeks } from '../utils/config';
// redux
import callSocket from '../utils/socket';
import { logOut } from '../redux/actions/adminActions';

const _minutes_ = Array.from({ length: 59 }, (_, i) => i + 1);
const Page = React.lazy(() => import('../components/Page'));
const NotAvailable = React.lazy(() => import('../components/NotAvailable'));
const RoasterListHead = React.lazy(() => import('../components/_dashboard/roster/RoasterListHead'));
const RosterListToolbar = React.lazy(() =>
  import('../components/_dashboard/roster/RosterListToolbar')
);
// const RosterMoreMenu = React.lazy(() => import('../components/_dashboard/roster/RosterMoreMenu'));
const RequestReinforcement = React.lazy(() =>
  import('../components/_dashboard/app/RequestReinforcement')
);
const ImageZoom = React.lazy(() => import('../components/ImageZoom'));
const AddShift = React.lazy(() => import('../components/_dashboard/shift/AddShift'));
const ProgressIndicators = React.lazy(() => import('../components/ProgressIndicators'));
const SiteProfile = React.lazy(() => import('./SiteProfile'));
const ClearRoster = React.lazy(() => import('../components/ClearRoster'));
const AddHoliday = React.lazy(() => import('../components/AddHoliday'));
const AppHolidays = React.lazy(() => import('../components/_dashboard/app/AppHolidays'));
const GetReinforcement = React.lazy(() => import('../components/_dashboard/app/GetReinforcement'));

let TABLE_HEAD = [];

// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));

// --------------------------------------------------------------------------

const CHART_HEIGHT = 305;
// const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  width: 550,
  marginTop: theme.spacing(1),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  }
  // '& .apexcharts-legend': {
  //   height: LEGEND_HEIGHT,
  //   alignContent: 'center',
  //   position: 'relative !important',
  //   borderTop: `solid 1px ${theme.palette.divider}`,
  //   top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  // }
}));

// --------------------------------------------------------------

export function MonthProgress(per) {
  return per;
}

Requirements.propTypes = {
  rosterMonth: PropTypes.any,
  selectedSite: PropTypes.any,
  siteData: PropTypes.any,
  open: PropTypes.any,
  onClose: PropTypes.any,
  token: PropTypes.string,
  selReqDay: PropTypes.any,
  setSelReqDay: PropTypes.any
};

export function Requirements({
  rosterMonth,
  selectedSite,
  siteData,
  open,
  token,
  onClose,
  selReqDay,
  setSelReqDay
}) {
  const theme = useTheme();
  // const today = new Date();
  const { info } = useSelector((state) => state.adminInfo);
  // const monthDay = rosterMonth.getMonth() > today.getMonth() ? 1 : today.getDate();
  const [showHolidays, setShowHolidays] = useState(false);
  // const [selReqDay, setSelReqDay] = useState({
  //   iso: `${monthDate[0]}-${monthDate[1]}-${monthDay < 10 ? `0${monthDay}` : monthDay}`, // DD_MM_YYYY(today)
  //   date: rosterMonth.getMonth() > today.getMonth() ? monthDay : today.getDate()
  // });
  const [startAnalyzer, setStartAnalyzer] = React.useState(false);
  const [, setProgress] = React.useState(0);
  const [analyzerId, setAnalyzerId] = React.useState('');
  const {
    isLoading: isAvailabilityLoading,
    isError: isAvailabilityError,
    data: siteAvailability
  } = useMonthAvailability(selectedSite, YYYY_MM_DD(rosterMonth), null, token); // Change Date To View Month Availability

  const { sit: ShiftAvailability, TOTAL_DAYS_REQUIREMENTS } = useMemo(() => {
    // const out = {}; // Shift Availability , Gaps
    // let availability = [];
    if (!isAvailabilityLoading && !isAvailabilityError) {
      if (siteAvailability) {
        return siteAvailability;
      }
    }
    return { sit: [], GAPS: [], TOTAL_DAYS_REQUIREMENTS: [] };
  }, [siteAvailability, isAvailabilityLoading, isAvailabilityError]);

  const {
    isLoading: isAnalyzerLoading,
    isError: isAnalyzerError,
    data: rAnalyzer
  } = useWorkforceRequirementAnalyzer(
    selectedSite,
    convertUTCtoLocal(rosterMonth, true),
    startAnalyzer
  );

  const requirementAnalyzer = useMemo(() => {
    if (!isAnalyzerLoading && !isAnalyzerError) {
      console.log(rAnalyzer);
      return rAnalyzer;
    }
    return undefined;
  }, [isAnalyzerError, isAnalyzerLoading, rAnalyzer]);

  useEffect(() => {
    if (analyzerId.length > 0) {
      const socket = callSocket();
      socket.on('disconnect', (reason) => {
        if (!navigator.onLine) {
          console.log(`Connection Lost!!, reconnecting...`);
        } else if (
          reason === 'io server disconnect' ||
          reason === 'ping timeout' ||
          reason === 'transport error'
        ) {
          // the disconnection was initiated by the server, you need to reconnect manually
          socket.connect();
        } else if (reason === 'transport close' && navigator.onLine) {
          console.log(`Error Analysing Data!!`);
        }
      });
      // window.onbeforeunload = () => true;
      socket.on('progress', (status) => {
        if (
          status.type === 'autoRosterRequirement' &&
          status.user === info?._id &&
          status.rosterId === analyzerId
        ) {
          const p = status.currProgress / status.totalProgress;
          // console.log(status.currProgress, status.totalProgress);
          setProgress(p);
        } else {
          // socket.disconnect();
        }
      });
    }
  }, [analyzerId, isAnalyzerLoading, info]);

  const [reqTab, setReqTab] = useState(0);

  return (
    <Card
      sx={{
        position: 'absolute',
        zIndex: 9,
        bottom: 0,
        right: 0,
        ml: 1.2,
        borderRadius: 1,
        height: 'calc(100vh - 155px)',
        width: '100%',
        backgroundColor: 'white',
        boxShadow: 'none'
      }}
      hidden={!open}
    >
      <Box
        sx={{
          height: 35,
          p: 1,
          mb: 2,
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        style={{
          backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
        }}
      >
        {showHolidays && <Typography className="desktop">Holidays</Typography>}
        <Typography>Requirement ( {selReqDay.iso} )</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip
            sx={{ '& .MuiChip-icon': { color: 'white' }, color: 'white', height: 25 }}
            onClick={() => setShowHolidays(!showHolidays)}
            icon={<WorkOffIcon sx={{ fontSize: 14 }} />}
            label="Holidays"
            variant="outlined"
          />
          &nbsp;&nbsp;
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
        </Box>
      </Box>
      <Grid container>
        {showHolidays && (
          <Grid item xs={12} md={4} ClassName="desktop">
            <AppHolidays list noShadow show site={selectedSite} height="calc(100vh - 220px)" />
          </Grid>
        )}
        <Grid item xs={12} md={showHolidays ? 8 : 12}>
          <Stack
            direction="row"
            sx={{ mb: 1, px: 2, overflowX: 'scroll' }}
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            className="hide_scrollbar"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip color="lightblue" label=" " sx={{ borderRadius: 0.5, height: 20, width: 20 }} />
              <Typography sx={{ fontSize: 14 }}>Requirements</Typography>
              <Chip
                color="lightyellow"
                label="  "
                sx={{ borderRadius: 0.5, height: 20, width: 20 }}
              />
              <Typography sx={{ fontSize: 14 }}>Assigned</Typography>
              <Chip
                color="lightgreen"
                label="  "
                sx={{ borderRadius: 0.5, height: 20, width: 20 }}
              />
              <Typography sx={{ fontSize: 14 }}>Attendance</Typography>
              <Chip color="lightred" label="  " sx={{ borderRadius: 0.5, height: 20, width: 20 }} />
              <Typography sx={{ fontSize: 14 }}>Gap</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {startAnalyzer ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setAnalyzerId('');
                    setStartAnalyzer(false);
                  }}
                >
                  Go Back
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={async () => {
                    const uniqueId = await uid();
                    setStartAnalyzer(uniqueId);
                  }}
                >
                  Requirement Analyser
                </Button>
              )}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  // views={['year', 'month']}
                  // label="Year and Month"
                  minDate={stringToDate(convertUTCtoLocal(rosterMonth, true), true)}
                  maxDate={new Date(rosterMonth)}
                  value={stringToDate(selReqDay?.iso)}
                  onChange={(newValue) => {
                    setSelReqDay({
                      iso: convertUTCtoLocal(newValue, true),
                      date: newValue.getDate()
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ '& input': { padding: '8px', fontSize: 14 } }}
                      {...params}
                      helperText={null}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>
          {startAnalyzer ? (
            <Stack sx={{ p: 1 }}>
              <Typography align="center">
                Requirements Analysis Results for ( {months[rosterMonth.getMonth()]},{' '}
                {rosterMonth.getFullYear()} ){' '}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ height: 'calc(100vh - 20rem)', overflowY: 'auto' }}>
                {isAnalyzerLoading ? (
                  <NotAvailable type="loading" left msg="Analyzing..." />
                ) : requirementAnalyzer ? (
                  requirementAnalyzer?.requirement?.map((req) => (
                    <Stack sx={{ px: 1 }} key={`-${req.role._id}`}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                          {req.name}
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Chip
                            color="lightblue"
                            sx={{
                              borderRadius: 0.5,
                              height: 20,
                              '& .css-6od3lo-MuiChip-label': {
                                padding: 1
                              }
                            }}
                            label={req.total}
                          />
                        </Stack>
                      </Stack>
                      {req.subroles.length > 0
                        ? req.subroles.map((subr) => (
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              sx={{
                                ml: 1.8,
                                my: 0.5
                                // color: `${
                                //   (subai ? subai.total : 0) > (subr.value ? subr.total : 0) &&
                                //   'red'
                                // }`
                              }}
                              key={`subv-${subr.subrole}`}
                            >
                              <Typography
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
                                &nbsp;{subr?.name}
                              </Typography>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Chip
                                  color="lightblue"
                                  sx={{
                                    borderRadius: 0.5,
                                    height: 20,
                                    '& .css-6od3lo-MuiChip-label': {
                                      padding: 1
                                    }
                                  }}
                                  label={subr.total ? subr.total : 0}
                                />
                              </Stack>
                            </Stack>
                          ))
                        : null}
                    </Stack>
                  ))
                ) : (
                  <NotAvailable type="na" msg="None of the Roles Required" />
                )}
              </Box>
            </Stack>
          ) : (
            <>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                className="hide_scrollbar"
                sx={{ height: '3rem', fontSize: 14, px: 1, overflowX: 'scroll' }}
              >
                <Stack sx={{ p: 1, color: theme.palette.primary.main, fontWeight: 'bold' }}>
                  Constraints
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    boxShadow: theme.customShadows.bloated,
                    backgroundColor: theme.palette.daily_working_hours.main,
                    py: 0.7,
                    px: 1,
                    minWidth: '11rem'
                  }}
                >
                  <Typography sx={{ fontSize: 14, color: '#fff' }}>Daily Working Hour</Typography>
                  <Chip
                    size="small"
                    color="default_white"
                    label={siteData?.constraints?.daily_working_hours}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    boxShadow: theme.customShadows.bloated,
                    backgroundColor: theme.palette.site_weekly_working_days.main,
                    py: 0.7,
                    px: 1,
                    minWidth: '12rem'
                  }}
                >
                  <Typography sx={{ fontSize: 14, color: '#fff' }}>Weekly Working Days</Typography>
                  <Chip
                    size="small"
                    color="default_white"
                    label={siteData?.constraints?.site_weekly_working_days}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    boxShadow: theme.customShadows.bloated,
                    backgroundColor: theme.palette.employee_weekly_working_days.main,
                    py: 0.7,
                    px: 1,
                    minWidth: '16rem'
                  }}
                >
                  <Typography sx={{ fontSize: 14, color: '#fff' }}>
                    Employee Weekly Working Days
                  </Typography>
                  <Chip
                    size="small"
                    color="default_white"
                    label={siteData?.constraints?.employee_weekly_working_days}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    boxShadow: theme.customShadows.bloated,
                    backgroundColor: theme.palette.weekly_working_hours.main,
                    py: 0.7,
                    px: 1,
                    minWidth: '13rem'
                  }}
                >
                  <Typography sx={{ fontSize: 14, color: '#fff' }}>Weekly Working Hours</Typography>
                  <Chip
                    size="small"
                    color="default_white"
                    label={siteData?.constraints?.weekly_working_hours}
                  />
                </Stack>
                {siteData?.constraints?.weekly_off?.length > 0 && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ boxShadow: theme.customShadows.bloated, p: 0.5, minWidth: '16rem' }}
                  >
                    <Typography sx={{ fontSize: 14 }}> Site Weekly Off</Typography>
                    {siteData?.constraints?.weekly_off?.map((of) => (
                      <Chip
                        size="small"
                        color="secondary"
                        key={`final-roster-${siteData?._id}-${of}`}
                        label={DayOfWeeks[of].substring(0, 3)}
                      />
                    ))}
                  </Stack>
                )}
              </Stack>
              <Stack direction="row">
                <Stack sx={{ px: 1, pb: 2 }}>
                  {!isAvailabilityLoading &&
                    !isAvailabilityError &&
                    TOTAL_DAYS_REQUIREMENTS?.filter((dy) => dy?.date === selReqDay?.date).map(
                      (dte, ix1) => {
                        let treq = 0;
                        let tavl = 0;
                        let tgap = 0;
                        let tatt = 0;
                        // console.log(dte);
                        Object?.values(dte?.total ?? {})?.forEach((ky) => {
                          treq += ky?.value;
                          tavl += ky?.available;
                          tgap += ky?.gap;
                          tatt += ky?.att;
                        });
                        return (
                          <Stack
                            key={`total_requirement-${dte?.date}-head`}
                            sx={{
                              minWidth: 350,
                              border: '1px solid #D7D4CF',
                              height: 'calc(100vh - 20rem)'
                            }}
                            spacing={1}
                          >
                            <Stack
                              sx={{
                                mb: 0.7,
                                boxShadow: theme.customShadows.bloated,
                                p: 1
                              }}
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
                              >
                                Total
                              </Typography>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Chip
                                  color="lightblue"
                                  sx={{
                                    borderRadius: 0.5,
                                    fontWeight: 'bold',
                                    height: 20,
                                    '& .css-6od3lo-MuiChip-label': {
                                      padding: 0.7
                                    }
                                  }}
                                  label={treq}
                                />
                                <Chip
                                  color="lightyellow"
                                  sx={{
                                    borderRadius: 0.5,
                                    fontWeight: 'bold',
                                    height: 20,
                                    '& .css-6od3lo-MuiChip-label': {
                                      padding: 0.7
                                    }
                                  }}
                                  label={tavl}
                                />
                                <Chip
                                  size="small"
                                  color="lightgreen"
                                  sx={{ borderRadius: 0.5, fontWeight: 'bold' }}
                                  label={isNaN(tatt) ? 0 : tatt}
                                />
                                <Chip
                                  color="lightred"
                                  sx={{
                                    borderRadius: 0.5,
                                    fontWeight: 'bold',
                                    height: 20,
                                    '& .css-6od3lo-MuiChip-label': {
                                      padding: 0.7
                                    }
                                  }}
                                  label={Math.abs(tgap)}
                                />
                              </Stack>
                            </Stack>
                            <Box
                              className="custom_scrollbar"
                              sx={{
                                overflowY: 'auto',
                                px: 1,
                                height: 'calc(100vh - 20rem)'
                              }}
                            >
                              {Object?.values(dte?.total ?? {})?.map((ky) => {
                                console.log();
                                return (
                                  <Stack key={`total-requirements-${dte.date}-${ky._id}-${ix1}`}>
                                    <Stack
                                      direction="row"
                                      sx={{ color: '#333' }}
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Typography
                                        sx={{ display: 'flex', p: 0.5, alignItems: 'center' }}
                                      >
                                        {ky?.name}
                                      </Typography>
                                      <Stack direction="row" alignItems="center" spacing={1}>
                                        <Chip
                                          color="lightblue"
                                          sx={{
                                            borderRadius: 0.5,
                                            fontWeight: 'bold',
                                            height: 20,
                                            '& .css-6od3lo-MuiChip-label': {
                                              padding: 0.7
                                            }
                                          }}
                                          label={ky?.value}
                                        />
                                        <Chip
                                          color="lightyellow"
                                          sx={{
                                            borderRadius: 0.5,
                                            fontWeight: 'bold',
                                            height: 20,
                                            '& .css-6od3lo-MuiChip-label': {
                                              padding: 0.7
                                            }
                                          }}
                                          label={ky?.available}
                                        />
                                        <Chip
                                          size="small"
                                          color="lightgreen"
                                          sx={{ borderRadius: 0.5, fontWeight: 'bold' }}
                                          label={isNaN(ky?.att) ? 0 : ky?.att}
                                        />
                                        <Chip
                                          color="lightred"
                                          sx={{
                                            borderRadius: 0.5,
                                            fontWeight: 'bold',
                                            height: 20,
                                            '& .css-6od3lo-MuiChip-label': {
                                              padding: 0.7
                                            }
                                          }}
                                          label={Math.abs(ky?.gap)}
                                        />
                                      </Stack>
                                    </Stack>
                                    {ky?.subroles !== undefined &&
                                      Object.values(ky?.subroles)?.map((rq) => (
                                        <Stack
                                          direction="row"
                                          justifyContent="space-between"
                                          sx={{
                                            ml: 1.8,
                                            my: 0.5,
                                            color: '#333'
                                            // color: `${
                                            //   (subai ? subai.total : 0) > (subr.value ? subr.value : 0) && 'red'
                                            // }`
                                          }}
                                          key={`total-requirements-${rq?._id}-${rq?.name.replace(
                                            ' ',
                                            '-'
                                          )}`}
                                        >
                                          <Typography
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            &emsp;
                                            <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
                                            &nbsp;{rq?.name}
                                          </Typography>
                                          <Stack direction="row" alignItems="center" spacing={1}>
                                            <Chip
                                              color="lightblue"
                                              sx={{
                                                borderRadius: 0.5,
                                                fontWeight: 'bold',
                                                height: 20,
                                                '& .css-6od3lo-MuiChip-label': {
                                                  padding: 0.7
                                                }
                                              }}
                                              label={rq?.value}
                                            />
                                            <Chip
                                              color="lightyellow"
                                              sx={{
                                                borderRadius: 0.5,
                                                fontWeight: 'bold',
                                                height: 20,
                                                '& .css-6od3lo-MuiChip-label': {
                                                  padding: 0.7
                                                }
                                              }}
                                              label={isNaN(rq?.available) ? 0 : rq?.available}
                                            />
                                            <Chip
                                              size="small"
                                              color="lightgreen"
                                              sx={{ borderRadius: 0.5, fontWeight: 'bold' }}
                                              label={isNaN(rq?.att) ? 0 : rq?.att}
                                            />
                                            <Chip
                                              color="lightred"
                                              sx={{
                                                borderRadius: 0.5,
                                                fontWeight: 'bold',
                                                height: 20,
                                                '& .css-6od3lo-MuiChip-label': {
                                                  padding: 0.7
                                                }
                                              }}
                                              label={
                                                isNaN(rq?.gap)
                                                  ? 0
                                                  : rq?.available > rq?.value
                                                  ? `+${Math.abs(rq?.gap)}`
                                                  : Math.abs(rq?.gap)
                                              }
                                            />
                                          </Stack>
                                        </Stack>
                                      ))}
                                  </Stack>
                                );
                              })}
                            </Box>
                          </Stack>
                        );
                      }
                    )}
                </Stack>
                <Tabs
                  value={reqTab}
                  onChange={(e, newValue) => setReqTab(newValue)}
                  className="roster_requirements_shifts"
                  sx={{ height: 'calc(100vh - 20rem)' }}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable days tab"
                >
                  {!isAvailabilityLoading &&
                    !isAvailabilityError &&
                    ShiftAvailability.map((shift, index) => {
                      // console.log('Shift:', shift);
                      const req = shift?.days?.find(
                        (dy) => dy.date === selReqDay.date
                      )?.requirement;
                      const areq = req?.map((r) => r.role._id);
                      // console.log('Req', req);
                      // console.log('Areq', areq);
                      const avl = shift.days.find((dy) => dy.date === selReqDay.date)?.available;
                      const extra = avl?.filter((av) => !areq.includes(av.role._id));
                      if (extra?.length > 0) {
                        req.push(...extra);
                      }
                      const tavl = avl?.reduce((s, a) => s + a?.total, 0);
                      const treq = req?.reduce((s, a) => s + a?.value, 0);
                      const tatt = avl?.reduce((s, a) => s + a?.att, 0);
                      // console.log('Avl', avl);
                      // console.log('Extra', extra);
                      return (
                        <Tab
                          value={index + 1}
                          key={`require-${shift._id}-${index}`}
                          label={
                            <Stack
                              sx={{
                                minWidth: 350,
                                height: 'calc(100vh - 20rem)',
                                border: '1px solid #D7D4CF',
                                color: '#333'
                              }}
                            >
                              <Stack sx={{ boxShadow: theme.customShadows.bloated, p: 1, mb: 2 }}>
                                <Box
                                  display="flex"
                                  sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                  <Typography
                                    sx={{
                                      backgroundColor: 'white',
                                      fontWeight: 'bold',
                                      color: theme.palette.primary.main
                                    }}
                                  >
                                    {shift.name}
                                  </Typography>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={1}
                                  >
                                    <Chip
                                      size="small"
                                      color="lightblue"
                                      sx={{ borderRadius: 0.5 }}
                                      label={treq}
                                    />
                                    <Chip
                                      size="small"
                                      color="lightyellow"
                                      sx={{ borderRadius: 0.5 }}
                                      label={tavl}
                                    />
                                    <Chip
                                      size="small"
                                      color="lightgreen"
                                      sx={{ borderRadius: 0.5 }}
                                      label={tatt}
                                    />
                                    <Chip
                                      size="small"
                                      color="lightred"
                                      sx={{ borderRadius: 0.5 }}
                                      label={
                                        tavl > treq ? `+${Math.abs(treq - tavl)}` : treq - tavl
                                      }
                                    />
                                  </Stack>
                                </Box>
                                {shift?.constraints?.weekly_off?.length > 0 ? (
                                  <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography sx={{ fontSize: 13 }}>Weekly Off:</Typography>
                                    {shift?.constraints?.weekly_off?.map((wo) => (
                                      <Chip
                                        size="small"
                                        key={`${shift._id}-weekday-${wo}`}
                                        label={DayOfWeeks[wo].substring(0, 3)}
                                      />
                                    ))}
                                  </Stack>
                                ) : null}
                              </Stack>
                              <Stack
                                className="custom_scrollbar"
                                sx={{ overflowY: 'auto', pb: 2, height: 'calc(100vh - 20rem)' }}
                              >
                                {req?.length > 0
                                  ? req.map((req) => {
                                      const ai = avl?.find((ai) => ai.role._id === req.role._id);
                                      const asreq = req?.subroles?.map((sr) => sr?.subrole?._id);
                                      const sextra = ai?.subroles?.filter(
                                        (suba) => !asreq.includes(suba?.subrole?._id)
                                      );
                                      if (sextra?.length > 0) {
                                        req.subroles.push(...sextra);
                                      }
                                      // console.log('Subrole Extra', sextra);
                                      // console.log(req.subroles);
                                      return (
                                        <Stack
                                          sx={{
                                            px: 1
                                          }}
                                          key={`${index}-${req.role._id}`}
                                        >
                                          <Stack direction="row" justifyContent="space-between">
                                            <Typography
                                              sx={{ display: 'flex', alignItems: 'center' }}
                                            >
                                              {req.role.name}
                                            </Typography>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                              <Chip
                                                color="lightblue"
                                                sx={{
                                                  borderRadius: 0.5,
                                                  height: 20,
                                                  '& .css-6od3lo-MuiChip-label': {
                                                    padding: 0.7
                                                  }
                                                }}
                                                label={req.value}
                                              />
                                              <Chip
                                                color="lightyellow"
                                                sx={{
                                                  borderRadius: 0.5,
                                                  height: 20,
                                                  '& .css-6od3lo-MuiChip-label': {
                                                    padding: 0.7
                                                  }
                                                }}
                                                label={ai ? ai.total : 0}
                                              />
                                              <Chip
                                                color="lightgreen"
                                                sx={{
                                                  borderRadius: 0.5,
                                                  height: 20,
                                                  '& .css-6od3lo-MuiChip-label': {
                                                    padding: 0.7
                                                  }
                                                }}
                                                label={ai ? ai.att : 0}
                                              />
                                              <Chip
                                                color="lightred"
                                                sx={{
                                                  borderRadius: 0.5,
                                                  height: 20,
                                                  '& .css-6od3lo-MuiChip-label': {
                                                    padding: 0.7
                                                  }
                                                }}
                                                label={
                                                  ai?.total > req?.value
                                                    ? `+${Math.abs(
                                                        (ai?.total ?? 0) - (req?.value ?? 0)
                                                      )}`
                                                    : (req?.value ?? 0) - (ai?.total ?? 0)
                                                }
                                              />
                                            </Stack>
                                          </Stack>
                                          {req.subroles.length > 0
                                            ? req.subroles.map((subr) => {
                                                const subai = ai?.subroles.find(
                                                  (subi) =>
                                                    subi?.subrole?._id === subr?.subrole?._id
                                                );
                                                return (
                                                  <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    sx={{
                                                      ml: 1.8,
                                                      my: 0.5,
                                                      color: `${
                                                        (subai ? subai.total : 0) >
                                                          (subr.value ? subr.value : 0) && 'red'
                                                      }`
                                                    }}
                                                    key={`subv-${subr.subrole._id}`}
                                                  >
                                                    <Typography
                                                      sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                      }}
                                                    >
                                                      <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
                                                      &nbsp;{subr?.subrole?.name}
                                                    </Typography>
                                                    <Stack
                                                      direction="row"
                                                      alignItems="center"
                                                      spacing={1}
                                                    >
                                                      <Chip
                                                        color="lightblue"
                                                        sx={{
                                                          borderRadius: 0.5,
                                                          height: 20,
                                                          '& .css-6od3lo-MuiChip-label': {
                                                            padding: 0.7
                                                          }
                                                        }}
                                                        label={subr.value ? subr.value : 0}
                                                      />
                                                      <Chip
                                                        color="lightyellow"
                                                        sx={{
                                                          borderRadius: 0.5,
                                                          height: 20,
                                                          '& .css-6od3lo-MuiChip-label': {
                                                            padding: 0.7
                                                          }
                                                        }}
                                                        label={subai ? subai.total : 0}
                                                      />
                                                      <Chip
                                                        color="lightgreen"
                                                        sx={{
                                                          borderRadius: 0.5,
                                                          height: 20,
                                                          '& .css-6od3lo-MuiChip-label': {
                                                            padding: 0.7
                                                          }
                                                        }}
                                                        label={subai ? subai.att : 0}
                                                      />
                                                      <Chip
                                                        color="lightred"
                                                        sx={{
                                                          borderRadius: 0.5,
                                                          height: 20,
                                                          '& .css-6od3lo-MuiChip-label': {
                                                            padding: 0.7
                                                          }
                                                        }}
                                                        label={
                                                          subai?.total > (subr?.value ?? 0)
                                                            ? `+${Math.abs(
                                                                (subai?.total ?? 0) -
                                                                  (subr?.value ?? 0)
                                                              )}`
                                                            : (subr?.value ?? 0) -
                                                              (subai?.total ?? 0)
                                                        }
                                                      />
                                                    </Stack>
                                                  </Stack>
                                                );
                                              })
                                            : null}
                                        </Stack>
                                      );
                                    })
                                  : 'No Requirement Available'}
                              </Stack>
                            </Stack>
                          }
                        />
                      );
                    })}
                </Tabs>
              </Stack>
              {ShiftAvailability.length === 0 && (
                <NotAvailable type="na" msg="No Shift Available" />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

function Roster() {
  const today = new Date();
  const theme = useTheme();
  const params = useParams();
  const assignShiftRef = React.useRef();
  const [imageSrc, setImageSrc] = useState();
  // Roster Site ID
  const selectedSite = params.site.replace(':', '');
  // Roster Date from the URL
  const monthDate = params.date.replace(':', '').split('-');
  const [rosterMonth, setRosterMonth] = useState(
    new Date(
      Number(monthDate[0]),
      formatDigit(monthDate[1]) - 1,
      formatDigit(monthDate[2]),
      0,
      0,
      0,
      0
    )
  );

  // Update Attendance
  const [updateAttendance, setUpdateAttendance] = useState([]);

  // useEffect(() => {
  //   console.log('Update Attendance', updateAttendance);
  // }, [updateAttendance]);

  // formatDigit(monthDate[2]
  const monthDay = rosterMonth.getMonth() > today.getMonth() ? 1 : today.getDate();

  const getInitialStartDate = (rMonth) => {
    const mDay = rMonth.getMonth() > today.getMonth() ? 1 : today.getDate();
    const initStartDate =
      mDay >= 0 && mDay <= 7
        ? 0
        : mDay >= 8 && mDay <= 14
        ? 7
        : mDay >= 15 && mDay <= 21
        ? 14
        : mDay >= 22 && mDay <= 28
        ? 21
        : 28;
    return initStartDate;
  };
  // const scrollRef = useHorizontalScroll();
  // const scrollShiftRef = useHorizontalScroll();
  // const scrollRequirementsRef = useHorizontalScroll();
  const [selReqDay, setSelReqDay] = useState({
    iso: `${monthDate[0]}-${monthDate[1]}-${monthDay < 10 ? `0${monthDay}` : monthDay}`, // DD_MM_YYYY(today)
    date: undefined,
    wd: undefined
  });
  const [shiftTab, setShiftTab] = useState(0); // Shift Tab Value
  const [currentMonth, setCurrentMonth] = useState([]);
  // const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [editShift, setEditShift] = useState(null);
  const [week, setWeek] = useState({
    start: 0,
    end: 7
  });
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [shiftOptions, setShiftOptions] = useState();
  const [workDays, setWorkDays] = useState([]);
  const [modalShiftId, setModalShiftId] = useState('');
  const [shiftId, setShiftId] = useState('');
  const [preferencesId, setPreferencesId] = React.useState();
  const [assignShift, setAssignShift] = useState();
  const [expandRoster, setExpandRoster] = useState(false); // Expand Roster
  const [openCard, setOpenCard] = useState(''); // Hide / Show Card By Id
  const [availabilityDate, setAvailabilityDate] = useState();
  const [subRole, setSubRole] = useState('');
  // const [attendanceTab, setAttendanceTab] = useState(0);
  // const [viewConstraints, setViewConstraints] = useState(false); // View Contraints
  const [siteOptions, setSiteOptions] = useState();
  const [roleOptions, setRoleOptions] = React.useState(); // Roles initial State for select
  const [subRoleOptions, setSubRoleOptions] = useState();
  const [roleType, setRoleType] = useState();
  const [assignError, setAssignError] = useState({});
  const [openAddShift, setOpenAddShift] = useState(false);
  const [smPriority, setSMPriority] = useState();
  const [showEmpInfo, setShowEmpInfo] = useState(true);
  const [assignShiftData, setAssignShiftData] = useState({
    _id: '',
    name: '',
    role: '',
    priority: '',
    date: ''
  });

  // useEffect(() => {
  //   console.log('Assign Shift Data:', assignShiftData);
  // }, [assignShiftData]);

  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    role: '',
    subRole: '',
    site: '',
    shift: ''
  });

  // const [selectedAttRole, setSelectedAttRole] = useState({});
  // const [expanded, setExpanded] = React.useState(); // Default 'panel1'
  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };
  // const [value, setValue] = React.useState(0);
  // const handleTabChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const { info } = useSelector((state) => state.adminInfo);
  // console.log('Info:', info);

  useEffect(() => {
    const monthDay = rosterMonth.getMonth() > today.getMonth() ? 1 : today.getDate();
    setDataInStorage('roster_month', rosterMonth); // Setting roster Month in Storage
    document.getElementById('update-progress').click();
    document.getElementById('update-sidebar-progress').click();
    // changed ------------------------------------
    // const cdm = getCurrentDate().split('-');
    const sdm = YYYY_MM_DD(rosterMonth).split('-');
    // --------------------------------------------
    setSelReqDay({
      ...selReqDay,
      iso: `${sdm[0]}-${sdm[1]}-${monthDay < 10 ? `0${monthDay}` : monthDay}`,
      date: Number(monthDay),
      wd:
        rosterMonth.getMonth() > today.getMonth()
          ? new Date(today.getFullYear(), rosterMonth.getMonth(), monthDay).getDay()
          : today.getDay()
    });
    setWeek({
      start: getInitialStartDate(rosterMonth),
      end: 7
    });
    const mainAsync = async () => {
      const calendarDates = new CalendarDates();
      const mayDates = await calendarDates.getDates(rosterMonth);
      // const mayMatrix = await calendarDates.getMatrix(may2018);
      const newarray = [].concat(...Array(7).fill(DayOfWeeks));
      mayDates.map((date, index) => (date.day = newarray[index]));
      const currentMonthWeekdays = mayDates.filter((date) => date.type === 'current');
      setCurrentMonth([...currentMonthWeekdays]);
    };
    mainAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rosterMonth]);

  /* ===================== Assign Manager Mutations ==============  */

  const updatePastAttendanceMutation = useMutation(updatePastAttendance, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Updating Attendance 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Updated Attendance`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      setUpdateAttendance([]); // Reset update attendance array
      cache.invalidateQueries('roster');
    }
  });
  // useEffect(() => {
  //   console.log('selReqDay :', selReqDay);
  //   // console.log(availabilityDate);
  // }, [selReqDay, availabilityDate]);

  const {
    isLoading: isSiteLoading,
    isError: isSiteError,
    data: _siteData
  } = useSiteById(selectedSite);

  const [siteId, setSiteId] = useState('');

  useEffect(() => {
    if (!isSiteLoading && !isSiteError) {
      setSiteId(
        `${_siteData.site._id}, ${_siteData?.site?.manager?._id}, ${_siteData?.site?.manager?.role?.priority}`
      );
    }
  }, [_siteData, isSiteLoading, isSiteError]);

  /* ===================== Roles ===================== */

  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(true); // gettings roles

  const allRoles = React.useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  React.useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      const sm = allRoles.find((role) => role.is_site_manager === true);
      // console.log('Site Manager:', sm); // site Manager
      setSMPriority(sm?.priority);
      allRoles.forEach((role) => {
        if (role.priority > sm.priority) {
          formattedRoles.push([`${role._id},${role.name}`, role.name]);
        }
      });
      setRoleOptions([...formattedRoles]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allRoles]);

  /* ================= End Roles =============== */

  /* ========================= Get Month Availability ====================== */

  const {
    isLoading: isAvailabilityLoading,
    isError: isAvailabilityError,
    data: siteAvailability
  } = useMonthAvailability(selectedSite, YYYY_MM_DD(rosterMonth)); // Change Date To View Month Availability

  const { GAPS } = useMemo(() => {
    // const out = {}; // Shift Availability , Gaps
    // let availability = [];
    if (!isAvailabilityLoading && !isAvailabilityError) {
      if (siteAvailability) {
        return siteAvailability;
      }
    }
    return { sit: [], GAPS: [], TOTAL_DAYS_REQUIREMENTS: [] };
  }, [siteAvailability, isAvailabilityLoading, isAvailabilityError]);

  /* ======================= Get Month availability ======================== */

  const {
    isLoading: isRosterLoading,
    // isFetching: isRosterFetching,
    isError: isRosterError,
    hasNextPage: hasNextRoster,
    fetchNextPage: fetchNextRoster,
    refetch: refetchRoster,
    data: roster
  } = useRoster(
    convertUTCtoLocal(rosterMonth, true) ?? getCurrentDate(),
    filterName, // search
    null, // state
    null, // gender
    filter?.role.split(',')[0],
    filter?.subRole.split(',')[0],
    selectedSite ?? filter?.site.split(',')[0],
    filter?.shift.split(',')[0],
    undefined,
    week?.start
  );

  const rows = useMemo(() => {
    // const data = [];
    const TableHead = [];
    const listOfRoster = [];
    if (!isRosterLoading && !isRosterError) {
      if (roster?.pages?.length > 0) {
        roster.pages.forEach((group) => {
          listOfRoster.push(group.employees);
        });
      }
    }
    const flattened = listOfRoster.flat();
    currentMonth.forEach((obj) => {
      TableHead.push({
        id: `${obj.day}-${obj.date}`,
        label: `${ordinal(obj.date)} ( ${obj.day.substring(0, 2)} )`,
        date: obj.date,
        alignRight: false
      });
    });
    TABLE_HEAD = TableHead.splice(week.start, 7);
    // console.log(data);
    return flattened;
  }, [roster, isRosterLoading, isRosterError, currentMonth, week.start]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const clearRosterMutation = useMutationFunc(
  //   clearRoster,
  //   {
  //     onSuccess: {
  //       title: 'Successfully Cleared!!'
  //     }
  //   },
  //   null,
  //   () => {
  //     cache.invalidateQueries('sites');
  //     cache.invalidateQueries('roster');
  //     cache.invalidateQueries('shifts');
  //     cache.invalidateQueries('roster-availability');
  //   },
  //   null,
  //   null
  // );

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  /* ============================= Get All Site Options =============================== */
  const {
    isLoading: isSitesLoading,
    isError: isSitesError,
    data: sites
    // fetchNextPage: fetchNextShifts,
    // hasMoreData: hasMoreShifts,
    // refetch: refetchShifts
  } = useSites();

  const allSites = useMemo(() => {
    const listOfSites = [];
    if (!isSitesLoading && !isSitesError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Site Options:', flattened);
    return flattened;
  }, [sites, isSitesLoading, isSitesError]);

  React.useEffect(() => {
    const formattedSites = [];
    if (allSites?.length > 0) {
      allSites.forEach((site) => {
        if (site.active === 'Active') {
          formattedSites.push([
            `${site._id}, ${site.manager._id}, ${site.manager.role.priority}`,
            site.name
          ]);
        }
      });
      setSiteOptions([...formattedSites]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allSites]);

  /* ======================= End Get All Site Options ======================= */

  /* ============================= Get All Shifts Options =============================== */

  const {
    isLoading: isShiftsLoading,
    // isFetching: isShiftsFetching,
    isError: isShiftsError,
    data: shifts
    // fetchNextPage: fetchNextShifts,
    // hasMoreData: hasMoreShifts,
    // refetch: refetchShifts
  } = useShifts(
    '',
    selectedSite ?? siteId.split(',')[0],
    null,
    null,
    availabilityDate ?? selReqDay?.iso
  );

  const allShifts = useMemo(() => {
    const listOfShifts = [];
    if (!isShiftsLoading && !isShiftsError) {
      if (shifts?.pages?.length > 0) {
        shifts.pages.forEach((group) => listOfShifts.push(group.shifts));
      }
    }
    const flattened = listOfShifts.flat();
    // console.log('ALL Shifts:', flattened);
    return flattened;
  }, [shifts, isShiftsLoading, isShiftsError]);

  React.useEffect(() => {
    const formattedShifts = [];
    if (allShifts?.length > 0) {
      allShifts.forEach((shift) => {
        if (shift.status === 'Active') {
          formattedShifts.push([shift._id, shift.name]);
        }
      });
      setShiftOptions([...formattedShifts]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allShifts]);

  /* ======================= End Get All Shifts Options ======================= */

  useEffect(() => {
    const selectedAssignShift = allShifts.find((el) => el._id === shiftId);
    // console.log('Available Assign Shift:', selectedAssignShift?.available);
    // console.log('Selected Assign Shift:', availableRole);
    setAssignShift(selectedAssignShift);
  }, [allShifts, shiftId]);

  /* ================== Sub Roles ======================== */

  const {
    isLoading: isSubRolesLoading,
    isError: isSubRolesError,
    data: subRoles
  } = useSubRoles(filter?.role ? filter?.role?.split(',')[0] : roleType);

  useMemo(() => {
    const listOfSubRoles = [];
    if (!isSubRolesLoading && !isSubRolesError) {
      if (subRoles?.roles?.length > 0) {
        subRoles.roles[0].subroles.forEach((role) =>
          listOfSubRoles.push([`${role._id},${role.name}`, role.name])
        );
      }
    }
    // console.log('Sub Roles By Role:', listOfSubRoles);
    setSubRoleOptions([...listOfSubRoles]);
  }, [subRoles, isSubRolesLoading, isSubRolesError]);

  /* ================== End Sub Roles ====================== */

  /* ================= Assign Shift Mutation ================ */
  const { mutate: assignShiftMutation } = useMutation(assignShiftMutate, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Assigning Shift :)',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // for unauthorized access
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      const msg = error.response
        ? error.response.data.message || error.toString()
        : error.toString();

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: msg,
        showConfirmButton: false
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: (response) => {
      if (response.status === 201) {
        // Overlaps when timings of assigning shifts are same
        setAssignError(response.data);
        Swal.close();
      } else if (response.status === 200) {
        setSelected([]);
        setOpenCard('');
        setAssignError({});
        Swal.fire({
          icon: 'success',
          title: `Assigned Shift Successfully`,
          text: response.data.message,
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true
        });
      }
    },
    onSettled: () => {
      cache.invalidateQueries('roster');
      cache.invalidateQueries('shifts');
      cache.invalidateQueries('roster-availability');
      cache.invalidateQueries('employees-workhours');
    }
  });

  /* ================= Remove From Shift Mutation ==================== */
  const { mutate: removeFromShiftMutation } = useMutation(removeFromShift, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Removing From Shift :)',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Removed Employee`,
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('roster');
      cache.invalidateQueries('roster-availability');
      cache.invalidateQueries('employees-workhours');
    }
  });

  const { mutate: updateWeekOffMutation } = useMutation(updateWeekOff, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Update Week Off...',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Updated WeekOffs`,
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true
      });
    }
  });

  /* ================= Assign Shift Mutation ================ */
  const changeSubRoleMutation = useMutation(updateSubRole, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Updating Sub Role :)',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // for unauthorized access
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      const msg = error.response
        ? error.response.data.message || error.toString()
        : error.toString();

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: msg,
        showConfirmButton: false
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Updated Sub Role`,
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('roster');
    }
  });

  /* ================== Delete Shift ========================== */
  const deleteShiftMutation = useMutation(deleteShift, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Deleted!`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('sites');
      cache.invalidateQueries('roster');
      cache.invalidateQueries('shifts');
      cache.invalidateQueries('roster-availability');
    }
  });

  /* ================== Auto Shift Replace ========================== */
  const autoShiftReplaceMutation = useMutationFunc(
    autoShiftReplace,
    {
      onMutate: {
        title: 'Finding replacement',
        text: 'this may take a while...'
      },
      onSuccess: {
        title: 'Successfully Completed!!'
      }
    },
    null,
    () => {
      cache.invalidateQueries('sites');
      cache.invalidateQueries('roster');
      cache.invalidateQueries('shifts');
      cache.invalidateQueries('roster-availability');
    },
    null,
    null
  );

  /* =============== Request Reforcement ====================== */
  // const requestReinforcementMutation = useMutation(requestReinforcement, {
  //   onMutate: () => {
  //     Swal.fire({
  //       icon: 'info',
  //       title: 'Hold on....',
  //       text: 'Requesting Reinforcement :)',
  //       allowOutsideClick: false,
  //       showConfirmButton: false,
  //       willOpen: () => {
  //         Swal.showLoading();
  //       }
  //     });
  //   },
  //   onError: (error) => {
  //     // for unauthorized access
  //     if (error.response && error.response.status === 401) {
  //       dispatch(logOut());
  //     }

  //     const msg = error.response
  //       ? error.response.data.message || error.toString()
  //       : error.toString();

  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Something went wrong!',
  //       text: msg,
  //       showConfirmButton: false
  //       // timer: 2000,
  //       // timerProgressBar: true
  //     });
  //   },
  //   onSuccess: () => {
  //     Swal.fire({
  //       icon: 'success',
  //       title: `Successfully Requested!`,
  //       showConfirmButton: false,
  //       timer: 4000,
  //       timerProgressBar: true
  //     });
  //   },
  //   onSettled: () => {
  //     cache.invalidateQueries('roster');
  //   }
  // });

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // const defaultLabelStyle = {
  //   fontSize: '6px',
  //   fontFamily: 'sans-serif'
  // };

  const [shiftPreferences, setShiftPreferences] = React.useState([]);

  const handlePreferencesClick = (event, id) => {
    const selectedIndex = shiftPreferences.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(shiftPreferences, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(shiftPreferences.slice(1));
    } else if (selectedIndex === shiftPreferences.length - 1) {
      newSelected = newSelected.concat(shiftPreferences.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        shiftPreferences.slice(0, selectedIndex),
        shiftPreferences.slice(selectedIndex + 1)
      );
    }
    setShiftPreferences(newSelected);
  };

  const {
    isLoading: isPreferencesLoading,
    isError: isPreferencesError,
    data: preferences
  } = useShiftPreferences(preferencesId);

  // React.useEffect(() => {
  //   if (preferencesId?.length > 0 && !isPreferencesLoading && !isPreferencesError) {
  //     const shiftp = preferences?.shifts?.map((sht) => sht._id);
  //     setShiftPreferences([...shiftp]);
  //     // const emp = rows.find((rr) => rr._id === preferencesId);
  //   }
  // }, [preferencesId, isPreferencesLoading, isPreferencesError, preferences, rows]);

  /* ================= Assign Shift Mutation ================ */
  const { mutate: updateShiftPreferencesMutation } = useMutation(updateShiftPreferences, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Updating Preferences !!',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // for unauthorized access
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      const msg = error.response
        ? error.response.data.message || error.toString()
        : error.toString();

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: msg,
        showConfirmButton: false
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: (response) => {
      Swal.fire({
        icon: 'success',
        title: `Preferences Updated Successfully`,
        text: response.message,
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('roster');
    }
  });

  /* ================= Roster Cards ==================== */

  const {
    // isCardsLoading,
    // isCardsError,
    data: employeesCards
  } = useEmployeesCards('sites', _siteData?.site._id); // getting employees cards

  const cardsData = useMemo(() => {
    const rdata = { labels: [], values: [] };
    const sdata = { labels: [], values: [] };
    if (allRoles.length > 0 && employeesCards?.employees.length > 0) {
      allRoles.forEach((role) => {
        if (role?.priority > smPriority) {
          const r = employeesCards.employees.find((rl) => rl.role === role._id) ?? {};
          rdata.labels.push(role.name);
          rdata.values.push(r?.total ?? 0);
          role?.subroles?.forEach((sr) => {
            sdata.labels.push(`${sr?.name} ( ${role.name} )`);
            sdata.values.push(r?.subroles?.find((svr) => sr?._id === svr?.subrole)?.total ?? 0);
          });
        }
      });
    }
    return { rdata, sdata };
  }, [allRoles, employeesCards, smPriority]);

  const CHART_OPTIONS_ROLE = merge(BaseOptionChart(), {
    colors: ['#E74C3C', '#2980B9', '#48C9B0', '#F39C12'],
    labels: cardsData.rdata.labels,
    stroke: { colors: ['#fff'] },
    legend: { position: 'right' },
    dataLabels: {
      enabled: true,
      dropShadow: { enabled: true },
      formatter: (val, opts) => opts.w.config.series[opts.seriesIndex]
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: { donut: { labels: { show: true } } }
    }
  });

  const CHART_OPTIONS_SUBROLE = merge(BaseOptionChart(), {
    colors: ['#E74C3C', '#2980B9', '#48C9B0', '#F39C12'],
    labels: cardsData.sdata.labels,
    stroke: { colors: ['#fff'] },
    legend: { position: 'right' },
    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false },
      formatter: (val, opts) => opts.w.config.series[opts.seriesIndex]
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: { donut: { labels: { show: true } } }
    }
  });

  // Auto Roster Mutation -------------------------------------------------------
  const [rosterCheck, setRosterCheck] = useState({
    rosterId: getDataFromStorage('rosterId') ?? null
  });
  const [inRoster, setInRoster] = useState(false); // Auto Roster State
  const autoRosterMutation = useMutationFunc(
    autoRoster,
    {
      onMutate: {
        default: false
      },
      onSuccess: {
        title: 'Successfully Completed!!'
      }
    },
    () => {
      console.log('On Success Roster');
      setDataInStorage('rosterId', '');
    },
    () => {
      cache.invalidateQueries('sites');
      cache.invalidateQueries('roster');
      cache.invalidateQueries('shifts');
      cache.invalidateQueries('roster-availability');
    },
    (mutateData) => {
      setInRoster(true);
      setDataInStorage('rosterId', '');
      setRosterCheck({
        ...rosterCheck,
        rosterId: mutateData.rosterId
      });
      setDataInStorage('rosterId', mutateData.rosterId);
    },
    () => {
      setDataInStorage('rosterId', '');
    }
  );

  // =-=================== ADD OVERTIME ===================
  const addOvertimeMutation = useMutationFunc(addOvertime, {
    onMutate: {
      default: false,
      text: 'Updating...'
    },
    toast: true
  });
  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    initialValues: {
      shift: {},
      hour: '',
      minute: '',
      date: '',
      employees: []
    },
    validationSchema: Yup.object({
      shift: Yup.object().required(),
      hour: Yup.number().notRequired(),
      minute: Yup.number().notRequired().min(0).max(59),
      date: Yup.string().required(),
      employees: Yup.array()
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        shift: values.shift,
        overtime: `${values.hour > 0 ? values.hour : '00'}:${
          values.minute > 0 ? values.minute : '00'
        }`,
        date: values.date,
        employees: values.employees
      };

      console.log('Add Overtime Form :', data);
      addOvertimeMutation(data);
    }
  });

  const {
    // errors,
    // touched,
    values,
    // isSubmitting,
    handleSubmit
    // getFieldProps,
    // resetForm
  } = formik;

  const handleOvertime = (overtime) => {
    // Change on overtime value
    // console.log(overtime);
    values.shift = overtime.shift;
    values.date = overtime.date;
    values.employees = overtime.employees;
    if (values.minute !== null && values.minute !== undefined && values?.minute >= 0) {
      setTimeout(() => {
        handleSubmit();
      }, 2000);
    }
  };

  useEffect(() => {
    if (rosterCheck?.rosterId?.length > 0) {
      // client-side
      const socket = callSocket();
      const swal = Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Assigning employees :)',
        allowOutsideClick: false,
        showConfirmButton: true,
        html: `<div style="display:block;">
                <p style="text-align:center;margin-bottom:10px;"><strong>Starting...</strong><p>
                <div style="display:flex;justify-content:center;align-items:center;">
                  <div class="custom_progress progress--horizontal-soft">
                    <div class="custom_progress__bar"></div>
                  </div>
                  &emsp;
                  <div id="roster_completed" class="import_percentage"></div>
                </div>
                <div class="import_progress">
                  <p>Total Assigned</p><div id="total_assigned">0</div>
                  <p>Total Required</p><div id="total_requirement">0</div>
                </div>
                </div>`,
        didOpen: () => {
          const content = Swal.getHtmlContainer();
          const progressBars = content.querySelectorAll('.custom_progress__bar');
          function setProgress(progress) {
            progressBars.forEach((bar) => {
              Object.assign(bar.style, {
                transform: `scaleX(${progress / 100})`,
                /* standard & vertical */
                'background-position-y': `${progress.toString()}%`,
                /* horizontal */
                'background-position-x': `${progress.toString()}%`
              });
            });
          }
          const er = content.querySelector('strong');
          socket.on('disconnect', (reason) => {
            if (!navigator.onLine) {
              er.textContent = `Connection Lost!!, reconnecting...`;
            } else if (
              reason === 'io server disconnect' ||
              reason === 'ping timeout' ||
              reason === 'transport error'
            ) {
              // the disconnection was initiated by the server, you need to reconnect manually
              socket.connect();
            } else if (reason === 'transport close' && navigator.onLine) {
              er.textContent = `Error Uploading Data!!`;
              setDataInStorage('rosterId', '');
            }
          });
          // window.onbeforeunload = () => true;
          socket.on('progress', (status) => {
            if (
              status.type === 'autoRoster' &&
              status.user === info._id &&
              status.rosterId === rosterCheck.rosterId
            ) {
              // const $ = content.querySelector.bind(content);
              const progress = (status.currProgress * 100) / status.totalProgress;
              setProgress(progress);
              content.querySelector(
                'strong'
              ).textContent = `${status.currProgress} / ${status.totalProgress}`;
              content.querySelector('#total_assigned').textContent = `${status.totalAssigned}`;
              content.querySelector(
                '#total_requirement'
              ).textContent = `${status.totalRequirement}`;
              content.querySelector('#roster_completed').textContent = `${parseFloat(
                progress
              ).toFixed(0)}%`;
              if (progress === 100) {
                socket.disconnect();
                setDataInStorage('rosterId', '');
                if (!inRoster) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Successfull',
                    html: `<span>Successfully Completed!!<div style="color: red; font-weight: bold;margin: 0.5rem 0">IMPORTANT</div>
                    <mark>Total Assigned: ${status.totalAssigned}</mark><br/><br/>
                    <mark>Total Required: ${status.totalRequirement}</mark>
                    </span>`
                  });
                }
              }
            } else {
              socket.disconnect();
              setDataInStorage('rosterId', '');
              setTimeout(() => {
                swal.close();
              }, 1000);
            }
          });
        }
      });
    }
  }, [rosterCheck, info, inRoster]);
  // End Auto Roster Mutation -----------------------------------------

  const [anchorRosterFilterEl, setAnchorRosterFilterEl] = React.useState(null);

  // WeekOff Selector
  const [weekOffSelector, setWeekOffSelector] = useState({
    employee: '',
    week_off: []
  });

  const handleWeekOffSelector = (value) => {
    const _weekOff = weekOffSelector?.week_off;
    const _index = _weekOff.indexOf(value);
    if (_index !== -1) {
      _weekOff.splice(_index, 1);
    } else {
      _weekOff.push(value);
    }
    setWeekOffSelector({ ...weekOffSelector, week_off: _weekOff });
  };

  return (
    <Page title="Roster | Trackkar">
      <Box sx={{ position: 'relative' }}>
        <RosterListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          setFilters={setFilter}
          setRosterMonth={setRosterMonth}
          setSelReqDay={setSelReqDay}
          selReqDay={selReqDay}
          rosterMonth={rosterMonth}
          refetchRoster={refetchRoster}
          shiftId={modalShiftId}
          setShiftId={setModalShiftId}
          expandRoster={expandRoster}
          setExpandRoster={setExpandRoster}
          workDays={workDays}
          setWorkDays={setWorkDays}
          addMembers={() =>
            assignShiftMutation({
              shouldCheckConstraints: true,
              workdays: workDays.map((d) => `${d.year}-${d.month.number}-${d.day}`),
              today: getCurrentDate()
            })
          }
          addShift={() => {
            setEditShift(null);
            setOpenAddShift(true);
          }}
          week={week}
          setWeek={setWeek}
          selectedSite={selectedSite}
          viewRequirements={() => setOpenCard('site-requirements')}
          viewWorkflow={() => setOpenCard('workforce-charts')}
          viewAttendance={() => setOpenCard('attendance-charts')}
          viewConstraints={() => setOpenCard('site-profile')}
          requestWorkforce={() => setOpenCard('request-for-workforce-roster-card')}
          approveWorkforce={() => setOpenCard('approve-for-workforce-roster-card')}
          clearRoster={() => setOpenCard('clear-roster-modal')}
          addHoliday={() => setOpenCard('add-holidays-modal')}
          viewHolidays={() => setOpenCard('site-holidays-roster-card')}
          autoRoster={() =>
            Swal.fire({
              title: 'Are you sure want to run auto roster ?',
              text: ``,
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Yes, Proceed!',
              allowOutsideClick: false
            }).then(async (result) => {
              if (result.isConfirmed) {
                const data = await checkRosterBackwardPropagation(selectedSite);
                if (data?.status === 'YES') {
                  Swal.fire({
                    title: 'Future months roster will be cleared!!',
                    text: `Are you aure want to run auto roster ?`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Proceed!',
                    allowOutsideClick: false
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      autoRosterMutation({
                        rosterId: Date.now().toString(),
                        site: selectedSite,
                        date: convertUTCtoLocal(rosterMonth, true),
                        today: getCurrentDate()
                      });
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      cache.invalidateQueries();
                    }
                  });
                } else {
                  autoRosterMutation({
                    rosterId: Date.now().toString(),
                    site: selectedSite,
                    date: convertUTCtoLocal(rosterMonth, true),
                    today: getCurrentDate()
                  });
                }
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                cache.invalidateQueries();
              }
            })
          }
        />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Tooltip title="Add Shift">
                <IconButton
                  onClick={() => {
                    setEditShift(null);
                    setOpenAddShift(true);
                  }}
                >
                  <AddCircleIcon sx={{ color: theme.palette.primary.main }} />
                </IconButton>
              </Tooltip>
              {isShiftsLoading ? (
                <NotAvailable type="loading" left msg="Loading..." />
              ) : isShiftsError ? (
                <NotAvailable type="error" msg="Something Went Wrong!" />
              ) : allShifts.length > 0 ? (
                <Tabs
                  value={shiftTab}
                  onChange={(e) => setShiftTab(e.target.value)}
                  variant="scrollable"
                  scrollButtons
                  className="roster_days"
                  aria-label="visible arrows tabs example"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 }
                    }
                  }}
                >
                  {allShifts.map((shift, ix) => {
                    // console.log('Shift:', shift);
                    let sreqp = 0;
                    if (shift.requirements.length > 0) {
                      const d = shift.requirements.find((dy) => selReqDay?.date === dy.day);
                      sreqp =
                        d?.requirement.length > 0
                          ? Math.floor(
                              (100 * d?.available.reduce((s, obj) => s + obj.total, 0)) /
                                d?.requirement.reduce((s, obj) => s + obj.value, 0)
                            )
                          : 0;
                    }
                    sreqp = isNaN(sreqp) ? 0 : sreqp;
                    const weekOffDate = selReqDay?.wd ?? today.getDay();
                    const DAY_OFF =
                      shift?.holidays?.length > 0 ||
                      shift?.constraints?.weekly_off?.includes(weekOffDate);
                    return (
                      <Tab
                        key={`side-${shift._id}`}
                        value={ix}
                        label={
                          <Box
                            display="flex"
                            sx={{
                              border: `${
                                filter?.shift.split(',')[0] === shift._id
                                  ? '2px solid #0897FF'
                                  : '1px solid #E3EEEB'
                              }`,
                              background:
                                sreqp === 100
                                  ? theme.palette.lightgreen.main
                                  : sreqp < 50 && sreqp > 0
                                  ? theme.palette.lightyellow.main
                                  : sreqp > 100
                                  ? theme.palette.lightred.main
                                  : sreqp === 0
                                  ? 'white'
                                  : theme.palette.lighterblue.main,
                              cursor: 'pointer',
                              color: '#333 !important',
                              position: 'relative',
                              borderRadius: 0.5,
                              minWidth: 240,
                              mr: 1
                            }}
                          >
                            <Box
                              display="flex"
                              onClick={() =>
                                filter?.shift.split(',')[0] === shift._id
                                  ? setFilter({ ...filter, shift: '' })
                                  : setFilter({ ...filter, shift: `${shift._id},${shift.name}` })
                              }
                            >
                              <Stack sx={{ minWidth: 170 }}>
                                <Stack
                                  sx={{ px: 0.5 }}
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                                    {shift.name}
                                  </Typography>
                                </Stack>
                                <Stack
                                  sx={{ px: 0.5, mt: 0.1 }}
                                  direction="row"
                                  alignItems="center"
                                >
                                  <Chip
                                    size="small"
                                    color={
                                      sreqp === 100
                                        ? 'success'
                                        : sreqp < 50 && sreqp > 0
                                        ? 'warning'
                                        : sreqp > 100
                                        ? 'error'
                                        : sreqp === 0
                                        ? 'lightgrey'
                                        : 'secondary'
                                    }
                                    sx={{ height: 15, fontSize: 10, '& .MuiChip-label': { pr: 0 } }}
                                    label={
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <Typography sx={{ fontSize: 10 }}>
                                          {ordinal(formatDigit(selReqDay?.iso.split('-')[2]))}
                                        </Typography>
                                        <Chip
                                          size="small"
                                          sx={{
                                            height: 15,
                                            borderRadius: 0.7,
                                            minWidth: 50,
                                            fontSize: 10,
                                            ml: 1,
                                            backgroundColor: `${
                                              DAY_OFF ? theme.palette.error.dark : 'white'
                                            }`,
                                            color: `${DAY_OFF ? 'white' : '#333'}`,
                                            '& .MuiChip-label': { px: 0 }
                                          }}
                                          label={`${
                                            shift?.holidays?.length > 0 ||
                                            shift?.constraints?.weekly_off?.includes(weekOffDate)
                                              ? shift?.constraints?.weekly_off?.includes(
                                                  weekOffDate
                                                )
                                                ? 'WO'
                                                : 'H'
                                              : isNaN(sreqp) || sreqp === Infinity
                                              ? 0
                                              : `${sreqp} %`
                                          }`}
                                        />
                                      </Stack>
                                    }
                                  />
                                  <Typography component="span" sx={{ fontSize: 11, ml: 1 }}>
                                    ( {_12hourTimeFormat(shift.start)}&nbsp;-&nbsp;
                                    {_12hourTimeFormat(shift.end)} )
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Box>
                            {(info.isMainAdmin ||
                              info?.role?.priority >= _siteData?.site?.manager?.role?.priority) && (
                              <Box>
                                <Box
                                  sx={{
                                    px: 0.3,
                                    color: theme.palette.grey[700],
                                    height: 20
                                  }}
                                >
                                  <EditOutlinedIcon
                                    onClick={() => {
                                      setEditShift(shift);
                                      setOpenAddShift(true);
                                    }}
                                    sx={{ fontSize: 14 }}
                                  />
                                </Box>
                                {(info?.role?.is_site_manager || info?.isMainAdmin) && (
                                  <Box
                                    sx={{
                                      px: 0.3,
                                      height: 20,
                                      color: theme.palette.error.main
                                    }}
                                  >
                                    <DeleteOutlinedIcon
                                      onClick={() => {
                                        if (
                                          info._id === _siteData?.site?.manager._id ||
                                          info?.isMainAdmin
                                        ) {
                                          Swal.fire({
                                            title: 'Are you sure?',
                                            text: `All Employees from shift will be removed!`,
                                            icon: 'question',
                                            showCancelButton: true,
                                            confirmButtonText: 'Yes, Proceed!',
                                            allowOutsideClick: false
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              deleteShiftMutation.mutate({
                                                id: shift._id,
                                                today: getCurrentDate()
                                              });
                                              cache.invalidateQueries('shifts');
                                            } else if (
                                              result.dismiss === Swal.DismissReason.cancel
                                            ) {
                                              cache.invalidateQueries('shifts');
                                            }
                                          });
                                        } else {
                                          Swal.fire({
                                            title: 'Access Denied!',
                                            text: `Only Admin or Site Manager can delete this shift`,
                                            icon: 'warning',
                                            confirmButtonText: 'OK',
                                            allowOutsideClick: false
                                          });
                                        }
                                      }}
                                      sx={{ fontSize: 14 }}
                                    />
                                  </Box>
                                )}
                              </Box>
                            )}
                          </Box>
                        }
                      />
                    );
                  })}
                </Tabs>
              ) : (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ height: '4rem' }}
                  justifyContent="center"
                >
                  <Typography align="center">No Shifts Available</Typography>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" sx={{ height: '4rem' }} alignItems="center">
              <IconButton onClick={(event) => setAnchorRosterFilterEl(event.currentTarget)}>
                <FilterListIcon sx={{ fontSize: 20 }} />
              </IconButton>
              <Popover
                id="filter_on_roster"
                open={!!anchorRosterFilterEl}
                anchorEl={anchorRosterFilterEl}
                onClose={() => setAnchorRosterFilterEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <Stack spacing={1} width="320px" sx={{ p: 2 }}>
                  <Stack direction="row" alignItems="center">
                    <FilterListIcon />
                    <Typography sx={{ ml: 1 }}>Filters</Typography>
                  </Stack>
                  <Divider />
                  {/* From Date 
        <Box sx={{ display: 'flex', mt: 1, alignItems: 'flex-end' }}>
          <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <LocalizationProvider dateAdapter={AdapterDate}>
            <DatePicker
              startText="From"
              endText="To"
              value={filter?.range}
              onChange={(date) => {
                setFilter({ ...filter, range: date });
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField variant="standard" {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField variant="standard" {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
          &nbsp;
          {filter.from ? (
            <Tooltip title="Clear">
              <IconButton onClick={() => setFilter({ ...filter, from: '' })}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
        </Box> */}
                  {/* Status
        <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
          <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="status_filter">
              Choose Status
            </InputLabel>
            <Select
              variant="standard"
              value={filter.status}
              inputProps={{
                name: 'status',
                id: 'status_filter'
              }}
              onChange={(e) => setFilter({ ...filter, status: e.target.value })}
              sx={{ mt: 2 }}
            >
              <MenuItem value="" disabled>
                Choose Status
              </MenuItem>
              <MenuItem value="Verified">Verified</MenuItem>
              <MenuItem value="Unverified">Not Verified</MenuItem>
            </Select>
          </FormControl>
          &nbsp;
          {filter.status ? (
            <Tooltip title="Clear">
              <IconButton onClick={() => setFilter({ ...filter, status: '' })}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
        </Box> */}
                  {/* <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
          <LocationCityIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="grouped-select">
              Choose State
            </InputLabel>
            <Select
              variant="standard"
              value={filter.state}
              onChange={(e) => setFilter({ ...filter, state: e.target.value })}
              id="grouped-select"
              label="Choose State"
            >
              <MenuItem value="" disabled>
                Choose State and Union Territories
              </MenuItem>
              <ListSubheader>States</ListSubheader>
              {INDIAN?.states?.length > 0 ? (
                INDIAN?.states.map((key) => (
                  <MenuItem key={`${'state'}-${key}`} value={key}>
                    {key}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No State Available</em>
                </MenuItem>
              )}
              <ListSubheader>Union Territories</ListSubheader>
              {INDIAN.union_territories?.length > 0 ? (
                INDIAN.union_territories.map((key) => (
                  <MenuItem key={`${'territory'}-${key}`} value={key}>
                    {key}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No Territories Available</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
          &nbsp;
          {filter.state ? (
            <Tooltip title="Clear">
              <IconButton onClick={() => setFilter({ ...filter, state: '' })}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
        </Box> */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="role_filter">
                        Choose Role
                      </InputLabel>
                      <Select
                        variant="standard"
                        value={filter?.role}
                        inputProps={{
                          name: 'role_filter',
                          id: 'role_filter'
                        }}
                        onChange={(e) =>
                          setFilter({ ...filter, role: e.target.value, subRole: '' })
                        }
                        sx={{ mt: 2 }}
                      >
                        <MenuItem value="" disabled>
                          Choose Role
                        </MenuItem>
                        {roleOptions?.length > 0 ? (
                          roleOptions.map(([key, value, image]) => (
                            <MenuItem key={`${'role_filter'}-${key}`} value={key} data-icon={image}>
                              {value}
                            </MenuItem>
                          ))
                        ) : (
                          <>
                            <MenuItem value="" disabled />
                          </>
                        )}
                      </Select>
                    </FormControl>
                    &nbsp;
                    {filter.role ? (
                      <Tooltip title="Clear">
                        <IconButton onClick={() => setFilter({ ...filter, role: '', subRole: '' })}>
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </Box>
                  {/* Sub Role */}
                  {filter.role && subRoleOptions?.length > 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="role_type">
                          Choose Sub Role
                        </InputLabel>
                        <Select
                          variant="standard"
                          disabled={!(filter.role && subRoleOptions.length > 0)}
                          value={filter.subRole}
                          inputProps={{
                            name: 'sub_role_type',
                            id: 'sub_role_type'
                          }}
                          required
                          sx={{ mt: 2 }}
                          onChange={(e) => setFilter({ ...filter, subRole: e.target.value })}
                          // error={Boolean(touched.sub_role_type && errors.sub_role_type)}
                          // helperText={touched.policy_role && errors.policy_role}
                        >
                          <MenuItem value="" disabled>
                            Choose Sub Role
                          </MenuItem>
                          {subRoleOptions?.length > 0 ? (
                            subRoleOptions.map(([key, value, image]) => (
                              <MenuItem
                                key={`${'sub_role_type'}-${key}`}
                                value={key}
                                data-icon={image}
                              >
                                {value}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="" disabled />
                          )}
                        </Select>
                      </FormControl>
                      &nbsp;
                      {filter.subRole ? (
                        <Tooltip title="Clear">
                          <IconButton onClick={() => setFilter({ ...filter, subRole: '' })}>
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                    </Box>
                  ) : null}
                </Stack>
              </Popover>
              &nbsp;
              <Stack
                className="hide_scrollbar"
                direction="row"
                sx={{ overflowX: 'auto', borderRadius: 2 }}
              >
                <Chip
                  color="secondary"
                  label={`Role : ${filter.role.split(',')[1] ?? 'All'}`}
                  size="small"
                  // onDelete={() => setFilter({ ...filter, role: '' })}
                  // deleteIcon={
                  //   <Tooltip title="Clear">
                  //     <ClearIcon />
                  //   </Tooltip>
                  // }
                />
                &nbsp;
                <Chip
                  sx={{ backgroundColor: '#DC7633', color: 'white' }}
                  label={`Sub Role : ${filter.subRole.split(',')[1] ?? 'All'}`}
                  size="small"
                />
                {/* &nbsp; */}
                {/* <Chip
              sx={{ backgroundColor: '#138D75', color: 'white' }}
              label={`Site : ${filter.site.split(',')[1] ?? 'All'}`}
              size="small"
            />
            &nbsp;
            <Chip
              sx={{ backgroundColor: '#34495E', color: 'white' }}
              label={`Shift : ${filter.shift.split(',')[1] ?? 'All'}`}
              size="small"
            /> */}
                {filter.role ||
                // filters.state ||
                // filters.gender ||
                // filters.manager ||
                // filters.policy ||
                // filters.doj ||
                filter.subRole ? (
                  <Button
                    variant="text"
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      setFilter({
                        ...filter,
                        // doj: '',
                        // state: '',
                        // gender: '',
                        role: '',
                        subRole: ''
                        // manager: '',
                        // policy: ''
                      });
                    }}
                  >
                    Clear All
                  </Button>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={2}>
            <Stack
              direction="row"
              sx={{ height: '4rem' }}
              justifyContent="space-between"
              alignItems="center"
            >
              <ProgressIndicators />
              <Tooltip disabled={!!(week.start === 0)} title="Prev Week">
                <IconButton
                  onClick={() => {
                    setWeek({ ...week, start: week.start - 7 });
                    refetchRoster();
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Tooltip>
              <Typography align="center" sx={{ minWidth: 60 }}>
                {week.start === 0 && 'Week 1'}
                {week.start === 7 && 'Week 2'}
                {week.start === 14 && 'Week 3'}
                {week.start === 21 && 'Week 4'}
                {week.start === 28 && 'Week 5'}
              </Typography>
              <Tooltip disabled={!!(week.start === 28)} title="Next Week">
                <IconButton
                  onClick={() => {
                    setWeek({ ...week, start: week.start + 7 });
                    refetchRoster();
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer
          id="main-roster-table-container"
          sx={{
            minWidth: 800,
            height: `${expandRoster ? 'calc(100vh - 13rem)' : 'calc(100vh - 13.60rem)'}`
          }}
        >
          <Table stickyHeader>
            <RoasterListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              rosterMonth={rosterMonth}
              showEmpInfo={showEmpInfo}
              setShowEmpInfo={setShowEmpInfo}
              refetchRoster={refetchRoster}
              week={week}
              setWeek={setWeek}
              gaps={GAPS}
              selReqDay={selReqDay}
              setSelReqDay={setSelReqDay}
            />
            <TableBody>
              {isRosterLoading ? (
                <TableRow>
                  <TableCell colSpan={32} align="center">
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              ) : isRosterError ? (
                <TableRow>
                  <TableCell colSpan={12} align="center" sx={{ color: 'red' }}>
                    <b>Something went wrong !</b>
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row, index) => {
                  const isItemSelected = selected.indexOf(row._id) !== -1;
                  return (
                    <TableRow
                      hover
                      key={row._id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, row._id)}
                          />
                        </TableCell> */}
                      <TableCell sx={{ position: 'sticky', left: 0, backgroundColor: 'white' }}>
                        {(index += 1)}
                      </TableCell>
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: 0,
                          backgroundColor: 'white',
                          zIndex: 8
                        }}
                        scope="row"
                      >
                        <Stack>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar
                              onClick={() => setImageSrc(row.avatar)}
                              alt={row.name}
                              src={row.avatar}
                              sx={{ mr: 1, cursor: 'pointer' }}
                            />
                            <Typography sx={{ mr: 2 }}>{row.name}</Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{ my: 0.3, display: `${showEmpInfo ? '' : 'none'}` }}
                          >
                            {row?.supervising_shifts?.length > 0 && (
                              <Chip
                                sx={{
                                  fontSize: 10.5,
                                  height: 18,
                                  my: 0.3,
                                  mr: 0.3,
                                  backgroundColor: '#9156E7',
                                  color: 'white'
                                }}
                                label="SUP"
                              />
                            )}
                            <Chip
                              component="div"
                              size="small"
                              variant="outlined"
                              color="secondary"
                              sx={{ fontSize: 10.5, my: 0.3, mr: 0.3, height: 18 }}
                              label={row.role.name}
                            />
                            {row?.subrole ? (
                              <Chip
                                avatar={
                                  <PopupState variant="popover" popupId="demo-popup-popover">
                                    {(popupState) => (
                                      <div>
                                        <Tooltip title="Change Sub Role">
                                          <Avatar
                                            sx={{
                                              cursor: 'pointer',
                                              width: '14px',
                                              height: '14px',
                                              ml: 0.5,
                                              backgroundColor: '#DC7633'
                                            }}
                                          >
                                            <EditIcon
                                              onClick={() => {
                                                setRoleType(row.role._id);
                                                popupState.open();
                                              }}
                                              sx={{ fontSize: 11 }}
                                            />
                                          </Avatar>
                                        </Tooltip>
                                        <Popover
                                          {...bindPopover(popupState)}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center'
                                          }}
                                        >
                                          <Stack width="320px" sx={{ p: 2 }}>
                                            <Typography sx={{ mb: 1 }}>
                                              Update Sub Role ( {row.name} )
                                            </Typography>
                                            <Divider />
                                            {/* Sub Role */}
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                mt: 1
                                              }}
                                            >
                                              <AddModeratorIcon
                                                sx={{
                                                  color: 'action.active',
                                                  mr: 1,
                                                  my: 0.5
                                                }}
                                              />
                                              <FormControl fullWidth>
                                                <InputLabel
                                                  variant="standard"
                                                  htmlFor="change_sub_role"
                                                >
                                                  Choose Sub Role
                                                </InputLabel>
                                                <Select
                                                  variant="standard"
                                                  value={subRole}
                                                  inputProps={{
                                                    name: 'change_sub_role',
                                                    id: 'change_sub_role'
                                                  }}
                                                  onChange={(e) => setSubRole(e.target.value)}
                                                  sx={{ mt: 2 }}
                                                >
                                                  <MenuItem value="" disabled>
                                                    Choose Sub Role
                                                  </MenuItem>
                                                  {subRoleOptions?.length > 0 ? (
                                                    subRoleOptions.map(([key, value]) => (
                                                      <MenuItem
                                                        key={`${'change_sub_role'}-${key}`}
                                                        value={key}
                                                      >
                                                        {value}
                                                      </MenuItem>
                                                    ))
                                                  ) : (
                                                    <>
                                                      <MenuItem value="" disabled />
                                                    </>
                                                  )}
                                                </Select>
                                              </FormControl>
                                            </Box>
                                            <Button
                                              sx={{ mt: 2 }}
                                              variant="contained"
                                              onClick={() => {
                                                popupState.close();
                                                Swal.fire({
                                                  title: `Are you sure ?`,
                                                  text: `Update Sub Role ?`,
                                                  icon: 'question',
                                                  showCancelButton: true,
                                                  confirmButtonText: 'Yes, Proceed!',
                                                  allowOutsideClick: false
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    changeSubRoleMutation.mutate({
                                                      employee: row._id,
                                                      subrole: subRole
                                                    });
                                                  } else if (
                                                    result.dismiss === Swal.DismissReason.cancel
                                                  ) {
                                                    cache.invalidateQueries('roster');
                                                  }
                                                });
                                              }}
                                            >
                                              Update
                                            </Button>
                                          </Stack>
                                        </Popover>
                                      </div>
                                    )}
                                  </PopupState>
                                }
                                component="div"
                                size="small"
                                variant="outlined"
                                sx={{
                                  fontSize: 10.5,
                                  my: 0.3,
                                  height: 18,
                                  borderColor: '#DC7633'
                                }}
                                label={row.subrole.name}
                              />
                            ) : null}
                            {/* {row?.tag && (
                              <Chip
                                component="div"
                                size="small"
                                variant="outlined"
                                color="lpurple"
                                sx={{ fontSize: 10.5, my: 0.3, mr: 0.3, height: 18 }}
                                label={row?.tag}
                              />
                            )} */}
                          </Stack>
                          <Box sx={{ mt: 0.5 }} display="flex">
                            {allShifts.length > 0 && (
                              <Tooltip placement="top" title="Shift Preferences">
                                <Chip
                                  onClick={(e) => {
                                    setPreferencesId(row._id);
                                    if (row?.shift_preferences) {
                                      const sh = row?.shift_preferences?.map((st) => st._id);
                                      setShiftPreferences([...sh]);
                                    }
                                    // console.log(preferencesId);
                                    setAnchorEl({
                                      id: 'employee-shift-preferences',
                                      target: e.currentTarget
                                    });
                                  }}
                                  size="small"
                                  icon={<ManageHistoryIcon size="inherit" />}
                                  label={`${row?.shift_preferences?.length}`}
                                />
                              </Tooltip>
                            )}
                            <Tooltip title="Week Off">
                              <Chip
                                size="small"
                                onClick={(e) => {
                                  setWeekOffSelector({
                                    employee: row._id,
                                    week_off: row?.week_off ?? []
                                  });
                                  setAnchorEl({
                                    id: 'employee-week-off',
                                    target: e.currentTarget
                                  });
                                }}
                                sx={{ ml: 2, cursor: 'pointer' }}
                                icon={<WorkOffIcon />}
                                label={row?.week_off?.length ?? 0}
                              />
                            </Tooltip>
                          </Box>
                        </Stack>
                      </TableCell>
                      {row.roster.map((ob) => (
                        <TableCell
                          sx={{
                            p: 0.5,
                            backgroundColor: `${
                              ob.iso === convertUTCtoLocal(today, true)
                                ? theme.palette.lighterblue.main
                                : ob?.isLeave
                                ? theme.palette.warning.lighter
                                : ''
                            }`
                          }}
                          key={`${row._id}_${ob.date}_${ob.iso}`}
                          align="center"
                        >
                          {ob?.isLeave ? (
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                              spacing={1}
                              sx={{ position: 'relative' }}
                            >
                              <Stack justifyContent="center" alignItems="center">
                                <Typography
                                  sx={{ color: theme.palette.warning.dark, fontSize: 15 }}
                                >
                                  <b>Leave</b>
                                </Typography>
                                <Typography component="span" sx={{ fontSize: 12 }}>
                                  {`( ${
                                    ob?.schedule?.find((sh) => sh.status === 'Leave')?.leave?.reason
                                  } )`}
                                </Typography>
                              </Stack>
                              {ob.schedule.find((sh) => !!sh?.shift) && (
                                <PopupState variant="popper" popupId="demo-popup-popper">
                                  {(popupState) => (
                                    <div>
                                      <IconButton
                                        sx={{ position: 'absolute', top: 0, right: 0, p: 0.2 }}
                                        {...bindTrigger(popupState)}
                                      >
                                        <InfoIcon sx={{ fontSize: 16 }} />
                                      </IconButton>

                                      <Popover {...bindPopover(popupState)}>
                                        <Stack justifyContent="center">
                                          {Object.keys(ob?.schedule).length === 0 &&
                                          new Date(
                                            ob.date
                                              .split('-')
                                              .map((a) => parseInt(a, 10))
                                              .flat()
                                          ).setHours(0, 0, 0, 0) <
                                            new Date().setHours(0, 0, 0, 0) ? (
                                            <Typography>-</Typography>
                                          ) : (
                                            <Stack
                                              direction="row"
                                              justifyContent="center"
                                              alignItems="center"
                                            >
                                              {ob?.schedule?.length > 0 ? (
                                                <Stack sx={{ my: 1.5 }}>
                                                  {ob.schedule?.map((schl) => (
                                                    <React.Fragment key={`leave-${schl?._id}`}>
                                                      {schl?.shift ? (
                                                        <Stack
                                                          sx={{
                                                            minWidth: 127,
                                                            p: 0.5,
                                                            mt: 2.5,
                                                            mb: 0.5,
                                                            position: 'relative',
                                                            border: `${
                                                              schl?.shift?.site?._id ===
                                                              selectedSite
                                                                ? '1px solid #F5EEF8'
                                                                : '1px solid #E3EEEB'
                                                            }`,
                                                            backgroundColor: `${
                                                              schl?.shift?.site?._id ===
                                                              selectedSite
                                                                ? '#F5EEF8'
                                                                : schl?.shift?.status ===
                                                                  'Cancelled'
                                                                ? '#FADBD8'
                                                                : 'white'
                                                            }`,
                                                            borderRadius: 0.5
                                                          }}
                                                          key={`table_${row._id}_${schl?.shift?._id}_${ob?.schedule?._id}`}
                                                        >
                                                          <Stack
                                                            direction="row"
                                                            // justifyContent="center"
                                                            sx={{
                                                              position: 'absolute',
                                                              width: '100%',
                                                              top: -18
                                                            }}
                                                          >
                                                            {schl?.shift?.status === 'Active' ? (
                                                              <Chip
                                                                size="small"
                                                                sx={{
                                                                  fontSize: 11,
                                                                  height: 18,
                                                                  borderRadius: 0.4,
                                                                  fontWeight: 'bold',
                                                                  backgroundColor: `${
                                                                    schl?.status === 'Active'
                                                                      ? 'green'
                                                                      : schl?.status ===
                                                                        'Unavailable'
                                                                      ? '#F39C12'
                                                                      : '#C0392B'
                                                                  }`,
                                                                  color: 'white'
                                                                }}
                                                                label={schl?.status}
                                                              />
                                                            ) : (
                                                              <Chip
                                                                size="small"
                                                                sx={{
                                                                  fontSize: 11,
                                                                  height: 18,
                                                                  borderRadius: 0.4,
                                                                  backgroundColor: `#34495E`,
                                                                  color: 'white'
                                                                }}
                                                                label="Archived"
                                                              />
                                                            )}
                                                          </Stack>
                                                          <Stack direction="row">
                                                            <Stack>
                                                              <Tooltip
                                                                placement="top"
                                                                title={schl?.shift?.site?.name}
                                                              >
                                                                <Typography
                                                                  textAlign="left"
                                                                  sx={{
                                                                    fontSize: 11,
                                                                    color: '#0897FF'
                                                                  }}
                                                                >
                                                                  <b>
                                                                    {schl?.shift?.site?.name
                                                                      ?.length > 12
                                                                      ? `${schl?.shift?.site?.name.substring(
                                                                          0,
                                                                          14
                                                                        )}...`
                                                                      : schl?.shift?.site?.name}
                                                                  </b>
                                                                </Typography>
                                                              </Tooltip>
                                                              <Stack direction="row">
                                                                <Stack justifyContent="left">
                                                                  <Typography
                                                                    textAlign="left"
                                                                    sx={{
                                                                      fontSize: 11,
                                                                      fontWeight: 'bold'
                                                                    }}
                                                                  >
                                                                    {schl?.shift?.name}
                                                                  </Typography>
                                                                </Stack>
                                                              </Stack>
                                                              {/* <Typography
                                                        textAlign="left"
                                                        component="span"
                                                        sx={{ fontSize: 9 }}
                                                      >
                                                        {_12hourTimeFormat(schl?.shift?.start)}
                                                        &nbsp;-&nbsp;
                                                        {_12hourTimeFormat(schl?.shift?.end)}
                                                      </Typography> */}
                                                            </Stack>
                                                          </Stack>
                                                        </Stack>
                                                      ) : (
                                                        <Typography sx={{ px: 2, py: 1 }}>
                                                          Leave with no Shift
                                                        </Typography>
                                                      )}
                                                    </React.Fragment>
                                                  ))}
                                                </Stack>
                                              ) : null}
                                            </Stack>
                                          )}
                                        </Stack>
                                      </Popover>
                                    </div>
                                  )}
                                </PopupState>
                              )}
                            </Stack>
                          ) : (
                            <Stack justifyContent="center">
                              {Object.keys(ob?.schedule).length === 0 &&
                              new Date(
                                ob.date
                                  .split('-')
                                  .map((a) => parseInt(a, 10))
                                  .flat()
                              ).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) ? (
                                <Typography>-</Typography>
                              ) : (
                                <>
                                  <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ mb: 1 }}
                                  >
                                    <Button
                                      sx={{
                                        fontSize: 11,
                                        py: 0.5,
                                        px: 1,
                                        // boxShadow: 'none',
                                        borderRadius: 0.5
                                        // color: '#0897FF'
                                      }}
                                      onClick={() => {
                                        setAvailabilityDate(ob?.date);
                                        // Assign Shift Data State
                                        setAssignShiftData({
                                          ...assignShiftData,
                                          _id: row?._id,
                                          name: row?.name,
                                          role: row?.role?.name,
                                          subrole: row?.subrole?.name,
                                          priority: row?.role?.priority,
                                          date: ob?.date,
                                          schedule: ob?.schedule
                                        });
                                        setOpenCard('assign-shift-roster-card');
                                      }}
                                    >
                                      <AddBoxIcon />
                                      &nbsp;Schedule
                                    </Button>
                                    {/* &nbsp;
                                        {new Date(
                                          ob.iso
                                            .split('-')
                                            .map((a) => parseInt(a, 10))
                                            .flat()
                                        ).setHours(0, 0, 0, 0) >=
                                        new Date().setHours(0, 0, 0, 0) ? (
                                          <Button
                                            sx={{
                                              fontSize: 11,
                                              py: 0,
                                              px: 1,
                                              minWidth: 84,
                                              // boxShadow: 'none',
                                              borderRadius: 0.5,
                                              color: '#34495E'
                                            }}
                                            onClick={() => {
                                              setAnchorEl({ id: null, target: null });
                                              Swal.fire({
                                                title: 'Are you sure ?',
                                                text: `Add ${ob.iso} as Day Off for ${row.name}`,
                                                icon: 'question',
                                                showCancelButton: true,
                                                confirmButtonText: 'Yes, Proceed!',
                                                allowOutsideClick: false
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  addDayOffMutation.mutate({
                                                    id: row._id,
                                                    days: [ob.iso]
                                                  });
                                                } else if (
                                                  result.dismiss === Swal.DismissReason.cancel
                                                ) {
                                                  cache.invalidateQueries('shifts');
                                                }
                                              });
                                            }}
                                          >
                                            <AddBoxIcon />
                                            &nbsp;Off
                                          </Button>
                                        ) : null} */}
                                  </Stack>
                                  <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    {ob?.schedule?.attendance ? (
                                      <HtmlTooltip
                                        arrow
                                        placement="top"
                                        title={
                                          <Stack>
                                            <Typography>
                                              IN :{' '}
                                              {ob?.schedule?.attendance?.in
                                                ? _12hourTimeFormat(
                                                    ob?.schedule?.attendance?.in?.time
                                                  )
                                                : 'NA'}
                                            </Typography>
                                            <Typography>
                                              OUT :{' '}
                                              {ob?.schedule?.attendance?.out
                                                ? _12hourTimeFormat(
                                                    ob?.schedule?.attendance?.out?.time
                                                  )
                                                : 'NA'}
                                            </Typography>
                                          </Stack>
                                        }
                                      >
                                        <Typography
                                          sx={{
                                            color: `${
                                              ob.schedule.attendance.in.time ? 'green' : 'red'
                                            }`,
                                            fontWeight: 'bold'
                                          }}
                                        >
                                          {ob.schedule.attendance.in.time ? 'P' : 'A'}
                                        </Typography>
                                      </HtmlTooltip>
                                    ) : ob?.schedule?.length > 0 ? (
                                      <Stack sx={{ my: 1.5 }}>
                                        {ob.schedule?.map((schl) => {
                                          // const sreqp =
                                          //   shift?.requirement?.length > 0 && shift?.available
                                          //     ? Math.floor(
                                          //         (100 *
                                          //           shift.available.reduce(
                                          //             (s, obj) => s + obj.total,
                                          //             0
                                          //           )) /
                                          //           shift.requirement.reduce(
                                          //             (s, obj) => s + obj.value,
                                          //             0
                                          //           )
                                          //       )
                                          //     : 0;
                                          const shiftDate = ob?.date?.split('-');
                                          const shiftTime = schl?.shift?.end.split(':');
                                          const dtime = new Date(
                                            shiftDate[0],
                                            Number(shiftDate[1]) - 1,
                                            shiftDate[2],
                                            shiftTime[0],
                                            shiftTime[1],
                                            0,
                                            0
                                          );
                                          const overtimeHour = schl?.overtime?.split(':')[0];
                                          const overtimeMinute = schl?.overtime?.split(':')[1];
                                          return (
                                            <Stack
                                              sx={{
                                                minWidth: 127,
                                                p: 0.5,
                                                mt: 2.5,
                                                mb: 0.5,
                                                position: 'relative',
                                                border: `${
                                                  schl?.shift?.site._id === selectedSite
                                                    ? '1px solid #F5EEF8'
                                                    : '1px solid #E3EEEB'
                                                }`,
                                                backgroundColor: `${
                                                  schl?.shift?.site._id === selectedSite
                                                    ? '#F5EEF8'
                                                    : schl?.shift?.status === 'Cancelled'
                                                    ? '#FADBD8'
                                                    : 'white'
                                                }`,
                                                borderRadius: 0.5
                                              }}
                                              key={`table_${row._id}_${schl?.shift._id}_${ob.schedule._id}`}
                                            >
                                              <Stack
                                                direction="row"
                                                // justifyContent="center"
                                                sx={{
                                                  position: 'absolute',
                                                  width: '100%',
                                                  top: -18
                                                }}
                                              >
                                                {schl?.shift.status === 'Active' ? (
                                                  <Chip
                                                    size="small"
                                                    sx={{
                                                      fontSize: 11,
                                                      height: 18,
                                                      borderRadius: 0.4,
                                                      fontWeight: 'bold',
                                                      backgroundColor: `${
                                                        schl?.status === 'Active'
                                                          ? 'green'
                                                          : schl?.status === 'Unavailable'
                                                          ? '#F39C12'
                                                          : '#C0392B'
                                                      }`,
                                                      color: 'white'
                                                    }}
                                                    label={schl?.status}
                                                  />
                                                ) : (
                                                  <Chip
                                                    size="small"
                                                    sx={{
                                                      fontSize: 11,
                                                      height: 18,
                                                      borderRadius: 0.4,
                                                      backgroundColor: `#34495E`,
                                                      color: 'white'
                                                    }}
                                                    label="Archived"
                                                  />
                                                )}
                                              </Stack>
                                              <Stack direction="row">
                                                <Stack>
                                                  <Tooltip
                                                    placement="top"
                                                    title={schl?.shift?.site?.name}
                                                  >
                                                    <Typography
                                                      textAlign="left"
                                                      sx={{ fontSize: 11, color: '#0897FF' }}
                                                    >
                                                      <b>
                                                        {schl?.shift?.site?.name?.length > 12
                                                          ? `${schl?.shift?.site?.name.substring(
                                                              0,
                                                              14
                                                            )}...`
                                                          : schl?.shift?.site?.name}
                                                      </b>
                                                    </Typography>
                                                  </Tooltip>
                                                  <Stack direction="row">
                                                    <Stack justifyContent="left">
                                                      <Typography
                                                        textAlign="left"
                                                        sx={{ fontSize: 11, fontWeight: 'bold' }}
                                                      >
                                                        {schl?.shift.name}
                                                      </Typography>
                                                    </Stack>
                                                  </Stack>
                                                  <Typography
                                                    textAlign="left"
                                                    component="span"
                                                    sx={{ fontSize: 9 }}
                                                  >
                                                    {_12hourTimeFormat(schl?.shift.start)}
                                                    &nbsp;-&nbsp;
                                                    {_12hourTimeFormat(schl?.shift.end)}
                                                  </Typography>
                                                  <Stack sx={{ fontSize: 9 }} direction="row">
                                                    AT:&nbsp;
                                                    <Typography
                                                      align="left"
                                                      sx={{
                                                        fontSize: 9,
                                                        color: 'green'
                                                      }}
                                                    >
                                                      {schl?.attendance?.in?.time
                                                        ? _12hourTimeFormat(
                                                            schl?.attendance.in.time
                                                          )
                                                        : 'N/A'}
                                                    </Typography>
                                                    &nbsp;-&nbsp;
                                                    <Typography
                                                      align="left"
                                                      sx={{ fontSize: 9, color: 'red' }}
                                                    >
                                                      {schl?.attendance?.out?.time
                                                        ? _12hourTimeFormat(
                                                            schl?.attendance.out.time
                                                          )
                                                        : 'N/A'}
                                                    </Typography>
                                                  </Stack>
                                                  {(schl?.shift.status === 'Active' ||
                                                    schl?.shift.status === 'Overtime') &&
                                                    dtime.getTime() > new Date().getTime() && (
                                                      <Stack
                                                        id={`${row._id}-${schl?.shift._id}-${ob.schedule._id}-ot`}
                                                        sx={{
                                                          height: 20,
                                                          minWidth: 80,
                                                          mt: 0.5
                                                          // backgroundColor: 'white'
                                                        }}
                                                        direction="row"
                                                        justifyContent="left"
                                                        alignItems="center"
                                                      >
                                                        <Typography sx={{ fontSize: 11, mr: 0.5 }}>
                                                          OT
                                                        </Typography>
                                                        <select
                                                          onChange={(e) => {
                                                            values.hour = e.target.value;
                                                          }}
                                                          defaultValue={overtimeHour}
                                                        >
                                                          <option>HH</option>
                                                          <option>00</option>
                                                          <option>01</option>
                                                          <option>02</option>
                                                          <option>03</option>
                                                          <option>04</option>
                                                          <option>05</option>
                                                          <option>06</option>
                                                          <option>07</option>
                                                          <option>08</option>
                                                          <option>09</option>
                                                          <option>10</option>
                                                          <option>11</option>
                                                          <option>12</option>
                                                        </select>
                                                        &nbsp;
                                                        <select
                                                          onChange={(e) => {
                                                            values.minute = e.target.value;
                                                            handleOvertime({
                                                              shift: {
                                                                id: schl?.shift._id,
                                                                name: schl?.shift.name
                                                              },
                                                              date: ob?.date,
                                                              employees: [row._id],
                                                              hour: values.hour ?? overtimeHour,
                                                              minute: e.target.value
                                                            });
                                                          }}
                                                          defaultValue={overtimeMinute}
                                                        >
                                                          <option>MM</option>
                                                          <option>00</option>
                                                          {_minutes_.map((mn, indx) => (
                                                            <option key={indx}>
                                                              {mn > 9 ? mn : `0${mn}`}
                                                            </option>
                                                          ))}
                                                        </select>
                                                      </Stack>
                                                    )}
                                                </Stack>
                                                &emsp;
                                                <Stack spacing={1}>
                                                  {new Date(
                                                    ob?.date
                                                      .split('-')
                                                      .map((a) => parseInt(a, 10))
                                                      .flat()
                                                  ).setHours(0, 0, 0, 0) >=
                                                    new Date().setHours(0, 0, 0, 0) &&
                                                  (info._id === schl?.shift?.site?.manager ||
                                                    info?.isMainAdmin) &&
                                                  (schl?.status === 'Active' ||
                                                    schl?.status === 'Overtime') ? (
                                                    <>
                                                      <DeleteOutlinedIcon
                                                        width={16}
                                                        height={16}
                                                        style={{
                                                          color: 'red',
                                                          cursor: 'pointer'
                                                        }}
                                                        onClick={() => {
                                                          Swal.fire({
                                                            title: `Are you sure to remove "${row.name}" from ${schl?.shift.name}?`,
                                                            text: "You won't be able to revert this!",
                                                            icon: 'question',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Yes, Proceed!',
                                                            allowOutsideClick: false
                                                          }).then((result) => {
                                                            console.log(result.isConfirmed);
                                                            if (result.isConfirmed) {
                                                              Swal.fire({
                                                                html: `<p style="text-align:center;"><span style="color:#0076B6;">${
                                                                  row.name
                                                                }</span><br /><br />for<br /><br />${ob.date
                                                                  .split('-')
                                                                  .reverse()
                                                                  .join(
                                                                    '/'
                                                                  )} or Upcoming or Forever ?</p>`,
                                                                title: `Remove From ${schl?.shift?.name}`,
                                                                icon: 'question',
                                                                showCancelButton: true,
                                                                showDenyButton: true,
                                                                denyButtonText: `${ob.date} & Upcoming`,
                                                                cancelButtonText: `Forever`,
                                                                confirmButtonText: `${ob.date
                                                                  .split('-')
                                                                  .reverse()
                                                                  .join('/')}`,
                                                                allowOutsideClick: true
                                                              }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                  removeFromShiftMutation({
                                                                    shift: schl?.shift._id,
                                                                    employees: [row._id],
                                                                    days: [ob.date],
                                                                    isCurrentAndFuture: false,
                                                                    isPermanent: false,
                                                                    today: getCurrentDate()
                                                                  });
                                                                } else if (
                                                                  result.dismiss ===
                                                                  Swal.DismissReason.cancel
                                                                ) {
                                                                  removeFromShiftMutation({
                                                                    shift: schl?.shift._id,
                                                                    employees: [row._id],
                                                                    days: [ob.date],
                                                                    isCurrentAndFuture: false,
                                                                    isPermanent: true,
                                                                    today: getCurrentDate()
                                                                  });
                                                                } else if (result.isDenied) {
                                                                  removeFromShiftMutation({
                                                                    shift: schl?.shift._id,
                                                                    employees: [row._id],
                                                                    days: [ob.DatePicker],
                                                                    isCurrentAndFuture: true,
                                                                    isPermanent: false,
                                                                    today: getCurrentDate()
                                                                  });
                                                                }
                                                              });
                                                            } else if (
                                                              result.dismiss ===
                                                              Swal.DismissReason.cancel
                                                            ) {
                                                              cache.invalidateQueries('roster');
                                                            }
                                                          });
                                                        }}
                                                      />
                                                      {!schl?.shift?.attendance && (
                                                        <IconButton
                                                          sx={{ p: 0.2 }}
                                                          onClick={() => {
                                                            Swal.fire({
                                                              title: `Are you sure ?`,
                                                              text: `Want to replace ${row.name} ?`,
                                                              icon: 'question',
                                                              showCancelButton: true,
                                                              confirmButtonText: 'Yes, Proceed!',
                                                              allowOutsideClick: false
                                                            }).then((result) => {
                                                              if (result.isConfirmed) {
                                                                autoShiftReplaceMutation({
                                                                  id: schl?._id,
                                                                  time: getCurrentTime(),
                                                                  today: getCurrentDate()
                                                                });
                                                              } else if (
                                                                result.dismiss ===
                                                                Swal.DismissReason.cancel
                                                              ) {
                                                                cache.invalidateQueries('roster');
                                                              }
                                                            });
                                                          }}
                                                        >
                                                          <SwapCallsIcon sx={{ fontSize: 17 }} />
                                                        </IconButton>
                                                      )}
                                                    </>
                                                  ) : null}
                                                </Stack>
                                              </Stack>
                                              <Stack direction="row" justifyContent="center">
                                                <Button
                                                  variant="contained"
                                                  sx={{ p: 0, boxShadow: 'none', lineHeight: 0 }}
                                                >
                                                  -
                                                </Button>
                                              </Stack>
                                            </Stack>
                                          );
                                        })}
                                      </Stack>
                                    ) : null}
                                  </Stack>
                                </>
                              )}
                            </Stack>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
            {!isRosterLoading && !isRosterError && rows.length === 0 && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={14} sx={{ py: 3 }}>
                    {filterName.length > 0 ? (
                      <SearchNotFound searchQuery={filterName} />
                    ) : (
                      <NotAvailable
                        type="na"
                        msg="No Employeess Available, Please add some to view"
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          {/* Put the scroll bar always on the bottom */}
          <InfiniteScroll
            dataLength={rows?.length}
            next={fetchNextRoster}
            // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
            // inverse //
            hasMore={hasNextRoster}
            loader={<h4>Loading...</h4>}
            scrollableTarget="main-roster-table-container"
            // endMessage={
            //   <p style={{ textAlign: 'center' }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
          />
        </TableContainer>
        <Button
          sx={{ display: 'none' }}
          id="site-profile-open"
          onClick={() => setOpenCard('site-profile')}
        />
        {/* {openCard === 'attendance-charts' && (
          <Card
            sx={{
              position: 'absolute',
              zIndex: 9,
              bottom: 0,
              right: 0,
              ml: 1.2,
              borderRadius: 1,
              height: 'calc(100vh - 150px)',
              width: '100%',
              backgroundColor: 'white',
              boxShadow: 'none'
            }}
          >
            <Box
              sx={{
                height: 35,
                p: 1,
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
              style={{ backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)' }}
            >
              <Typography>Attendance ( {selReqDay.iso} )</Typography>
              <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenCard('')} />
            </Box>
            {!isAvailabilityLoading && !isAvailabilityError ? (
              <Tabs
                // value={Number(getCurrentDate().split('-')[2] - 1)}
                value={attendanceTab}
                onChange={() => setAttendanceTab}
                className="roster_days"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable days tab"
              >
                {ShiftAvailability.map((shift, index) => {
                  const data = [];
                  const avl = shift.days.find((dy) => dy.date === selReqDay.date)?.available ?? [];
                  shift.days
                    .find((dy) => dy.date === selReqDay.date)
                    ?.requirement.forEach((rq) => {
                      const a = avl.find((av) => av.role._id === rq.role._id) ?? {};
                      data.push([rq?.role.name, rq.value, a?.total ?? 0, a?.att ?? 0]);
                      rq.subroles.forEach((sq) => {
                        const b = a?.subroles?.find((sa) => sa.subrole._id === sq.subrole._id);
                        data.push([sq?.subrole.name, sq.value, b?.total ?? 0, b?.att ?? 0]);
                      });
                    });
                  const _O = data.map((dt) => dt[0]);
                  const _R = data.map((dt) => dt[1]);
                  const _P = data.map((dt) => dt[2]);
                  const _A = data.map((dt) => dt[3]);
                  // console.log(_R, _P, _A);
                  // avl.forEach((av) => {
                  //   data.push([av?.role.name, av.total, av.att]);
                  //   av.subroles.forEach((su) => {
                  //     data.push([su?.subrole.name, su.total, su.att]);
                  //   });
                  // });
                  const chartOptions = merge(BaseOptionChart(), {
                    chart: {
                      type: 'bar'
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                      }
                    },
                    dataLabels: {
                      enabled: false
                    },
                    stroke: {
                      show: true,
                      width: 2,
                      colors: ['transparent']
                    },
                    xaxis: {
                      categories: _O
                    },
                    yaxis: {
                      title: {
                        text: 'Employees'
                      }
                    },
                    fill: {
                      opacity: 1
                    },
                    tooltip: {
                      y: {
                        formatter: function (val) {
                          return `${val}`;
                        }
                      }
                    }
                  });
                  const chartWidth = 115 * data.length - 1;
                  return (
                    <Tab
                      key={`attendance-${shift._id}-${index}`}
                      sx={{ maxWidth: '100%' }}
                      value={index}
                      label={
                        <Stack
                          sx={{
                            mx: 3,
                            borderTop: '2px solid #333',
                            mt: 2,
                            pt: 3
                          }}
                        >
                          <Typography
                            sx={{
                              position: 'absolute',
                              ml: 2,
                              top: -12,
                              px: 2,
                              backgroundColor: 'white'
                            }}
                          >
                            {shift.name}
                          </Typography>
                          <ReactApexChart
                            type="bar"
                            series={[
                              {
                                name: 'Requirement',
                                data: _R
                              },
                              {
                                name: 'Availability',
                                data: _P
                              },
                              {
                                name: 'Attendance',
                                data: _A
                              }
                            ]}
                            options={chartOptions}
                            height={290}
                            width={chartWidth}
                          />
                        </Stack>
                      }
                    />
                  );
                })}
              </Tabs>
            ) : (
              <NotAvailable type="na" msg="No Data Available" />
            )}
          </Card>
        )} */}
        <Requirements
          rosterMonth={rosterMonth}
          monthDate={monthDate}
          open={openCard === 'site-requirements'}
          onClose={() => setOpenCard('')}
          selectedSite={selectedSite}
          siteData={_siteData?.site}
          selReqDay={selReqDay}
          setSelReqDay={setSelReqDay}
        />
        {openCard === 'workforce-charts' && (
          <Card
            sx={{
              position: 'absolute',
              zIndex: 9,
              bottom: 0,
              right: 0,
              ml: 1.2,
              borderRadius: 1,
              height: 'calc(100vh - 155px)',
              width: '100%',
              boxShadow: 'none',
              backgroundColor: 'white'
            }}
          >
            <Box
              sx={{
                height: 35,
                p: 1,
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
              style={{ backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)' }}
            >
              <Typography>Workforce</Typography>
              {/* ( {selReqDay.iso} ) */}
              <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenCard('')} />
            </Box>
            <Stack sx={{ position: 'relative', pt: '3rem' }}>
              <Stack
                direction="row"
                justifyContent="right"
                sx={{ p: 2, position: 'absolute', right: 0, top: 0 }}
                spacing={1}
              >
                {info?.role?.is_site_manager && (
                  <Button
                    onClick={() => setOpenCard('request-for-workforce-roster-card')}
                    variant="outlined"
                    startIcon={<HomeWorkIcon />}
                  >
                    Request Workforce
                  </Button>
                )}
                {info?.role?.priority < _siteData?.site?.manager?.role?.priority && (
                  <Button
                    onClick={() => setOpenCard('approve-for-workforce-roster-card')}
                    variant="outlined"
                    startIcon={<HomeWorkIcon />}
                  >
                    Approve Workforce
                  </Button>
                )}
              </Stack>
              {cardsData.rdata.labels.length > 0 ? (
                <Stack
                  className="hide_scrollbar"
                  direction="row"
                  alignContent="center"
                  sx={{ overflowX: 'scroll' }}
                >
                  <Stack
                    sx={{
                      m: 2,
                      // boxShadow: theme.customShadows.bloated,
                      p: 1
                    }}
                  >
                    <Typography sx={{ mt: 2 }} align="center">
                      Roles
                    </Typography>
                    <ChartWrapperStyle dir="ltr">
                      <ReactApexChart
                        type="donut"
                        series={cardsData.rdata.values}
                        options={CHART_OPTIONS_ROLE}
                        height={300}
                      />
                    </ChartWrapperStyle>
                  </Stack>
                  <Stack sx={{ mx: 2 }}>
                    <Typography sx={{ mt: 2 }} align="center">
                      Sub Roles
                    </Typography>
                    <ChartWrapperStyle dir="ltr">
                      <ReactApexChart
                        type="donut"
                        series={cardsData.sdata.values}
                        options={CHART_OPTIONS_SUBROLE}
                        height={300}
                      />
                    </ChartWrapperStyle>
                  </Stack>
                </Stack>
              ) : (
                <NotAvailable msg="No Data Available" />
              )}
            </Stack>
          </Card>
        )}
        {openCard === 'site-profile' && (
          <Card
            sx={{
              position: 'absolute',
              zIndex: 9,
              bottom: 0,
              right: 0,
              ml: 1.2,
              borderRadius: 1,
              height: 'calc(100vh - 155px)',
              width: '100%',
              boxShadow: 'none',
              backgroundColor: 'white'
            }}
          >
            <Box
              sx={{
                height: 35,
                p: 1,
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
              style={{ backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)' }}
            >
              <Typography>Profile</Typography>
              <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenCard('')} />
            </Box>
            <SiteProfile site={_siteData?.site} rosterMonth={rosterMonth} />
          </Card>
        )}
        {openCard === 'request-for-workforce-roster-card' && (
          <Card
            id="request-for-workforce-roster-card"
            sx={{
              position: 'absolute',
              zIndex: 9,
              bottom: 0,
              right: 0,
              ml: 1.2,
              borderRadius: 1,
              height: 'calc(100vh - 155px)',
              width: '100%',
              boxShadow: 'none',
              backgroundColor: 'white'
            }}
            hidden={!(openCard === 'request-for-workforce-roster-card')}
          >
            <Box
              sx={{
                height: 35,
                p: 1,
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
              style={{ backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)' }}
            >
              <Typography>Request For Reinforcement</Typography>
              {/* ( {selReqDay.iso} ) */}
              <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenCard('')} />
            </Box>
            <RequestReinforcement siteId={selectedSite} handleClose={() => setOpenCard('')} />
          </Card>
        )}
        <Card
          id="approve-for-workforce-roster-card"
          sx={{
            position: 'absolute',
            zIndex: 9,
            bottom: 0,
            right: 0,
            ml: 1.2,
            borderRadius: 1,
            height: 'calc(100vh - 155px)',
            width: '100%',
            boxShadow: 'none',
            backgroundColor: 'white'
          }}
          hidden={!(openCard === 'approve-for-workforce-roster-card')}
        >
          <Box
            sx={{
              height: 35,
              p: 1,
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
            style={{ backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)' }}
          >
            <Typography>Approve Reinforcement</Typography>
            {/* ( {selReqDay.iso} ) */}
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenCard('')} />
          </Box>
          <Stack
            sx={{ overflowY: 'scroll', height: 'calc(100vh - 220px)' }}
            className="custom_scrollbar"
          >
            <GetReinforcement siteId={selectedSite} status="Pending" />
          </Stack>
        </Card>
        {/* Assign Shift Modal */}
        {assignShiftData?._id?.length > 0 && (
          <Card
            ref={assignShiftRef}
            id="assign-shift-roster-card"
            sx={{
              position: 'absolute',
              zIndex: 9,
              bottom: 0,
              right: 0,
              ml: 1.2,
              borderRadius: 1,
              height: 'calc(100vh - 155px)',
              width: '100%',
              boxShadow: 'none',
              backgroundColor: 'white'
            }}
            hidden={!(openCard === 'assign-shift-roster-card')}
          >
            <CardContent>
              <Box
                sx={{
                  height: 35,
                  p: 1,
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                style={{ backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)' }}
              >
                <Typography>
                  Update Schedule of {assignShiftData.name} on{' '}
                  {assignShiftData.date.split('-').reverse().join('/')}, ( {assignShiftData.role} )
                </Typography>
                {/* ( {selReqDay.iso} ) */}
                <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenCard('')} />
              </Box>
              <Grid container sx={{ pt: 2 }}>
                <Grid md={7} item>
                  {(info.isMainAdmin || info?.role?.is_site_manager) &&
                  new Date(
                    assignShiftData.date
                      .split('-')
                      .map((a) => parseInt(a, 10))
                      .flat()
                  ).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography sx={{ color: theme.palette.primary.main, mb: 1 }}>
                          <b>Assign Shift</b>
                        </Typography>
                      </Grid>
                      <Grid item md={5}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            mt: 1
                          }}
                        >
                          <AddModeratorIcon
                            sx={{
                              color: 'action.active',
                              mr: 1,
                              my: 0.5
                            }}
                          />
                          <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="choose_site">
                              Choose Site
                            </InputLabel>
                            <Select
                              variant="standard"
                              value={siteId}
                              inputProps={{
                                name: 'choose_site',
                                id: 'choose_site'
                              }}
                              onChange={(e) => setSiteId(e.target.value)}
                              sx={{ mt: 2 }}
                            >
                              <MenuItem value="" disabled>
                                Choose Site
                              </MenuItem>
                              {siteOptions?.length > 0 ? (
                                siteOptions.map(([key, value]) => (
                                  <MenuItem key={`${'assign_site'}-${key}`} value={key}>
                                    {value}
                                  </MenuItem>
                                ))
                              ) : (
                                <>
                                  <MenuItem value="" disabled />
                                </>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item md={5}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            mt: 1
                          }}
                        >
                          <AddModeratorIcon
                            sx={{
                              color: 'action.active',
                              mr: 1,
                              my: 0.5
                            }}
                          />
                          <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="choose_shift">
                              Choose Shift
                            </InputLabel>
                            <Select
                              variant="standard"
                              value={shiftId}
                              // disabled={!!siteId}
                              inputProps={{
                                name: 'choose_shift',
                                id: 'choose_shift'
                              }}
                              onChange={(e) => setShiftId(e.target.value)}
                              disabled={
                                !(siteId
                                  ? (siteId?.split(',')[1].trim() === info?._id.trim() &&
                                      assignShiftData?.priority >
                                        parseInt(siteId?.split(',')[2], 10)) ||
                                    info?.isMainAdmin
                                  : false)
                              }
                              sx={{ mt: 2 }}
                            >
                              <MenuItem value="" disabled>
                                Choose Shift
                              </MenuItem>
                              {shiftOptions?.length > 0 ? (
                                shiftOptions.map(([key, value, image]) => (
                                  <MenuItem
                                    key={`${'assign_shift'}-${key}`}
                                    value={key}
                                    data-icon={image}
                                  >
                                    {value}
                                  </MenuItem>
                                ))
                              ) : (
                                <>
                                  <MenuItem value="" disabled />
                                </>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item md={2}>
                        <Button
                          sx={{ mt: 2, ml: 3 }}
                          variant="contained"
                          disabled={
                            !(siteId
                              ? (siteId?.split(',')[1].trim() === info._id.trim() &&
                                  assignShiftData.priority > parseInt(siteId?.split(',')[2], 10)) ||
                                info?.isMainAdmin
                              : false)
                          }
                          onClick={() => {
                            // popupState.close();
                            Swal.fire({
                              title: `Are you sure ?`,
                              text: `Assign this Shift on ${assignShiftData.date} to ${assignShiftData.name} ?`,
                              icon: 'question',
                              showCancelButton: true,
                              confirmButtonText: 'Yes, Proceed!',
                              allowOutsideClick: false
                            }).then((result) => {
                              if (result.isConfirmed) {
                                assignShiftMutation({
                                  shift: shiftId,
                                  shouldCheckConstraints: true,
                                  employees: [assignShiftData._id],
                                  workdays: [assignShiftData.date],
                                  today: getCurrentDate()
                                });
                              } else if (result.dismiss === Swal.DismissReason.cancel) {
                                cache.invalidateQueries('roster');
                              }
                            });
                          }}
                        >
                          Assign
                        </Button>
                      </Grid>
                      {siteId ? (
                        assignShiftData.priority < parseInt(siteId?.split(',')[2], 10) ? (
                          <Alert severity="error" sx={{ mt: 1 }}>
                            Only Assign Shift to lower Priority Employees
                          </Alert>
                        ) : null
                      ) : null}
                    </Grid>
                  ) : null}
                  <Grid sx={{ mt: 3 }} container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={{ color: theme.palette.primary.main }}>
                        <b>Update Attendance</b>
                      </Typography>
                    </Grid>
                    {assignShiftData.schedule.length > 0 ? (
                      <>
                        {assignShiftData.schedule.map((sch) => (
                          <>
                            <Grid item md={4}>
                              <Typography>{sch?.shift?.name} </Typography>
                              <Typography>
                                {_12hourTimeFormat(sch?.shift?.start)} -{' '}
                                {_12hourTimeFormat(sch?.shift?.end)}
                              </Typography>
                            </Grid>
                            <Grid item md={4}>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                  label="IN"
                                  value={
                                    updateAttendance.find((ua) => ua.id === sch?._id)?.in ??
                                    (sch?.attendance?.in?.time &&
                                      _24toUTC(sch?.attendance?.in?.time))
                                  }
                                  onChange={(newValue) => {
                                    const d = updateAttendance.find((ua) => ua.id === sch?._id);
                                    if (d) {
                                      d.in = newValue;
                                    } else {
                                      updateAttendance.push({
                                        id: sch?._id,
                                        in: newValue
                                      });
                                    }
                                    setUpdateAttendance([...updateAttendance]);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      variant="standard"
                                      {...params}
                                      // error={Boolean(errors.shift_start)}
                                      // helperText={errors.shift_start}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item md={4}>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                  label="OUT"
                                  value={
                                    updateAttendance.find((ua) => ua.id === sch?._id)?.out ??
                                    (sch?.attendance?.out?.time &&
                                      _24toUTC(sch?.attendance?.out?.time))
                                  }
                                  onChange={(newValue) => {
                                    const d = updateAttendance.find((ua) => ua.id === sch?._id);
                                    if (d) {
                                      d.out = newValue;
                                    } else {
                                      updateAttendance.push({
                                        id: sch?._id,
                                        out: newValue
                                      });
                                    }
                                    setUpdateAttendance([...updateAttendance]);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      variant="standard"
                                      {...params}
                                      // error={Boolean(errors.shift_end)}
                                      // helperText={errors.shift_end}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={4} />
                            <Grid item xs={4}>
                              <TextField
                                label="Reason"
                                fullWidth
                                variant="standard"
                                onChange={(e) => {
                                  const d = updateAttendance.find((ua) => ua.id === sch?._id);
                                  d.reason = e.target.value;
                                  setUpdateAttendance([...updateAttendance]);
                                }}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                sx={{ mt: 2 }}
                                onClick={() => {
                                  Swal.fire({
                                    title: 'Are you sure?',
                                    text: `Attendance will be Updated`,
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, Proceed!',
                                    allowOutsideClick: false
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      const d = updateAttendance.find((ua) => ua.id === sch?._id);
                                      const sendData = {
                                        scheduleId: sch?._id,
                                        attendance: sch?.attendance?._id,
                                        employee: assignShiftData._id,
                                        shift: sch?.shift?._id,
                                        platform: 'web',
                                        reason: d.reason,
                                        date: assignShiftData.date,
                                        in: d.in
                                          ? convertTime12to24(d.in.toLocaleTimeString())
                                          : formatTime(sch?.attendance?.in?.time) ?? undefined,
                                        out: d.out
                                          ? convertTime12to24(d.out.toLocaleTimeString())
                                          : formatTime(sch?.attendance?.out?.time) ?? undefined,
                                        prev_out: sch?.attendance?.out?.time ?? undefined,
                                        prev_in: sch?.attendance?.in?.time ?? undefined
                                      };
                                      console.log(sendData);
                                      updatePastAttendanceMutation.mutate(sendData);
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                      cache.invalidateQueries('roster');
                                    }
                                  });
                                }}
                                variant="contained"
                                size="small"
                              >
                                Update
                              </Button>
                            </Grid>
                          </>
                        ))}
                      </>
                    ) : (
                      <NotAvailable type="na" />
                    )}
                  </Grid>
                </Grid>
                {(info.isMainAdmin || info?.role?.is_site_manager) &&
                new Date(
                  assignShiftData.date
                    .split('-')
                    .map((a) => parseInt(a, 10))
                    .flat()
                ).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? (
                  <Grid md={5} item>
                    {assignShift ? (
                      <Stack
                        sx={{
                          overflowY: 'auto',
                          px: 1
                        }}
                      >
                        <Typography sx={{ mb: 1 }}>
                          Requirements (&nbsp;
                          {assignShift.requirements[0].requirement.reduce(
                            (a, r) => a + r.value,
                            0
                          )}{' '}
                          )
                        </Typography>
                        <Divider sx={{ mb: 1 }} />
                        <Stack
                          className="custom_scrollbar"
                          sx={{ overflowY: 'auto', height: 'calc(100vh - 20rem)' }}
                        >
                          {assignShift.requirements.length > 0
                            ? assignShift.requirements[0].requirement.map((req) => (
                                <Stack key={`requirement_${req._id}`}>
                                  <Typography>
                                    <ArrowForwardIosIcon sx={{ fontSize: 12, mr: 0.5 }} />
                                    {req.role.name} (&nbsp;
                                    {assignShift.requirements[0].available.find(
                                      (avi) => avi.role._id === req.role._id
                                    )
                                      ? assignShift.requirements[0].available.find(
                                          (avi) => avi.role._id === req.role._id
                                        ).total
                                      : 0}
                                    &nbsp;/ {req.value} )
                                  </Typography>
                                  {req.subroles.length > 0
                                    ? req.subroles.map((sub) => (
                                        <Stack
                                          sx={{
                                            px: 1,
                                            my: 1,
                                            ml: 2
                                          }}
                                          key={`_${sub._id}`}
                                          direction="row"
                                          justifyContent="space-between"
                                        >
                                          <Typography>{sub.subrole.name}</Typography>
                                          <Typography
                                            sx={{
                                              color: `${
                                                assignShift.requirements[0].available
                                                  .find((avi) => avi.role._id === req.role._id)
                                                  ?.subroles.find(
                                                    (sri) => sri.subrole._id === sub.subrole._id
                                                  )
                                                  ? assignShift.requirements[0].available
                                                      .find((avi) => avi.role._id === req.role._id)
                                                      .subroles.find(
                                                        (sri) => sri.subrole._id === sub.subrole._id
                                                      ).total > sub.value
                                                    ? 'red'
                                                    : null
                                                  : null
                                              }`
                                            }}
                                          >
                                            {assignShift.requirements[0].available
                                              .find((avi) => avi.role._id === req.role._id)
                                              ?.subroles.find(
                                                (sri) => sri.subrole._id === sub.subrole._id
                                              )
                                              ? assignShift.requirements[0].available
                                                  .find((avi) => avi.role._id === req.role._id)
                                                  .subroles.find(
                                                    (sri) => sri.subrole._id === sub.subrole._id
                                                  ).total
                                              : 0}
                                            &nbsp;/ {sub.value ?? 0}
                                          </Typography>
                                        </Stack>
                                      ))
                                    : null}
                                </Stack>
                              ))
                            : 'Requirements Not Added'}
                        </Stack>
                      </Stack>
                    ) : (
                      <NotAvailable type="na" msg="Please Select a Shift to view Requirements" />
                    )}
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        )}
      </Box>
      {openAddShift && (
        <AddShift
          edit={editShift}
          shiftSite={_siteData?.site}
          rosterMonth={rosterMonth}
          // totalShiftRequirement={TOTAL_SHIFT_REQUIREMENT}
          open={openAddShift}
          onClose={() => setOpenAddShift(false)}
        />
      )}
      <ErrorsAlert
        errorsData={assignError}
        setErrors={() => setAssignError({})}
        assignMutation={assignShiftMutation}
        shiftId={shiftId}
        workDays={workDays}
      />
      {openCard === 'clear-roster-modal' && (
        <ClearRoster
          open={openCard === 'clear-roster-modal'}
          onClose={() => setOpenCard('')}
          site={selectedSite}
          rosterMonth={rosterMonth}
        />
      )}
      {openCard === 'add-holidays-modal' && (
        <AddHoliday
          open={openCard === 'add-holidays-modal'}
          onClose={() => setOpenCard('')}
          site={selectedSite}
          rosterMonth={rosterMonth}
        />
      )}
      <Popover
        id="employee-shift-preferences"
        open={anchorEl.id === 'employee-shift-preferences'}
        anchorEl={anchorEl.target}
        onClose={() => setAnchorEl({ id: null, target: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack sx={{ p: 2 }}>
          <Typography>Shift Preferences</Typography>
          <Divider />
          {isPreferencesLoading && <NotAvailable type="loading" />}
          {!isPreferencesLoading &&
            !isPreferencesError &&
            preferences?.shifts.map((sht) => {
              const isItemSelected = shiftPreferences.indexOf(sht?._id) !== -1;
              return (
                <Typography key={`preferences-${sht._id}`} sx={{ p: 0.5 }}>
                  <Checkbox
                    checked={isItemSelected}
                    onChange={(event) => handlePreferencesClick(event, sht._id)}
                  />
                  {sht?.name} ( {sht?.site?.name} )
                </Typography>
              );
            })}
          <Button
            variant="contained"
            onClick={() => {
              setAnchorEl({ id: null, target: null });
              updateShiftPreferencesMutation({
                employees: [preferencesId],
                preferences: shiftPreferences
              });
            }}
          >
            Update
          </Button>
        </Stack>
      </Popover>
      <Popover
        id="employee-week-off"
        open={anchorEl.id === 'employee-week-off'}
        anchorEl={anchorEl.target}
        onClose={() => setAnchorEl({ id: null, target: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack sx={{ p: 2 }}>
          <Typography>Select Week Off</Typography>
          <Divider />
          <Stack direction="row" alignItems="center" spacing={2}>
            {DayOfWeeks.map((wkd, index) => (
              <Typography key={`${wkd}-week-off-${index}`} sx={{ p: 0.5 }}>
                <Checkbox
                  checked={weekOffSelector?.week_off?.indexOf(index) !== -1}
                  onChange={() => handleWeekOffSelector(index)}
                />
                {wkd.substring(0, 3)}
              </Typography>
            ))}
          </Stack>
          <Button
            variant="contained"
            sx={{ width: 120 }}
            onClick={() => {
              setAnchorEl({ id: null, target: null });
              updateWeekOffMutation(weekOffSelector);
            }}
          >
            Update
          </Button>
        </Stack>
      </Popover>
      <ImageZoom src={imageSrc} onClose={() => setImageSrc(null)} />
    </Page>
  );
}

export default React.memo(Roster);
