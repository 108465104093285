/* eslint-disable no-nested-ternary */
import '../../../index.css';
import * as React from 'react';
// import * as Yup from 'yup';
import clsx from 'clsx';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import Swal from 'sweetalert2/src/sweetalert2';
import InfiniteScroll from 'react-infinite-scroll-component';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
import {
  Modal,
  Typography,
  Box,
  Stack,
  IconButton,
  Checkbox,
  Button,
  Divider,
  Alert,
  // TextField,
  OutlinedInput,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TreeView, TreeItem } from '@mui/lab';
import { useTreeItem } from '@mui/lab/TreeItem';
// import { useTheme } from '@mui/material/styles';
// icons
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { assignSite } from '../../../api/mutations/adminMutations';
// import useMutationFunc from '../api/mutations/mutation';
// hooks
import useSites from '../../../hooks/get-sites-query';
// utils
// import { getCurrentDate, convertUTCtoLocal } from '../../../utils/functions';
import { SWAL_TIME } from '../../../utils/config';
import NotAvailable from '../../NotAvailable';
// redux
import { logOut } from '../../../redux/actions/adminActions';
// import INDIAN from '../../../utils/INDIAN_STATES.json'; // importing indian states and uts

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250
//     }
//   }
// };

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  margin: '0.5rem',
  height: 40,
  // transition: theme.transitions.create(['box-shadow', 'width'], {
  //   easing: theme.transitions.easing.easeInOut,
  //   duration: theme.transitions.duration.shorter
  // }),
  // '&.Mui-focused': { width: 300, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const CustomContent = React.forwardRef((props, ref) => {
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

CustomContent.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * className applied to the root element.
   */
  className: PropTypes.string,
  /**
   * The icon to display next to the tree node's label. Either a parent or end icon.
   */
  displayIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label. Either an expansion or collapse icon.
   */
  expansionIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label.
   */
  icon: PropTypes.node,
  /**
   * The tree node label.
   */
  label: PropTypes.node,
  /**
   * The id of the node.
   */
  nodeId: PropTypes.string.isRequired
};

const CustomTreeItem = (props) => <TreeItem ContentComponent={CustomContent} {...props} />;

function AssignSiteEmployee({ open, onClose, selectedEmp }) {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const [filterName, setFilterName] = React.useState();
  const [search] = useDebounce(filterName, 1500);
  const [selected, setSelected] = React.useState([]);
  const [error] = React.useState('');

  React.useEffect(() => {
    if (selectedEmp?.sites?.length > 0) {
      setSelected([...selectedEmp?.sites.map((st) => st?._id)]);
    }
  }, [selectedEmp]);

  // For Testing
  React.useEffect(() => {
    console.log('Selected Sites:', selected);
  }, [selected]);

  /* ===================== Sites ===================== */

  const {
    isLoading: isSitesLoading,
    isError: isSitesError,
    data: sites,
    fetchNextPage: fetchNextSites,
    hasMoreData: hasNextSites
    // refetch: refetchSites
  } = useSites(
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[0], true) : null, // from
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[1], true) : null, // to
    null, // filter.date.length > 0 ? filter.date : getCurrentDate(),
    null, // type
    null, // filter.state, // state
    null, // filter.status, // status
    null, // filter.manager, // manager
    null, // filter.role,
    search, // search
    null
  );

  const ALL_SITES = React.useMemo(() => {
    const listOfSites = [];
    if (!isSitesLoading && !isSitesError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Sites :', flattened);
    return flattened;
  }, [sites, isSitesLoading, isSitesError]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // const handleAllClick = (event) => {
  //   if (event.target.checked) {
  //     setSelected([]);
  //   }
  // };

  const { mutate: assignSiteMutation } = useMutation(assignSite, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Adding Members to site 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      setSelected([]);
      Swal.fire({
        icon: 'success',
        title: `Successfully Added ${selected.length > 1 ? 'Employees' : 'Employee'} to Site`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('potential-site-members');
      cache.invalidateQueries('unmanaged-employees');
      cache.invalidateQueries('employees');
    }
  });

  return (
    <Modal
      open={open ?? false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 1,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          {/* <img src={ADD_EMPLOYEE} width="40" height="40" alt="edit_icon" /> */}
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            Assign Site
          </Typography>
          <IconButton
            onClick={() => onClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>{' '}
        <Stack
          className="custom_scrollbar"
          sx={{ position: 'relative', height: 'calc(100vh - 6rem)', paddingBottom: 2 }}
        >
          {error && (
            <Alert sx={{ mb: 1 }} severity="error">
              {error}
            </Alert>
          )}
          <Stack sx={{ p: 2 }} spacing={1}>
            <Typography>
              <b>Employee Name</b>
            </Typography>
            <Typography>{selectedEmp?.name}</Typography>
          </Stack>
          <Stack direction="row" sx={{ py: 2 }} spacing={1} alignItems="center">
            <Typography sx={{ px: 2, fontWeight: 'bold' }}>All Sites</Typography>
            {/* {!site ?? <Checkbox onChange={(e) => handleAllClick(e)} />} */}
          </Stack>
          <Divider />
          <SearchStyle
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            placeholder="Search Sites..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          <Box className="custom_scrollbar" sx={{ height: 250, overflowY: 'auto' }}>
            <TreeView
              aria-label="clear roster navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={Array.from({ length: ALL_SITES?.length }, (v, i) => `${i}`)}
              sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
            >
              {isSitesLoading ? (
                <NotAvailable type="loading" msg="Loading..." />
              ) : isSitesError ? (
                <NotAvailable type="na" msg="Something Went Wrong!" />
              ) : !isSitesLoading && ALL_SITES?.length > 0 ? (
                ALL_SITES.map((row, indx) => {
                  const sel = selected.includes(row?._id);
                  return (
                    <CustomTreeItem
                      nodeId={`${indx}`}
                      key={`asssign-site-employee-${row._id}`}
                      label={
                        <Typography>
                          <Checkbox checked={sel} onChange={(e) => handleClick(e, row._id)} />
                          {row.name}
                        </Typography>
                      }
                    />
                  );
                })
              ) : (
                <NotAvailable type="na" msg="No Data Available" />
              )}
            </TreeView>
          </Box>
          <Box sx={{ m: 1, position: 'fixed', bottom: 0 }}>
            <Button
              onClick={() => {
                onClose();
                Swal.fire({
                  title: 'Are you sure?',
                  text: `${selectedEmp?.name} will get added to Selected Sites!`,
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, Proceed!',
                  allowOutsideClick: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    assignSiteMutation({
                      sites: selected,
                      employees: [selectedEmp?._id],
                      isMultiple: true
                    });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    cache.invalidateQueries();
                  }
                });
              }}
              sx={{ width: '100%' }}
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </Stack>
        {/* Put the scroll bar always on the bottom */}
        <InfiniteScroll
          dataLength={ALL_SITES?.length}
          next={fetchNextSites}
          // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
          // inverse //
          hasMore={hasNextSites}
          loader={
            <Stack direction="row" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Stack>
          }
          scrollableTarget="site-shifts-container"
          // endMessage={
          //   <p style={{ textAlign: 'center' }}>
          //     <b>Yay! You have seen it all</b>
          //   </p>
          // }
        />
      </Box>
    </Modal>
  );
}

AssignSiteEmployee.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedEmp: PropTypes.object
};

export default React.memo(AssignSiteEmployee);
