/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
// This file contains all the GET API REQUESTS of ADMIN
import axios from 'axios';
import FileDownload from 'js-file-download';
import CalendarDates from 'calendar-dates';
import { baseApiURL, DayOfWeeks, WeekDaysToNumber } from '../../utils/config';
import {
  getDataFromStorage,
  getCurrentDate,
  formatDigit,
  getMonthCalendar
  // YYYY_MM_DD,
  // convertUTCtoLocal,
  // convertTimeToNumber
} from '../../utils/functions';
// import { fetchHeirarchy } from './roles';

/**
 * Function to get current heirarchy of the organization
 */
export const getHeirarchy = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/heirarchy`, config);

  return data;
};

/**
 * Function to get current employees of the organization
 */
export const fetchEmployees = async (queryParams) => {
  console.log(getDataFromStorage('adminAuthToken'));
  // console.log('qe: ', queryParams);
  const [, doj, search, state, policyId, role, manager, gender, shouldFetchPolicies, subrole] =
    queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      policyId,
      doj,
      search,
      state,
      role,
      manager,
      gender,
      subrole,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/employees`, config);
  // console.log('Employees: ', data);
  if (shouldFetchPolicies) {
    const { employees } = data;

    const promises = [];

    let policyOptions = {};

    employees.forEach((emp) => {
      if (emp?.role?._id) {
        if (policyOptions[emp.role._id]?.length > 0) {
          emp.policyOptions = policyOptions[emp.role._id];
        } else {
          promises.push(
            fetchPolicies({ queryKey: ['policies', emp.role._id] }).then((response) => {
              // console.log(response);
              const policies = [];
              response.policies.forEach((m) => {
                const { _id, name } = m;
                policies.push([_id, name]);
              });
              emp.policyOptions = policies;
              policyOptions = { ...policyOptions, [emp.role._id]: policies };
            })
          );
        }
      } else {
        emp.policyOptions = [];
      }
    });
    await Promise.all(promises);
  }
  return data;
};

/**
 * Function to get current Unmanaged employees of the organization
 */
export const fetchUnmanagedEmployees = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, doj, search, state, policyId, role, subrole, manager, isManager] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      date: getCurrentDate(),
      policyId,
      doj,
      search,
      state,
      role,
      subrole,
      manager,
      isManager,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/unmanaged-employees`, config);
  const { employees } = data;

  const promises = [];

  employees.forEach((emp) => {
    if (emp?.role?._id) {
      promises.push(
        fetchRoleManagers(emp.role._id).then((response) => {
          const managers = []; // let
          response.managers.forEach((m) => {
            // console.log('Import Employees Managers:', m);
            const vals = [`${m?._id},${m?.name}`];
            if (m.avatar) {
              vals.push(m.avatar);
            }
            if (m.role) {
              vals.push(m.role.name);
            }
            if (m.subrole) {
              vals.push(m.subrole.name);
            }
            managers.push(vals);
          });
          emp.options = managers;
        })
      );
    } else {
      emp.options = [];
    }
  });

  await Promise.all(promises);
  // console.log('Un-Assigned Employees:', data);
  return data;
};

/**
 * Function to get managers under specific role of the organization
 */
export const fetchManagers = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, roleId] = queryParams.queryKey;
  // console.log('Role Id:', roleId);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      role: roleId,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/managers`, config);
  // console.log('Managers Query Data :', data);
  return data;
};

export const fetchRoleManagers = async (role) => {
  // const manager = async (role) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      role
    }
  };

  const { data } = await axios.get(`${baseApiURL}/common/managers`, config);
  return data;
  // }
  // manager(role).then((response) => {
  //   let managers = [];
  //   response.managers.forEach(m => {
  //     const { _id,name,avatar} = m;
  //     managers.push([_id,name,avatar]);
  //   })
  //   return managers;
  // })
};

export const fetchPolicies = async (queryParams) => {
  const [, roleId, search, from, to] = queryParams.queryKey;
  // console.log('Role Id:',roleId);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      role: roleId,
      search,
      from,
      to,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/policies`, config);
  // console.log('Policies Axios: ', data);
  return data;
};

export const disableEmployee = async (id) => {
  // console.log('Disable Employee ID:', id);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/change-employee-status/${id}`, config);

  return data;
};

// testing only on employees for now
export const refreshEmployee = async (id) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  const { data } = await axios.get(`${baseApiURL}/admin/location-refresh/${id}`, config);

  // console.log('Here comes the refreshed location for employee: ', id);
  return data;
};

export const getDashboardCards = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  const { data } = await axios.get(`${baseApiURL}/admin/dashboard-cards`, config);

  return data;
};

/* ================================== Site Queries ================================= */

export const fetchAllSites = async (queryParams) => {
  const [, from, to, date, type, state, status, manager, search, employee] = queryParams.queryKey;
  // console.log('Sites Search', search);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      from,
      to,
      date: date ?? getCurrentDate(),
      type,
      state,
      status,
      manager,
      search,
      employee,
      today: getCurrentDate(),
      next: queryParams.pageParam
    }
  };
  const { data: hei } = await axios.get(`${baseApiURL}/common/roles`, config);
  const { heirarchy } = hei;
  const { data } = await axios.get(`${baseApiURL}/common/sites`, config);

  data.sites.forEach((site) => {
    const globalHeirarchy = heirarchy.filter((h) => h.priority > site.manager.role.priority);
    // console.log(`heirarchy ${index}: `, globalHeirarchy);
    const requirements = [];
    globalHeirarchy.forEach((role) => {
      requirements.push({
        role: role._id,
        name: role.name,
        total: 0,
        subroles: role.subroles.map((sr) => ({ subrole: sr._id, name: sr.name, total: 0 }))
      });
    });
    const totalRequirement = [...requirements];
    site.shifts.forEach((shift) => {
      totalRequirement.forEach((treq) => {
        shift?.requirement?.forEach((req) => {
          if (treq.role === req.role._id) {
            treq.total += req.value;
            treq.subroles.forEach((tsub) => {
              req.subroles.forEach((sub) => {
                if (tsub.subrole === sub.subrole._id) {
                  tsub.total += sub.value;
                }
              });
            });
          }
        });
      });
    });
    site.total_requirement = [...totalRequirement];
    // const vacancy = [];
    // totalRequirement.forEach((y) => vacancy.push({ ...y }));
    // vacancy.forEach((vac) => {
    //   const rl = site.employees.find((re) => re.role._id === vac.role);
    //   if (rl) {
    //     vac.total -= rl?.total ?? 0;
    //     vac.subroles.forEach((vsub) => {
    //       if (rl?.subroles) {
    //         rl?.subroles.forEach((sub) => {
    //           if (vsub.subrole === sub.subrole._id) {
    //             vsub.total -= sub.total ?? 0;
    //           }
    //         });
    //       }
    //     });
    //   }
    // });
    // // console.log('Vacancy:', vacancy);
    // site.vacancy = vacancy;
  });
  // console.log('All Sites :', data);
  return data;
};

export const deleteSite = async (id) => {
  // console.log('Id :', id);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/change-site-status/${id}`, config);

  return data;
};

export const fetchSiteById = async (id, token) => {
  // console.log('Site ID :',id);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token ?? getDataFromStorage('adminAuthToken')}`
    },
    params: {
      id
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/site`, config);
  // console.log('Site By Id',data);
  return data;
};

export const updateFeedbackStatus = async (key, id, status) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };

  await axios.get(`${baseApiURL}/admin/update-feedback/?id=${id}&status=${status}`, config);
};

/* ============================= Leads Queries ==================================== */

export const fetchAllLeads = async (queryParams) => {
  const [, from, to, type, state, status, manager, search] = queryParams.queryKey;
  // console.log(getDataFromStorage('adminAuthToken'))
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      from,
      to,
      type,
      state,
      status,
      manager,
      search
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/leads`, config);
  // console.log('Leads Query Data:',data);
  return data;
};

/* ============================= Leads Queries End ==================================== */

export const fetchSiteManagers = async (queryParams) => {
  const [, search, role] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      search,
      role
    }
  };

  const { data } = await axios.get(`${baseApiURL}/common/site-managers`, config);
  // console.log('Site Managers:', data);
  return data;
};

export const getSiteMembersByRole = async (queryParams) => {
  const [, site, role] = queryParams.queryKey;
  // console.log(queryParams);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      site,
      role,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/site-members`, config);
  // console.log(`Site Members :`, data);
  return data;
};

export const fetchSiteEmployees = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, site, role, search, subrole, onlyUnassigned] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      site,
      role,
      search,
      subrole,
      onlyUnassigned,
      date: getCurrentDate(),
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/site-employees`, config);
  // console.log('Site Employees Query Data :', data);
  return data;
};

export const fetchSitesFeedbacks = async (queryParams) => {
  const [, from, to, site, employee] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      from,
      to,
      site,
      employee
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/feedbacks`, config);
  return data;
};

/**
 * Function to get current Unmanaged employees of the organization
 */
export const fetchUnmanagedSites = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, from, to, type, search] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      from,
      to,
      type,
      search,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/unmanaged-sites`, config);
  // console.log('Unmanaged SItes', data);
  return data;
};

/* ================================== Shifts Queries ============================ */

export const fetchAllShifts = async (queryParams) => {
  const [, employee, site, from, to, date, token] = queryParams.queryKey;
  // console.log('Sites Search', search);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token ?? getDataFromStorage('adminAuthToken')}`
    },
    params: {
      employee,
      site,
      from,
      to,
      date: date ?? getCurrentDate(),
      today: getCurrentDate(),
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/shifts`, config);
  // data.shifts.forEach((shift) => {
  //   const employees = shift.employees.filter((emp) => !!emp._id);
  //   shift.employees = employees;
  // });
  data.shifts.forEach((shift) => {
    const vacancy = [];
    shift?.requirement?.forEach((shr) => {
      const lss = [];
      shr.subroles.forEach((subr) => lss.push({ ...subr }));
      const itb = {
        role: { ...shr.role },
        value: shr.value,
        subroles: lss
      };
      vacancy.push(itb);
    });
    if (vacancy.length > 0) {
      vacancy.forEach((vac) => {
        const rl = shift.available.find((re) => re.role._id === vac.role._id);
        if (rl) {
          vac.value -= rl?.total ?? 0; // NaN possibility
          vac.subroles.forEach((vsub) => {
            if (rl?.subroles) {
              rl?.subroles.forEach((sub) => {
                if (vsub.subrole._id === sub.subrole._id) {
                  vsub.value -= sub.total ?? 0;
                }
              });
            }
          });
        }
      });
    }
    shift.vacancy = vacancy;
  });
  // console.log('All Shifts :', data);
  return data;
};

/* ================================ Fetch Shift Members ============================== */

/* ================================== Shifts Employees ============================ */

export const fetchShiftEmployees = async (queryParams) => {
  const [, search, shift, role] = queryParams.queryKey;
  // console.log('Sites Search', search);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      shift,
      role,
      search,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/shift-employees`, config);
  // data.shifts.forEach((shift) => {
  //   const employees = shift.employees.filter((emp) => !!emp._id);
  //   shift.employees = employees;
  // });
  // console.log('All Shifts Emplopyees :', data);
  return data;
};

/* ================================ Fetch Shift Employees ============================== */

export const fetchShiftMembers = async (queryParams) => {
  const [, search, role, shift, manager] = queryParams.queryKey;
  // console.log(queryParams);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      search,
      role,
      shift,
      manager,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/shift-members`, config);
  // console.log(`Shift Members :`, data);
  return data;
};

/* ================================ Attendence Queries ============================ */

// export const fetchNormalAttendance = async (queryParams) => {
//   const [, date] = queryParams.queryKey;
//   // console.log('Sites Search', search);
//   const config = {
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
//     },
//     params: {
//       date,
//       next: queryParams.pageParam
//     }
//   };
//   const { data } = await axios.get(`${baseApiURL}/common/normal-attendance`, config);
//   // console.log('All Normal Attendance :', data);
//   return data;
// };

export const fetchNormalAttendance = async (queryParams) => {
  const [, date, search, employee] = queryParams.queryKey;
  // console.log('Sites Search', search);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      date,
      search,
      employee,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/attendances`, config);
  // console.log('All Normal Attendance :', data);
  return data;
};

export const fetchSiteAttendance = async (id, date) => {
  // console.log('Sites Search', search);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      id,
      date
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/site-attendance`, config);
  // console.log('Site Overview :', data);
  return data;
};

export const fetchOvertimeAttendance = async (queryParams) => {
  const [, site, date] = queryParams.queryKey;
  // console.log('Sites Search', search);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      site,
      date,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/overtime-attendance`, config);
  // console.log('All Overtime Attendance :', data);
  return data;
};

export const fetchShiftAttendance = async (queryParams) => {
  const [, shift, date] = queryParams.queryKey;
  // console.log('Sites Search', search);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      id: shift,
      date,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/shift-attendance`, config);
  // console.log('All Shift Attendance :', data);
  return data;
};

/* ================================= Overtimes Queries ================================= */

export const fetchAllOvertimes = async (queryParams) => {
  const [, site, from, to] = queryParams.queryKey;
  // console.log('Sites Search', search);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      site,
      from,
      to,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/overtimes`, config);
  // console.log('All Overtimes :', data);
  return data;
};

export const fetchPotentialSiteMembers = async (queryParams) => {
  const [, site, search, role, subrole, onlyUnassigned] = queryParams.queryKey;
  // console.log(queryParams);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      site,
      search,
      role,
      subrole,
      onlyUnassigned,
      date: getCurrentDate(),
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/potential-site-members`, config);
  // console.log(`Shift Members :`, data);
  return data;
};

// ---------------- Get Queries ---------------------------------------

export const getQueries = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, from, to, employee] = queryParams.queryKey;
  // console.log('Role Id:', roleId);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      from,
      to,
      employee,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/queries`, config);
  // console.log('Managers Query Data :', data);
  return data;
};

/* ================================== Shifts Employees ============================ */

export const fetchRoster = async (queryParams) => {
  const [, date, search, state, gender, role, subRole, site, shift, token, weekStart] =
    queryParams.queryKey;
  // console.log('Week Start:', weekStart);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token ?? getDataFromStorage('adminAuthToken')}`
    },
    params: {
      date,
      search,
      state,
      gender,
      role,
      subrole: subRole,
      site,
      shift,
      today: getCurrentDate(),
      next: queryParams.pageParam
    }
  };
  const monthDate = date.split('-');
  const rosterMonth = new Date(
    Number(monthDate[0]),
    Number(formatDigit(monthDate[1]) - 1),
    Number(formatDigit(monthDate[2])),
    0,
    0,
    0,
    0
  );
  const monthCalender = await getMonthCalendar(rosterMonth);
  const { data } = await axios.get(`${baseApiURL}/common/roster`, config);
  const EMPLOYEES = [...data?.employees];
  EMPLOYEES?.forEach((emp) => {
    const rd = emp?.roster.map((d) => d.day);
    monthCalender.forEach((obj) => {
      if (!rd.includes(obj.date)) {
        emp.roster.push({
          day: obj?.date,
          date: obj?.iso,
          isLeave: false,
          schedule: []
        });
      }
    });
    emp.roster.sort((a, b) => a.day - b.day);
    if (weekStart !== null) {
      emp.roster = emp.roster.splice(weekStart ?? 0, 7);
    }
  });
  // console.log('Roster :', data);
  return data;
};

export const fetchSubRoles = async (queryParams) => {
  const [, roleId, token] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token ?? getDataFromStorage('adminAuthToken')}`
    },
    params: {
      role: roleId,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/subroles`, config);
  // console.log('SubRoles By Role Query :', data);
  return data;
};

export const fetchSiteShifts = async (queryParams) => {
  const [, from, to, search, date, site, status, type] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      from,
      to,
      search,
      date,
      today: getCurrentDate(),
      site,
      status,
      type,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/all-shifts`, config);
  data.sites.forEach((site) => {
    const requirement = {};
    const available = {};
    if (site.shifts.length > 0) {
      site.shifts.forEach((shift) => {
        const dd = shift?.requirements.find((d) => d.day === Number(date.split('-')[2]));
        dd.requirement?.forEach((shr) => {
          const sroles = {};
          const res = Object.keys(requirement).find((v) => v === shr.role._id);
          if (res) {
            requirement[shr.role._id].value += shr.value;
            shr.subroles.forEach((suhr) => {
              const sres = Object.keys(requirement[shr.role._id].subroles).find(
                (v) => v === suhr.subrole._id
              );
              if (sres) {
                requirement[shr.role._id].subroles[suhr.subrole._id].value += suhr.value;
              } else {
                requirement[shr.role._id].subroles[suhr.subrole._id] = {
                  name: suhr.subrole.name,
                  value: suhr.value
                };
              }
            });
          } else {
            shr.subroles.forEach((suhr) => {
              sroles[suhr.subrole._id] = {
                _id: suhr.subrole._id,
                name: suhr.subrole.name,
                value: suhr.value
              };
            });
            requirement[shr.role._id] = {
              _id: shr.role._id,
              name: shr.role.name,
              value: shr.value,
              subroles: sroles
            };
          }
        });
        dd.available?.forEach((shr) => {
          const avroles = {};
          const res = Object.keys(available).find((v) => v === shr.role._id);
          if (res) {
            available[shr.role._id].value += shr.total;
            available[shr.role._id].att += shr.att;
            shr.subroles.forEach((suhr) => {
              const sres = Object.keys(available[shr.role._id].subroles).find(
                (v) => v === suhr.subrole._id
              );
              if (sres) {
                available[shr.role._id].subroles[suhr.subrole._id].value += suhr.total;
                available[shr.role._id].subroles[suhr.subrole._id].att += suhr.att;
              } else {
                available[shr.role._id].subroles[suhr.subrole._id] = {
                  name: suhr.subrole.name,
                  value: suhr.total,
                  att: suhr.att
                };
              }
            });
          } else {
            shr.subroles.forEach((suhr) => {
              avroles[suhr.subrole._id] = {
                _id: suhr.subrole._id,
                name: suhr.subrole.name,
                value: suhr.total,
                att: suhr.att
              };
            });
            available[shr.role._id] = {
              _id: shr.role._id,
              name: shr.role.name,
              value: shr.total,
              att: shr.att,
              subroles: avroles
            };
          }
        });
      });
    }
    // console.log(requirement);
    // console.log(available);
    const r = Object.values(requirement);
    const v = Object.values(available);
    r.forEach((rr) => (rr.subroles = Object.values(rr.subroles)));
    site.requirement = r;
    site.available = v;
  });
  // console.log('Site Shift Requirement :', data);
  return data;
};

// const vacancy = [];
//   shift?.requirement?.forEach((shr) => vacancy.push({ ...shr }));
//   if (vacancy.length > 0) {
//     vacancy.forEach((vac) => {
//       const rl = shift.available.find((re) => re.role._id === vac.role._id);
//       if (rl) {
//         vac.value -= rl?.total ?? 0;
//         vac.subroles.forEach((vsub) => {
//           if (rl?.subroles) {
//             rl?.subroles.forEach((sub) => {
//               if (vsub.subrole._id === sub.subrole._id) {
//                 vsub.value -= sub.total ?? 0;
//               }
//             });
//           }
//         });
//       }
//     });
//   }
//   shift.vacancy = vacancy;
// });

/* ============== Fetch All Templates ========================= */

export const fetchAllTemplates = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, from, to, employee] = queryParams.queryKey;
  // console.log('Role Id:', roleId);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      from,
      to,
      employee,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/shift-templates`, config);
  // console.log('Managers Query Data :', data);
  return data;
};

export const fetchRosterCards = async (queryParams) => {
  const [, site, date] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      site,
      date
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/roster-cards`, config);
  // console.log('Roster Cards Data:', data);
  return data;
};

export const fetchMonthAvailability = async (queryParams) => {
  const [, site, date, progress, token] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token ?? getDataFromStorage('adminAuthToken')}`
    },
    params: {
      site,
      date,
      today: getCurrentDate()
    }
  };
  const MONTH_DATE = date.split('-');
  // console.log(MONTH_DATE);
  const { data } = await axios.get(`${baseApiURL}/common/shifts-availability`, config);
  const calendarDates = new CalendarDates();
  const mayDates = await calendarDates.getDates(
    new Date(
      Number(MONTH_DATE[0]),
      formatDigit(MONTH_DATE[1]) - 1,
      Number(MONTH_DATE[2]),
      0,
      0,
      0,
      0
    )
  );
  // const mayMatrix = await calendarDates.getMatrix(may2018);
  const newarray = [].concat(...Array(7).fill(DayOfWeeks));
  mayDates.map((date, index) => (date.day = newarray[index]));
  const currentMonth = mayDates.filter((date) => date.type === 'current');
  const out = {}; // Shift Availability , Gaps
  const _Shifts = data?.shifts;
  const DAYS = [];
  // console.log('Site Hook Availability:', availability);
  const sit = []; // Shift Availability
  _Shifts.forEach((sht) => {
    const avail = [];
    currentMonth.forEach((obj) => {
      const av = sht.requirements.find((avi) => avi.day === obj.date)?.available ?? [];
      const _rq = sht.requirements.find((rr) => rr.day === obj.date)?.requirement ?? [];
      avail.push({
        date: obj.date,
        iso: obj.iso,
        day: obj.day,
        available: av,
        requirement: _rq
      });
      DAYS.push({
        date: obj.date,
        available: av,
        requirement: _rq
      });
    });
    sit.push({
      _id: sht._id,
      name: sht.name,
      constraints: sht.constraints,
      days: avail
    });
  });
  // console.log('Sit:', sit);
  // // console.log('Modified Site Availability:', sit);
  out.sit = sit; // Site Availability
  const groupByDate = (arr = []) => {
    let result = [];
    result = arr.reduce((r, a) => {
      r[a.date] = r[a.date] || [];
      r[a.date].push(a);
      return r;
    }, Object.create(null));
    return result;
  };
  const DATA_BY_DAYS = groupByDate(DAYS);
  const TOTAL_DAYS_REQUIREMENTS = [];
  Object.keys(DATA_BY_DAYS).forEach((t) => {
    const TOTAL_REQUIREMENT = {};
    const TOTAL_AVAILABLE = {};
    // console.log(DATA_BY_DAYS);
    DATA_BY_DAYS[t].forEach((dat) => {
      // console.log(dat);
      dat?.requirement?.forEach((shr) => {
        const sroles = {};
        const res = Object.keys(TOTAL_REQUIREMENT).some((v) => v === shr.role._id);
        if (res) {
          TOTAL_REQUIREMENT[shr.role._id].value += shr.value;
          if (dat?.available.length === 0) {
            TOTAL_REQUIREMENT[shr.role._id].gap += shr.value;
          }
          shr.subroles.forEach((suhr) => {
            const sres = Object.keys(TOTAL_REQUIREMENT[shr.role._id].subroles).some(
              (v) => v === suhr.subrole._id
            );
            if (sres) {
              TOTAL_REQUIREMENT[shr.role._id].subroles[suhr.subrole._id].value += suhr.value;
              if (dat?.available.length === 0) {
                TOTAL_REQUIREMENT[shr.role._id].subroles[suhr.subrole._id].gap += suhr.value;
              }
            } else {
              TOTAL_REQUIREMENT[shr.role._id].subroles[suhr.subrole._id] = {
                name: suhr.subrole.name,
                value: suhr.value,
                available: 0,
                att: 0,
                gap: dat?.available.length === 0 ? suhr.value : 0
              };
            }
          });
        } else {
          shr.subroles.forEach((suhr) => {
            sroles[suhr.subrole._id] = {
              _id: suhr.subrole._id,
              name: suhr.subrole.name,
              value: suhr.value,
              available: 0,
              att: 0,
              gap: dat.available.length === 0 ? suhr.value : 0
            };
          });
          TOTAL_REQUIREMENT[shr.role._id] = {
            _id: shr.role._id,
            name: shr.role.name,
            value: shr.value,
            available: 0,
            att: 0,
            gap: dat.available.length === 0 ? shr.value : 0,
            subroles: sroles
          };
        }
      });
      // console.log(dat?.available);
      dat?.available?.forEach((ashr) => {
        if (TOTAL_REQUIREMENT[ashr.role._id]) {
          TOTAL_REQUIREMENT[ashr.role._id].available += ashr?.total;
          TOTAL_REQUIREMENT[ashr.role._id].att += ashr?.att;
          TOTAL_REQUIREMENT[ashr.role._id].gap =
            // eslint-disable-next-line prettier/prettier
            TOTAL_REQUIREMENT[ashr.role._id].value - TOTAL_REQUIREMENT[ashr.role._id]?.available ??
            0;
          if (Object.keys(ashr?.subroles).length > 0) {
            ashr.subroles.forEach((suhr) => {
              const sub = TOTAL_REQUIREMENT[ashr.role._id]?.subroles;
              if (sub[suhr.subrole._id]) {
                sub[suhr.subrole._id].available += suhr.total;
                sub[suhr.subrole._id].att += suhr.att;
                sub[suhr.subrole._id].gap =
                  // eslint-disable-next-line prettier/prettier
                  sub[suhr.subrole._id].value - sub[suhr.subrole._id]?.available ?? 0;
              }
            });
          }
        }
      });
    });
    // console.log(TOTAL_REQUIREMENT);
    TOTAL_DAYS_REQUIREMENTS.push({
      date: Number(t),
      total: TOTAL_REQUIREMENT
    });
  });
  out.TOTAL_DAYS_REQUIREMENTS = TOTAL_DAYS_REQUIREMENTS;
  // console.log(TOTAL_DAYS_REQUIREMENTS); // Days
  const gaps = [];
  currentMonth.forEach((obj) => {
    const dt = obj.iso.split('-');
    if (
      new Date(dt[0], dt[1] - 1, dt[2]).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) &&
      progress
    ) {
      // console.log('DATE:', obj.iso);
      const avail = [];
      const require = [];
      out.sit.forEach((shift) => {
        const d = shift.days.find((day) => obj.iso === day.iso);
        if (d.available.length > 0) {
          d.available.forEach((al) => avail.push(al));
        }
        if (d.requirement.length > 0) {
          d.requirement.forEach((r) => require.push(r));
        }
      });
      gaps.push({
        date: obj.date,
        iso: obj.iso,
        day: obj.day,
        wd: WeekDaysToNumber[obj.day],
        requirement: require,
        available: avail
      });
    } else {
      const avail = [];
      const require = [];
      out.sit.forEach((shift) => {
        const d = shift.days.find((day) => obj.iso === day.iso);
        if (d.available.length > 0) {
          d.available.forEach((al) => avail.push(al));
        }
        if (d.requirement.length > 0) {
          d.requirement.forEach((r) => require.push(r));
        }
      });
      gaps.push({
        date: obj.date,
        iso: obj.iso,
        day: obj.day,
        wd: WeekDaysToNumber[obj.day],
        requirement: require,
        available: avail
      });
    }
  });
  // console.log('GAPS IN DAYS:', gaps);
  let tp = 0;
  let total = 0;
  gaps.forEach((gap, im) => {
    // let subTotal = 0;
    // let subTotalLength = 0;
    gap.totalreq = gap.requirement.reduce((sm, a) => sm + a.value, 0);
    gap.totalavl = gap.available.reduce((sm, a) => sm + a.total, 0);
    const RQ = gap.requirement.reduce((r, a) => {
      r[a.role._id] = [...(r[a.role._id] || []), a];
      return r;
    }, {});
    const AV = gap.available.reduce((r, a) => {
      r[a.role._id] = [...(r[a.role._id] || []), a];
      return r;
    }, {});
    Object.keys(RQ).forEach((k) => {
      RQ[k] = RQ[k].reduce((s, ob) => s + ob.value, 0);
    });
    Object.keys(AV).forEach((k) => {
      AV[k] = AV[k].reduce((s, ob) => s + ob.total, 0);
    });
    // gap.requirement = RQ;
    Object.keys(RQ).forEach((k) => {
      RQ[k] =
        RQ[k] !== 0
          ? (100 * AV[k]) / RQ[k] === Infinity
            ? 0
            : Math.floor((100 * (AV[k] ?? 0)) / RQ[k])
          : 0;
    });
    Object.keys(RQ).forEach((rq) => {
      if (RQ[rq] > 0) {
        // subTotal += RQ[rq];
        // subTotalLength += 1;
        total += 1;
      }
      if (im + 1 >= new Date().getDate() && RQ[rq] === 0) {
        total += 1;
      }
    });
    // console.log(gap.totalavl, gap.totalreq);
    // console.log(index, subTotal, subTotalLength);
    const per = Number(parseFloat((gap.totalavl * 100) / gap.totalreq).toFixed(2));
    // console.log('Prcentage:', gap.date, per);
    // const per = Math.floor(Object.values(RQ).reduce((a, b) => a + b, 0) / Object.keys(RQ).length);
    tp += Number.isNaN(per) ? 0 : per > 100 ? 100 : per; // calculating total progress
    // gap.available = AV;
    gap.percentage = Number.isNaN(per) ? 0 : per;
  });
  // console.log('GAPS', gaps);
  // console.log('Total percentage', tp / gaps.length);
  // total = tp / currentMonth.length); // Total Progress
  out.GAPS = gaps;
  const todayAvailability = gaps.find((gp) => gp.iso === getCurrentDate());
  out.todayGap = {
    requirement: todayAvailability?.requirement.reduce((sm, a) => sm + a.value, 0),
    available: todayAvailability?.available.reduce((sm, a) => sm + a.total, 0),
    percentage: isNaN(
      (todayAvailability?.available.reduce((sm, a) => sm + a.total, 0) * 100) /
        todayAvailability?.requirement.reduce((sm, a) => sm + a.value, 0)
    )
      ? 0
      : (todayAvailability?.available.reduce((sm, a) => sm + a.total, 0) * 100) /
        todayAvailability?.requirement.reduce((sm, a) => sm + a.value, 0)
  };
  // console.log('Total Progress:', tp);
  // console.log(new Date().getDate());
  // console.log('Month Length:', total, currentMonth.length);
  // + (currentMonth.length - new Date().getDate()))
  out.monthProgress = tp / total;
  // console.log('Roster Availability Data:', out);
  return out;
  // return data;
};

/* ================ Employeees Crads ==================== */

export const fetchEmployeesCards = async (queryParams) => {
  const [, type, site] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      type,
      site
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/employees-cards`, config);
  // console.log('Employee Cards Data:', data);
  return data;
};

/**
 * Get Leaves
 * @param {string} type - // ( own or others ) whether to get only your leave requests or of others below you in heirarchy
 * @param {string} status - Approved or Pending or Rejected or Cancelled, // (Optional) don't send if want to get all
 * @param {string} search - // (Optional) search by employee name
 * @param {string} searchBy - "name" or "reference" // (Optional)
 * @param {string} reason - "Sick" or "Casual"
 * @param {string} from - // (Optional) to get leaves from this date
 * @param {string} to - // (Optional) to get leaves upto this date
 * @param {string} employee - // (Optional) to get leaves of this particualr employee
 */
export const fetchLeaves = async (queryParams) => {
  const [, from, to, reason, status, search, employee, type, searchBy] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      from,
      to,
      reason,
      status,
      search,
      employee,
      type,
      searchBy,
      today: getCurrentDate(),
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/leaves`, config);
  return data;
};

/**
 * Get Remaining Leaves
 * @param {string} employee - // _id of employee
 * @param {string} years - // comma seperated years for which to get leaves for
 */
export const fetchRemainingLeaves = async (queryParams) => {
  const [, employee, years] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      employee,
      years,
      today: getCurrentDate()
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/remaining-leaves`, config);
  return data;
};

export const fetchStaticRemainingLeaves = async (employee, years) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      employee,
      years,
      today: getCurrentDate()
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/remaining-leaves`, config);
  return data;
};

export const fetchShiftById = async (id) => {
  // console.log('Shift ID :', id);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      id
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/shift`, config);
  // console.log('Shift By Id',data);
  return data;
};

/* ============================= Leads Queries ==================================== */

export const fetchAllRequirementTemplates = async () => {
  // const [, from, to, type, state, status, manager, search] = queryParams.queryKey;
  // console.log(getDataFromStorage('adminAuthToken'))
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      // from,
      // to,
      // type,
      // state,
      // status,
      // manager,
      // search
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/requirement-templates`, config);
  // console.log('Leads Query Data:',data);
  return data;
};

export const fetchAllReinforcement = async (queryParams) => {
  const [, from, to, site, status] = queryParams.queryKey;
  // console.log(getDataFromStorage('adminAuthToken'))
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      from,
      to,
      site,
      status
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/reinforcements`, config);
  // console.log('Leads Query Data:',data);
  return data;
};

/* ================== Fetch Work Hours Details ======================= */

export const fetchEmployeesWorkHours = async (queryParams) => {
  const [, search, date, role, subrole, gender] = queryParams.queryKey;
  // console.log(getDataFromStorage('adminAuthToken'))
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      search,
      date,
      role,
      subrole,
      gender,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/workhours`, config);
  // if (data) {
  //   data.employees.forEach((emp) => {
  //     emp.workdates = emp.workhours.map((hr) => convertUTCtoLocal(hr.date, true, true));
  //     emp.workhours = emp.workhours.map((hr) =>
  //       parseFloat(convertTimeToNumber(hr.hours)).toFixed(1)
  //     );
  //   });
  // }
  // console.log('WorkHours Query Data:', data);
  return data;
};

/* ================== Roster Cards ========================= */
export const fetchDashboardCards = async (queryParams) => {
  const [, date] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      date,
      today: getCurrentDate()
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/dashboard-cards`, config);
  // console.log('Employee Cards Data:', data);
  return data;
};

export const fetchHolidays = async (queryParams) => {
  const [, date, site, status] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      date,
      site,
      status,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/holidays`, config);
  return data;
};

/* ====================== Fetch Feeds =========================== */

export const fetchSwaps = async (queryParams) => {
  const [, status] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      status,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/swaps`, config);
  return data;
};

export const fetchSwapOvertime = async (date, selectedShift, swapShift, employee) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      date,
      selectedShift,
      swapShift,
      employee
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/swap-overtime`, config);
  return data;
};

// =========== GET Potential Swap Members
export const fetchPotentialSwapMembers = async (queryParams) => {
  const [, id, today, role, subrole, search] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      id, // Swap ID
      today,
      role,
      subrole,
      search,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/potential-swap-members`, config);
  return data;
};

/* Fetch Notifications */
export const fetchNotifications = async (queryParams) => {
  const [,] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/notifications`, config);
  // console.log('SubRoles By Role Query :', data);
  return data;
};

/* Fetch Notifications Topics */
export const fetchNotificationsTopics = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  const { data } = await axios.get(`${baseApiURL}/employee/topics`, config);
  // console.log('Notifications Topics:', data);
  return data;
};

/* Fetch Notifications Topics */
export const fetchEmployeeTokens = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/tokens`, config);
  // console.log('Employees Tokens:', data);
  return data;
};

/**
 * Send OTP to Mobile Number
 * @param {boolean} shouldResend - send it as true if you want resend OTP
 * @param {string} mobile - mobile number to send otp
 * @param {string} countryCode -  country code of mobile number
 */
export const sendMobileOTP = async (queryParams) => {
  const [, mobile, countryCode, resend] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      mobile,
      countryCode,
      resend
    }
  };
  const { data } = await axios.get(`${baseApiURL}/employee/send-mobile-otp`, config);
  // console.log('Employees Tokens:', data);
  return data;
};

/**
 * Send OTP to Email
 * @param {boolean} shouldResend - send it as true if you want resend OTP
 * @param {string} email - email to send otp
 */
export const sendEmailOTP = async (queryParams) => {
  const [, email, resend] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      email,
      resend
    }
  };
  const { data } = await axios.get(`${baseApiURL}/employee/send-email-otp`, config);
  // console.log('Employees Tokens:', data);
  return data;
};

/**
 * Check Roster Backward Propagation
 * @param {string} site - for which to be checked
 */
export const checkRosterBackwardPropagation = async (site) => {
  // const [, site] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      site,
      today: getCurrentDate()
    }
  };
  const { data } = await axios.get(
    `${baseApiURL}/common/check-roster-backward-propagation`,
    config
  );
  // console.log('Employees Tokens:', data);
  return data;
};

/**
 * Calculate Workforce Requirement for 100% roster of a month
 * @param {string} site - for which to be checked
 * @param {string} today - YYYY-MM-DD
 * @param {string} date - date of month for checking requirement for
 * @param {string} rosterId - unique roster id for socket
 */
export const workforceRequirementAnalyzer = async (site, date, rosterId) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      site,
      today: getCurrentDate(),
      date,
      rosterId
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/employees-for-roster`, config);
  // console.log('Employees Requirements:', data);
  return data;
};

/**
 * Get All the buckets
 */
export const fetchBuckets = async (queryParams) => {
  const [, search, date] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      search,
      date: date ?? getCurrentDate(),
      today: getCurrentDate(),
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/buckets`, config);
  // console.log('Employees Requirements:', data);
  return data;
};

/**
 * Get All Available Shift Preferences
 */
export const fetchShiftPreferences = async (key, id) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: { id }
  };
  const { data } = await axios.get(`${baseApiURL}/common/shift-preferences`, config);
  // console.log('Shift Preferences:', data);
  return data;
};

/**
 * Fetch Employee Schedule
 */
export const fetchEmployeeSchedule = async (queryParams) => {
  const [, date, employee] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: { date, employee, today: getCurrentDate() }
  };
  const { data } = await axios.get(`${baseApiURL}/employee/schedule`, config);
  // console.log('Shift Preferences:', data);
  return data;
};

/**
 * Fetch Get Payroll Components
 */
export const fetchPayrollComponents = async (queryParams) => {
  const [, type, search] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: { type, search }
  };
  const { data } = await axios.get(`${baseApiURL}/common/payroll-components`, config);
  // console.log('Payroll Components:', data);
  return data;
};

/**
 * Function to get current employees payroll of the organization
 */
export const fetchPayroll = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, date, search, state, role, manager, gender, doj, subrole] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      date,
      doj,
      search,
      state,
      role,
      manager,
      gender,
      subrole,
      today: getCurrentDate(),
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/payroll`, config);
  // console.log('Employees: ', data);
  return data;
};

/**
 * Function to get current employees payroll of the organization
 */
export const fetchPayrollTemplates = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, type, search] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      type,
      search,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/payroll-template`, config);
  return data;
};

/**
 * Function to get current employees payroll of the organization
 */
export const fetchAllPayrollTemplates = async (role, subrole) => {
  // console.log('qe: ', queryParams);
  // const [, role, subrole] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      role,
      subrole
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/all-payroll-template`, config);
  // console.log('All Payroll Templates:', data);
  return data;
};

/**
 * Function to get current employees payroll of the organization
 */
export const fetchResumes = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, status, search] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      status,
      search,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/resume`, config);
  return data;
};

export const fetchAllowances = async () => {
  // console.log('qe: ', queryParams);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
    // params: {
    //   next: queryParams.pageParam
    // }
  };
  const { data } = await axios.get(`${baseApiURL}/common/get-allowances`, config);
  // console.log('Query Allowances:', data);
  return data;
};

export const fetchDeductions = async () => {
  // console.log('qe: ', queryParams);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
    // params: {
    //   next: queryParams.pageParam
    // }
  };
  const { data } = await axios.get(`${baseApiURL}/common/get-deductions`, config);
  // console.log('Query Deductions:', data);
  return data;
};

export const fetchOfferLetterTemplate = async () => {
  // console.log('qe: ', queryParams);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
    // params: {
    //   next: queryParams.pageParam
    // }
  };
  const { data } = await axios.get(`${baseApiURL}/common/offer-letter`, config);
  // console.log('Query Deductions:', data);
  return data;
};

/**
 * To Get PaySlips of Employees
 */
export const fetchPaySlips = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, search, date, status] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      status,
      search,
      date,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/payslip`, config);
  // console.log('Query Deductions:', data);
  return data;
};

export const fetchLoans = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, search, status] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      status,
      search
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/loan`, config);
  // console.log('All Loans:', data);
  return data;
};

export const fetchPayrollSettings = async () => {
  // console.log('qe: ', queryParams);
  // const [, role, subrole] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
    // params: {
    //   role,
    //   subrole
    // }
  };
  const { data } = await axios.get(`${baseApiURL}/common/payroll-settings`, config);
  // console.log('All Payroll Templates:', data);
  return data;
};

/**
 * @param {string} id - Payslip Id
 * @param {string} fileName - FileName to download file
 * To Get PaySlips of Employees
 */
export const downloadPayslip = async (id, fileName) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`,
      responseType: 'blob'
    },
    params: {
      payslipId: id
    }
  };

  const { data } = await axios.get(`${baseApiURL}/common/download/payslip`, config);
  // console.log('DOwnload Payslip:', data);
  // FileDownload(data, `${fileName}.pdf`);
  return data;
};

export const fetchPaySlipsByEmployee = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, employee] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      employee,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/employee/payslips`, config);
  // console.log('Query Deductions:', data);
  return data;
};

export const fetchBirthdayAnniversary = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, employee] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      employee,
      today: getCurrentDate(),
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/birthdays-anniversary`, config);
  // console.log('Query Deductions:', data);
  return data;
};

export const fetchJoinExit = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, employee] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      employee,
      today: getCurrentDate(),
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/join-exit`, config);
  // console.log('Query Deductions:', data);
  return data;
};

export const downloadECRChallan = async (date, fileName) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`,
      responseType: 'blob'
    },
    params: {
      date
    }
  };

  const { data } = await axios.get(`${baseApiURL}/common/download/ecr-challan`, config);
  // console.log('DOwnload ECR Challan:', data);
  FileDownload(data, `${fileName}.txt`);
  return data;
};

export const fetchSitePayroll = async (queryParams) => {
  // console.log('qe: ', queryParams);
  const [, site] = queryParams.queryKey;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      site: site,
      today: getCurrentDate()
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/get-site-payroll`, config);
  // console.log('Query Deductions:', data);
  return data;
};
