import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchUnmanagedSites } from '../api/queries/Queries';

export const useUnmanagedSites = (from, to, type, search) => {
  const dispatch = useDispatch();

  return useInfiniteQuery(['unmanaged-sites', from, to, type, search], fetchUnmanagedSites, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};
