import axios from 'axios';
import { baseApiURL } from '../../utils/config';
import { getDataFromStorage } from '../../utils/functions';

const getConfig = () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  return config;
};

export const moveShiftMembers = async (values) => {
  if (values) {
    const { data } = await axios.put(
      `${baseApiURL}/common/move-shift-members`,
      values,
      getConfig()
    );
    return data;
  }
  throw new Error('No data to send');
};

export const removeFromSite = async (values) => {
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/remove-from-site`, values, getConfig());
    return data;
  }
  throw new Error('No data to send');
};
