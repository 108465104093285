/* eslint-disable new-cap */
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { jsPDF } from 'jspdf';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { months } from '../utils/config';
import {
  calculateLoanInstallment,
  capitalizeInitial,
  getCurrentDate,
  htmlToPDF
} from '../utils/functions';
import { downloadPayslip } from '../api/queries/Queries';

const cellStyle = {
  whiteSpace: 'nowrap',
  pr: 2
};

function PaySlip({ data, short }) {
  const { info } = useSelector((state) => state.adminInfo);
  return (
    <Stack className="salary-slip" sx={{ px: 1, border: '1px solid #d3d3d3', borderRadius: 1 }}>
      <Box sx={{ '& th, td': { border: 'none' } }} id={`payslip-${data?._id}`}>
        {!short && (
          <>
            <Table size="small">
              <TableBody>
                <TableRow height="70px">
                  <TableCell padding="none" colSpan={9}>
                    <Stack
                      sx={{ position: 'relative' }}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Avatar
                        alt=""
                        src={info?.avatar}
                        sx={{ height: 60, width: 60, position: 'absolute', left: 5, mt: 2 }}
                      />
                      <Typography component="span">
                        {info?.isMainAdmin
                          ? info?.name?.toUpperCase()
                          : info?.admin?.name?.toUpperCase()}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="none" align="center">
                    {info?.isMainAdmin
                      ? info?.address_details?.house
                      : info?.admin?.address_details?.house}
                    ,&nbsp;
                    {info?.isMainAdmin
                      ? info?.address_details?.town
                      : info?.admin?.address_details?.town}
                    ,&nbsp;
                    {info?.isMainAdmin
                      ? info?.address_details?.city.split(',')[1]
                      : info?.admin?.address_details?.city.split(',')[1]}
                    ,&nbsp;
                    {info?.isMainAdmin
                      ? info?.address_details?.state.split(',')[1]
                      : info?.admin?.address_details?.state.split(',')[1]}
                    ,&nbsp;
                    {info?.isMainAdmin
                      ? info?.address_details?.country.split(',')[1]
                      : info?.admin?.address_details?.country.split(',')[1]}
                    &nbsp;-&nbsp;
                    {info?.isMainAdmin
                      ? info?.address_details?.pincode
                      : info?.admin?.address_details?.pincode}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }} padding="none" align="center">
                    SALARY SLIP FOR THE MONTH OF&nbsp;
                    {months[Number(data.payslip_month) - 1]?.toUpperCase()}
                    ,&nbsp;
                    {data?.payslip_year}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell padding="none" colSpan={6}>
                    <b>EMPLOYEE DETAILS</b>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ mt: 3 }}>
                  <TableCell padding="none" sx={cellStyle}>
                    Code
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.employee?.username ?? 'XXXXXXXXXXX'}
                  </TableCell>
                  <TableCell padding="none" />
                  <TableCell padding="none" sx={cellStyle}>
                    PAN
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.employee?.pan?.value ?? 'XXXXXXXXXXX'}
                  </TableCell>
                  <TableCell padding="none" />
                  <TableCell padding="none" sx={cellStyle}>
                    Grade
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    XXXXXXXXXXX
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" padding="none" sx={cellStyle}>
                    Name
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.employee?.name}
                  </TableCell>
                  <TableCell padding="none" />
                  <TableCell padding="none" sx={cellStyle}>
                    UAN
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.employee?.uan ?? 'XXXXXXXXXXX'}
                  </TableCell>
                  <TableCell padding="none" />
                  <TableCell padding="none" sx={cellStyle}>
                    Designation
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.employee?.role?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" padding="none" sx={cellStyle}>
                    Father Name
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.employee?.father_name ?? 'XXXXXXXXXX'}
                  </TableCell>
                  <TableCell padding="none" />
                  <TableCell padding="none" sx={cellStyle}>
                    PF No.
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.employee?.epf_no ?? 'XXXXXXXXXXX'}
                  </TableCell>
                  <TableCell padding="none" />
                  <TableCell padding="none" sx={cellStyle}>
                    Department
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.employee?.department ?? 'XXXXXXXXXXX'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Divider sx={{ my: 1 }} />
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell padding="none" colSpan="4">
                    <b>WORKING DETAILS</b>
                  </TableCell>
                  <TableCell padding="none">
                    <b>LEAVE DETAILS</b>
                  </TableCell>
                  <TableCell padding="none">Opening</TableCell>
                  <TableCell padding="none">Earned</TableCell>
                  <TableCell padding="none">Availed</TableCell>
                  <TableCell padding="none">Closing</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="none" sx={cellStyle}>
                    Present
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.working_details?.present ?? 0}
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    Unpaid
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.working_details?.unpaid ?? 0}
                  </TableCell>
                  <TableCell padding="none">Casual Leave</TableCell>
                  <TableCell padding="none">0</TableCell>
                  <TableCell padding="none">0</TableCell>
                  <TableCell padding="none">0</TableCell>
                  <TableCell padding="none">0</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="none" sx={cellStyle}>
                    On Duty
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.working_details?.onduty ?? 0}
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    Absent
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.working_details?.absent ?? 0}
                  </TableCell>
                  <TableCell padding="none">Sick Leave</TableCell>
                  <TableCell padding="none">0</TableCell>
                  <TableCell padding="none">0</TableCell>
                  <TableCell padding="none">0</TableCell>
                  <TableCell padding="none">0</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="none" sx={cellStyle}>
                    Weekly Off
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.working_details?.weekly_off ?? 0}
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    Compen. Off
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.working_details?.compen_off ?? 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="none" sx={cellStyle}>
                    Holidays
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.working_details?.holidays ?? 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="none" sx={cellStyle}>
                    Extra Days
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.working_details?.extra_days ?? 0}
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    Paid Days
                  </TableCell>
                  <TableCell padding="none" sx={cellStyle}>
                    {data?.working_details?.paid_days ?? 0}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Divider sx={{ my: 1 }} />
          </>
        )}
        <Table>
          <TableBody>
            <TableRow className="myBackground">
              <TableCell sx={cellStyle} padding="none" colSpan="2">
                <b>EARNINGS</b>
              </TableCell>
              <TableCell padding="none" sx={cellStyle} />
              <TableCell align="right" sx={cellStyle} padding="none" className="Table-border-right">
                Amount {short ? Monthly | Annually : ''}
              </TableCell>
              <TableCell sx={cellStyle} padding="none" colSpan="2">
                <b>DEDUCTIONS</b>
              </TableCell>
              <TableCell sx={cellStyle} padding="none" />
              <TableCell align="right" sx={cellStyle} padding="none">
                Amount {short ? Monthly | Annually : ''}
              </TableCell>
            </TableRow>
            {data?.earnings_deductions?.map((d, indx) => (
              <TableRow key={`ed-${data._id}-${indx}`}>
                <TableCell sx={cellStyle} padding="none" colSpan="2">
                  {d[0]?.label}
                </TableCell>
                <TableCell padding="none" />
                <TableCell align="right" sx={cellStyle} padding="none" className="myAlign">
                  {`₹ ${d[0]?.value ?? '00.00'}`}{' '}
                  {short ? `| ₹ ${(d[0]?.value * 12).toFixed(2) ?? '00.00'}` : ''}
                </TableCell>
                <TableCell sx={cellStyle} padding="none" colSpan="2">
                  {d[1]?.label}
                </TableCell>
                <TableCell padding="none" />
                {d[1]?.label && (
                  <TableCell align="right" sx={cellStyle} padding="none" className="myAlign">
                    ₹ {d[1]?.value ?? '00.00'}{' '}
                    {short ? `| ₹ ${(d[1]?.value * 12).toFixed(2) ?? '00.00'}` : ''}
                  </TableCell>
                )}
              </TableRow>
            ))}
            <TableRow className="myBackground">
              <TableCell sx={cellStyle} padding="none" colSpan="3">
                Total Earnings
              </TableCell>
              <TableCell align="right" sx={cellStyle} padding="none" className="myAlign">
                ₹ {data?.total_earnings}{' '}
                {short ? `| ₹ ${(data?.total_earnings * 12).toFixed(2) ?? '00.00'}` : ''}
              </TableCell>
              <TableCell sx={cellStyle} padding="none" colSpan="3">
                Total Deductions
              </TableCell>
              <TableCell align="right" sx={cellStyle} padding="none" className="myAlign">
                ₹ {data?.total_deductions}{' '}
                {short ? `| ₹ ${(data?.total_deductions * 12).toFixed(2) ?? '00.00'}` : ''}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider sx={{ my: 1 }} />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell padding="none">
                <b>Payment Mode</b>
              </TableCell>
              <TableCell padding="none">Bank Transfer</TableCell>
              <TableCell padding="none">
                <b>A/C No.</b>
              </TableCell>
              <TableCell padding="none">XXXXXXXXXX</TableCell>
              <TableCell padding="none" colSpan={2}>
                Gross Earnings
              </TableCell>
              <TableCell padding="none" />
              <TableCell padding="none" sx={cellStyle} align="right">
                ₹ {data?.total_earnings ?? 'XXXXXXXXXX'}{' '}
                {short ? `| ₹ ${(data?.total_earnings * 12).toFixed(2) ?? '00:00'}` : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell padding="none">Bank Name</TableCell>
              <TableCell padding="none">{data?.employee?.bank_details?.bank_name}</TableCell>
              <TableCell padding="none">Bank Code</TableCell>
              <TableCell padding="none">{data?.employee?.bank_details?.bank_ifsc_code}</TableCell>
              <TableCell padding="none" colSpan={2}>
                Less Deduction
              </TableCell>
              <TableCell padding="none" />
              <TableCell padding="none" sx={cellStyle} align="right">
                ₹ {data?.total_deductions ?? 'XXXXXXXXXX'}{' '}
                {short ? `| ₹ ${(data?.total_deductions * 12).toFixed(2) ?? '00:00'}` : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell padding="none" colSPan="4" />
              <TableCell
                style={{ fontWeight: 'bold' }}
                sx={cellStyle}
                padding="none"
                colSpan="2"
                className="Table-border-bottom"
              >
                Net Salary Payable
              </TableCell>
              <TableCell padding="none" />
              <TableCell align="right" sx={cellStyle} padding="none">
                ₹ {data?.net_salary ?? 'XXXXXXXXXX'}{' '}
                {short ? `| ₹ ${(data?.net_salary * 12).toFixed(2) ?? '00:00'}` : ''}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {/* <TableRow>
          <TableCell padding="none" sx={cellStyle}>
            Bank A/C no.
          </TableCell>
          <TableCell padding="none" sx={cellStyle}>
            {data?.payroll?.bank_details?.bank_ac_no ?? 'XXXXXXXXXXX'}
          </TableCell>
          <TableCell padding="none" />
          <TableCell padding="none" sx={cellStyle}>
            Pay Period
          </TableCell>
          <TableCell padding="none" sx={cellStyle}>
            {capitalizeInitial(data?.employee?.pay_period) ?? 'XXXXXXXXXXX'}
          </TableCell>
          <TableCell padding="none" sx={cellStyle}>
            Bank Name
          </TableCell>
          <TableCell padding="none" sx={cellStyle}>
            {data?.employee?.bank_details?.bank_name ?? 'XXXXXXXXXXX'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell padding="none" sx={cellStyle}>
            ESI No.
          </TableCell>
          <TableCell padding="none" sx={cellStyle}>
            {data?.employee?.esi_no ?? 'XXXXXXXXXXX'}
          </TableCell>
          <TableCell padding="none" />
          <TableCell padding="none" sx={cellStyle}>
            CTC
          </TableCell>
          <TableCell padding="none" sx={cellStyle}>
            {data?.ctc ?? 'XXXXXXXXXXX'}
          </TableCell>
        </TableRow> */}
        {/* <TableRow>
        <TableCell padding="none" colSpan="2">
          Variable Dearness Allowance
        </TableCell>
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {`${data?.payroll?.allowances?.variable_dearness_allowance ?? '00'}.00`}
        </TableCell>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Loan
        </TableCell>
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {`${data?.payroll?.deductions?.loan ?? '00'}.00`}
        </TableCell>
      </TableRow> */}
        {/* <TableRow>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          House Rent Allowance
        </TableCell>
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {`${data?.payroll?.allowances?.house_rent_allowance ?? '00'}.00`}
        </TableCell>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Professional Tax
        </TableCell>
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {`${data?.payroll?.deductions?.professional_tax ?? '00'}.00`}
        </TableCell>
      </TableRow> */}
        {/* <TableRow>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Graduation Allowance
        </TableCell>
        <TableCell padding="none" />
        <TableCell padding="none" className="myAlign">
          {`${data?.payroll?.allowances?.graduation_allowance ?? '00'}.00`}
        </TableCell>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Security Deposite(SD)
        </TableCell>
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {`${data?.payroll?.deductions?.security_deposit ?? '00'}.00`}
        </TableCell>
      </TableRow> */}
        {/* <TableRow>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Conveyance Allowance
        </TableCell>{' '}
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {`${data?.payroll?.allowances?.conveyance_allowance ?? '00'}.00`}
        </TableCell>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Staff Benefit(SB)
        </TableCell>
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {`${data?.payroll?.deductions?.staff_benefit ?? '00'}.00`}
        </TableCell>
      </TableRow> */}
        {/* <TableRow>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Post Allowance
        </TableCell>
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {`${data?.payroll?.allowances?.post_allowance ?? '00'}.00`}
        </TableCell>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Labour Welfare Fund
        </TableCell>
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {`${data?.payroll?.deductions?.labour_welfare_fund ?? '00'}.00`}
        </TableCell>
      </TableRow> */}
        {/* <TableRow>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Special Allowance
        </TableCell>
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {`${data?.payroll?.allowances?.special_allowance ?? '00'}.00`}
        </TableCell>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          NSC
        </TableCell>
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          00.00
        </TableCell>
      </TableRow> */}
        {/* <TableRow>
        <TableCell padding="none" colSpan="4" className="Table-border-right" />
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Union Thanco Officer(UTO)
        </TableCell>
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          00.00
        </TableCell>
      </TableRow> */}
        {/* <TableRow>
        <TableCell padding="none" colSpan="4" className="Table-border-right" />
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Advance
        </TableCell>
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {`${data?.payroll?.deductions?.advance ?? '00'}.00`}
        </TableCell>
      </TableRow> */}
        {/* <TableRow>
        <TableCell padding="none" colSpan="4" className="Table-border-right" />
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Income Tax
        </TableCell>{' '}
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {`${data?.payroll?.deductions?.income_tax ?? '00'}.00`}
        </TableCell>
      </TableRow> */}
        {/* {!short && (
          <TableRow>
            <TableCell sx={cellStyle} padding="none">
              Employer PF
            </TableCell>
            <TableCell align="right" sx={cellStyle} padding="none" className="myAlign">
              {data?.pf_employer ?? '00.00'}
            </TableCell>
            <TableCell padding="none" />
            <TableCell padding="none" />
            <TableCell padding="none" sx={cellStyle}>
              Monthly Gross
            </TableCell>
            <TableCell padding="none" align="right" sx={cellStyle}>
              {data?.monthly_gross ?? 'XXXXXXXXXXX'}
            </TableCell>
          </TableRow>
        )} */}
        {/* <TableRow>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Gross Salary
        </TableCell>{' '}
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {data?.payroll?.allowances ? `${totalAllowances + totalDeductions}.00` : '00.00'}
        </TableCell>
        <TableCell padding="none" colSpan="4" />
      </TableRow>
      <TableRow>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Aggr. Dedu - P.Tax & Std Ded
        </TableCell>{' '}
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          00.00
        </TableCell>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Cumulative
        </TableCell>
        <TableCell padding="none" colSpan="2" />
      </TableRow>
      <TableRow>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Gross Total Income
        </TableCell>{' '}
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {data?.payroll?.allowances
            ? `${(totalAllowances + totalDeductions) * 12}.00`
            : '00.00'}
        </TableCell>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Empl PF Contribution
        </TableCell>{' '}
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          00.00
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Aggr of Chapter "PF"
        </TableCell>{' '}
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {data?.payroll?.deductions?.provident_fund
            ? data?.payroll?.deductions?.provident_fund * 12
            : '00.00'}
        </TableCell>
        <TableCell padding="none" colSpan="4" />
      </TableRow>
      <TableRow>
        <TableCell sx={cellStyle} padding="none" colSpan="2">
          Total Income
        </TableCell>{' '}
        <TableCell padding="none" />
        <TableCell sx={cellStyle} padding="none" className="myAlign">
          {data?.payroll?.allowances
            ? `${
                (totalAllowances +
                  totalDeductions -
                  data?.payroll?.deductions?.provident_fund) *
                12
              }.00`
            : '00.00'}
        </TableCell>
        <TableCell padding="none" colSpan="4" />
      </TableRow>
      <TableBody className="border-center">
        <TableRow>
          <TableCell sx={cellStyle} padding="none">
            Attend/ Absence
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            Days in Month
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            Days Paid
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            Days Not Paid
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            Leave Position
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            Privilege Leave
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            Sick Leave
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            Casual Leave
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell padding="none" />
          <TableCell padding="none" />
          <TableCell padding="none" />
          <TableCell padding="none" />
          <TableCell sx={cellStyle} padding="none">
            Yrly Open Balance
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            {yearLeave?.privilege ?? '0.0'}
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            {yearLeave?.sick ?? '0.0'}
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            {yearLeave?.casual ?? '0.0'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={cellStyle} padding="none">
            Current Month
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            31
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            {data?.working_days}
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            {data?.days_attended}
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            Availed
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            0.0
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            {Object.entries(data?.leaves_taken ?? {}).map(([key, value]) => value.sick)}.0
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            {Object.entries(data?.leaves_taken ?? {}).map(([key, value]) => value.casual)}.0
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell padding="none" colSpan="4" />
          <TableCell sx={cellStyle} padding="none">
            Closing Balance
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            0.0
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            {`${
              yearLeave?.sick -
              Object.entries(data?.leaves_taken ?? {}).map(([key, value]) => value.sick)
            }.0`}
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            {`${
              yearLeave?.casual -
              Object.entries(data?.leaves_taken ?? {}).map(([key, value]) => value.casual)
            }.0`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell padding="none" colSpan="4">
            {' '}
            &nbsp;{' '}
          </TableCell>
          <TableCell padding="none"> </TableCell>
          <TableCell padding="none"> </TableCell>
          <TableCell padding="none"> </TableCell>
          <TableCell padding="none"> </TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell padding="none" colSpan="4" />
          <TableCell sx={cellStyle} padding="none">
            Company Pool Leave Balance
          </TableCell>
          <TableCell sx={cellStyle} padding="none">
            1500
          </TableCell>
          <TableCell padding="none" />
          <TableCell padding="none" />
        </TableRow>
        </TableBody> */}
      </Box>
      {!short && (
        <Stack sx={{ p: 1 }} direction="row" justifyContent="right" alignItems="center">
          <Button
            onClick={async () => {
              const fileName = `${data?.payslip_no}-${data?.payslip_month}-${data?.payslip_year}`;
              const pdf = await downloadPayslip(data?._id, fileName);
              const file = new Blob([pdf], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
            }}
            variant="contained"
            size="small"
          >
            Preview
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

export default PaySlip;
