import axios from 'axios';
import { baseApiURL } from '../../utils/config';
import { getDataFromStorage } from '../../utils/functions';

// function to create heirarchy
export const createHeirarchy = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    // console.log('values: ', JSON.stringify(values, null, '\t'));
    const { data } = await axios.post(`${baseApiURL}/admin/heirarchy`, values, config);
    return data;
  }
  return `No Data to Send`;
};

// function to manager role privileges
export const updateRolesPrivileges = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  if (values) {
    // console.log('values: ', JSON.stringify(values, null, '\t'));
    const { data } = await axios.post(`${baseApiURL}/admin/roles-privileges`, values, config);
    return data;
  }
  return `No Data to Send`;
};
