/* eslint-disable no-nested-ternary */
import * as React from 'react';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useQueryClient } from 'react-query';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
import {
  Stack,
  IconButton,
  Tooltip,
  Typography,
  Tab,
  Box,
  Modal,
  TextField,
  Button,
  OutlinedInput,
  InputAdornment,
  Checkbox,
  Alert,
  Divider,
  Card
} from '@mui/material';
import { useDebounce } from 'use-debounce';
import { TreeView, TreeItem } from '@mui/lab';
import { useTreeItem } from '@mui/lab/TreeItem';
import InfoIcon from '@mui/icons-material/Info';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme, styled } from '@mui/material/styles';
// import { tooltipClasses } from '@mui/material/Tooltip';
import { Form, useFormik, FormikProvider } from 'formik';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import SearchIcon from '@mui/icons-material/Search';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import NotAvailable from '../../NotAvailable';
import { lightGradients } from '../../../theme/colors';
import { ordinal, convertUTCtoLocal, getCurrentDate, formatDigit } from '../../../utils/functions';
import { shortMonth, ADD_EMPLOYEE } from '../../../utils/config';
import { editHoliday, deleteHoliday } from '../../../api/mutations/adminMutations';
import useMutationFunc from '../../../api/mutations/mutation';
import { useHolidays } from '../../../hooks/dashboard';
import AddHoliday from '../../AddHoliday';

const DEBUG = false;

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  margin: '0.5rem',
  height: 40,
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

function searchArray(input, data) {
  let filtered = [];
  if (input) {
    filtered = data.filter((el) =>
      Object.values(el).some((val) => String(val).toLowerCase().includes(input.toLowerCase()))
    );
    return filtered;
  }
  return data;
}

function EditHoliday({ open, onClose, edit }) {
  const style = {
    position: 'absolute',
    // top: '50%',
    // left: '50%',
    top: 0,
    right: 0,
    height: '100vh',
    // transform: 'translate(-50%, -50%)',
    minWidth: 320,
    maxWidth: 400,
    backgroundColor: 'white',
    border: 'none',
    // borderRadius: 1,
    boxShadow: 24
    // p: 4
  };
  const editHolidayMutation = useMutationFunc(editHoliday);
  // const deleteHolidayMutation = useMutationFunc(deleteHoliday);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      holidayId: edit?._id,
      reason: edit?.reason // Reason for holiday
    },
    validationSchema: Yup.object({
      holidayId: Yup.string().required(),
      reason: Yup.string()
        .required('Reason is required')
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9\s\-&.']*[a-zA-Z0-9]+$/,
          "Invalid Reason. ( Minimum 2 characters, must start & end with alphanumeric characters, allowed special characters  - & . ' )"
        )
    }),
    // validate: (values) => {
    //   console.log(values);
    // },
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        id: values.holidayId,
        dates: edit?.dates,
        reason: values.reason
      };

      if (DEBUG) {
        console.log('Update Holiday Form :', data);
      } else {
        onClose();
        editHolidayMutation(data);
      }
    }
  });
  const { getFieldProps, errors, touched } = formik;
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style} container>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 2,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          {/* <img src={ADD_EMPLOYEE} width="40" height="40" alt="edit_icon" /> */}
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            Update Holiday
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <FormikProvider value={formik}>
          <Form>
            <Stack
              spacing={2}
              sx={{
                p: 2,
                backgroundColor: 'white',
                height: 'calc(100vh - 5rem)',
                position: 'relative'
              }}
            >
              <TextField
                variant="standard"
                fullWidth
                type="text"
                multiline
                label="Reason"
                autoComplete="off"
                required
                {...getFieldProps('reason')}
                error={Boolean(touched.reason && errors.reason)}
                helperText={touched.reason && errors.reason}
              />
              <Button sx={{ position: 'absolute', bottom: 0 }} type="submit" variant="contained">
                Submit
              </Button>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Modal>
  );
}

EditHoliday.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  edit: PropTypes.object
};

function HolidayDetails({ open, onClose, detail }) {
  const theme = useTheme();
  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100vh',
    minWidth: 320,
    maxWidth: 400,
    backgroundColor: 'white',
    boxShadow: 24
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style} container>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 2,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          {/* <img src={ADD_EMPLOYEE} width="40" height="40" alt="edit_icon" /> */}
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            Holiday Details
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack sx={{ p: 1 }}>
          <Typography align="left" sx={{ fontSize: 13 }}>
            <b>Holiday For</b>
          </Typography>
          <Typography align="center" sx={{ p: 1, color: theme.palette.secondary.main }}>
            <b>{detail?.reason}</b>
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1} sx={{ p: 2, height: 'calc(100vh - 5rem)' }}>
          {detail?.holidays.map((hdy) => {
            const shifts = hdy.shifts.map((sht) => sht.name);
            return (
              <Box
                sx={{ p: 1, boxShadow: theme.customShadows.bloated, borderRadius: 0.2 }}
                key={hdy._id}
              >
                <Typography sx={{ color: theme.palette.primary.main, fontSize: 14 }}>
                  <b>{hdy.site.name}</b>
                </Typography>
                {shifts.length > 0 && (
                  <Typography sx={{ fontSize: 12 }}>
                    <Typography component="span" sx={{ mr: 1, fontSize: 12 }}>
                      <b>Shifts:</b>
                    </Typography>
                    {shifts.toString()}
                  </Typography>
                )}
                <Typography sx={{ fontSize: 12 }} align="right">
                  <Typography component="span" sx={{ mr: 1, fontSize: 12 }}>
                    <b>By</b>
                  </Typography>
                  {hdy.addedBy === null ? 'Admin' : hdy?.addedBy?.name}
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Modal>
  );
}

HolidayDetails.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  detail: PropTypes.object
};

const CustomContent = React.forwardRef((props, ref) => {
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

CustomContent.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * className applied to the root element.
   */
  className: PropTypes.string,
  /**
   * The icon to display next to the tree node's label. Either a parent or end icon.
   */
  displayIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label. Either an expansion or collapse icon.
   */
  expansionIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label.
   */
  icon: PropTypes.node,
  /**
   * The tree node label.
   */
  label: PropTypes.node,
  /**
   * The id of the node.
   */
  nodeId: PropTypes.string.isRequired
};

const CustomTreeItem = (props) => <TreeItem ContentComponent={CustomContent} {...props} />;

function DeleteHoliday({ open, onClose, site = null, detail }) {
  const cache = useQueryClient();
  const [siteSelected, setSiteSelected] = React.useState([]);
  const [shiftSelected, setShiftSelected] = React.useState([]);
  const [filterName, setFilterName] = React.useState();
  const [search] = useDebounce(filterName, 1500);
  const [error, setError] = React.useState('');
  const [allHolidays, setAllHolidays] = React.useState();
  const SITE_SHIFTS = searchArray(search, detail?.holidays ?? []);
  const startDate = convertUTCtoLocal(detail?.dates[0], true);
  const endDate = convertUTCtoLocal(detail?.dates[1], true);

  const handleSiteClick = (event, name) => {
    const selectedIndex = siteSelected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(siteSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(siteSelected.slice(1));
    } else if (selectedIndex === siteSelected.length - 1) {
      newSelected = newSelected.concat(siteSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        siteSelected.slice(0, selectedIndex),
        siteSelected.slice(selectedIndex + 1)
      );
    }
    setSiteSelected(newSelected);
  };

  const handleSiteAllClick = (event) => {
    if (event.target.checked) {
      setSiteSelected([]);
      setAllHolidays(true);
      return;
    }
    setAllHolidays(false);
  };

  const handleShiftAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = SITE_SHIFTS[0].shifts.map((n) => n._id);
      setShiftSelected(newSelecteds);
      return;
    }
    setShiftSelected([]);
  };

  const handleShiftClick = (event, name) => {
    const selectedIndex = shiftSelected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(shiftSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(shiftSelected.slice(1));
    } else if (selectedIndex === shiftSelected.length - 1) {
      newSelected = newSelected.concat(shiftSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        shiftSelected.slice(0, selectedIndex),
        shiftSelected.slice(selectedIndex + 1)
      );
    }
    setShiftSelected(newSelected);
  };

  const deleteHolidayMutation = useMutationFunc(
    deleteHoliday,
    {
      onSuccess: {
        title: 'Successfully Deleted!!'
      }
    },
    () => onClose(),
    () => cache.invalidateQueries(),
    null,
    null
  );

  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100vh',
    minWidth: 320,
    maxWidth: 400,
    backgroundColor: 'white',
    boxShadow: 24
  };

  return (
    <Modal
      open={open ?? false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 1,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          <img src={ADD_EMPLOYEE} width="40" height="40" alt="edit_icon" />
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            Delete Holiday
          </Typography>
          <IconButton
            onClick={() => onClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>{' '}
        <Stack
          id="site-shifts-container"
          className="custom_scrollbar"
          sx={{ position: 'relative', height: 'calc(100vh - 1rem)', paddingBottom: 2 }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography sx={{ p: 2, fontWeight: 'bold' }}>
              {site ? 'All Shifts' : 'All Sites'}
            </Typography>
            {site ? (
              <Checkbox onChange={(e) => handleShiftAllClick(e)} />
            ) : (
              <Checkbox onChange={(e) => handleSiteAllClick(e)} />
            )}
          </Stack>
          <Divider />
          <SearchStyle
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            placeholder="Search Sites..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          {error && <Alert severity="error">{error}</Alert>}
          <TreeView
            aria-label="clear roster navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={Array.from({ length: SITE_SHIFTS?.length }, (v, i) => `${i}`)}
            sx={{ flexGrow: 1, maxWidth: 400, pt: 2, overflowY: 'auto' }}
          >
            {SITE_SHIFTS.map((row, indx) => (
              <CustomTreeItem
                nodeId={`${indx}`}
                key={`clear-roster-${row._id}`}
                label={
                  <Typography>
                    {site === null && (
                      <Checkbox
                        checked={siteSelected.indexOf(row?.site?._id) !== -1}
                        onChange={(e) => handleSiteClick(e, row.site._id)}
                      />
                    )}
                    {row.site.name}
                  </Typography>
                }
              >
                {site &&
                  row.shifts.map((shift, inx) => (
                    <CustomTreeItem
                      key={`clear-roster-${shift._id}`}
                      nodeId={`${indx}${inx}`}
                      label={
                        <Typography>
                          <Checkbox
                            checked={shiftSelected.indexOf(shift?._id) !== -1}
                            onChange={(e) => handleShiftClick(e, shift._id)}
                          />{' '}
                          {shift.name}
                        </Typography>
                      }
                    />
                  ))}
              </CustomTreeItem>
            ))}
          </TreeView>
          <Box sx={{ m: 1, position: 'fixed', bottom: 0 }}>
            {site ? (
              <Stack direction="row" spacing={1}>
                <Button
                  onClick={() =>
                    Swal.fire({
                      title: 'Are you sure?',
                      text: `Clear roster for rest of the month!`,
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: 'Yes, Proceed!',
                      allowOutsideClick: false
                    }).then(() => {
                      // if (result.isConfirmed) {
                      //   clearRosterMutation({
                      //     sites: [site],
                      //     shifts: [],
                      //     today: getCurrentDate(),
                      //     date: convertUTCtoLocal(rosterMonth, true)
                      //   });
                      // } else if (result.dismiss === Swal.DismissReason.cancel) {
                      //   cache.invalidateQueries();
                      // }
                    })
                  }
                  sx={{ width: '100%' }}
                  variant="contained"
                >
                  Complete Site
                </Button>
                <Button
                  onClick={() => {
                    if (shiftSelected.length === 0) {
                      setError('Please select atleast one shift to clear its roster');
                    } else {
                      Swal.fire({
                        title: 'Are you sure?',
                        text: `Clear roster for rest of the month!`,
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, Proceed!',
                        allowOutsideClick: false
                      }).then(() => {
                        // if (result.isConfirmed) {
                        //   clearRosterMutation({
                        //     shifts: shiftSelected,
                        //     sites: [],
                        //     today: getCurrentDate(),
                        //     date: convertUTCtoLocal(rosterMonth, true)
                        //   });
                        // } else if (result.dismiss === Swal.DismissReason.cancel) {
                        //   cache.invalidateQueries();
                        // }
                      });
                    }
                  }}
                  sx={{ width: '100%' }}
                  variant="contained"
                >
                  Clear Selected
                </Button>
              </Stack>
            ) : (
              <Button
                onClick={() => {
                  if (siteSelected.length === 0 && !allHolidays) {
                    setError('Please select aleast one site to remove its holidays');
                  } else {
                    onClose();
                    Swal.fire({
                      title: 'Are you sure?',
                      text: `Delete Holiday ${startDate} - ${endDate}!`,
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: 'Yes, Proceed!',
                      allowOutsideClick: false
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteHolidayMutation({
                          sites: siteSelected,
                          // dates: [startDate, endDate],
                          id: detail._id
                        });
                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                        cache.invalidateQueries();
                      }
                    });
                  }
                }}
                sx={{ width: '100%' }}
                variant="contained"
              >
                Submit
              </Button>
            )}
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}

DeleteHoliday.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  site: PropTypes.bool,
  detail: PropTypes.object
};

function GetHolidays({ monthDate, list, listHeight, filter }) {
  const theme = useTheme();
  const { info } = useSelector((state) => state.adminInfo);
  const [value, setValue] = React.useState(0);
  const [editHoliday, setEditHoliday] = React.useState(null);
  const [holidayDetails, setHolidayDetails] = React.useState(null);
  const [deleteHoliday, setDeleteHoliday] = React.useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const cardStyles = {
    height: 120,
    minHeight: 120,
    width: 180,
    color: '#222',
    borderRadius: 2,
    // border: '1px solid #222',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const iconContainer = {
    height: 20,
    width: 24,
    padding: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const iconStyle = {
    fontSize: 17,
    cursor: 'pointer'
  };

  const {
    isLoading: isHolidaysLoading,
    isError: isHolidaysError,
    data
  } = useHolidays(monthDate, null, filter?.status);

  const HOLIDAYS = React.useMemo(() => {
    // console.log('Holidays:', data?.holidays);
    if (!isHolidaysLoading && !isHolidaysError) {
      if (data) {
        return data.holidays;
      }
    }
    return [];
  }, [data, isHolidaysLoading, isHolidaysError]);

  return (
    <>
      {isHolidaysLoading ? (
        <Stack direction="row">
          <NotAvailable type="loading" msg="Loading..." />
        </Stack>
      ) : isHolidaysError ? (
        <NotAvailable msq="Something Went Wrong" />
      ) : !isHolidaysLoading && !isHolidaysError ? (
        list ? (
          <Stack
            spacing={1}
            className="custom_scrollbar"
            sx={{ overflowY: 'auto', overflowX: 'hidden', height: listHeight }}
          >
            {HOLIDAYS?.length > 0 ? (
              HOLIDAYS.map((d, indx) => {
                const startDate = convertUTCtoLocal(d.dates[0], true).split('-');
                const endDate = convertUTCtoLocal(d.dates[1], true).split('-');
                const holidays = d.holidays.sort((a, b) => a.priority - b.priority);
                return (
                  <Stack
                    key={d._id}
                    style={{
                      margin: 10,
                      width: '95%',
                      border: `2px solid ${lightGradients[indx]?.border}`,
                      display: 'block'
                    }}
                    sx={cardStyles}
                  >
                    <Box
                      display="flex"
                      style={{
                        backgroundImage: lightGradients[indx]?.gradient,
                        justifyContent: 'space-between',
                        borderTopLeftRadius: '0.8rem',
                        borderTopRightRadius: '0.8rem'
                      }}
                      sx={{ px: 1 }}
                    >
                      <Typography>
                        {shortMonth[formatDigit(startDate[1]) - 1]}, {startDate[0]}
                      </Typography>
                      <Box display="flex">
                        {(info?._id === holidays[0]?.addedBy?._id || info?.isMainAdmin) && (
                          <>
                            <Box
                              onClick={() => setDeleteHoliday(d)}
                              style={iconContainer}
                              sx={{ color: theme.palette.error.main }}
                            >
                              <Tooltip title="Delete Holiday">
                                <DeleteOutlinedIcon style={iconStyle} />
                              </Tooltip>
                            </Box>
                          </>
                        )}
                        <Box
                          onClick={() => setHolidayDetails(d)}
                          style={iconContainer}
                          sx={{ color: theme.palette.grey[700], borderTopRightRadius: 15 }}
                        >
                          <Tooltip title="Holiday Details">
                            <InfoIcon sx={{ mr: 0.5 }} style={iconStyle} />
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                    <Stack sx={{ justifyContent: 'center', pt: 1.5, alignItems: 'center' }}>
                      {startDate[2] !== undefined && (
                        <Typography variant="h5">
                          {ordinal(formatDigit(startDate[2]))}{' '}
                          {endDate[2] !== undefined && `- ${ordinal(formatDigit(endDate[2]))}`}
                        </Typography>
                      )}
                      <Typography sx={{ fontSize: 12 }}>
                        {d.reason}&nbsp;
                        <EditOutlinedIcon onClick={() => setEditHoliday(d)} style={iconStyle} />
                      </Typography>
                      <Typography
                        sx={{ position: 'absolute', bottom: 0, right: 0, m: 1, fontSize: 11 }}
                      >
                        Given By -&nbsp;
                        {holidays[0].addedBy === null ? 'Admin' : holidays[0]?.addedBy?.name}
                      </Typography>
                    </Stack>
                  </Stack>
                );
              })
            ) : (
              <NotAvailable type="na" msg="No Data Available" />
            )}
          </Stack>
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 }
              },
              [`& .${tabsClasses.indicator}`]: { backgroundColor: 'white' }
            }}
          >
            {HOLIDAYS?.length > 0 ? (
              HOLIDAYS.map((d, idnx) => {
                const startDate = convertUTCtoLocal(d.dates[0], true).split('-');
                const endDate = convertUTCtoLocal(d.dates[1], true).split('-');
                const holidays = d.holidays.sort((a, b) => a.priority - b.priority);
                return (
                  <Tab
                    key={d._id}
                    label={
                      <Stack
                        style={{
                          color: lightGradients[idnx]?.color,
                          backgroundImage: lightGradients[idnx]?.gradient
                        }}
                        sx={cardStyles}
                      >
                        <Box
                          display="flex"
                          sx={{
                            position: 'absolute',
                            alignItems: 'center',
                            justifyContent: 'right',
                            top: 0,
                            right: 0
                          }}
                        >
                          {(info?._id === holidays[0]?.addedBy?._id ||
                            (holidays[0]?.addedBy === null && info?.isMainAdmin)) && (
                            <>
                              <Box
                                onClick={() => setDeleteHoliday(d)}
                                style={iconContainer}
                                sx={{ color: theme.palette.error.main }}
                              >
                                <Tooltip title="Delete Holiday">
                                  <DeleteOutlinedIcon style={iconStyle} />
                                </Tooltip>
                              </Box>
                            </>
                          )}
                          <Box
                            onClick={() => setHolidayDetails(d)}
                            style={iconContainer}
                            sx={{ color: theme.palette.grey[700], borderTopRightRadius: 15 }}
                          >
                            <Tooltip title="Holiday Details">
                              <InfoIcon
                                sx={{ mr: 0.5, color: lightGradients[idnx]?.color }}
                                style={iconStyle}
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: 13,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            p: 0.5,
                            ml: 1
                          }}
                        >
                          {shortMonth[formatDigit(startDate[1]) - 1]}, {startDate[0]}
                        </Typography>
                        <Typography variant="h5">
                          {ordinal(formatDigit(startDate[2]))} - {ordinal(formatDigit(endDate[2]))}
                        </Typography>
                        <Typography sx={{ fontSize: 12 }}>
                          {d.reason}
                          &nbsp;
                          <EditOutlinedIcon onClick={() => setEditHoliday(d)} style={iconStyle} />
                        </Typography>
                        <Typography sx={{ position: 'absolute', bottom: 0, m: 1, fontSize: 11 }}>
                          By&nbsp;
                          {holidays[0].addedBy === null ? 'Admin' : holidays[0]?.addedBy?.name}
                        </Typography>
                      </Stack>
                    }
                  />
                );
              })
            ) : (
              <NotAvailable type="na" msg="No Data Available" imgSize={80} />
            )}
          </Tabs>
        )
      ) : null}
      <EditHoliday
        open={editHoliday != null}
        onClose={() => setEditHoliday(null)}
        edit={editHoliday}
      />
      <HolidayDetails
        open={holidayDetails != null}
        onClose={() => setHolidayDetails(null)}
        detail={holidayDetails}
      />
      <DeleteHoliday
        open={deleteHoliday != null}
        onClose={() => setDeleteHoliday(null)}
        detail={deleteHoliday}
      />
    </>
  );
}

GetHolidays.propTypes = {
  monthDate: PropTypes.string,
  list: PropTypes.bool,
  listHeight: PropTypes.number,
  filter: PropTypes.object
};

export default function AppHolidays({
  list,
  height,
  noShadow,
  small,
  show,
  site,
  listContainerHeight
}) {
  const currentDate = getCurrentDate().split('-');
  const [rosterMonth] = React.useState(new Date());
  const theme = useTheme();
  const [openModal, setOpenModal] = React.useState('');
  const [filter] = React.useState({
    status: 'Active'
  });
  const firstDate = `${currentDate[0]}-${currentDate[1]}-01`;
  const [monthDate, setMonthDate] = React.useState(firstDate);
  const changeMonth = (date, next = false) => {
    const yy = Number(date.split('-')[0]);
    const mm = Number(date.split('-')[1]);
    const dd = Number(date.split('-')[2]);
    if (next) {
      if (mm === 12) {
        setMonthDate(`${yy + 1}-0${1}-0${dd}`);
        return;
      }
      setMonthDate(`${yy}-${mm + 1 < 10 ? `0${mm + 1}` : mm + 1}-0${dd}`);
      return;
    }
    if (mm === 1) {
      setMonthDate(`${yy - 1}-${12}-0${dd}`);
      return;
    }
    setMonthDate(`${yy}-${mm - 1 < 10 ? `0${mm - 1}` : mm - 1}-0${dd}`);
  };
  const cStyle = { boxShadow: 'none' };
  return (
    <Card style={{ height: 440 }} sx={noShadow ? cStyle : {}} hidden={!show}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Tooltip title="Prev Month">
          <Button variant="text" sx={{ borderRadius: 0 }} onClick={() => changeMonth(monthDate)}>
            Prev
          </Button>
        </Tooltip>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          align="center"
        >
          <b>
            Holidays{' '}
            {!small && (
              <>
                (&nbsp;{shortMonth[formatDigit(monthDate.split('-')[1]) - 1]}, &nbsp;
                {monthDate.split('-')[0]}&nbsp;)
              </>
            )}
          </b>
          &nbsp;
          {/* {filter.status === 'Active' ? (
            <Chip
              onClick={() => setFilter({ ...filter, status: 'Inactive' })}
              sx={{ color: theme.palette.primary.main, fontSize: 14, height: 20 }}
              label="Active"
            />
          ) : (
            <Chip
              onClick={() => setFilter({ ...filter, status: 'Active' })}
              sx={{ color: theme.palette.primary.main, fontSize: 14, height: 20 }}
              label="Inactive"
            />
          )} */}
          &nbsp;
          <IconButton
            onClick={() => setOpenModal('add-holidays-modal')}
            sx={{ color: theme.palette.primary.main, p: '4px' }}
          >
            <AddCircleIcon />
          </IconButton>
        </Typography>
        <Tooltip title="Next Month">
          <Button
            variant="text"
            sx={{ borderRadius: 0 }}
            onClick={() => changeMonth(monthDate, true)}
          >
            Next
          </Button>
        </Tooltip>
      </Stack>
      <Divider />
      <Stack sx={{ height: listContainerHeight }}>
        <GetHolidays monthDate={monthDate} list={list} listHeight={height} filter={filter} />
      </Stack>
      {openModal === 'add-holidays-modal' && (
        <AddHoliday
          open={openModal === 'add-holidays-modal'}
          onClose={() => setOpenModal('')}
          rosterMonth={rosterMonth}
          site={site}
        />
      )}
    </Card>
  );
}

AppHolidays.propTypes = {
  height: PropTypes.number,
  list: PropTypes.bool,
  noShadow: PropTypes.bool,
  small: PropTypes.bool,
  show: PropTypes.bool,
  site: PropTypes.string,
  listContainerHeight: PropTypes.number
};
