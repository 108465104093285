/* eslint-disable no-nested-ternary */
import { useState, useEffect, useMemo, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, FormikProvider, Form } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
// icons
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import WcIcon from '@mui/icons-material/Wc';
import EmailIcon from '@mui/icons-material/Email';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContactsIcon from '@mui/icons-material/Contacts';
import PasswordIcon from '@mui/icons-material/Password';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import CloseIcon from '@mui/icons-material/Close';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import AddIcon from '@mui/icons-material/Add';
import FocusError from '../components/FocusError';
import NotAvailable from '../components/NotAvailable';
// redux
import { logOut } from '../redux/actions/adminActions';
import { countries } from '../utils/countries';
// utils
import { SWAL_TIME, TRACKKAR_LOGO } from '../utils/config';
import { Decrypt, getBase64, capitalizeEachWord, convertUTCtoLocal, uid } from '../utils/functions';
import { addResume } from '../api/mutations/adminMutations';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function GetResumes() {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const params = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const edata = decodeURIComponent(params.edata.split(':')[1]);
  const key = edata.substring(0, 10);
  const originalText = Decrypt(edata.substring(10, edata.length), `sec ${key}`).split(',');

  const [pictureUploading, setPictureUploading] = useState(false);
  const [pictureTouched, setpictureTouched] = useState(false); // Check Focused Profile Picture
  const [dateOfBirth, setDateOfBirth] = useState('');
  // const [dateOfJoining, setDateOfJoining] = useState('');

  // Get Experience
  const [experience, setExperience] = useState([]);
  // Get Education
  const [education, setEducation] = useState([]);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const _username = useMemo(() => originalText[3].substring(0, 16), [originalText]);

  // Mutation
  const { mutate: addResumeMutation } = useMutation(addResume, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Adding resume to Queue 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    }
  });

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    initialValues: {
      job_title: originalText?.[0].replace('"', ''),
      job_description: originalText?.[1],
      username: _username,
      name: '',
      gender: '',
      blood_group: '',
      email: '',
      mobile: '',
      password: '',
      dateOfBirth: '',
      profilePicture: '',
      country: '91',
      // state: '',
      // pan_proof: '',
      aadhar: '',
      aadhar_proof: '',
      address: '',
      doj: '',
      marital_status: '',
      reliving_certificate: '',
      exp_certificate: '',
      bank_name: '',
      bank_account_no: '',
      bank_ifsc_code: '',
      bank_branch: '',
      previous_employ_start: new Date(),
      previous_employ_end: new Date(),
      previous_organization_name: '',
      previous_designation: '',
      previous_ctc: '',
      permanent_house_no: '',
      permanent_village_town_street: '',
      permanent_pincode: '',
      permanent_country: '',
      permanent_state: '',
      permanent_district: '',
      permanent_city: '',
      same_as_permanent: false,
      correspondence_house_no: '',
      correspondence_village_town_street: '',
      correspondence_pincode: '',
      correspondence_country: '',
      correspondence_state: '',
      correspondence_district: '',
      correspondence_city: '',
      emergency_contact_country: '',
      emergency_contact_no: '',
      applicant_resume: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name is required')
        .matches(
          /^[a-zA-Z][a-zA-Z\s]*$/,
          'Please enter a valid name (only alphabets & whitespaces are allowed)'
        ),
      gender: Yup.string().required('Gender is required'),
      email: Yup.string().email('Invalid email address').notRequired(),
      mobile: Yup.string()
        .required('Mobile number is required')
        .matches(/^[6-9]\d{9}$/, 'Invalid mobile number'),
      country: Yup.string().required('Country Code is required')
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        job_title: values.job_title,
        job_description: values.job_description,
        username: values.username ?? _username,
        resumeId: originalText[4],
        name: capitalizeEachWord(values.name),
        gender: values.gender,
        marital_status: values.marital_status,
        email: values.email.toLowerCase(),
        mobile: values.mobile,
        // password: values.password,
        // confirmPassword: values.password,
        dateOfBirth: convertUTCtoLocal(values.dateOfBirth, true),
        // state: values.state,
        // pan: {
        //   value: values.pan,
        //   data: values.pan_proof
        // },
        aadhar: {
          value: values.aadhar,
          data: values.aadhar_proof
        },
        blood_group: values.blood_group,
        country_code: values.country,
        address: capitalizeEachWord(values.address),
        education_details: education.filter((edu) => edu.aggregate > 0),
        experience_details: experience
          .map((ep) => {
            // eslint-disable-next-line prefer-destructuring
            ep.from = convertUTCtoLocal(ep.from, true).split('-')[0];
            // eslint-disable-next-line prefer-destructuring
            ep.to = convertUTCtoLocal(ep.to, true).split('-')[0];
            return ep;
          })
          .filter((exp) => exp?.certificate !== undefined),
        relivingCertificate: values.reliving_certificate,
        profilePicture: values.profilePicture,
        permanent_address: {
          permanent_house_no: values.permanent_house_no,
          permanent_village_town_street: values.permanent_village_town_street,
          permanent_pincode: values.permanent_pincode,
          permanent_country: values.permanent_country,
          permanent_state: values.permanent_state,
          // permanent_district: values.permanent_district,
          permanent_city: values.permanent_city
        },
        correspondence_address: {
          correspondence_house_no: values.correspondence_house_no,
          correspondence_village_town_street: values.correspondence_village_town_street,
          correspondence_pincode: values.correspondence_pincode,
          correspondence_country: values.correspondence_country,
          correspondence_state: values.correspondence_state,
          // correspondence_district: values.correspondence_district,
          correspondence_city: values.correspondence_city
        },
        previous_employment_details: {
          previous_employ_start: convertUTCtoLocal(values.previous_employ_start, true),
          previous_employ_end: convertUTCtoLocal(values.previous_employ_end, true),
          previous_organization_name: values.previous_organization_name,
          previous_designation: values.previous_designation,
          previous_ctc: values.previous_ctc
        },
        bank_details: {
          bank_name: values.bank_name,
          bank_branch: values.bank_branch,
          bank_account_no: values.bank_account_no,
          bank_ifsc_code: values.bank_ifsc_code
        },
        emergency_contact: {
          country_code: values.emergency_contact_country,
          contact_no: values.emergency_contact_no
        },
        upload_resume: values.applicant_resume
      };

      // console.log('Resume Add Form :', data);

      addResumeMutation(data, {
        onError: (error) => {
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false
            // timer: 2000,
            // timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          document.getElementById('profilePicture').value = null;
          // values?.profilePicture = '';
          actions.setSubmitting(false);
          resetForm();
          setDateOfBirth('');
          setEducation([]);
          setExperience([]);
          Swal.fire({
            icon: 'success',
            title: 'Resume saved!',
            text: data.message,
            showConfirmButton: false,
            timer: SWAL_TIME,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          cache.invalidateQueries('resumes');
        }
      });
    }
  });

  const {
    errors,
    touched,
    values,
    // isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm
  } = formik;

  const _countries = Country.getAllCountries().map((cn) => `${cn.isoCode},${cn.name}`);
  const _permanentStates = State.getStatesOfCountry(values.permanent_country.split(',')[0]).map(
    (pst) => `${pst.isoCode},${pst.name}`
  );
  const _permanentCities = City.getCitiesOfState(
    values.permanent_country.split(',')[0],
    values.permanent_state.split(',')[0]
  ).map((pct) => `${pct.isoCode},${pct.name}`);
  const _correspondanceStates = State.getStatesOfCountry(
    values.correspondence_country.split(',')[0]
  ).map((pst) => `${pst.isoCode},${pst.name}`);
  const _correspondanceCities = City.getCitiesOfState(
    values.correspondence_country.split(',')[0],
    values.correspondence_state.split(',')[0]
  ).map((pct) => `${pct.isoCode},${pct.name}`);

  useEffect(() => {
    if (values.same_as_permanent) {
      values.correspondence_house_no = values.permanent_house_no;
      values.correspondence_village_town_street = values.permanent_village_town_street;
      values.correspondence_pincode = values.permanent_pincode;
      values.correspondence_country = values.permanent_country;
      values.correspondence_state = values.permanent_state;
      // values.correspondence_district = values.permanent_district;
      values.correspondence_city = values.permanent_city;
    } else {
      values.correspondence_house_no = '';
      values.correspondence_village_town_street = '';
      values.correspondence_pincode = '';
      values.correspondence_country = '';
      values.correspondence_state = '';
      values.correspondence_district = '';
      values.correspondence_city = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.same_as_permanent]);

  // For Image Size : 2MB , Supported Formats
  const FILE_SIZE = 2 * 1024 * 1024;
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
  const [expiryYear, expiryMonth, expiryDate] = originalText[2].split('-');
  // eslint-disable-next-line prefer-destructuring
  //   token = isAdminAuthenticated
  //     ? undefined
  //     : originalText[2]?.substring(0, originalText[2]?.length - 1);
  return (
    <Stack sx={{ p: 2 }} direction="row" justifyContent="center">
      {new Date().setHours(0, 0, 0, 0) <
      new Date(expiryYear, expiryMonth, expiryDate, 0, 0, 0, 0) ? (
        <Stack
          sx={{
            width: 'calc(100vw - 10rem)',
            minHeight: '100vh',
            border: '2px solid #0897FF',
            borderRadius: 0.5
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
            <Box>
              <Typography variant="h4" sx={{ color: '#0897FF' }}>
                Job Title
              </Typography>
              <Typography>{originalText?.[0].replace('"', '')}</Typography>
              <Typography variant="h4" sx={{ color: '#0897FF' }}>
                Description
              </Typography>
              <Typography>{originalText?.[1]}</Typography>
            </Box>
            <Box sx={{ px: 2 }}>
              <Avatar
                sx={{
                  width: 100,
                  height: 100
                }}
                src={TRACKKAR_LOGO}
              />
            </Box>
          </Stack>
          <Divider sx={{ border: '3px solid #0897FF' }} />
          <FormikProvider value={formik}>
            <Form id="add-employee-form" autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={1} sx={{ px: 2, py: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Personal Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {/* Profile Picture */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <InsertPhotoIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <InputLabel variant="standard" htmlFor="profilePicture">
                        <Button
                          sx={{
                            color: `${errors.profilePicture ? 'red' : null}`
                          }}
                          variant="raised"
                          component="span"
                          onFocus={() => setpictureTouched(true)}
                        >
                          Profile Picture&nbsp;
                          {pictureUploading ? (
                            <CircularProgress size={0.9 * 23} />
                          ) : values.profilePicture && errors.profilePicture === undefined ? (
                            <CheckCircleIcon size={14} sx={{ color: 'green' }} />
                          ) : (
                            <AddIcon />
                          )}
                        </Button>
                      </InputLabel>
                      <TextField
                        variant="standard"
                        sx={{ display: 'none' }}
                        fullWidth
                        required
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        id="profilePicture"
                        name="profilePicture"
                        // eslint-disable-next-line prefer-destructuring
                        onChange={async (event) => {
                          setPictureUploading(true);
                          values.profilePicture = '';
                          const profilePicture = event.currentTarget.files[0];
                          if (profilePicture) {
                            const { type, size } = profilePicture;
                            if (!SUPPORTED_FORMATS.includes(type)) {
                              errors.profilePicture = 'Unsupported Format';
                            } else if (size > FILE_SIZE) {
                              errors.profilePicture = 'File too large (Max: 2MB)';
                            } else {
                              delete errors.profilePicture;
                              values.profilePicture = await getBase64(profilePicture);
                            }
                          }
                          if (!values.profilePicture) {
                            errors.profilePicture = 'Profile picture is required';
                          }
                          setPictureUploading(false);
                        }}
                      />
                    </Box>
                    {errors.profilePicture ? (
                      <Typography align="center" sx={{ color: 'red', fontSize: 12 }}>
                        {errors.profilePicture}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    {/* Name */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Name"
                        autoComplete="off"
                        required
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    {/* Date Of Birth */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date Of Birth"
                          disableFuture
                          value={dateOfBirth}
                          onChange={(dateOfBirth) => {
                            values.dateOfBirth = convertUTCtoLocal(dateOfBirth, true);
                            setDateOfBirth(values.dateOfBirth);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              fullWidth
                              {...params}
                              error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                              helperText={touched.dateOfBirth && errors.dateOfBirth}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    {/* Gender */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="employee_gender">
                          Choose Gender
                        </InputLabel>
                        <Select
                          variant="standard"
                          inputProps={{
                            name: 'gender',
                            id: 'employee_gender'
                          }}
                          required
                          sx={{ mt: 2 }}
                          {...getFieldProps('gender')}
                          error={Boolean(touched.gender && errors.gender)}
                          // helperText={touched.policy_role && errors.policy_role}
                        >
                          <MenuItem value="" disabled>
                            Choose Gender
                          </MenuItem>
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="marital_status">
                          Marital Status
                        </InputLabel>
                        <Select
                          variant="standard"
                          inputProps={{
                            name: 'marital_status',
                            id: 'marital_status'
                          }}
                          required
                          sx={{ mt: 2 }}
                          {...getFieldProps('marital_status')}
                          error={Boolean(touched.marital_status && errors.marital_status)}
                          // helperText={touched.policy_role && errors.policy_role}
                        >
                          <MenuItem value="" disabled>
                            Marital Status
                          </MenuItem>
                          <MenuItem value="Married">Married</MenuItem>
                          <MenuItem value="Unmarried">Unmarried</MenuItem>
                          <MenuItem value="Window">Widow</MenuItem>
                          <MenuItem value="Widower">Widower</MenuItem>
                          <MenuItem value="Divorced">Divorced</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    {/* Blood Group */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="blood_group">
                          Blood Group
                        </InputLabel>
                        <Select
                          variant="standard"
                          inputProps={{
                            name: 'blood_group',
                            id: 'blood_group'
                          }}
                          required
                          sx={{ mt: 2 }}
                          {...getFieldProps('blood_group')}
                          error={Boolean(touched.blood_group && errors.blood_group)}
                          // helperText={touched.policy_role && errors.policy_role}
                        >
                          <MenuItem value="" disabled>
                            Blood Group
                          </MenuItem>
                          <MenuItem value="A+">A+</MenuItem>
                          <MenuItem value="A-">A-</MenuItem>
                          <MenuItem value="B+">B+</MenuItem>
                          <MenuItem value="B-">B-</MenuItem>
                          <MenuItem value="O+">O+</MenuItem>
                          <MenuItem value="O-">O-</MenuItem>
                          <MenuItem value="AB+">AB+</MenuItem>
                          <MenuItem value="AB-">AB-</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item md={4} xs={12}>
                    {/* Aadhar */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Aadhar No"
                        autoComplete="off"
                        required
                        {...getFieldProps('aadhar')}
                        error={Boolean(touched.aadhar && errors.aadhar)}
                        helperText={touched.aadhar && errors.aadhar}
                      />
                    </Box>
                  </Grid>
                  <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={8} xs={12}>
                    <Button
                      color={`${values?.aadhar_proof?.length > 0 ? 'success' : 'primary'}`}
                      sx={{ mt: 2 }}
                      variant="contained"
                      size="small"
                      component="label"
                    >
                      Upload
                      <input
                        hidden
                        accept=".jpg,.jpeg,.png"
                        id="panCard"
                        name="panCard"
                        // eslint-disable-next-line prefer-destructuring
                        onChange={async (event) => {
                          // setPictureUploading(true);
                          values.aadhar_proof = '';
                          const aadharCard = event.currentTarget.files[0];
                          if (aadharCard) {
                            const { type, size } = aadharCard;
                            if (!SUPPORTED_FORMATS.includes(type)) {
                              errors.aadhar_proof = 'Unsupported Format';
                            } else if (size > FILE_SIZE) {
                              errors.aadhar_proof = 'File too large (Max: 2MB)';
                            } else {
                              delete errors.pan;
                              values.aadhar_proof = await getBase64(aadharCard);
                            }
                          }
                          if (!values.aadhar_proof) {
                            errors.aadhar_proof = 'Profile picture is required';
                          }
                          // setPictureUploading(false);
                        }}
                        type="file"
                      />
                    </Button>
                    <Typography sx={{ mt: 2, ml: 2, fontSize: 14 }}>
                      jpg, not more than 2MB
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    {/* Email */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <EmailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Email"
                        autoComplete="off"
                        // required
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    {/* Mobile */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <ContactPhoneIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl>
                        {/* <InputLabel id="mobile-country-select-label">Country</InputLabel> */}
                        <Select
                          labelId="mobile-country-select-label"
                          id="mobile-country-select"
                          variant="standard"
                          label="Country"
                          {...getFieldProps('country')}
                        >
                          {countries.map((c) => (
                            <MenuItem key={c.code} value={c.phone}>
                              <img
                                loading="lazy"
                                width="23"
                                src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                                alt={`Flag of ${c.label}`}
                              />
                              &nbsp; +{c.phone}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* &nbsp;[ {c.label} ] */}
                      <TextField
                        sx={{ width: 160 }}
                        variant="standard"
                        type="number"
                        label="Mobile"
                        autoComplete="off"
                        required
                        {...getFieldProps('mobile')}
                        error={Boolean(touched.mobile && errors.mobile)}
                      />
                    </Box>
                    {errors.mobile && (
                      <Typography align="center" sx={{ color: 'red', fontSize: 12 }}>
                        {errors.mobile}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Permanent Address
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="House No."
                        autoComplete="off"
                        required
                        {...getFieldProps('permanent_house_no')}
                        error={Boolean(touched.permanent_house_no && errors.permanent_house_no)}
                        helperText={touched.permanent_house_no && errors.permanent_house_no}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Village / Town / Street"
                        autoComplete="off"
                        required
                        {...getFieldProps('permanent_village_town_street')}
                        error={Boolean(
                          touched.permanent_village_town_street &&
                            errors.permanent_village_town_street
                        )}
                        helperText={
                          touched.permanent_village_town_street &&
                          errors.permanent_village_town_street
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Pincode"
                        autoComplete="off"
                        required
                        {...getFieldProps('permanent_pincode')}
                        error={Boolean(touched.permanent_pincode && errors.permanent_pincode)}
                        helperText={touched.permanent_pincode && errors.permanent_pincode}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="permanent_country">
                          Choose Country
                        </InputLabel>
                        <Select
                          variant="standard"
                          inputProps={{
                            name: 'permanent_country',
                            id: 'permanent_country'
                          }}
                          required
                          sx={{ mt: 2 }}
                          {...getFieldProps('permanent_country')}
                          error={Boolean(touched.permanent_country && errors.permanent_country)}
                          // helperText={touched.policy_role && errors.policy_role}
                        >
                          <MenuItem value="" disabled>
                            Choose Country
                          </MenuItem>
                          {_countries.map((cn) => (
                            <MenuItem key={cn.toLowerCase()} value={cn}>
                              {cn.split(',')[1]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="permanent_state">
                          State
                        </InputLabel>
                        <Select
                          variant="standard"
                          inputProps={{
                            name: 'permanent_state',
                            id: 'permanent_state'
                          }}
                          required
                          sx={{ mt: 2 }}
                          {...getFieldProps('permanent_state')}
                          error={Boolean(touched.permanent_state && errors.permanent_state)}
                          // helperText={touched.policy_role && errors.policy_role}
                        >
                          <MenuItem value="" disabled>
                            State
                          </MenuItem>
                          {_permanentStates.map((pst) => (
                            <MenuItem key={pst.toLowerCase()} value={pst}>
                              {pst.split(',')[1]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="permanent_city">
                          City
                        </InputLabel>
                        <Select
                          variant="standard"
                          inputProps={{
                            name: 'permanent_city',
                            id: 'permanent_city'
                          }}
                          required
                          sx={{ mt: 2 }}
                          {...getFieldProps('permanent_city')}
                          error={Boolean(touched.permanent_city && errors.permanent_city)}
                          // helperText={touched.policy_role && errors.policy_role}
                        >
                          <MenuItem value="" disabled>
                            City
                          </MenuItem>
                          {_permanentCities.map((pct) => (
                            <MenuItem key={pct.toLowerCase()} value={pct}>
                              {pct.split(',')[1]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Correspondence Details
                    </Typography>
                  </Grid>
                  <Grid xs={12} />
                  <Grid item md={3} xs={12}>
                    <Typography sx={{ mt: 1 }}>Save as Permanent</Typography>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Switch {...label} {...getFieldProps('same_as_permanent')} />
                  </Grid>
                  <Grid xs={12} />
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="House No."
                        autoComplete="off"
                        required
                        {...getFieldProps('correspondence_house_no')}
                        error={Boolean(
                          touched.correspondence_house_no && errors.correspondence_house_no
                        )}
                        helperText={
                          touched.correspondence_house_no && errors.correspondence_house_no
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Village / Town / Street"
                        autoComplete="off"
                        required
                        {...getFieldProps('correspondence_village_town_street')}
                        error={Boolean(
                          touched.correspondence_village_town_street &&
                            errors.correspondence_village_town_street
                        )}
                        helperText={
                          touched.correspondence_village_town_street &&
                          errors.correspondence_village_town_street
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <ContactsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Pincode"
                        autoComplete="off"
                        required
                        {...getFieldProps('correspondence_pincode')}
                        error={Boolean(
                          touched.correspondence_pincode && errors.correspondence_pincode
                        )}
                        helperText={touched.correspondence_pincode && errors.correspondence_pincode}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="correspondence_country">
                          Country
                        </InputLabel>
                        <Select
                          variant="standard"
                          inputProps={{
                            name: 'correspondence_country',
                            id: 'correspondence_country'
                          }}
                          required
                          sx={{ mt: 2 }}
                          {...getFieldProps('correspondence_country')}
                          error={Boolean(
                            touched.correspondence_country && errors.correspondence_country
                          )}
                          // helperText={touched.policy_role && errors.policy_role}
                        >
                          <MenuItem value="" disabled>
                            Country
                          </MenuItem>
                          {_countries.map((cn) => (
                            <MenuItem key={cn.toLowerCase()} value={cn}>
                              {cn.split(',')[1]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="correspondence_state">
                          State
                        </InputLabel>
                        <Select
                          variant="standard"
                          inputProps={{
                            name: 'correspondence_state',
                            id: 'correspondence_state'
                          }}
                          required
                          sx={{ mt: 2 }}
                          {...getFieldProps('correspondence_state')}
                          error={Boolean(
                            touched.correspondence_state && errors.correspondence_state
                          )}
                          // helperText={touched.policy_role && errors.policy_role}
                        >
                          <MenuItem value="" disabled>
                            State
                          </MenuItem>
                          {_correspondanceStates.map((cst) => (
                            <MenuItem key={cst.toLowerCase()} value={cst}>
                              {cst.split(',')[1]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  {/* <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="correspondence_district">
                          Choose District
                        </InputLabel>
                        <Select
                          variant="standard"
                          inputProps={{
                            name: 'correspondence_district',
                            id: 'correspondence_district'
                          }}
                          required
                          sx={{ mt: 2 }}
                          {...getFieldProps('correspondence_district')}
                          error={Boolean(
                            touched.correspondence_district && errors.correspondence_district
                          )}
                          // helperText={touched.policy_role && errors.policy_role}
                        >
                          <MenuItem value="" disabled>
                            Choose District
                          </MenuItem>
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid> */}
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="correspondence_city">
                          Choose City
                        </InputLabel>
                        <Select
                          variant="standard"
                          inputProps={{
                            name: 'correspondence_city',
                            id: 'correspondence_city'
                          }}
                          required
                          sx={{ mt: 2 }}
                          {...getFieldProps('correspondence_city')}
                          error={Boolean(touched.correspondence_city && errors.correspondence_city)}
                          // helperText={touched.policy_role && errors.policy_role}
                        >
                          <MenuItem value="" disabled>
                            Choose City
                          </MenuItem>
                          {_correspondanceCities.map((cct) => (
                            <MenuItem key={cct.toLowerCase()} value={cct}>
                              {cct.split(',')[1]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Educational Details
                    </Typography>
                  </Grid>
                  {education.map((edu) => (
                    <Fragment key={edu?.id}>
                      <Grid item md={3} xs={12}>
                        {/* Education */}
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <WcIcon sx={{ color: 'action.active', mr: 1 }} />
                          <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="employee_educatio">
                              Choose Education
                            </InputLabel>
                            <Select
                              variant="standard"
                              inputProps={{
                                name: 'education',
                                id: 'employee_education'
                              }}
                              required
                              sx={{ mt: 2 }}
                              onChange={(e) => {
                                const ed = education.find((du) => du.id === edu.id);
                                ed.type = e.target.value;
                                setEducation([...education]);
                              }}
                              error={Boolean(touched.education && errors.education)}
                              // helperText={touched.policy_role && errors.policy_role}
                            >
                              <MenuItem value="" disabled>
                                Choose Education
                              </MenuItem>
                              <MenuItem value="10th">10th</MenuItem>
                              <MenuItem value="12th">12th</MenuItem>
                              <MenuItem value="bachelor">Bachelor</MenuItem>
                              <MenuItem value="master">Master</MenuItem>
                              <MenuItem value="phd">PHD</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        {/* Aggregate */}
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <PersonIcon sx={{ color: 'action.active', mr: 1 }} />
                          <TextField
                            variant="standard"
                            fullWidth
                            type="text"
                            label="Aggregate ( % )"
                            autoComplete="off"
                            required
                            onChange={(e) => {
                              const ed = education.find((du) => du.id === edu.id);
                              ed.aggregate = e.target.value;
                              setEducation([...education]);
                            }}
                            error={Boolean(touched.aggregate && errors.aggregate)}
                            helperText={touched.aggregate && errors.aggregate}
                          />
                        </Box>
                      </Grid>
                      <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={4} xs={12}>
                        <Typography sx={{ mr: 2, mt: 1 }}>Certificate</Typography>
                        <Button
                          sx={{ mt: 1 }}
                          variant="contained"
                          color={`${edu?.certificate?.length > 0 ? 'success' : 'primary'}`}
                          size="small"
                          component="label"
                        >
                          Upload
                          <input
                            hidden
                            accept=".jpg,.jpeg,.png"
                            id={`certificate-${edu.id}`}
                            name={`certificate-${edu.id}`}
                            // eslint-disable-next-line prefer-destructuring
                            onChange={async (event) => {
                              // setPictureUploading(true);
                              const ed = education.find((du) => du.id === edu.id);
                              // eslint-disable-next-line prefer-destructuring
                              ed.certificate = event.currentTarget.files[0];
                              if (ed.certificate) {
                                const { type, size } = ed.certificate;
                                if (!SUPPORTED_FORMATS.includes(type)) {
                                  errors[`certificate-${edu.id}`] = 'Unsupported Format';
                                } else if (size > FILE_SIZE) {
                                  errors[`certificate-${edu.id}`] = 'File too large (Max: 2MB)';
                                } else {
                                  delete errors[`certificate-${edu.id}`];
                                  ed.certificate = await getBase64(ed.certificate);
                                }
                              }
                              if (!ed.certificate) {
                                errors[`certificate-${edu.id}`] = 'Certificate is required';
                              }
                              // setPictureUploading(false);
                            }}
                            type="file"
                          />
                        </Button>
                        <Typography sx={{ mt: 1, ml: 2, fontSize: 12 }}>
                          jpg, not more than 2MB
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <IconButton color="error" sx={{ mt: 0.5 }}>
                          <DeleteIcon
                            onClick={() => {
                              const ed = education.findIndex((du) => du.id === edu.id);
                              delete education[ed];
                              setEducation([...education]);
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Fragment>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      startIcon={<AddIcon />}
                      onClick={() =>
                        setEducation([
                          ...education,
                          {
                            id: uid(),
                            type: '',
                            aggregate: '',
                            certificate: ''
                          }
                        ])
                      }
                      variant="contained"
                      size="small"
                    >
                      Add
                    </Button>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Past Experience
                    </Typography>
                  </Grid>
                  {experience.map((exp) => (
                    <Fragment key={exp.id}>
                      <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={6} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            views={['year']}
                            label="From"
                            value={exp.from}
                            onChange={(newValue) => {
                              const xp = experience.find((xp) => xp.id === exp.id);
                              xp.from = newValue;
                              setExperience([...experience]);
                            }}
                            renderInput={(params) => (
                              <TextField variant="standard" {...params} helperText={null} />
                            )}
                          />
                        </LocalizationProvider>
                        <Typography sx={{ mx: 2 }}>-</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            views={['year']}
                            label="To"
                            value={exp.to}
                            onChange={(newValue) => {
                              const xp = experience.find((xp) => xp.id === exp.id);
                              xp.to = newValue;
                              setExperience([...experience]);
                            }}
                            renderInput={(params) => (
                              <TextField variant="standard" {...params} helperText={null} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={6} xs={12}>
                        <Typography sx={{ mr: 2, mt: 2 }}>Certificate</Typography>
                        <Button
                          sx={{ mt: 2 }}
                          variant="contained"
                          color={`${exp?.certificate?.length > 0 ? 'success' : 'primary'}`}
                          size="small"
                          component="label"
                        >
                          Upload
                          <input
                            hidden
                            accept=".jpg,.jpeg,.png"
                            id={`certificate-${exp.id}`}
                            name={`certificate-${exp.id}`}
                            // eslint-disable-next-line prefer-destructuring
                            onChange={async (event) => {
                              // setPictureUploading(true);
                              const xp = experience.find((du) => du.id === exp.id);
                              // eslint-disable-next-line prefer-destructuring
                              xp.certificate = event.currentTarget.files[0];
                              if (xp.certificate) {
                                const { type, size } = xp.certificate;
                                if (!SUPPORTED_FORMATS.includes(type)) {
                                  errors[`certificate-${exp.id}`] = 'Unsupported Format';
                                } else if (size > FILE_SIZE) {
                                  errors[`certificate-${exp.id}`] = 'File too large (Max: 2MB)';
                                } else {
                                  delete errors[`certificate-${exp.id}`];
                                  xp.certificate = await getBase64(xp.certificate);
                                }
                              }
                              if (!xp.certificate) {
                                errors[`certificate-${exp.id}`] = 'Certificate is required';
                              }
                              // setPictureUploading(false);
                            }}
                            type="file"
                          />
                        </Button>
                      </Grid>
                    </Fragment>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      onClick={() =>
                        setExperience([
                          ...experience,
                          {
                            id: uid(),
                            from: undefined,
                            to: undefined,
                            certificate: ''
                          }
                        ])
                      }
                      startIcon={<AddIcon />}
                      variant="contained"
                      size="small"
                    >
                      Add
                    </Button>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Previous Employment Details
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={6} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        views={['year']}
                        label="From"
                        value={values.previous_employ_start}
                        onChange={(newValue) => {
                          values.previous_employ_start = newValue;
                        }}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} helperText={null} />
                        )}
                      />
                    </LocalizationProvider>
                    <Typography sx={{ mx: 2 }}>-</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        views={['year']}
                        label="To"
                        onChange={(newValue) => {
                          values.previous_employ_end = newValue;
                        }}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} helperText={null} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Organization Name"
                        autoComplete="off"
                        required
                        {...getFieldProps('previous_organization_name')}
                        error={Boolean(
                          touched.previous_organization_name && errors.previous_organization_name
                        )}
                        helperText={
                          touched.previous_organization_name && errors.previous_organization_name
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Designation"
                        autoComplete="off"
                        required
                        {...getFieldProps('previous_designation')}
                        error={Boolean(touched.previous_designation && errors.previous_designation)}
                        helperText={touched.designation && errors.designation}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="CTC"
                        autoComplete="off"
                        required
                        {...getFieldProps('previous_ctc')}
                        error={Boolean(touched.previous_ctc && errors.previous_ctc)}
                        helperText={touched.previous_ctc && errors.previous_ctc}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Past Experience Certificate
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={6} xs={12}>
                      <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        color={`${values?.exp_certificate?.length > 0 ? 'success' : 'primary'}`}
                        size="small"
                        component="label"
                      >
                        Upload
                        <input
                          hidden
                          accept=".jpg,.jpeg,.png"
                          id="exp-certificate"
                          name="exp-certificate"
                          // eslint-disable-next-line prefer-destructuring
                          onChange={async (event) => {
                            values.exp_certificate = '';
                            const expCertificate = event.currentTarget.files[0];
                            if (expCertificate) {
                              const { type, size } = expCertificate;
                              if (!SUPPORTED_FORMATS.includes(type)) {
                                errors.exp_certificate = 'Unsupported Format';
                              } else if (size > FILE_SIZE) {
                                errors.exp_certificate = 'File too large (Max: 2MB)';
                              } else {
                                delete errors.exp_certificate;
                                values.exp_certificate = await getBase64(expCertificate);
                              }
                            }
                            // if (!values.exp_certificate) {
                            //   errors.exp_certificate = 'Profile picture is required';
                            // }
                          }}
                          type="file"
                        />
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                      Bank Details
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* Bank Name */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Bank Name"
                        autoComplete="off"
                        required
                        {...getFieldProps('bank_name')}
                        error={Boolean(touched.bank_name && errors.bank_name)}
                        helperText={touched.bank_name && errors.bank_name}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* Bank Name */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Bank Branch"
                        autoComplete="off"
                        required
                        {...getFieldProps('bank_branch')}
                        error={Boolean(touched.bank_branch && errors.bank_branch)}
                        helperText={touched.bank_branch && errors.bank_branch}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* Bank Account No */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="Bank Account No"
                        autoComplete="off"
                        required
                        {...getFieldProps('bank_account_no')}
                        error={Boolean(touched.bank_account_no && errors.bank_account_no)}
                        helperText={touched.bank_account_no && errors.bank_account_no}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* Bank IFSC Code */}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        label="IFSC Code"
                        autoComplete="off"
                        required
                        {...getFieldProps('bank_ifsc_code')}
                        error={Boolean(touched.bank_ifsc_code && errors.bank_ifsc_code)}
                        helperText={touched.bank_ifsc_code && errors.bank_ifsc_code}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <br />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6">Reliving Certificate ( if Required )</Typography>
                  </Grid>
                  <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={8} xs={12}>
                    <Button
                      variant="contained"
                      sx={{ mt: 1 }}
                      color={`${values?.reliving_certificate?.length > 0 ? 'success' : 'primary'}`}
                      size="small"
                      component="label"
                    >
                      Upload
                      <input
                        hidden
                        accept=".jpg,.jpeg,.png"
                        id="reliving_certificate"
                        name="reliving_certificate"
                        // eslint-disable-next-line prefer-destructuring
                        onChange={async (event) => {
                          // setPictureUploading(true);
                          values.reliving_certificate = '';
                          const relivingCertificate = event.currentTarget.files[0];
                          if (relivingCertificate) {
                            const { type, size } = relivingCertificate;
                            if (!SUPPORTED_FORMATS.includes(type)) {
                              errors.reliving_certificate = 'Unsupported Format';
                            } else if (size > FILE_SIZE) {
                              errors.reliving_certificate = 'File too large (Max: 2MB)';
                            } else {
                              delete errors.reliving_certificate;
                              values.reliving_certificate = await getBase64(relivingCertificate);
                            }
                          }
                          if (!values.reliving_certificate) {
                            errors.reliving_certificate = 'Profile picture is required';
                          }
                          // setPictureUploading(false);
                        }}
                        type="file"
                      />
                    </Button>
                    <Typography sx={{ mt: 1, ml: 2, fontSize: 14 }}>
                      jpg, not more than 2MB
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <ContactPhoneIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl>
                        {/* <InputLabel id="mobile-country-select-label">Country</InputLabel> */}
                        <Select
                          labelId="emergency-contact-country"
                          id="emergency-contact-country"
                          variant="standard"
                          label="Country"
                          {...getFieldProps('emergency_contact_country')}
                        >
                          {countries.map((c) => (
                            <MenuItem key={c.code} value={c.phone}>
                              <img
                                loading="lazy"
                                width="23"
                                style={{ display: 'inline-block' }}
                                src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                                alt={`Flag of ${c.label}`}
                              />
                              &nbsp; +{c.phone}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* &nbsp;[ {c.label} ] */}
                      <TextField
                        sx={{ width: 250 }}
                        variant="standard"
                        type="number"
                        label="Emergency Contact No"
                        autoComplete="off"
                        required
                        {...getFieldProps('emergency_contact_no')}
                        error={Boolean(touched.emergency_contact_no && errors.emergency_contact_no)}
                      />
                    </Box>
                    {errors.emergency_contact_no && (
                      <Typography align="center" sx={{ color: 'red', fontSize: 12 }}>
                        {errors.emergency_contact_no}
                      </Typography>
                    )}
                  </Grid>
                  <Grid xs={12}>
                    <Divider sx={{ mt: 4 }} />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                      Resume
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: 'flex', alignItems: 'center' }} md={8} xs={12}>
                    <Button
                      variant="contained"
                      sx={{ mt: 1 }}
                      color={`${values?.applicant_resume?.length > 0 ? 'success' : 'primary'}`}
                      size="small"
                      component="label"
                    >
                      Upload
                      <input
                        hidden
                        accept=".pdf"
                        id="applicant_resume"
                        name="applicant_resume"
                        // eslint-disable-next-line prefer-destructuring
                        onChange={async (event) => {
                          // setPictureUploading(true);
                          values.applicant_resume = '';
                          const applicantResume = event.currentTarget.files[0];
                          if (applicantResume) {
                            const { type, size } = applicantResume;
                            if (!['application/pdf'].includes(type)) {
                              errors.applicant_resume = 'Unsupported Format';
                            } else if (size > FILE_SIZE) {
                              errors.applicant_resume = 'File too large (Max: 2MB)';
                            } else {
                              delete errors.applicant_resume;
                              values.applicant_resume = await getBase64(applicantResume);
                            }
                          }
                          if (!values.applicant_resume) {
                            errors.applicant_resume = 'Applicant resume is required';
                          }
                          // setPictureUploading(false);
                        }}
                        type="file"
                      />
                    </Button>
                    <Typography sx={{ mt: 1, ml: 2, fontSize: 14 }}>
                      pdf, not more than 2MB
                    </Typography>
                  </Grid>
                </Grid>

                {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <LocationCityIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="grouped-select">
                    Choose State
                  </InputLabel>
                  <Select
                    variant="standard"
                    id="grouped-select"
                    label="Choose State"
                    {...getFieldProps('state')}
                    error={Boolean(touched.state && errors.state)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <ListSubheader>States</ListSubheader>
                    {INDIAN?.states?.length > 0 ? (
                      INDIAN?.states.map((key) => (
                        <MenuItem key={`${'state'}-${key}`} value={key}>
                          {key}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                    <ListSubheader>Union Territories</ListSubheader>
                    {INDIAN.union_territories?.length > 0 ? (
                      INDIAN.union_territories.map((key) => (
                        <MenuItem key={`${'territory'}-${key}`} value={key}>
                          {key}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box> */}
              </Stack>
              <Stack direction="row" sx={{ px: 2, pb: 1.2, mt: 3 }}>
                <Button
                  onClick={() => {
                    Swal.fire({
                      title: 'Are you sure?',
                      // text: `Manager will be Updated`,
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: 'Yes, Proceed!',
                      allowOutsideClick: false
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleSubmit();
                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                        cache.invalidateQueries('resumes');
                      }
                    });
                  }}
                  variant="contained"
                >
                  Submit
                </Button>
              </Stack>
              <FocusError />
            </Form>
          </FormikProvider>
        </Stack>
      ) : (
        <Stack sx={{ my: 'auto' }} justifyContent="center" alignItems="center">
          <NotAvailable type="na" msg="Link Expired" />
        </Stack>
      )}
    </Stack>
  );
}
