import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DD_MM_YYYY, htmlToPDF, getCurrentDate } from '../../../utils/functions';

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 'calc(100vw - 35rem)',
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

function PFStatementForm({ open, handleClose, data }) {
  //   console.log(data);
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <Stack
          sx={{ p: 2, backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)' }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography varint="h6" sx={{ color: 'white' }}>
            PF Declaration Form No. - 11
          </Typography>
          <IconButton
            onClick={() => handleClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box sx={{ p: 2, fontSize: 10, overflow: 'auto', height: 'calc(100vh - 3rem)' }}>
          <Box id="pf-form-content">
            <Stack sx={{ textAlign: 'center', '& p,h6': { fontSize: 12 } }}>
              <Typography variant="h6">EMPLOYEE'S PROVIDENT FUND ORGANIZATION</Typography>
              <Typography>
                Employee's Provident Funds Scheme, 1952 ( Paragraph 34 & 57 ) &
              </Typography>
              <Typography>Employee's Pension Scheme, 1995 ( Paragraph 24 )</Typography>
              <Typography>
                (Declaration by a person taking up employment in any establishment on which EPF
                Scheme, 1952 and /or EPS, 1995 is applicable)
              </Typography>
            </Stack>
            <TableContainer
              sx={{
                mt: 1,
                '& .block-letter': {
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  color: '#000F55'
                },
                '& p': {
                  fontSize: 12
                },
                '& span': {
                  fontSize: 12
                },
                '& td': {
                  fontSize: 12,
                  py: 0.2
                }
              }}
              component={Paper}
            >
              <Table size="small" sx={{ border: '1px solid rgba(241, 243, 244, 1)' }}>
                <TableBody
                  sx={{
                    '& .MuiTableBody-root:first-child td': {
                      maxWidth: 10,
                      px: 0
                    },
                    '& .form-index': {
                      width: 10
                    },
                    '& .MuiCheckbox-root': {
                      p: 0,
                      py: 0
                    }
                  }}
                >
                  <TableRow>
                    <TableCell className="form-index">1</TableCell>
                    <TableCell>Name of the Member</TableCell>
                    <TableCell>
                      <span className="block-letter">{data?.name ?? '---------------'}</span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="form-index">2</TableCell>
                    <TableCell>
                      <Stack sx={{ minWidth: '12rem' }} direction="row" alignItems="center">
                        <FormControlLabel
                          label="Father Name"
                          control={
                            <Checkbox
                              checked={!!data?.father_name && !data?.spouse_name}
                              size="small"
                            />
                          }
                        />{' '}
                        <FormControlLabel
                          label="Spouse Name"
                          control={<Checkbox checked={!!data?.spouse_name} size="small" />}
                        />
                        <Typography sx={{ p: 0, m: 0 }}>
                          (Please Tick whichever is applicable)
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <span className="block-letter">
                        {data?.spouse_name ?? data?.father_name ?? 'xxxxxxxxxx'}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="form-index">3</TableCell>
                    <TableCell>
                      <Typography variant="body1">Date of Birth ( DD / MM / YYYY )</Typography>
                    </TableCell>
                    <TableCell>
                      <span className="block-letter">
                        {DD_MM_YYYY(data?.dob).split('-').join('/') ?? '-- / -- / ----'}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="form-index">4</TableCell>
                    <TableCell>
                      <Typography variant="subtitles">
                        Gender ( Male / Female / Transgender )
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <span className="block-letter">{data?.gender}</span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="form-index">5</TableCell>
                    <TableCell>
                      <Typography variant="subtitles">
                        Marital Status ( Married / Unmarried / Widow / Widower / Divorcee )
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <span className="block-letter">{data?.marital_status ?? 'xxxxxxxxxx'}</span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="form-index">6</TableCell>
                    <TableCell>
                      <Stack>
                        <Typography variant="subtitles">(a) Email Id</Typography>
                        <Typography variant="subtitles">(b) Mobile No</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack>
                        <Typography variant="subtitles">
                          <span className="block-letter">{data?.email ?? 'xxxxxxxxxxxxx'}</span>
                        </Typography>
                        <Typography variant="subtitles">
                          <span className="block-letter">{data?.contact}</span>
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="form-index">7</TableCell>
                    <TableCell>
                      <Typography variant="subtitles">
                        Whether earlier a member of Employee's Provident Fund Scheme, 1952
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" alignItems="center">
                        <FormControlLabel
                          label="Yes"
                          labelPlacement="start"
                          control={<Checkbox size="small" />}
                        />
                        <FormControlLabel
                          label="No"
                          labelPlacement="start"
                          control={<Checkbox size="small" />}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="form-index">8</TableCell>
                    <TableCell>
                      <Typography variant="subtitles">
                        Whether earlier a member of Employee's Pension Scheme, 1955
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" alignItems="center">
                        <FormControlLabel
                          label="Yes"
                          labelPlacement="start"
                          control={<Checkbox size="small" />}
                        />
                        <FormControlLabel
                          label="No"
                          labelPlacement="start"
                          control={<Checkbox size="small" />}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="form-index">9</TableCell>
                    <TableCell>
                      <Stack>
                        <Typography variant="subtitles">
                          <b>Previours employment details: [if Yes to 7 AND/OR 8 above]</b>
                        </Typography>
                        <Typography variant="subtitles">(a) Universal Account Number</Typography>
                        <Typography variant="subtitles">(b) Previous PF Account Number</Typography>
                        <Typography variant="subtitles">
                          (c) Date of exit from previous employment (DD/MM/YYYY)
                        </Typography>
                        <Typography variant="subtitles">
                          (d) Scheme Certificate No. ( if issued )
                        </Typography>
                        <Typography variant="subtitles">
                          (e) Pension Payment Order (PPO) No. ( if issued )
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack>
                        <Typography sx={{ mt: 2 }} />
                        <Typography variant="subtitles">
                          <span className="block-letter">
                            {data?.previous_uan ?? 'xxxxxxxxxxxxx'}
                          </span>
                        </Typography>
                        <Typography variant="subtitles">
                          <span className="block-letter">
                            {data?.previous_pf_no ?? 'xxxxxxxxxxxxx'}
                          </span>
                        </Typography>
                        <Typography variant="subtitles">
                          <span className="block-letter">
                            {data?.previous_doe ?? 'xx / xx / xxxx'}
                          </span>
                        </Typography>
                        <Typography variant="subtitles">
                          <span className="block-letter">
                            {data?.scheme_certificate_no ?? 'xxxxxxxxxxxxx'}
                          </span>
                        </Typography>
                        <Typography variant="subtitles">
                          <span className="block-letter">
                            {data?.previous_ppo ?? 'xxxxxxxxxxxxx'}
                          </span>
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="form-index">10</TableCell>
                    <TableCell>
                      <Stack>
                        <Typography variant="subtitles">(a) Internation Worker</Typography>
                        <Typography variant="subtitles">
                          (b) If Yes, state country of origin (India/Name of other country)
                        </Typography>
                        <Typography variant="subtitles">(c) Passport No.</Typography>
                        <Typography variant="subtitles">
                          (d) Validity of Passport [(DD/MM/YYYY) to (DD/MM/YYYY)]
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack>
                        <Stack direction="row" alignItems="center">
                          <FormControlLabel
                            label="Yes"
                            labelPlacement="start"
                            control={<Checkbox size="small" />}
                          />
                          <FormControlLabel
                            label="No"
                            labelPlacement="start"
                            control={<Checkbox size="small" />}
                          />
                        </Stack>
                        <Typography className="block-letter" variant="subtitles">
                          {data?.permanent_address?.permanent_country ?? 'xxxxxxxxxx'}
                        </Typography>
                        <Typography className="block-letter" variant="subtitles">
                          {data?.passport?.passport_no ?? 'xxxxxxxxxx'}
                        </Typography>
                        <Typography className="block-letter" variant="subtitles">
                          {data?.passport?.valid_from ?? 'xx / xx / xxxx'}&nbsp;to&nbsp;
                          {data?.passport?.valid_upto ?? 'xx / xx / xxxx'}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="form-index">11</TableCell>
                    <TableCell>
                      <Stack>
                        <Typography variant="subtitles">
                          KYC Details: (attach self attested copies of following KYCs)
                        </Typography>
                        <Typography variant="subtitles">(a) Bank Accoutn No. & IFS Code</Typography>
                        <Typography variant="subtitles">(b) AADHAR Number</Typography>
                        <Typography variant="subtitles">
                          (c) Permanent Account Number, if available
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack>
                        <Typography className="block-letter" variant="subtitles">
                          {data?.bank_details?.bank_account_no ?? 'xxxxxxxxxx'} &{' '}
                          {data?.bank_details?.bank_ifsc_code ?? 'xxxxx'}
                        </Typography>
                        <Typography className="block-letter" variant="subtitles">
                          {data?.aadhar?.value ?? 'xxxxxxxxxxxx'}
                        </Typography>
                        <Typography className="block-letter" variant="subtitles">
                          {data?.pan?.value ?? 'xxxxxxxxxxxx'}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" colSpan="3">
                      <u>
                        <b>Undertaking</b>
                      </u>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="3">
                      <Stack>
                        <Typography variant="subtitles">
                          1) Certified that the particulars are true to the best of my knowledge
                        </Typography>
                        <Typography variant="subtitles">
                          2) I authorize EPFO to use my Aadhar for verification/authentication/eKYC
                          purpose for service delivery
                        </Typography>
                        <Typography variant="subtitles">
                          3) Kindly transfer the funds and service details, if applicable, from the
                          previous PF account as declared above to the present PF Account. (The
                          transfer would be possible only if the identified KYC detail approved by
                          previous employer has been verified by present employer using his Digital
                          Signature Certificate)
                        </Typography>
                        <Typography variant="subtitles">
                          4) In case of changes in above details, the same will be intimated to
                          employer at the earliest
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="2" align="left">
                      <Stack>
                        <Typography variant="subtitles">Date</Typography>
                        <Typography variant="subtitles">Place</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitles">Signature of Member</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" colSpan="3">
                      <u>
                        <b>DECLARATION BY PRESENT EMPLOYER</b>
                      </u>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="3">
                      <Stack>
                        <Typography variant="subtitles">
                          A. The member Mr./Ms./Mrs.{' '}
                          <span className="block-letter">{data?.name}</span> has joined on{' '}
                          <span className="block-letter">
                            {DD_MM_YYYY(data?.doj) ?? 'xx / xx / xxxx'}
                          </span>{' '}
                          allotted PF Number{' '}
                          <span className="block-letter">{data?.pf_no ?? 'xxxxxxxxxx'}</span>
                        </Typography>
                        <Stack>
                          <Typography variant="subtitles">
                            B. In case the person was earlier not a member of EPF Scheme, 1952 and
                            1995:
                          </Typography>
                          <ul style={{ marginLeft: '2rem' }}>
                            <li>
                              <b>( Post allotment of UAN )</b> The UAN allotted for the member is
                              ...............
                            </li>
                            <li>
                              <b>Please Tick the Appropriate Option:</b>
                              <br />
                              The KYC details of the above member in UAN Database
                            </li>
                            <ol style={{ listStyle: 'none' }}>
                              <li>
                                <FormControlLabel
                                  label="Have not been uploaded"
                                  control={<Checkbox size="small" />}
                                />
                              </li>
                              <li>
                                <FormControlLabel
                                  label="Have been uploaded but not approved"
                                  control={<Checkbox size="small" />}
                                />
                              </li>
                              <li>
                                <FormControlLabel
                                  label="Have been uploaded and approved with DSC"
                                  control={<Checkbox size="small" />}
                                />
                              </li>
                            </ol>
                          </ul>
                        </Stack>
                        <Stack>
                          <Typography variant="subtitles">
                            C. In case the person was earlier a member of EPF Scheme, 1952 and EPS,
                            1995:
                          </Typography>
                          <ul style={{ marginLeft: '2rem' }}>
                            <li>
                              The above PF Account number/UAN of the member as mentioned in (A)
                              above has been tagged with his/her UAN/Previous Member ID as declared
                              by member.
                            </li>
                            <li>
                              <b>Please tick the Appropriate Option:</b>
                              <ol style={{ listStyle: 'none' }}>
                                <li>
                                  <FormControlLabel
                                    label="The KYC details of the above member in
                          the UAN database have been approved with Digital Signature
                          Certificate and transfer request has been generated on portal."
                                    control={<Checkbox size="small" />}
                                  />
                                </li>
                                <li>
                                  <FormControlLabel
                                    label="As the DSC of establishment are not
                                    registered with EPFO, the member has been informed to file
                                    physical claim (Form-13) for transfer of funds from his previous
                                    establishment."
                                    control={<Checkbox size="small" />}
                                  />
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </Stack>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Date:</Typography>
                    </TableCell>
                    <TableCell colSpan="2" align="right">
                      <Typography>Signature of Employer with Seal of Establishment</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Button
            onClick={() => {
              const _html = document.getElementById('pf-form-content');
              htmlToPDF(_html, `PF_Form-${data?.name?.replace(' ', '_')}_${getCurrentDate()}`);
            }}
            sx={{ mt: 2 }}
            variant="contained"
            size="small"
          >
            Download
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default React.memo(PFStatementForm);
