import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// redux
import { SWAL_TIME } from '../../utils/config';
import { logOut } from '../../redux/actions/adminActions';

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
  didRender: (data) => {
    // changing text alignment for alert and adding some padding
    const content = data.querySelector('#swal2-content');
    if (content) {
      content.style.paddingLeft = '1rem';
      content.style.paddingBottom = '.7rem';
      content.style.textAlign = 'left';
    }
  }
});

export default function useMutationFunc(
  func,
  msg,
  onSuccessFunc,
  onSettledFunc,
  onMutateFunc,
  onErrorFunc
) {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const { mutate: mutation } = useMutation(func, {
    onMutate: (data) => {
      if (msg?.onMutate?.default ?? true) {
        if (msg?.toast) {
          Toast.fire({
            background: '#ec4646',
            html: `<h6 style='color: #ffffff; font-weight: bold;'>${msg?.onMutate?.text}</h6>`
          });
        } else {
          Swal.fire({
            icon: 'info',
            title: msg?.onMutate?.title ?? 'Hold on....',
            text: msg?.onMutate?.text,
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading();
            }
          });
        }
      }
      if (onMutateFunc) {
        if (msg?.onMutate?.default === false) {
          Swal.close();
        }
        onMutateFunc(data);
      }
    },
    onError: (error) => {
      if (msg?.onError?.default ?? true) {
        // for unauthorized access
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }

        if (msg?.toast) {
          Toast.fire({
            background: '#ec4646',
            html: `<h6 style='color: #ffffff; font-weight: bold;'>${
              error.response ? error.response.data.message || error.toString() : error.toString()
            }</h6>`
          });
        } else {
          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();
          Swal.fire({
            icon: 'error',
            title: msg?.onError?.title ?? 'Something went wrong!',
            text: msg,
            showConfirmButton: false
            // timer: 2000,
            // timerProgressBar: true
          });
        }
      }
      if (onErrorFunc) {
        if (msg?.onError?.default === false) {
          Swal.close();
        }
        onErrorFunc(error);
      }
    },
    onSuccess: (response) => {
      if (msg?.onSuccess?.default ?? true) {
        if (msg?.toast) {
          Toast.fire({
            icon: 'success',
            iconColor: '#fff',
            background: '#16c79a',
            title: `<span style='color: #ffffff; font-weight: bold; padding-left: 10px'>${response.message}</span>`,
            timer: SWAL_TIME,
            timerProgressBar: true
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: msg?.onSuccess?.title,
            text: response?.message,
            showConfirmButton: false
            // timer: 2000,
            // timerProgressBar: true
          });
        }
      }
      if (onSuccessFunc) {
        if (msg?.onSuccess?.default === false) {
          Swal.close();
        }
        // console.log('MUTATION SUCCESS');
        onSuccessFunc(response);
      }
    },
    onSettled: () => {
      cache.invalidateQueries();
      if (onSettledFunc) {
        onSettledFunc();
      }
    }
  });
  return mutation;
}
