import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchAllSites } from '../api/queries/Queries';

const useSites = (from, to, date, type, state, status, manager, search, employee) => {
  // console.log("Site Search", search)
  const dispatch = useDispatch();
  return useInfiniteQuery(
    ['sites', from, to, date, type, state, status, manager, search, employee],
    fetchAllSites,
    {
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};

export default useSites;
