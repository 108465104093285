/* eslint-disable no-nested-ternary */
/**
 * This file contains all the assets for this project which are stored in s3
 */
// have to encrypt it or move it to .env file
export const mapsApiKey = process.env.REACT_APP_MAPS_API_KEY;
export const tinyURLApiKey = process.env.REACT_APP_TINY_URL_API_KEY;
export const DEBUG = process.env.REACT_APP_BETA_ENV === 'true';
export const SEARCH_DEBOUNCE = process.env.REACT_APP_SEARCH_DEBOUNCE;
export const baseURL = DEBUG
  ? process.env.REACT_APP_BACKEND_BETA_URL
  : process.env.REACT_APP_BACKEND_PROD_URL;
export const baseApiURL = `${baseURL}/api/v1`;
export const tinyBaseURL = process.env.REACT_APP_TINY_BACKEND_BETA_URL;
export const frontendBaseURL = process.env.REACT_APP_FRONTEND_BASE_URL;
export const SWAL_TIME = process.env.REACT_APP_SWAL_TIME;
export const TRACKKAR_LOGO = 'https://itrackassets.s3.ap-south-1.amazonaws.com/trackkar_logo.png';
export const TRACKKAR_BULK_UPLOAD_FILES =
  'https://itrackassets.s3.ap-south-1.amazonaws.com/Trackkar_bulk_upload_format_v3.zip';
export const TRACKKAR_WHITE_LOGO =
  'https://itrackassets.s3.ap-south-1.amazonaws.com/Trackkar_logo_white.png';
export const AVATAR = 'https://itrackassets.s3.ap-south-1.amazonaws.com/avatar.png';
export const ERROR_IMG = 'https://itrackassets.s3.ap-south-1.amazonaws.com/error.png';
export const ERROR_RED_IMG = 'https://itrackassets.s3.ap-south-1.amazonaws.com/error-red.png';
export const SCHEDULE = 'https://itrackassets.s3.ap-south-1.amazonaws.com/schedule.png';
export const NOTIFICATION = 'https://itrackassets.s3.ap-south-1.amazonaws.com/notifications.png';
export const HOT_LEAD = 'https://itrackassets.s3.ap-south-1.amazonaws.com/hot.png';
export const COLD_LEAD = 'https://itrackassets.s3.ap-south-1.amazonaws.com/cold.png';
export const USER_LOCATION = 'https://itrackassets.s3.ap-south-1.amazonaws.com/user-location.png';
export const LOGIN_BACKGROUND = 'https://itrackassets.s3.ap-south-1.amazonaws.com/login_bg.png';
export const ADMIN_LOGIN_BACKGROUND =
  'https://itrackassets.s3.ap-south-1.amazonaws.com/admin_login_background.png';
export const PRIVILEGE_LEAVE =
  'https://itrackassets.s3.ap-south-1.amazonaws.com/privellege_leave.png';
export const CASUAL_LEAVE = 'https://itrackassets.s3.ap-south-1.amazonaws.com/casual_leave.png';
export const SICK_LEAVE = 'https://itrackassets.s3.ap-south-1.amazonaws.com/sick_leave.png';
export const LEAVES = 'https://itrackassets.s3.ap-south-1.amazonaws.com/leaves.png';
export const MEETINGS = 'https://itrackassets.s3.ap-south-1.amazonaws.com/meetings.svg';
export const ATTENDANCE = 'https://itrackassets.s3.ap-south-1.amazonaws.com/attendance.png';
export const UNDRAW_LOCATION =
  'https://itrackassets.s3.ap-south-1.amazonaws.com/undraw_location.svg';
export const FORGOT_PASSWORD =
  'https://itrackassets.s3.ap-south-1.amazonaws.com/forgot_password.svg';
export const ADD_EMPLOYEE = 'https://itrackassets.s3.ap-south-1.amazonaws.com/add_employee.png';
export const ADD_TEAM = 'https://itrackassets.s3.ap-south-1.amazonaws.com/add_team.png';
export const CLIENT_PLACEHOLDER_IMG = 'https://itrackassets.s3.ap-south-1.amazonaws.com/client.png';
export const POLICY = 'https://itrackassets.s3.ap-south-1.amazonaws.com/policy.png';
export const PLACEHOLDER_IMG =
  'https://itrackassets.s3.ap-south-1.amazonaws.com/placeholder-image.png';
export const EDIT_HEADING = 'https://itrackassets.s3.ap-south-1.amazonaws.com/edit_heading.svg';
export const ATTACHED_FILES = 'https://itrackassets.s3.ap-south-1.amazonaws.com/attached_files.png';
export const ERROR_ROBOT = 'https://itrackassets.s3.ap-south-1.amazonaws.com/pixeltrue-error.svg';
export const QUERY_IMG = 'https://itrackassets.s3.ap-south-1.amazonaws.com/queries.png';
export const COMPANY_PLACEHOLDER_LOGO =
  'https://itrackassets.s3.ap-south-1.amazonaws.com/your_logo.png';
export const BIRDIE = 'https://itrackassets.s3.ap-south-1.amazonaws.com/birdie.png';
export const WEB_ICON = 'https://itrackassets.s3.ap-south-1.amazonaws.com/web.png';
export const ANDROID_ICON = 'https://itrackassets.s3.ap-south-1.amazonaws.com/android.png';
export const IOS_ICON = 'https://itrackassets.s3.ap-south-1.amazonaws.com/ios.png';
export const CROWN = 'https://itrackassets.s3.ap-south-1.amazonaws.com/crown.png';
export const BG_HEIRARCHY_IMG = 'https://itrackassets.s3.ap-south-1.amazonaws.com/clip-940.png';
export const months = [
  'January',
  'Febraury',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const shortMonth = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export const DayOfWeeks = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const WeekDaysToNumber = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
};

export const regex = {
  // To validate number is between 0.0 to 1.0
  point_percentage: /^(0(\.\d+)?|1(\.0+)?)$/,
  '': ''
};

export const ACCESS_CODES = {
  payroll: 35827,
  hr: 37651
};
