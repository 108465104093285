/* eslint-disable no-nested-ternary */
import { useState, useEffect, useMemo, useRef } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
// import * as Yup from 'yup';
// import PropTypes from 'prop-types';
// material
// import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Stack,
  Button,
  Divider,
  Paper,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  Chip,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import FilterListIcon from '@mui/icons-material/FilterList';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ContactsIcon from '@mui/icons-material/Contacts';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import { useDebounce } from 'use-debounce';
import UploadEmployees from '../components/_dashboard/import_employee/UploadEmployees';
// hooks
import { useUnmanagedEmployees } from '../hooks/employees';
import { useRoles } from '../hooks/roles';
import { useSubRoles } from '../hooks/subRoles';
import { useManagers } from '../hooks/get-managers';
import { useAllDepartments } from '../api/queries/department';
import AddEmployee from '../components/_dashboard/employee/AddEmployee';
import AddDepartment from '../components/_dashboard/policy/AddDepartment';
import EditHeirarchy from '../components/EditHeirarchy';
// utils
import { DD_MM_YYYY } from '../utils/functions';
import { SWAL_TIME } from '../utils/config';
import { assignRole, assignManager } from '../api/mutations/adminMutations';
// redux
import { logOut } from '../redux/actions/adminActions';

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 500,
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9'
//   }
// }));

// Step3.propTypes = {
//   heirarchy: PropTypes.array
// };

export default function Step3() {
  // let socket = null; // socket save
  const [filterName] = useState('');
  const [search] = useDebounce(filterName, 1000); // Debounce value 1 sec
  const [selected, setSelected] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [managerOptions, setManagerOptions] = useState([]);
  // const { info } = useSelector((state) => state.adminInfo);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [filter, setFilter] = useState({
    role: ''
  });
  const [assign, setAssign] = useState({
    role: '',
    manager: ''
  });
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const selectRoleRef = useRef();
  const [allAvailableRoles, setAllAvailableRoles] = useState([]);
  const [roleOptions, setRoleOptions] = useState(); // Roles initial State for select
  // const [role, setRole] = useState('');
  const [viewHeirarchy, setViewHeirarchy] = useState(true);
  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const [openAddDepartment, setOpenAddDepartment] = useState(false);
  const [subRoleOptions, setSubRoleOptions] = useState();

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  const { isLoading: isDeptLoading, isError: isDeptError, data } = useAllDepartments();

  const allDepartments = useMemo(() => {
    const DEPT_LIST = {};
    if (!isDeptLoading && !isDeptError) {
      data.departments.forEach((dep) => {
        DEPT_LIST[dep.name] = dep._id;
      });
    }
    return DEPT_LIST;
  }, [data, isDeptError, isDeptLoading]);

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  useEffect(() => {
    const allAvailableRoles = [];
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      let priority = 0;
      allRoles.forEach((role) => {
        allAvailableRoles.push([role._id, role.name]);
        if (role.is_site_manager) {
          priority = role.priority;
        }
        if (priority === 0 || role.priority === priority) {
          formattedRoles.push([role._id, role.name]);
        }
      });
    }
    setRoleOptions([...formattedRoles]);
    setAllAvailableRoles([...allAvailableRoles]); // All Available Roles in Heirarchy
    // console.log('Role Options :', formattedRoles);
  }, [allRoles]);

  /* ================= End Roles =============== */

  /* ===================== Managers ===================== */
  const {
    isLoading: isManagersLoading,
    isError: isManagersError,
    data: managers
  } = useManagers(selectedRole); // gettings managers

  const Managers = useMemo(() => {
    // console.log('Managers :', managers);
    const listOfManagers = [];
    if (!isManagersLoading && !isManagersError) {
      if (managers?.pages?.length > 0) {
        managers.pages.forEach((group) => listOfManagers.push(group.managers));
      }
    }
    const flattened = listOfManagers.flat();
    // console.log('Choose Manager:', flattened);
    return flattened;
  }, [managers, isManagersLoading, isManagersError]);

  useEffect(() => {
    const formattedManagers = [];
    if (Managers?.length > 0) {
      Managers.forEach((emp) =>
        formattedManagers.push([
          `${emp._id}, ${emp.name}`,
          emp?.avater,
          emp?.role?.name,
          emp?.subrole?.name
        ])
      );
    }
    // console.log('Managers Options', formattedManagers);
    setManagerOptions([...formattedManagers]);
  }, [Managers]);

  /* ================= End Managers =============== */

  /* ================== Unemployees ========================== */
  const {
    isLoading: isImportEmployeesLoading,
    isError: isImportEmployeesError,
    data: unemployees,
    fetchNextPage: fetchNextImportemployees,
    hasNextPage: hasNextImportemployees
  } = useUnmanagedEmployees(
    null,
    search,
    filter.state, // unemployeeActualFilter.state,
    null,
    filter.role, // unemployeeActualFilter.role
    null,
    null,
    true
  );

  const rows = useMemo(() => {
    const listOfEmployees = [];
    if (!isImportEmployeesLoading && !isImportEmployeesError) {
      if (unemployees?.pages?.length > 0) {
        unemployees.pages.forEach((group) => listOfEmployees.push(group.employees));
      }
    }
    const flattened = listOfEmployees.flat();
    // console.log('Imported Employees:', flattened);
    return flattened;
  }, [unemployees, isImportEmployeesLoading, isImportEmployeesError]);

  const handleClick = (event, name) => {
    setSelectedRole(rows.find((r) => r._id === name).role._id);
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  /* ================= End Unmanaged Employees =============== */

  /* ================== Sub Roles ======================== */

  const { isLoading: isSubRolesLoading, isError: isSubRolesError, data: subRoles } = useSubRoles();

  useEffect(() => {
    const listOfSubRoles = [];
    if (!isSubRolesLoading && !isSubRolesError) {
      if (subRoles?.roles?.length > 0) {
        subRoles.roles.forEach((role) => {
          const d = {};
          role.subroles.filter((s) => (d[s.name.toLowerCase()] = s._id));
          listOfSubRoles.push({
            id: role._id,
            subroles: d
          });
        });
      }
    }
    setSubRoleOptions(listOfSubRoles);
  }, [subRoles, isSubRolesLoading, isSubRolesError]);

  /* ================== End Sub Roles ====================== */

  /* ===================== Assign Role Mutations ==============  */

  const assignRoleMutation = useMutation(assignRole, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      setAssign({ ...assign, role: '' }); // Reset Role Select
      Swal.fire({
        icon: 'success',
        title: `Successfully assigned role`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      setSelected([]);
      setAnchorEl({ ...anchorEl, id: null, target: null });
      cache.invalidateQueries('unmanaged-employees');
    }
  });

  /* ===================== Assign Role Mutations END  ==============  */

  /* ===================== Assign Manager Mutations ==============  */

  const assignManagerMutation = useMutation(assignManager, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      setSelected([]);
      setAssign({ ...assign, manager: '' }); // Reset Manager Select
      Swal.fire({
        icon: 'success',
        title: `Successfully assigned manager`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      setSelected([]);
      setAnchorEl({ ...anchorEl, id: null, target: null });
      cache.invalidateQueries('unmanaged-employees');
    }
  });

  // useEffect(() => {
  //   socket = callSocket();
  // },[]);

  /* ===================== Assign Manager Mutations END ==============  */

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack direction="row" justifyContent="space-between" alignContent="center" sx={{ p: 2 }}>
        <Stack direction="row" spacing={2} alignContent="center">
          <Button
            variant="contained"
            size="small"
            startIcon={<EditIcon />}
            sx={{ boxShadow: 'none' }}
            onClick={() => setOpenAddDepartment(true)}
          >
            Departments
          </Button>
          {selected.length === 0 && Object.keys(allDepartments).length > 0 && !isSubRolesLoading ? (
            <>
              <UploadEmployees
                allRoles={allRoles}
                allAvailableRoles={allAvailableRoles}
                subRoleOptions={subRoleOptions}
                allDepartments={allDepartments}
              />
              <Button
                size="small"
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ boxShadow: 'none' }}
                onClick={() => setOpenAddEmployee(true)}
              >
                Employee
              </Button>
            </>
          ) : null}
        </Stack>
        &nbsp;
        {selected.length > 0 && (
          <Typography sx={{ color: '#0897FF', fontWeight: 'bold' }}>
            {selected.length}&nbsp;Selected
          </Typography>
        )}
        <Stack direction="row" alignContent="center">
          {selected.length > 0 && (
            <Tooltip title="Update Role">
              <IconButton
                onClick={(event) =>
                  setAnchorEl({
                    ...anchorEl,
                    id: 'change_role_of_employees',
                    target: event.currentTarget
                  })
                }
              >
                <AddModeratorIcon />
              </IconButton>
            </Tooltip>
          )}
          {selected.length > 0 && (
            <Tooltip title="Assign Manager">
              <IconButton
                onClick={(event) =>
                  setAnchorEl({
                    ...anchorEl,
                    id: 'assign_role_manager_to_employees',
                    target: event.currentTarget
                  })
                }
              >
                <AssignmentIndIcon />
              </IconButton>
            </Tooltip>
          )}
          &nbsp;
          {selected.length === 0 && (
            <IconButton
              onClick={(event) =>
                setAnchorEl({ ...anchorEl, id: 'filter_unemployees_step_3', target: event.target })
              }
            >
              <FilterListIcon />
            </IconButton>
          )}
          <Tooltip title="View Heirarchy">
            <IconButton onClick={() => setViewHeirarchy(!viewHeirarchy)}>
              <AccountTreeIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Popover
          id="filter_unemployees_step_3"
          open={anchorEl.id === 'filter_unemployees_step_3'}
          anchorEl={anchorEl.target}
          onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Stack spacing={1} width="320px" sx={{ p: 2 }}>
            <Stack direction="row" alignItems="center">
              <FilterListIcon />
              <Typography sx={{ ml: 1 }}>Filters</Typography>
            </Stack>
            <Divider />
            {/* Gender
            <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
              <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="gender_filter">
                  Choose Gender
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter.gender}
                  inputProps={{
                    name: 'gender',
                    id: 'gender_filter'
                  }}
                  onChange={(e) => {
                    setFilter({ ...filter, gender: e.target.value });
                  }}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Gender
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              &nbsp;
              {filter.gender ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, gender: '' });
                      setFilters(filter);
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box> */}
            {/* State
            <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
              <LocationCityIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="grouped-select">
                  Choose State
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter.state}
                  onChange={(e) => setFilter({ ...filter, state: e.target.value })}
                  id="grouped-select"
                  label="Choose State"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <ListSubheader>States</ListSubheader>
                  {INDIAN?.states?.length > 0 ? (
                    INDIAN?.states.map((key) => (
                      <MenuItem key={`${'state'}-${key}`} value={key}>
                        {key}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                  <ListSubheader>Union Territories</ListSubheader>
                  {INDIAN.union_territories?.length > 0 ? (
                    INDIAN.union_territories.map((key) => (
                      <MenuItem key={`${'territory'}-${key}`} value={key}>
                        {key}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              &nbsp;
              {filter.state ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, state: '' });
                      setFilters(filter);
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box> */}
            {/* Role */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="role_filter">
                  Choose Role
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter?.role}
                  inputProps={{
                    name: 'role_filter',
                    id: 'role_filter'
                  }}
                  onChange={(e) => setFilter({ ...filter, role: e.target.value })}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Role
                  </MenuItem>
                  {roleOptions?.length > 0 ? (
                    roleOptions.map(([key, value, image]) => (
                      <MenuItem key={`${'role_filter'}-${key}`} value={key} data-icon={image}>
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <>
                      <MenuItem value="" disabled />
                    </>
                  )}
                </Select>
              </FormControl>
              &nbsp;
              {filter.role ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, role: '' });
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box>
            {/* Manager
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="manager_filter">
                  Choose Manager
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter.manager}
                  inputProps={{
                    name: 'manager_filter',
                    id: 'manager_filter'
                  }}
                  onChange={(e) => setFilter({ ...filter, manager: e.target.value })}
                  disabled={!filter?.role}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Manager
                  </MenuItem>
                  {managerOptions?.length > 0 ? (
                    managerOptions.map(([key, value, image]) => (
                      <MenuItem
                        style={{ padding: '0.5rem' }}
                        key={`${'manager_filter'}-${key}`}
                        value={key}
                        data-icon={image}
                      >
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled />
                  )}
                </Select>
              </FormControl>
              &nbsp;
              {filter.manager ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, manager: '' });
                      setFilters(filter);
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box> */}
            {/* Policy
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <StickyNote2Icon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="policy_filter">
                  Choose Policy
                </InputLabel>
                <Select
                  variant="standard"
                  value={filter.policy}
                  inputProps={{
                    name: 'policy_filter',
                    id: 'policy_filter'
                  }}
                  onChange={(e) => setFilter({ ...filter, policy: e.target.value })}
                  disabled={!filter?.role}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Choose Policy
                  </MenuItem>
                  {policyOptions?.length > 0 ? (
                    policyOptions.map(([key, value, image]) => (
                      <MenuItem
                        style={{ padding: '0.5rem' }}
                        key={`${'policy_type'}-${key}`}
                        value={key}
                        data-icon={image}
                      >
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled />
                  )}
                </Select>
              </FormControl>
              &nbsp;
              {filter.policy ? (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setFilter({ ...filter, policy: '' });
                      setFilters(filter);
                      cache.invalidateQueries('employees');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box> */}
          </Stack>
        </Popover>
      </Stack>
      <Stack style={{ border: '2px solid #0897FF', borderRadius: 8 }} sx={{ p: 1, mt: 1 }}>
        <Typography
          sx={{
            mt: -2.5,
            px: 2,
            backgroundColor: 'white',
            width: 'calc(100vh - 25rem)',
            color: '#0897FF'
          }}
        >
          <b>Hierarchy Structure</b>
        </Typography>
        <TableContainer
          className="custom_scrollbar"
          id="step_3_import_employees"
          sx={{ height: 'calc(100vh - 300px)' }}
          component={Paper}
        >
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="center">Employee</TableCell>
                <TableCell align="center">Gender</TableCell>
                <TableCell align="center">DOJ</TableCell>
                <TableCell align="center">Role</TableCell>
                <TableCell align="center">Manager</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isImportEmployeesLoading ? (
                <TableRow>
                  <TableCell align="center" colSpan="7">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : isImportEmployeesError ? (
                <TableRow>
                  <TableCell align="center" colSpan="7">
                    Something Went Wrong
                  </TableCell>
                </TableRow>
              ) : rows?.length > 0 ? (
                rows
                  .sort((a, b) => a.role.priority - b.role.priority)
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const isItemSelected = selected.indexOf(row?._id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={row._id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            disabled={
                              selected.length > 0 &&
                              (selectedRole ? !(selectedRole === row.role._id) : false)
                            }
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, row?._id)}
                          />
                        </TableCell>
                        {/* <TableCell>{(index += 1)}</TableCell> */}
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar alt={row.name} src={row.avatar} />
                            <Typography variant="subtitle2" noWrap>
                              {row.name}
                              <br />
                              {row?.subrole ? (
                                <Chip
                                  component="span"
                                  size="small"
                                  sx={{ fontSize: 10.5 }}
                                  label={row.subrole.name}
                                />
                              ) : null}
                            </Typography>
                            <InfoIcon
                              sx={{ cursor: 'pointer' }}
                              onClick={(event) =>
                                setAnchorEl({
                                  id: `info_${row._id}`,
                                  target: event.currentTarget
                                })
                              }
                            />
                            <Popover
                              id={`info_${row._id}`}
                              open={anchorEl.id === `info_${row._id}`}
                              anchorEl={anchorEl.target}
                              onClose={() => setAnchorEl({ id: null, target: null })}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <List>
                                {row?.contact && (
                                  <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <PhoneAndroidIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`+91 ${row.contact}`} />
                                    </ListItemButton>
                                  </ListItem>
                                )}
                                {row?.email && (
                                  <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <AlternateEmailIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`${row.email}`} />
                                    </ListItemButton>
                                  </ListItem>
                                )}
                                {row?.state && (
                                  <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <LocationCityIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`${row?.state ?? 'Not Provided'}`} />
                                    </ListItemButton>
                                  </ListItem>
                                )}
                                {row?.address && (
                                  <ListItem disablePadding>
                                    <ListItemButton component="a" href="#simple-list">
                                      <ListItemIcon>
                                        <ContactsIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`${row.address}`} />
                                    </ListItemButton>
                                  </ListItem>
                                )}
                              </List>
                            </Popover>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{row.gender}</TableCell>
                        <TableCell align="left">{DD_MM_YYYY(row.doj)}</TableCell>
                        <TableCell>
                          <Stack sx={{ minWidth: '8rem' }}>
                            <FormControl fullWidth>
                              <InputLabel variant="standard" htmlFor="assign_role">
                                Choose Role
                              </InputLabel>
                              <Select
                                ref={selectRoleRef}
                                value={row?.role?._id ?? ''}
                                variant="standard"
                                inputProps={{
                                  name: 'assign_role',
                                  id: 'assign_role'
                                }}
                                disabled={selected.length > 0}
                                sx={{ mt: 2 }}
                                onChange={(event) => {
                                  Swal.fire({
                                    title: 'Are you sure?',
                                    text: "You won't be able to revert this!",
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, Proceed!',
                                    allowOutsideClick: false
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      assignRoleMutation.mutate({
                                        employees: [row._id],
                                        role: event.target.value
                                      });
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                      // selectRoleRef.current.selectedIndex = 0;
                                      cache.invalidateQueries('unmanaged-employees');
                                    }
                                  });
                                }}
                              >
                                <MenuItem value="" disabled>
                                  Choose Role
                                </MenuItem>
                                {roleOptions?.length > 0 ? (
                                  roleOptions.map(([key, value]) => (
                                    <MenuItem
                                      style={{ padding: '0.5rem' }}
                                      key={`${'assign_role'}-${key}`}
                                      value={key}
                                    >
                                      {value}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem value="" disabled />
                                )}
                              </Select>
                            </FormControl>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Stack
                            sx={{
                              minWidth: '8rem',
                              '& #mui-component-select-assign_manager': {
                                display: 'flex',
                                alignItems: 'center'
                              }
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel variant="standard" htmlFor="assign_manager">
                                Choose Manager
                              </InputLabel>
                              <Select
                                variant="standard"
                                value={
                                  row.managers.length > 0
                                    ? `${row.managers[0]._id},${row.managers[0].name}`
                                    : ''
                                }
                                inputProps={{
                                  name: 'assign_manager',
                                  id: `assign_manager_${row._id}`
                                }}
                                sx={{ mt: 2 }}
                                disabled={selected.length > 0}
                                onChange={(event) => {
                                  Swal.fire({
                                    title: 'Are you sure?',
                                    text: "You won't be able to revert this!",
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, Proceed!',
                                    allowOutsideClick: false
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      assignManagerMutation.mutate({
                                        employees: [row._id],
                                        manager: event.target.value.split(',')[0] // manager id
                                      });
                                    } else if (
                                      /* Read more about handling dismissals below */
                                      result.dismiss === Swal.DismissReason.cancel
                                    ) {
                                      const select = document.querySelector(
                                        `#assign_manager_${row._id}`
                                      );
                                      select.selectedIndex = 0;
                                      cache.invalidateQueries('unmanaged-employees');
                                    }
                                  });
                                }}
                              >
                                <MenuItem value="" disabled>
                                  Choose Manager
                                </MenuItem>
                                {row.options?.length > 0 ? (
                                  row.options.map(([key, image, empR, empSR]) => (
                                    <MenuItem
                                      style={{ padding: '0.5rem' }}
                                      key={`${'assign_manager'}-${key}`}
                                      value={key}
                                    >
                                      <Avatar src={image} />
                                      <Stack sx={{ ml: 1 }}>
                                        <Typography>&nbsp;{key.split(',')[1]}</Typography>
                                        {empR && (
                                          <Box display="flex">
                                            <Chip
                                              fontSize={12}
                                              sx={{ height: 18, mb: 0.5 }}
                                              size="small"
                                              label={empR}
                                            />
                                            &nbsp;
                                            {empSR && (
                                              <Chip
                                                fontSize={12}
                                                sx={{ height: 18 }}
                                                size="small"
                                                label={empSR}
                                              />
                                            )}
                                          </Box>
                                        )}
                                      </Stack>
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem value="" disabled />
                                )}
                              </Select>
                            </FormControl>
                          </Stack>
                        </TableCell>
                        {/* <TableCell>{`${row.day_off}`}</TableCell> */}
                        {/* <TableCell align="right">
                        {selected.length === 0 && <EmployeeMoreMenu />}
                      </TableCell> */}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan="6">
                    {Object.keys(allDepartments).length > 0 && (
                      <Box>
                        <UploadEmployees
                          allRoles={allRoles}
                          allAvailableRoles={allAvailableRoles}
                          subRoleOptions={subRoleOptions}
                          allDepartments={allDepartments}
                        />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/* Put the scroll bar always on the bottom */}
          <InfiniteScroll
            dataLength={rows?.length}
            next={fetchNextImportemployees}
            // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
            // inverse //
            hasMore={hasNextImportemployees}
            loader={
              <Stack direction="row" sx={{ my: 1.5 }} justifyContent="center" alignItems="center">
                <CircularProgress />
              </Stack>
            }
            scrollableTarget="step_3_import_employees"
            // endMessage={
            //   <p style={{ textAlign: 'center' }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
          />
        </TableContainer>
      </Stack>
      <AddEmployee step={3} open={openAddEmployee} onClose={() => setOpenAddEmployee(false)} />
      <AddDepartment open={openAddDepartment} onClose={() => setOpenAddDepartment(false)} />
      <Popover
        id="change_role_of_employees"
        open={anchorEl.id === 'change_role_of_employees'}
        anchorEl={anchorEl.target}
        onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack spacing={1} width="320px" sx={{ p: 2 }}>
          <Stack direction="row" alignItems="center">
            <AddModeratorIcon />
            <Typography sx={{ ml: 1 }}>Update Role</Typography>
          </Stack>
          <Divider />
          {/* Role */}
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="role_filter">
                Choose Role
              </InputLabel>
              <Select
                variant="standard"
                value={assign?.role ?? ''}
                inputProps={{
                  name: 'role_filter',
                  id: 'role_filter'
                }}
                onChange={(e) => setAssign({ ...assign, role: e.target.value })}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Role
                </MenuItem>
                {roleOptions?.length > 0 ? (
                  roleOptions.map(([key, value, image]) => (
                    <MenuItem key={`${'role_filter'}-${key}`} value={key} data-icon={image}>
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <>
                    <MenuItem value="" disabled />
                  </>
                )}
              </Select>
            </FormControl>
          </Box>
          <br />
          <Box>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              id="employee_apply_filter_button"
              size="medium"
              onClick={() => {
                setAnchorEl({ id: null, target: null });
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'This will apply changes to all selected Employee!',
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, Proceed!',
                  allowOutsideClick: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    assignRoleMutation.mutate({
                      employees: selected,
                      role: assign?.role
                    });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    cache.invalidateQueries('employees');
                  }
                });
              }}
            >
              Update
            </Button>
          </Box>
        </Stack>
      </Popover>
      <Popover
        id="assign_role_manager_to_employees"
        open={anchorEl.id === 'assign_role_manager_to_employees'}
        anchorEl={anchorEl.target}
        onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack spacing={1} width="320px" sx={{ p: 2 }}>
          <Stack direction="row" alignItems="center">
            <AddModeratorIcon />
            <Typography sx={{ ml: 1 }}>Assign Manager</Typography>
          </Stack>
          <Divider />
          {/* Manager */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              '& #mui-component-select-assign_manager': { display: 'flex', alignItems: 'center' }
            }}
          >
            <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="assign_manager">
                Choose Manager
              </InputLabel>
              <Select
                variant="standard"
                value={assign?.manager ?? ''}
                inputProps={{
                  name: 'assign_manager',
                  id: 'assign_manager'
                }}
                onChange={(e) => setAssign({ ...assign, manager: e.target.value })}
                // disabled={!assign?.role}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Manager
                </MenuItem>
                {managerOptions?.length > 0 ? (
                  managerOptions.map(([key, image, empR, empSR]) => (
                    <MenuItem
                      style={{ padding: '0.5rem' }}
                      key={`${'manager_filter'}-${key}`}
                      value={key}
                      data-icon={image}
                    >
                      <Avatar src={image} />
                      <Stack sx={{ ml: 1 }}>
                        <Typography>&nbsp;{key.split(',')[1]}</Typography>
                        {empR && (
                          <Box display="flex">
                            <Chip
                              fontSize={12}
                              sx={{ height: 18, mb: 0.5 }}
                              size="small"
                              label={empR}
                            />
                            &nbsp;
                            {empSR && (
                              <Chip fontSize={12} sx={{ height: 18 }} size="small" label={empSR} />
                            )}
                          </Box>
                        )}
                      </Stack>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled />
                )}
              </Select>
            </FormControl>
          </Box>
          <br />
          <Box>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              id="employee_apply_filter_button"
              size="medium"
              onClick={() => {
                setAnchorEl({ id: null, target: null }); // reset popup
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'This will apply changes to all selected Employee!',
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, Proceed!',
                  allowOutsideClick: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    assignManagerMutation.mutate({
                      employees: selected,
                      manager: assign?.manager.split(',')[0]
                    });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    cache.invalidateQueries('employees');
                  }
                });
              }}
            >
              Assign
            </Button>
          </Box>
        </Stack>
      </Popover>
      {/* Edit Heirarchy Modal */}
      <EditHeirarchy
        heirarchy={roles?.heirarchy}
        open={viewHeirarchy}
        close={() => setViewHeirarchy(true)}
      />
    </Box>
  );
}
