import { useMemo } from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import NotAvailable from 'src/components/NotAvailable';
import { useJoinExit } from '../../../hooks/get-join-exit';

function ItemCard({ row, joining, anniversary, birthday }) {
  return (
    <Card
      sx={{
        p: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
      }}
    >
      <Stack>
        <Typography>{row.name}</Typography>
        <Typography sx={{ fontSize: 12 }}>{row.contact}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        {joining && <Chip color="primary" size="small" label="New" />}
        {anniversary && <Chip color="rose" size="small" label="Anniversary" />}
        {birthday && <Chip color="secondary" size="small" label="Birthday" />}
      </Stack>
    </Card>
  );
}

export default function EmployeeHappenings() {
  const { isLoading, isError, data } = useJoinExit();
  const { event, attrition_rate } = useMemo(() => {
    let result = {};
    if (!isLoading && !isError) {
      result = data;
    }
    return result;
  }, [data, isLoading, isError]);
  return (
    <Card sx={{ height: 440 }}>
      <Stack
        direction="row"
        sx={{ px: 2, py: 1 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">Employee Events</Typography>
        <Typography sx={{ fontSize: 16 }}>Attrition Rate&emsp;{attrition_rate} %</Typography>
      </Stack>
      <Divider />
      <Box sx={{ p: 2 }}>
        {isLoading ? (
          <NotAvailable />
        ) : isError ? (
          <NotAvailable />
        ) : (
          <Stack spacing={2}>
            {event.length > 0 ? (
              event.map((row) => (
                <ItemCard
                  row={row}
                  joining={row?.event?.joining}
                  anniversary={row?.event?.anniversary}
                  birthday={row?.event?.birthday}
                />
              ))
            ) : (
              <NotAvailable type="na" msg="No Events" />
            )}
          </Stack>
        )}
      </Box>
    </Card>
  );
}
