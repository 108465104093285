import {
  ADMIN_INFO_REQUESTED,
  ADMIN_INFO_FAILED,
  ADMIN_INFO_SUCCEDED,
  ADMIN_LOGGED_OUT
} from '../constants/adminConstants';
import { getDataFromStorage } from '../../utils/functions';

const token = getDataFromStorage('adminAuthToken');
const info = getDataFromStorage('adminInfo');

const initialState = {
  isAdminAuthenticated: !!token,
  info: info || null
};

export const adminInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_INFO_REQUESTED:
      return { loading: true, ...state };
    case ADMIN_INFO_SUCCEDED:
      return {
        ...state,
        isAdminAuthenticated: true,
        loading: false,
        info: action.payload
      };
    case ADMIN_INFO_FAILED:
      return {
        ...state,
        isAdminAuthenticated: false,
        loading: false,
        error: action.payload
      };
    case ADMIN_LOGGED_OUT:
      return {
        ...state,
        isAdminAuthenticated: false,
        info: null,
        loading: false
      };
    default:
      return state;
  }
};
