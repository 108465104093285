/* eslint-disable func-names */
import '../../../index.css';
import { memo, useMemo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider, Form } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// icons
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
// config
import {
  ADD_EMPLOYEE
  // COMPANY_PLACEHOLDER_LOGO
} from '../../../utils/config';
// hooks
import { fetchSiteManagers } from '../../../api/queries/Queries';
import { addPayrollTemplate, updatePayrollTemplate } from '../../../api/mutations/adminMutations';
// utils
import { getBase64, capitalizeEachWord } from '../../../utils/functions';
// redux
import { logOut } from '../../../redux/actions/adminActions';
// import INDIAN from '../../../utils/INDIAN_STATES.json'; // importing indian states and uts
import { countries } from '../../../utils/countries';
import CreateComponent from './CreateComponent';
import { useRoles } from '../../../hooks/roles';
import { useSubRoles } from '../../../hooks/subRoles';
import usePayrollComponents from '../../../hooks/get-payroll-components';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const DEBUG = false;

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 'calc(100vw - 20rem)',
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

function NewTemplate({ open, handleClose, edit }) {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const [createComponent, setCreateComponent] = useState(false);
  const { info } = useSelector((state) => state.adminInfo);
  const [componentOptions, setComponentOptions] = useState([]);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [roleOptions, setRoleOptions] = useState();
  const [subRoleOptions, setSubRoleOptions] = useState([]);
  // const [personName, setPersonName] = useState([]);
  // const [showPassword, setShowPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [roleOptions, setRoleOptions] = useState(); // Roles initial State for select
  // const [submitClicked, setSubmitClicked] = useState(false);
  // const [, setpictureTouched] = useState(false); // pictureTouched
  // For Image Size : 2MB , Supported Formats
  // const FILE_SIZE = 2 * 1024 * 1024;
  // const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

  useEffect(() => {
    if (edit !== null) {
      setSelectedComponents(edit?.components);
    }
  }, [edit]);
  /* ===================== Roles ===================== */
  // const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  // const allRoles = useMemo(() => {
  //   if (!isRolesLoading && !isRolesError) {
  //     return roles.heirarchy;
  //   }
  //   return ''; // Needs to be changed
  // }, [roles, isRolesLoading, isRolesError]);

  // useEffect(() => {
  //   const formattedRoles = [];
  //   if (allRoles?.length > 0) {
  //     allRoles.forEach((role) => formattedRoles.push([role._id, role.name]));
  //     formattedRoles.pop();
  //     setRoleOptions([...formattedRoles]);
  //     // console.log('Role Options :', formattedRoles);
  //   }
  // }, [allRoles]);
  // /* ================= End Roles =============== */

  const { mutate } = useMutation(edit ? updatePayrollTemplate : addPayrollTemplate);

  // Manager Id if loggedin user is a site manager
  const managerId =
    info?.role?.priority === info?.site_manager_priority && !info?.isMainAdmin ? info?._id : '';
  // console.log('Manager ID:', managerId);

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: edit?.name ?? '',
      enable_lop: edit?.enable_lop ?? false,
      as_default: edit?.default ?? false,
      basic_salary_percentage: edit?.basic_salary_percentage ?? '',
      hra_percentage: edit?.hra_percentage ?? '',
      role_type: edit?.role?._id ?? '',
      sub_role_type: edit?.subrole ?? '',
      components: edit?.components ?? []
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Template Name is required')
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9\s\-&.']*[a-zA-Z0-9]+$/,
          "Invalid client name. ( Minimum 2 characters, must start & end with alphanumeric characters, allowed special characters  - & . ' )"
        ),
      basic_salary_percentage: Yup.string()
        .required('This field is required')
        .matches(/^(0|[1-9][0-9]?|100)$/, 'Enter value between 0 - 100'),
      hra_percentage: Yup.string()
        .required('This field is required')
        .matches(/^(0|[1-9][0-9]?|100)$/, 'Enter value between 0 - 100')
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        name: capitalizeEachWord(values.name),
        default: values.as_default,
        role: values.role_type,
        enable_lop: values.enable_lop,
        basic_salary_percentage: values.basic_salary_percentage,
        hra_percentage: values.hra_percentage,
        components: selectedComponents.map((cmp) => cmp._id)
      };

      if (values.sub_role_type.length > 0) {
        data.subrole = values.sub_role_type;
      }

      if (edit) {
        data.id = edit?._id;
      }

      handleClose();
      if (DEBUG) {
        console.log('Payroll Template Form :', data);
      } else {
        mutate(data, {
          onMutate: () => {
            Swal.fire({
              icon: 'info',
              title: 'Hold on....',
              text: `${edit ? 'Updating' : 'Adding new'} payroll template 😃`,
              allowOutsideClick: false,
              showConfirmButton: false,
              willOpen: () => {
                Swal.showLoading();
              }
            });
          },
          onError: (error) => {
            // for unauthorized access
            if (error.response && error.response.status === 401) {
              dispatch(logOut());
            }

            const msg = error.response
              ? error.response.data.message || error.toString()
              : error.toString();

            Swal.fire({
              icon: 'error',
              title: 'Something went wrong!',
              text: msg,
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            });

            actions.setStatus({
              errorResponse: msg
            });
            actions.setSubmitting(false);
          },
          onSuccess: (data) => {
            setSelectedComponents([]);
            resetForm();
            Swal.fire({
              icon: 'success',
              title: `Payroll Template ${edit ? 'updated' : 'created'}!`,
              text: data.message,
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            });
          },
          onSettled: () => {
            cache.invalidateQueries('payroll-templates');
            cache.invalidateQueries('payroll');
          }
        });
      }
    }
  });

  const {
    errors,
    touched,
    values,
    // isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm
  } = formik;

  // useEffect(() => {
  //   console.log(values);
  //   console.log(errors);
  // }, [values, errors]);

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Components
  const {
    isLoading,
    isError,
    data,
    fetchNextPage: fetchNextEmployees,
    hasNextPage: hasNextEmployees,
    refetch: refetchEmployees
  } = usePayrollComponents(values?.component_type);

  const rows = useMemo(() => {
    if (!isLoading && !isError) {
      const listOfComponents = [];
      if (data?.pages?.length > 0) {
        data.pages.forEach((group) => listOfComponents.push(group.components));
      }
      const flattened = listOfComponents.flat();
      return flattened;
    }
    return [];
  }, [data, isLoading, isError]);

  useEffect(() => {
    const formattedComponents = [];
    if (rows?.length > 0) {
      rows.forEach((row) => {
        formattedComponents.push([`${row._id},${row.name}`, row.name]);
      });
      setComponentOptions([...formattedComponents]);
      // console.log('Component Options :', formattedComponents);
    }
  }, [rows]);

  /* ================= End Managers =============== */

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      // console.log(allRoles);
      let priority = 0;
      allRoles.forEach((role) => {
        if (role.is_site_manager) {
          priority = role.priority;
        }
        if (role.priority > priority && priority > 0) {
          formattedRoles.push([role._id, role.name]);
        } else if (priority === 0 || role.priority === priority) {
          formattedRoles.push([role._id, role.name]);
        }
      });
      setRoleOptions([...formattedRoles]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allRoles]);
  /* ================= End Roles =============== */

  /* ================== Sub Roles ======================== */

  const {
    isLoading: isSubRolesLoading,
    isError: isSubRolesError,
    data: subRoles
  } = useSubRoles(values.role_type);

  useMemo(() => {
    const listOfSubRoles = [];
    if (!isSubRolesLoading && !isSubRolesError) {
      if (subRoles?.roles?.length > 0) {
        subRoles.roles[0].subroles.forEach((role) => listOfSubRoles.push([role._id, role.name]));
      }
    }
    // console.log('Sub Roles By Role:', listOfSubRoles);
    setSubRoleOptions([...listOfSubRoles]);
  }, [subRoles, isSubRolesLoading, isSubRolesError]);

  /* ================== End Sub Roles ====================== */

  return (
    <Modal
      open={open ?? false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 1,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          <img src={ADD_EMPLOYEE} width="40" height="40" alt="edit_icon" />
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            {edit ? 'Edit' : 'New'} Template
          </Typography>
          <IconButton
            onClick={() => handleClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <FormikProvider value={formik}>
          <Form id="add-employee-form" autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack
              className="custom_scrollbar"
              spacing={1}
              height="calc(100vh - 135px)"
              sx={{ px: 2, py: 1, overflowY: 'scroll' }}
            >
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  {/* Name */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    {/* <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Template Name"
                      autoComplete="off"
                      required
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} />
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="role_type">
                      Choose Role
                    </InputLabel>
                    <Select
                      variant="standard"
                      inputProps={{
                        name: 'role_type',
                        id: 'role_type'
                      }}
                      required
                      sx={{ mt: 2 }}
                      {...getFieldProps('role_type')}
                      error={Boolean(touched.role_type && errors.role_type)}
                      // helperText={touched.policy_role && errors.policy_role}
                    >
                      <MenuItem value="" disabled>
                        Choose Role
                      </MenuItem>
                      {roleOptions?.length > 0 ? (
                        roleOptions.map(([key, value, image]) => (
                          <MenuItem key={`${'role_type'}-${key}`} value={key} data-icon={image}>
                            {value}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" disabled />
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="role_type">
                        Choose Sub Role
                      </InputLabel>
                      <Select
                        variant="standard"
                        disabled={!(values.role_type && subRoleOptions.length > 0)}
                        value={!subRoleOptions && ''}
                        inputProps={{
                          name: 'sub_role_type',
                          id: 'sub_role_type'
                        }}
                        required
                        sx={{ mt: 2 }}
                        {...getFieldProps('sub_role_type')}
                        error={Boolean(touched.sub_role_type && errors.sub_role_type)}
                        // helperText={touched.policy_role && errors.policy_role}
                      >
                        <MenuItem value="" disabled>
                          Choose Sub Role
                        </MenuItem>
                        {subRoleOptions?.length > 0 ? (
                          subRoleOptions.map(([key, value, image]) => (
                            <MenuItem
                              key={`${'sub_role_type'}-${key}`}
                              value={key}
                              data-icon={image}
                            >
                              {value}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" disabled />
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid xs={12} />
                <Grid xs={12} />
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center">
                    <TextField
                      variant="standard"
                      sx={{ minWidth: 180 }}
                      type="number"
                      autoComplete="off"
                      {...getFieldProps('basic_salary_percentage')}
                      error={Boolean(
                        touched.basic_salary_percentage && errors.basic_salary_percentage
                      )}
                      helperText={
                        (touched.basic_salary_percentage && errors.basic_salary_percentage) ??
                        'Enter value between 0 - 100'
                      }
                    />
                    <Typography>&nbsp;% of CTC is basic gross salary</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center">
                    <TextField
                      variant="standard"
                      sx={{ minWidth: 180 }}
                      type="number"
                      autoComplete="off"
                      {...getFieldProps('hra_percentage')}
                      error={Boolean(touched.hra_percentage && errors.hra_percentage)}
                      helperText={
                        (touched.hra_percentage && errors.hra_percentage) ??
                        'Enter value between 0 - 100'
                      }
                    />
                    <Typography sx={{ mx: 2, minWidth: 200 }}>
                      &nbsp;% of basic gross salary is HRA
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Typography sx={{ mt: 1 }}>Enable LOP</Typography>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Switch {...label} {...getFieldProps('enable_lop')} />
                </Grid>
                <Grid item md={3} xs={6}>
                  <Typography sx={{ mt: 1 }}>Set As Default</Typography>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Switch {...label} {...getFieldProps('as_default')} />
                </Grid>
                <Grid xs={12} />
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: 'bold' }}>Add Component</Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="component_type">
                        Component Type
                      </InputLabel>
                      <Select
                        variant="standard"
                        inputProps={{
                          name: 'component_type',
                          id: 'component_type'
                        }}
                        required
                        sx={{ mt: 2 }}
                        {...getFieldProps('component_type')}
                        error={Boolean(touched.component_type && errors.component_type)}
                        // helperText={touched.policy_role && errors.policy_role}
                      >
                        <MenuItem value="" disabled>
                          Component Type
                        </MenuItem>
                        <MenuItem value="allowance">Allowance</MenuItem>
                        <MenuItem value="deduction">Deduction</MenuItem>
                        <MenuItem value="bonus">Bonus</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="component">
                        Choose Component
                      </InputLabel>
                      <Select
                        variant="standard"
                        inputProps={{
                          name: 'component',
                          id: 'component'
                        }}
                        required
                        sx={{ mt: 2 }}
                        {...getFieldProps('component')}
                        error={Boolean(touched.component && errors.component)}
                        // helperText={touched.policy_role && errors.policy_role}
                      >
                        <MenuItem value="" disabled>
                          Choose Component
                        </MenuItem>
                        {componentOptions?.length > 0 ? (
                          componentOptions
                            .filter(
                              (cp) =>
                                !selectedComponents
                                  .map((sp) => sp._id)
                                  .includes(cp[0].split(',')[0])
                            )
                            .map(([key, value]) => (
                              <MenuItem key={`${'component_option'}-${key}`} value={key}>
                                {value}
                              </MenuItem>
                            ))
                        ) : (
                          <MenuItem value="" disabled />
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Button
                    disabled={values?.component?.length === 0}
                    onClick={() => {
                      const Id = values.component.split(',')[0];
                      const comp = rows.find((r) => r._id === Id);
                      selectedComponents.push(comp);
                      setSelectedComponents([...selectedComponents]);
                      setFieldValue('component', '');
                    }}
                    sx={{ mt: 1.5 }}
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: 'bold' }}>Components</Typography>
                </Grid>
                <Grid item xs={12}>
                  {selectedComponents.map((cmp) => (
                    <Grid
                      sx={{ border: '1px solid #333', borderRadius: 1, p: 1, mb: 1.2 }}
                      container
                      key={`selected-component-${cmp._id}`}
                    >
                      <Grid item md={4}>
                        <b>Name:&nbsp;</b>
                        {cmp.name}
                      </Grid>
                      <Grid item md={4}>
                        <b>Type:&nbsp;</b>
                        {cmp.type}
                      </Grid>
                      <Grid item md={4}>
                        <b>Taxable:&nbsp;</b>
                        {cmp.taxable ? 'Yes' : 'No'}
                      </Grid>
                      <Grid item md={12}>
                        <b>Payment type:&nbsp;</b>
                        {cmp.ctype}
                      </Grid>
                      <Grid item md={12}>
                        <b>Amount:&nbsp;</b>
                        {cmp.flat}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Stack>
            <Stack
              sx={{ position: 'absolute', backgroundColor: 'white', bottom: 0, px: 2, pb: 1.2 }}
            >
              <Button type="submit" variant="contained">
                {edit ? 'Update' : 'Create'} Template
              </Button>
            </Stack>
          </Form>
        </FormikProvider>
        <CreateComponent open={createComponent} handleClose={() => setCreateComponent(false)} />
      </Box>
    </Modal>
  );
}

NewTemplate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default memo(NewTemplate);
