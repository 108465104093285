/* eslint-disable no-nested-ternary */
import '../../../index.css';
import { memo, useMemo, useEffect, useState, useCallback } from 'react';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
// import { useDispatch } from 'react-redux';
import { useFormik, FormikProvider, Form } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Modal, Typography, Box, Stack, Container, Button, IconButton, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
// icons
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
// config
import { syncDepartments } from '../../../api/mutations/department';
import { useAllDepartments } from '../../../api/queries/department';
import { POLICY } from '../../../utils/config';
// mutations
// import { addPolicy, editPolicy } from '../../../api/mutations/policy';
// utils
import { capitalizeEachWord } from '../../../utils/functions';
// redux
// import { logOut } from '../../../redux/actions/adminActions';
import classes from '../../../pages/styles/Hierarchy.module.css';

// For Debugging this file
const DEBUG = false;

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 'calc(100vw - 20rem)',
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

function AddDepartment({ open, onClose, edit }) {
  const cache = useQueryClient();
  const [itens, setItens] = useState([]); // to store list of sub roles

  const { mutate } = useMutation(syncDepartments, {
    onMutate: () => {
      onClose();
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: `${edit ? 'Updating' : 'Adding New'} Departments 😃`,
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    }
  });

  const handleDepartments = useCallback(() => {
    // handle step-1
    if (itens.length > 0) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Dont Worry!, You can change those in your Profile!`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, Add Departments!'
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            departments: itens.map((item) => ({
              id: item.id,
              name: item.content
            }))
          };
          if (DEBUG) {
            console.log('Adding Departments:', data);
          } else {
            mutate(data, {
              onError: (error) => {
                const msg = error?.response?.data?.message || error.toString();

                Swal.fire({
                  icon: 'error',
                  title: 'Something went wrong !',
                  text: msg
                });
              },
              onSuccess: (data) => {
                cache.invalidateQueries('privileges');
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: data.message,
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true
                  // history.replace("/privileges"),
                });
              },
              onSettled: () => cache.invalidateQueries('privileges')
            });
          }
        }
      });
    } else {
      Swal.fire({
        title: 'Please add some departments before submit!',
        icon: 'warning'
      });
    }
  }, [itens, mutate, cache]);

  function hasDuplicates(arr, val) {
    if (arr.includes(val?.toLowerCase())) {
      return true;
    }
    const counts = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= arr.length; i++) {
      if (counts[arr[i]] === undefined) {
        counts[arr[i]] = 1;
      } else {
        return true;
      }
    }
    return false;
  }

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    initialValues: {
      departmentName: ''
    },
    validationSchema: Yup.object({
      departmentName: Yup.string()
        .notRequired()
        .matches(
          /^[a-zA-Z][a-zA-Z0-9\s-]+$/,
          '- Must start with an alphabet - Minimum 2 characters'
        )
        .test(
          'is-duplicate',
          'Duplicate names not allowed, some of the names are already used for previously added roles',
          (value) => {
            const _sub = itens
              .map((t) => t.content.toLowerCase())
              .flat()
              .filter((ft) => ft !== undefined);
            if (hasDuplicates(_sub)) {
              return false;
            }
            if (hasDuplicates(_sub, value)) {
              return false;
            }
            return true;
          }
        )
    }),
    onSubmit: (values, actions) => {
      if (values.departmentName) {
        itens.push({
          id: `${Date.now()}`,
          content: `${capitalizeEachWord(values.departmentName?.trim())}`
        });
        setItens([...itens]);
        actions.resetForm();
      }
    }
  });

  const { getFieldProps } = formik;

  // function dragEnd(result, itens, setItens) {
  //   if (!result.destination) return;

  //   const { source, destination } = result;
  //   const copiedItems = itens;
  //   const [removed] = copiedItems.splice(source.index, 1);
  //   copiedItems.splice(destination.index, 0, removed);

  //   setItens(copiedItems);
  // }

  function handleDelete(index) {
    itens.splice(index, 1);
    setItens([...itens]);
  }

  const { isLoading, isError, data } = useAllDepartments();

  useEffect(() => {
    const DEPT_LIST = [];
    if (!isLoading && !isError) {
      data.departments.forEach((dep) => {
        DEPT_LIST.push({
          id: dep._id,
          content: `${capitalizeEachWord(dep.name)}`
        });
      });
    }
    setItens(DEPT_LIST);
  }, [data, isError, isLoading]);

  return (
    <Modal
      open={open ?? false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} container>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 2,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          <img src={POLICY} width="40" height="40" alt="edit_icon" />
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            Edit Department
          </Typography>
          <IconButton
            onClick={() => onClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate>
            <Container>
              <DragDropContext
              // onDragEnd={(result) => {
              //   dragEnd(result, itens, setItens);
              // }}
              >
                <Stack>
                  <TextField
                    id="departmentName"
                    name="departmentName"
                    sx={{ mt: 2 }}
                    fullWidth
                    type="text"
                    label="Name"
                    autoComplete="off"
                    autoFocus
                    {...getFieldProps('departmentName')}
                    onKeyUp={(event) => {
                      // Number 13 is the "Enter" key on the keyboard
                      if (event.keyCode === 13) {
                        // Cancel the default action, if needed
                        event.preventDefault();
                        formik.handleSubmit();
                      }
                    }}
                    error={Boolean(formik.touched.departmentName && formik.errors.departmentName)}
                    // helperText={touched.email && errors.email}
                  />
                  <Typography sx={{ fontSize: 11, color: 'red' }}>
                    {formik.errors.departmentName}
                  </Typography>
                  <Typography sx={{ mt: 0.5 }} style={{ fontSize: '0.8rem' }}>
                    e.g. Press 'Enter' to add a Department
                  </Typography>
                  <Box
                    style={{ border: '2px solid #0897FF', borderRadius: 8 }}
                    sx={{ p: 1, mt: 1 }}
                  >
                    <Typography
                      sx={{
                        mt: -2,
                        px: 2,
                        backgroundColor: 'white',
                        maxWidth: '99.5%',
                        color: '#0897FF'
                      }}
                    >
                      <b>All Departments</b>
                    </Typography>
                    <Box
                      className="custom_scrollbar"
                      sx={{ overflowY: 'auto', height: 'calc(100vh - 280px)', px: 2 }}
                    >
                      {itens.length > 0 ? (
                        itens.map((item, index) => (
                          <Stack key={`${item.id}`}>
                            <Grid className={`${classes.content__container} z-depth-1`}>
                              <Typography className={classes.number}>{index + 1}</Typography>
                              <Typography
                                sx={{ py: 1 }}
                                className={classes.role}
                                title={`${item.content}`}
                                alt={`${item.content}`}
                              >
                                <Typography
                                  component="span"
                                  sx={{ px: 1, display: 'flex', flexWrap: 'none' }}
                                  className={classes.role__text}
                                >
                                  {item.content}
                                </Typography>
                              </Typography>
                              <IconButton
                                color="error"
                                style={{
                                  borderRadius: 0,
                                  px: 1
                                }}
                                onClick={() => handleDelete(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Stack>
                        ))
                      ) : (
                        <Stack
                          direction="row"
                          sx={{ height: 300 }}
                          justifyContent="center"
                          alignItems="center"
                        >
                          Add Some to View
                        </Stack>
                      )}
                    </Box>
                  </Box>
                </Stack>
              </DragDropContext>
            </Container>
            <Stack
              sx={{ position: 'absolute', backgroundColor: 'white', bottom: 0, px: 2, pb: 1.2 }}
            >
              <Button
                type="button"
                onClick={() => {
                  handleDepartments();
                  onClose();
                }}
                // disabled={hasDuplicates(subNames)}
                variant="contained"
              >
                Submit
              </Button>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Modal>
  );
}

AddDepartment.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultPolicy: PropTypes.bool,
  selectedRole: PropTypes.object,
  edit: PropTypes.object
};

export default memo(AddDepartment);
