import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  height: 300,
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24
  // p: 4
};

export default function ImageZoom({ src, onClose }) {
  const theme = useTheme();
  return (
    <Modal
      open={src?.length > 0}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ position: 'relative' }}>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              zIndex: 99,
              m: 1,
              boxShadow: theme.customShadows.bloated,
              border: '1px solid #969799',
              backgroundColor: 'white',
              '&:hover': { backgroundColor: 'white', opacity: 0.8 },
              cursor: 'pointer'
            }}
          >
            <CloseIcon sx={{ fontSize: 16 }} />
          </IconButton>
          <img src={src} style={{ borderRadius: 3 }} alt="" />
        </Box>
      </Box>
    </Modal>
  );
}

ImageZoom.propTypes = {
  onClose: PropTypes.func,
  src: PropTypes.string
};
