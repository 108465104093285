import { io } from 'socket.io-client';
import { baseURL } from './config';

export default function callSocket() {
  const socket = io(`${baseURL}/`);
  socket.on('connect', () => {
    console.log('CONNECTION SUCCESSFULL:', socket.id); // x8WIv7-mJelg7on_ALbx
    const { engine } = socket.io;
    // engine.on('packet', ({ type, data }) => {
    //   // called for each packet received
    //   console.log(type, data);
    // });
    engine.on('close', (reason) => {
      console.log(reason);
      // called when the underlying connection is closed
    });
    socket.on('disconnect', () => {
      console.log(socket.id); // undefined
    });
  });

  return socket;
}
