import React from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  Chip,
  Button,
  Popover,
  Divider,
  // TextField,
  Select
} from '@mui/material';
//
// import EventIcon from '@mui/icons-material/Event';
// import WcIcon from '@mui/icons-material/Wc';
import FilterListIcon from '@mui/icons-material/FilterList';
import CancelIcon from '@mui/icons-material/Cancel';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { useSubRoles } from '../../../hooks/subRoles';
import { useRoles } from '../../../hooks/roles';
// import { convertUTCtoLocal, getCurrentDate } from '../../../utils/functions';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

PolicyListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  setFilters: PropTypes.func,
  filters: PropTypes.object
};

export default function PolicyListToolbar({
  numSelected,
  filterName,
  onFilterName,
  setFilters,
  filters
}) {
  const [roleOptions, setRoleOptions] = React.useState(); // Roles initial State for select
  // const [subRoleOptions, setSubRoleOptions] = React.useState(); // Sub Roles initial State for select
  const [filter, setFilter] = React.useState({
    role: '',
    subRole: ''
  });
  const cache = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState({
    id: '',
    target: ''
  });

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = React.useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  React.useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      allRoles.forEach((role) => formattedRoles.push([`${role._id},${role.name}`, role.name]));
      setRoleOptions([...formattedRoles]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allRoles]);
  /* ================= End Roles =============== */

  /* ================== Sub Roles ======================== */

  // const {
  //   isLoading: isSubRolesLoading,
  //   isError: isSubRolesError,
  //   data: subRoles
  // } = useSubRoles(filter.role.split(',')[0]);

  // React.useMemo(() => {
  //   const listOfSubRoles = [];
  //   if (!isSubRolesLoading && !isSubRolesError) {
  //     if (subRoles?.roles?.length > 0) {
  //       subRoles.roles[0].subroles.forEach((role) =>
  //         listOfSubRoles.push([`${role._id},${role.name}`, role.name])
  //       );
  //     }
  //   }
  //   // console.log('Sub Roles By Role:', listOfSubRoles);
  //   setSubRoleOptions([...listOfSubRoles]);
  // }, [subRoles, isSubRolesLoading, isSubRolesError]);

  /* ================== End Sub Roles ====================== */

  return (
    <>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter'
          })
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Search Policy..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton
              onClick={(event) =>
                setAnchorEl({ ...anchorEl, id: 'filter_policies', target: event.currentTarget })
              }
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </RootStyle>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 1, px: 2 }}
      >
        <Stack direction="row" alignItems="center">
          <FilterListIcon sx={{ fontSize: 20 }} />
          &nbsp;
          <Typography>Filters</Typography>
          &nbsp;
          <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
          <Chip
            color="secondary"
            label={
              <Stack direction="row" alignItems="center">
                Role : {filters.role.split(',')[1] ?? 'All'}
                &nbsp;&nbsp;
                {filters.role && (
                  <CancelIcon
                    onClick={() => {
                      setFilter({ ...filter, role: '' });
                      setFilters({ ...filters, role: '' });
                      cache.invalidateQueries('employees');
                    }}
                    sx={{ fontSize: 14, cursor: 'pointer' }}
                  />
                )}
              </Stack>
            }
            size="small"
          />
          &nbsp;
          {/* <Chip
            sx={{ backgroundColor: '#DC7633', color: 'white', fontSize: 11 }}
            label={
              <Stack direction="row" alignItems="center">
                Sub Role : {filters.subRole.split(',')[1] ?? 'All'}
                &nbsp;&nbsp;
                {filters.subRole && (
                  <CancelIcon
                    onClick={() => {
                      setFilter({ ...filter, subRole: '' });
                      setFilters({ ...filters, subRole: '' });
                      cache.invalidateQueries('employees');
                    }}
                    sx={{ fontSize: 14, cursor: 'pointer' }}
                  />
                )}
              </Stack>
            }
            size="small"
          /> */}
          &nbsp;
          {/* <Chip
            sx={{ backgroundColor: '#D68910', color: 'white' }}
            label={
              <Stack direction="row" alignItems="center">
                Manager : {filters.manager.split(',')[1] ?? 'All'}
                &nbsp;&nbsp;
                {filters.manager && (
                  <CancelIcon
                    onClick={() => {
                      setFilter({ ...filter, manager: '' });
                      setFilters({ ...filters, manager: '' });
                      cache.invalidateQueries('employees');
                    }}
                    sx={{ fontSize: 14, cursor: 'pointer' }}
                  />
                )}
              </Stack>
            }
            size="small"
          /> */}
        </Stack>
        {filters.role || filters.subRole ? (
          <Button
            variant="text"
            size="small"
            onClick={() => {
              setFilter({
                ...filter,
                role: '',
                subRole: ''
              });
              setFilters({
                ...filters,
                role: '',
                subRole: ''
              });
            }}
          >
            Clear All
          </Button>
        ) : null}
      </Stack>
      <Popover
        id="filter_policies"
        open={anchorEl.id === 'filter_policies'}
        anchorEl={anchorEl.target}
        onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack spacing={1} width="320px" sx={{ p: 2 }}>
          <Stack direction="row" alignItems="center">
            <FilterListIcon />
            <Typography sx={{ ml: 1 }}>Filters</Typography>
          </Stack>
          <Divider />
          {/* Role */}
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="role_filter">
                Choose Role
              </InputLabel>
              <Select
                variant="standard"
                value={filter?.role}
                inputProps={{
                  name: 'role_filter',
                  id: 'role_filter'
                }}
                onChange={(e) => setFilter({ ...filter, role: e.target.value })}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Role
                </MenuItem>
                {roleOptions?.length > 0 ? (
                  roleOptions.map(([key, value, image]) => (
                    <MenuItem key={`${'role_filter'}-${key}`} value={key} data-icon={image}>
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <>
                    <MenuItem value="" disabled />
                  </>
                )}
              </Select>
            </FormControl>
          </Box>
          {/* Sub Role */}
          {/* {filter.role && subRoleOptions?.length > 0 ? (
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="role_type">
                  Choose Sub Role
                </InputLabel>
                <Select
                  variant="standard"
                  disabled={!(filter.role && subRoleOptions.length > 0)}
                  value={filter.subRole}
                  inputProps={{
                    name: 'sub_role_type',
                    id: 'sub_role_type'
                  }}
                  required
                  sx={{ mt: 2 }}
                  onChange={(e) => setFilter({ ...filter, subRole: e.target.value })}
                  // error={Boolean(touched.sub_role_type && errors.sub_role_type)}
                  // helperText={touched.policy_role && errors.policy_role}
                >
                  <MenuItem value="" disabled>
                    Choose Sub Role
                  </MenuItem>
                  {subRoleOptions?.length > 0 ? (
                    subRoleOptions.map(([key, value, image]) => (
                      <MenuItem key={`${'sub_role_type'}-${key}`} value={key} data-icon={image}>
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled />
                  )}
                </Select>
              </FormControl>
            </Box>
          ) : null} */}
          <br />
          <Box>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              id="employee_apply_filter_button"
              size="medium"
              onClick={() => setFilters(filter)}
            >
              Apply
            </Button>
          </Box>
        </Stack>
      </Popover>
    </>
  );
}
