/* eslint-disable func-names */
/* eslint-disable no-var */
import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import {
  Typography,
  Stack,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Avatar,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  FormLabel,
  IconButton,
  CircularProgress,
  Divider
} from '@mui/material';
// icons
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import EditIcon from '@mui/icons-material/Edit';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
import ContactsIcon from '@mui/icons-material/Contacts';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { SWAL_TIME } from '../utils/config';
// mutations
import useMutationFunc from '../api/mutations/mutation';
import {
  updateSiteDetails,
  updateSitePicture,
  deleteSite,
  clearRoster
} from '../api/mutations/adminMutations';
import {
  setDataInStorage,
  capitalizeEachWord,
  getBase64,
  getCurrentDate,
  arraysEqual,
  convertUTCtoLocal,
  getDaysFromMonth
} from '../utils/functions';
// redux
import { logOut } from '../redux/actions/adminActions';
// import INDIAN from '../utils/INDIAN_STATES.json'; // importing indian states and uts
// components
// import Page from '../components/Page';

// Queries
import { UpdateSiteManagers } from './RosterOverview';
// import { fetchSiteById } from '../api/queries/Queries';
import { countries } from '../utils/countries';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     'aria-controls': `full-width-tabpanel-${index}`
//   };
// }

SiteProfile.propTypes = {
  site: PropTypes.any,
  rosterMonth: PropTypes.any
};

function SiteProfile({ site, rosterMonth }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const { info } = useSelector((state) => state.adminInfo);
  const [, setProfilePictureError] = useState(false);
  const [isUpdatingImg, setIsUpdatingImg] = useState(false); // to check if profile picture is updating

  // const [value, setValue] = React.useState(0);

  const [anchorEl, setAnchorEl] = React.useState({
    id: '',
    target: ''
  });

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };

  // const { isLoading, isError, data } = useQuery(
  //   // eslint-disable-next-line block-scoped-var
  //   ['site-profile', site?._id],
  //   // eslint-disable-next-line block-scoped-var
  //   () => fetchSiteById(site?._id),
  //   {
  //     // eslint-disable-next-line block-scoped-var
  //     enabled: !!site?._id,
  //     onError: (error) => {
  //       // status: 401 unauthorized
  //       if (error.response && error.response.status === 401) {
  //         dispatch(logOut());
  //       }
  //     }
  //   }
  // );

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  /* =================== Update Site Image ====================== */

  const { mutate: updateImageMutation } = useMutation(updateSitePicture, {
    onMutate: () => {
      setIsUpdatingImg(true);
    }
  });

  // 2MB
  const FILE_SIZE = 2 * 1024 * 1024;

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

  // const [pictureTouched, setpictureTouched] = useState(false);

  const validateProfilePicture = (profilePicture) => {
    let hasError = false;
    const { type, size } = profilePicture;
    const fileType = type;
    const fileSize = size;

    if (profilePicture && !SUPPORTED_FORMATS.includes(fileType)) {
      setProfilePictureError('Unsupported Format');
      hasError = true;
    } else if (profilePicture && fileSize > FILE_SIZE) {
      setProfilePictureError('File too large (Max: 2MB)');
      hasError = true;
    }
    return hasError;
  };

  const handleImageUpload = async (image) => {
    const imgError = validateProfilePicture(image);
    if (!imgError) {
      updateImageMutation(
        {
          id: site._id,
          image: await getBase64(image)
        },
        {
          onMutate: () => {
            setIsUpdatingImg(true);
          },
          onError: (error) => {
            // for unauthorized access
            if (error.response && error.response.status === 401) {
              dispatch(logOut());
            }
            // document.getElementById(
            //   'profile-picture-label'
            // ).style.backgroundImage = `url(${info?.avatar})`;

            Toast.fire({
              background: '#ec4646',
              html: `<h6 style='color: #ffffff; font-weight: bold;'>${
                error.response ? error.response.data.message || error.toString() : error.toString()
              }</h6>`
            });
          },
          onSuccess: (data) => {
            cache.invalidateQueries('site-profile');
            // if (data.image) {
            //   dispatch(updateAdminInfo('avatar', data.image));
            // }
            Toast.fire({
              icon: 'success',
              iconColor: '#fff',
              background: '#16c79a',
              title: `<span style='color: #ffffff; font-weight: bold; padding-left: 10px'>${data.message}</span>`,
              timer: SWAL_TIME,
              timerProgressBar: true
            });
          },
          onSettled: () => {
            setIsUpdatingImg(false);
            document.getElementById('profilePicture').value = null;
          }
        }
      );
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    didRender: (data) => {
      // changing text alignment for alert and adding some padding
      const content = data.querySelector('#swal2-content');
      if (content) {
        content.style.paddingLeft = '1rem';
        content.style.paddingBottom = '.7rem';
        content.style.textAlign = 'left';
      }
    }
  });

  const clearRosterMutation = useMutationFunc(
    clearRoster,
    {
      onSuccess: {
        title: 'Successfully Cleared!!'
      }
    },
    null,
    () => {
      cache.invalidateQueries();
    },
    null,
    null
  );

  /* =================== Update Site Details Mutation =========== */

  const { mutate: updateSiteDetailsMutation } = useMutation(updateSiteDetails, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: `Updating ${site?.name}'s profile details 🙂`,
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    }
  });

  const deleteSitesMutation = useMutationFunc(
    deleteSite,
    {
      onSuccess: {
        title: 'Successfully Deleted!'
      }
    },
    () => {
      navigate('/dashboard/roster');
    }
  );

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      siteId: site?._id,
      siteName: site?.name ?? '',
      siteEmail: site?.email ?? '',
      siteMobile: site?.mobile ?? '',
      country_code: site?.country_code ?? '91',
      siteCategory: site?.category ?? '',
      siteAddress: site?.address ?? '',
      // siteState: site?.state ?? '',
      siteRequirement: site?.requirement ?? '',
      fenceRadius: site?.fence_radius ?? 100,
      work_days_per_week: site?.constraints?.employee_weekly_working_days ?? 5,
      work_hours_per_day: site?.constraints?.daily_working_hours ?? 9,
      site_weekly_working_days: site?.constraints?.site_weekly_working_days ?? '',
      site_weekly_days_off: site?.constraints?.weekly_off
    },
    validationSchema: Yup.object({
      siteName: Yup.string()
        .notRequired()
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9\s\-&.']*[a-zA-Z0-9]+$/,
          "Invalid site name. ( Minimum 2 characters, must start & end with alphanumeric characters, allowed special characters  - & . ' )"
        )
        .test(
          'empty-check',
          "Name can't be empty",
          (value) => value && value.length && value.length !== 0
        ),
      // .matches(/^[a-zA-Z\s]+$/, "Please enter a valid name"),
      siteEmail: Yup.string().notRequired().nullable().email('Invalid email address'),
      // .test(
      //   "empty-check",
      //   "E-mail can't be empty",
      //   (value) => value && value.length && value.length !== 0
      // )
      siteMobile: Yup.string()
        .notRequired()
        .matches(/^[6-9]\d{9}$/, 'Invalid mobile number')
        .test(
          'empty-check',
          "Mobile can't be empty",
          (value) => value && value.length && value.length !== 0
        ),
      country_code: Yup.string().required('Country Code is required'),
      // siteState: Yup.string().notRequired(),
      // .test(
      //   'empty-check',
      //   'Please choose a state',
      //   (value) => value && value.length && value.length !== 0
      // ),
      siteAddress: Yup.string().notRequired().nullable(),
      fenceRadius: Yup.number().min(100).max(1000),
      site_weekly_working_days: Yup.number().required('This Field is required'),
      site_weekly_days_off: Yup.array().test({
        name: 'max',
        exclusive: false,
        params: {},
        // eslint-disable-next-line no-template-curly-in-string
        // eslint-disable-next-line react/no-this-in-sfc
        // message: `DayOff ${Yup.ref('site_weekly_working_days').getValue()}must be given`,
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          // You can access the price field with `this.parent`.
          // eslint-disable-next-line react/no-this-in-sfc
          const swd = this.parent.site_weekly_working_days;
          const diff = 7 - swd;
          const des = value.length === diff;
          // eslint-disable-next-line no-unneeded-ternary
          if (swd) {
            if (des) {
              return des;
            }
            // eslint-disable-next-line react/no-this-in-sfc
            return this.createError({
              message: `${diff} Day Off must be given`,
              path: 'site_weekly_days_off'
            });
          }
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            message: `Please select weekly working days for a site`,
            path: 'site_weekly_days_off'
          });
        }
      }),
      work_days_per_week: Yup.number().test({
        name: 'max',
        exclusive: false,
        params: {},
        // eslint-disable-next-line no-template-curly-in-string
        // eslint-disable-next-line react/no-this-in-sfc
        // message: `DayOff ${Yup.ref('site_weekly_working_days').getValue()}must be given`,
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          // You can access the price field with `this.parent`.
          // eslint-disable-next-line react/no-this-in-sfc
          const swd = this.parent.site_weekly_working_days;
          const des = value <= swd;
          // eslint-disable-next-line no-unneeded-ternary
          if (swd) {
            if (des) {
              return des;
            }
            // eslint-disable-next-line react/no-this-in-sfc
            return this.createError({
              message: `Employee working days must be less than or equal to ${swd}`,
              path: 'work_days_per_week'
            });
          }
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            message: `Please select weekly working days for a site`,
            path: 'work_days_per_week'
          });
        }
      })
      // .test(
      //   "empty-check",
      //   "Address can't be empty",
      //   (value) => value && value.length && value.length !== 0
      // ),
    }),
    // validate: (values) => {
    //   const errors = {};
    //   let fileType;
    //   let fileSize;
    //   if (values.profilePicture) {
    //     const { type, size } = values.profilePicture;
    //     fileType = type;
    //     fileSize = size;
    //   }
    //   if ((submitClicked && !values.profilePicture) || (pictureTouched && !values.profilePicture)) {
    //     errors.profilePicture = 'Profile picture is required';
    //   } else if (values.profilePicture && !SUPPORTED_FORMATS.includes(fileType)) {
    //     errors.profilePicture = 'Unsupported Format';
    //   } else if (values.profilePicture && fileSize > FILE_SIZE) {
    //     errors.profilePicture = 'File too large (Max: 2MB)';
    //   }
    //   console.log(values);
    //   return errors;
    // },
    onSubmit: async (values, actions) => {
      // console.log(values);
      actions.setStatus(null);
      const data = {
        id: values.siteId,
        name: capitalizeEachWord(values?.siteName),
        email: values?.siteEmail ? values?.siteEmail.toLowerCase() : '',
        country_code: values.country_code,
        mobile: values?.siteMobile,
        // category: values.siteCategory,
        address: values?.siteAddress ? capitalizeEachWord(values?.siteAddress) : '',
        // state: values?.siteState,
        // business: businessType,
        // requirement: values?.siteRequirement,
        fence_radius: values?.fenceRadius,
        constraints: {
          site_weekly_working_days: values.site_weekly_working_days,
          employee_weekly_working_days: values.work_days_per_week,
          daily_working_hours: values.work_hours_per_day,
          weekly_working_hours: values.work_days_per_week * values.work_hours_per_day,
          weekly_off: values.site_weekly_days_off
        },
        clearDates: await getDaysFromMonth(values.site_weekly_days_off)
      };

      // console.log('Site Profile Form', data);

      updateSiteDetailsMutation(data, {
        onError: (error) => {
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false
            // timer: 2000,
            // timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          // console.log('Updated Site Data:', data);
          setDataInStorage('selected_site', data?.site);
          actions.setSubmitting(false);
          Swal.fire({
            icon: 'success',
            title: 'Details Updated!',
            text: data.message,
            showConfirmButton: false,
            timer: SWAL_TIME,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          cache.invalidateQueries('site-profile');
        }
      });

      if (
        site?.shifts > 0 &&
        (site?.constraints?.weekly_working_hours !== data?.constraints?.weekly_working_hours ||
          !arraysEqual(data?.constraints?.weekly_off, site?.constraints?.weekly_off) ||
          data?.constraints?.site_weekly_working_days !==
            site?.constraints?.site_weekly_working_days)
      ) {
        clearRosterMutation({
          sites: [data?.id],
          shifts: [],
          today: getCurrentDate(),
          date: convertUTCtoLocal(rosterMonth, true),
          isClearFuture: true
        });
      }
    }
  });

  const {
    errors,
    touched,
    values,
    // isSubmitting,
    handleSubmit: UpdateSiteDetailsHandleSubmit,
    getFieldProps
    // resetForm
  } = formik;

  return (
    <Stack className="custom_scrollbar" sx={{ overflowY: 'auto', height: 'calc(100vh - 12rem)' }}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              height: '125px',
              position: 'relative',
              // px: 1,
              p: 2
            }}
          >
            <FormLabel
              htmlFor="profilePicture"
              sx={{ position: 'absolute', zIndex: 9, bottom: 12 }}
            >
              <TextField
                accept=".jpg,.jpeg,.png"
                id="profilePicture"
                type="file"
                sx={{ display: 'none' }}
                onChange={(event) => {
                  // previewImage(event, event.currentTarget.files[0]);
                  handleImageUpload(event.currentTarget.files[0]);
                }}
              />
              <IconButton color="primary" aria-label="upload picture" component="span">
                {isUpdatingImg ? <CircularProgress size={20} /> : <PhotoCameraIcon />}
              </IconButton>
            </FormLabel>
            <Avatar sx={{ width: 80, height: 80, mr: 3 }} alt={site?.name} src={site?.image} />
            <Stack>
              <Typography variant="h6">{site?.name}</Typography>
              <Typography componenet="span" sx={{ fontSize: 12 }}>
                Manager&nbsp;:&nbsp;{site?.manager?.name}
                {(info?.role?.priority < site?.manager?.role?.priority || info?.isMainAdmin) && (
                  <EditIcon
                    onClick={(e) =>
                      setAnchorEl({
                        ...anchorEl,
                        id: `update_site_manager_${site?._id}`,
                        target: e.currentTarget
                      })
                    }
                    sx={{
                      fontSize: 14,
                      mt: 0.5,
                      ml: 0.5,
                      cursor: 'pointer'
                    }}
                  />
                )}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid className="desktop" item xs={6} md={2}>
          <Card sx={{ height: 110, m: 1, p: 1, py: 2, backgroundColor: '#008080' }}>
            <Typography sx={{ color: 'white', fontSize: 14 }} align="center">
              Site Working
              <br />
              Days Weekly
            </Typography>
            <Typography
              align="center"
              className="counter-font"
              sx={{
                fontSize: 30,
                mt: 0.5,
                color: 'white',
                fontWeight: 'bold'
              }}
            >
              {site?.constraints?.site_weekly_working_days ?? 0}
            </Typography>
          </Card>
        </Grid>
        <Grid className="desktop" item xs={6} md={2}>
          <Card sx={{ height: 110, m: 1, p: 1, py: 2, backgroundColor: '#A569BD' }}>
            <Typography sx={{ color: 'white', fontSize: 14 }} align="center">
              Employee Working
              <br />
              Days Weekly
            </Typography>
            <Typography
              align="center"
              className="counter-font"
              sx={{
                fontSize: 30,
                mt: 0.5,
                color: 'white',
                fontWeight: 'bold'
              }}
            >
              {site?.constraints?.employee_weekly_working_days ?? 0}
            </Typography>
          </Card>
        </Grid>
        <Grid className="desktop" item xs={6} md={2}>
          <Card sx={{ height: 110, m: 1, p: 1, py: 2, backgroundColor: '#EB984E' }}>
            <Typography sx={{ color: 'white', fontSize: 14 }} align="center">
              Employee Working
              <br />
              Hours Daily
            </Typography>
            <Typography
              className="counter-font"
              align="center"
              sx={{ fontSize: 30, mt: 0.5, color: 'white', fontWeight: 'bold' }}
            >
              {site?.constraints?.daily_working_hours ?? 0}
            </Typography>
          </Card>
        </Grid>
        <Grid className="desktop" item xs={6} md={2}>
          <Card sx={{ height: 110, m: 1, p: 1, py: 2, backgroundColor: '#EC7063' }}>
            <Typography sx={{ color: 'white', fontSize: 14 }} align="center">
              Employee Working
              <br />
              Hours Weekly
            </Typography>
            <Typography
              className="counter-font"
              align="center"
              sx={{ fontSize: 30, mt: 0.5, color: 'white', fontWeight: 'bold' }}
            >
              {site?.constraints?.weekly_working_hours ?? 0}
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ mt: 1 }}>
        <Typography
          align="center"
          sx={{ fontWeight: 'bold', my: 2, color: theme.palette.primary.main }}
        >
          Profile
        </Typography>
        <Divider />
        <br />
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            {/* Name */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <PersonIcon sx={{ color: 'action.active', mr: 1 }} />
              <TextField
                variant="standard"
                // value={data?.site.name}
                label="Site Name"
                fullWidth
                type="text"
                autoComplete="off"
                {...getFieldProps('siteName')}
                error={Boolean(touched.siteName && errors.siteName)}
                helperText={touched.siteName && errors.siteName}
              />
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            {/* Email */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <PersonIcon sx={{ color: 'action.active', mr: 1 }} />
              <TextField
                variant="standard"
                // value={data?.site.name}
                label="Site Email"
                fullWidth
                type="text"
                autoComplete="off"
                {...getFieldProps('siteEmail')}
                error={Boolean(touched.siteEmail && errors.siteEmail)}
                helperText={touched.siteEmail && errors.siteEmail}
              />
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            {/* Mobile */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <ContactPhoneIcon sx={{ color: 'action.active', mr: 1 }} />
              <FormControl sx={{ width: 100 }}>
                {/* <InputLabel id="mobile-country-select-label">Country</InputLabel> */}
                <Select
                  labelId="mobile-country-select-label"
                  id="mobile-country-select"
                  variant="standard"
                  label="Country"
                  {...getFieldProps('country_code')}
                >
                  <MenuItem value="" disabled>
                    Choose Country
                  </MenuItem>
                  {countries.map((c) => (
                    <MenuItem key={c.code} value={c.phone}>
                      <img
                        loading="lazy"
                        width="23"
                        src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                        alt={`Flag of ${c.label}`}
                      />
                      &nbsp; +{c.phone}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                variant="standard"
                // value={data?.site?.mobile}
                fullWidth
                label="Contact Number"
                type="number"
                autoComplete="off"
                required
                {...getFieldProps('siteMobile')}
                error={Boolean(touched.siteMobile && errors.siteMobile)}
                // helperText={touched.siteMobile && }
              />
            </Box>
            {errors.siteMobile && (
              <Typography align="center" sx={{ color: 'red', fontSize: 12 }}>
                {errors.siteMobile}
              </Typography>
            )}
          </Grid>
          {/* <Grid item md={6} xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <LocationCityIcon sx={{ color: 'action.active', mr: 1 }} />
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="grouped-select">
                        Choose State
                      </InputLabel>
                      <Select
                        variant="standard"
                        id="grouped-select"
                        label="Choose State"
                        {...getFieldProps('siteState')}
                        error={Boolean(touched.siteState && errors.siteState)}
                        helperText={touched.siteState && errors.siteState}
                      >
                        <MenuItem value="">Choose State and Union Territories</MenuItem>
                        <ListSubheader>States</ListSubheader>
                        {INDIAN?.states?.length > 0 ? (
                          INDIAN?.states.map((key) => (
                            <MenuItem key={`${'state'}-${key}`} value={key}>
                              {key}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                        <ListSubheader>Union Territories</ListSubheader>
                        {INDIAN.union_territories?.length > 0 ? (
                          INDIAN.union_territories.map((key) => (
                            <MenuItem key={`${'territory'}-${key}`} value={key}>
                              {key}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid> */}
          <Grid item md={6} xs={12}>
            {/* Address */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <ContactsIcon sx={{ color: 'action.active', mr: 1 }} />
              <TextField
                variant="standard"
                fullWidth
                type="text"
                // placeholder="Site Address"
                label="Site Address"
                {...getFieldProps('siteAddress')}
                error={Boolean(touched.siteAddress && errors.siteAddress)}
                helperText={touched.siteAddress && errors.siteAddress}
              />
            </Box>
          </Grid>
          {/* <Grid item md={6} xs={12}>
          Requirement 
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <GroupIcon sx={{ color: 'action.active', mr: 1 }} />
            <TextField
              variant="standard"
              fullWidth
              type="number"
              // placeholder="Site Address"
              label="Requirement"
              {...getFieldProps('siteRequirement')}
              error={Boolean(touched.siteRequirement && errors.siteRequirement)}
              helperText={touched.siteRequirement && errors.siteRequirement}
            />
          </Box>
        </Grid> */}
          <Grid item md={6} xs={12}>
            {/* Geo Fence Radius */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <GroupIcon sx={{ color: 'action.active', mr: 1 }} />
              <TextField
                variant="standard"
                fullWidth
                type="number"
                inputProps={{ min: 100, max: 1000 }}
                // placeholder="Site Address"
                label="Geo Fence Radius (In meters)"
                {...getFieldProps('fenceRadius')}
                error={Boolean(touched.fenceRadius && errors.fenceRadius)}
                helperText={touched.fenceRadius && errors.fenceRadius}
              />
            </Box>
          </Grid>
        </Grid>
        <br />
        <Typography
          align="center"
          sx={{ fontWeight: 'bold', my: 2, color: theme.palette.primary.main }}
        >
          Constraints
        </Typography>
        <Divider />
        <br />
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Site Weekly Working Days</TableCell>
              <TableCell sx={{ minWidth: 40 }}>
                <FormControl fullWidth>
                  <Select
                    variant="standard"
                    // inputProps={{
                    //   name: 'role_type',
                    //   id: 'role_type'
                    // }}
                    {...getFieldProps('site_weekly_working_days')}
                    // error={Boolean(touched.role_type && errors.role_type)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Days
                    </MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Site Weekly Days Off</TableCell>
              <TableCell sx={{ minWidth: 60, maxWidth: 70 }}>
                <FormControl fullWidth>
                  <Select
                    variant="standard"
                    multiple
                    sx={{ minWidth: 70 }}
                    // inputProps={{
                    //   name: 'role_type',
                    //   id: 'role_type'
                    // }}
                    {...getFieldProps('site_weekly_days_off')}
                    error={Boolean(touched.site_weekly_days_off && errors.site_weekly_days_off)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Days
                    </MenuItem>
                    <MenuItem value={0}>Sunday</MenuItem>
                    <MenuItem value={1}>Monday</MenuItem>
                    <MenuItem value={2}>Tuesday</MenuItem>
                    <MenuItem value={3}>Wednesday</MenuItem>
                    <MenuItem value={4}>Thrusday</MenuItem>
                    <MenuItem value={5}>Friday</MenuItem>
                    <MenuItem value={6}>Saturday</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            {errors?.site_weekly_days_off && (
              <TableRow>
                <TableCell colSpan="2" align="center">
                  <Typography component="span" sx={{ fontSize: 11, color: 'red' }}>
                    {errors?.site_weekly_days_off}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Employee Working days / week</TableCell>
              <TableCell sx={{ minWidth: 40 }}>
                <FormControl fullWidth>
                  <Select
                    variant="standard"
                    // inputProps={{
                    //   name: 'role_type',
                    //   id: 'role_type'
                    // }}
                    {...getFieldProps('work_days_per_week')}
                    // error={Boolean(touched.role_type && errors.role_type)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Days
                    </MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            {errors?.work_days_per_week && (
              <TableRow>
                <TableCell colSpan="2" align="center">
                  <Typography component="span" sx={{ fontSize: 11, color: 'red' }}>
                    {errors?.work_days_per_week}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Employee Working hours / day</TableCell>
              <TableCell sx={{ minWidth: 40 }}>
                <FormControl fullWidth>
                  <Select
                    variant="standard"
                    // inputProps={{
                    //   name: 'role_type',
                    //   id: 'role_type'
                    // }}
                    {...getFieldProps('work_hours_per_day')}
                    // error={Boolean(touched.role_type && errors.role_type)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <MenuItem value="" disabled>
                      Hours
                    </MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Working hours / week</TableCell>
              <TableCell sx={{ minWidth: 40, maxWidth: 40 }}>
                <FormControl fullWidth>
                  <TextField
                    readOnly
                    variant="standard"
                    value={values.work_days_per_week * values.work_hours_per_day}
                  />
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br />
        <Stack direction="row" alignItems="center" spacing={1} sx={{ my: 1 }}>
          <Button variant="contained" onClick={() => UpdateSiteDetailsHandleSubmit()}>
            Update
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              Swal.fire({
                title: 'Are you sure to delete this site ?',
                text: "You won't be able to revert this!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, Proceed!',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteSitesMutation({
                    sites: [site?._id]
                  });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  cache.invalidateQueries('sites');
                }
              });
            }}
          >
            Delete
          </Button>
          {errors.length > 0 && (
            <Typography sx={{ color: 'red', fontWeight: 'bold', ml: 3 }}>
              {errors.length} Errors
            </Typography>
          )}
        </Stack>
      </Box>
      <UpdateSiteManagers site={site} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </Stack>
  );
}

export default SiteProfile;
