import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// material
import {
  Link,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Mutations
import { logInAdmin, logInEmployee } from '../../../api/mutations/auth';
// utils
import { getDataFromStorage } from '../../../utils/functions';
// redux
import { storeAdminInfo } from '../../../redux/actions/adminActions';
import { countries } from '../../../utils/countries';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const { mutate: adminLoginMutation } = useMutation(logInAdmin); // for admin login
  const { mutate: employeeLoginMutation } = useMutation(logInEmployee); // for employee login

  // getting state of authenticated from redux store
  const { isAdminAuthenticated, info } = useSelector((state) => state.adminInfo);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address'),
    mobile: Yup.string().notRequired('Mobile Number is Required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      mobile: '',
      type: 'Employee',
      country: 91,
      fcm_token: getDataFromStorage('fcm_token')
      // remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      let authToken = null;
      try {
        authToken = getDataFromStorage('adminAuthToken');
      } catch (err) {
        console.log('error getting admin auth token');
      }

      // if token doesn't exist already, only then send login req to server
      if (!authToken) {
        // login as Organization (Admin)
        if (values.type === 'Organization') {
          adminLoginMutation(values, {
            onError: (error) => {
              const msg = error.response
                ? error.response.data.message || error.toString()
                : error.toString();

              actions.setStatus({
                errorResponse: msg
              });

              actions.setSubmitting(false);
            },
            onSuccess: (data) => {
              actions.setSubmitting(false);
              dispatch(storeAdminInfo(data)); // storing user payload from req response in redux store
            }
          });
        } else {
          // login as Employee
          employeeLoginMutation(values, {
            onError: (error) => {
              const msg = error.response
                ? error.response.data.message || error.toString()
                : error.toString();

              // Swal.fire({
              //   icon: 'error',
              //   title: 'Something went wrong!',
              //   text: msg,
              //   showConfirmButton: false,
              //   timer: 2000,
              //   timerProgressBar: true
              // });

              actions.setStatus({
                errorResponse: msg
              });

              actions.setSubmitting(false);
            },
            onSuccess: (data) => {
              console.log(data);
              actions.setSubmitting(false);
              dispatch(storeAdminInfo(data)); // storing user payload from req response in redux store
            }
          });
        }
      } else {
        actions.setSubmitting(false);
        Swal.fire({
          icon: 'info',
          title: 'Another account is already logged in',
          text: 'We found another account logged in the same browser profile. Please log out of any other accounts before logging again in different one.',
          showConfirmButton: true
        });
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    if (isAdminAuthenticated) {
      if (info.is_setup_completed) {
        navigate('/dashboard/app', { replace: true });
      } else {
        navigate('/setup', { replace: true });
      }
    }
  }, [info, isAdminAuthenticated, navigate]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {formik?.status?.errorResponse && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {formik?.status?.errorResponse}
          </Alert>
        )}
        <Stack spacing={1}>
          <Stack spacing={2} direction="row">
            {values.type !== 'Organization' && (
              <FormControl fullWidth>
                <InputLabel id="mobile-country-select-label">Country</InputLabel>
                <Select
                  labelId="mobile-country-select-label"
                  id="mobile-country-select"
                  variant="standard"
                  label="Country"
                  {...getFieldProps('country')}
                >
                  {countries.map((c) => (
                    <MenuItem key={c.code} value={c.phone}>
                      <img
                        loading="lazy"
                        width="23"
                        src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                        alt={`Flag of ${c.label}`}
                      />
                      &nbsp; +{c.phone}&nbsp;[ {c.label} ]
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              fullWidth
              variant="standard"
              autoComplete="username"
              required
              type={values.type === 'Organization' ? 'email' : 'number'}
              label={values.type === 'Organization' ? 'Email Address' : 'Mobile Number'}
              {...getFieldProps(values.type === 'Organization' ? 'email' : 'mobile')}
              error={Boolean(
                values.type === 'Organization'
                  ? touched.email && errors.email
                  : touched.mobile && errors.mobile
              )}
              helperText={
                values.type === 'Organization'
                  ? touched.email && errors.email
                  : touched.mobile && errors.mobile
              }
            />
          </Stack>
          <TextField
            fullWidth
            variant="standard"
            required
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <FormControl sx={{ m: 1, minWidth: 300 }}>
            <InputLabel variant="standard" id="demo-simple-select-helper-label">
              Login As
            </InputLabel>
            <Select
              variant="standard"
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Login As"
              {...getFieldProps('type')}
            >
              {/* <MenuItem value="">
                <em>None</em>
              </MenuItem> */}
              <MenuItem value="Organization">Organization</MenuItem>
              <MenuItem value="Employee">Employee</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/reset-password">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
