import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchAllPayrollTemplates } from '../api/queries/Queries';

export const useAllPayrollComponents = (role, subrole) => {
  const dispatch = useDispatch();
  return useQuery(
    ['all-payroll-templates', role, subrole],
    () => fetchAllPayrollTemplates(role, subrole),
    {
      enabled: !!role,
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};
