/* eslint-disable no-nested-ternary */
import { useState, useEffect, useMemo } from 'react';
// import * as Yup from 'yup';
import { useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import AddIcon from '@mui/icons-material/Add';
// material
// import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  // Link,
  Box,
  Stack,
  // TextField,
  Button,
  // Tooltip,
  Paper,
  // Switch,
  Chip,
  Grid,
  Checkbox,
  IconButton,
  Divider,
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  // Card,
  // Container,
  Typography,
  Tooltip
} from '@mui/material';
// import { tooltipClasses } from '@mui/material/Tooltip';
import { useDebounce } from 'use-debounce';
import InfoIcon from '@mui/icons-material/Info';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import CircleIcon from '@mui/icons-material/Circle';
// import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ContactsIcon from '@mui/icons-material/Contacts';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { AddCircleOutlineTwoTone } from '@mui/icons-material';
// import classes from './styles/ImportEmployees.module.css';
// hooks
import { useUnmanagedSites } from '../hooks/get-sites-unmanaged';
import { fetchSiteManagers } from '../api/queries/Queries';
import useSites from '../hooks/get-sites-query';
// Mutations
import AddSite from '../components/_dashboard/site/AddSite';
import AddShift from '../components/_dashboard/shift/AddShift';
import EditHeirarchy from '../components/EditHeirarchy';
import NotAvailable from '../components/NotAvailable';
// utils
import {
  // DD_MM_YYYY,
  // excelToJSON,
  // capitalizeEachWord,
  _12hourTimeFormat
  // getDataFromStorage
  // setDataInStorage
} from '../utils/functions';
import { SWAL_TIME } from '../utils/config';
import { assignSiteManager } from '../api/mutations/adminMutations';
import UploadSites from '../components/_dashboard/import_sites/UploadSites';
// redux
import { logOut } from '../redux/actions/adminActions';
// import callSocket from '../utils/socket';
// import { countries } from '../utils/countries';

// const DEBUG = false;

// const countryPhoneCodes = countries.map((country) => country.phone);

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 500,
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9'
//   }
// }));

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary
// }));

Step4.propTypes = {
  heirarchy: PropTypes.array,
  disableNext: PropTypes.any
};

export default function Step4({ heirarchy, disableNext }) {
  const [filterName] = useState('');
  const [search] = useDebounce(filterName, 1000); // Debounce value 1 sec
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const [selected, setSelected] = useState([]);
  // const [roleOptions, setRoleOptions] = useState([]); // Roles initial State for select
  // const [siteOptions, setSiteOptions] = useState([]);
  // const selectRoleRef = useRef();
  // const [inMutate, setInMutate] = useState(false);
  // const [isValidating, setIsValidating] = useState(''); // Upon excel validation
  // const [importCheck, setImportCheck] = useState({
  //   importId: getDataFromStorage('importId') ?? null,
  //   importLength: getDataFromStorage('importLength') ?? null
  // });
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  // const [role, setRole] = useState({
  //   id: null,
  //   role: null
  // });
  const [assign, setAssign] = useState({
    manager: ''
  });
  const [openAddShift, setOpenAddShift] = useState(false);
  const [shiftSite, setShiftSite] = useState();
  const [viewHeirarchy, setViewHeirarchy] = useState(true);
  const [openAddSite, setOpenAddSite] = useState(false);
  // const { info } = useSelector((state) => state.adminInfo);
  // const [isImportingSites, setIsImportingSites] = useState(false);
  const [managerOptions, setManagerOptions] = useState([]); // Managers initial State for select

  /* ================= Unmanaged Sites ================= */

  const {
    isLoading: isImportSitesLoading,
    isError: isImportSitesError,
    data: unsites,
    fetchNextPage: fetchNextImportSites,
    hasNextPage: hasNextImportSites
    // refetch: refetchUnsites
  } = useUnmanagedSites(
    null,
    null,
    null,
    null,
    null,
    null, // Manager
    null, // Female
    true // to tell function to fetch policies for each employee too
  ); // getting Unmanaged sites

  //   useEffect(() => {
  //     if (unsitesearch) {
  //       refetchUnsites();
  //     }
  //   }, [unsitesearch, refetchUnsites]);

  const rows = useMemo(() => {
    const listOfSites = [];
    if (!isImportSitesLoading && !isImportSitesError) {
      if (unsites?.pages?.length > 0) {
        unsites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Unsites :', flattened);
    return flattened;
  }, [unsites, isImportSitesLoading, isImportSitesError]);

  /* ================= End Unmanaged Sites =============== */

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  /* ===================== Assign Manager Mutations ==============  */

  const assignManagerMutation = useMutation(assignSiteManager, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      setSelected([]);
      setAssign({ ...assign, manager: '' }); // Reset Select Value
      Swal.fire({
        icon: 'success',
        title: `Successfully assigned manager`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      setAnchorEl({ ...anchorEl, id: null, target: null });
      cache.invalidateQueries('unmanaged-sites');
      cache.invalidateQueries('sites');
    }
  });

  /* ===================== Assign Manager Mutations END ==============  */

  /* ===================== Sites ===================== */

  const {
    isLoading,
    isError,
    data: sites,
    fetchNextPage: fetchNextSites,
    hasMoreData: hasNextSites
    // refetch: refetchSites
  } = useSites(
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[0], true) : null, // from
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[1], true) : null, // to
    null, // filter.date.length > 0 ? filter.date : getCurrentDate(),
    null, // type
    null, // filter.state, // state
    null, // filter.status, // status
    null, // filter.manager, // manager
    null, // filter.role,
    search, // search
    null
  );

  const AllSites = useMemo(() => {
    const listOfSites = [];
    if (!isLoading && !isError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    if (flattened.length === 0) {
      disableNext(true);
    } else {
      disableNext(false);
    }
    // console.log('Sites :', flattened);
    return flattened;
  }, [isLoading, isError, sites, disableNext]);

  /* ================= End Sites =============== */

  const {
    isLoading: isSiteManagersLoading,
    isError: isSiteManagersError,
    data: siteManagers
    // fetchNextPage: fetchNextSiteManagers,
    // hasNextPage: hasNextSiteManagers
  } = useInfiniteQuery(
    ['site-managers', null, null],
    fetchSiteManagers,
    {
      // enabled: !!values.role_type,
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        // console.log("Error :", error)
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    } // gettings sitemanagers
  );

  const SiteManagers = useMemo(() => {
    const listOfSiteManagers = [];
    // console.log("Add Site Managers :", siteManagers);
    if (!isSiteManagersLoading && !isSiteManagersError) {
      if (siteManagers?.pages?.length > 0) {
        siteManagers.pages.forEach((group) => listOfSiteManagers.push(group.employees));
      }
    }
    const flattened = listOfSiteManagers.flat();
    return flattened;
  }, [siteManagers, isSiteManagersLoading, isSiteManagersError]);

  useEffect(() => {
    const formattedEmployees = [];

    if (SiteManagers?.length > 0) {
      SiteManagers.forEach((emp) =>
        formattedEmployees.push([
          `${emp._id}, ${emp.name}`,
          emp?.avater,
          emp?.role?.name,
          emp?.subrole?.name
        ])
      );
    }
    setManagerOptions([...formattedEmployees]);
  }, [SiteManagers]);

  /* ================= End Managers =============== */

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
        <Stack direction="row" alignContent="center">
          {rows.length > 0 && selected.length === 0 && (
            <Box>
              <UploadSites />
            </Box>
          )}
          &nbsp;&nbsp;
          {selected.length === 0 && (
            <Button
              variant="contained"
              component="span"
              startIcon={<AddIcon />}
              sx={{ boxShadow: 'none' }}
              onClick={() => setOpenAddSite(true)}
            >
              Site
            </Button>
          )}
          &nbsp;
        </Stack>
        {selected.length > 0 && (
          <Typography sx={{ color: '#0897FF', fontWeight: 'bold' }}>
            {selected.length}&nbsp;Selected
          </Typography>
        )}
        <Stack direction="row">
          {selected.length > 0 && (
            <Tooltip title="Assign Manager">
              <Button
                onClick={(event) =>
                  setAnchorEl({
                    ...anchorEl,
                    id: 'assign_manager_to_sites',
                    target: event.currentTarget
                  })
                }
              >
                <AssignmentIndIcon />
              </Button>
            </Tooltip>
          )}
          &nbsp;
          <Tooltip title="View Heirarchy">
            <IconButton onClick={() => setViewHeirarchy(!viewHeirarchy)}>
              <AccountTreeIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack style={{ border: '2px solid #0897FF', borderRadius: 8 }} sx={{ p: 1, mt: 1 }}>
            <Typography
              sx={{
                mt: -2.5,
                px: 2,
                backgroundColor: 'white',
                minWidth: 'calc(100vh - 25rem)',
                color: '#0897FF'
              }}
            >
              <b>Unassigned Sites ( {rows.length} )</b>
            </Typography>
            <TableContainer
              className="custom_scrollbar"
              id="step_4_import_sites"
              sx={{ height: 'calc(100vh - 300px)' }}
              component={Paper}
            >
              <Table sx={{ minWidth: 450 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Site</TableCell>
                    <TableCell>Manager</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isImportSitesLoading ? (
                    <TableRow>
                      <TableCell align="center" colSpan="3">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : isImportSitesError ? (
                    <TableRow>
                      <TableCell align="center" colSpan="3">
                        Something Went Wrong
                      </TableCell>
                    </TableRow>
                  ) : rows?.length > 0 ? (
                    rows
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const isItemSelected = selected.indexOf(row._id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={row._id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, row?._id)}
                              />
                            </TableCell>
                            {/* <TableCell>{(index += 1)}</TableCell> */}
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Avatar alt={row?.name} src={row?.avatar} />
                                <Typography variant="subtitle2" noWrap>
                                  {row?.name}
                                </Typography>
                                <InfoIcon
                                  sx={{ cursor: 'pointer' }}
                                  onClick={(event) =>
                                    setAnchorEl({
                                      id: `info_${row._id}`,
                                      target: event.currentTarget
                                    })
                                  }
                                />
                                <Popover
                                  id={`info_${row._id}`}
                                  open={anchorEl.id === `info_${row._id}`}
                                  anchorEl={anchorEl.target}
                                  onClose={() => setAnchorEl({ id: null, target: null })}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                >
                                  <List>
                                    {row?.mobile && (
                                      <ListItem disablePadding>
                                        <ListItemButton>
                                          <ListItemIcon>
                                            <PhoneAndroidIcon />
                                          </ListItemIcon>
                                          <ListItemText primary={`+91 ${row?.mobile}`} />
                                        </ListItemButton>
                                      </ListItem>
                                    )}
                                    {row?.email && (
                                      <ListItem disablePadding>
                                        <ListItemButton>
                                          <ListItemIcon>
                                            <AlternateEmailIcon />
                                          </ListItemIcon>
                                          <ListItemText primary={`${row?.email}`} />
                                        </ListItemButton>
                                      </ListItem>
                                    )}
                                    {/* <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <LocationCityIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`${row?.state ?? 'Not Provided'}`} />
                                      </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                      <ListItemButton component="a" href="#simple-list">
                                        <ListItemIcon>
                                          <ContactsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`${row?.address}`} />
                                      </ListItemButton>
                                    </ListItem> */}
                                  </List>
                                </Popover>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack sx={{ minWidth: '8rem' }}>
                                <FormControl fullWidth>
                                  <InputLabel variant="standard" htmlFor="assign_manager">
                                    Choose Manager
                                  </InputLabel>
                                  <Select
                                    variant="standard"
                                    value=""
                                    inputProps={{
                                      name: 'assign_manager',
                                      id: `assign_manager_${row._id}`
                                    }}
                                    sx={{ mt: 2 }}
                                    onChange={(event) => {
                                      Swal.fire({
                                        title: 'Are you sure?',
                                        text: "You won't be able to revert this!",
                                        icon: 'question',
                                        showCancelButton: true,
                                        confirmButtonText: 'Yes, Proceed!',
                                        allowOutsideClick: false
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          assignManagerMutation.mutate({
                                            sites: [row._id],
                                            manager: event.target.value.split(',')[0]
                                          });
                                        } else if (
                                          /* Read more about handling dismissals below */
                                          result.dismiss === Swal.DismissReason.cancel
                                        ) {
                                          const select = document.querySelector(
                                            `#assign_manager_${row._id}`
                                          );
                                          select.selectedIndex = 0;
                                          cache.invalidateQueries('unmanaged-sites');
                                        }
                                      });
                                    }}
                                  >
                                    <MenuItem value="" disabled>
                                      Choose Manager
                                    </MenuItem>
                                    {managerOptions.length > 0 ? (
                                      managerOptions.map(([key, image, empR, empSR]) => (
                                        <MenuItem
                                          style={{ padding: '0.5rem' }}
                                          key={`${'assign_manager'}-${key}`}
                                          value={key}
                                        >
                                          <Avatar src={image} />
                                          <Stack sx={{ ml: 1 }}>
                                            <Typography>&nbsp;{key.split(',')[1]}</Typography>
                                            {empR && (
                                              <Box display="flex">
                                                <Chip
                                                  fontSize={12}
                                                  sx={{ height: 18, mb: 0.5 }}
                                                  size="small"
                                                  label={empR}
                                                />
                                                &nbsp;
                                                {empSR && (
                                                  <Chip
                                                    fontSize={12}
                                                    sx={{ height: 18 }}
                                                    size="small"
                                                    label={empSR}
                                                  />
                                                )}
                                              </Box>
                                            )}
                                          </Stack>
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem value="No Data Found" disabled />
                                    )}
                                  </Select>
                                </FormControl>
                              </Stack>
                            </TableCell>
                            {/* <TableCell align="right">
                        <SiteMoreMenu />
                      </TableCell> */}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan="3">
                        <Box>
                          <UploadSites />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {/* Put the scroll bar always on the bottom */}
              <InfiniteScroll
                dataLength={rows?.length}
                next={fetchNextImportSites}
                // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
                // inverse //
                hasMore={hasNextImportSites}
                loader={
                  <Stack
                    direction="row"
                    sx={{ my: 1.5 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Stack>
                }
                scrollableTarget="step_4_import_sites"
                // endMessage={
                //   <p style={{ textAlign: 'center' }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
              />
            </TableContainer>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack style={{ border: '2px solid #0897FF', borderRadius: 8 }} sx={{ p: 1, mt: 1 }}>
            <Typography
              sx={{
                mt: -2.5,
                px: 2,
                backgroundColor: 'white',
                minWidth: 'calc(100vh - 25rem)',
                color: '#0897FF'
              }}
            >
              <b>Sites ( {AllSites.length} )</b>
            </Typography>
            <TableContainer id="sites-table-container" sx={{ height: 'calc(100vh - 300px)' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Site</TableCell>
                    <TableCell>Shifts</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell align="center" colSpan="3">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : isError ? (
                    <TableRow>
                      <TableCell>Something Went Wrong</TableCell>
                    </TableRow>
                  ) : !isLoading && AllSites.length > 0 ? (
                    AllSites
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = selected.indexOf(row._id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={row._id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            {/* <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, row._id)}
                                />
                              </TableCell> */}
                            <TableCell>{(index += 1)}</TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Avatar alt={row.name} src={row?.image} />
                                {/* <RouterLink
                                  style={{ textDecoration: 'none' }}
                                  // to="/site/profile"
                                  // state={{ site: row }}
                                  
                                > */}
                                <Tooltip
                                  sx={{ fontSize: '1rem' }}
                                  title={`Visit ${row.name}`}
                                  placement="top"
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ cursor: 'pointer' }}
                                    noWrap
                                    // onClick={() => {
                                    //   setDataInStorage('selected_site', row);
                                    //   navigate('/site/profile');
                                    // }}
                                  >
                                    {row.name}
                                  </Typography>
                                </Tooltip>
                                {/* </RouterLink> */}
                                <InfoIcon
                                  sx={{ cursor: 'pointer' }}
                                  onClick={(event) =>
                                    setAnchorEl({
                                      id: `info_${row._id}`,
                                      target: event.currentTarget
                                    })
                                  }
                                />
                                <Popover
                                  id={`info_${row._id}`}
                                  open={anchorEl.id === `info_${row._id}`}
                                  anchorEl={anchorEl.target}
                                  onClose={() => setAnchorEl({ id: null, target: null })}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                >
                                  <List>
                                    <Stack
                                      sx={{ p: 2 }}
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      <Avatar alt={row.name} src={row.manager.avatar} />
                                      <Stack>
                                        <Typography variant="subtitle2" noWrap>
                                          {row.manager.name}
                                        </Typography>
                                        <Typography sx={{ fontSize: 11 }} component="span">
                                          Manager
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                    {row.mobile ? (
                                      <ListItem disablePadding>
                                        <ListItemButton>
                                          <ListItemIcon>
                                            <PhoneAndroidIcon />
                                          </ListItemIcon>
                                          <ListItemText primary={`+91 ${row.mobile}`} />
                                        </ListItemButton>
                                      </ListItem>
                                    ) : null}
                                    {row.email ? (
                                      <ListItem disablePadding>
                                        <ListItemButton>
                                          <ListItemIcon>
                                            <AlternateEmailIcon />
                                          </ListItemIcon>
                                          <ListItemText primary={`${row.email}`} />
                                        </ListItemButton>
                                      </ListItem>
                                    ) : null}
                                    {row.state ? (
                                      <ListItem disablePadding>
                                        <ListItemButton>
                                          <ListItemIcon>
                                            <LocationCityIcon />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={`${row.state ?? 'Not Provided'}`}
                                          />
                                        </ListItemButton>
                                      </ListItem>
                                    ) : null}
                                    {row.address ? (
                                      <ListItem disablePadding>
                                        <ListItemButton component="a" href="#simple-list">
                                          <ListItemIcon>
                                            <ContactsIcon />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={`${row.address ?? 'Not Provided'}`}
                                          />
                                        </ListItemButton>
                                      </ListItem>
                                    ) : null}
                                  </List>
                                </Popover>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {row.shifts.length > 0 ? (
                                <Stack direction="row" alignItems="center">
                                  <Typography
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(e) =>
                                      setAnchorEl({
                                        ...anchorEl,
                                        id: `shift_step_4_${row._id}`,
                                        target: e.currentTarget
                                      })
                                    }
                                  >
                                    Shift ( {row.shifts.length} )
                                  </Typography>
                                  &nbsp;&nbsp;
                                  <Popover
                                    id={`shift_step_4_${row._id}`}
                                    open={anchorEl.id === `shift_step_4_${row._id}`}
                                    anchorEl={anchorEl.target}
                                    onClose={() =>
                                      setAnchorEl({
                                        ...anchorEl,
                                        id: null,
                                        target: null
                                      })
                                    }
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left'
                                    }}
                                  >
                                    <Stack sx={{ p: 2 }}>
                                      {row.shifts.map((shift) => (
                                        <Stack
                                          sx={{ mt: 1 }}
                                          key={`shifts_step_4_${row._id}_${shift._id}`}
                                        >
                                          <Typography>{shift.name}</Typography>
                                          <Typography sx={{ fontSize: 12 }}>
                                            Timing ( {_12hourTimeFormat(shift.start)}&nbsp;-&nbsp;
                                            {_12hourTimeFormat(shift.end)} )
                                          </Typography>
                                        </Stack>
                                      ))}
                                    </Stack>
                                  </Popover>
                                  <Button
                                    size="small"
                                    sx={{ minWidth: 15, boxShadow: 'none' }}
                                    onClick={() => {
                                      setOpenAddShift(true);
                                      setShiftSite(row);
                                    }}
                                    variant="contained"
                                  >
                                    <AddIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Button
                                  size="small"
                                  onClick={() => {
                                    setOpenAddShift(true);
                                    setShiftSite(row);
                                  }}
                                  variant="contained"
                                  startIcon={<AddIcon />}
                                >
                                  Shift
                                </Button>
                              )}
                            </TableCell>
                            {/* <TableCell>
                                {row?.status === 'Verified' ? (
                                  <Typography sx={{ color: 'green', fontWeight: 'bold' }}>
                                    {row.status}
                                  </Typography>
                                ) : (
                                  <Typography sx={{ color: 'red', fontWeight: 'bold' }}>
                                    {row.status}
                                  </Typography>
                                )}
                              </TableCell> */}
                            {/* <TableCell align="right">
                                <SiteMoreMenu />
                              </TableCell> */}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        <NotAvailable type="na" msg="No Sites Available" />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {/* Put the scroll bar always on the bottom */}
              <InfiniteScroll
                dataLength={AllSites?.length}
                next={fetchNextSites}
                // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
                // inverse //
                hasMore={hasNextSites}
                loader={<h4>Loading...</h4>}
                scrollableTarget="sites-table-container"
                // endMessage={
                //   <p style={{ textAlign: 'center' }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
              />
            </TableContainer>
          </Stack>
        </Grid>
      </Grid>
      <AddSite open={openAddSite} onClose={() => setOpenAddSite(false)} />
      <Popover
        id="assign_manager_to_sites"
        open={anchorEl.id === 'assign_manager_to_sites'}
        anchorEl={anchorEl.target}
        onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack spacing={1} width="320px" sx={{ p: 2 }}>
          <Stack direction="row" alignItems="center">
            <AddModeratorIcon />
            <Typography sx={{ ml: 1 }}>Assign Manager</Typography>
          </Stack>
          <Divider />
          {/* Manager */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              '& #mui-component-select-multiple_assign_manager': {
                display: 'flex',
                alignItems: 'center',
                pl: 1
              }
            }}
          >
            <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="manager_filter">
                Choose Manager
              </InputLabel>
              <Select
                variant="standard"
                value={assign?.manager ?? ''}
                inputProps={{
                  name: 'multiple_assign_manager',
                  id: 'multiple_assign_manager'
                }}
                onChange={(e) => setAssign({ ...assign, manager: e.target.value })}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Manager
                </MenuItem>
                {managerOptions?.length > 0 ? (
                  managerOptions.map(([key, image, empR, empSR]) => (
                    <MenuItem
                      style={{ padding: '0.5rem' }}
                      key={`${'manager_filter'}-${key}`}
                      value={key}
                      data-icon={image}
                    >
                      <Avatar src={image} />
                      <Stack sx={{ ml: 1 }}>
                        <Typography>&nbsp;{key.split(',')[1]}</Typography>
                        {empR && (
                          <Box display="flex">
                            <Chip
                              fontSize={12}
                              sx={{ height: 18, mb: 0.5 }}
                              size="small"
                              label={empR}
                            />
                            &nbsp;
                            {empSR && (
                              <Chip fontSize={12} sx={{ height: 18 }} size="small" label={empSR} />
                            )}
                          </Box>
                        )}
                      </Stack>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled />
                )}
              </Select>
            </FormControl>
          </Box>
          <br />
          <Box>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              id="employee_apply_filter_button"
              size="medium"
              onClick={() => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'This will apply changes to all selected Site!',
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, Proceed!',
                  allowOutsideClick: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    setAnchorEl({ ...anchorEl, id: null, target: null });
                    assignManagerMutation.mutate({
                      sites: selected,
                      manager: assign.manager.split(',')[0]
                    });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    cache.invalidateQueries('employees');
                  }
                });
              }}
            >
              Assign
            </Button>
          </Box>
        </Stack>
      </Popover>
      {/* Edit Heirarchy Modal */}
      <EditHeirarchy
        heirarchy={heirarchy}
        open={viewHeirarchy}
        close={() => setViewHeirarchy(true)}
      />
      <AddShift
        shiftSite={shiftSite}
        step
        edit={null}
        rosterMonth={new Date()}
        // totalShiftRequirement={TOTAL_SHIFT_REQUIREMENT}
        open={openAddShift}
        onClose={() => setOpenAddShift(false)}
      />
    </Box>
  );
}
