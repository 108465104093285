/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import { useMutation, useQueryClient, useQuery } from 'react-query';
// import { useNavigate } from 'react-router-dom';
import { useFormik, FormikProvider, Form } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// icons
import PersonIcon from '@mui/icons-material/Person';
import WcIcon from '@mui/icons-material/Wc';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import NumbersIcon from '@mui/icons-material/Numbers';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import EventIcon from '@mui/icons-material/Event';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import EmailIcon from '@mui/icons-material/Email';
import ContactsIcon from '@mui/icons-material/Contacts';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
import NotAvailable from '../components/NotAvailable';
import {
  changeAvatar,
  // updateAdminDetails,
  updateEmployeeDetails
} from '../api/mutations/profile';
import {
  getEmployeeProfileById
  // getAdminProfileById
} from '../api/queries/profile';
// hooks
import { useRoles } from '../hooks/roles';
import { useAllPayrollComponents } from '../hooks/get-all-payroll-templates';
import useSites from '../hooks/get-sites-query';
import usePolicies from '../hooks/get-policies';
import { useManagers } from '../hooks/get-managers';
import { useSubRoles } from '../hooks/subRoles';
// utils
import {
  uid,
  getCurrentDate,
  getBase64,
  capitalizeEachWord,
  convertUTCtoLocal,
  // setDataInStorage,
  getDataFromStorage,
  DD_MM_YYYY
} from '../utils/functions';
import { SWAL_TIME, COMPANY_PLACEHOLDER_LOGO } from '../utils/config';
// components
import Page from '../components/Page';
import EmployeeForm from '../components/_dashboard/employee/EmployeeForm';
import { logOut, updateAdminInfo } from '../redux/actions/adminActions';
// import INDIAN from '../utils/INDIAN_STATES.json'; // importing indian states and uts
import { countries } from '../utils/countries';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  borderBottom: '1px solid grey',
  color: theme.palette.text.secondary
}));

const label = { inputProps: { 'aria-label': 'Switch demo' } };

// const names = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Sunday'];

function EmployeeProfile() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const cache = useQueryClient();

  const [roleOptions, setRoleOptions] = useState([]); // Roles initial State for select
  const [subRoleOptions, setSubRoleOptions] = useState([]); // Sub Roles initial State for select
  const [policyOptions, setPolicyOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [payrollTemplateOptions, setPayrollTemplateOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [siteType, setSiteType] = useState([]); // Select Multiple Sites
  const [smPriority, setSMPriority] = useState();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [education, setEducation] = useState([]);

  const {
    // isAdminAuthenticated,
    info
  } = useSelector((state) => state.adminInfo);
  // const [adminDobError, setAdminDobError] = useState();
  const [
    // profilePictureError,
    setProfilePictureError
  ] = useState(false);
  // const [offdays, setOffDays] = useState([]);
  const [isUpdatingImg, setIsUpdatingImg] = useState(false); // to check if profile picture is updating
  const [isEdit, setIsEdit] = useState(false);
  // useEffect(() => {
  //   console.log(info);
  // }, [info]);
  const selectedEmployee = getDataFromStorage('selected_employee');

  const { mutate: updatePhotoMutation } = useMutation(changeAvatar, {
    onMutate: () => {
      setIsUpdatingImg(true);
    }
  });

  const {
    isLoading: isProfileLoading,
    isError: isProfileError,
    data: profileData
  } = useQuery(
    [`employee-profile`, selectedEmployee?._id],
    () => getEmployeeProfileById(selectedEmployee._id),
    {
      enabled: !!selectedEmployee._id,
      refetchOnWindowFocus: false,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );

  const [dateOfBirth, setDateOfBirth] = useState();
  const [dateOfJoining, setDateOfJoining] = useState();

  const EMPLOYEE = React.useMemo(() => {
    const options = [];
    let data = {};
    if (!isProfileLoading && !isProfileError) {
      setDateOfBirth(profileData?.employee?.dob);
      setDateOfJoining(profileData?.employee?.doj);
      profileData?.employee?.admin?.group?.forEach((gp) => {
        options.push([gp._id, gp.name]);
      });
      setCompanyOptions([...options]);
      data = profileData?.employee;
    }
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileLoading, isProfileError]);

  // const { mutate: employeeDetailsMutation } = useMutation(updateEmployeeDetails);

  // 2MB
  const FILE_SIZE = 2 * 1024 * 1024;

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

  // const [pictureemployeeTouched, setpictureemployeeTouched] = useState(false);

  const validateProfilePicture = (profilePicture) => {
    let hasError = false;
    const { type, size } = profilePicture;
    const fileType = type;
    const fileSize = size;

    if (profilePicture && !SUPPORTED_FORMATS.includes(fileType)) {
      setProfilePictureError('Unsupported Format');
      hasError = true;
    } else if (profilePicture && fileSize > FILE_SIZE) {
      setProfilePictureError('File too large (Max: 2MB)');
      hasError = true;
    }
    return hasError;
  };

  const handleImageUpload = async (image) => {
    const imgError = validateProfilePicture(image);
    if (!imgError) {
      updatePhotoMutation(
        {
          id: selectedEmployee?._id,
          image: await getBase64(image),
          admin: false
        },
        {
          onMutate: () => {
            setIsUpdatingImg(true);
          },
          onError: (error) => {
            // for unauthorized access
            if (error.response && error.response.status === 401) {
              dispatch(logOut());
            }
            // document.getElementById(
            //   'profile-picture-label'
            // ).style.backgroundImage = `url(${info?.avatar})`;

            Toast.fire({
              background: '#ec4646',
              html: `<h6 style='color: #ffffff; font-weight: bold;'>${
                error.response ? error.response.data.message || error.toString() : error.toString()
              }</h6>`
            });
          },
          onSuccess: (data) => {
            cache.invalidateQueries('admin-profile');
            cache.invalidateQueries('employee-profile');
            if (data.image) {
              dispatch(updateAdminInfo('avatar', data.image));
            }
            Toast.fire({
              icon: 'success',
              iconColor: '#fff',
              background: '#16c79a',
              title: `<span style='color: #ffffff; font-weight: bold; padding-left: 10px'>${data.message}</span>`,
              timer: SWAL_TIME,
              timerProgressBar: true
            });
          },
          onSettled: () => {
            setIsUpdatingImg(false);
            document.getElementById('profilePicture').value = null;
          }
        }
      );
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: SWAL_TIME,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    didRender: (data) => {
      // changing text alignment for alert and adding some padding
      const content = data.querySelector('#swal2-content');
      if (content) {
        content.style.paddingLeft = '1rem';
        content.style.paddingBottom = '.7rem';
        content.style.textAlign = 'left';
      }
    }
  });

  /* ===================== Roles ===================== */

  /* ===================== Sites ===================== */

  const {
    isLoading,
    isError,
    // fetchNextPage: fetchNextSites,
    // hasMoreData: hasNextSites,
    data: sites
    // refetch: refetchSites
  } = useSites(
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[0], true) : null, // from
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[1], true) : null, // to
    null, // filter.date.length > 0 ? filter.date : getCurrentDate(),
    null, // type
    null, // filter.state, // state
    null, // filter.status, // status
    null, // filter.manager, // manager
    null, // filter.role,
    null, // search
    null
  );

  const allSites = React.useMemo(() => {
    const listOfSites = [];
    if (!isLoading && !isError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Sites :', flattened);
    return flattened;
  }, [sites, isLoading, isError]);

  useEffect(() => {
    const formattedSites = [];
    if (allSites?.length > 0) {
      allSites.forEach((site) => {
        if (site.active === 'Active') {
          formattedSites.push([`${site._id},${site.name}`, site.name]);
        }
      });
      setSiteOptions([...formattedSites]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allSites]);

  /* ================= End Sites =============== */

  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = React.useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      // console.log(allRoles);
      let priority = 0;
      allRoles.forEach((role) => {
        if (role.is_site_manager) {
          priority = role.priority;
          setSMPriority(priority);
        }
        if (role.priority > priority && priority > 0) {
          formattedRoles.push([`${role._id},${true}`, role.name]);
        } else if (priority === 0 || role.priority === priority) {
          formattedRoles.push([`${role._id},${false}`, role.name]);
        }
      });
      setRoleOptions([...formattedRoles]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allRoles]);

  /* ================= End Roles =============== */

  // ----------------------------- Employee Formik
  const employeeFormik = useFormik({
    enableReinitialize: !isEdit,
    initialValues: {
      name: EMPLOYEE?.name ?? '',
      gender: EMPLOYEE?.gender ?? '',
      email: EMPLOYEE?.email ?? '',
      mobile: EMPLOYEE?.contact ?? '',
      country_code: EMPLOYEE?.country_code ?? '',
      dateOfBirth: new Date(EMPLOYEE?.dob) ?? '',
      doj: new Date(EMPLOYEE?.doj) ?? '',
      vehicle_type: EMPLOYEE?.vehicle_type ?? '',
      holidays: EMPLOYEE?.day_off ?? [],
      company: EMPLOYEE?.admin?._id ?? '',
      department: EMPLOYEE?.department ?? '',
      tag: '',
      marital_status: EMPLOYEE?.marital_status ?? '',
      blood_group: EMPLOYEE?.blood_group ?? '',
      // password: '',
      // state: '',
      pan: EMPLOYEE?.pan?.value ?? '',
      pan_proof: EMPLOYEE?.pan?.data ?? '',
      aadhar: EMPLOYEE?.aadhar?.value ?? '',
      aadhar_proof: EMPLOYEE?.aadhar?.data ?? '',
      role_type: `${EMPLOYEE?.role?._id},${EMPLOYEE?.role?.priority > smPriority}` ?? '',
      sub_role_type: EMPLOYEE?.subrole?._id ?? '',
      payout_type: EMPLOYEE?.payout_type ?? '',
      payout_amount: EMPLOYEE?.payout_amount ?? '',
      manager_type: '',
      site_type: '',
      enroll_pf: EMPLOYEE?.pf_settings?.enable ?? false,
      pf_type: EMPLOYEE?.pf_settings?.pf_type ?? '',
      eps_employer_contribution: EMPLOYEE?.eps_employer_contribution ?? '',
      pf_employee_contribution: EMPLOYEE?.pf_settings?.pf_employee_contribution ?? '',
      add_employee_policy_type: EMPLOYEE?.policy?._id ?? '',
      reliving_certificate: EMPLOYEE?.reliving_certificate ?? '',
      bank_name: EMPLOYEE?.bank_details?.bank_name ?? '',
      bank_branch: EMPLOYEE?.bank_details?.bank_branch ?? '',
      bank_account_no: EMPLOYEE?.bank_details?.bank_account_no ?? '',
      bank_ifsc_code: EMPLOYEE?.bank_details?.bank_ifsc_code ?? '',
      uan: EMPLOYEE?.uan,
      epf_no: EMPLOYEE?.epf_no ?? '',
      esi_no: EMPLOYEE?.esi_no ?? '',
      previous_employ_start:
        EMPLOYEE?.previous_employment_details?.previous_employ_start ?? new Date(),
      previous_employ_end: EMPLOYEE?.previous_employment_details?.previous_employ_end ?? new Date(),
      previous_organization_name:
        EMPLOYEE?.previous_employment_details?.previous_organization_name ?? '',
      previous_designation: EMPLOYEE?.previous_employment_details?.previous_designation ?? '',
      previous_ctc: EMPLOYEE?.previous_employment_details?.previous_ctc ?? '',
      permanent_house_no: EMPLOYEE?.permanent_address?.permanent_house_no ?? '',
      permanent_village_town_street:
        EMPLOYEE?.permanent_address?.permanent_village_town_street ?? '',
      permanent_pincode: EMPLOYEE?.permanent_address?.permanent_pincode ?? '',
      permanent_country: EMPLOYEE?.permanent_address?.permanent_country ?? '',
      permanent_state: EMPLOYEE?.permanent_address?.permanent_state ?? '',
      permanent_city: EMPLOYEE?.permanent_address?.permanent_city ?? '',
      same_as_permanent: false,
      correspondence_house_no: EMPLOYEE?.correspondence_address?.correspondence_house_no ?? '',
      correspondence_village_town_street:
        EMPLOYEE?.correspondence_address?.correspondence_village_town_street ?? '',
      correspondence_pincode: EMPLOYEE?.correspondence_address?.correspondence_pincode ?? '',
      correspondence_country: EMPLOYEE?.correspondence_address?.correspondence_country ?? '',
      correspondence_state: EMPLOYEE?.correspondence_address?.correspondence_state ?? '',
      correspondence_city: EMPLOYEE?.correspondence_address?.correspondence_city ?? '',
      emergency_contact_country: EMPLOYEE?.emergency_contact?.country_code ?? '',
      emergency_contact_no: EMPLOYEE?.emergency_contact?.contact_no ?? '',
      payroll_template: EMPLOYEE?.payroll ?? ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name is required')
        .matches(
          /^[a-zA-Z][a-zA-Z\s]*$/,
          'Please enter a valid name (only alphabets & whitespaces are allowed)'
        ),
      company: Yup.string().required('Company is required'),
      department: Yup.string().required('Department is required'),
      gender: Yup.string().required('Gender is required'),
      marital_status: Yup.string().required('Marital Status is required'),
      blood_group: Yup.string().required('Blood group is required'),
      email: Yup.string().email('Invalid email address').notRequired(),
      mobile: Yup.string()
        .required('Mobile number is required')
        .matches(/^[6-9]\d{9}$/, 'Invalid mobile number'),
      // password: Yup.string()
      //   .required('Password is required')
      //   .min(6, 'Minimum 6 characters')
      //   .max(15, 'Maximum 15 characters')
      //   .matches(
      //     /^[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{6,15}$/,
      //     'Password can only contain alphabets, numbers or special characters'
      //   ),
      // confirmPassword: Yup.string()
      //   .required('This field is required')
      //   .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      dateOfBirth: Yup.date()
        // .max(new Date(getCurrentDate()), "Date of birth can't be a future date")
        .required('Date of birth is required'),
      doj: Yup.date()
        // .max(new Date(getCurrentDate()), "Date of joining can't be a future date")
        .required('Date of joining is required'),
      // state: Yup.string().required('This field is required'),
      country_code: Yup.string().required('Country Code is required'),
      // address: Yup.string().required('Address is required'),
      vehicle_type: Yup.string()
        .required('This field is required')
        .oneOf(['Two-wheeler', 'Four-wheeler']),
      role_type: Yup.string().required('This Field is required'),
      manager_type: Yup.string().notRequired(),
      // site_type: Yup.string().notRequired(),
      add_employee_policy_type: Yup.string().required('This Filed is required'),
      emergency_contact_no: Yup.string().required('This Field is required'),
      emergency_contact_country: Yup.string().required('This Field is required'),
      permanent_house_no: Yup.string().required('This Field is required'),
      permanent_village_town_street: Yup.string().required('This Field is required'),
      permanent_pincode: Yup.string().required('This Field is required'),
      permanent_country: Yup.string().required('This Field is required'),
      permanent_state: Yup.string().required('This Field is required'),
      permanent_city: Yup.string().required('This Field is required'),
      correspondence_house_no: Yup.string().required('This Field is required'),
      correspondence_village_town_street: Yup.string().required('This Field is required'),
      correspondence_pincode: Yup.string().required('This Field is required'),
      correspondence_country: Yup.string().required('This Field is required'),
      correspondence_state: Yup.string().required('This Field is required'),
      correspondence_city: Yup.string().required('This Field is required'),
      payout_type: Yup.string().required('This Field is required'),
      payout_amount: Yup.string().required('This Field is required'),
      uan: Yup.string().required('This Field is required'),
      bank_name: Yup.string().required('This Field is required'),
      bank_account_no: Yup.string().required('This Field is required'),
      bank_ifsc_code: Yup.string().required('This Field is required')
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        id: EMPLOYEE?._id,
        name: capitalizeEachWord(values.name),
        gender: values.gender,
        email: values.email.toLowerCase(),
        contact: values.mobile,
        country_code: values.country_code,
        marital_status: values.marital_status,
        dob: convertUTCtoLocal(values.dateOfBirth, true),
        doj: EMPLOYEE?.doj, // Same as profile Data convertUTCtoLocal(employeeValues.doj, true)
        // address: capitalizeEachWord(values.address),
        vehicle_type: values.vehicle_type,
        // state: employeeValues.state,
        pan: {
          value: values.pan,
          data: values.pan_proof
        },
        aadhar: {
          value: values.aadhar,
          data: values.aadhar_proof
        },
        blood_group: values.blood_group,
        // tag: employeeValues.tag,
        department: values.department,
        role: values.role_type.split(',')[0],
        uan: values.uan,
        epf_no: values.epf_no,
        esi_no: values.esi_no,
        policy: values.add_employee_policy_type,
        payout_type: values.payout_type, // Type of pay
        payout_amount: values.payout_amount, // Amount of Pay
        company: values.company,
        payroll_template: values.payroll_template,
        same_as_permanent: values.same_as_permanent,
        permanent_address: {
          permanent_house_no: values.permanent_house_no,
          permanent_village_town_street: values.permanent_village_town_street,
          permanent_pincode: values.permanent_pincode,
          permanent_country: values.permanent_country,
          permanent_state: values.permanent_state,
          // permanent_district: employeeValues.permanent_district,
          permanent_city: values.permanent_city
        },
        correspondence_address: {
          correspondence_house_no: values.correspondence_house_no,
          correspondence_village_town_street: values.correspondence_village_town_street,
          correspondence_pincode: values.correspondence_pincode,
          correspondence_country: values.correspondence_country,
          correspondence_state: values.correspondence_state,
          // correspondence_district: employeeValues.correspondence_district,
          correspondence_city: values.correspondence_city
        },
        previous_employment_details: {
          previous_employ_start: convertUTCtoLocal(values.previous_employ_start, true),
          previous_employ_end: convertUTCtoLocal(values.previous_employ_end, true),
          previous_organization_name: values.previous_organization_name,
          previous_designation: values.previous_designation,
          previous_ctc: values.previous_ctc,
          exp_certificate: values.exp_certificate
        },
        education_details: education.filter((edu) => edu.aggregate > 0),
        emergency_contact: {
          country_code: values.emergency_contact_country,
          contact_no: values.emergency_contact_no
        },
        bank_details: {
          bank_name: values.bank_name,
          bank_branch: values.bank_branch,
          bank_account_no: values.bank_account_no,
          bank_ifsc_code: values.bank_ifsc_code
        },
        pf_settings: {
          enable: values.enroll_pf,
          pf_employee_contribution: values.pf_employee_contribution
        },
        eps_employer_contribution: values.eps_employer_contribution,
        // experience_details: experience
        //   .map((ep) => {
        //     // eslint-disable-next-line prefer-destructuring
        //     ep.from = convertUTCtoLocal(ep.from, true).split('-')[0];
        //     // eslint-disable-next-line prefer-destructuring
        //     ep.to = convertUTCtoLocal(ep.to, true).split('-')[0];
        //     return ep;
        //   })
        //   .filter((exp) => exp.certificate.length > 0),
        relivingCertificate: values.reliving_certificate
        // profilePicture: values.profilePicture
        // day_off: employeeValues.holidays ?? EMPLOYEE?.day_off
      };

      if (employeeValues.sub_role_type.length > 0) {
        data.subrole = employeeValues.sub_role_type;
      }

      if (employeeValues.manager_type.length > 0) {
        data.manager = employeeValues.manager_type;
      }

      if (siteType.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        data.sites = siteType.map((site) => site.split(',')[0]);
      }

      console.log('Employee Profile Update Form :', data);

      // employeeDetailsMutation(data, {
      //   onError: (error) => {
      //     // for unauthorized access
      //     if (error.response && error.response.status === 401) {
      //       dispatch(logOut());
      //     }

      //     const msg = error.response
      //       ? error.response.data.message || error.toString()
      //       : error.toString();

      //     Swal.fire({
      //       icon: 'error',
      //       title: 'Something went wrong!',
      //       text: msg,
      //       showConfirmButton: false,
      //       // timer: 2000,
      //       // timerProgressBar: true
      //     });

      //     actions.setStatus({
      //       errorResponse: msg
      //     });
      //     actions.setSubmitting(false);
      //   },
      //   onSuccess: (data) => {
      //     actions.setSubmitting(false);
      //     Swal.fire({
      //       icon: 'success',
      //       title: 'Profile Updated!',
      //       text: data.message,
      //       showConfirmButton: false,
      //       timer: SWAL_TIME,
      //       timerProgressBar: true
      //     });
      //   },
      //   onSettled: () => {
      //     setIsEdit(false);
      //     cache.invalidateQueries('employee-profile');
      //   }
      // });
    }
  });

  const {
    errors: employeeErrors,
    touched: employeeTouched,
    values: employeeValues,
    isSubmitting,
    handleSubmit: handleEmployeeSubmit,
    getFieldProps: getEmployeeFieldProps
    // resetForm: resetEmployeeForm
  } = employeeFormik;

  const _countries = Country.getAllCountries().map((cn) => `${cn.isoCode},${cn.name}`);
  const _permanentStates = State.getStatesOfCountry(
    employeeValues?.permanent_country.split(',')[0]
  ).map((pst) => `${pst.isoCode},${pst.name}`);
  const _permanentCities = City.getCitiesOfState(
    employeeValues?.permanent_country.split(',')[0],
    employeeValues.permanent_state.split(',')[0]
  ).map((pct) => `${pct.isoCode},${pct.name}`);
  const _correspondanceStates = State.getStatesOfCountry(
    employeeValues.correspondence_country.split(',')[0]
  ).map((pst) => `${pst.isoCode},${pst.name}`);
  const _correspondanceCities = City.getCitiesOfState(
    employeeValues.correspondence_country.split(',')[0],
    employeeValues.correspondence_state.split(',')[0]
  ).map((pct) => `${pct.isoCode},${pct.name}`);

  useEffect(() => {
    if (employeeValues.same_as_permanent) {
      employeeValues.correspondence_house_no = employeeValues.permanent_house_no;
      employeeValues.correspondence_village_town_street =
        employeeValues.permanent_village_town_street;
      employeeValues.correspondence_pincode = employeeValues.permanent_pincode;
      employeeValues.correspondence_country = employeeValues.permanent_country;
      employeeValues.correspondence_state = employeeValues.permanent_state;
      // values.correspondence_district = values.permanent_district;
      employeeValues.correspondence_city = employeeValues.permanent_city;
    } else {
      employeeValues.correspondence_house_no = '';
      employeeValues.correspondence_village_town_street = '';
      employeeValues.correspondence_pincode = '';
      employeeValues.correspondence_country = '';
      employeeValues.correspondence_state = '';
      employeeValues.correspondence_district = '';
      employeeValues.correspondence_city = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeValues.same_as_permanent]);

  /* ================== Sub Roles ======================== */

  const {
    isLoading: isSubRolesLoading,
    isError: isSubRolesError,
    data: subRoles
  } = useSubRoles(employeeValues.role_type.split(',')[0] ?? EMPLOYEE?.role?._id); //

  React.useMemo(() => {
    const listOfSubRoles = [];
    if (!isSubRolesLoading && !isSubRolesError) {
      if (subRoles?.roles?.length > 0) {
        subRoles.roles[0].subroles.forEach((role) => listOfSubRoles.push([role._id, role.name]));
      }
    }
    // console.log('Sub Roles By Role:', listOfSubRoles);
    setSubRoleOptions([...listOfSubRoles]);
  }, [subRoles, isSubRolesLoading, isSubRolesError]);

  /* ================== End Sub Roles ====================== */

  /* ===================== Policies =====================  */

  const {
    isPoliciesLoading,
    isPoliciesError,
    // fetchNextPage: fetchNextPolicies,
    // hasNextPage: hasNextPolicies,
    data: policies
  } = usePolicies(employeeValues?.role_type.split(',')[0] ?? EMPLOYEE?.role?._id); // getting policies

  const Policies = React.useMemo(() => {
    const listOfPolicies = [];
    if (!isPoliciesLoading && !isPoliciesError) {
      if (policies?.pages?.length > 0) {
        policies.pages.forEach((group) => listOfPolicies.push(group.policies));
      }
    }
    const flattened = listOfPolicies.flat();
    return flattened;
  }, [policies, isPoliciesLoading, isPoliciesError]);

  useEffect(() => {
    // console.log(Policies);
    const formattedPolicies = [];
    if (Policies?.length > 0) {
      Policies.forEach((policy) => formattedPolicies.push([policy._id, policy.name]));
      setPolicyOptions([...formattedPolicies]);
    }
  }, [Policies]);

  /* ===================== End Policies ==================  */
  /* ===================== Managers ===================== */

  const {
    isLoading: isManagersLoading,
    isError: isManagersError,
    data: managers
  } = useManagers(employeeValues?.role_type.split(',')[0] ?? EMPLOYEE?.role?._id); // gettings managers employeeValues?.role_type.split(',')[0]

  const Managers = React.useMemo(() => {
    // console.log('Managers :', managers);
    const listOfManagers = [];
    if (!isManagersLoading && !isManagersError) {
      if (managers?.pages?.length > 0) {
        managers.pages.forEach((group) => listOfManagers.push(group.managers));
      }
    }
    const flattened = listOfManagers.flat();
    return flattened;
  }, [managers, isManagersLoading, isManagersError]);

  useEffect(() => {
    const formattedManagers = [];
    if (Managers?.length > 0) {
      Managers.forEach((manager) =>
        formattedManagers.push([
          manager._id,
          manager.name
          // manager.avatar ?? COMPANY_PLACEHOLDER_LOGO
        ])
      );
    }
    // console.log('Managers Options', formattedManagers);
    setManagerOptions([...formattedManagers]);
  }, [Managers]);

  /* ================= End Managers =============== */

  const {
    isLoading: isPayrollTemplatesLoading,
    isError: isPayrollTemplatesError,
    data: payrollTemplates
  } = useAllPayrollComponents(
    employeeValues?.role_type.split(',')[0] ?? EMPLOYEE?.role?._id,
    employeeValues?.subrole_type ? employeeValues?.subrole_type.split(',')[0] : undefined
  ); // gettings roles

  const allPayrollTemplates = React.useMemo(() => {
    if (!isPayrollTemplatesLoading && !isPayrollTemplatesError) {
      return payrollTemplates?.templates;
    }
    return []; // Needs to be changed
  }, [payrollTemplates, isPayrollTemplatesLoading, isPayrollTemplatesError]);

  useEffect(() => {
    const formattedTemplates = [];
    if (allPayrollTemplates?.length > 0) {
      allPayrollTemplates.forEach((payroll) => {
        formattedTemplates.push([payroll._id, payroll.name]);
      });
      setPayrollTemplateOptions([...formattedTemplates]);
    }
  }, [allPayrollTemplates]);

  return (
    <Page title="Profile | Trackkar">
      <Container>
        {isProfileLoading ? (
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 'calc(100vh - 11rem)'
            }}
          >
            <NotAvailable type="loading" left msg="Loading Profile..." />
          </Stack>
        ) : isProfileError ? (
          <NotAvailable type="na" msg="Somwthing Went Wrong!" />
        ) : isSubmitting ? (
          <NotAvailable type="loading" left msg="Update Profile..." />
        ) : (
          <Box sx={{ p: 3, pt: 1, position: 'relative' }}>
            <Box sx={{ position: 'absolute', display: 'flex', p: 2, right: 0, top: 0 }}>
              {/* <Chip
                label={`Shifts : ${data?.site?.shifts?.lenght ?? 0}`}
                sx={{ mr: 2 }}
                component="a"
                href="#basic-chip"
              />
              <Chip
                label={`Feedback : ${data?.site?.feedback ?? 0}`}
                component="a"
                href="#basic-chip"
              /> */}
            </Box>
            <Stack spacing={2}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  height: '120px',
                  position: 'relative',
                  px: 1,
                  pb: 2,
                  borderBottom: '2px solid grey'
                }}
              >
                <FormLabel
                  htmlFor="profilePicture"
                  sx={{ position: 'absolute', zIndex: 9, bottom: 12 }}
                >
                  <TextField
                    accept=".jpg,.jpeg,.png"
                    id="profilePicture"
                    type="file"
                    sx={{ display: 'none' }}
                    onChange={(event) => {
                      // previewImage(event, event.currentTarget.files[0]);
                      handleImageUpload(event.currentTarget.files[0]);
                    }}
                  />
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    {isUpdatingImg ? <CircularProgress size={20} /> : <PhotoCameraIcon />}
                  </IconButton>
                </FormLabel>
                <Avatar
                  sx={{ width: 90, height: 90, mr: 3 }}
                  alt={EMPLOYEE?.name}
                  src={EMPLOYEE?.avatar}
                />
                <Typography sx={{ fontSize: '1rem' }}>{EMPLOYEE?.name}</Typography>
                {/* <Typography
                  marginBottom="0.4rem"
                  sx={{ position: 'absolute', fontSize: '0.9rem', right: 0, bottom: 0 }}
                >
                  Email : {!info.isMainAdmin ? EMPLOYEE?.email : profileData?.admin?.email}
                </Typography> */}
              </Stack>
            </Stack>
            <br />
            <Box sx={{ flexGrow: 1 }}>
              {isEdit ? (
                <EmployeeForm formMutation={updateEmployeeDetails} edit={EMPLOYEE} />
              ) : (
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Item>Name : {EMPLOYEE?.name}</Item>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Item>Gender : {EMPLOYEE?.gender}</Item>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Item>Email : {EMPLOYEE?.email}</Item>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Item>Contact : {`+${EMPLOYEE?.country_code} ${EMPLOYEE?.contact}`}</Item>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Item>Date Of Birth : {DD_MM_YYYY(EMPLOYEE?.dob)}</Item>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Item>Date Of Joining : {DD_MM_YYYY(EMPLOYEE?.doj)}</Item>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Item>Address : {EMPLOYEE?.address}</Item>
                  </Grid>
                  {/* <Grid item md={6} xs={12}>
                    <Item>
                      State : {EMPLOYEE?.state}
                    </Item>
                  </Grid> */}
                  <Grid item md={6} xs={12}>
                    <Item>Vehicle : {EMPLOYEE?.vehicle_type}</Item>
                  </Grid>
                  {/* <Grid item md={6} xs={12}>
                    <Item>
                      Day Offs : {EMPLOYEE?.day_off.toString()}
                    </Item>
                  </Grid> */}
                </Grid>
              )}
            </Box>
            <br />
            {isEdit ? (
              <>
                <Button variant="contained" color="error" onClick={() => setIsEdit(false)}>
                  Cancel
                </Button>
              </>
            ) : (
              <Button variant="contained" onClick={() => setIsEdit(true)}>
                Edit
              </Button>
            )}
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default EmployeeProfile;
