import { Typography, Stack } from '@mui/material';

export default function InternetConnectionError() {
  return (
    <Stack
      sx={{ width: '100%', height: '100%' }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Typography>No Internet Connection</Typography>
    </Stack>
  );
}
