import React, { useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Stack,
  Typography,
  Card,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  ListItem,
  List,
  TextField,
  ListItemText,
  Popover,
  Button
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import { useDebounce } from 'use-debounce';
import { downloadECRChallan } from 'src/api/queries/Queries';
import { SiteFeedbackListHead, SiteFeedbackMoreMenu } from '../components/_dashboard/site_feedback';
import { ECRChallanListToolbar } from '../components/_dashboard/payroll';
import { usePaySlips } from '../hooks/get-payslips';
// components
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
// utils
import { DD_MM_YYYY, convertUTCtoLocal } from '../utils/functions';
import { SEARCH_DEBOUNCE } from '../utils/config';

// ---------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'uan', label: 'UAN', alignRight: false },
  { id: 'member_name', label: 'MEMBER_NAME', alignRight: false },
  { id: 'gross_wages', label: 'GROSS_WAGES', alignRight: false },
  { id: 'epf_wages', label: 'EPF WAGES', alignRight: false },
  { id: 'eps_wages', label: 'EPS WAGES', alignRight: false },
  { id: 'edli_wages', label: 'EDLI WAGES', alignRight: false },
  { id: 'epf_contri', label: 'EPF CONTRI REMITTED', alignRight: false },
  { id: 'eps_contri', label: 'EPS CONTRI REMITTED', alignRight: false },
  { id: 'epf_eps_diff', label: 'EPF_EPS_DIFF_REMITTED', alignRight: false },
  { id: 'ncp_days', label: 'NCP_DAYS', alignRight: false },
  { id: 'refund_of_advances', label: 'REFUND_OF_ADVANCES', alignRight: false },
  { id: '' }
];

// --------------------------------------------------------------

function ECRChallan() {
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [search] = useDebounce(filterName, SEARCH_DEBOUNCE); // Debounce value 1.2 sec
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    site: '',
    date: new Date(),
    employee: ''
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const {
    isLoading: isPaySlipsLoading,
    isError: isPaySlipsError,
    hasNextPage: hasNextPaySlips,
    fetchNextPage: fetchNextPaySlips,
    data: payslips
  } = usePaySlips(search, convertUTCtoLocal(filter?.date, true));

  const rows = useMemo(() => {
    const listOfPaySlips = [];
    if (!isPaySlipsLoading && !isPaySlipsError) {
      if (payslips?.pages?.length > 0) {
        payslips.pages.forEach((group) => {
          listOfPaySlips.push(group.payslips);
        });
      }
    }
    const flattened = listOfPaySlips.flat();
    return flattened;
  }, [payslips, isPaySlipsLoading, isPaySlipsError]);

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const filteredUsers = rows;

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="ECR Challan | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <ECRChallanListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
          />
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
            <DatePicker
              views={['year', 'month']}
              label="Year and Month"
              minDate={new Date('2012-03-01')}
              // maxDate={new Date('2023-06-01')}
              value={filter.date}
              onChange={(newValue) => {
                setFilter({ ...filter, date: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ '& input': { padding: '9.5px 14px' } }}
                  {...params}
                  helperText={null}
                />
              )}
            />
            <Button
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<DownloadIcon />}
              onClick={async () => {
                const fileName = `ECR_Challan-${convertUTCtoLocal(filter.date, true)}`;
                const pdf = await downloadECRChallan(
                  convertUTCtoLocal(filter.date, true),
                  fileName
                );
                // const file = new Blob([pdf], { type: 'application/pdf' });
                // const fileURL = URL.createObjectURL(file);
                // window.open(fileURL);
              }}
            >
              ECR Challan File
            </Button>
          </Stack>
        </Stack>

        <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 180px)' }}>
          <Table>
            <SiteFeedbackListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = selected.indexOf(row.name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={row._id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell>{(index += 1)}</TableCell>
                      <TableCell>{row.employee.uan}</TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {row.employee.name}
                      </TableCell>
                      <TableCell>{row.total_earnings}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Container>
    </Page>
  );
}

export default ECRChallan;
