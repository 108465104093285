/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import _ from 'lodash';
import React, { useMemo, useEffect, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider, Form } from 'formik';
import { useDebounce } from 'use-debounce';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
// icons
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
// date picker
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ShareIcon from '@mui/icons-material/Share';
import SyncIcon from '@mui/icons-material/Sync';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import PeopleIcon from '@mui/icons-material/People';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
// import InventoryIcon from '@mui/icons-material/Inventory';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StoreIcon from '@mui/icons-material/Store';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
// hooks
import useSites from '../hooks/get-sites-query';
import useBuckets from '../hooks/bucket';
import { useMonthAvailability } from '../hooks/get-site-month-availability';
import {
  assignSiteManager,
  deleteSite,
  createBucket,
  deleteBucket,
  updateBucket
} from '../api/mutations/adminMutations';
import useMutationFunc from '../api/mutations/mutation';
import { useSiteManagers } from '../hooks/site';
import AddSite from '../components/_dashboard/site/AddSite';
import ClearRoster from '../components/ClearRoster';
import AddHoliday from '../components/AddHoliday';
import { ProgressIndicators } from '../components/ProgressIndicators';
// utils
import { SWAL_TIME, ADD_EMPLOYEE } from '../utils/config';
import {
  convertUTCtoLocal,
  setDataInStorage,
  getCurrentDate,
  capitalizeEachWord,
  getBase64
} from '../utils/functions';
import { ImageZoom } from '../components';
import Page from '../components/Page';
import { useSitePayroll } from '../hooks/get-site-payroll';
import '../index.css';
import NotAvailable from '../components/NotAvailable';
// redux
import { logOut } from '../redux/actions/adminActions';

const Months = [
  'January',
  'Febrary',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'Octuber',
  'November',
  'December'
];

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const modalStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100vh',
  minWidth: 320,
  maxWidth: 400,
  bgcolor: 'background.paper',
  boxShadow: 24
};

UpdateSiteManagers.propTypes = {
  site: PropTypes.any,
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func
};

export function UpdateSiteManagers({ site, anchorEl, setAnchorEl }) {
  const [siteManagersOptions, setSiteManagersOptions] = React.useState();
  const [manager, setManager] = React.useState('');
  const cache = useQueryClient();
  const dispatch = useDispatch();
  /* ===================== Sites ===================== */

  const {
    isLoading,
    isError,
    data: siteManagers
    // fetchNextPage: fetchNextSiteManagers,
    // hasMoreData: hasNextSiteManagers
    // refetch: refetchSites
  } = useSiteManagers(
    null, // Search
    null // Role
  );

  const allSiteManagers = useMemo(() => {
    const listOfSiteManagers = [];
    if (!isLoading && !isError) {
      if (siteManagers?.pages?.length > 0) {
        siteManagers.pages.forEach((group) => listOfSiteManagers.push(group.employees));
      }
    }
    const flattened = listOfSiteManagers.flat();
    // console.log('Site Managers :', flattened);
    return flattened;
  }, [siteManagers, isLoading, isError]);

  useEffect(() => {
    const formattedSiteManagers = [];
    if (allSiteManagers?.length > 0) {
      allSiteManagers.forEach((emp) => {
        formattedSiteManagers.push([emp._id, emp.name]);
      });
      setSiteManagersOptions([...formattedSiteManagers]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allSiteManagers]);

  /* ================= End Sites =============== */

  /* ===================== Assign Manager Mutations ==============  */

  const assignManagerMutation = useMutation(assignSiteManager, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Assiging New Manager 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: (response) => {
      // setSelected([]);
      // setAssign({ ...assign, manager: '' }); // Reset Select Value
      setDataInStorage('selected_site', response.sites[0]);
      Swal.fire({
        icon: 'success',
        title: `Successfully assigned manager`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      setAnchorEl({ ...anchorEl, id: null, target: null });
      cache.invalidateQueries('unmanaged-sites');
      cache.invalidateQueries('sites');
      cache.invalidateQueries();
    }
  });

  /* ===================== Assign Manager Mutations END ==============  */

  return (
    <Popover
      id={`update_site_manager_${site._id}`}
      open={anchorEl.id === `update_site_manager_${site._id}`}
      anchorEl={anchorEl.target}
      onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Stack sx={{ p: 1, width: 200 }}>
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="site_manager_type">
            Choose Manager
          </InputLabel>
          <Select
            variant="standard"
            inputProps={{
              name: 'site_manager_type',
              id: 'site_manager_type'
            }}
            required
            value={manager}
            sx={{ mt: 2 }}
            onChange={(e) => setManager(e.target.value)}
            // {...getFieldProps('role_type')}
            // error={Boolean(touched.role_type && errors.role_type)}
            // helperText={touched.policy_role && errors.policy_role}
          >
            <MenuItem value="" disabled>
              Choose Manager
            </MenuItem>
            {siteManagersOptions?.length > 0 ? (
              siteManagersOptions.map(([key, value, image]) => (
                <MenuItem key={`${'site_manager'}-${key}`} value={key} data-icon={image}>
                  <Avatar src={image} />
                  <Typography sx={{ ml: 1 }}>{value}</Typography>
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled />
            )}
          </Select>
        </FormControl>
        <Button
          sx={{ my: 1 }}
          onClick={() => {
            Swal.fire({
              title: 'Are you sure?',
              text: `Site Manager will be Updated`,
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Yes, Proceed!',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                assignManagerMutation.mutate({
                  sites: [site._id],
                  oldManager: site.manager._id,
                  manager
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                cache.invalidateQueries('potential-site-members');
              }
            });
          }}
        >
          Update
        </Button>
      </Stack>
    </Popover>
  );
}

function SitePayroll({ site }) {
  const { isLoading, isError, data: sitePayroll } = useSitePayroll(site);
  return (
    <Box>
      {!isLoading && !isError ? (
        <Stack
          sx={{ mt: 1, px: 1 }}
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={{ fontSize: 14 }}>
            Gross Amt. ₹ {sitePayroll?.data?.total_gross_salary}
          </Typography>
          <Typography sx={{ fontSize: 14 }}>Total EPF. ₹ {sitePayroll?.data?.total_pf}</Typography>
          <Typography sx={{ fontSize: 14 }}>Total ESI. ₹ {sitePayroll?.data?.total_esi}</Typography>
        </Stack>
      ) : null}
    </Box>
  );
}

function RosterOverview() {
  const navigate = useNavigate();
  const cache = useQueryClient();
  const theme = useTheme();
  const dispatch = useDispatch();
  // const today = new Date();
  const [open, setOpen] = useState(); // take id as input
  // const [, setCurrentMonth] = useState([]);
  const [imageSrc, setImageSrc] = useState();
  const [chooseBucket, setChooseBucket] = useState({});
  const [openAddBucket, setOpenAddBucket] = useState(false);
  const [openUpdateBucket, setOpenUpdateBucket] = useState(false);
  const [rosterMonth, setRosterMonth] = useState(new Date());
  const [anchorEl, setAnchorEl] = React.useState({
    id: null,
    target: null
  });
  const {
    // isAdminAuthenticated,
    info
  } = useSelector((state) => state.adminInfo);

  // const [expanded, setExpanded] = React.useState(false);
  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };
  // const [filter, setFilter] = useState({
  //   shift: ''
  // });
  // const [requirementExpanded, setRequirementExpanded] = React.useState();
  // const [shiftExpanded, setShiftExpanded] = React.useState();
  // const handleChange = (panel) => (event, newExpanded) => {
  //   setRequirementExpanded(newExpanded ? panel : true); // false
  // };
  // const handleShiftChange = (panel) => (event, newExpanded) => {
  //   setShiftExpanded(newExpanded ? panel : true); // false
  // };
  const [filterName, setFilterName] = React.useState('');
  const [search] = useDebounce(filterName, 1200); // Debounce value 1.2 sec
  const [requirement, setRequirement] = React.useState([]);
  // const [shifts, setShifts] = React.useState([]);
  const [chooseSelected, setChooseSelected] = React.useState([]);
  const [listView, setListView] = useState(false);

  useEffect(() => {
    console.log(requirement);
  }, [requirement]);

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // useEffect(() => {
  //   const mainAsync = async () => {
  //     const calendarDates = new CalendarDates();
  //     const mayDates = await calendarDates.getDates(rosterMonth);
  //     // const mayMatrix = await calendarDates.getMatrix(may2018);
  //     const newarray = [].concat(...Array(7).fill(DayOfWeeks));
  //     mayDates.map((date, index) => (date.day = newarray[index]));
  //     const currentMonthWeekdays = mayDates.filter((date) => date.type === 'current');
  //     setCurrentMonth([...currentMonthWeekdays]);
  //   };
  //   mainAsync();
  // }, [rosterMonth]);

  /* ===================== Sites ===================== */
  const {
    isLoading,
    isError,
    data: sites,
    fetchNextPage: fetchNextSites,
    hasMoreData: hasNextSites
    // refetch: refetchSites
  } = useSites(
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[0], true) : null, // from
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[1], true) : null, // to
    null, // filter.date.length > 0 ? filter.date : getCurrentDate(),
    null, // type
    null, // filter.state, // state
    null, // filter.status, // status
    null, // filter.manager, // manager
    // filter.role,
    search, // search
    null
  );

  const rows = useMemo(() => {
    const listOfSites = [];
    if (!isLoading && !isError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    console.log('Sites :', flattened);
    // const result = _.groupBy(flattened, (site) => site.manager._id);
    // console.log(result);
    // .filter((rw) => rw.bucket === undefined)
    return flattened;
  }, [sites, isLoading, isError]);

  const {
    isLoading: isBucketLoading,
    isError: isBucketError,
    data: buckets,
    fetchNextPage: fetchNextBuckets,
    hasMoreData: hasNextBuckets
    // refetch: refetchBuckets
  } = useBuckets();

  const AllBuckets = useMemo(() => {
    const listOfBuckets = [];
    if (!isBucketLoading && !isBucketError) {
      if (buckets?.pages?.length > 0) {
        buckets.pages.forEach((group) => listOfBuckets.push(group.buckets));
      }
    }
    const flattened = listOfBuckets.flat();
    // console.log('Buckets :', flattened);
    return flattened;
  }, [buckets, isBucketLoading, isBucketError]);

  const deleteSitesMutation = useMutationFunc(
    deleteSite,
    {
      onSuccess: {
        title: 'Successfully Deleted!'
      }
    },
    null
  );

  GetAvailability.propTypes = {
    site: PropTypes.any
  };

  function GetAvailability({ site }) {
    /* ========================= Get Month Availability ====================== */
    const {
      isLoading: isAvailabilityLoading,
      isError: isAvailabilityError,
      data: siteAvailability
    } = useMonthAvailability(site, convertUTCtoLocal(rosterMonth, true));

    const { GAPS } = useMemo(() => {
      // console.log('Site Availaility:', siteAvailability);
      // const out = {}; // Shift Availability , Gaps
      // let _Shifts = [];
      if (!isAvailabilityLoading && !isAvailabilityError) {
        if (siteAvailability) {
          return siteAvailability;
        }
      }
      return { sit: [], GAPS: [] };
    }, [siteAvailability, isAvailabilityLoading, isAvailabilityError]);

    /* ===================== Get Month availability ======================== */
    const mprogress = [];
    for (let i = 0; i < GAPS.length; i += 7) {
      mprogress.push(GAPS.slice(i, i + 7));
    }

    return (
      <Box sx={{ height: 235 }}>
        {/* <Stack
          sx={{
            // border: '1px solid #D5DBDF',
            boxShadow:
              'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
            // backgroundColor: '#5BA6D8',
            // color: 'white',
            borderRadius: 0.5,
            pt: 1,
            m: 1
            // cursor: 'pointer'
          }}
          // onClick={() => setRequirement([...requirement, row._id])}
        >
          <Stack sx={{ px: 1 }} direction="row" justifyContent="space-between">
            <Typography sx={{ display: 'flex', fontSize: 14, alignItems: 'center' }}>Availability ( {getCurrentDate()} )</Typography>
            <Typography sx={{ fontSize: 14 }}>
              {todayGap.available}&nbsp;/&nbsp;{todayGap.requirement}
            </Typography>
          </Stack>
          <Stack sx={{ position: 'relative', mt: 0.7 }}>
            <LinearProgress
              sx={{
                height: 18,
                bgcolor: 'white !important',
                borderTop: '1px solid #E7EBEE'
              }}
              color={todayGap.percentage === 100 ? 'success' : todayGap.percentage < 50 ? 'warning' : todayGap.percentage > 100 ? 'error' : todayGap.percentage > 50 && todayGap.percentage < 100 ? 'secondary' : 'info'}
              variant="determinate"
              value={Number(todayGap.percentage === Infinity ? 0 : parseFloat(todayGap.percentage).toFixed(2) ?? 0)}
            />
            <Typography sx={{ position: 'absolute', top: 1, ml: '46%', fontSize: 12 }} align="center">{todayGap.percentage === Infinity ? 0.00 : parseFloat(todayGap.percentage).toFixed(2) ?? 0}&nbsp;%</Typography>
          </Stack>
        </Stack> */}
        {isAvailabilityLoading ? (
          <NotAvailable type="loading" />
        ) : isAvailabilityError ? (
          <NotAvailable type="na" />
        ) : (
          <Stack
            sx={{
              borderTop: '2px solid #0094FF',
              px: 2,
              pt: 1,
              mx: 1,
              mt: 2.5,
              position: 'relative'
            }}
            direction="row"
            justifyContent="center"
          >
            <Typography
              sx={{ mb: 1, position: 'absolute', top: -12, ml: 1, backgroundColor: 'white', px: 1 }}
            >
              {Months[new Date(rosterMonth).getMonth()]},&nbsp;{new Date(rosterMonth).getFullYear()}
            </Typography>
            <Stack sx={{ mt: 1 }}>
              {mprogress.map((h, indx) => (
                // start
                <Stack key={`s-${indx}`} direction="row">
                  {h.map((obj, idx) => {
                    const p = obj.percentage === Infinity ? 0 : obj.percentage;
                    // console.log('Percentage:', obj.percentage);
                    const np = Number(p);
                    return (
                      <Tooltip
                        key={`p-${indx}-${idx}`}
                        title={`${obj.totalavl} / ${obj.totalreq} ( ${p} % )`}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ position: 'relative' }}
                        >
                          <Stack sx={{ position: 'absolute', zIndex: 1, cursor: 'mouse', p: 1 }}>
                            <Typography align="center" sx={{ fontSize: 10 }}>
                              {obj.day.substring(0, 3)}
                            </Typography>
                            <Typography
                              align="center"
                              sx={{
                                fontSize: 12,
                                color: `${
                                  np === 100
                                    ? theme.palette.primary.darker
                                    : np < 50
                                    ? theme.palette.warning.darker
                                    : np > 100
                                    ? theme.palette.error.darker
                                    : theme.palette.info.darker
                                }`,
                                fontWeight: 'bolder'
                              }}
                            >
                              {obj.date}
                            </Typography>
                          </Stack>
                          <LinearProgress
                            variant="determinate"
                            // value={Number(p > 100 ? 100 : p)}
                            value={Number(p > 0 || p > 100 ? 100 : 0)}
                            color={
                              np === 100
                                ? 'lightgreen'
                                : np < 50
                                ? 'lightyellow'
                                : np > 100
                                ? 'lightred'
                                : 'lightblue'
                            }
                            sx={{
                              height: 40,
                              width: 40,
                              // borderRadius: 0.5,
                              backgroundColor: 'white',
                              boxShadow:
                                'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                              border: `${
                                getCurrentDate() === obj.iso ? '2px solid #0897FF' : '' // 1px solid #E5EEEC
                              }`
                            }}
                          />
                        </Stack>
                      </Tooltip>
                    );
                  })}
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      </Box>
    );
  }

  /* ================== Delete Employee ========================== */
  const deleteBucketMutation = useMutation(deleteBucket, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Bucket Successfully Deleted`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('buckets');
      cache.invalidateQueries('sites');
    }
  });

  const { mutate: addBucketMutation } = useMutation(createBucket);
  const { mutate: updateBucketMutation } = useMutation(updateBucket, {});
  const [selected, setSelected] = useState([]);

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    initialValues: {
      name: '',
      profilePicture: '',
      sites: selected
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Bucket Name is required')
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9\s\-&.']*[a-zA-Z0-9]+$/,
          "Invalid bucket name. ( Minimum 2 characters, must start & end with alphanumeric characters, allowed special characters  - & . ' )"
        ),
      profilePicture: Yup.string().notRequired(),
      sites: Yup.array().required()
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        name: capitalizeEachWord(values.name),
        image: values.profilePicture,
        sites: selected.map((sl) => sl.split(',')[0])
      };

      console.log('Add Bucket Form :', data);
      setOpenAddBucket(false);
      addBucketMutation(data, {
        onMutate: () => {
          Swal.fire({
            icon: 'info',
            title: 'Hold on....',
            text: 'Adding new site 😃',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading();
            }
          });
        },
        onError: (error) => {
          setOpenAddBucket(false);
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          setOpenAddBucket(false);
          actions.setSubmitting(false);
          // document.getElementById('profilePicture').value = null;
          setSelected([]);
          resetForm();
          values.profilePicture = '';
          Swal.fire({
            icon: 'success',
            title: 'Bucket added!',
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          cache.invalidateQueries('buckets');
        }
      });
    }
  });

  const updateFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: chooseBucket?.name ?? '',
      profilePicture: chooseBucket?.image ?? '',
      sites: []
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Bucket Name is required')
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9\s\-&.']*[a-zA-Z0-9]+$/,
          "Invalid bucket name. ( Minimum 2 characters, must start & end with alphanumeric characters, allowed special characters  - & . ' )"
        ),
      profilePicture: Yup.string().notRequired(),
      sites: Yup.array().required()
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        id: chooseBucket._id,
        manager: info?._id,
        sites: chooseSelected.map((sl) => sl.split(',')[0])
      };
      if (values.profilePicture !== chooseBucket.image) {
        data.image = values.profilePicture;
      }
      if (values.name !== chooseBucket.name) {
        data.name = capitalizeEachWord(values.name);
      }

      // console.log('Update Bucket Form :', data);
      updateBucketMutation(data, {
        onMutate: () => {
          Swal.fire({
            icon: 'info',
            title: 'Hold on....',
            text: 'Update Bucket 😃',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading();
            }
          });
        },
        onError: (error) => {
          setOpenAddBucket(false);
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          setOpenUpdateBucket(false);
          actions.setSubmitting(false);
          document.getElementById('profileUpdatePicture').value = null;
          setSelected([]);
          updateResetForm();
          updateValues.profilePicture = '';
          Swal.fire({
            icon: 'success',
            title: 'Bucket updated!',
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          cache.invalidateQueries('buckets');
          cache.invalidateQueries('sites');
        }
      });
    }
  });

  const {
    errors,
    touched,
    values,
    // isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm
  } = formik;

  const {
    errors: updateErrors,
    touched: updateTouched,
    values: updateValues,
    // isSubmitting,
    handleSubmit: handleUpdateSubmit,
    getFieldProps: getUpdateFieldProps,
    resetForm: updateResetForm
  } = updateFormik;

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const RemoveSite = (event, name) => {
    const selectedIndex = chooseSelected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(chooseSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(chooseSelected.slice(1));
    } else if (selectedIndex === chooseSelected.length - 1) {
      newSelected = newSelected.concat(chooseSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        chooseSelected.slice(0, selectedIndex),
        chooseSelected.slice(selectedIndex + 1)
      );
    }
    setChooseSelected(newSelected);
  };

  return (
    <Page title="Roster | Trackkar">
      <Stack
        sx={{ mt: 1, px: 1 }}
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchStyle
          value={filterName}
          onChange={handleFilterByName}
          placeholder="Search Sites..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
        <Stack direction="row" alignItems="center">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiDatePicker
              views={['year', 'month']}
              // label="Year and Month"
              minDate={new Date('2012-03-01')}
              // maxDate={new Date('2023-06-01')}
              value={rosterMonth}
              onChange={(newValue) => {
                setRosterMonth(newValue);
              }}
              renderInput={(params) => <TextField {...params} helperText={null} />}
            />
          </LocalizationProvider>
          &nbsp;&nbsp;
          <ProgressIndicators />
          &nbsp;
          <IconButton>
            {listView ? (
              <GridViewIcon onClick={() => setListView(false)} />
            ) : (
              <FormatListBulletedIcon onClick={() => setListView(true)} />
            )}
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="right" sx={{ p: 1 }}>
        {(info.isMainAdmin || info?.role?.is_site_manager) && (
          <>
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setAnchorEl({ id: null, target: null });
                setOpen('add-site-modal');
              }}
            >
              Site
            </Button>
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => setOpen('add-holidays-modal')}
            >
              Holiday
            </Button>
          </>
        )}
        {(info?.role?.is_site_manager || info?.isMainAdmin) && (
          <>
            {selected.length > 0 && (
              <Button
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => setOpenAddBucket(true)}
              >
                Site Group
              </Button>
            )}
            {selected.length === 0 && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => setOpen('clear-roster-modal')}
              >
                Clear Roster
              </Button>
            )}
          </>
        )}
      </Stack>
      <Box
        className="custom_scrollbar"
        sx={{
          flexGrow: 1,
          mt: 0.5,
          pb: 1,
          px: 1,
          overflowY: 'scroll',
          height: 'calc(100vh - 210px)'
        }}
      >
        {!isBucketLoading && AllBuckets?.length > 0 && (
          <Typography sx={{ fontWeight: 'bold' }}>Site Groups ( {AllBuckets?.length} )</Typography>
        )}
        <Grid
          id="buckets-grid-container"
          container
          spacing={2}
          sx={{ mt: 0.4, pb: 2, px: 2, maxHeight: 600, overflowY: 'auto' }}
          className="custom_scrollbar"
        >
          {isLoading && (
            <Stack
              sx={{ height: 450, width: 'inherit' }}
              justifyContent="center"
              alignItems="center"
            >
              <NotAvailable type="loading" />
            </Stack>
          )}
          {isError && <NotAvailable type="na" msg="Something Went Wrong" />}
          {!isBucketLoading &&
            !isBucketError &&
            (AllBuckets.length > 0
              ? AllBuckets.map((buk) => (
                  <Grid key={buk._id} item xs={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        className="custom_card"
                        sx={{
                          border: '1px solid #E3E8EC',
                          borderRadius: 2,
                          my: 0,
                          boxShadow: theme.customShadows.bloated,
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                        id="panel1a-header"
                      >
                        <CardHeader
                          sx={{ textAlign: 'left', p: 0 }}
                          avatar={
                            <Avatar
                              onClick={() => setImageSrc(buk.image)}
                              sx={{ cursor: 'pointer' }}
                              alt={buk.name}
                              src={buk.image}
                            />
                          }
                          title={
                            <Tooltip placement="top" title={buk.name}>
                              <Typography variant="h6">
                                {buk.name.length > 12
                                  ? `${buk.name.substring(0, 12).toUpperCase()}...`
                                  : buk.name}
                              </Typography>
                            </Tooltip>
                          }
                          subheader={
                            <Stack
                              direction="row"
                              // justifyContent="center"
                              alignItems="center"
                            >
                              <Typography sx={{ fontSize: 14 }}>
                                <b>Sites:</b> {buk.sites.length}
                              </Typography>
                            </Stack>
                          }
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack
                          direction="row"
                          sx={{ p: 1 }}
                          spacing={1}
                          justifyContent="right"
                          alignItems="center"
                        >
                          <Button
                            startIcon={<SyncIcon />}
                            variant="contained"
                            onClick={() => {
                              setChooseBucket({ ...buk });
                              setOpenUpdateBucket(true);
                              const sites = buk?.sites.map((st) => `${st._id},${st.name}`);
                              const sel = Array.prototype.concat(sites, selected);
                              setChooseSelected([...sel]);
                            }}
                          >
                            Site Group
                          </Button>
                          <Button
                            startIcon={<DeleteIcon />}
                            variant="contained"
                            color="error"
                            onClick={() => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: "This option will delete the bucket, sites contained will not be deleted. You won't be able to revert this!",
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, Proceed!',
                                allowOutsideClick: false
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteBucketMutation.mutate({
                                    id: buk._id
                                  });
                                } else if (result.dismiss === Swal.DismissReason.cancel) {
                                  cache.invalidateQueries('buckets');
                                }
                              });
                            }}
                          >
                            Delete
                          </Button>
                          <Button
                            startIcon={<DeleteSweepIcon />}
                            variant="contained"
                            color="error"
                            onClick={() => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: "This option will delete the bucket and sites contained within. You won't be able to revert this!",
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, Proceed!',
                                allowOutsideClick: false
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteBucketMutation.mutate({
                                    id: buk._id,
                                    shouldDeleteSites: true
                                  });
                                } else if (result.dismiss === Swal.DismissReason.cancel) {
                                  cache.invalidateQueries('buckets');
                                }
                              });
                            }}
                          >
                            Delete All
                          </Button>
                        </Stack>
                        {buk.sites.map((row) => (
                          <Card
                            className="custom_card"
                            key={row._id}
                            sx={{
                              border: '1px solid #E3E8EC',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              pb: 1,
                              my: 1
                            }}
                          >
                            <CardHeader
                              sx={{ textAlign: 'left', pt: 1, px: 2 }}
                              avatar={
                                <Box display="flex">
                                  <Avatar
                                    onClick={() => setImageSrc(row.image)}
                                    sx={{ cursor: 'pointer' }}
                                    alt={row.name}
                                    src={row.image}
                                  />
                                </Box>
                              }
                              title={
                                <Tooltip placement="top" title={row.name}>
                                  <Typography variant="h6">
                                    {row.name.length > 12
                                      ? `${row.name.substring(0, 12).toUpperCase()}...`
                                      : row.name}
                                  </Typography>
                                </Tooltip>
                              }
                              subheader={
                                <Stack
                                  direction="row"
                                  // justifyContent="center"
                                  alignItems="center"
                                >
                                  <Typography sx={{ fontSize: 14 }}>
                                    Manager : <b>{row.manager.name}</b>
                                    &nbsp;
                                    {info.isMainAdmin ||
                                    info?.role?.priority < row?.manager?.role?.priority ? (
                                      <Tooltip title="Update Site Manager">
                                        <EditIcon
                                          onClick={(e) =>
                                            setAnchorEl({
                                              ...anchorEl,
                                              id: `update_site_manager_${row._id}`,
                                              target: e.currentTarget
                                            })
                                          }
                                          sx={{ fontSize: 14, mt: 0.5, cursor: 'pointer' }}
                                        />
                                      </Tooltip>
                                    ) : null}
                                  </Typography>
                                  <UpdateSiteManagers
                                    site={row}
                                    anchorEl={anchorEl}
                                    setAnchorEl={setAnchorEl}
                                  />
                                </Stack>
                              }
                            />
                            <Stack direction="row" justifyContent="space-between" spacing={1}>
                              <Typography
                                sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}
                              >
                                <PeopleIcon sx={{ color: theme.palette.text.secondary }} />
                                &nbsp;Employees ( {row.total_employees} )
                              </Typography>
                              <Typography
                                sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}
                              >
                                <StoreIcon sx={{ color: theme.palette.text.secondary }} />
                                &nbsp;Shifts ( {row.shifts.length} )
                              </Typography>
                            </Stack>
                            <Box display="flex">
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/roster/app/:${row._id}/:${convertUTCtoLocal(
                                      rosterMonth,
                                      true
                                    )}`
                                  );
                                }}
                              >
                                Go to Roster <ArrowForwardIosSharpIcon sx={{ fontSize: 14 }} />
                              </Button>
                            </Box>
                            <Stack direction="row" spacing={1} sx={{ pr: 1 }}>
                              <Tooltip title="View Requirement">
                                <IconButton
                                  onClick={(e) => {
                                    setAnchorEl({
                                      ...anchorEl,
                                      id: `view_calendar_${row._id}`,
                                      target: e.currentTarget
                                    });
                                  }}
                                >
                                  <CalendarMonthIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="View Attendance">
                                <IconButton
                                  onClick={() => {
                                    navigate(`/attendance/site/${row?._id}`);
                                  }}
                                >
                                  <AnalyticsIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Share Roster">
                                <IconButton
                                  component={RouterLink}
                                  to={`/sharing/${row._id}/${convertUTCtoLocal(rosterMonth, true)}`}
                                  target="_blank"
                                  aria-label="share"
                                >
                                  <ShareIcon sx={{ fontSize: 20 }} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete Site">
                                <IconButton
                                  sx={{ color: 'red' }}
                                  onClick={() => {
                                    setAnchorEl({ ...anchorEl, id: null, target: null });
                                    Swal.fire({
                                      title: 'Are you sure to delete this site ?',
                                      text: "You won't be able to revert this!",
                                      icon: 'question',
                                      showCancelButton: true,
                                      confirmButtonText: 'Yes, Proceed!',
                                      allowOutsideClick: false
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        setAnchorEl({ ...anchorEl, id: null, target: null });
                                        deleteSitesMutation({
                                          sites: [row?._id]
                                        });
                                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                                        cache.invalidateQueries('sites');
                                      }
                                    });
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                            <Menu
                              id={`view_calendar_${row._id}`}
                              open={anchorEl.id === `view_calendar_${row._id}`}
                              anchorEl={anchorEl.target}
                              onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button'
                              }}
                            >
                              <MenuItem
                                sx={{
                                  width: 350,
                                  height: 253,
                                  display: 'flex',
                                  justifyContent: 'center'
                                }}
                              >
                                <GetAvailability site={row._id} />
                              </MenuItem>
                            </Menu>
                          </Card>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))
              : null)}
          {/* Put the scroll bar always on the bottom */}
          <InfiniteScroll
            dataLength={AllBuckets?.length}
            next={fetchNextBuckets}
            // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
            // inverse //
            hasMore={hasNextBuckets}
            loader={
              <Stack direction="row" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Stack>
            }
            scrollableTarget="buckets-grid-container"
            // endMessage={
            //   <p style={{ textAlign: 'center' }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
          />
        </Grid>
        <Box>
          <Typography sx={{ fontWeight: 'bold' }}>
            Sites ( {rows.filter((rw) => rw.bucket === undefined).length} )
          </Typography>
        </Box>
        <Grid
          id="sites-grid-container"
          container
          spacing={2}
          sx={{ pb: 2, mt: 1, px: 2, maxHeight: 600, overflowY: 'auto' }}
          className="custom_scrollbar"
        >
          {!isLoading &&
            !isError &&
            (rows.length > 0 ? (
              rows
                .filter((rw) => rw.bucket === undefined)
                .sort((a, b) => b.shifts.length - a.shifts.length && a.name - b.name)
                .map((row) => {
                  const isItemSelected = selected.indexOf(`${row._id},${row.name}`) !== -1;
                  // const reqp = row?.total_requirement?.length > 0 ? Math.floor((100 *
                  //   (row.employees.reduce((s, obj) => s + (obj.role._id !== row.manager.role._id ? obj.total : 0), 0))) /
                  //   row.total_requirement.reduce((s, obj) => s + obj.total, 0)) : 0;
                  return listView ? (
                    <Grid key={row._id} item xs={12}>
                      <Card
                        className="custom_card"
                        sx={{
                          border: '1px solid #E3E8EC',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          pb: 1
                        }}
                      >
                        <CardHeader
                          sx={{ textAlign: 'left', pt: 1, px: 2 }}
                          avatar={
                            <Box display="flex">
                              {(info?.role?.is_site_manager || info?.isMainAdmin) && (
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, `${row._id},${row.name}`)}
                                />
                              )}
                              &nbsp;
                              <Avatar
                                onClick={() => setImageSrc(row.image)}
                                sx={{ cursor: 'pointer' }}
                                alt={row.name}
                                src={row.image}
                              />
                            </Box>
                          }
                          title={
                            <Tooltip placement="top" title={row.name}>
                              <Typography variant="h6">
                                {row.name.length > 12
                                  ? `${row.name.substring(0, 12).toUpperCase()}...`
                                  : row.name}
                              </Typography>
                            </Tooltip>
                          }
                          subheader={
                            <Stack
                              direction="row"
                              // justifyContent="center"
                              alignItems="center"
                            >
                              <Typography sx={{ fontSize: 14 }}>
                                Manager : <b>{row.manager.name}</b>
                                &nbsp;
                                {info.isMainAdmin ||
                                info?.role?.priority < row?.manager?.role?.priority ? (
                                  <Tooltip title="Update Site Manager">
                                    <EditIcon
                                      onClick={(e) =>
                                        setAnchorEl({
                                          ...anchorEl,
                                          id: `update_site_manager_${row._id}`,
                                          target: e.currentTarget
                                        })
                                      }
                                      sx={{ fontSize: 14, mt: 0.5, cursor: 'pointer' }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </Typography>
                              <UpdateSiteManagers
                                site={row}
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                              />
                            </Stack>
                          }
                        />
                        <Stack direction="row" justifyContent="space-between" spacing={1}>
                          <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
                            <PeopleIcon sx={{ color: theme.palette.text.secondary }} />
                            &nbsp;Employees ( {row.total_employees} )
                          </Typography>
                          <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
                            <StoreIcon sx={{ color: theme.palette.text.secondary }} />
                            &nbsp;Shifts ( {row.shifts.length} )
                          </Typography>
                        </Stack>
                        <Box display="flex">
                          <Button
                            onClick={() => {
                              navigate(
                                `/roster/app/:${row._id}/:${convertUTCtoLocal(rosterMonth, true)}`
                              );
                            }}
                          >
                            Go to Roster <ArrowForwardIosSharpIcon sx={{ fontSize: 14 }} />
                          </Button>
                        </Box>
                        <Stack direction="row" spacing={1} sx={{ pr: 1 }}>
                          <Tooltip title="View Requirement">
                            <IconButton
                              onClick={(e) => {
                                setAnchorEl({
                                  ...anchorEl,
                                  id: `view_calendar_${row._id}`,
                                  target: e.currentTarget
                                });
                              }}
                            >
                              <CalendarMonthIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="View Attendance">
                            <IconButton
                              onClick={() => {
                                navigate(`/attendance/site/${row?._id}`);
                              }}
                            >
                              <AnalyticsIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Share Roster">
                            <IconButton
                              component={RouterLink}
                              to={`/sharing/${row._id}/${convertUTCtoLocal(rosterMonth, true)}`}
                              target="_blank"
                              aria-label="share"
                            >
                              <ShareIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Delete Site">
                            <IconButton
                              sx={{ color: 'red' }}
                              onClick={() => {
                                setAnchorEl({ ...anchorEl, id: null, target: null });
                                Swal.fire({
                                  title: 'Are you sure to delete this site ?',
                                  text: "You won't be able to revert this!",
                                  icon: 'question',
                                  showCancelButton: true,
                                  confirmButtonText: 'Yes, Proceed!',
                                  allowOutsideClick: false
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    setAnchorEl({ ...anchorEl, id: null, target: null });
                                    deleteSitesMutation({
                                      sites: [row?._id]
                                    });
                                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                                    cache.invalidateQueries('sites');
                                  }
                                });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                        <Menu
                          id={`view_calendar_${row._id}`}
                          open={anchorEl.id === `view_calendar_${row._id}`}
                          anchorEl={anchorEl.target}
                          onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button'
                          }}
                        >
                          <MenuItem
                            sx={{
                              width: 350,
                              height: 253,
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <GetAvailability site={row._id} />
                          </MenuItem>
                        </Menu>
                      </Card>
                    </Grid>
                  ) : (
                    <Grid key={row._id} item xs={12} sm={6} md={6}>
                      <Card className="custom_card" sx={{ border: '1px solid #E3E8EC' }}>
                        <CardHeader
                          sx={{ textAlign: 'left', pt: 2, px: 2 }}
                          avatar={
                            <Avatar
                              onClick={() => setImageSrc(row.image)}
                              sx={{ cursor: 'pointer' }}
                              alt={row.name}
                              src={row.image}
                            />
                          }
                          action={
                            <IconButton
                              sx={{ color: 'red' }}
                              onClick={() => {
                                setAnchorEl({ ...anchorEl, id: null, target: null });
                                Swal.fire({
                                  title: 'Are you sure to delete this site ?',
                                  text: "You won't be able to revert this!",
                                  icon: 'question',
                                  showCancelButton: true,
                                  confirmButtonText: 'Yes, Proceed!',
                                  allowOutsideClick: false
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    setAnchorEl({ ...anchorEl, id: null, target: null });
                                    deleteSitesMutation({
                                      sites: [row?._id]
                                    });
                                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                                    cache.invalidateQueries('sites');
                                  }
                                });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                          title={
                            <Tooltip placement="top" title={row.name}>
                              <Typography variant="h6">
                                {row.name.length > 12
                                  ? `${row.name.substring(0, 12).toUpperCase()}...`
                                  : row.name}
                              </Typography>
                            </Tooltip>
                          }
                          subheader={
                            <Stack
                              direction="row"
                              // justifyContent="center"
                              alignItems="center"
                            >
                              <Typography sx={{ fontSize: 14 }}>
                                Manager : <b>{row.manager.name}</b>
                                &nbsp;
                                {info.isMainAdmin ||
                                info?.role?.priority < row?.manager?.role?.priority ? (
                                  <Tooltip title="Update Site Manager">
                                    <EditIcon
                                      onClick={(e) =>
                                        setAnchorEl({
                                          ...anchorEl,
                                          id: `update_site_manager_${row._id}`,
                                          target: e.currentTarget
                                        })
                                      }
                                      sx={{ fontSize: 14, mt: 0.5, cursor: 'pointer' }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </Typography>
                              <UpdateSiteManagers
                                site={row}
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                              />
                            </Stack>
                          }
                        />
                        <Stack
                          sx={{ mt: 1.5, px: 1 }}
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
                            <PeopleIcon sx={{ color: theme.palette.text.secondary }} />
                            &nbsp;Employees ( {row.total_employees} )
                          </Typography>
                          <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
                            <StoreIcon sx={{ color: theme.palette.text.secondary }} />
                            &nbsp;Shifts ( {row.shifts.length} )
                          </Typography>
                        </Stack>
                        <SitePayroll site={row._id} />
                        <GetAvailability site={row._id} />
                        <CardActions
                          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                          disableSpacing
                        >
                          <Stack>
                            {/* <IconButton aria-label="add to favorites">
                          <StarIcon />
                          </IconButton> */}
                            <IconButton
                              component={RouterLink}
                              to={`/sharing/${row._id}/${convertUTCtoLocal(rosterMonth, true)}`}
                              target="_blank"
                              aria-label="share"
                            >
                              <ShareIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                          </Stack>
                          <Button
                            onClick={() => {
                              navigate(`/attendance/site/${row?._id}`);
                            }}
                          >
                            Attendance
                          </Button>
                          <Button
                            onClick={() => {
                              navigate(
                                `/roster/app/:${row._id}/:${convertUTCtoLocal(rosterMonth, true)}`
                              );
                            }}
                          >
                            Go to Roster <ArrowForwardIosSharpIcon sx={{ fontSize: 14 }} />
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })
            ) : info?.isMainAdmin && rows.length === 0 ? (
              <Grid item xs={12} sm={12} md={12}>
                <Stack
                  sx={{ height: 300 }}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack>
                    <NotAvailable type="na" msg="No Sites available, Please add a Site" />
                    <Stack direction="row" justifyContent="center">
                      <Button onClick={() => setOpen('add-site-modal')} variant="contained">
                        Add Site
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            ) : (
              <NotAvailable type="na" msg="No Sites Available" />
            ))}
          {/* Put the scroll bar always on the bottom */}
          <InfiniteScroll
            dataLength={rows?.length}
            next={fetchNextSites}
            // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
            // inverse //
            hasMore={hasNextSites}
            loader={
              <Stack direction="row" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Stack>
            }
            scrollableTarget="sites-grid-container"
            // endMessage={
            //   <p style={{ textAlign: 'center' }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
          />
        </Grid>
      </Box>
      {open === 'add-site-modal' && (
        <AddSite open={open === 'add-site-modal'} onClose={() => setOpen('')} />
      )}
      {open === 'clear-roster-modal' && (
        <ClearRoster
          open={open === 'clear-roster-modal'}
          onClose={() => setOpen('')}
          rosterMonth={rosterMonth}
        />
      )}
      {open === 'add-holidays-modal' && (
        <AddHoliday
          open={open === 'add-holidays-modal'}
          onClose={() => setOpen('')}
          rosterMonth={rosterMonth}
        />
      )}
      <ImageZoom src={imageSrc} onClose={() => setImageSrc(null)} />
      <Modal
        open={openAddBucket}
        onClose={() => setOpenAddBucket(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{
              p: 1,
              backgroundColor: '#2a2a72',
              backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
            }}
          >
            <Typography
              id="modal-modal-title"
              color="white"
              variant="h6"
              sx={{ ml: 2 }}
              component="h2"
            >
              Add Site Group
            </Typography>
            <IconButton
              onClick={() => setOpenAddBucket(false)}
              sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <FormikProvider value={formik}>
            <Form id="add-employee-form" autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack
                className="custom_scrollbar"
                spacing={1}
                height="calc(100vh - 135px)"
                sx={{ px: 2, py: 1, overflowY: 'scroll' }}
              >
                <Stack spacing={2}>
                  {/* Profile Picture */}
                  <Box sx={{ display: 'flex', mt: 1, alignItems: 'flex-end' }}>
                    <InsertPhotoIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <Stack>
                      <InputLabel
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}
                        variant="standard"
                        htmlFor="profilePicture"
                      >
                        <Button variant="raised" component="span">
                          {!values.profilePicture && <AddIcon />} Group Picture
                        </Button>
                        &nbsp;
                        {values.profilePicture && <CheckCircleIcon sx={{ color: 'green' }} />}
                      </InputLabel>
                      {errors.profilePicture && (
                        <Typography sx={{ color: 'red', fontSize: 12 }}>
                          {errors.profilePicture}
                        </Typography>
                      )}
                    </Stack>
                    <TextField
                      variant="standard"
                      sx={{ display: 'none' }}
                      fullWidth
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      id="profilePicture"
                      name="profilePicture"
                      // eslint-disable-next-line prefer-destructuring
                      onChange={async (event) => {
                        values.profilePicture = await getBase64(event.currentTarget.files[0]);
                        if (values.profilePicture) {
                          delete errors.profilePicture;
                        }
                      }}
                    />
                  </Box>
                  {/* Name */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    {/* <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Group Name"
                      autoComplete="off"
                      required
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Box>
                  <Box>
                    <Typography sx={{ my: 2 }}>Sites</Typography>
                    {selected.map((sel) => (
                      <Card sx={{ p: 2 }} key={`bucket-${sel.split(',')[0]}`}>
                        {sel.split(',')[1]}
                      </Card>
                    ))}
                  </Box>
                </Stack>
              </Stack>
              <Stack
                sx={{ position: 'absolute', backgroundColor: 'white', bottom: 0, px: 2, pb: 1.2 }}
              >
                <Button
                  onClick={() => {
                    setOpenAddBucket(false);
                    Swal.fire({
                      title: 'Are you sure?',
                      text: `Selected sites will be added to group "${values.name}"`,
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: 'Yes, Proceed!',
                      allowOutsideClick: false
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleSubmit();
                      }
                    });
                  }}
                  variant="contained"
                >
                  Create
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
      <Modal
        open={openUpdateBucket}
        onClose={() => setOpenUpdateBucket(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{
              p: 1,
              backgroundColor: '#2a2a72',
              backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
            }}
          >
            <Typography
              id="modal-modal-title"
              color="white"
              variant="h6"
              sx={{ ml: 2 }}
              component="h2"
            >
              Update Site Group
            </Typography>
            <IconButton
              onClick={() => setOpenUpdateBucket(false)}
              sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <FormikProvider value={updateFormik}>
            <Form
              id="update-bucket-form"
              autoComplete="off"
              noValidate
              onSubmit={handleUpdateSubmit}
            >
              <Stack
                className="custom_scrollbar"
                spacing={1}
                height="calc(100vh - 135px)"
                sx={{ px: 2, py: 1, overflowY: 'scroll' }}
              >
                <Stack spacing={2}>
                  {/* Profile Picture */}
                  <Box sx={{ display: 'flex', mt: 1, alignItems: 'flex-end' }}>
                    <InsertPhotoIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <Stack>
                      <InputLabel
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}
                        variant="standard"
                        htmlFor="profilePicture"
                      >
                        <Button variant="raised" component="span">
                          {!updateValues.profilePicture && <AddIcon />} Group Picture
                        </Button>
                        &nbsp;
                        {updateValues.profilePicture && <CheckCircleIcon sx={{ color: 'green' }} />}
                      </InputLabel>
                      {updateErrors.profilePicture && (
                        <Typography sx={{ color: 'red', fontSize: 12 }}>
                          {updateErrors.profilePicture}
                        </Typography>
                      )}
                    </Stack>
                    <TextField
                      variant="standard"
                      sx={{ display: 'none' }}
                      fullWidth
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      id="profileUpdatePicture"
                      name="profilePicture"
                      // eslint-disable-next-line prefer-destructuring
                      onChange={async (event) => {
                        updateValues.profilePicture = await getBase64(event.currentTarget.files[0]);
                        if (updateValues.profilePicture) {
                          delete updateErrors.profilePicture;
                        }
                      }}
                    />
                  </Box>
                  {/* Name */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    {/* <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Group Name"
                      autoComplete="off"
                      required
                      {...getUpdateFieldProps('name')}
                      error={Boolean(updateTouched.name && updateErrors.name)}
                      helperText={updateTouched.name && updateErrors.name}
                    />
                  </Box>
                  <Box>
                    <Typography sx={{ my: 2 }}>Sites</Typography>
                    {chooseSelected.map((sel) => (
                      <Card
                        sx={{
                          p: 2,
                          my: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                        key={`bucket-${sel.split(',')[0]}`}
                      >
                        <Typography>{sel.split(',')[1]}</Typography>
                        <IconButton size="small" onClick={(e) => RemoveSite(e, sel)}>
                          <DeleteIcon color="error" fontSize="inherit" />
                        </IconButton>
                      </Card>
                    ))}
                  </Box>
                </Stack>
              </Stack>
              <Stack
                sx={{ position: 'absolute', backgroundColor: 'white', bottom: 0, px: 2, pb: 1.2 }}
              >
                <Button type="submit" variant="contained">
                  Update
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </Page>
  );
}

export default React.memo(RosterOverview);
