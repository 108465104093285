/* eslint-disable no-nested-ternary */
import '../index.css';
import * as React from 'react';
// import * as Yup from 'yup';
import clsx from 'clsx';
import { useDebounce } from 'use-debounce';
import { useQueryClient } from 'react-query';
// import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2/src/sweetalert2';
import InfiniteScroll from 'react-infinite-scroll-component';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
import {
  Modal,
  Typography,
  Box,
  Stack,
  IconButton,
  Checkbox,
  Button,
  Divider,
  Alert,
  OutlinedInput,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { TreeView, TreeItem } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useTreeItem } from '@mui/lab/TreeItem';
// import { useTheme } from '@mui/material/styles';
// icons
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { clearRoster } from '../api/mutations/adminMutations';
import useMutationFunc from '../api/mutations/mutation';
// config
import {
  ADD_EMPLOYEE
  // COMPANY_PLACEHOLDER_LOGO
} from '../utils/config';
// hooks
import { useSiteShifts } from '../hooks/roster';
// utils
import { getCurrentDate, convertUTCtoLocal } from '../utils/functions';
import NotAvailable from './NotAvailable';
// redux
// import { logOut } from '../redux/actions/adminActions';
// import INDIAN from '../../../utils/INDIAN_STATES.json'; // importing indian states and uts

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  margin: '0.5rem',
  height: 40,
  // transition: theme.transitions.create(['box-shadow', 'width'], {
  //   easing: theme.transitions.easing.easeInOut,
  //   duration: theme.transitions.duration.shorter
  // }),
  // '&.Mui-focused': { width: 300, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250
//     }
//   }
// };

const CustomContent = React.forwardRef((props, ref) => {
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

CustomContent.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * className applied to the root element.
   */
  className: PropTypes.string,
  /**
   * The icon to display next to the tree node's label. Either a parent or end icon.
   */
  displayIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label. Either an expansion or collapse icon.
   */
  expansionIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label.
   */
  icon: PropTypes.node,
  /**
   * The tree node label.
   */
  label: PropTypes.node,
  /**
   * The id of the node.
   */
  nodeId: PropTypes.string.isRequired
};

const CustomTreeItem = (props) => <TreeItem ContentComponent={CustomContent} {...props} />;

function ClearRoster({ open, onClose, site = null, rosterMonth }) {
  // const dispatch = useDispatch();
  const cache = useQueryClient();
  const [siteSelected, setSiteSelected] = React.useState([]);
  const [shiftSelected, setShiftSelected] = React.useState([]);
  const [filterName, setFilterName] = React.useState('');
  const [search] = useDebounce(filterName, 1500);
  const [error, setError] = React.useState('');
  const {
    isLoading: isSiteShiftsLoading,
    isError: isSiteShiftsError,
    fetchNextPage: fetchNextSiteShifts,
    hasNextPage: hasNextSiteShifts,
    data: siteShifts
  } = useSiteShifts(null, null, search, getCurrentDate(), site);
  const SITE_SHIFTS = React.useMemo(() => {
    const listOfSites = [];
    if (!isSiteShiftsLoading && !isSiteShiftsError) {
      // console.log('Clear Roster List of Sites:', siteShifts);
      if (siteShifts?.pages?.length > 0) {
        siteShifts.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    return listOfSites.flat();
  }, [siteShifts, isSiteShiftsLoading, isSiteShiftsError]);

  const handleSiteClick = (event, name) => {
    const selectedIndex = siteSelected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(siteSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(siteSelected.slice(1));
    } else if (selectedIndex === siteSelected.length - 1) {
      newSelected = newSelected.concat(siteSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        siteSelected.slice(0, selectedIndex),
        siteSelected.slice(selectedIndex + 1)
      );
    }
    setSiteSelected(newSelected);
  };

  const handleSiteAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = SITE_SHIFTS.map((n) => n._id);
      setSiteSelected(newSelecteds);
      return;
    }
    setSiteSelected([]);
  };

  // const handleShiftAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = SITE_SHIFTS[0].shifts.map((n) => n._id);
  //     setShiftSelected(newSelecteds);
  //     return;
  //   }
  //   setShiftSelected([]);
  // };

  const handleShiftClick = (event, name) => {
    const selectedIndex = shiftSelected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(shiftSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(shiftSelected.slice(1));
    } else if (selectedIndex === shiftSelected.length - 1) {
      newSelected = newSelected.concat(shiftSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        shiftSelected.slice(0, selectedIndex),
        shiftSelected.slice(selectedIndex + 1)
      );
    }
    setShiftSelected(newSelected);
  };

  const clearRosterMutation = useMutationFunc(
    clearRoster,
    {
      onSuccess: {
        title: 'Successfully Cleared!!'
      }
    },
    () => {
      onClose();
    },
    () => {
      cache.invalidateQueries('sites');
      cache.invalidateQueries('roster');
      cache.invalidateQueries('shifts');
      cache.invalidateQueries('roster-availability');
    },
    null,
    null
  );

  return (
    <Modal
      open={open ?? false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 1,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          <img src={ADD_EMPLOYEE} width="40" height="40" alt="edit_icon" />
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            Clear Roster
          </Typography>
          <IconButton
            onClick={() => onClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>{' '}
        <Stack
          id="site-shifts-container"
          className="custom_scrollbar"
          sx={{ position: 'relative', height: 'calc(100vh - 1rem)', paddingBottom: 2 }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography sx={{ p: 2, fontWeight: 'bold' }}>
              {site ? 'All Shifts' : 'All Sites'}
            </Typography>
            {/* <Checkbox onChange={(e) => handleShiftAllClick(e)} /> */}
            {!site && <Checkbox onChange={(e) => handleSiteAllClick(e)} />}
          </Stack>
          <Divider />
          <SearchStyle
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            placeholder="Search Sites..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          {error && <Alert severity="error">{error}</Alert>}
          <TreeView
            aria-label="clear roster navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={Array.from({ length: SITE_SHIFTS?.length }, (v, i) => `${i}`)}
            sx={{ flexGrow: 1, maxWidth: 400, pt: 2, overflowY: 'auto' }}
          >
            {isSiteShiftsLoading ? (
              <NotAvailable type="loading" msg="Loading..." />
            ) : isSiteShiftsError ? (
              <NotAvailable type="na" msg="Something Went Wrong!" />
            ) : !isSiteShiftsLoading && SITE_SHIFTS?.length > 0 ? (
              SITE_SHIFTS.map((row, indx) => (
                <CustomTreeItem
                  nodeId={`${indx}`}
                  key={`clear-roster-${row._id}`}
                  label={
                    <Typography>
                      {site === null && (
                        <Checkbox
                          checked={siteSelected.indexOf(row?._id) !== -1}
                          onChange={(e) => handleSiteClick(e, row._id)}
                        />
                      )}
                      {row.name}
                    </Typography>
                  }
                >
                  {site &&
                    row.shifts.map((shift, inx) => (
                      <CustomTreeItem
                        key={`clear-roster-${shift._id}`}
                        nodeId={`${indx}${inx}`}
                        label={
                          <Typography>
                            <Checkbox
                              checked={shiftSelected.indexOf(shift?._id) !== -1}
                              onChange={(e) => handleShiftClick(e, shift._id)}
                            />{' '}
                            {shift.name}
                          </Typography>
                        }
                      />
                    ))}
                </CustomTreeItem>
              ))
            ) : (
              <NotAvailable type="na" msg="No Data Available" />
            )}
          </TreeView>
          <Box sx={{ m: 1, position: 'fixed', bottom: 0 }}>
            {site ? (
              <Stack direction="row" spacing={1}>
                {shiftSelected.length > 0 && (
                  <Button
                    onClick={() => {
                      Swal.fire({
                        title: 'Are you sure?',
                        text: `Clear roster for rest of the month!`,
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, Proceed!',
                        allowOutsideClick: false
                      }).then((result) => {
                        if (result.isConfirmed) {
                          clearRosterMutation({
                            shifts: shiftSelected,
                            sites: [],
                            today: getCurrentDate(),
                            date: convertUTCtoLocal(rosterMonth, true)
                          });
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                          cache.invalidateQueries();
                        }
                      });
                    }}
                    sx={{ width: '100%' }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
                <Button
                  onClick={() => {
                    onClose();
                    Swal.fire({
                      title: 'Are you sure?',
                      text: `Clear roster for rest of the month!`,
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: 'Yes, Proceed!',
                      allowOutsideClick: false
                    }).then((result) => {
                      if (result.isConfirmed) {
                        clearRosterMutation({
                          sites: [site],
                          shifts: [],
                          today: getCurrentDate(),
                          date: convertUTCtoLocal(rosterMonth, true)
                        });
                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                        cache.invalidateQueries();
                      }
                    });
                  }}
                  sx={{ width: '100%' }}
                  variant="contained"
                >
                  Complete Site
                </Button>
              </Stack>
            ) : (
              <Button
                onClick={() => {
                  if (siteSelected.length === 0) {
                    setError('Please select aleast one site to clear its roster');
                  } else {
                    onClose();
                    Swal.fire({
                      title: 'Are you sure?',
                      text: `Clear roster for rest of the month!`,
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: 'Yes, Proceed!',
                      allowOutsideClick: false
                    }).then((result) => {
                      if (result.isConfirmed) {
                        clearRosterMutation({
                          sites: siteSelected,
                          shifts: [],
                          today: getCurrentDate(),
                          date: convertUTCtoLocal(rosterMonth, true)
                        });
                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                        cache.invalidateQueries();
                      }
                    });
                  }
                }}
                sx={{ width: '100%' }}
                variant="contained"
              >
                Submit
              </Button>
            )}
          </Box>
        </Stack>
        {/* Put the scroll bar always on the bottom */}
        <InfiniteScroll
          dataLength={SITE_SHIFTS?.length}
          next={fetchNextSiteShifts}
          // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
          // inverse //
          hasMore={hasNextSiteShifts}
          loader={
            <Stack direction="row" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Stack>
          }
          scrollableTarget="site-shifts-container"
          // endMessage={
          //   <p style={{ textAlign: 'center' }}>
          //     <b>Yay! You have seen it all</b>
          //   </p>
          // }
        />
      </Box>
    </Modal>
  );
}

ClearRoster.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  site: PropTypes.string,
  rosterMonth: PropTypes.object
};

export default React.memo(ClearRoster);
