import PieChartIcon from '@mui/icons-material/PieChart';
import PeopleIcon from '@mui/icons-material/People';
import ArticleIcon from '@mui/icons-material/Article';
import LockIcon from '@mui/icons-material/Lock';
import MapIcon from '@mui/icons-material/Map';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DashboardIcon from '@mui/icons-material/Dashboard';
// ----------------------------------------------------------------------

const sidebarConfig = {
  admin: [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: <PieChartIcon />
    },
    {
      title: 'roster',
      path: '/dashboard/roster',
      icon: <DashboardIcon />
    },
    {
      title: 'map',
      path: '/dashboard/map',
      icon: <MapIcon />
    },
    {
      title: 'HR',
      icon: <PeopleIcon />,
      children: [
        {
          title: 'Employees',
          path: '/dashboard/employees'
        },
        {
          title: 'Un-Assigned',
          path: '/dashboard/employees/unassigned'
        }
      ]
    },
    {
      title: 'policies',
      path: '/dashboard/policies',
      icon: <PersonAddIcon />
    },
    {
      title: 'leaves',
      path: '/dashboard/leaves',
      icon: <ArticleIcon />
    },
    {
      title: 'queries',
      path: '/dashboard/queries',
      icon: <LockIcon />
    },
    {
      title: 'Sites',
      icon: <PeopleIcon />,
      children: [
        {
          title: 'Sites Feedback',
          path: '/dashboard/sites/feedback'
        }
      ]
    },
    {
      title: 'Report',
      path: '/dashboard/report',
      icon: <SummarizeIcon />
    },
    {
      title: 'Reinforcement',
      path: '/dashboard/reinforcement',
      icon: <PeopleIcon />
    }
  ],
  employee: [
    {
      title: 'schedule',
      path: '/dashboard/app',
      icon: <PieChartIcon />
    }
  ]
};

export default sidebarConfig;
