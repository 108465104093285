import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CalendarDates from 'calendar-dates';
import DatePicker from 'react-multi-date-picker';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// material
import { styled } from '@mui/material/styles';
import '../../../index.css';
import {
  Box,
  Stack,
  Toolbar,
  Button,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  // Chip,
  //   TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Modal,
  // FormControlLabel,
  // Checkbox,
  //   ListSubheader,
  Popover,
  Divider
} from '@mui/material';
// icons
// import EventIcon from '@mui/icons-material/Event';
// import WcIcon from '@mui/icons-material/Wc';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
// import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
// import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
// date picker
import {
  //   convertUTCtoLocal,
  getDataFromStorage
} from '../../../utils/functions';
// import INDIAN from '../../../utils/INDIAN_STATES.json'; // importing indian states and uts
// config
// import { COMPANY_PLACEHOLDER_LOGO } from '../../../utils/config';
// hooks
// import { useRoles } from '../../../hooks/roles';
import { useShifts } from '../../../hooks/get-shifts-query';
// import usePolicies from '../../../hooks/get-policies';
// import useSites from '../../../hooks/get-sites-query';
// import { useManagers } from '../../../hooks/get-managers';

// ----------------------------------------------------------------------

// const MONTHS = [
//   'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
//   'August',
//   'September',
//   'October',
//   'November',
//   'December'
// ];

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 190,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 220, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250
//     }
//   }
// };

const DayOfWeeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// ----------------------------------------------------------------------

AddMembersListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  // siteId: PropTypes.string,
  // setFilters: PropTypes.func,
  // setSiteId: PropTypes.func,
  addMembers: PropTypes.func,
  shiftId: PropTypes.string,
  setShiftId: PropTypes.func,
  workDays: PropTypes.array,
  setWorkDays: PropTypes.func
  // isFullWeek: PropTypes.bool,
  // setIsFullWeek: PropTypes.func
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export default function AddMembersListToolbar({
  numSelected,
  filterName,
  onFilterName,
  // setFilters,
  shiftId,
  setShiftId,
  workDays,
  setWorkDays,
  // isFullWeek,
  // setIsFullWeek,
  addMembers
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [anchorAddToShift, setAnchorAddToShift] = React.useState(null);
  const selectedSite = getDataFromStorage('selected_site');
  // const today = new Date();
  //   const [roleOptions, setRoleOptions] = React.useState(); // Roles initial State for select
  //   const [policyOptions, setPolicyOptions] = React.useState();
  //   const [managerOptions, setManagerOptions] = React.useState();
  //   const [siteOptions, setSiteOptions] = React.useState();
  const [shiftOptions, setShiftOptions] = React.useState();
  const [, setCurrentMonth] = React.useState([]);
  const [filter, setFilter] = React.useState({
    doj: '',
    state: '',
    gender: '',
    role: '',
    manager: '',
    policy: ''
  });
  // const theme = useTheme();

  // const handleChange = (event) => {
  //   const {
  //     target: { value }
  //   } = event;
  //   setWorkDays(
  //     // On autofill we get a the stringified value.
  //     typeof value === 'string' ? value.split(',') : value
  //   );
  // };

  // function getStyles(name, workDays, theme) {
  //   return {
  //     fontWeight:
  //       workDays.indexOf(name) === -1
  //         ? theme.typography.fontWeightRegular
  //         : theme.typography.fontWeightMedium
  //   };
  // }

  //   /* ===================== Sites ===================== */

  //   const {
  //     isLoading,
  //     isError,
  //     data: sites
  //     // fetchNextPage: fetchNextSites,
  //     // hasMoreData: hasMoreSites,
  //     // refetch: refetchSites
  //   } = useSites();

  //   const allSites = useMemo(() => {
  //     const listOfSites = [];
  //     if (!isLoading && !isError) {
  //       if (sites?.pages?.length > 0) {
  //         sites.pages.forEach((group) => listOfSites.push(group.sites));
  //       }
  //     }
  //     const flattened = listOfSites.flat();
  //     return flattened;
  //   }, [sites, isLoading, isError]);

  //   React.useEffect(() => {
  //     const formattedSites = [];
  //     if (allSites?.length > 0) {
  //       allSites.forEach((site) => formattedSites.push([site._id, site.name]));
  //       setSiteOptions([...formattedSites]);
  //       // console.log('Role Options :', formattedRoles);
  //     }
  //   }, [allSites]);

  //   /* ================= End Sites =============== */

  React.useEffect(() => {
    const mainAsync = async () => {
      const today = new Date();
      const calendarDates = new CalendarDates();
      const mayDates = await calendarDates.getDates(today);
      // const mayMatrix = await calendarDates.getMatrix(may2018);
      const newarray = [].concat(...Array(7).fill(DayOfWeeks));
      mayDates.map((date, index) => (date.day = newarray[index]));
      const currentMonthWeekdays = mayDates.filter((date) => date.type === 'current');
      setCurrentMonth([...currentMonthWeekdays]);
    };
    mainAsync();
  }, []);

  /* ============================= Shifts =============================== */

  const {
    isShiftsLoading,
    isShiftsError,
    data: shifts
    // fetchNextPage: fetchNextShifts,
    // hasMoreData: hasMoreShifts,
    // refetch: refetchShifts
  } = useShifts('', selectedSite._id, null, null);

  const allShifts = useMemo(() => {
    const listOfShifts = [];
    if (!isShiftsLoading && !isShiftsError) {
      if (shifts?.pages?.length > 0) {
        shifts.pages.forEach((group) => listOfShifts.push(group.shifts));
      }
    }
    const flattened = listOfShifts.flat();
    return flattened;
  }, [shifts, isShiftsLoading, isShiftsError]);

  React.useEffect(() => {
    const formattedShifts = [];
    if (allShifts?.length > 0) {
      allShifts.forEach((shift) => {
        if (shift.status === 'Active') {
          formattedShifts.push([shift._id, shift.name]);
        }
      });
      setShiftOptions([...formattedShifts]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allShifts]);

  //   /* ===================== Roles ===================== */
  //   const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  //   const allRoles = React.useMemo(() => {
  //     if (!isRolesLoading && !isRolesError) {
  //       return roles.heirarchy;
  //     }
  //     return ''; // Needs to be changed
  //   }, [roles, isRolesLoading, isRolesError]);

  //   React.useEffect(() => {
  //     const formattedRoles = [];
  //     if (allRoles?.length > 0) {
  //       allRoles.forEach((role) => formattedRoles.push([role._id, role.name]));
  //       setRoleOptions([...formattedRoles]);
  //       // console.log('Role Options :', formattedRoles);
  //     }
  //   }, [allRoles]);
  //   /* ================= End Roles =============== */
  //   /* ===================== Policies =====================  */

  //   const {
  //     isPoliciesLoading,
  //     isPoliciesError,
  //     // fetchNextPage: fetchNextPolicies,
  //     // hasNextPage: hasNextPolicies,
  //     data: policies
  //   } = usePolicies(filter.role); // getting policies

  //   const Policies = React.useMemo(() => {
  //     const listOfPolicies = [];
  //     if (!isPoliciesLoading && !isPoliciesError) {
  //       if (policies?.pages?.length > 0) {
  //         policies.pages.forEach((group) => listOfPolicies.push(group.policies));
  //       }
  //     }
  //     const flattened = listOfPolicies.flat();
  //     return flattened;
  //   }, [policies, isPoliciesLoading, isPoliciesError]);

  //   React.useEffect(() => {
  //     // console.log(Policies);
  //     const formattedPolicies = [];
  //     if (Policies?.length > 0) {
  //       Policies.forEach((policy) => formattedPolicies.push([policy._id, policy.name]));
  //       setPolicyOptions([...formattedPolicies]);
  //     }
  //   }, [Policies]);

  //   /* ===================== End Policies ==================  */
  //   /* ===================== Managers ===================== */

  //   const {
  //     isLoading: isManagersLoading,
  //     isError: isManagersError,
  //     data: managers
  //   } = useManagers(filter?.role); // gettings managers

  //   const Managers = React.useMemo(() => {
  //     // console.log('Managers :', managers);
  //     const listOfManagers = [];
  //     if (!isManagersLoading && !isManagersError) {
  //       if (managers?.pages?.length > 0) {
  //         managers.pages.forEach((group) => listOfManagers.push(group.managers));
  //       }
  //     }
  //     const flattened = listOfManagers.flat();
  //     return flattened;
  //   }, [managers, isManagersLoading, isManagersError]);

  //   React.useEffect(() => {
  //     const formattedManagers = [];
  //     if (Managers?.length > 0) {
  //       Managers.forEach((manager) =>
  //         formattedManagers.push([
  //           manager._id,
  //           manager.name,
  //           manager.avatar ?? COMPANY_PLACEHOLDER_LOGO
  //         ])
  //       );
  //     }
  //     // console.log('Managers Options', formattedManagers);
  //     setManagerOptions([...formattedManagers]);
  //   }, [Managers]);

  //   /* ================= End Managers =============== */

  return (
    <>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main'
            // bgcolor: 'primary.lighter'
          })
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Search Employee..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
        )}

        {numSelected > 0 ? (
          <Stack direction="row">
            <Tooltip title="Add To Shift">
              <IconButton onClick={handleOpen}>
                <AccountTreeIcon />
              </IconButton>
            </Tooltip>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Stack direction="row" alignItems="center">
                  <AccountTreeIcon />
                  <Typography sx={{ ml: 1 }}>Add To Shift</Typography>
                </Stack>
                <Divider />
                {/* Shift */}
                <Box sx={{ display: 'flex', alignItems: 'flex-end', my: 2 }}>
                  <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="choose_shift">
                      Choose Shift
                    </InputLabel>
                    <Select
                      variant="standard"
                      value={shiftId}
                      inputProps={{
                        name: 'choose_shift',
                        id: 'choose_shift'
                      }}
                      onChange={(e) => setShiftId(e.target.value)}
                      sx={{ mt: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Choose Shift
                      </MenuItem>
                      {shiftOptions?.length > 0 ? (
                        shiftOptions.map(([key, value, image]) => (
                          <MenuItem key={`${'assign_shift'}-${key}`} value={key} data-icon={image}>
                            {value}
                          </MenuItem>
                        ))
                      ) : (
                        <>
                          <MenuItem value="" disabled />
                        </>
                      )}
                    </Select>
                  </FormControl>
                  &nbsp;
                  {filter.role ? (
                    <Tooltip title="Clear">
                      <IconButton onClick={() => setFilter({ ...filter, role: '' })}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </Box>
                <Typography sx={{ mb: 2 }}>Choose Workdays</Typography>
                <DatePicker
                  calendarPosition="bottom-center"
                  inputClass="custom-input"
                  placeholder="Select Work Days"
                  format="YYYY-MM-DD"
                  sort
                  minDate={new Date()}
                  multiple
                  value={workDays}
                  onChange={setWorkDays}
                />
                <Box sx={{ mt: 3 }}>
                  <Button
                    sx={{ float: 'right' }}
                    variant="contained"
                    size="medium"
                    disabled={!(shiftId && workDays.length > 0)}
                    onClick={() => {
                      addMembers();
                      handleClose();
                    }}
                  >
                    Assign
                  </Button>
                </Box>
              </Box>
            </Modal>
            &emsp;
          </Stack>
        ) : (
          <Tooltip title="Filter list">
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </RootStyle>
      <Popover
        id="filter_employees"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack spacing={1} width="320px" sx={{ p: 2 }}>
          <Stack direction="row" alignItems="center">
            <FilterListIcon />
            <Typography sx={{ ml: 1 }}>Filters</Typography>
          </Stack>
          <Divider />
          {/* Date Of Joining */}
          {/* <Box sx={{ display: 'flex', mt: 1, alignItems: 'flex-end' }}>
            <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <LocalizationProvider dateAdapter={AdapterDate}>
              <DatePicker
                label="Date Of Joining"
                // value={}
                onChange={(dateOfJoining) => {
                  setFilter({ ...filter, doj: convertUTCtoLocal(dateOfJoining, true) });
                }}
                renderInput={(params) => <TextField variant="standard" fullWidth {...params} />}
              />
            </LocalizationProvider>
            &nbsp;
            {filter.doj ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, doj: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box> */}
          {/* Gender */}
          {/* <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
            <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="gender_filter">
                Choose Gender
              </InputLabel>
              <Select
                variant="standard"
                value={filter.gender}
                inputProps={{
                  name: 'gender',
                  id: 'gender_filter'
                }}
                onChange={(e) => setFilter({ ...filter, gender: e.target.value })}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Gender
                </MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            &nbsp;
            {filter.gender ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, gender: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box> */}
          {/* State */}
          {/* <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
            <LocationCityIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="grouped-select">
                Choose State
              </InputLabel>
              <Select
                variant="standard"
                value={filter.state}
                onChange={(e) => setFilter({ ...filter, state: e.target.value })}
                id="grouped-select"
                label="Choose State"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <ListSubheader>States</ListSubheader>
                {INDIAN?.states?.length > 0 ? (
                  INDIAN?.states.map((key) => (
                    <MenuItem key={`${'state'}-${key}`} value={key}>
                      {key}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
                <ListSubheader>Union Territories</ListSubheader>
                {INDIAN.union_territories?.length > 0 ? (
                  INDIAN.union_territories.map((key) => (
                    <MenuItem key={`${'territory'}-${key}`} value={key}>
                      {key}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            &nbsp;
            {filter.state ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, state: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box> */}
          {/* Role */}
          {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="role_filter">
                Choose Role
              </InputLabel>
              <Select
                variant="standard"
                value={filter?.role}
                inputProps={{
                  name: 'role_filter',
                  id: 'role_filter'
                }}
                onChange={(e) => setFilter({ ...filter, role: e.target.value })}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Role
                </MenuItem>
                {roleOptions?.length > 0 ? (
                  roleOptions.map(([key, value, image]) => (
                    <MenuItem key={`${'role_filter'}-${key}`} value={key} data-icon={image}>
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <>
                    <MenuItem value="" disabled />
                  </>
                )}
              </Select>
            </FormControl>
            &nbsp;
            {filter.role ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, role: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box> */}
          {/* Manager */}
          {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="manager_filter">
                Choose Manager
              </InputLabel>
              <Select
                variant="standard"
                value={filter.manager}
                inputProps={{
                  name: 'manager_filter',
                  id: 'manager_filter'
                }}
                onChange={(e) => setFilter({ ...filter, manager: e.target.value })}
                disabled={!filter?.role}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Manager
                </MenuItem>
                {managerOptions?.length > 0 ? (
                  managerOptions.map(([key, value, image]) => (
                    <MenuItem
                      style={{ padding: '0.5rem' }}
                      key={`${'manager_filter'}-${key}`}
                      value={key}
                      data-icon={image}
                    >
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled />
                )}
              </Select>
            </FormControl>
            &nbsp;
            {filter.manager ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, manager: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box> */}
          {/* Policy */}
          {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <StickyNote2Icon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="policy_filter">
                Choose Policy
              </InputLabel>
              <Select
                variant="standard"
                value={filter.policy}
                inputProps={{
                  name: 'policy_filter',
                  id: 'policy_filter'
                }}
                onChange={(e) => setFilter({ ...filter, policy: e.target.value })}
                disabled={!filter?.role}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Policy
                </MenuItem>
                {policyOptions?.length > 0 ? (
                  policyOptions.map(([key, value, image]) => (
                    <MenuItem
                      style={{ padding: '0.5rem' }}
                      key={`${'policy_type'}-${key}`}
                      value={key}
                      data-icon={image}
                    >
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled />
                )}
              </Select>
            </FormControl>
            &nbsp;
            {filter.policy ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, policy: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box> */}
        </Stack>
      </Popover>
    </>
  );
}
