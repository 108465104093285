import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  SiteFeedbackListHead,
  SiteFeedbackListToolbar,
  SiteFeedbackMoreMenu
} from '../components/_dashboard/site_feedback';
import CreateComponent from '../components/_dashboard/payroll/CreateComponent';
import SearchNotFound from '../components/SearchNotFound';
// config
import { SWAL_TIME } from '../utils/config';
// redux
import { logOut } from '../redux/actions/adminActions';
import { deleteComponent } from '../api/mutations/adminMutations';
import usePayrollComponents from '../hooks/get-payroll-components';

const TABLE_HEAD = [
  { id: 'component_name', label: 'Component Name', alignRight: false },
  { id: 'payout_frequency', label: 'Payout Frequency', alignRight: false },
  { id: 'due_on', label: 'Due on', alignRight: false },
  { id: 'taxable', label: 'Taxable', alignRight: false },
  { id: 'lop', label: 'LOP', alignRight: false },
  { id: 'annual_limit', label: 'Annual Limit', alignRight: false },
  { id: '' }
];

export default function SalaryComponents() {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const [order, setOrder] = React.useState('asc');
  const [selected, setSelected] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('name');
  const [filterName, setFilterName] = React.useState('');
  const [value, setValue] = React.useState('1');
  const [createComponent, setCreateComponent] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* ===================== Payroll Components ===================== */

  const {
    isLoading,
    isError,
    data,
    fetchNextPage: fetchNextEmployees,
    hasNextPage: hasNextEmployees,
    refetch: refetchEmployees
  } = usePayrollComponents(value); // getting employees

  const rows = React.useMemo(() => {
    if (!isLoading && !isError) {
      const listOfComponents = [];
      if (data?.pages?.length > 0) {
        data.pages.forEach((group) => listOfComponents.push(group.components));
      }
      const flattened = listOfComponents.flat();
      return flattened;
    }
    return [];
  }, [data, isLoading, isError]);

  /* ================= End Payroll Components =============== */

  /* ================== Delete Component ========================== */
  const deleteComponentMutation = useMutation(deleteComponent, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Deleted!`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('payroll-components');
    }
  });

  const filteredUsers = rows;
  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Basic" value="1" />
            <Tab label="Allowance" value="allowance" />
            <Tab label="Deduction" value="deduction" />
            <Tab label="Bonus" value="bonus" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Table>
            <TableRow>
              <TableCell component="th">
                <b>Salary Components</b>
              </TableCell>
              <TableCell component="th">
                <b>Calculation (Annual)</b>
              </TableCell>
            </TableRow>
            <TableBody>
              <TableRow>
                <TableCell>Basic</TableCell>
                <TableCell>CTC * A</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>HRA</TableCell>
                <TableCell>Basic * A</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PF</TableCell>
                <TableCell>System Calculated</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ESI</TableCell>
                <TableCell>System Calculated</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Special Allowance</TableCell>
                <TableCell>Balancing Amount of CTC</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel value="allowance">
          <Box>
            <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 15rem)' }}>
              <Table>
                <SiteFeedbackListHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  // numSelected={selected.length}
                  // onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                          </TableCell> */}
                          <TableCell>{(index += 1)}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.payout_frequency ?? 'N/A'}</TableCell>
                          <TableCell align="left">{row.month_due_on ?? 'N/A'}</TableCell>
                          <TableCell align="left">{row.taxable ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">{row.lop ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">
                            {row.ctype === 'flat' ? `₹ ${row?.flat ? row.flat : 0}` : row?.ctype}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton color="error">
                              <DeleteIcon
                                onClick={() =>
                                  Swal.fire({
                                    title: 'Are you sure?',
                                    text: "You won't be able to revert this!",
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, Proceed!',
                                    allowOutsideClick: false
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      deleteComponentMutation.mutate({
                                        id: row._id
                                      });
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                      cache.invalidateQueries('payroll-components');
                                    }
                                  })
                                }
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <Button variant="contained" onClick={() => setCreateComponent(true)}>
              Create Component
            </Button>
          </Box>
        </TabPanel>
        <TabPanel value="bonus">
          <Box>
            <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 15rem)' }}>
              <Table>
                <SiteFeedbackListHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  // numSelected={selected.length}
                  // onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                          </TableCell> */}
                          <TableCell>{(index += 1)}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.payout_frequency ?? 'N/A'}</TableCell>
                          <TableCell align="left">{row.month_due_on ?? 'N/A'}</TableCell>
                          <TableCell align="left">{row.taxable ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">{row.lop ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">
                            {row.ctype === 'flat' ? `₹ ${row?.flat ? row.flat : 0}` : row?.ctype}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton color="error">
                              <DeleteIcon
                                onClick={() =>
                                  Swal.fire({
                                    title: 'Are you sure?',
                                    text: "You won't be able to revert this!",
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, Proceed!',
                                    allowOutsideClick: false
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      deleteComponentMutation.mutate({
                                        id: row._id
                                      });
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                      cache.invalidateQueries('payroll-components');
                                    }
                                  })
                                }
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <Button variant="contained" onClick={() => setCreateComponent(true)}>
              Create Component
            </Button>
          </Box>
        </TabPanel>
        {/* <TabPanel value="prequisites">
          <Box>
            <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 15rem)' }}>
              <Table>
                <SiteFeedbackListHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  // numSelected={selected.length}
                  // onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                          </TableCell>
                          <TableCell>{(index += 1)}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.payout_frequency ?? 'N/A'}</TableCell>
                          <TableCell align="left">{row.month_due_on ?? 'N/A'}</TableCell>
                          <TableCell align="left">{row.taxable ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">{row.lop ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">
                            {row.ctype === 'flat' ? `₹ ${row?.flat ? row.flat : 0}` : row?.ctype}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton color="error">
                              <DeleteIcon
                                onClick={() =>
                                  Swal.fire({
                                    title: 'Are you sure?',
                                    text: "You won't be able to revert this!",
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, Proceed!',
                                    allowOutsideClick: false
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      deleteComponentMutation.mutate({
                                        id: row._id
                                      });
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                      cache.invalidateQueries('payroll-components');
                                    }
                                  })
                                }
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <Button variant="contained" onClick={() => setCreateComponent(true)}>
              Create Component
            </Button>
          </Box>
        </TabPanel> */}
        <TabPanel value="deduction">
          <Box>
            <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 15rem)' }}>
              <Table>
                <SiteFeedbackListHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  // numSelected={selected.length}
                  // onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                          </TableCell> */}
                          <TableCell>{(index += 1)}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.payout_frequency ?? 'N/A'}</TableCell>
                          <TableCell align="left">{row.month_due_on ?? 'N/A'}</TableCell>
                          <TableCell align="left">{row.taxable ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">{row.lop ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">
                            {row.ctype === 'flat' ? `₹ ${row?.flat ? row.flat : 0}` : row?.ctype}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton color="error">
                              <DeleteIcon
                                onClick={() =>
                                  Swal.fire({
                                    title: 'Are you sure?',
                                    text: "You won't be able to revert this!",
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, Proceed!',
                                    allowOutsideClick: false
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      deleteComponentMutation.mutate({
                                        id: row._id
                                      });
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                      cache.invalidateQueries('payroll-components');
                                    }
                                  })
                                }
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <Button variant="contained" onClick={() => setCreateComponent(true)}>
              Create Component
            </Button>
          </Box>
        </TabPanel>
        {/* <TabPanel value="reimbursement">
          <Box>
            <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 15rem)' }}>
              <Table>
                <SiteFeedbackListHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  // numSelected={selected.length}
                  // onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                          </TableCell>
                          <TableCell>{(index += 1)}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.payout_frequency ?? 'N/A'}</TableCell>
                          <TableCell align="left">{row.month_due_on ?? 'N/A'}</TableCell>
                          <TableCell align="left">{row.taxable ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">{row.lop ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">
                            {row.ctype === 'flat' ? `₹ ${row?.flat ? row.flat : 0}` : row?.ctype}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton color="error">
                              <DeleteIcon
                                onClick={() =>
                                  Swal.fire({
                                    title: 'Are you sure?',
                                    text: "You won't be able to revert this!",
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, Proceed!',
                                    allowOutsideClick: false
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      deleteComponentMutation.mutate({
                                        id: row._id
                                      });
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                      cache.invalidateQueries('payroll-components');
                                    }
                                  })
                                }
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <Button variant="contained" onClick={() => setCreateComponent(true)}>
              Create Component
            </Button>
          </Box>
        </TabPanel> */}
      </TabContext>
      <CreateComponent open={createComponent} handleClose={() => setCreateComponent(false)} />
    </Box>
  );
}
