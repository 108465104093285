import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchNotifications } from '../api/queries/Queries';

export const useNotifications = () => {
  const dispatch = useDispatch();
  return useInfiniteQuery(['notifications'], fetchNotifications, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

// export const useEmployeeTokens = () => {
//   const dispatch = useDispatch();
//   return useQuery(['employees-tokens'], fetchEmployeeTokens, {
//     onError: (error) => {
//       // status: 401 unauthorized
//       if (error.response && error.response.status === 401) {
//         dispatch(logOut());
//       }
//     }
//   });
// };
