/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-comment-textnodes */
// import { filter } from 'lodash';
import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import { useDebounce } from 'use-debounce';
import {
  Container,
  Stack,
  Typography,
  Card,
  // Button,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Avatar,
  ListItem,
  List,
  ListItemText,
  Popover,
  ListItemButton,
  ListItemIcon
} from '@mui/material';
// icons
import InfoIcon from '@mui/icons-material/Info';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
// import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
// import ContactsIcon from '@mui/icons-material/Contacts';
import {
  AttendanceListHead,
  AttendanceListToolbar,
  AttendanceMoreMenu
} from '../components/_dashboard/attendance';
import ImageZoom from '../components/ImageZoom';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import NotAvailable from '../components/NotAvailable';
// import SearchNotFound from '../components/SearchNotFound';
// hooks
import { useShiftAttendance } from '../hooks/get-attendance-shift';
// utils
import { _12hourTimeFormat, convertUTCtoLocal, DD_MM_YYYY } from '../utils/functions';

// ---------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'employee', label: 'Employee', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'in', label: 'IN', alignRight: false },
  { id: 'in_marked_by', label: 'IN Marked By', alignRight: false },
  { id: 'out', label: 'OUT', alignRight: false },
  { id: 'out_marked_by', label: 'OUT Marked By', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,
//       (_user) => _user.employee.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
//     );
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// --------------------------------------------------------------

function ShiftWiseAttendance() {
  const { shift } = useParams();
  // const bytes = CryptoJS.SHA256.decrypt(shift);
  // const shiftId = bytes.toString(CryptoJS.enc.Utf8);
  // const [page, setPage] = useState(0);
  const [src, setSrc] = useState();
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  // const [search] = useDebounce(filterName, 1200); // Debounce value 1.2 sec
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [filter, setFilter] = useState({
    from: new Date()
    // to: '',
    // date: getUTCDate()
    // site: '',
    // employee: ''
  });

  useEffect(() => {
    console.log(filter);
  }, [filter]);

  const {
    isShiftAttendanceLoading,
    isShiftAttendanceError,
    data: shiftAttendance
    // refetch: refetchShiftAttendance,
  } = useShiftAttendance(shift, convertUTCtoLocal(filter.from, true)); // Add Date Filter

  const rows = useMemo(() => {
    const listOfShiftAttendance = [];
    if (!isShiftAttendanceLoading && !isShiftAttendanceError) {
      if (shiftAttendance?.pages?.length > 0) {
        shiftAttendance.pages.forEach((group) => listOfShiftAttendance.push(group.attendances));
      }
    }
    const flattened = listOfShiftAttendance.flat();
    return flattened;
  }, [shiftAttendance, isShiftAttendanceLoading, isShiftAttendanceError]);

  useEffect(() => {
    // const bytes = CryptoJS.AES.decrypt(shift, 'my-secret-key@123');
    // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // console.log('Shift :', decryptedData);
    console.log('Shift Attendance :', rows);
  }, [rows, shift]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const filteredUsers = rows;

  // const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Site Feedback | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Attendance
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => setOpenAddEmployee(true)}
          >
            New Employee
          </Button> */}
        </Stack>
        <Card>
          <AttendanceListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
            filters={filter}
          />

          <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 320px)' }}>
            <Table>
              <AttendanceListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {isShiftAttendanceLoading ? (
                  <TableRow>
                    <TableCell colSpan="9">
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                ) : isShiftAttendanceError ? (
                  <TableRow>
                    <TableCell>Something Went Wrong</TableCell>
                  </TableRow>
                ) : rows.length > 0 ? (
                  filteredUsers.map((row, index) => {
                    const isItemSelected = selected.indexOf(row.name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={row._id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                          </TableCell> */}
                        <TableCell>{(index += 1)}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar
                              alt={row?.employee?.name}
                              onClick={() => setSrc(row?.employee?.avatar)}
                              src={row?.employee?.avatar}
                              sx={{ cursor: 'pointer' }}
                            />
                            <Typography variant="subtitle2" noWrap>
                              {row?.employee?.name}
                            </Typography>
                            <InfoIcon
                              sx={{ cursor: 'pointer' }}
                              onClick={(event) =>
                                setAnchorEl({
                                  id: `info_${row?._id}`,
                                  target: event.currentTarget
                                })
                              }
                            />
                            <Popover
                              id={`info_${row?._id}`}
                              open={anchorEl.id === `info_${row?._id}`}
                              anchorEl={anchorEl.target}
                              onClose={() => setAnchorEl({ id: null, target: null })}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <List>
                                <ListItem disablePadding>
                                  <ListItemButton>
                                    <ListItemIcon>
                                      <PhoneAndroidIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`+91 ${row?.employee?.contact}`} />
                                  </ListItemButton>
                                </ListItem>
                                {/* <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <AlternateEmailIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`${row?.email}`} />
                                    </ListItemButton>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <LocationCityIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`${row?.state}`} />
                                    </ListItemButton>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemButton component="a" href="#simple-list">
                                      <ListItemIcon>
                                        <ContactsIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={`${row?.address}`} />
                                    </ListItemButton>
                                  </ListItem> */}
                              </List>
                            </Popover>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{DD_MM_YYYY(row.date)}</TableCell>
                        <TableCell align="center">
                          {row?.in ? (
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar
                                alt={row?.name}
                                src={row?.in?.image}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => setSrc(row?.in?.image)}
                              />
                              <Typography variant="subtitle2" noWrap>
                                {row?.in?.time ? _12hourTimeFormat(row.in.time) : '-'}
                              </Typography>
                            </Stack>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row?.in ? (
                            row?.in?.markedBy?._id !== row?.employee?._id ? (
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Avatar
                                  alt={row?.in?.markedBy?.name}
                                  src={row?.in?.markedBy?.avatar}
                                />
                                <Typography variant="subtitle2" noWrap>
                                  {row?.in?.markedBy?.name}
                                </Typography>
                              </Stack>
                            ) : (
                              'Self'
                            )
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row?.out ? (
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar
                                alt={row?.name}
                                src={row?.out?.image}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => setSrc(row?.out?.image)}
                              />
                              <Typography variant="subtitle2" noWrap>
                                {row?.out?.time ? _12hourTimeFormat(row.out.time) : '_ _ : _ _'}
                              </Typography>
                            </Stack>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row?.out ? (
                            row?.out?.markedBy?._id !== row?.employee?._id ? (
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Avatar
                                  alt={row?.out?.markedBy?.name}
                                  src={row?.out?.markedBy?.avatar}
                                />
                                <Typography variant="subtitle2" noWrap>
                                  {row?.out?.markedBy?.name}
                                </Typography>
                              </Stack>
                            ) : (
                              'Self'
                            )
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        {/* <TableCell align="right">
                          <AttendanceMoreMenu />
                        </TableCell> */}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={7}>
                      <NotAvailable msg="No Data Available!" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
      <ImageZoom src={src} onClose={() => setSrc('')} />
    </Page>
  );
}

export default ShiftWiseAttendance;
