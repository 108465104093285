import React from 'react';
import { Container, Typography, Stack } from '@mui/material';
// components
import Page from '../components/Page';

const mapContainerStyle = {
  width: '100%',
  height: 'calc(100vh - 200px)'
};

function ShiftAttendance() {
  return (
    <Page title="Shift Attendance | Trackkar">
      <Container>
        <Stack
          style={mapContainerStyle}
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundImage: 'url(/static/illustrations/illustration_attendance.png)',
            backgroundSize: '50%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            // border: '1px solid grey',
            borderRadius: 1
          }}
        >
          <Typography sx={{ position: 'absolute', bottom: 0, mb: 3 }}>
            Choose a Shift to Load the Attendance
          </Typography>
          {/* <CircularProgress /> */}
        </Stack>
      </Container>
    </Page>
  );
}

export default ShiftAttendance;
