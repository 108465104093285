// scroll bar
// import 'simplebar/src/simplebar.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import NotAvailable from './components/NotAvailable';

// ----------------------------------------------------------------------
// require('dotenv').config();
// 👇️ IMPORTANT: use correct ID of your root element
// this is the ID of the div in your index.html file
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <HelmetProvider>
    <React.Suspense fallback={<NotAvailable type="loading" logo />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Suspense>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();
// serviceWorker.registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
