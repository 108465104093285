import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchShiftPreferences } from '../api/queries/Queries';

export const useShiftPreferences = (id) => {
  const dispatch = useDispatch();
  return useQuery(['shift-preferences', id], () => fetchShiftPreferences('shift-preferences', id), {
    enabled: !!id,
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};
