import { useInfiniteQuery, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import {
  fetchEmployees,
  fetchUnmanagedEmployees,
  fetchEmployeesCards,
  fetchEmployeesWorkHours,
  fetchEmployeeSchedule,
  fetchPaySlipsByEmployee
} from '../api/queries/Queries';

export const useEmployees = (
  doj,
  search,
  state,
  policyId,
  role,
  manager,
  gender,
  shouldFetchPolicies = false,
  subrole
) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(
    [
      'employees',
      doj,
      search,
      state,
      policyId,
      role,
      manager,
      gender,
      shouldFetchPolicies,
      subrole
    ],
    fetchEmployees,
    {
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};

export const useUnmanagedEmployees = (
  doj,
  search,
  state,
  policyId,
  role,
  subrole,
  manager,
  isManager
) => {
  const dispatch = useDispatch();

  return useInfiniteQuery(
    ['unmanaged-employees', doj, search, state, policyId, role, subrole, manager, isManager],
    fetchUnmanagedEmployees,
    {
      retry: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};

export const useEmployeesCards = (type, site) => {
  const dispatch = useDispatch();
  return useQuery(['employees-cards', type, site], fetchEmployeesCards, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export const useEmployeeSchedule = (date, employee) => {
  const dispatch = useDispatch();
  return useQuery(['employee-schedule', date, employee], fetchEmployeeSchedule, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export const useEmployeesWorkHours = (search, date, role, subrole, gender) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(
    ['employees-workhours', search, date, role, subrole, gender],
    fetchEmployeesWorkHours,
    {
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};

export const usePaySlipsByEmployee = (employee) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(['employee-payslips', employee], fetchPaySlipsByEmployee, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};
