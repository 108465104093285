import PieChartIcon from '@mui/icons-material/PieChart';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

// ----------------------------------------------------------------------

const sidebarConfig = [
  {
    title: 'Profile',
    path: '/employee/profile',
    icon: <PieChartIcon />
  },
  {
    title: 'Site History',
    path: '/employee/site-history',
    icon: <PeopleIcon />
  },
  {
    title: 'Attendance History',
    path: '/employee/attendance-history',
    icon: <PersonAddIcon />
  },
  {
    title: 'Feedback History',
    path: '/employee/feedback-history',
    icon: <PeopleIcon />
  },
  {
    title: 'Queries History',
    path: '/employee/queries-history',
    icon: <PeopleIcon />
  },
  {
    title: 'Change Password',
    path: '/employee/change_password',
    icon: <PeopleIcon />
  }
];

export default sidebarConfig;
