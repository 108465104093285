import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchBuckets } from '../api/queries/Queries';

const useBuckets = (search, date) => {
  // console.log("Site Search", search)
  const dispatch = useDispatch();
  return useInfiniteQuery(['buckets', search, date], fetchBuckets, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export default useBuckets;
