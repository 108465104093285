import axios from 'axios';
import { baseApiURL } from '../../utils/config';
import { getDataFromStorage } from '../../utils/functions';

const getConfig = () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  return config;
};

export const addShift = async (values) => {
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/common/shifts`, values, getConfig());
    return data;
  }
  throw new Error('No data to send');
};

export const editShift = async (values) => {
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/shift`, values, getConfig());
    return data;
  }
  throw new Error('No data to send');
};

export const moveShiftMembers = async (values) => {
  if (values) {
    const { data } = await axios.put(
      `${baseApiURL}/common/move-shift-members`,
      values,
      getConfig()
    );
    return data;
  }
  throw new Error('No data to send');
};

export const swapShiftMembers = async (values) => {
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/swap-shift-member`, values, getConfig());
    return data;
  }
  throw new Error('No data to send');
};

export const removeFromShift = async (values) => {
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/remove-from-shift`, values, getConfig());
    return data;
  }
  throw new Error('No data to send');
};

export const endRelocation = async (values) => {
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/relocation`, values, getConfig());
    return data;
  }
  throw new Error('No data to send');
};
