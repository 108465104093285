import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Chip, Typography, Menu, MenuItem, Tooltip } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CategoryIcon from '@mui/icons-material/Category';

export function ProgressIndicators() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: theme.palette.primary.main }}
      >
        <Tooltip title="Color Breakdown">
          <CategoryIcon />
        </Tooltip>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem>
          <Chip
            sx={{
              backgroundColor: theme.palette.warning.lighter,
              width: 20,
              height: 20,
              borderRadius: 0.5
            }}
          />
          <Typography sx={{ ml: 1 }}>1%&nbsp;-&nbsp;49%&nbsp;</Typography>
        </MenuItem>
        <MenuItem>
          <Chip
            sx={{
              backgroundColor: theme.palette.info.lighter,
              width: 20,
              height: 20,
              borderRadius: 0.5
            }}
          />
          <Typography sx={{ ml: 1 }}>50%&nbsp;-&nbsp;99%&nbsp;</Typography>
        </MenuItem>
        <MenuItem>
          <Chip
            sx={{
              backgroundColor: theme.palette.primary.lighter,
              width: 20,
              height: 20,
              borderRadius: 0.5
            }}
          />
          <Typography sx={{ ml: 1 }}>100%&nbsp;</Typography>
        </MenuItem>
        <MenuItem>
          <Chip
            sx={{
              backgroundColor: theme.palette.error.lighter,
              width: 20,
              height: 20,
              borderRadius: 0.5
            }}
          />
          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            &nbsp;
            <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
            &nbsp;100%
          </Typography>
        </MenuItem>
        <MenuItem>
          <Chip
            sx={{
              backgroundColor: theme.palette.error.dark,
              width: 20,
              height: 20,
              color: 'white',
              borderRadius: 0.5,
              '& .MuiChip-label': {
                p: 0
              }
            }}
            label="H"
          />
          <Typography sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>Holiday</Typography>
        </MenuItem>
        <MenuItem>
          <Chip
            sx={{
              backgroundColor: theme.palette.error.dark,
              width: 28,
              height: 20,
              color: 'white',
              borderRadius: 0.5,
              '& .MuiChip-label': {
                p: 0
              }
            }}
            label="WO"
          />
          <Typography sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>Weekly Off</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ProgressIndicators;
