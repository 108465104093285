/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
import { useState, useEffect, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
// import PropTypes from 'prop-types';
// material
// import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import { TextField, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// utils
import {
  excelToJSON,
  capitalizeEachWord,
  setDataInStorage,
  getDataFromStorage,
  arraysEqual
} from '../../../utils/functions';
import classes from '../../../pages/styles/ImportEmployees.module.css';
import { TRACKKAR_BULK_UPLOAD_FILES, DayOfWeeks, WeekDaysToNumber } from '../../../utils/config';
import { importSites } from '../../../api/mutations/adminMutations';
import callSocket from '../../../utils/socket';
import { logOut } from '../../../redux/actions/adminActions';
import { countries } from '../../../utils/countries';

const DEBUG = false;

const EXCEL_HEADER = [
  'Name',
  'Country Code',
  'Mobile',
  'E-mail (Optional)',
  'Site Work days per week',
  'Site Weekly Off',
  'Employee Work days per week',
  'Employee Work hours per day'
];

const countryPhoneCodes = countries.map((country) => country.phone);

export default function UploadSites() {
  const [importCheck, setImportCheck] = useState({
    importId: getDataFromStorage('importId') ?? null,
    importLength: getDataFromStorage('importLength') ?? null
  });
  const [isValidating, setIsValidating] = useState(''); // Upon excel validation
  const { info } = useSelector((state) => state.adminInfo);
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const [inMutate, setInMutate] = useState(false);
  // const [isImportingSites, setIsImportingSites] = useState(false);

  /* ==================== Import Employees Mutations ================== */
  const { mutate } = useMutation(importSites, {
    onMutate: (mutateData) => {
      setInMutate(true);
      setDataInStorage('importId', '');
      setImportCheck({
        ...importCheck,
        importId: mutateData.importId,
        importLength: mutateData.sites.length
      });
      setDataInStorage('importId', mutateData.importId);
      setDataInStorage('importLength', mutateData.sites.length);
    }
  });

  useEffect(() => {
    if (importCheck?.importId?.length > 0) {
      setIsValidating('');
      // client-side
      const socket = callSocket();
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Onboarding sites :)',
        allowOutsideClick: false,
        // showConfirmButton: false,
        html: `<div style="display:block;">
          <p style="text-align:center;margin-bottom:10px;"><strong>Starting...</strong><p>
          <div style="display:flex;justify-content:center;">
            <div class="custom_progress progress--horizontal-soft">
              <div class="custom_progress__bar"></div>
            </div>
            &emsp;
            <div id="import_completed" class="import_percentage"></div>
          </div>
          <div class="import_progress">
            <p>Error</p><div id="import_error">0</div>
            <p>Existing</p><div id="import_existing">0</div>
            <p>Uploaded</p><div id="import_inserted">0</div>
          </div>
          </div>`,
        didOpen: () => {
          const content = Swal.getHtmlContainer();
          const progressBars = content.querySelectorAll('.custom_progress__bar');
          function setProgress(progress) {
            progressBars.forEach((bar) => {
              Object.assign(bar.style, {
                transform: `scaleX(${progress / 100})`,
                /* standard & vertical */
                'background-position-y': `${progress.toString()}%`,
                /* horizontal */
                'background-position-x': `${progress.toString()}%`
              });
            });
          }
          // window.onbeforeunload = () => true;
          const er = content.querySelector('strong');
          socket.on('disconnect', (reason) => {
            if (!navigator.onLine) {
              er.textContent = `Connection Lost!!, reconnecting...`;
            } else if (
              reason === 'io server disconnect' ||
              reason === 'ping timeout' ||
              reason === 'transport error'
            ) {
              // the disconnection was initiated by the server, you need to reconnect manually
              socket.connect();
            } else if (reason === 'transport close' && navigator.onLine) {
              // er.textContent = `Error Uploading Data!!`;
              setDataInStorage('importId', '');
              setDataInStorage('importLength', '');
            }
          });
          socket.on('progress', (status) => {
            if (
              status.type === 'importEmployees' &&
              status.user === info._id &&
              status.importId === importCheck.importId
            ) {
              // const $ = content.querySelector.bind(content);
              const dataLength = importCheck.importLength;
              const sum = status.error + status.existing + status.inserted;
              const progress = (sum * 100) / dataLength;
              setProgress(progress);
              content.querySelector('strong').textContent = `${sum} / ${dataLength}`;
              content.querySelector('#import_error').textContent = `${status.error}`;
              content.querySelector('#import_existing').textContent = `${status.existing}`;
              content.querySelector('#import_inserted').textContent = `${status.inserted}`;
              content.querySelector('#import_completed').textContent = `${parseFloat(
                progress
              ).toFixed(0)}%`;
              if (progress === 100) {
                socket.disconnect();
                setDataInStorage('importId', '');
                setDataInStorage('importLength', '');
                if (!inMutate) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Successfull',
                    html: `<span>Successfully Completed!!<div style="color: red; font-weight: bold;margin: 0.5rem 0">IMPORTANT</div><mark>Please update the profile picture of the added employees with their real faces for our facial-recognition based attendace system to work properly. Otherwise, they won't be able to mark the attendance.</mark><br/><br/>
                          Employees can login to their respective accounts by using mobile numbers as their initial password.\nFeel free to change it whenever you want from employee profile.</span>`
                  });
                }
              }
            }
          });
        }
      });
    }
  }, [importCheck, inMutate, info]);

  useEffect(() => {
    if (isValidating.length > 0) {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: `${isValidating === 'Validating' ? 'Validating File... 😃' : 'Reading File... 😃'}`,
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    }
  }, [isValidating]);

  const handleBulkUpload = useCallback(
    async (files) => {
      // console.log(files[0]);
      const excelFile = files[0];

      if (excelFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        // console.log("Only Excel files please");
        Swal.fire({
          icon: 'error',
          title: 'Unsupported Format!',
          text: 'Please upload a valid excel document with the proper format provided in the template.'
          // timer: 4000,
          // timerProgressBar: true
        });
      } else {
        setIsValidating('Reading');
        const excel = await excelToJSON(excelFile);
        if (!arraysEqual(excel.headers, EXCEL_HEADER)) {
          setIsValidating('');
          Swal.fire({
            icon: 'error',
            title: 'Some of Headers are not Correct!',
            text: `Correct Headers are ${EXCEL_HEADER.toString().replaceAll(',', ', ')}`
            // timer: 4000,
            // timerProgressBar: true
          });
          document.getElementById('import-sites-file').value = null;
        } else {
          const result = excel.rows.map((row) => {
            const tempObj = {
              name: capitalizeEachWord(row.Name?.trim()),
              country_code: row['Country Code']?.toString()?.trim(),
              mobile: row.Mobile?.toString()?.trim(),
              email: row['E-mail (Optional)']?.toLowerCase()?.trim(),
              // eslint-disable-next-line dot-notation
              site_weekly_working_days: row['Site Work days per week'],
              weekly_off: row['Site Weekly Off']?.split(',') ?? [],
              employee_weekly_working_days: row['Employee Work days per week'],
              daily_working_hours: row['Employee Work hours per day'],
              weekly_working_hours:
                row['Employee Work days per week'] * row['Employee Work hours per day']
            };
            return tempObj;
          });

          // excel file data validationSchema
          const validationSchema = Yup.object({
            name: Yup.string()
              .required('Name is required')
              .typeError('Please provide the name of the client')
              .matches(
                /^[a-zA-Z0-9][a-zA-Z0-9\s\-&.']*[a-zA-Z0-9]+$/,
                "Invalid client name. ( Minimum 2 characters, must start & end with alphanumeric characters, allowed special characters  - & . ' )"
              ),
            email: Yup.string()
              .notRequired()
              .email('Invalid email address.')
              .typeError('Please provide the email of the client'),
            country_code: Yup.string()
              .required('Country Code is Required')
              .oneOf(countryPhoneCodes, 'Please Provide a valid country code'),
            mobile: Yup.string().required('Mobile number is required.'),
            // .typeError('Please provide the mobile number of the client')
            // .matches(/^[6-9]\d{9}$/, 'Invalid mobile number.'),
            site_weekly_working_days: Yup.number().required('This Field is required'),
            employee_weekly_working_days: Yup.number()
              .typeError('Please enter a valid working days')
              .min(1, 'Minimum number of days can be 1')
              .test({
                name: 'max',
                exclusive: false,
                params: {},
                // eslint-disable-next-line no-template-curly-in-string
                // eslint-disable-next-line react/no-this-in-sfc
                // message: `DayOff ${Yup.ref('site_weekly_working_days').getValue()}must be given`,
                // eslint-disable-next-line object-shorthand
                test: function (value) {
                  // You can access the price field with `this.parent`.
                  // eslint-disable-next-line react/no-this-in-sfc
                  const swd = this?.parent?.site_weekly_working_days;
                  const des = value <= swd;
                  // eslint-disable-next-line no-unneeded-ternary
                  if (swd) {
                    if (des) {
                      return des;
                    }
                    // eslint-disable-next-line react/no-this-in-sfc
                    return this.createError({
                      message: `Employee working days must be less than or equal to ${swd}`,
                      path: 'employee_weekly_working_days'
                    });
                  }
                  // eslint-disable-next-line react/no-this-in-sfc
                  return this.createError({
                    message: `Please select weekly working days for a site`,
                    path: 'employee_weekly_working_days'
                  });
                }
              })
              .required('Please provide working days (1 - 6) in week '),
            daily_working_hours: Yup.number()
              .typeError('Please enter a valid working hours')
              .min(1, 'Minimum number of days can be 1')
              .max(12, 'Maximum number of days can be 12')
              .required('Please provide working hours (8 - 12) in a day'),
            weekly_working_hours: Yup.number().required('Please provide working hours in a week'),
            weekly_off: Yup.array()
              .notRequired()
              .test({
                name: 'max',
                exclusive: false,
                params: {},
                // eslint-disable-next-line no-template-curly-in-string
                // eslint-disable-next-line react/no-this-in-sfc
                // message: `DayOff ${Yup.ref('site_weekly_working_days').getValue()}must be given`,
                // eslint-disable-next-line object-shorthand
                test: function (value) {
                  // eslint-disable-next-line react/no-this-in-sfc
                  // You can access the price field with `this.parent`.
                  // eslint-disable-next-line react/no-this-in-sfc
                  const swd = this?.parent?.site_weekly_working_days;
                  const diff = 7 - swd;
                  const des = value?.length === diff;
                  const check = Boolean(value?.some((a) => DayOfWeeks.includes(a)));
                  if (value === undefined) {
                    return false;
                  }
                  // eslint-disable-next-line no-unneeded-ternary
                  if (swd) {
                    if (des) {
                      return des;
                    }
                    // eslint-disable-next-line react/no-this-in-sfc
                    return this.createError({
                      message: `${diff} Day Off must be given`,
                      path: 'weekly_off'
                    });
                  }
                  if (!check) {
                    // eslint-disable-next-line react/no-this-in-sfc
                    return this.createError({
                      message: `Possible Day Off Values are ${DayOfWeeks.toString()}`,
                      path: 'weekly_off'
                    });
                  }
                  // eslint-disable-next-line react/no-this-in-sfc
                  return this.createError({
                    message: `Please select weekly working days for a site`,
                    path: 'site_work_days_per_week'
                  });
                }
              })
            // business: Yup.string()
            //   .required("Type of business is required.")
            //   .typeError("Please provide a type of business for the client")
            //   .oneOf(
            //     [
            //       "Retail",
            //       "Manufacturer",
            //       "Pick-up",
            //       "Drop-off",
            //       "Distribution",
            //       "Wholesale",
            //       "Clothing",
            //       "Jewellery",
            //       "Other",
            //     ],
            //     "Type of business must be one of the following values: Retail, Manufacturer, Pick-up, Drop-off, Distribution, Wholesale, Clothing, Jewellery, Other."
            //   ),
          });

          const validationErrors = [];
          setIsValidating('Validating');
          // eslint-disable-next-line no-restricted-syntax
          for (const [index, record] of result.entries()) {
            // console.log(record);
            // eslint-disable-next-line no-await-in-loop
            await validationSchema
              .validate(record, { abortEarly: false })
              .catch((validationError) => {
                // console.log('validation-error: ', validationError);
                validationErrors.push({
                  rowNumber: index + 2,
                  message: validationError.message,
                  errors: validationError.errors
                });
              });
          }
          if (validationErrors.length === 0) {
            const fresult = [];
            result.forEach((i) => {
              i.weekly_off = i.weekly_off.map((w) => WeekDaysToNumber[w]);
              i.constraints = {
                site_weekly_working_days: i.site_weekly_working_days,
                weekly_off: i.weekly_off,
                employee_weekly_working_days: i.employee_weekly_working_days,
                daily_working_hours: i.daily_working_hours,
                weekly_working_hours: i.weekly_working_hours
              };
              fresult.push(i);
            });
            if (DEBUG) {
              setIsValidating('');
              console.log('Site Upload Data:', fresult);
            } else {
              mutate(
                {
                  sites: fresult,
                  importId: Date.now().toString()
                },
                {
                  onError: (error) => {
                    setIsValidating('');
                    setDataInStorage('importId', '');
                    setDataInStorage('importLength', '');
                    // for unauthorized access
                    if (error.response && error.response.status === 401) {
                      dispatch(logOut());
                    }
                    Swal.fire({
                      icon: 'error',
                      title: 'Something went wrong!',
                      text: error.response
                        ? error.response.data.message || error.toString()
                        : error.toString()
                    });
                  },
                  onSuccess: (data) => {
                    setDataInStorage('importId', '');
                    setDataInStorage('importLength', '');
                    // cache.invalidateQueries("unmanaged-employees");
                    if (data?.existing?.length > 0) {
                      setIsValidating('');
                      Swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        text: data.message,
                        confirmButtonText: 'Show details'
                      }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                          const errors = data?.existing;

                          Swal.fire({
                            didRender: (data) => {
                              // to remove "," inserted automatically by swal2 after each list item
                              const ul = data.querySelector(`.${classes.import__err_ul}`);
                              const nodes = ul.childNodes;

                              nodes.forEach((node) => {
                                if (node.nodeType === Node.TEXT_NODE) {
                                  node.parentNode.removeChild(node); // removing any text content ohter than list-item
                                }
                              });

                              // removing extra margin from action buttons
                              const actions = document.querySelector('.swal2-actions');
                              actions.style.marginTop = 0;
                            },
                            html: `<h5>Following issues were encountered while adding clients</h5>
                        <ul class=${classes.import__err_ul}>
                        ${errors.map(
                          (resErr, index) =>
                            `<li class=${classes.import__err_li}>
                            <span>${(index += 1)}.&nbsp;</span>
                            <span class=${classes.import__err_msg}>${resErr.error}</span>
                            </li>`
                        )}
                        </ul>`
                          });
                        }
                      });
                    } else {
                      Swal.fire({
                        icon: 'success',
                        title: 'Successfull',
                        html: `<span>${data.message}<div style="color: red; font-weight: bold;margin: 0.5rem 0">IMPORTANT</div><mark>Please Assign Site Manager to all the Imported Sites</span>`
                      });
                    }
                  },
                  onSettled: () => {
                    // setIsImportingSites(false);
                    document.getElementById('import-sites-file').value = null;
                    cache.invalidateQueries('unmanaged-sites');
                    cache.invalidateQueries('sites');
                  }
                }
              );
            }
          } else {
            // setIsImportingSites(false);
            setIsValidating('');
            document.getElementById('import-sites-file').value = null;
            Swal.fire({
              didRender: (data) => {
                // to remove "," inserted automatically by swal2 after each list item
                const ul = data.querySelector(`.${classes.import__err_ul}`);
                const innerUls = data.querySelectorAll(`.${classes.import__err_inner_ul}`);

                const nodes = ul.childNodes;

                nodes.forEach((node) => {
                  if (node.nodeType === Node.TEXT_NODE) {
                    node.parentNode.removeChild(node); // removing any text content ohter than list-item
                  }
                });

                innerUls.forEach((list) => {
                  const nodes = list.childNodes;

                  nodes.forEach((node) => {
                    if (node.nodeType === Node.TEXT_NODE) {
                      node.parentNode.removeChild(node); // removing any text content ohter than list-item
                    }
                  });
                });

                // removing extra margin from action buttons
                const actions = document.querySelector('.swal2-actions');
                actions.style.marginTop = 0;
              },
              html: `<h6>Fix the following issues in your excel file (see instructions file for more details)</h6>
            <ul class=${classes.import__err_ul}>
            ${validationErrors.map(
              (resErr) =>
                `<li class=${classes.import__err_li}>
                <span class=${classes.import__err_number}>Row-${resErr.rowNumber}: </span>
                <span class=${classes.import__err_msg}>
                ${resErr.message}
                ${
                  resErr?.errors?.length > 1
                    ? `
                  <ul class=${classes.import__err_inner_ul}>
                  ${resErr.errors.map(
                    (er) => `
                <li>
                ${er}
                </li>
                `
                  )}
                  </ul>
                  `
                    : ''
                }
                </span>
                </li>`
            )}
            </ul>`
            });
          }
        }
      }
    },
    [dispatch, mutate, cache]
  );

  return (
    <>
      <TextField
        sx={{ display: 'none' }}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        id="import-sites-file"
        onChange={(event) => handleBulkUpload(event.target.files)}
        type="file"
      />
      <Button
        variant="contained"
        component="span"
        startIcon={<AddIcon />}
        sx={{ boxShadow: 'none' }}
        onClick={() => {
          Swal.fire({
            title: 'Import sites in bulk',
            html: `<span>From here you can import all of your sites in bulk, please refer <a href=${TRACKKAR_BULK_UPLOAD_FILES} download style='text-transform: underline'>this</a> for the upload format.</span>`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Upload file'
          }).then((result) => {
            if (result.isConfirmed) {
              document.getElementById('import-sites-file').click();
            }
          });
        }}
      >
        Import
      </Button>
    </>
  );
}
