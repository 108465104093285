/* eslint-disable no-nested-ternary */
// import { filter } from 'lodash';
import React, { useState, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Popover from '@mui/material/Popover';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import InfiniteScroll from 'react-infinite-scroll-component';
// icons
import InfoIcon from '@mui/icons-material/Info';
import { LeadListHead, LeadListToolbar, LeadMoreMenu } from '../components/_dashboard/lead';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
import { updateLeads } from '../api/mutations/adminMutations';
// hooks
import { useLeads } from '../hooks/get-leads-query';
// utils
import { DD_MM_YYYY } from '../utils/functions';
import { SWAL_TIME } from '../utils/config';
// redux
import { logOut } from '../redux/actions/adminActions';

// ---------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'business', label: 'Business Name', alignRight: false },
  { id: 'added_by', label: 'Added By', alignRight: false },
  { id: 'added_on', label: 'Added On', alignRight: false },
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// --------------------------------------------------------------

function EmployeeLeadHistory() {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [isUpdatingLeads, setIsUpdatingLeads] = useState(false);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    type: '',
    state: '',
    status: '',
    manager: ''
  });

  const {
    isLeadsLoading,
    isLeadsError,
    hasNextPage: hasNextLeads,
    fetchNextPage: fetchNextLeads,
    data: leads
  } = useLeads(filter.from, filter.to, filter.type, filter.state, filter.status, filter.manager);

  const rows = useMemo(() => {
    const listOfLeads = [];
    if (!isLeadsLoading && !isLeadsError) {
      if (leads?.pages?.length > 0) {
        leads.pages.forEach((group) => listOfLeads.push(group.sites));
      }
    }
    const flattened = listOfLeads.flat();
    // console.log('All leads', flattened);
    return flattened;
  }, [leads, isLeadsLoading, isLeadsError]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  /* ======================== Update Leads Status Mutation Function ====================== */

  const { mutate } = useMutation(updateLeads);

  const mutateData = (leadsData) => {
    // console.log("leads data: ", leadsData);

    mutate(leadsData, {
      onError: (error) => {
        // for unauthorized access
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!',
          text: error.response ? error.response.data.message || error.toString() : error.toString(),
          showConfirmButton: false
          // timer: 2000,
          // timerProgressBar: true
        });
      },
      onSuccess: (data) => {
        Swal.fire({
          icon: 'success',
          title: 'Successfull',
          text: data.message,
          showConfirmButton: false,
          timer: SWAL_TIME,
          timerProgressBar: true
        });
      },
      onSettled: () => {
        // setIsUpdatingLeads(false);
        cache.invalidateQueries('leads');
      }
    });
  };

  const handleSelected = (leadsData) => {
    mutateData(leadsData);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const filteredUsers = rows;

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Leads | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Leads
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => setOpenAddEmployee(true)}
          >
            New Employee
          </Button> */}
        </Stack>
        <Card>
          <LeadListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
            approveLeads={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: 'Add all selected lead to the clients?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, approve!'
              }).then((result) => {
                if (result.isConfirmed) {
                  if (selected.length > 0) {
                    handleSelected({
                      status: 'Approved',
                      leads: selected
                    });
                  }
                }
              });
            }}
            rejectLeads={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: 'Reject all selected leads?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, reject selected!'
              }).then((result) => {
                if (result.isConfirmed) {
                  if (selected.length > 0) {
                    handleSelected({
                      status: 'Rejected',
                      leads: selected
                    });
                  }
                }
              });
            }}
          />

          <TableContainer
            id="leads-table-container"
            sx={{ minWidth: 800, height: 'calc(100vh - 320px)' }}
          >
            <Table>
              <LeadListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const isItemSelected = selected.indexOf(row._id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={row._id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, row._id)}
                          />
                        </TableCell>
                        {/* <TableCell>{(index += 1)}</TableCell> */}
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            {row.type === 'cold' ? (
                              <Tooltip title="I am a cold lead">
                                <AcUnitIcon sx={{ color: '#80c5de' }} />
                              </Tooltip>
                            ) : (
                              <Tooltip title="I am a hot lead">
                                <LocalFireDepartmentIcon sx={{ color: '#F73718' }} />
                              </Tooltip>
                            )}
                            <Typography variant="subtitle2" noWrap>
                              {row?.name}
                            </Typography>
                            <InfoIcon
                              sx={{ cursor: 'pointer' }}
                              onClick={(event) =>
                                setAnchorEl({
                                  id: `info_${row._id}`,
                                  target: event.currentTarget
                                })
                              }
                            />

                            <Popover
                              id={`info_${row._id}`}
                              open={anchorEl.id === `info_${row._id}`}
                              anchorEl={anchorEl.target}
                              onClose={() => setAnchorEl({ id: null, target: null })}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <List>
                                <ListItem>
                                  <ListItemIcon>
                                    <PhoneAndroidIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={`+91 ${row?.mobile}`} />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <AlternateEmailIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={`${row?.email}`} />
                                </ListItem>
                              </List>
                            </Popover>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar alt={row?.addedBy?.name} src={row?.addedBy?.avatar} />
                            <Typography variant="subtitle2" noWrap>
                              {row?.addedBy?.name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{DD_MM_YYYY(row?.createdAt)}</TableCell>
                        <TableCell align="left">
                          <Avatar alt={row?.name} src={row?.image} />
                        </TableCell>
                        <TableCell align="left">
                          {row.status === 'Approved' ? (
                            <Typography>Approved</Typography>
                          ) : row.status === 'Rejected' ? (
                            <Typography>Rejected</Typography>
                          ) : (
                            <Typography>Pending</Typography>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <LeadMoreMenu
                            approveLead={() => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: 'Add this lead to the clients?',
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, approve!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  handleSelected({
                                    status: 'Approved',
                                    leads: [row._id]
                                  });
                                }
                              });
                            }}
                            rejectLead={() => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: 'Reject this lead?',
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, reject it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  handleSelected({
                                    status: 'Rejected',
                                    leads: [row._id]
                                  });
                                }
                              });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            {/* Put the scroll bar always on the bottom */}
            <InfiniteScroll
              dataLength={rows?.length}
              next={fetchNextLeads}
              // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
              // inverse //
              hasMore={hasNextLeads}
              loader={<h4>Loading...</h4>}
              scrollableTarget="leads-table-container"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            />
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
    </Page>
  );
}

export default EmployeeLeadHistory;
