/* eslint-disable new-cap */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable no-cond-assign */
/* eslint-disable no-useless-escape */
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
// import axios from 'axios';
import CryptoJS from 'crypto-js';
import CalendarDates from 'calendar-dates';
import { DayOfWeeks, WeekDaysToNumber } from './config';

// import { startLocationSocket } from '../containers/Location';
// import { employeeAxiosInstance } from '../containers/axios-defaults';

// import Geocode from 'react-geocode';

export function getUTCDate() {
  return new Date();
}
// standardized date
export function getCurrentDate() {
  const d = new Date();
  let dd = d.getDate();
  let mm = d.getMonth() + 1;
  const yyyy = d.getFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }

  const date = `${yyyy}-${mm}-${dd}`;
  return date;
}

// standardized time
export function getCurrentTime() {
  const t = new Date();
  let hh = t.getHours();
  let mm = t.getMinutes();
  if (hh < 10) {
    hh = `0${hh}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  const time = `${hh}:${mm}`;

  return time;
}

// 24hour time to utc
export function _24toUTC(time) {
  const [hour, min] = time.split(':');
  // Date.UTC(year, month, day, hours, minutes, seconds, millisec)
  const t = new Date();
  const UtCtime = new Date(t.getFullYear(), t.getMonth(), t.getDay(), hour, min);
  return UtCtime;
}

// Accepts a Date object or date string that is recognized by the Date.parse() method
export function getDayOfWeek(date) {
  const dayOfWeek = new Date(date).getDay();
  return Number.isNaN(dayOfWeek)
    ? null
    : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
}

export function convertUTCtoLocal(UTCDateString, onlyDate = false, dd = false) {
  const convertedLocalDateTime = new Date(UTCDateString);

  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour12: 'true',
    hour: '2-digit',
    minute: '2-digit'
    // timeZone: 'Asia/Kolkata',
  };
  if (onlyDate) {
    const date = convertedLocalDateTime.toLocaleDateString('en-GB').split('/').reverse().join('-');
    if (dd) {
      const d = Number(date.split('-')[2]);
      return d;
    }
    return convertedLocalDateTime.toLocaleDateString('en-GB').split('/').reverse().join('-');
  }
  return convertedLocalDateTime.toLocaleString(undefined, options);
}

export function DD_MM_YYYY(dateString) {
  const convertedLocalDateTime = new Date(dateString);
  return convertedLocalDateTime.toLocaleDateString('en-GB').split('/').join('-');
}

export function YYYY_MM_DD(dateString, sep) {
  if (sep === '-') {
    return dateString.split('-').reverse().join('-');
  }
  return DD_MM_YYYY(dateString).split('-').reverse().join('-');
}
export function MM_DD_YYYY(dateString) {
  const convertedLocalDateTime = new Date(dateString);
  const date = convertedLocalDateTime.toLocaleDateString('en-GB').split('/').join('-');
  const yyyy = convertedLocalDateTime.getFullYear();
  return `${date[3]}${date[4]}-${date[0]}${date[1]}-${yyyy}`;
}

export function correctDateFormat(dateString) {
  const ds = dateString.split('-');
  if (ds[1].length < 2 || ds[2].length < 2) {
    ds[1] = `0${ds[1]}`;
    ds[2] = `0${ds[2]}`;
  }
  return ds.join('-');
}

export function formatDate(dateString) {
  const dateObj = new Date(dateString).toISOString();
  return convertUTCtoLocal(dateObj, true);
}

// return example: 1st, 2nd, 3rd, 4th, etc
export function ordinal(number) {
  const ordinals = ['th', 'st', 'nd', 'rd'];
  const temp = number % 100;
  return number + (ordinals[(temp - 20) % 10] || ordinals[temp] || ordinals[0]); // clever mind
}

// english name date format e.g 16 October, 2020
export function englishDateFormat(date, withOrdinals = false, shortMonthName = false) {
  // eslint-disable-next-line prefer-const
  let [day, month, year] = date.split('-');
  // if date format is YYYY/MM/DD instead of DD/MM/YYYY
  if (day.length === 4) {
    [day, year] = [year, day];
  }
  let months = [];

  if (shortMonthName) {
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Augt', 'Sep', 'Oct', 'Nov', 'Dec'];
  } else {
    months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
  }

  if (withOrdinals) {
    let dd;
    if (day < 10) {
      dd = day.substring(1);
    } else {
      dd = day;
    }
    return `${ordinal(dd)} ${months[month - 1]}, ${year}`;
  }
  return `${day} ${months[month - 1]}, ${year}`;
}

export function formatDigit(num) {
  const snum = num?.toString();
  if (Number(num) < 10 && snum.length === 2) {
    return Number(snum.substring(1, snum.length));
  }
  return num;
}

// 12 hour time format e.g: 5:40 AM
export function _12hourTimeFormat(time) {
  let [hours, minutes] = time.split(':');

  const amOrpm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  if (minutes < 10) {
    if (minutes.length === 2) {
      minutes = minutes.substring(1);
    }
  }
  const formattedTime = `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  } ${amOrpm}`;

  return formattedTime;
}

// 24 hour time format
export function convertTime12to24(time12h) {
  const [time, modifier] = time12h.split(' ');
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  hours = Number(hours) < 10 ? `0${hours}` : hours;
  console.log(hours);

  return `${hours}:${minutes}`;
}

export function formatTime(time) {
  if (time.length > 0) {
    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time.split(':');
    const formattedTime = `${hours > 1 && hours < 10 ? `0${hours}` : hours}:${
      minutes > 1 && minutes < 10 ? `0${minutes}` : minutes
    }`;
    return formattedTime;
  }
  return '00:00';
}

export function alertDateTimeFormat(isoTime) {
  const date = new Date(isoTime);

  const [YYYY, MM, DD, hh, mm, ss] = [
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  ];

  const dateTime = new Date(Date.UTC(YYYY, MM, DD, hh, mm, ss)).toUTCString();
  const dateTimeArray = dateTime.split(' ');

  const formattedDate = dateTimeArray.slice(1, 4).join(' ');
  const formattedTime = _12hourTimeFormat(dateTimeArray[4]);

  return `${formattedTime}, ${formattedDate}`;
}

// base64Img
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// capitalize each word
export function capitalizeInitial(text) {
  if (typeof text === 'string') {
    return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
  }
  return text;
}

// capitalize each word
export function capitalizeEachWord(text) {
  if (typeof text === 'string') {
    return text
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }
  return text;
}

export const validateEnglishDate = (value, setterFn, required = true) => {
  let errors = false;
  // let pattern = /\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|(Nov|Dec)(?:ember)?)\s([0][1-9]|[12][0-9]|3[01])(\s|,\s)(?:20[7-9]\d|2\d{3})(?=\D|$)\b/;
  const pattern =
    /\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|(Nov|Dec)(?:ember)?)\s([0][1-9]|[12][0-9]|3[01])(\s|,\s)(?:[1-9])\d{3}(?=\D|$)\b/;
  if (!value && required) {
    errors = true;
    setterFn('This field is required');
  } else if (!value && !required) {
    errors = true;
    setterFn(`This field can't be empty`);
  } else if (value && !value.match(pattern)) {
    errors = true;
    setterFn('Invalid date');
  } else {
    setterFn(false);
  }

  return !!errors; // errors ? true : false
};

export function getStateFromAddress(address) {
  let state = address
    .split(',')
    [address.split(',')?.length - 2]?.replace(/\d+|^\s+|\s+$/g, '')
    .trim();

  if (state === 'Hyderabad') {
    state = 'Telangana';
  }

  return state;
}

// function to connect socket after reload
// export async function checkAndUpdateSocketStatus(userId) {
//   if (window.location.reload) {
//     console.log('reloaded or rerendered');

//     const config = {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${getDataFromStorage('employeeAuthToken', true)}`
//       }
//     };

//     const err = await axios
//       .get(`${baseURL}/employee/check-leave/?id=${userId}&date=${getCurrentDate()}`, config)
//       .then((response) => {
//         if (response.data && response.data.attendance) {
//           const { attendtype, status } = response.data.attendance;
//           if (attendtype) {
//             console.log('attendance: has marked in only');
//             // startLocationSocket(userId);
//           } else if (!attendtype) {
//             console.log('attendance: has marked out');
//           }
//           if (!status) {
//             console.log('on leave today');
//           }
//         } else {
//           console.log(`hasn't marked attendance yet`);
//         }
//       })
//       .catch((error) => {
//         console.log('checkandupdateSocketErr ', error);
//         if (error.response) {
//           console.log('err res', error.response);
//         }
//         return error;
//       });
//     return err;
//   }
// }

export function checkForSameLocation(arr) {
  let result = false;
  // create a Set with array elements

  const _arr = arr.map((ar) => `${ar[0]}, ${ar[1]}`);

  const s = new Set(_arr);

  if (_arr.length !== s.size) {
    result = true;
  }

  return result;
}

export const filterByDate = (rows, dateFilterValue) => {
  if (rows.length > 0) {
    const noMatchFoundMsg = document.querySelector('#no-match-found');
    const currentDateRows = rows.filter(
      (row) => convertUTCtoLocal(row.createdAt, true) === dateFilterValue
    );

    if (currentDateRows.length > 0) {
      if (!noMatchFoundMsg.classList.contains('hide')) {
        noMatchFoundMsg.classList.add('hide');
      }
    } else {
      noMatchFoundMsg.classList.remove('hide');
    }
  }
};

// function to get address from lattitude and longitude
// export async function getAddressFromCordinates(latt, lngt) {
//   let address;
//   if (latt && lngt) {
//     // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
//     Geocode.setApiKey('AIzaSyBtvd6aTmLkNwTS_TqzVfjNGBppvftKo5w');

//     // set response language. Defaults to english.
//     Geocode.setLanguage('en');

//     // set response region. Its optional.
//     // A Geocoding request with region=es (Spain) will return the Spanish city.
//     Geocode.setRegion('in');

//     // Enable or disable logs. Its optional.
//     // Geocode.enableDebug();

//     const { results } = await Geocode.fromLatLng(
//       latt.toString(),
//       lngt.toString()
//     );

//     address = results[0].formatted_address;
//   } else {
//     address = 'Location not available';
//   }
//   return address;
// }

/* Function to search data from the table (single column search from entire row).
    args: Table-id, inputbox-id, column-index to search from.
    Note: add class 'searchable' to the rows which you want to search from.
    Note: If the searchable column has any element other than text, wrap the text with an element of class 'searchable-data', 
          if only the text is present then leave the column as it is.
*/
export function searchFromTable(
  event,
  sourceTableId,
  searchBoxId,
  columnIndex,
  hasOtherElements = false
) {
  const input = document.getElementById(searchBoxId);

  // clear input value on clicking of clear icon
  if (event.target.tagName === 'I') {
    input.value = '';
  }

  const filter = input.value.toUpperCase();
  const table = document.getElementById(sourceTableId);
  // select all rows with class 'searchable' from the table
  const rows = table.querySelectorAll('tbody > tr.searchable');

  const visibleRows = [];
  let txtValue;
  // Loop through all selected rows, and hide those who don't match the search query
  rows.forEach((row) => {
    // selecting column for searching
    const column = row.getElementsByTagName('td')[columnIndex];

    if (column) {
      // if td tag has anything other than text as its children
      if (hasOtherElements) {
        const element = column.querySelector('.searchable-data');
        txtValue = element.textContent || element.innerText;
      } else {
        txtValue = column.textContent || column.innerText;
      }

      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        row.style.display = '';
        if (row.id) {
          visibleRows.push(row.id);
        }
      } else {
        row.style.display = 'none';
      }
    }
  });

  // check and show 'Not Found' if no match is found.
  checkIfNoMatch(table, rows);

  return visibleRows;
}

function checkIfNoMatch(table, rows) {
  const tbody = table.getElementsByTagName('tbody')[0];
  let noMatchFound;
  let atleastOneMatch;

  for (let i = 0; i < rows.length; i += 1) {
    // only check for searchable rows
    if (rows[i].classList.contains('searchable')) {
      if (rows[i].style.display === 'none') {
        noMatchFound = true;
        atleastOneMatch = false;
      } else {
        noMatchFound = false;
        atleastOneMatch = true;
        break;
      }
    }
  }
  // toggle visibility for rows without search item
  const unsearchableRows = table.querySelectorAll('tbody > tr:not(.searchable)');

  // only show 'No match found' once if no search result is found & the message is not already displayed
  if (noMatchFound && !tbody.contains(tbody.querySelector('.empty-table-message'))) {
    unsearchableRows.forEach((row) => (row.style.display = 'none'));

    const emptyRow = tbody.insertRow();
    emptyRow.classList.add('empty-table-message');
    emptyRow.style.cursor = 'default';
    const totalCells = calculateCells(table);
    emptyRow.innerHTML = `<td colspan=${totalCells} style='color: #721c24; background-color: #f8d7da; text-align: center; font-family: 'Dosis',sans-seriff; font-weight: 600 '>No match found!</td>`;
  }
  if (!noMatchFound) {
    unsearchableRows.forEach((row) => (row.style.display = ''));
  }

  const insertedRow = tbody.querySelector('.empty-table-message');
  if (atleastOneMatch && tbody.contains(insertedRow)) {
    insertedRow.remove();
  }
}

function calculateCells(table) {
  let max = 0;
  for (let i = 0; i < table.rows.length; i += 1) {
    if (max < table.rows[i].cells.length) max = table.rows[i].cells.length;
  }
  return max;
}

export function daysInMonth(month, year) {
  let number;
  const dateArray = [];

  if (month === 4 || month === 6 || month === 9 || month === 11) {
    number = 30;
  } else if (month === 2) {
    if (year % 4 === 0) {
      number = 29;
    } else {
      number = 28;
    }
  } else {
    number = 31;
  }

  for (let i = 1; i <= number; i += 1) {
    dateArray.push(i);
  }
  return dateArray;
}

export function absentArray(month, year, doj) {
  let number;
  const absentArray = [];
  if (month === 4 || month === 6 || month === 9 || month === 11) {
    number = 30;
  } else if (month === 2) {
    if (year % 4 === 0) {
      number = 29;
    } else {
      number = 28;
    }
  } else {
    number = 31;
  }

  const [yyyy, mm, dd] = getCurrentDate()
    .split('-')
    .map((x) => +x);
  const currentDate = new Date(yyyy, mm - 1, dd, 0, 0, 0, 0);

  for (let i = 1; i <= number; i += 1) {
    const tempDate = new Date(Number(year), Number(month - 1), i, 0, 0, 0, 0);
    const dateOfJoining = new Date(convertUTCtoLocal(doj, true)).setHours(0, 0, 0, 0);

    if (tempDate >= currentDate || tempDate < dateOfJoining) {
      absentArray.push('-');
    } else {
      absentArray.push('A');
    }
  }
  return absentArray;
}

function safeDecodeRange(range) {
  const o = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };
  let idx = 0;
  let i = 0;
  let cc = 0;
  const len = range.length;
  for (idx = 0; i < len; ++i) {
    if ((cc = range.charCodeAt(i) - 64) < 1 || cc > 26) break;
    idx = 26 * idx + cc;
  }
  o.s.c = --idx;

  for (idx = 0; i < len; ++i) {
    if ((cc = range.charCodeAt(i) - 48) < 0 || cc > 9) break;
    idx = 10 * idx + cc;
  }
  o.s.r = --idx;

  if (i === len || range.charCodeAt(++i) === 58) {
    o.e.c = o.s.c;
    o.e.r = o.s.r;
    return o;
  }

  for (idx = 0; i !== len; ++i) {
    if ((cc = range.charCodeAt(i) - 64) < 1 || cc > 26) break;
    idx = 26 * idx + cc;
  }
  o.e.c = --idx;

  for (idx = 0; i !== len; ++i) {
    if ((cc = range.charCodeAt(i) - 48) < 0 || cc > 9) break;
    idx = 10 * idx + cc;
  }
  o.e.r = --idx;
  return o;
}

export function getHeaders(sheet) {
  let header = 0;
  let offset = 1;
  const hdr = [];
  const o = {};
  if (sheet == null || sheet['!ref'] == null) return [];
  const range = o.range !== undefined ? o.range : sheet['!ref'];
  let r;
  if (o.header === 1) header = 1;
  else if (o.header === 'A') header = 2;
  else if (Array.isArray(o.header)) header = 3;
  switch (typeof range) {
    case 'string':
      r = safeDecodeRange(range);
      break;
    case 'number':
      r = safeDecodeRange(sheet['!ref']);
      r.s.r = range;
      break;
    default:
      r = range;
  }
  // eslint-disable-next-line no-unused-vars
  if (header > 0) offset = 0;
  const rr = XLSX.utils.encode_row(r.s.r);
  const cols = new Array(r.e.c - r.s.c + 1);
  for (let C = r.s.c; C <= r.e.c; ++C) {
    cols[C] = XLSX.utils.encode_col(C);
    const val = sheet[cols[C] + rr];
    switch (header) {
      case 1:
        hdr.push(C);
        break;
      case 2:
        hdr.push(cols[C]);
        break;
      case 3:
        hdr.push(o.header[C - r.s.c]);
        break;
      default:
        // eslint-disable-next-line no-continue
        if (val === undefined) continue;
        hdr.push(XLSX.utils.format_cell(val));
    }
  }
  return hdr;
}

export function excelToJSON(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      workbook.SheetNames.forEach((sheetName) => {
        const workbookHeaders = getHeaders(workbook.Sheets[sheetName]);
        const XLRowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        // const result = XL_row_object.map((row) => {
        //   const tempObj = {
        //     name: capitalizeEachWord(row['Name']),
        //     mobile: row['Mobile (+91)'],
        //     email: row['E-mail (optional'].toLowerCase(),
        //     business: capitalizeInitial(row['Type of business']),
        //     // doj: row['Date of joining (YYYY-MM-DD)'],
        //     // dob: row['Date of birth (YYYY-MM-DD)'],
        //     // address: row['Address'],
        //   };
        //   return tempObj;
        // });
        resolve({
          headers: workbookHeaders,
          rows: XLRowObject
        });
      });
    };
    reader.onerror = (event) => {
      console.error('File could not be read: ', event.target.error);
      reject(event.target.error);
    };

    reader.readAsBinaryString(file);
  });
}

// eslint-disable-next-line consistent-return
export function putCommas(number) {
  if (number >= 0) {
    const reversed = number.toString().split('').reverse();
    const finalReversed = [];
    for (let i = 0; i < reversed.length; i += 1) {
      if (reversed.length > 4) {
        if (i !== 1 && i % 2 !== 0) {
          finalReversed.push(',');
        }
      }
      finalReversed.push(reversed[i]);
    }
    return finalReversed.reverse().join('');
  }
}

/**
 * Function to generate formatted file name
 * @param {string} name - actual name of the file
 * @returns {string} - formatted file name
 */
export function generateFileName(name) {
  return `Trackkar_${name}`;
}

/**
 * Function to set encryption key
 * @param {string} key - encryption key name
 * @param {string} value string value to be set as encryption key
 */
export function setEncryptionKey(key, value) {
  if (key && value) {
    const sha256Key = CryptoJS.SHA256(key).toString();
    // console.log('setting key: ', key, value);
    const sha256Data = CryptoJS.SHA256(value).toString();

    localStorage.setItem(sha256Key, JSON.stringify(sha256Data));
  } else {
    console.error('No data provided');
  }
}

/**
 * function to get the encryption key
 * @param {string} - name of the key to get encryption key for
 * @returns {string}
 */
// eslint-disable-next-line consistent-return
export function getEncryptionKey(key) {
  try {
    const shaKey = CryptoJS.SHA256(key).toString();
    // console.log('getting key: ', key, localStorage.getItem(shaKey));
    return localStorage.getItem(shaKey);
  } catch (err) {
    console.error('something went wrong man hoho');
  }
}

export function daysCountInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

/**
 * Funtion to store data in local storage
 * Pass second argument as true if setting data for employee side
 * @param {string} key key for the value to be stored
 * @param {*} data actual data to be stored with the provided key
 * @param {boolean} [useEmployeeKey] - flag to decide which encyption key to use, (employee's or admin's)
 */
export function setDataInStorage(key, data, useEmployeeKey) {
  // Encrypt key
  const shaKey = CryptoJS.SHA256(key).toString();

  const encKeyName = useEmployeeKey ? 'employee_key' : 'admin_key';

  const encryptionKey = getEncryptionKey(encKeyName);

  // console.log('setting data: ', encryption_key, key, shaKey, data);

  if (encryptionKey) {
    // Encrypt data
    const dataCipher = CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();

    localStorage.setItem(shaKey, JSON.stringify(dataCipher));
  }
}

/**
 * Function to get data from local storage
 * Pass second argument as true if getting data for employee side
 * @param {string} key - key of the item to fetch from local storage
 * @param {boolean} [useEmployeeKey] - flag to decide which encyption key to use, (employee's or admin's)
 * @returns {*} value associated with the key
 */
// eslint-disable-next-line consistent-return
export function getDataFromStorage(key, useEmployeeKey) {
  try {
    const shaKey = CryptoJS.SHA256(key).toString();

    const encKeyName = useEmployeeKey ? 'employee_key' : 'admin_key';

    const encryptionKey = getEncryptionKey(encKeyName);

    // console.log(
    //   'from getting data: ',
    //   key,
    //   'encryption-key',
    //   typeof encryption_key,
    //   encryption_key
    // );
    if (encryptionKey) {
      const ciphertext = JSON.parse(localStorage.getItem(shaKey));
      if (ciphertext) {
        // Decrypt
        const bytes = CryptoJS.AES.decrypt(ciphertext, encryptionKey);
        const originalData = bytes.toString(CryptoJS.enc.Utf8);
        // console.log('getting data: ', key, shaKey, originalData);
        return JSON.parse(originalData);
      }
    }
  } catch (err) {
    // console.error('Error getting data from storage boi boi');
  }
}

/**
 * Function to clear items from local storage
 * @param {(string|string[])} keys - key to remove or array of keys
 */
export function clearStorage(keys) {
  try {
    if (Array.isArray(keys)) {
      keys.forEach((key) => {
        const shaKey = CryptoJS.SHA256(key).toString();
        localStorage.removeItem(shaKey);
      });
    } else if (typeof keys === 'string') {
      const shaKey = CryptoJS.SHA256(keys).toString();
      localStorage.removeItem(shaKey);
    }
  } catch (err) {
    console.error('Error clearing storage', err);
  }
}

export const getDatesBetween = (start, end) => {
  const arr = [];
  const dt = new Date(start);
  for (arr; dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

// a and b are javascript Date objects
export function dateDiffInMin(dt1, dt2) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export function arrayMin(arr) {
  let len = arr.length;
  let min = Infinity;
  // eslint-disable-next-line no-plusplus
  while (len--) {
    if (arr[len] < min) {
      min = arr[len];
    }
  }
  return min;
}

export function arrayMax(arr) {
  let lenMax = arr.length;
  let max = -Infinity;
  // eslint-disable-next-line no-plusplus
  while (lenMax--) {
    if (arr[lenMax] > max) {
      max = arr[lenMax];
    }
  }
  return max;
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

export const formatDuration = (milliseconds) => {
  if (milliseconds < 0) milliseconds = -milliseconds;
  const time = {
    day: Math.floor(milliseconds / 86400000),
    hour: Math.floor(milliseconds / 3600000) % 24,
    min: Math.floor(milliseconds / 60000) % 60,
    sec: Math.floor(milliseconds / 1000) % 60,
    ms: Math.floor(milliseconds) % 1000
  };
  return Object.entries(time)
    .filter((val) => val[1] !== 0)
    .map((val) => `${val[1]} ${val[1] !== 1 ? `${val[0]}` : val[0]}`)
    .join(' ');
};

export function Encrypt(word, key) {
  const encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString();
  const encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
}

export function Decrypt(word, key) {
  const decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
  const bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8);
  return bytes;
}

export function randomString(len) {
  const p = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // eslint-disable-next-line no-bitwise
  return [...Array(len)].reduce((a) => a + p[~~(Math.random() * p.length)], '');
}

export function convertTimeToNumber(time) {
  const hours = Number(time.split(':')[0]);
  const minutes = Number(time.split(':')[1]) / 60;
  return hours + minutes;
}

export function convertTimeToHuman(time) {
  const hours = Number(time.split(':')[0]);
  const minutes = Number(time.split(':')[1]);
  if (hours === 0 && minutes === 0) {
    return '0 hrs';
  }
  return `${hours} hrs${minutes > 0 ? `, ${minutes} min` : ''}`;
}

export function getFirstDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getLastDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export async function getMonthCalendar(date) {
  const calendarDates = new CalendarDates();
  const mayDates = await calendarDates.getDates(date);
  // const mayMatrix = await calendarDates.getMatrix(may2018);
  const newarray = [].concat(...Array(7).fill(DayOfWeeks));
  mayDates.map((date, index) => {
    date.day = newarray[index];
    date.wdn = date.day;
    date.wd = WeekDaysToNumber[date.day];
    return date;
  });
  const currentMonthWeekdays = mayDates.filter((date) => date.type === 'current');
  return currentMonthWeekdays;
}

export async function getDaysFromMonth(days) {
  const cal = await getMonthCalendar(new Date());
  return cal
    .map((m) => {
      if (days.includes(m.wd)) {
        return m.date;
      }
      return null;
    })
    .filter((f) => f !== null);
}

/**
 * Calculates difference between two 24 hour time formats (HH:MM)
 * @param {string} start - starting 24 hour time format
 * @param {string} end - ending 24 hour time format
 * @param {Boolean} notAShiftInterval - whether the start and end is from a shift timing interval or not, if yes, then timings will be calculated as per shift timing interval spanning through one or more than 1 day
 * @param {Boolean} truncateToZeroForSmallerStart - if true, and start time is shorter than end time, then returns 00:00
 * @returns difference between start and end in 24 hour time format
 */
export function getDifferenceInHours(
  start,
  end,
  notAShiftInterval = false,
  truncateToZeroForSmallerStart = false
) {
  if (truncateToZeroForSmallerStart && start < end) {
    return '00:00';
  }

  /* let startTiming = notAShift ? start > end ? end : start : start; */
  let startTiming = start;
  if (notAShiftInterval && start > end) {
    startTiming = end;
  }
  const endTiming = startTiming === start ? end : start;

  const [shh, smm] = startTiming.split(':').map((n) => Number(n));
  const [ehh, emm] = endTiming.split(':').map((n) => Number(n));

  let hours = 0;
  let minutes = 0;

  const smin = smm + 60 * shh;
  const emin = emm + 60 * ehh;
  const mDiff = startTiming > endTiming ? Math.abs(smin - 24 * 60 - emin) : emin - smin;
  hours = Math.floor(mDiff / 60);
  minutes = mDiff % 60;

  return `${hours.toString().padStart(2, 0)}:${minutes.toString().padStart(2, 0)}`;
}

export function arraysEqual(first, second, unequal) {
  const diff = [];
  if (unequal) {
    for (let i = 0; i < first.length; i++) {
      if (!second.includes(first[i])) {
        diff.push(first[i]);
      }
    }
  } else {
    if (first.length !== second.length) {
      return false;
    }
    for (let i = 0; i < first.length; i++) {
      if (!second.includes(first[i])) {
        return false;
      }
    }
  }
  return unequal ? diff : true;
}

// export function detectMobile() {
//   return window.innerWidth <= 800 && window.innerHeight <= 600;
// }

export function detectMobile() {
  let check = false;
  // eslint-disable-next-line func-names
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function humanTime(dateFuture) {
  const dateNow = new Date();
  let d = Math.abs(dateFuture - dateNow) / 1000; // delta
  const r = {}; // result
  const s = {
    // structure
    years: 31536000,
    months: 2592000,
    weeks: 604800,
    days: 86400,
    hours: 3600,
    minutes: 60,
    seconds: 1
  };
  Object.keys(s).forEach((key) => {
    r[key] = Math.floor(d / s[key]);
    d -= r[key] * s[key];
  });
  // for example: {year:0,month:0,week:1,day:2,hour:34,minute:56,second:7}
  return r;
}

export function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}

export function truncateString(str, len) {
  if (str.length > len) {
    return `${str.substring(0, len)}...`;
  }
  return str;
}

export function stringToDate(dateString, firstDayMonth) {
  const date = dateString.split('-');
  return new Date(
    Number(date[0]),
    Number(formatDigit(date[1]) - 1),
    Number(firstDayMonth ? 1 : formatDigit(date[2])),
    0,
    0,
    0,
    0
  );
}

export const uid = () => Date.now().toString(36) + Math.random().toString(36).substr(2);

export function dateDiffInYears(dateold, datenew) {
  const ynew = datenew.getFullYear();
  const mnew = datenew.getMonth();
  const dnew = datenew.getDate();
  const yold = dateold.getFullYear();
  const mold = dateold.getMonth();
  const dold = dateold.getDate();
  let diff = ynew - yold;
  if (mold > mnew) diff--;
  else if (mold === mnew) {
    if (dold > dnew) diff--;
  }
  return diff;
}

function startOfDay(date) {
  return new Date(
    typeof date.getMonth === 'function'
      ? date.setHours(0, 0, 0, 0)
      : new Date(date).setHours(0, 0, 0, 0)
  );
}

/**
 * Function to categorize years by leaves calculation year
 * @param {Array} dates - array of dates
 * @param {number} startMonth - start month of the leaves calculation
 * @returns {Array} - array of years
 */
export function getLeaveYearsFromDates(dates, startMonth) {
  const years = new Set();
  dates.forEach((date) => {
    const dt = startOfDay(date);

    if (dt.getMonth() + 1 < startMonth) {
      years.add(dt.getFullYear() - 1);
    } else {
      years.add(dt.getFullYear());
    }
  });

  return Array.from(years).sort((a, b) => a - b);
}

export function _daysInMonth(now) {
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}

/**
 * Calculate Loan Installment of Employee
 * @param {number} amount - Loan Total Amount
 * @param {number} tenure - Tenure Of Loan
 * @param {number} interest - Interest On Loan
 * @returns {number} Loan Installment
 */
export function calculateLoanInstallment(amount, tenure, interest) {
  let interestAmt = 0;
  if (interest) {
    interestAmt = (amount * interest) / 100;
  }
  return Number(parseFloat((amount + interestAmt) / tenure).toFixed(2));
}

/**
 * Calculate Loan Installment of Employee
 * @param {string} html_content - HTML Content
 * @param {string} save_name - Saved File Name
 */
export function htmlToPDF(html_content, save_name, config) {
  html2canvas(html_content).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF(config);
    pdf.addImage(imgData, 'JPEG', 5, 5);
    // pdf.output('dataurlnewwindow');
    pdf.save(`${save_name}.pdf`);
  });
}
