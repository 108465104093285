import { useInfiniteQuery, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchAllShifts, fetchShiftById } from '../api/queries/Queries';

export const useShifts = (employee, site, from, to, date, token) => {
  // console.log("Site Search", search)
  const dispatch = useDispatch();

  return useInfiniteQuery(['shifts', employee, site, from, to, date, token], fetchAllShifts, {
    enabled: !!site,
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export const useShiftById = (id) => {
  // console.log("Site Search", search)
  const dispatch = useDispatch();
  return useQuery(
    // eslint-disable-next-line block-scoped-var
    ['shift', id],
    // eslint-disable-next-line block-scoped-var
    () => fetchShiftById(id),
    {
      // eslint-disable-next-line block-scoped-var
      enabled: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};
