/* eslint-disable no-nested-ternary */
// import { filter } from 'lodash';
import React, { useState, useMemo, useEffect, forwardRef, Fragment } from 'react';
import { useSelector } from 'react-redux';
import CalendarDates from 'calendar-dates';
import { Link as RouterLink, useParams } from 'react-router-dom';
// import CryptoJS from 'crypto-js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import Swal from 'sweetalert2/src/sweetalert2';
import InfiniteScroll from 'react-infinite-scroll-component';
// import '@sweetalert2/theme-material-ui/material-ui.css';
import {
  Popover,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  LinearProgress,
  Avatar,
  Box,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Grid,
  Alert,
  TextField,
  Button,
  OutlinedInput,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShareIcon from '@mui/icons-material/Share';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import KeyIcon from '@mui/icons-material/Key';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled, useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
// components
import {
  // EmailShareButton,
  // FacebookShareButton,
  // HatenaShareButton,
  // InstapaperShareButton,
  // LineShareButton,
  // LinkedinShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  // PinterestShareButton,
  // PocketShareButton,
  // RedditShareButton,
  // TelegramShareButton,
  // TumblrShareButton,
  // TwitterShareButton,
  // ViberShareButton,
  // VKShareButton,
  WhatsappShareButton
  // WorkplaceShareButton
} from 'react-share';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ImageZoom } from '../components';
import Logo from '../components/Logo';
import Page from '../components/Page';
import { Requirements } from './Roster';
// import NotAvailable from '../components/NotAvailable';
// date picker
// hooks
import { useRoles } from '../hooks/roles';
import { useShifts } from '../hooks/get-shifts-query';
import { useRoster } from '../hooks/roster';
import { useSiteById } from '../hooks/site';
import { colors } from '../theme/colors';
import { useSubRoles } from '../hooks/subRoles';
import { createShortenURL } from '../api/mutations/adminMutations';
import useMutationFunc from '../api/mutations/mutation';
// utils
import {
  getCurrentDate,
  convertUTCtoLocal,
  _12hourTimeFormat,
  getDataFromStorage,
  Decrypt,
  getRandomInt,
  randomString,
  Encrypt,
  formatDigit
} from '../utils/functions';
import { DayOfWeeks, baseURL, months } from '../utils/config';

// ------------------------------------------------------------------------------------------
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

let TABLE_HEAD = [];

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    boxShadow: theme.shadows.bloated,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  maxWidth: 285,
  minWidth: 120,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '& input': { padding: '8px', fontSize: 14 },
  '&.Mui-focused': { width: 290, boxShadow: theme.customShadows.bloated },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// --------------------------------------------------------------

function ShareRoster() {
  const { isAdminAuthenticated } = useSelector((state) => state.adminInfo);
  const params = useParams();
  const theme = useTheme();
  const today = new Date();
  const [isValid, setIsValid] = useState();
  // console.log(params);
  let token = null;
  let passcode = null;
  let siteId = null;
  const monthDate = params.date?.replace(':', '')?.split('-');

  if (isAdminAuthenticated && params.edata.length < 100) {
    siteId = params.edata;
  } else {
    const edata = decodeURIComponent(params.edata.split(':')[1]);
    const key = edata.substring(0, 10);
    const originalText = Decrypt(edata.substring(10, edata.length), `sec ${key}`).split(',');
    // console.log(originalText);
    siteId = originalText[0].substring(1, originalText[0].length);
    // eslint-disable-next-line prefer-destructuring
    passcode = originalText[1];
    token = isAdminAuthenticated
      ? undefined
      : originalText[2]?.substring(0, originalText[2]?.length - 1);
    // if (passcode === '00000') {
    //   setIsValid(true);
    // }
  }
  // console.log(passcode);
  // console.log('Token:', token);
  const [imageSrc, setImageSrc] = useState();
  const [currentMonth, setCurrentMonth] = useState([]);
  const [rosterMonth, setRosterMonth] = useState(
    new Date(
      Number(monthDate[0]),
      Number(formatDigit(monthDate[1]) - 1),
      Number(formatDigit(monthDate[2])),
      0,
      0,
      0,
      0
    )
  );
  const monthDay = rosterMonth.getMonth() > today.getMonth() ? 1 : today.getDate();
  const [selReqDay, setSelReqDay] = useState({
    iso: `${monthDate[0]}-${monthDate[1]}-${monthDay < 10 ? `0${monthDay}` : monthDay}`, // DD_MM_YYYY(today)
    date: rosterMonth.getMonth() > today.getMonth() ? monthDay : today.getDate()
  });
  const [filterName, setFilterName] = useState('');
  // ------------------------------------------------------
  const [showEmpInfo, setShowEmpInfo] = useState(false);
  const [code, setCode] = useState();
  const [error, setError] = useState('');
  // --------------------------------------------------
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    role: '',
    subRole: '',
    site: '',
    shift: ''
  });
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [openRequirements, setOpenRequirements] = useState(false);
  const [roleOptions, setRoleOptions] = React.useState(); // Roles initial State for select
  const [subRoleOptions, setSubRoleOptions] = useState();
  const [anchorRosterFilterEl, setAnchorRosterFilterEl] = useState(null);
  const [shiftColor, setShiftColor] = useState({});
  const { data } = useSiteById(siteId, token);
  const [copyClipboard, setCopyClipboard] = useState(false);
  const [copied, setCopied] = useState(false);
  const [codeInfo, setCodeInfo] = useState({
    code: '',
    cipher: '',
    key: ''
  });
  const [shareURL, setShareURL] = useState(null);

  useEffect(() => {
    // setDataInStorage('roster_month', rosterMonth); // Setting roster Month in Storage
    const mainAsync = async () => {
      const calendarDates = new CalendarDates();
      const mayDates = await calendarDates.getDates(rosterMonth);
      // const mayMatrix = await calendarDates.getMatrix(may2018);
      const newarray = [].concat(...Array(7).fill(DayOfWeeks));
      mayDates.map((date, index) => (date.day = newarray[index]));
      const currentMonthWeekdays = mayDates.filter((date) => date.type === 'current');
      setCurrentMonth([...currentMonthWeekdays]);
    };
    mainAsync();
  }, [rosterMonth]);

  // useEffect(() => {
  //   console.log('Filter:', filter);
  // }, [filter]);
  /* ===================== Roles ===================== */

  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(true, token); // gettings roles

  const allRoles = React.useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  React.useEffect(() => {
    // console.log('All Roles:', allRoles);
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      const sm = allRoles.find((role) => role.is_site_manager === true);
      // console.log('Site Manager:', sm); // site Manager
      // setSMPriority(sm?.priority);
      allRoles.forEach((role) => {
        if (role.priority > sm.priority) {
          formattedRoles.push([`${role._id},${role?.name}`, role?.name]);
        }
      });
      setRoleOptions([...formattedRoles]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allRoles]);

  /* ================= End Roles =============== */

  /* ================== Sub Roles ======================== */

  const {
    isLoading: isSubRolesLoading,
    isError: isSubRolesError,
    data: subRoles
  } = useSubRoles(filter.role.split(',')[0], token);

  useMemo(() => {
    const listOfSubRoles = [];
    if (!isSubRolesLoading && !isSubRolesError) {
      if (subRoles?.roles?.length > 0) {
        subRoles.roles[0].subroles.forEach((role) =>
          listOfSubRoles.push([`${role._id},${role.name}`, role.name])
        );
      }
    }
    // console.log('Sub Roles By Role:', listOfSubRoles);
    setSubRoleOptions([...listOfSubRoles]);
  }, [subRoles, isSubRolesLoading, isSubRolesError]);

  /* ================== End Sub Roles ====================== */

  const {
    isLoading: isRosterLoading,
    // isFetching: isRosterFetching,
    isError: isRosterError,
    hasNextPage: hasNextRoster,
    fetchNextPage: fetchNextRoster,
    // refetch: refetchRoster,
    data: roster
  } = useRoster(
    convertUTCtoLocal(rosterMonth, true) ?? getCurrentDate(),
    filterName, // search
    null, // state
    null, // gender
    filter?.role.split(',')[0],
    filter?.subRole.split(',')[0],
    siteId,
    filter?.shift.split(',')[0],
    token,
    null
  );
  const rows = useMemo(() => {
    const TableHead = [];
    const listOfRoster = [];
    if (!isRosterLoading && !isRosterError) {
      if (roster?.pages?.length > 0) {
        roster.pages.forEach((group) => {
          listOfRoster.push(group.employees);
        });
      }
    }
    const flattened = listOfRoster.flat();
    // console.log(flattened);
    currentMonth.forEach((obj) => {
      TableHead.push({
        id: `${obj.day}-${obj.date}`,
        label: { date: obj.date, day: obj.day.substring(0, 2) },
        alignRight: false
      });
    });
    TABLE_HEAD = TableHead;
    return flattened;
  }, [roster, isRosterLoading, isRosterError, currentMonth]);

  const {
    isLoading: isShiftsLoading,
    isError: isShiftsError,
    data: shifts
  } = useShifts('', siteId, null, null, null, token);

  const AllShifts = useMemo(() => {
    const listOfShifts = [];
    const color = shiftColor;
    if (!isShiftsLoading && !isShiftsError) {
      if (shifts?.pages?.length > 0) {
        shifts.pages.forEach((group) => listOfShifts.push(group.shifts));
      }
    }
    const flattened = listOfShifts.flat();
    flattened.forEach((ft, idx) => (color[ft._id] = colors[idx].value));
    setShiftColor({ ...color });
    // console.log('Shift Colors:', shiftColor); // Shift Colors
    // console.log('All Shifts:', flattened);
    return flattened;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShiftsLoading, isShiftsError, shifts]);

  // For Roster Sharing ====================================
  const tinyURLMutation = useMutationFunc(
    createShortenURL,
    {
      onMutate: { default: false },
      onSuccess: { default: false }
    },
    (response) => {
      console.log('TinyURL Response:', response.data);
      setShareURL(response.data.tiny_url);
    }
  );

  const createSharableLink = () => {
    const passcode = getRandomInt(100000, 1000000).toString();
    const key = randomString(10);
    const token = getDataFromStorage('adminAuthToken');
    const cipher = Encrypt(`${siteId},${passcode},${token}`, `sec ${key}`);
    const encryptCode = encodeURIComponent(cipher);
    // console.log(encryptCode);
    // console.log('Key:', key);
    setCodeInfo({ ...codeInfo, code: passcode, cipher: encryptCode, key });
  };

  useEffect(() => {
    // console.log('Code Info:', codeInfo);
    if (codeInfo.key.length > 0 && codeInfo.cipher.length > 0) {
      tinyURLMutation({
        url: `${baseURL}/sharing/:${codeInfo.key}${codeInfo.cipher}/${convertUTCtoLocal(
          rosterMonth,
          true
        )}`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeInfo, tinyURLMutation]);

  return (
    <Page sx={{ p: 1 }} title="Report | Trackkar">
      {isValid || isAdminAuthenticated || passcode === '000000' ? (
        <>
          <Stack sx={{ mb: 1 }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={3}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Stack>
                    <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
                      <Logo sx={{ minWidth: 40 }} />
                    </Box>
                  </Stack>
                  <Stack sx={{ border: `1px solid #D7D4CF` }}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ p: 1, borderRadius: 0.5, minWidth: 250 }}
                    >
                      <Avatar
                        onClick={() => setImageSrc(data?.site?.image)}
                        src={data?.site?.image}
                        sx={{ cursor: 'pointer' }}
                      />
                      <Stack>
                        <Typography sx={{ fontSize: 13, color: theme.palette.primary.main }}>
                          <b>{data?.site?.name}</b>
                        </Typography>
                        <Typography sx={{ fontSize: 12 }}>
                          <b>Manager&nbsp;:</b> {data?.site?.manager?.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <IconButton
                    onClick={(e) => setAnchorEl({ id: 'info_matrix', target: e.currentTarget })}
                  >
                    <AnnouncementIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Stack
                  sx={{ py: 1 }}
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SearchStyle
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                    placeholder="Search Employee..."
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    }
                  />
                  {isAdminAuthenticated ? (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MuiDatePicker
                        views={['year', 'month']}
                        // label="Year and Month"
                        minDate={new Date('2012-03-01')}
                        // maxDate={new Date('2023-06-01')}
                        value={rosterMonth}
                        onChange={(newValue) => {
                          setRosterMonth(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{ '& input': { padding: '8px', fontSize: 14 } }}
                            {...params}
                            helperText={null}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  ) : (
                    <Typography>
                      {months[new Date(rosterMonth).getMonth()]},
                      {new Date(rosterMonth).getFullYear()}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Stack
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  sx={{ px: 1, my: 1.1 }}
                  spacing={1}
                >
                  <IconButton onClick={() => setOpenRequirements(true)}>
                    <ListAltIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                  {params.edata.length < 100 && (
                    <IconButton
                      onClick={() => {
                        createSharableLink();
                        setCopyClipboard(true);
                      }}
                    >
                      <ShareIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  )}
                  <IconButton onClick={(event) => setAnchorRosterFilterEl(event.currentTarget)}>
                    <FilterListIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                  <ArrowForwardIosIcon className="desktop" sx={{ fontSize: 14 }} />
                  <Stack
                    className="hide_scrollbar"
                    direction="row"
                    sx={{ overflowX: 'auto', borderRadius: 2 }}
                  >
                    <Chip
                      color="secondary"
                      label={`Role : ${filter.role.split(',')[1] ?? 'All'}`}
                      size="small"
                      // onDelete={() => setFilter({ ...filter, role: '' })}
                      // deleteIcon={
                      //   <Tooltip title="Clear">
                      //     <ClearIcon />
                      //   </Tooltip>
                      // }
                    />
                    &nbsp;
                    <Chip
                      sx={{ backgroundColor: '#DC7633', color: 'white' }}
                      label={`Sub Role : ${filter.subRole.split(',')[1] ?? 'All'}`}
                      size="small"
                    />
                    {/* &nbsp; */}
                    {/* <Chip
                      sx={{ backgroundColor: '#138D75', color: 'white' }}
                      label={`Site : ${filter.site.split(',')[1] ?? 'All'}`}
                      size="small"
                    />
                    &nbsp;
                    <Chip
                      sx={{ backgroundColor: '#34495E', color: 'white' }}
                      label={`Shift : ${filter.shift.split(',')[1] ?? 'All'}`}
                      size="small"
                    /> */}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <TableContainer
            id="final-roster-table-container"
            sx={{ height: 'calc(100vh - 90px)', overflow: 'auto' }}
          >
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 0,
                      top: 0,
                      backgroundColor: 'white',
                      zIndex: 9,
                      p: '4px !important'
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 0,
                      top: 0,
                      backgroundColor: 'white',
                      zIndex: 9,
                      p: '4px !important'
                    }}
                    padding="none"
                    align="center"
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={`${showEmpInfo ? 'left' : 'center'}`}
                    >
                      <Tooltip title={`${showEmpInfo ? 'Hide' : 'View'} Employee Info`}>
                        <IconButton sx={{ p: 0 }} onClick={() => setShowEmpInfo(!showEmpInfo)}>
                          {showEmpInfo ? (
                            <ExpandLessIcon sx={{ transform: 'rotate(-90deg)' }} />
                          ) : (
                            <ExpandLessIcon sx={{ transform: 'rotate(90deg)' }} />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Typography>{showEmpInfo ? 'Employee' : ''}</Typography>
                    </Stack>
                  </TableCell>
                  {TABLE_HEAD.map((hd) => (
                    <TableCell sx={{ p: '4px !important' }} key={hd._id}>
                      <Stack>
                        <Typography align="center" sx={{ fontSize: 12 }}>
                          {hd.label.date}
                        </Typography>
                        <Typography align="center" sx={{ fontSize: 11 }} component="span">
                          (&nbsp;{hd.label.day}&nbsp;)
                        </Typography>
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isRosterLoading ? (
                  <TableRow>
                    <TableCell colSpan={32} align="center">
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                ) : isRosterError ? (
                  <TableRow>
                    <TableCell colSpan={12} align="center" sx={{ color: 'red' }}>
                      <b>Something went wrong !</b>
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => (
                    <TableRow hover key={row._id} tabIndex={-1}>
                      <TableCell
                        sx={{ position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 5 }}
                      >
                        {(index += 1)}
                      </TableCell>
                      <TableCell
                        sx={{ position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 5 }}
                        component="th"
                        scope="row"
                        padding="none"
                      >
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ px: 1 }}>
                          <Avatar
                            onClick={() => setImageSrc(row.avatar)}
                            alt={row.name}
                            src={row.avatar}
                            sx={{ mr: 1, width: 30, height: 30, cursor: 'pointer' }}
                          />
                          {showEmpInfo && (
                            <Box sx={{ my: 0.3 }}>
                              <Typography sx={{ mr: 2, fontSize: 14 }}>{row.name}</Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {row.supervising_shifts.length > 0 && (
                                  <Chip
                                    sx={{
                                      fontSize: 10.5,
                                      height: 18,
                                      my: 0.3,
                                      mr: 0.3,
                                      backgroundColor: '#9156E7',
                                      color: 'white'
                                    }}
                                    label="SUP"
                                  />
                                )}
                              </Box>
                              <Typography sx={{ fontSize: 11, color: '#3366FF' }}>
                                {row.role.name}
                              </Typography>
                              <Typography sx={{ fontSize: 11, color: '#DC7633' }}>
                                {row?.subrole?.name}
                              </Typography>
                            </Box>
                          )}
                        </Stack>
                      </TableCell>
                      {row.roster.map((ob) =>
                        Object.keys(ob?.schedule).length === 0 &&
                        new Date(
                          ob.date
                            .split('-')
                            .map((a) => parseInt(a, 10))
                            .flat()
                        ).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) ? (
                          <TableCell
                            padding="none"
                            key={`${row._id}_${ob.date}_${ob.date}`}
                            align="center"
                          >
                            <Typography>-</Typography>
                          </TableCell>
                        ) : ob?.schedule?.filter(
                            (st) =>
                              (st.status === 'Active' || st.status === 'Overtime') &&
                              st?.shift?.site._id === siteId
                          )?.length === 1 ? (
                          ob.schedule
                            ?.filter(
                              (st) =>
                                (st.status === 'Active' || st.status === 'Overtime') &&
                                st?.shift?.site._id === siteId
                            )
                            .map((schl) => {
                              let st = '';
                              if (
                                new Date(
                                  ob.date
                                    .split('-')
                                    .map((a) => parseInt(a, 10))
                                    .flat()
                                ).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
                              ) {
                                st = 'Pn';
                              } else if (
                                !schl?.attendance?.in?.time &&
                                new Date(
                                  ob.date
                                    .split('-')
                                    .map((a) => parseInt(a, 10))
                                    .flat()
                                ).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
                              ) {
                                st = 'Pn';
                              } else if (
                                schl?.attendance?.in?.time &&
                                !schl?.attendance?.out?.time &&
                                new Date(
                                  ob.date
                                    .split('-')
                                    .map((a) => parseInt(a, 10))
                                    .flat()
                                ).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
                              ) {
                                st = 'Pn';
                              } else if (
                                schl?.attendance?.in?.time &&
                                schl?.attendance?.out?.time
                              ) {
                                st = 'P';
                              } else if (
                                !schl?.attendance?.in?.time &&
                                !schl?.attendance?.out?.time
                              ) {
                                st = 'A';
                              }
                              return (
                                <HtmlTooltip
                                  arrow
                                  key={`table_${row._id}_${schl?.shift._id}_${ob.date}`}
                                  placement="top"
                                  title={
                                    <Stack sx={{ '& p, span': { fontSize: 12 } }}>
                                      <Typography align="center">
                                        <Typography
                                          component="span"
                                          style={{ color: theme.palette.primary.main }}
                                        >
                                          <b>{schl?.shift.site.name}</b>
                                        </Typography>
                                        &nbsp;(&nbsp;{schl?.shift.name}&nbsp;)
                                      </Typography>
                                      <Typography align="center">
                                        {ob.date.split('-').reverse().join('-')}&nbsp;[&nbsp;
                                        {_12hourTimeFormat(schl?.shift.start)}
                                        &nbsp;-&nbsp;{_12hourTimeFormat(schl?.shift.end)}
                                        &nbsp;]
                                      </Typography>
                                      <Stack
                                        sx={{
                                          mt: 0.5,
                                          p: 0.5,
                                          borderTop: '1px solid #E3EEEB',
                                          borderRadius: 0.5
                                        }}
                                      >
                                        <Typography align="center">
                                          <b>{row?.name}</b>&nbsp;(&nbsp;{row?.role?.name}, &nbsp;
                                          {row?.subrole?.name}&nbsp;)
                                        </Typography>
                                        <Stack direction="row">
                                          <Typography sx={{ minWidth: 100 }}>
                                            IN :{' '}
                                            {schl?.attendance?.in
                                              ? _12hourTimeFormat(schl?.attendance?.in?.time)
                                              : 'NA'}
                                          </Typography>
                                          <Typography sx={{ minWidth: 100 }}>
                                            OUT :{' '}
                                            {schl?.attendance?.out
                                              ? _12hourTimeFormat(schl?.attendance?.out?.time)
                                              : 'NA'}
                                          </Typography>
                                        </Stack>
                                        {schl?.attendance?.in?.reason && (
                                          <Typography>
                                            Reason for Update: {schl?.attendance?.in?.reason}
                                          </Typography>
                                        )}
                                      </Stack>
                                    </Stack>
                                  }
                                >
                                  <TableCell
                                    padding="none"
                                    sx={{
                                      position: 'relative',
                                      backgroundColor: `${
                                        schl?.status === 'Cancelled'
                                          ? '#FADBD8'
                                          : shiftColor[schl?.shift._id]
                                      }`,
                                      border: `${
                                        schl?.shift.status === 'Overtime'
                                          ? '2px solid red'
                                          : '2px solid white'
                                      }`
                                    }}
                                    align="center"
                                  >
                                    {/* {schl?.overtime && (
                                      <Chip
                                        label=""
                                        color="error"
                                        sx={{
                                          position: 'absolute',
                                          top: 0,
                                          right: 0,
                                          m: 0.3,
                                          height: 10,
                                          width: 10
                                        }}
                                      />
                                    )} */}
                                    <Stack>
                                      <Typography
                                        sx={{
                                          fontSize: 11,
                                          fontWeight: 'bold',
                                          color: 'white'
                                        }}
                                      >
                                        {st}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                </HtmlTooltip>
                              );
                            })
                        ) : ob?.schedule?.filter((st) => st?.shift?.site._id === siteId).length >
                          1 ? (
                          <TableCell
                            padding="none"
                            sx={{
                              position: 'relative',
                              border: '1px solid #E3EEEB'
                            }}
                            key={`${row._id}_${ob.date}_${ob.date}`}
                            align="center"
                          >
                            {ob.schedule
                              ?.filter(
                                (st) =>
                                  (st.status === 'Active' || st.status === 'Overtime') &&
                                  st?.shift?.site._id === siteId
                              )
                              .map((schl) => {
                                let st = '';
                                if (
                                  new Date(
                                    ob.date
                                      .split('-')
                                      .map((a) => parseInt(a, 10))
                                      .flat()
                                  ).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
                                ) {
                                  st = 'Pn';
                                } else if (
                                  !schl?.attendance?.in?.time &&
                                  new Date(
                                    ob.date
                                      .split('-')
                                      .map((a) => parseInt(a, 10))
                                      .flat()
                                  ).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
                                ) {
                                  st = 'Pn';
                                } else if (
                                  schl?.attendance?.in?.time &&
                                  !schl?.attendance?.out?.time &&
                                  new Date(
                                    ob.date
                                      .split('-')
                                      .map((a) => parseInt(a, 10))
                                      .flat()
                                  ).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
                                ) {
                                  st = 'Pn';
                                } else if (
                                  schl?.attendance?.in?.time &&
                                  schl?.attendance?.out?.time
                                ) {
                                  st = 'P';
                                } else if (!schl?.attendance?.out?.time) {
                                  st = 'A';
                                }
                                return (
                                  <Stack
                                    style={{
                                      display: 'flex',
                                      height: 25,
                                      flexDirection: 'column'
                                    }}
                                    key={`table_${row._id}_${schl?.shift._id}_${ob.date}`}
                                  >
                                    <HtmlTooltip
                                      arrow
                                      placement="top"
                                      title={
                                        <Stack sx={{ '& p, span': { fontSize: 12 } }}>
                                          <Typography align="center">
                                            <Typography
                                              component="span"
                                              style={{ color: theme.palette.primary.main }}
                                            >
                                              <b>{schl?.shift.site.name}</b>
                                            </Typography>
                                            &nbsp;(&nbsp;{schl?.shift.name}&nbsp;)
                                          </Typography>
                                          <Typography align="center">
                                            {ob.date.split('-').reverse().join('-')}&nbsp;[&nbsp;
                                            {_12hourTimeFormat(schl?.shift.start)}
                                            &nbsp;-&nbsp;{_12hourTimeFormat(schl?.shift.end)}
                                            &nbsp;]
                                          </Typography>
                                          <Stack
                                            sx={{
                                              mt: 0.5,
                                              p: 0.5,
                                              borderTop: '1px solid #E3EEEB',
                                              borderRadius: 0.5
                                            }}
                                          >
                                            <Typography align="center">
                                              <b>{row.name}</b>&nbsp;(&nbsp;{row.role.name}, &nbsp;
                                              {row.subrole.name}&nbsp;)
                                            </Typography>
                                            <Stack direction="row">
                                              <Typography sx={{ minWidth: 100 }}>
                                                IN :{' '}
                                                {schl?.attendance?.in
                                                  ? _12hourTimeFormat(schl?.attendance?.in?.time)
                                                  : 'NA'}
                                              </Typography>
                                              <Typography sx={{ minWidth: 100 }}>
                                                OUT :{' '}
                                                {schl?.attendance?.out
                                                  ? _12hourTimeFormat(schl?.attendance?.out?.time)
                                                  : 'NA'}
                                              </Typography>
                                              {schl?.attendance?.in?.reason && (
                                                <Typography>
                                                  {schl?.attendance?.in?.reason}
                                                </Typography>
                                              )}
                                            </Stack>
                                          </Stack>
                                        </Stack>
                                      }
                                    >
                                      <Stack
                                        sx={{
                                          position: 'relative',
                                          border: `${
                                            schl?.status === 'Overtime'
                                              ? '2px solid red'
                                              : '1px solid #E3EEEB'
                                          }`,
                                          height: '100%',
                                          backgroundColor: `${
                                            schl?.status === 'Cancelled'
                                              ? '#FADBD8'
                                              : shiftColor[schl?.shift._id]
                                          }`
                                        }}
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        {/* {schl?.shift?.overtime && (
                                          <Chip
                                            label=""
                                            color="error"
                                            sx={{
                                              position: 'absolute',
                                              top: 0,
                                              right: 0,
                                              m: 0.3,
                                              height: 10,
                                              width: 10
                                            }}
                                          />
                                        )} */}
                                        <Typography
                                          sx={{
                                            fontSize: 11,
                                            fontWeight: 'bold',
                                            color: 'white'
                                          }}
                                        >
                                          {st}
                                        </Typography>
                                      </Stack>
                                    </HtmlTooltip>
                                  </Stack>
                                );
                              })}
                          </TableCell>
                        ) : (
                          <TableCell align="center">
                            <Typography>-</Typography>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            {/* Put the scroll bar always on the bottom */}
            <InfiniteScroll
              dataLength={rows?.length}
              next={fetchNextRoster}
              // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
              // inverse //
              hasMore={hasNextRoster}
              loader={<h4>Loading...</h4>}
              scrollableTarget="final-roster-table-container"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            />
          </TableContainer>
        </>
      ) : (
        <Stack
          direction="row"
          sx={{ height: 'calc(100vh - 100px)' }}
          justifyContent="center"
          alignItems="center"
        >
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Logo size="large" />
            </Stack>
            <Alert severity="info">Please Enter a valid Password to Continue...</Alert>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <KeyIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField
                variant="standard"
                fullWidth
                type="text"
                label="Passcode"
                autoComplete="off"
                autoFocus
                onChange={(e) => setCode(e.target.value)}
                // {...getFieldProps('policy_name')}
                // error={Boolean(touched.policy_name && errors.policy_name)}
                // helperText={touched.policy_name && errors.policy_name}
              />
            </Box>
            <Button
              variant="contained"
              onClick={() => {
                if (code) {
                  if (code === passcode) {
                    setIsValid(true);
                  } else {
                    setError('Please enter a valid password!!');
                  }
                } else {
                  setError('Password is Required!!');
                }
              }}
            >
              Submit
            </Button>
            {error.length > 0 && <Alert severity="error">{error}</Alert>}
          </Stack>
        </Stack>
      )}
      <Popover
        id="info_matrix"
        open={anchorEl.id === `info_matrix`}
        anchorEl={anchorEl.target}
        onClose={() => setAnchorEl({ id: null, target: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack sx={{ p: 1, minWidth: 250 }}>
          {/* {isShiftsLoading && (
              <Stack direction="row" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Stack>
            )} */}
          <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
            <Chip
              sx={{
                border: '1px solid #222',
                // width: 17,
                height: 20,
                borderRadius: 0.5,
                '& span': { padding: '5px' }
              }}
              label="P"
            />
            <Stack sx={{ ml: 1 }}>
              <Typography sx={{ fontSize: 14 }}>Present</Typography>
              <Typography sx={{ fontSize: 11 }}>Marked IN and OUT Attendance</Typography>
            </Stack>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
            <Chip
              sx={{
                border: '1px solid #222',
                // width: 17,
                height: 20,
                borderRadius: 0.5,
                '& span': { paddingLeft: '5px', paddingRight: '5px' }
              }}
              label="A"
            />
            <Stack sx={{ ml: 1 }}>
              <Typography sx={{ fontSize: 14 }}>Absent</Typography>
              <Typography sx={{ fontSize: 11 }}>Not Marked IN and OUT Attendance</Typography>
            </Stack>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
            <Chip
              sx={{
                border: '1px solid #222',
                // width: 17,
                height: 20,
                borderRadius: 0.5,
                '& span': { paddingLeft: '5px', paddingRight: '5px' }
              }}
              label="L"
            />
            <Stack sx={{ ml: 1 }}>
              <Typography sx={{ fontSize: 14 }}>Leave</Typography>
              <Typography sx={{ fontSize: 11 }}>Is on Leave</Typography>
            </Stack>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
            <Chip
              sx={{
                border: '1px solid #222',
                height: 20,
                borderRadius: 0.5,
                '& span': { paddingLeft: '5px', paddingRight: '5px' }
              }}
              label="Pn"
            />
            <Stack sx={{ ml: 1 }}>
              <Typography sx={{ fontSize: 14 }}>Pending</Typography>
              <Typography sx={{ fontSize: 11 }}>Marked IN Attendance or Future Dates</Typography>
            </Stack>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
            <Stack sx={{ height: 30, width: 30, position: 'relative' }}>
              <Chip
                label=""
                color="error"
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  m: 0.3,
                  height: 10,
                  width: 10
                }}
              />
            </Stack>
            <Stack sx={{ ml: 1 }}>
              <Typography sx={{ fontSize: 14 }}>Active + Overtime</Typography>
              <Typography sx={{ fontSize: 11 }}>Overtime after the shift end</Typography>
            </Stack>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
            <Stack sx={{ height: 30, width: 30 }}>
              <Chip
                label=""
                sx={{
                  border: '2px solid red',
                  m: 0.3,
                  borderRadius: 0.2,
                  height: 22,
                  width: 25
                }}
              />
            </Stack>
            <Stack sx={{ ml: 1 }}>
              <Typography sx={{ fontSize: 14 }}>Overtime</Typography>
              <Typography sx={{ fontSize: 11 }}>Overtime Shift</Typography>
            </Stack>
          </Box>
          <Divider sx={{ my: 1 }} />
          {!isShiftsLoading && AllShifts?.length > 0 ? (
            <Stack>
              {AllShifts?.map((sht) => (
                <Fragment key={`report-${sht._id}`}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
                    <Chip
                      sx={{
                        backgroundColor: shiftColor[sht._id],
                        width: 17,
                        height: 17,
                        borderRadius: 0.5
                      }}
                    />
                    <Stack sx={{ ml: 1 }}>
                      <Typography sx={{ fontSize: 12 }}>{sht.name}</Typography>
                      <Typography sx={{ fontSize: 11 }} component="span">
                        [&nbsp;{_12hourTimeFormat(sht.start)}&nbsp;-&nbsp;
                        {_12hourTimeFormat(sht.end)}&nbsp;]
                      </Typography>
                    </Stack>
                  </Box>
                  <Divider sx={{ my: 1 }} />
                </Fragment>
              ))}
            </Stack>
          ) : (
            'No Data Available'
          )}
        </Stack>
      </Popover>
      <Popover
        id="filter_on_roster"
        open={!!anchorRosterFilterEl}
        anchorEl={anchorRosterFilterEl}
        onClose={() => setAnchorRosterFilterEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack spacing={1} width="320px" sx={{ p: 2 }}>
          <Stack direction="row" alignItems="center">
            <FilterListIcon />
            <Typography sx={{ ml: 1 }}>Filters</Typography>
          </Stack>
          <Divider />
          {/* From Date 
        <Box sx={{ display: 'flex', mt: 1, alignItems: 'flex-end' }}>
          <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <LocalizationProvider dateAdapter={AdapterDate}>
            <DatePicker
              startText="From"
              endText="To"
              value={filter?.range}
              onChange={(date) => {
                setFilter({ ...filter, range: date });
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField variant="standard" {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField variant="standard" {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
          &nbsp;
          {filter.from ? (
            <Tooltip title="Clear">
              <IconButton onClick={() => setFilter({ ...filter, from: '' })}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
        </Box> */}
          {/* Status
        <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
          <WcIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="status_filter">
              Choose Status
            </InputLabel>
            <Select
              variant="standard"
              value={filter.status}
              inputProps={{
                name: 'status',
                id: 'status_filter'
              }}
              onChange={(e) => setFilter({ ...filter, status: e.target.value })}
              sx={{ mt: 2 }}
            >
              <MenuItem value="" disabled>
                Choose Status
              </MenuItem>
              <MenuItem value="Verified">Verified</MenuItem>
              <MenuItem value="Unverified">Not Verified</MenuItem>
            </Select>
          </FormControl>
          &nbsp;
          {filter.status ? (
            <Tooltip title="Clear">
              <IconButton onClick={() => setFilter({ ...filter, status: '' })}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
        </Box> */}
          {/* <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
          <LocationCityIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="grouped-select">
              Choose State
            </InputLabel>
            <Select
              variant="standard"
              value={filter.state}
              onChange={(e) => setFilter({ ...filter, state: e.target.value })}
              id="grouped-select"
              label="Choose State"
            >
              <MenuItem value="" disabled>
                Choose State and Union Territories
              </MenuItem>
              <ListSubheader>States</ListSubheader>
              {INDIAN?.states?.length > 0 ? (
                INDIAN?.states.map((key) => (
                  <MenuItem key={`${'state'}-${key}`} value={key}>
                    {key}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No State Available</em>
                </MenuItem>
              )}
              <ListSubheader>Union Territories</ListSubheader>
              {INDIAN.union_territories?.length > 0 ? (
                INDIAN.union_territories.map((key) => (
                  <MenuItem key={`${'territory'}-${key}`} value={key}>
                    {key}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No Territories Available</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
          &nbsp;
          {filter.state ? (
            <Tooltip title="Clear">
              <IconButton onClick={() => setFilter({ ...filter, state: '' })}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
        </Box> */}
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="role_filter">
                Choose Role
              </InputLabel>
              <Select
                variant="standard"
                value={filter?.role}
                inputProps={{
                  name: 'role_filter',
                  id: 'role_filter'
                }}
                onChange={(e) => setFilter({ ...filter, role: e.target.value, subRole: '' })}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Role
                </MenuItem>
                {roleOptions?.length > 0 ? (
                  roleOptions.map(([key, value, image]) => (
                    <MenuItem key={`${'role_filter'}-${key}`} value={key} data-icon={image}>
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <>
                    <MenuItem value="" disabled />
                  </>
                )}
              </Select>
            </FormControl>
            &nbsp;
            {filter.role ? (
              <Tooltip title="Clear">
                <IconButton onClick={() => setFilter({ ...filter, role: '', subRole: '' })}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </Box>
          {/* Sub Role */}
          {filter.role && subRoleOptions?.length > 0 ? (
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="role_type">
                  Choose Sub Role
                </InputLabel>
                <Select
                  variant="standard"
                  disabled={!(filter.role && subRoleOptions.length > 0)}
                  value={filter.subRole}
                  inputProps={{
                    name: 'sub_role_type',
                    id: 'sub_role_type'
                  }}
                  required
                  sx={{ mt: 2 }}
                  onChange={(e) => setFilter({ ...filter, subRole: e.target.value })}
                  // error={Boolean(touched.sub_role_type && errors.sub_role_type)}
                  // helperText={touched.policy_role && errors.policy_role}
                >
                  <MenuItem value="" disabled>
                    Choose Sub Role
                  </MenuItem>
                  {subRoleOptions?.length > 0 ? (
                    subRoleOptions.map(([key, value, image]) => (
                      <MenuItem key={`${'sub_role_type'}-${key}`} value={key} data-icon={image}>
                        {value}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled />
                  )}
                </Select>
              </FormControl>
              &nbsp;
              {filter.subRole ? (
                <Tooltip title="Clear">
                  <IconButton onClick={() => setFilter({ ...filter, subRole: '' })}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box>
          ) : null}
          {/* Sites */}
          {/* <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  mt: 1
                }}
              >
                <AddModeratorIcon
                  sx={{
                    color: 'action.active',
                    mr: 1,
                    my: 0.5
                  }}
                />
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="choose_site">
                    Choose Site
                  </InputLabel>
                  <Select
                    variant="standard"
                    value={filter.site}
                    inputProps={{
                      name: 'choose_site',
                      id: 'choose_site'
                    }}
                    onChange={(e) => setFilter({ ...filter, site: e.target.value, shift: '' })}
                    sx={{ mt: 2 }}
                  >
                    <MenuItem value="" disabled>
                      Choose Site
                    </MenuItem>
                    {siteOptions?.length > 0 ? (
                      siteOptions.map(([key, value]) => (
                        <MenuItem key={`${'assign_site'}-${key}`} value={key}>
                          {value}
                        </MenuItem>
                      ))
                    ) : (
                      <>
                        <MenuItem value="" disabled />
                      </>
                    )}
                  </Select>
                </FormControl>
                &nbsp;
                {filter.site ? (
                  <Tooltip title="Clear">
                    <IconButton onClick={() => setFilter({ ...filter, site: '', shift: '' })}>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Box> */}
          {/* Shift */}
          {/* {filter.site && shiftOptions?.length > 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    mt: 1
                  }}
                >
                  <AddModeratorIcon
                    sx={{
                      color: 'action.active',
                      mr: 1,
                      my: 0.5
                    }}
                  />
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="choose_shift">
                      Choose Shift
                    </InputLabel>
                    <Select
                      variant="standard"
                      value={filter.shift}
                      // disabled={!!siteId}
                      inputProps={{
                        name: 'choose_shift',
                        id: 'choose_shift'
                      }}
                      onChange={(e) => setFilter({ ...filter, shift: e.target.value })}
                      sx={{ mt: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Choose Shift
                      </MenuItem>
                      {shiftOptions?.length > 0 ? (
                        shiftOptions.map(([key, value, image]) => (
                          <MenuItem key={`${'assign_shift'}-${key}`} value={key} data-icon={image}>
                            {value}
                          </MenuItem>
                        ))
                      ) : (
                        <>
                          <MenuItem value="" disabled />
                        </>
                      )}
                    </Select>
                  </FormControl>
                  &nbsp;
                  {filter.shift ? (
                    <Tooltip title="Clear">
                      <IconButton onClick={() => setFilter({ ...filter, shift: '' })}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </Box>
              ) : null} */}
        </Stack>
      </Popover>
      <Dialog
        open={copyClipboard}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setCopyClipboard(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <ShareIcon sx={{ fontSize: 16 }} />
          &nbsp;Roster Share
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="center">
              <WhatsappShareButton
                title={`Trackkar Roster Share with Password: ${codeInfo.code}`}
                url={`${shareURL}`}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{ backgroundColor: 'green', p: 1, borderRadius: 0.5 }}
                >
                  <WhatsAppIcon sx={{ color: 'white' }} />
                  <Typography sx={{ color: 'white' }}>WhatsApp</Typography>
                </Stack>
              </WhatsappShareButton>
            </Stack>
            <Typography align="center" sx={{ fontSize: 16, fontWeight: 'bold' }}>
              Or
            </Typography>
            <Typography>Password: {codeInfo.code}</Typography>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <TextField readOnly sx={{ minWidth: 300 }} value={`${shareURL ?? 'Loading...'}`} />
              <Stack sx={{ p: 2 }}>
                <CopyToClipboard text={`${shareURL}`} onCopy={() => setCopied(true)}>
                  <IconButton disabled={!shareURL}>
                    <ContentCopyIcon sx={{ cursor: 'pointer' }} />
                  </IconButton>
                </CopyToClipboard>
              </Stack>
            </Stack>
            {copied && <Alert severity="info">Copied To Clipboard!!</Alert>}
          </Stack>
        </DialogContent>
      </Dialog>
      <ImageZoom src={imageSrc} onClose={() => setImageSrc(null)} />
      <Requirements
        open={openRequirements}
        onClose={() => setOpenRequirements(false)}
        rosterMonth={rosterMonth}
        selectedSite={siteId}
        token={token}
        monthDate={monthDate}
        siteData={data?.site}
        selReqDay={selReqDay}
        setSelReqDay={setSelReqDay}
      />
    </Page>
  );
}

export default ShareRoster;
