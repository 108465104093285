import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchPayrollTemplates } from '../api/queries/Queries';

const usePayrollTemplates = (type, search) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(['payroll-templates', type, search], fetchPayrollTemplates, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export default usePayrollTemplates;
