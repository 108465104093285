import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
// import DeleteIcon from '@mui/icons-material/Delete';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// ----------------------------------------------------------------------

QueriesMoreMenu.propTypes = {
  resolveQueries: PropTypes.func,
  rejectQueries: PropTypes.func,
  // deleteLead: PropTypes.func,
  queryStatus: PropTypes.string
};

export default function QueriesMoreMenu({ resolveQueries, rejectQueries, queryStatus }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {queryStatus === 'Pending' ? (
          <>
            <MenuItem sx={{ color: 'text.secondary' }} onClick={() => resolveQueries()}>
              <ListItemIcon sx={{ mr: 0 }}>
                <ThumbUpIcon sx={{ color: 'green' }} />
              </ListItemIcon>
              <ListItemText primary="Resolve" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>

            <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={() => rejectQueries()}>
              <ListItemIcon sx={{ mr: 0 }}>
                <ThumbDownIcon sx={{ color: 'red' }} />
              </ListItemIcon>
              <ListItemText primary="Reject" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </>
        ) : null}

        {/* <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={() => deleteLead()}>
          <ListItemIcon sx={{ mr: 0 }}>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      </Menu>
    </>
  );
}
