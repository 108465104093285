import '../../../index.css';
import { memo, useMemo, useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useFormik, FormikProvider, Form } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Modal,
  Typography,
  Box,
  TextField,
  Stack,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  NativeSelect,
  MenuItem
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
// icons
import SubjectIcon from '@mui/icons-material/Subject';
import EventIcon from '@mui/icons-material/Event';
import SickIcon from '@mui/icons-material/Sick';
import NightlifeIcon from '@mui/icons-material/Nightlife';
import InfoIcon from '@mui/icons-material/Info';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
// import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import UpdateIcon from '@mui/icons-material/Update';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import CloseIcon from '@mui/icons-material/Close';
// config
import { POLICY, months } from '../../../utils/config';
// hooks
import { useRoles } from '../../../hooks/roles';
// mutations
import { addPolicy, editPolicy } from '../../../api/mutations/policy';
// utils
import { capitalizeEachWord, getCurrentDate, convertUTCtoLocal } from '../../../utils/functions';
// redux
import { logOut } from '../../../redux/actions/adminActions';

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

function AddPolicy({ open, onClose, defaultPolicy, selectedRole, edit }) {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const monthRef = useRef();
  selectedRole = edit?.role?._id ?? selectedRole;
  const aprilStartDate = new Date(new Date().getFullYear(), 3, 1, 0, 0, 0, 0);
  const yearEndDate = new Date(new Date().getFullYear() + 1, 2, 31, 0, 0, 0, 0);
  const [selectRolesOptions, setSelectRolesOptions] = useState(); // Roles initial State for select
  const [leaveStartDate, setLeaveStartDate] = useState(aprilStartDate);
  const [leaveEndDate, setLeaveEndDate] = useState(yearEndDate);

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      allRoles.forEach((role) => formattedRoles.push([role._id, role.name]));
      setSelectRolesOptions([...formattedRoles]);
      // console.log('Add Policy Role Options :', formattedRoles);
    }
  }, [allRoles]);
  /* ================= End Roles =============== */

  useEffect(() => {
    console.log('Edit Policy :', edit);
  }, [edit]);

  const { mutate } = useMutation(edit ? editPolicy : addPolicy, {
    onMutate: () => {
      onClose();
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: `${edit ? 'Updating' : 'Adding New'} Policy 😃`,
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    }
  });

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      policy_name: edit?.name ?? '',
      policy_role: selectedRole,
      start_month: 4,
      // end_date: convertUTCtoLocal(yearEndDate, true),
      sick_leaves: edit?.sick_leaves ?? '',
      casual_leaves: edit?.casual_leaves ?? '',
      // national_leaves: edit?.national_leaves ?? '',
      two_wheeler: edit?.two_wheeler_amount ?? '',
      four_wheeler: edit?.four_wheeler_amount ?? '',
      overtime: edit?.overtime_amount ?? '',
      default_policy: !!defaultPolicy,
      is_carry_forward: edit?.is_carry_forward ?? false
    },
    validationSchema: Yup.object({
      policy_name: Yup.string()
        .required('Name is required')
        .matches(/^[a-zA-Z][a-zA-Z0-9\s]+$/, '-Must start with an alphabet -Minimum 2 characters'),
      policy_role: Yup.string().required('Role is required'),
      start_month: Yup.string().required('Start Month is required'),
      sick_leaves: Yup.number()
        .typeError('Sick leaves must be a number')
        .required('Please provide sick leaves')
        .min(0, 'Value should be greater than or equal to 0'),
      casual_leaves: Yup.number()
        .typeError('Casual leaves must be a number')
        .required('Please provide casual leaves')
        .min(0, 'Value should be greater than or equal to 0'),
      // national_leaves: Yup.number()
      //   .typeError('Privilege leaves must be a number')
      //   .required('Please provide privilege leaves')
      //   .min(0, 'Value should be greater than or equal to 0'),
      two_wheeler: Yup.number()
        .typeError('Please enter a valid amount')
        .required('Please provide reimbursement amount for two-wheelers vehicle')
        .min(0, 'Value should be greater than or equal to 0'),
      four_wheeler: Yup.number()
        .typeError('Please enter a valid amount')
        .required('Please provide reimbursement amount for four-wheelers vehicle')
        .min(0, 'Value should be greater than or equal to 0'),
      overtime: Yup.number()
        .typeError('Please enter a valid amount')
        .required('Please provide reimbursement amount for overtime per hour')
        .min(0, 'Value should be greater than or equal to 0')
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        name: capitalizeEachWord(values.policy_name),
        role: values.policy_role,
        casual: values.casual_leaves,
        sick: values.sick_leaves,
        // national: values.national_leaves,
        total: values.sick_leaves + values.casual_leaves,
        two_wheeler: values.two_wheeler,
        four_wheeler: values.four_wheeler,
        startMonth: values.start_month,
        // check_in: convertTime12to24(values.check_in),
        // check_out: convertTime12to24(values.check_out),
        isDefault: defaultPolicy === true ? defaultPolicy : values.default_policy,
        overtime: values.overtime,
        today: getCurrentDate(),
        isCarryForward: values.is_carry_forward ?? false
      };

      if (edit) {
        data.id = edit?._id;
      }

      // console.log('Policy Add/Update Form :', data);

      mutate(data, {
        onError: (error) => {
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          actions.setSubmitting(false);
          Swal.fire({
            icon: 'success',
            title: 'Successfull',
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          });
        },
        onSettled: () => {
          cache.invalidateQueries('policies');
          cache.invalidateQueries('privileges'); // for step-2 of create heirarchy
        }
      });
    }
  });

  const {
    errors,
    touched,
    values,
    // isSubmitting,
    handleSubmit,
    getFieldProps
  } = formik;

  return (
    <Modal
      open={open ?? false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} container>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 2,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          <img src={POLICY} width="40" height="40" alt="edit_icon" />
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            {edit ? 'Edit' : 'Add'} Policy
          </Typography>
          <IconButton
            onClick={() => onClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack
              className="custom_scrollbar"
              spacing={1}
              height="calc(100vh - 135px)"
              sx={{ px: 4, py: 2, overflowY: 'scroll' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <SubjectIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Policy"
                  autoComplete="off"
                  autoFocus
                  {...getFieldProps('policy_name')}
                  error={Boolean(touched.policy_name && errors.policy_name)}
                  helperText={touched.policy_name && errors.policy_name}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <AddModeratorIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl fullWidth>
                  {/* <InputLabel variant="standard" htmlFor="policy_role">
                    Role
                  </InputLabel> */}
                  <NativeSelect
                    inputProps={{
                      name: 'policy_role',
                      id: 'policy_role'
                    }}
                    disabled={edit?.role?._id ? false : selectedRole}
                    sx={{ mt: 2 }}
                    {...getFieldProps('policy_role')}
                    error={Boolean(touched.policy_role && errors.policy_role)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    <option value={0}>Choose Role</option>
                    {selectRolesOptions?.length > 0 ? (
                      selectRolesOptions.map(([key, value, image]) => (
                        <option
                          style={{ padding: '0.5rem' }}
                          key={`${'policy_role'}-${key}`}
                          value={key}
                          data-icon={image}
                        >
                          {value}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No Data Found
                      </option>
                    )}
                  </NativeSelect>
                </FormControl>
              </Box>
              <br />
              <Box>
                <Typography>Leaves ( Yearly )</Typography>
              </Box>
              {/* Month Of Starting Leave */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <EventIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="policy_role">
                    Leave Year Start Month
                  </InputLabel>
                  <Select
                    inputProps={{
                      name: 'start_month',
                      id: 'start_month'
                    }}
                    variant="standard"
                    sx={{ mt: 2 }}
                    {...getFieldProps('start_month')}
                    error={Boolean(touched.start_month && errors.start_month)}
                    // helperText={touched.policy_role && errors.policy_role}
                  >
                    {months.map((m, i) => (
                      <MenuItem key={`${m}-${i}`} value={i + 1}>
                        {m}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {values.start_month && (
                <Typography align="center">
                  {months[values.start_month - 1]} {new Date().getFullYear()}&emsp;-&emsp;
                  {months[values.start_month - 2]} {new Date().getFullYear() + 1}
                </Typography>
              )}
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <SickIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="number"
                  min="0"
                  name="sick_leaves"
                  label="Sick Leaves"
                  autoComplete="off"
                  {...getFieldProps('sick_leaves')}
                  error={Boolean(touched.sick_leaves && errors.sick_leaves)}
                  helperText={touched.sick_leaves && errors.sick_leaves}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <NightlifeIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="number"
                  min="0"
                  name="casual_leaves"
                  label="Casual Leaves"
                  autoComplete="off"
                  {...getFieldProps('casual_leaves')}
                  error={Boolean(touched.casual_leaves && errors.casual_leaves)}
                  helperText={touched.casual_leaves && errors.casual_leaves}
                />
              </Box>
              {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <MilitaryTechIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="number"
                  min="0"
                  name="national_leaves"
                  label="National Leaves"
                  autoComplete="off"
                  {...getFieldProps('national_leaves')}
                  error={Boolean(touched.national_leaves && errors.national_leaves)}
                  helperText={touched.national_leaves && errors.national_leaves}
                />
              </Box> */}
              <br />
              <Box>
                <Typography>Travel ( ₹ / km )</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TwoWheelerIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="number"
                  min="0"
                  step=".01"
                  name="two_wheeler"
                  label="Two Wheeler"
                  autoComplete="off"
                  {...getFieldProps('two_wheeler')}
                  error={Boolean(touched.two_wheeler && errors.two_wheeler)}
                  helperText={touched.two_wheeler && errors.two_wheeler}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <DirectionsCarIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="number"
                  min="0"
                  step=".01"
                  name="four_wheeler"
                  label="Four Wheeler"
                  autoComplete="off"
                  {...getFieldProps('four_wheeler')}
                  error={Boolean(touched.four_wheeler && errors.four_wheeler)}
                  helperText={touched.four_wheeler && errors.four_wheeler}
                />
              </Box>
              <br />
              <Box>
                <Typography>Overtime ( ₹ / hour ) </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <UpdateIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  type="number"
                  min="0"
                  step=".01"
                  name="overtime"
                  label="Overtime"
                  autoComplete="off"
                  {...getFieldProps('overtime')}
                  error={Boolean(touched.overtime && errors.overtime)}
                  helperText={touched.overtime && errors.overtime}
                />
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.default_policy}
                    {...getFieldProps('default_policy')}
                    disabled={!!defaultPolicy}
                  />
                }
                label=" Save as default policy for this role"
              />
              <FormControlLabel
                control={<Checkbox {...getFieldProps('is_carry_forward')} />}
                label={
                  <Stack direction="row" alignItems="center">
                    <Typography>Forward Leave Yearly</Typography>
                    <InfoIcon sx={{ ml: 1, fontSize: 17 }} />
                  </Stack>
                }
              />
            </Stack>
            <Stack
              sx={{ position: 'absolute', backgroundColor: 'white', bottom: 0, px: 2, pb: 1.2 }}
            >
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Modal>
  );
}

AddPolicy.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultPolicy: PropTypes.bool,
  selectedRole: PropTypes.string,
  edit: PropTypes.object
};

export default memo(AddPolicy);
