/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
import '../../../index.css';
import { memo, useMemo, useEffect, useCallback, useState } from 'react';
import * as Yup from 'yup';
import {
  useMutation,
  useQueryClient
  // useInfiniteQuery
} from 'react-query';
import { ThemeProvider, styled, useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useFormik, FormikProvider, Form } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Modal,
  Typography,
  Box,
  TextField,
  Stack,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Chip,
  Alert,
  List,
  ListItem,
  Divider,
  Avatar,
  FormControl,
  Button,
  IconButton,
  InputLabel
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
// icons
import AssignmentIcon from '@mui/icons-material/Assignment';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PeopleIcon from '@mui/icons-material/People';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import TreeView from '@mui/lab/TreeView';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { theme } from '../../Palette';
// config
import { ADD_EMPLOYEE, months, DayOfWeeks, SWAL_TIME } from '../../../utils/config';
// hooks
// import { useRoles } from '../../../hooks/roles';
import { useRequirementTemplates } from '../../../hooks';
import { useSiteEmployees } from '../../../hooks/site';
import useMutationFunc from '../../../api/mutations/mutation';
import { useShiftById } from '../../../hooks/get-shifts-query';
import { addShift, editShift } from '../../../api/mutations/shift';
import { clearRoster } from '../../../api/mutations/adminMutations';
// utils
import {
  getCurrentDate,
  capitalizeEachWord,
  convertTime12to24,
  _12hourTimeFormat,
  _24toUTC,
  _daysInMonth,
  ordinal,
  formatTime,
  countOccurrences,
  getDifferenceInHours,
  convertTimeToNumber,
  arraysEqual,
  getMonthCalendar,
  formatDigit
  // convertUTCtoLocal
} from '../../../utils/functions';
// hook
import { useStaticRoles } from '../../../hooks/roles';
// redux
import { logOut } from '../../../redux/actions/adminActions';
// import INDIAN from '../../../utils/INDIAN_STATES.json'; // importing indian states and uts

const DEBUG = false; // Change this to true to debug the file

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250
//     }
//   }
// };

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

// eslint-disable-next-line consistent-return
// eslint-disable-next-line array-callback-return

function AddShift({ open, onClose, edit, step, shiftSite, rosterMonth }) {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const theme = useTheme();
  const dInMonth = _daysInMonth(new Date());
  const monthDays = Array.from({ length: 31 }, (_, i) => {
    const n = i + 1;
    let today = new Date();
    let tm = today.getMonth();
    let ty = today.getFullYear();
    if (tm !== rosterMonth.getMonth()) {
      tm = rosterMonth.getMonth();
      today = new Date(rosterMonth.getFullYear(), rosterMonth.getMonth(), 1);
    }
    if (ty !== rosterMonth.getFullYear()) {
      ty = rosterMonth.getFullYear();
    }
    // if (n >= today.getDate()) {
    //   if (n > 29 && tm === 1) {
    //     return {
    //       day: n,
    //       m: tm + 1,
    //       month: months[tm + 1],
    //       wdn: new Date(ty, tm + 1, n).getDay(),
    //       wd: DayOfWeeks[new Date(ty, tm + 1, n).getDay()]
    //     };
    //   }
    //   return {
    //     day: n,
    //     m: tm,
    //     month: months[tm],
    //     wdn: new Date(ty, tm, n).getDay(),
    //     wd: DayOfWeeks[new Date(ty, tm, n).getDay()]
    //   };
    // }
    return {
      day: n,
      m: tm + 1,
      month: months[tm],
      wdn: new Date(ty, tm, n).getDay(),
      wd: DayOfWeeks[new Date(ty, tm, n).getDay()]
    };
  });

  // eslint-disable-next-line no-return-await
  // const [submitClicked, setSubmitClicked] = useState(false);
  const [daysRequirement, setDaysRequirement] = useState({});
  const [reqfill, setReqFill] = useState({});
  const [reqError, setReqError] = useState(null);
  const [shiftWeeklyOff, setShiftWeeklyOff] = useState([]);
  const [addRequirements, setAddRequirements] = useState(false);
  const [bulkRequirements, setBulkRequirements] = useState(false);
  const [supervisors, setSupervisors] = useState([]); // Store Supervisors ids
  const [siteEmployeesOptions, setSiteEmployeesOptions] = useState([]);
  // const [rtemplatesOptions, setRTemplatesOptions] = useState([]);
  const [addConstraints, setAddConstraints] = useState(false);
  const [, setHFill] = useState(); // Heirarchy Requirement State Fill
  const [viewTemplate, setViewTemplate] = useState();
  const [reqHeirarchy, setReqHeirarchy] = useState([]); // For Storing Requirements Heirarchy
  const [onlyRole, setOnlyRole] = useState({});
  const [useTemplate, setUseTemplate] = useState(false);
  const [initialValue, setInitialValue] = useState({
    shift_start: null,
    shift_end: null
  });
  const [selected, setSelected] = useState([]); // Save Selected Days

  const [expanded, setExpanded] = useState(); // 'panel1'
  const [outerExpanded, setOuterExpanded] = useState(); // 'panel1'
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleAChange = (panel) => (event, isExpanded) => {
    setOuterExpanded(isExpanded ? panel : false);
  };

  const disableDate = (date) => {
    if (rosterMonth.getMonth() === new Date().getMonth() && date < new Date().getDate()) {
      return true;
    }
    return false;
  };

  const {
    isLoading: isRolesLoading,
    isError: isRolesError,
    data: roles
    // refetch: refetchStaticRoles
  } = useStaticRoles();

  const allRoles = useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return []; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  const progress = useMemo(() => {
    if (DEBUG) {
      console.log('Progress reqfill:', reqfill);
      console.log('Occurance reqfill:', countOccurrences(Object.values(reqfill), true));
    }
    // eslint-disable-next-line consistent-return, array-callback-return
    const negectDays = monthDays?.filter((md) => {
      if (rosterMonth.getMonth() === new Date().getMonth()) {
        if (md.day >= new Date().getDate()) {
          return shiftWeeklyOff?.includes(md.wdn);
        }
        return disableDate(md.day);
      }
      if (rosterMonth.getMonth() > new Date().getMonth()) {
        return shiftWeeklyOff?.includes(md.wdn);
      }
    });
    // console.log('Divide Days:', negectDays);
    // console.log('ReqFill', reqfill);
    return (countOccurrences(Object.values(reqfill), true) * 100) / (31 - negectDays.length);
  }, [reqfill, shiftWeeklyOff, monthDays]);

  useEffect(() => {
    if (progress === 100) {
      setReqError('');
    }
  }, [progress]);

  // ----------- Requirements Templates ---------------------------------------------

  const {
    isLoading: isTemplatesLoading,
    isError: isTemplatesError,
    data: _Rtemplates
  } = useRequirementTemplates();

  const AllRequirementTemplates = useMemo(() => {
    const listOfTemplates = [];
    if (!isTemplatesLoading && !isTemplatesError) {
      if (_Rtemplates?.pages?.length > 0) {
        _Rtemplates.pages.forEach((group) => {
          listOfTemplates.push(group.shifts);
        });
      }
    }
    const flattened = listOfTemplates.flat();
    // console.log('Sites Employees:', flattened);
    return flattened;
  }, [_Rtemplates, isTemplatesLoading, isTemplatesError]);

  /* ================================= Site Employees ============================= */

  const {
    isLoading: isSiteEmployeesLoading,
    isError: isSiteEmployeesError,
    // hasNextPage: hasNextSiteEmployees,
    // fetchNextPage: fetchNextSiteEmployees,
    data: siteEmployees
  } = useSiteEmployees(shiftSite?._id, null, null, null);

  const SiteEmployees = useMemo(() => {
    const listOfSiteEmployees = [];
    if (!isSiteEmployeesLoading && !isSiteEmployeesError) {
      if (siteEmployees?.pages?.length > 0) {
        siteEmployees.pages.forEach((group) => {
          listOfSiteEmployees.push(group.employees);
        });
      }
    }
    const flattened = listOfSiteEmployees.flat();
    // console.log('Sites Employees:', flattened);
    // return flattened;
    const filtered = [];
    flattened.forEach((emp) => {
      if (!(shiftSite?.manager?._id === emp._id)) {
        filtered.push(emp);
      }
    });
    // console.log('Site Employees :', filtered);
    return filtered;
  }, [siteEmployees, isSiteEmployeesLoading, isSiteEmployeesError, shiftSite]);

  useEffect(() => {
    const formattedEmployees = [];
    if (SiteEmployees?.length > 0) {
      SiteEmployees.forEach((emp) => {
        formattedEmployees.push([
          `${emp._id}, ${emp.name}`,
          emp.avater,
          emp.role.name,
          emp?.subrole?.name
        ]);
      });
      setSiteEmployeesOptions([...formattedEmployees]);
    }
  }, [SiteEmployees]);
  /* =============== End Site Employees ======================== */

  const clearRosterMutation = useMutationFunc(
    clearRoster,
    {
      onSuccess: {
        title: 'Successfully Cleared!!'
      }
    },
    null,
    () => {
      cache.invalidateQueries();
    },
    null,
    null
  );

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // Preparing on Submit Data
  useEffect(() => {
    // console.log('Day Wise Requirement:', daysRequirement);
    // console.log('Heirarchy Req:', reqHeirarchy);
    // console.log('Selected Days:', selected);
    const __RQ = reqHeirarchy;
    if (selected.length === 1) {
      const rq_ = daysRequirement[selected[0]];
      console.log(rq_);
      __RQ.forEach((rl, idx) => {
        rl.value = rq_[idx]?.value;
        rl.subroles.forEach((srl, ixn) => {
          srl.value = rq_[idx]?.subroles[ixn]?.value;
        });
      });
      console.log(rq_);
      setReqHeirarchy([...__RQ]);
      console.log('+1:', reqHeirarchy);
    }
    if (selected.length > 1 || selected.length === 0) {
      __RQ.forEach((rl) => {
        rl.value = 0;
        rl.subroles.forEach((srl) => {
          srl.value = 0;
        });
      });
      setReqHeirarchy([...__RQ]);
    }
    // console.log('Req Fill', reqfill);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const checkRequirement = (require) => {
    if (require?.some((e) => e.value > 0)) {
      return true;
    }
    return false;
  };

  const {
    isLoading: isShiftLoading,
    isError: isShiftError,
    data: _Edit,
    refetch: refetchShift
  } = useShiftById(edit?._id);

  useEffect(() => {
    if (edit) {
      refetchShift();
    }
  }, [edit, refetchShift]);

  useEffect(() => {
    // console.log('shiftSite:', shiftSite);
    // console.log('allRoles', allRoles);
    // console.log('edit:', edit);
    // console.log(edit);
    // console.log('Initial:', requirements);
    if (_Edit !== undefined && edit && !isShiftLoading && !isShiftError) {
      console.log('Edit Shift:', _Edit);
      const offs = _Edit?.shift?.constraints?.weekly_off ?? [];
      console.log('Shift Offs', offs);
      setSupervisors(_Edit?.shift?.supervisors.map((sup) => `${sup._id}, ${sup.name}`));
      const require = daysRequirement; // Day Wise Requirements
      const _RQMT = [];
      allRoles?.forEach((role) => {
        if (
          role.priority > shiftSite?.manager?.role?.priority &&
          role?.parents?.find((pp) => pp.is_site_manager === true)
        ) {
          _RQMT.push({
            role: role._id,
            name: role.name,
            value: 0,
            subroles: role.subroles.map((sr) => ({
              subrole: sr._id,
              name: sr.name,
              value: 0
            }))
          });
        }
      });
      setReqHeirarchy(_RQMT);
      monthDays?.forEach((date) => {
        const _rqmnt = [];
        allRoles?.forEach((role) => {
          if (
            role.priority > shiftSite?.manager?.role?.priority &&
            role?.parents?.find((pp) => pp.is_site_manager === true)
          ) {
            _rqmnt.push({
              role: role._id,
              name: role.name,
              value: 0,
              subroles: role.subroles.map((sr) => ({
                subrole: sr._id,
                name: sr.name,
                value: 0
              }))
            });
          }
        });
        require[`${date.day},${date.wdn}`] = [..._rqmnt];
        reqfill[`${date.day},${date.wdn}`] = false;
        setReqFill({ ...reqfill });
      });
      // day week off in shift in [1, 2, 3, 4, 5, 6]
      const dayWeekOff = monthDays?.filter((md) => offs?.includes(md.wdn)).map((dw) => dw.day);
      // console.log('Day Week Offs:', dayWeekOff);
      setShiftWeeklyOff([...offs]);
      _Edit.shift.requirements.forEach((date) => {
        const mday = monthDays?.find((mds) => mds.day === date.day).wdn;
        const ro = require[`${date.day},${mday}`];
        date.requirement.forEach((r) => {
          const __r = ro.find((_rt) => _rt.role === r.role._id);
          if (__r) {
            __r.value = r.value;
            __r.subroles.forEach((_tt) => {
              // console.log(r);
              _tt.value = r.subroles.find((___sr) => ___sr.subrole._id === _tt.subrole)?.value ?? 0;
            });
          }
          if (r.subroles.length === 0) {
            onlyRole[`${date.day}-${r.role._id}`] = true;
          }
        });
        // console.log(_q);
        if (
          !dayWeekOff?.includes(date.day) &&
          require[`${date.day},${mday}`].some((r) => r.value > 0)
        ) {
          if (rosterMonth.getMonth() === new Date().getMonth()) {
            if (date.day >= new Date().getDate()) {
              reqfill[`${date.day},${mday}`] = true;
            }
          } else if (rosterMonth.getMonth() > new Date().getMonth()) {
            reqfill[`${date.day},${mday}`] = true;
          }
        }
      });

      setReqFill({ ...reqfill });
      setDaysRequirement({ ...require }); // Store Days Requirement
    } else if (!isRolesLoading && !isRolesError && edit === null) {
      // console.log('Add Shift');
      // console.log(_rqmnt);
      const offs = shiftSite?.constraints?.weekly_off;
      // console.log(offs);
      setShiftWeeklyOff(offs); // changed from spread
      monthDays?.forEach((date) => {
        const _rqmnt = [];
        allRoles.forEach((role) => {
          // console.log(role);
          if (
            role.priority > shiftSite?.manager?.role?.priority &&
            role?.parents?.find((pp) => pp.is_site_manager === true)
          ) {
            _rqmnt.push({
              role: role._id,
              name: role.name,
              value: 0,
              subroles: role.subroles.map((sr) => ({
                subrole: sr._id,
                name: sr.name,
                value: 0
              }))
            });
          }
        });
        setReqHeirarchy([..._rqmnt]);
        daysRequirement[`${date.day},${date.wdn}`] = [..._rqmnt];
        reqfill[`${date.day},${date.wdn}`] = false;
        setReqFill({ ...reqfill });
      });
      // console.log('Day Wise Requirements:', daysRequirement);
    }
    // console.log(onlyRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_Edit, shiftSite, edit, allRoles, isRolesLoading, isRolesError]);

  const { mutate } = useMutation(edit && _Edit ? editShift : addShift, {
    onMutate: () => {
      onClose();
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: `${edit ? 'Updating' : 'Onboarding'} This Shift :)`,
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // for unauthorized access
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
      onClose();
      const msg = error.response
        ? error.response.data.message || error.toString()
        : error.toString();

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: msg,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });

      // actions.setStatus({
      //   errorResponse: msg
      // });
      // actions.setSubmitting(false);
    },
    onSuccess: (data) => {
      // actions.setSubmitting(false);
      onClose();
      resetForm();
      setInitialValue({
        shift_end: '',
        shift_start: ''
      });
      // requirements.forEach((rq) => {
      //   rq.requirement = 0;
      //   rq.subroles.forEach((sbq) => (sbq.requirement = 0));
      // });
      Swal.fire({
        icon: 'success',
        title: `Shift ${edit ? 'Updated!' : 'Added!'}`,
        text: data.message,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
      cache.invalidateQueries();
      // cache.invalidateQueries('sites');
      // cache.invalidateQueries('sites-shifts');
      // cache.invalidateQueries('roster-cards');
      // cache.invalidateQueries('requirements-templates');
      // cache.invalidateQueries('roster-availability');
    }
  });

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      shift_name: edit?.name ?? '',
      shift_requirement: [], // Array a requirements
      // eslint-disable-next-line no-nested-ternary
      shift_start: initialValue.shift_start
        ? convertTime12to24(initialValue.shift_start.toLocaleTimeString())
        : '',
      shift_end: initialValue.shift_end
        ? convertTime12to24(initialValue.shift_end.toLocaleTimeString())
        : '',
      gender_constraint: _Edit?.shift?.constraints?.genders
        ? _Edit?.shift?.constraints?.genders
        : ['Male', 'Female', 'Other'],
      shift_weekly_days_off: shiftWeeklyOff
    },
    validationSchema: Yup.object({
      shift_name: Yup.string()
        .required('Name is required')
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9\s\-&.']*[a-zA-Z0-9]+$/,
          "Invalid client name. ( Minimum 2 characters, must start & end with alphanumeric characters, allowed special characters  - & . ' )"
        ),
      shift_requirement: Yup.array().required('Requirement is Required'),
      // .test('shift-requirement-test', `Requirements is Required`, (value) => {
      //   console.log(errors);
      //   value.forEach((val) => {
      //     if (val.requirement > 0) {
      //       console.log('true');
      //       return true;
      //     }
      //   });
      //   return false;
      // }),
      // shift_requirement: Yup.string()
      // .required('Minimum Workforce is Required')
      // .test(
      //   'shift-requirement-test',
      //   `Requirement Must be less then Equal to ${
      //     selectedSite.requirement - totalShiftRequirement
      //   }, Or Increase Current Site requirement`,
      //   (value) => {
      //     if (parseInt(value, 10) <= selectedSite.requirement - totalShiftRequirement) {
      //       return true;
      //     }
      //     return false;
      //   }
      // ),
      // .matches(/^[a-zA-Z\s]+$/, "Please enter a valid name"),
      // /^(1[0-2]|0?[1-9]):[0-5][0-9] (am|pm)$/i // 12 hour time Checking Regex
      // /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/ 24 hour time checking Regex
      shift_start: Yup.string()
        .required('Please choose a shift start time for employees')
        .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Please enter a valid start time'),
      // .test('shift-start-test', 'Shirt start time should be less than Shift End time', () => {
      //   if (values.shift_start && values.shift_end) {
      //     const endTimeHour = Number(values.shift_end.split(':')[0]);
      //     const startTimeHour = Number(values.shift_start.split(':')[0]);
      //     return endTimeHour > startTimeHour;
      //   }
      // return true;
      // }),
      shift_end: Yup.string()
        .required('Please choose a shift end time for employees')
        .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Please enter a valid end time')
        .test({
          name: 'max',
          exclusive: false,
          params: {},
          // eslint-disable-next-line no-template-curly-in-string
          // eslint-disable-next-line react/no-this-in-sfc
          // message: `DayOff ${Yup.ref('site_weekly_working_days').getValue()}must be given`,
          // eslint-disable-next-line object-shorthand
          test: function (value) {
            if (value) {
              // eslint-disable-next-line react/no-this-in-sfc
              const startTime = formatTime(this.parent.shift_start);
              const endTime = formatTime(value);
              const hourDiff = getDifferenceInHours(startTime, endTime);
              const des =
                convertTimeToNumber(hourDiff) <= shiftSite?.constraints?.daily_working_hours;
              // eslint-disable-next-line no-unneeded-ternary
              // console.log(startTime, endTime, hourDiff, des);
              if (des) {
                return des;
              }
            }
            // eslint-disable-next-line react/no-this-in-sfc
            return this.createError({
              message: `Max time of shift can be ${shiftSite?.constraints?.daily_working_hours}:00 hrs`,
              path: 'shift_end'
            });
          }
        }),
      // .test('Shift-end-test', 'Shift End time should be higher than shift start time', () => {
      //   if (values.shift_start && values.shift_end) {
      //     const startTimeHour = Number(values.shift_start.split(':')[0]);
      //     const endTimeHour = Number(values.shift_end.split(':')[0]);
      //     return endTimeHour > startTimeHour;
      //   }
      // return true;
      // }),
      shift_weekly_days_off: Yup.array().test({
        name: 'max',
        exclusive: false,
        params: {},
        // eslint-disable-next-line no-template-curly-in-string
        // eslint-disable-next-line react/no-this-in-sfc
        // message: `DayOff ${Yup.ref('site_weekly_working_days').getValue()}must be given`,
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          // You can access the price field with `this.parent`.
          // eslint-disable-next-line react/no-this-in-sfc
          // const diff = shiftSite?.constraints?.weekly_off.length;
          // const des = value.length === diff;
          const diff = shiftSite?.constraints?.site_weekly_working_days - 1;
          const des =
            value.length < diff && value.length >= shiftSite?.constraints?.weekly_off?.length;
          // eslint-disable-next-line no-unneeded-ternary
          if (des) {
            return des;
          }
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            message: `At least ${shiftSite?.constraints?.weekly_off.length} - ${diff} Day Off can be given`,
            path: 'shift_weekly_days_off'
          });
        }
      })
    }),
    // validate: (values) => {
    //   console.log(values);
    // },
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      let data = {};
      const REQUIREMENTS = [];
      if (Object.keys(daysRequirement).length > 0) {
        Object.keys(daysRequirement).forEach((dy) => {
          REQUIREMENTS.push({ day: Number(dy.split(',')[0]), requirement: daysRequirement[dy] }); // Spread its change
        });
      }
      // console.log('Updated Days Requirement:', REQUIREMENTS);
      if (edit !== null) {
        data = {
          id: edit?._id,
          name: capitalizeEachWord(values.shift_name),
          requirements: REQUIREMENTS,
          start: edit?.start,
          end: edit?.end,
          constraints: {
            genders: values.gender_constraint,
            weekly_off: values.shift_weekly_days_off
          },
          supervisors: supervisors.map((sup) => sup.split(',')[0])
        };
      } else {
        data = {
          site: shiftSite?._id,
          name: capitalizeEachWord(values.shift_name),
          requirements: REQUIREMENTS,
          start: values.shift_start,
          end: values.shift_end,
          constraints: {
            genders: values.gender_constraint,
            weekly_off: values.shift_weekly_days_off
          },
          supervisors: supervisors.map((sup) => sup.split(',')[0])
        };
      }

      if (DEBUG) {
        console.log('Shift Add Form :', data);
      } else {
        mutate(data);

        if (
          edit !== null &&
          (!arraysEqual(data.constraints?.weekly_off, shiftWeeklyOff) ||
            !arraysEqual(data.constraints.genders, _Edit?.shift?.constraints?.genders))
        ) {
          clearRosterMutation({
            sites: [],
            shifts: [edit?._id],
            today: getCurrentDate(),
            date: getCurrentDate(),
            isClearFuture: true
          });
        }
      }
    }
  });

  const {
    errors,
    touched,
    values,
    // isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm
  } = formik;

  const submitRequirements = (requ) => {
    let flag = null;
    // console.log('Submit Requirements:', requ);
    Object.keys(requ).forEach((ru) => {
      if (!values.shift_weekly_days_off?.includes(Number(ru?.split(',')[1]))) {
        if (
          Number(ru?.split(',')[0]) >= new Date().getDate() &&
          rosterMonth.getMonth() === new Date().getMonth()
        ) {
          flag = !requ[ru].some((r) => r.value > 0);
        } else if (rosterMonth.getMonth() > new Date().getMonth()) {
          flag = !requ[ru].some((r) => r.value > 0);
        }
      }
    });
    return flag ?? true;
  };

  const headingStyle = {
    fontWeight: 'bold',
    p: 1
  };

  const handleShiftWeeklyOff = (e) => {
    const weekOff = e.target.value;
    const DRQ = { ...daysRequirement };
    const RF = reqfill;
    setShiftWeeklyOff(weekOff);
    const filterSelected = selected.filter((sel) => !weekOff?.includes(Number(sel.split(',')[1])));
    setSelected(filterSelected);
    const dayWeekOff = monthDays?.filter((md) => weekOff?.includes(md.wdn));
    // console.log('Day Week Off:', dayWeekOff);
    const days = dayWeekOff.map((dwf) => `${dwf.day},${dwf.wdn}`);
    // console.log('Final Days:', days);
    days.forEach((dy) => {
      // console.log(`ITR ${dx}`, dy);
      RF[dy] = false;
      DRQ[dy].forEach((rl) => {
        rl.value = 0;
        if (rl.subroles.length > 0) {
          rl.subroles.forEach((srl) => {
            srl.value = 0;
          });
        }
      });
    });
    // console.log('Pre WeekOff:', DRQ);
    setDaysRequirement({ ...DRQ });
    // console.log('WeekOff Update:', daysRequirement);
  };

  return (
    <Modal
      open={open ?? false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} container>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 2,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          <img src={ADD_EMPLOYEE} width="40" height="40" alt="edit_icon" />
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            {edit ? 'Edit' : 'Add'} Shift
          </Typography>
          <IconButton
            onClick={() => onClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        {!useTemplate &&
          !bulkRequirements &&
          !addRequirements &&
          !addConstraints &&
          AllRequirementTemplates?.length > 0 && (
            <Stack sx={{ px: 2, py: 1 }}>
              <ThemeProvider theme={theme}>
                <Button
                  startIcon={<AssignmentIcon />}
                  variant="outlined"
                  color="rose"
                  sx={{ fontWeight: 'bold' }}
                  onClick={() => setUseTemplate(true)}
                >
                  Use Template
                </Button>
              </ThemeProvider>
            </Stack>
          )}
        {useTemplate && (
          <Box sx={{ p: 1 }}>
            <Stack sx={{ mb: 1 }} direction="row" alignItems="center">
              <IconButton
                onClick={() => {
                  setUseTemplate(false);
                  setViewTemplate(null);
                  setBulkRequirements(false);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              &emsp;
              <Typography sx={{ color: '#0897FF' }} align="center">
                <b>All Templates</b>
              </Typography>
            </Stack>
            <Divider />
          </Box>
        )}
        {addRequirements && (
          <Stack sx={{ py: 1 }}>
            <Stack
              direction="row"
              sx={{ px: 1 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <IconButton
                onClick={() => {
                  setAddRequirements(false);
                  setBulkRequirements(false);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography sx={{ ml: 2 }}>
                <b>Requirements</b>
                <Typography
                  sx={{
                    display: 'flex',
                    mb: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 12
                  }}
                  component="span"
                >
                  For every month
                </Typography>
              </Typography>
              <IconButton
                onClick={() => {
                  setBulkRequirements(!bulkRequirements);
                }}
              >
                <ListIcon />
              </IconButton>
            </Stack>
            <Stack sx={{ position: 'relative', mt: 2 }}>
              <Typography
                align="center"
                component="span"
                sx={{ position: 'absolute', top: '5%', left: '45%', zIndex: 9, fontSize: 13 }}
              >
                {parseFloat(progress).toFixed(1)}&nbsp;%
              </Typography>
              <LinearProgress
                variant="determinate"
                color={`${
                  progress < 10
                    ? 'lightred'
                    : progress < 50
                    ? 'lightyellow'
                    : progress === 100
                    ? 'lightgreen'
                    : 'lightblue'
                }`}
                sx={{ height: 20 }}
                value={progress}
              />
            </Stack>
            {progress === 100 ? (
              <Alert severity="success">
                Requirement added, Click list icon <br />
                on top right to edit the requirements
              </Alert>
            ) : (
              <Alert severity="info">
                Select dates for which you want <br />
                to {bulkRequirements ? 'edit' : 'add'} the requirements
              </Alert>
            )}
          </Stack>
        )}
        <FormikProvider value={formik}>
          <Form id="add-employee-form" autoComplete="off" noValidate>
            <Stack
              className="custom_scrollbar"
              height="calc(100vh - 190px)"
              sx={{ px: 1.5, py: 1, mb: 1, overflowY: 'auto' }}
            >
              <Stack
                spacing={1}
                sx={{
                  display: `${addRequirements || useTemplate || addConstraints ? 'none' : ''}`
                }}
              >
                <Stack spacing={1}>
                  <Typography sx={headingStyle}>Timings</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <AccessTimeIcon sx={{ color: 'action.active', mr: 1 }} />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Start"
                        readOnly={!!edit}
                        value={edit ? _24toUTC(edit?.start) : initialValue.shift_start}
                        onChange={(newValue) => {
                          setInitialValue({ ...initialValue, shift_start: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            variant="standard"
                            {...params}
                            error={Boolean(errors.shift_start)}
                            helperText={errors.shift_start}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <AccessTimeIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="End"
                        readOnly={!!edit}
                        value={edit ? _24toUTC(edit?.end) : initialValue.shift_end}
                        onChange={(newValue) => {
                          setInitialValue({ ...initialValue, shift_end: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            variant="standard"
                            {...params}
                            error={Boolean(errors.shift_end)}
                            helperText={errors.shift_end}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Stack>

                <Stack spacing={1}>
                  <Typography sx={headingStyle}>Requirements</Typography>
                  <Stack sx={{ position: 'relative' }}>
                    <Typography
                      align="center"
                      component="span"
                      sx={{
                        position: 'absolute',
                        top: '5%',
                        left: '45%',
                        zIndex: 9,
                        fontSize: 13,
                        color: `${
                          progress === 100
                            ? theme.palette.primary.darker
                            : progress < 50
                            ? theme.palette.warning.darker
                            : progress < 10
                            ? theme.palette.error.darker
                            : theme.palette.info.darker
                        }`
                      }}
                    >
                      {parseFloat(progress).toFixed(1)}&nbsp;%
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      color={`${
                        progress < 10
                          ? 'lightred'
                          : progress < 50
                          ? 'lightyellow'
                          : progress === 100
                          ? 'lightgreen'
                          : 'lightblue'
                      }`}
                      sx={{ height: 20 }}
                      value={progress}
                    />
                  </Stack>
                  {reqError && (
                    <Typography align="center" sx={{ color: 'red', fontSize: 12 }}>
                      {reqError}
                    </Typography>
                  )}
                  {errors?.shift_weekly_days_off && (
                    <Typography align="center" sx={{ color: 'red', fontSize: 11 }}>
                      {errors?.shift_weekly_days_off}
                    </Typography>
                  )}
                  <Button
                    startIcon={progress < 100 ? <AddIcon /> : <UpgradeIcon />}
                    variant="outlined"
                    onClick={() => setAddRequirements(true)}
                  >
                    {progress < 100 ? 'Add' : 'Update'}
                  </Button>
                </Stack>
                <Stack spacing={1}>
                  <Typography sx={headingStyle}>Constraints</Typography>
                  <Button
                    startIcon={<UpgradeIcon />}
                    variant="outlined"
                    onClick={() => setAddConstraints(true)}
                  >
                    Update
                  </Button>
                </Stack>
                <Stack spacing={1}>
                  <Typography sx={headingStyle}>Info</Typography>
                  {/* Name */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <DriveFileRenameOutlineIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      variant="standard"
                      fullWidth
                      disabled={!!(values.shift_start && values.shift_end && edit)}
                      type="text"
                      label="Shift Name"
                      autoComplete="off"
                      required
                      {...getFieldProps('shift_name')}
                      error={Boolean(touched.shift_name && errors.shift_name)}
                      helperText={touched.shift_name && errors.shift_name}
                    />
                  </Box>
                  {/* Supervisor */}
                  {!step && (
                    <Box sx={{ display: 'flex', minWidth: '12rem', alignItems: 'flex-end' }}>
                      <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="choose_supervisor">
                          Choose Supervisor
                        </InputLabel>
                        <Select
                          variant="standard"
                          value={supervisors}
                          inputProps={{
                            name: 'choose_supervisor',
                            id: 'choose_supervisor'
                          }}
                          multiple
                          onChange={(event) => {
                            const {
                              target: { value }
                            } = event;
                            setSupervisors(
                              // On autofill we get a stringified value.
                              typeof value === 'string' ? value.split(',') : value
                            );
                          }}
                          sx={{ mt: 2 }}
                          renderValue={(selp) => selp.map((value) => value?.split(',')[1])}
                        >
                          <MenuItem value="" disabled>
                            Choose Supervisor
                          </MenuItem>
                          {siteEmployeesOptions.map(([key, image, empR, empSR]) => (
                            <MenuItem key={`site-employees-${key}`} value={key}>
                              <Avatar src={image} />
                              <Stack sx={{ ml: 1 }}>
                                <Typography>&nbsp;{key.split(',')[1]}</Typography>
                                <Box display="flex">
                                  <Chip
                                    fontSize={12}
                                    sx={{ height: 18, mb: 0.5 }}
                                    size="small"
                                    label={empR}
                                  />
                                  &nbsp;
                                  {empSR && (
                                    <Chip
                                      fontSize={12}
                                      sx={{ height: 18 }}
                                      size="small"
                                      label={empSR}
                                    />
                                  )}
                                </Box>
                              </Stack>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </Stack>
              </Stack>
              <Stack
                sx={{
                  display: `${addRequirements ? '' : 'none'}`,
                  position: 'relative'
                }}
              >
                {addRequirements && !bulkRequirements ? (
                  <Stack
                    className="custom_scrollbar"
                    sx={{ height: 'calc(100vh - 270px)', overflowY: 'auto', px: 1 }}
                    spacing={2}
                  >
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="select_holidays">
                        Select Holidays
                      </InputLabel>
                      <Select
                        variant="standard"
                        multiple
                        inputProps={{
                          name: 'week_off',
                          id: 'select_holidays'
                        }}
                        value={shiftWeeklyOff}
                        onChange={handleShiftWeeklyOff}
                        error={Boolean(errors.shift_weekly_days_off)}
                        // helperText={touched.policy_role && errors.policy_role}
                      >
                        <MenuItem value="" disabled>
                          Select Holidays
                        </MenuItem>
                        <MenuItem
                          disabled={shiftSite?.constraints?.weekly_off.includes(0)}
                          value={0}
                        >
                          Sunday
                        </MenuItem>
                        <MenuItem
                          disabled={shiftSite?.constraints?.weekly_off.includes(1)}
                          value={1}
                        >
                          Monday
                        </MenuItem>
                        <MenuItem
                          disabled={shiftSite?.constraints?.weekly_off.includes(2)}
                          value={2}
                        >
                          Tuesday
                        </MenuItem>
                        <MenuItem
                          disabled={shiftSite?.constraints?.weekly_off.includes(3)}
                          value={3}
                        >
                          Wednesday
                        </MenuItem>
                        <MenuItem
                          disabled={shiftSite?.constraints?.weekly_off.includes(4)}
                          value={4}
                        >
                          Thrusday
                        </MenuItem>
                        <MenuItem
                          disabled={shiftSite?.constraints?.weekly_off.includes(5)}
                          value={5}
                        >
                          Friday
                        </MenuItem>
                        <MenuItem
                          disabled={shiftSite?.constraints?.weekly_off.includes(6)}
                          value={6}
                        >
                          Saturday
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Stack direction="row" justifyContent="center">
                      <Stack direction="row" sx={{ maxWidth: 290 }} flexWrap="wrap">
                        {monthDays
                          ?.filter((dt) => dt?.day <= dInMonth)
                          ?.map((date, indx) => {
                            const p = reqfill[`${date.day},${date.wdn}`] === true ? 100 : 0;
                            const v = daysRequirement[`${date.day},${date.wdn}`];
                            const tv = v.reduce((a, r) => a + r.value, 0);
                            return (
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                key={`pr-${indx}`}
                                sx={{
                                  position: 'relative',
                                  cursor: 'pointer',
                                  mb: 1,
                                  border: `${
                                    selected?.includes(`${date.day},${date.wdn}`)
                                      ? '2px solid #0897FF'
                                      : values?.shift_weekly_days_off?.includes(date.wdn)
                                      ? '2px solid gray'
                                      : p === 100 && tv > 0 && !disableDate(date.day)
                                      ? `2px solid ${theme.palette.primary.lighter}`
                                      : '2px solid #E5EEEC'
                                  }`
                                }}
                                onClick={() => {
                                  if (
                                    !values.shift_weekly_days_off?.includes(date.wdn) &&
                                    !disableDate(date.day)
                                  ) {
                                    handleClick(`${date.day},${date.wdn}`);
                                  }
                                }}
                              >
                                <Box
                                  style={{
                                    height: 17,
                                    background: `${
                                      !disableDate(date.day)
                                        ? tv === 0
                                          ? theme.palette.error.lighter
                                          : theme.palette.primary.lighter
                                        : '#E5EEEC'
                                    }`,
                                    boxShadow: theme.customShadows.bloated,
                                    fontSize: 12,
                                    zIndex: 2,
                                    position: 'absolute',
                                    top: 0,
                                    textAlign: 'center',
                                    width: '100%'
                                  }}
                                >
                                  {tv}
                                </Box>
                                <Stack
                                  sx={{ position: 'absolute', mt: 3, zIndex: 1, cursor: 'mouse' }}
                                >
                                  <Typography align="center" sx={{ fontSize: 10 }}>
                                    {date.wd.substring(0, 3)}
                                  </Typography>
                                  <Typography
                                    align="center"
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: 'bolder',
                                      color: `${
                                        p === 100 && tv > 0
                                          ? theme.palette.primary.darker
                                          : p < 50
                                          ? theme.palette.warning.darker
                                          : p > 100
                                          ? theme.palette.error.darker
                                          : theme.palette.info.darker
                                      }`
                                    }}
                                  >
                                    {date.day}
                                  </Typography>
                                </Stack>
                                <LinearProgress
                                  variant="determinate"
                                  value={p}
                                  color="default_white"
                                  sx={{
                                    height: 54,
                                    width: 40,
                                    // borderRadius: 0.5,
                                    backgroundColor: 'white !important'
                                  }}
                                />
                              </Stack>
                            );
                          })}
                      </Stack>
                    </Stack>
                    {selected.length > 0 && (
                      <Stack>
                        {reqHeirarchy?.map((role, index) => (
                          <Accordion
                            key={`global-${role.role}`}
                            expanded={expanded === 'panel1'}
                            onChange={handleChange('panel1')}
                          >
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                              <Typography>{role?.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {role?.subroles?.length > 0 && (
                                <FormControlLabel
                                  sx={{ mb: 1 }}
                                  control={
                                    <Checkbox
                                      onChange={(event) => {
                                        if (onlyRole[`global-${role.role}`]) {
                                          delete onlyRole[`global-${role.role}`];
                                        } else {
                                          const req = reqHeirarchy;
                                          req[index].value = 0;
                                          req[index].subroles.forEach((obj) => {
                                            if (obj?.value) {
                                              delete obj.value;
                                            }
                                          });
                                          setReqHeirarchy([...req]);
                                          onlyRole[`global-${role.role}`] = event.target.checked;
                                          setOnlyRole({ ...onlyRole });
                                        }
                                      }}
                                      checked={!!onlyRole[`global-${role.role}`]}
                                    />
                                  }
                                  label="Only Total Requirement"
                                />
                              )}
                              {!onlyRole[`global-${role.role}`] &&
                                role?.subroles.map((subrole, idx) => (
                                  <Box
                                    key={`${subrole.subrole}`}
                                    sx={{ display: 'flex', alignItems: 'flex-end', mb: 1 }}
                                  >
                                    <PeopleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                    <TextField
                                      variant="standard"
                                      fullWidth
                                      // disabled={!!(values.shift_start && values.shift_end && edit)}
                                      type="text"
                                      // eslint-disable-next-line no-restricted-globals
                                      value={isNaN(subrole?.value) ? 0 : subrole?.value}
                                      // inputProps={{ min: 0, max: 1000 }}
                                      label={`${subrole.name}`}
                                      autoComplete="off"
                                      required
                                      onChange={(event) => {
                                        let total = 0;
                                        const req = reqHeirarchy;
                                        req[index].subroles[idx].value = parseInt(
                                          event.target.value,
                                          10
                                        );
                                        req[index].subroles.forEach((obj) => {
                                          if (obj?.value) {
                                            total += obj.value;
                                          }
                                        });
                                        req[index].value = total;
                                        setReqHeirarchy([...req]);
                                        if (parseInt(event.target.value, 10)) {
                                          setHFill(true);
                                        }
                                      }}
                                      // {...getFieldProps('shift_name')}
                                      // error={Boolean(touched.shift_name && errors.shift_name)}
                                      // helperText={touched.shift_name && errors.shift_name}
                                    />
                                  </Box>
                                ))}
                              {(onlyRole[`global-${role.role}`] || role.subroles.length === 0) && (
                                <Box
                                  key={`_${role.role}`}
                                  sx={{ display: 'flex', alignItems: 'flex-end', mb: 1 }}
                                >
                                  <PeopleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                  <TextField
                                    variant="standard"
                                    fullWidth
                                    // disabled={!!(values.shift_start && values.shift_end && edit)}
                                    type="text"
                                    // eslint-disable-next-line no-restricted-globals
                                    value={isNaN(role?.value) ? 0 : role?.value}
                                    inputProps={{ min: 0, max: 1000 }}
                                    label={`${role.name}`}
                                    autoComplete="off"
                                    required
                                    onChange={(event) => {
                                      const req = reqHeirarchy;
                                      req[index].value = parseInt(event.target.value, 10);
                                      setReqHeirarchy([...req]);
                                      if (parseInt(event.target.value, 10)) {
                                        setHFill(true);
                                      }
                                    }}
                                    // {...getFieldProps('shift_name')}
                                    // error={Boolean(touched.shift_name && errors.shift_name)}
                                    // helperText={touched.shift_name && errors.shift_name}
                                  />
                                </Box>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                        <Button
                          variant="contained"
                          sx={{ my: 2 }}
                          onClick={() => {
                            selected.forEach((d) => {
                              const rHQ = [];
                              reqHeirarchy.forEach((rh) => {
                                const srl = [];
                                const rl = {
                                  name: rh.name,
                                  role: rh.role,
                                  value: rh.value
                                };
                                rh.subroles.forEach((sr) =>
                                  srl.push({
                                    name: sr.name,
                                    subrole: sr.subrole,
                                    value: sr.value
                                  })
                                );
                                rl.subroles = [...srl];
                                rHQ.push(rl);
                              });
                              daysRequirement[d] = [...rHQ];
                              reqfill[d] = true;
                              setReqFill({ ...reqfill });
                              setBulkRequirements(false);
                              setSelected([]);
                            });
                          }}
                        >
                          {_Edit ? 'Update' : 'Add'}
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                ) : (
                  <Stack
                    className="custom_scrollbar"
                    sx={{ height: 'calc(100vh - 270px)', overflowY: 'auto' }}
                  >
                    {monthDays
                      ?.filter((dt) => dt?.day <= dInMonth)
                      ?.sort((a, b) => a.m - b.m)
                      ?.map((date) => (
                        <MuiAccordion
                          TransitionProps={{ unmountOnExit: true }}
                          key={`requirement-day-${date.day}`}
                          expanded={outerExpanded === `requirement-day-${date.day}`}
                          onChange={handleAChange(`requirement-day-${date.day}`)}
                        >
                          <MuiAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              sx={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Typography component="span">
                                {ordinal(date.day)}&nbsp;{date.month},&nbsp;
                                {date.wd.substring(0, 3)}
                              </Typography>
                              &emsp;
                              {checkRequirement(daysRequirement[`${date.day},${date.wdn}`]) && (
                                <CheckCircleIcon sx={{ color: 'green', fontSize: 18, mr: 1 }} />
                              )}
                            </Typography>
                          </MuiAccordionSummary>
                          <MuiAccordionDetails>
                            {!isShiftLoading &&
                              !isShiftError &&
                              daysRequirement[`${date.day},${date.wdn}`]?.map((role, index) => (
                                <Accordion
                                  key={`day-${date.day}-${role.role}-Ngxd7`}
                                  expanded={expanded === 'panel1'}
                                  onChange={handleChange('panel1')}
                                >
                                  <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                  >
                                    <Typography>{role?.name}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {role?.subroles?.length > 0 && (
                                      <FormControlLabel
                                        sx={{ mb: 1 }}
                                        control={
                                          <Checkbox
                                            onChange={(event) => {
                                              if (onlyRole[`${date.day}-${role.role}`]) {
                                                delete onlyRole[`${date.day}-${role.role}`];
                                              } else {
                                                const _eq = daysRequirement;
                                                _eq[`${date.day},${date.wdn}`][index].value = 0;
                                                _eq[`${date.day},${date.wdn}`][
                                                  index
                                                ].subroles.forEach((obj) => {
                                                  if (obj?.value) {
                                                    delete obj.value;
                                                  }
                                                });
                                                setDaysRequirement({ ..._eq });
                                                onlyRole[`${date.day}-${role.role}`] =
                                                  event.target.checked;
                                                setOnlyRole({ ...onlyRole });
                                              }
                                            }}
                                            checked={!!onlyRole[`${date.day}-${role.role}`]}
                                          />
                                        }
                                        label="Only Total Requirement"
                                      />
                                    )}
                                    {!onlyRole[`${date.day}-${role.role}`] &&
                                      role?.subroles.map((subrole, idx) => (
                                        <Box
                                          key={`${subrole.subrole}`}
                                          sx={{ display: 'flex', alignItems: 'flex-end', mb: 1 }}
                                        >
                                          <PeopleIcon
                                            sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                                          />
                                          <TextField
                                            variant="standard"
                                            fullWidth
                                            // disabled={!!(values.shift_start && values.shift_end && edit)}
                                            type="text"
                                            defaultValue={
                                              Number.isNaN(subrole?.value) ? 0 : subrole?.value
                                            }
                                            // inputProps={{ min: 0, max: 1000 }}
                                            label={`${subrole.name}`}
                                            autoComplete="off"
                                            required
                                            onChange={(event) => {
                                              let total = 0;
                                              const _v = daysRequirement;
                                              _v[`${date.day},${date.wdn}`][index].subroles[
                                                idx
                                              ].value = parseInt(event.target.value, 10);
                                              _v[`${date.day},${date.wdn}`][index].subroles.forEach(
                                                (obj) => {
                                                  if (obj?.value) {
                                                    total += obj.value;
                                                  }
                                                }
                                              );
                                              _v[`${date.day},${date.wdn}`][index].value = total;
                                              setDaysRequirement({ ..._v });
                                              reqfill[`${date.day},${date.wdn}`] = true;
                                              setReqFill({ ...reqfill });
                                            }}
                                            // {...getFieldProps('shift_name')}
                                            // error={Boolean(touched.shift_name && errors.shift_name)}
                                            // helperText={touched.shift_name && errors.shift_name}
                                          />
                                        </Box>
                                      ))}
                                    {(onlyRole[`${date.day}-${role.role}`] ||
                                      role.subroles.length === 0) && (
                                      <Box
                                        key={`_${role.role}`}
                                        sx={{ display: 'flex', alignItems: 'flex-end', mb: 1 }}
                                      >
                                        <PeopleIcon
                                          sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                                        />
                                        <TextField
                                          variant="standard"
                                          fullWidth
                                          // disabled={!!(values.shift_start && values.shift_end && edit)}
                                          type="text"
                                          defaultValue={Number.isNaN(role?.value) ? 0 : role?.value}
                                          // inputProps={{ min: 0, max: 1000 }}
                                          label={`${role.name}`}
                                          autoComplete="off"
                                          required
                                          onChange={(event) => {
                                            const req = daysRequirement[`${date.day},${date.wdn}`];
                                            req[index].value = parseInt(event.target.value, 10);
                                            daysRequirement[`${date.day},${date.wdn}`] = req;
                                            setDaysRequirement({ ...daysRequirement });
                                            reqfill[`${date.day},${date.wdn}`] = true;
                                            setReqFill({ ...reqfill });
                                          }}
                                          // {...getFieldProps('shift_name')}
                                          // error={Boolean(touched.shift_name && errors.shift_name)}
                                          // helperText={touched.shift_name && errors.shift_name}
                                        />
                                      </Box>
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              ))}
                          </MuiAccordionDetails>
                        </MuiAccordion>
                      ))}
                  </Stack>
                )}

                {errors.shift_requirement ? (
                  <Typography>{errors.shift_requirement}</Typography>
                ) : null}
              </Stack>
              <Stack sx={{ display: `${useTemplate ? '' : 'none'}` }}>
                {viewTemplate ? (
                  <Stack>
                    <Typography>Name&nbsp;:&nbsp;{viewTemplate.name}</Typography>
                    <Typography>
                      Timing&nbsp;:&nbsp;{_12hourTimeFormat(viewTemplate.start)}&nbsp;-&nbsp;
                      {_12hourTimeFormat(viewTemplate.end)}
                    </Typography>
                    <Stack
                      sx={{
                        border: '2px solid #0897FF',
                        borderRadius: 1,
                        p: 1,
                        pt: 2,
                        mt: 2,
                        position: 'relative'
                      }}
                    >
                      <Typography
                        sx={{ position: 'absolute', top: -13, px: 1, backgroundColor: 'white' }}
                      >
                        Supervisor's
                      </Typography>
                      {viewTemplate.supervisors.length > 0 ? (
                        viewTemplate.supervisors.map((sup) => (
                          <Stack sx={{ my: 0.5 }} key={sup._id} direction="row" alignItems="center">
                            <Avatar src={sup.avatar} />
                            &nbsp;&nbsp;
                            <Typography>{sup.name}</Typography>
                          </Stack>
                        ))
                      ) : (
                        <Typography align="center">No Supervisor Available</Typography>
                      )}
                    </Stack>
                    <Stack
                      sx={{
                        border: '2px solid #0897FF',
                        borderRadius: 1,
                        p: 1,
                        pt: 2,
                        mt: 2,
                        position: 'relative'
                      }}
                    >
                      <Typography
                        sx={{ position: 'absolute', top: -13, px: 1, backgroundColor: 'white' }}
                      >
                        Requirements
                      </Typography>
                      <TreeView
                        aria-label="file system navigator"
                        className="custom_scrollbar"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                      >
                        {viewTemplate.requirements.map((rd, inx) => (
                          <TreeItem
                            key={`${rd._id}-${inx}`}
                            nodeId={inx + 1}
                            label={ordinal(rd.day)}
                          >
                            {rd.requirement.map((rdq, idx) => (
                              <TreeItem
                                key={`${rdq._id}-${idx}`}
                                nodeId={`${inx}${idx}`}
                                label={`${rdq.role.name} ( ${rdq.value} )`}
                              >
                                {/* {console.log(rdq)} */}
                                {rdq.subroles.map((srdq, iex) => (
                                  <TreeItem
                                    key={`${srdq._id}-${iex}`}
                                    nodeId={`${inx}${idx}${iex}`}
                                    label={`${srdq.subrole.name} ( ${srdq.value} )`}
                                  />
                                ))}
                              </TreeItem>
                            ))}
                          </TreeItem>
                        ))}
                      </TreeView>
                    </Stack>

                    <Stack sx={{ mt: 1.5 }} direction="row">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          const REQ_ = {};
                          const dayWeekOff = monthDays
                            ?.filter((md) => shiftWeeklyOff?.includes(md.wdn))
                            ?.map((dw) => dw.wdn);
                          viewTemplate.requirements.forEach((rq_) => {
                            const mday = monthDays?.find((mds) => mds.day === rq_.day).wdn;
                            REQ_[`${rq_.day},${mday}`] = rq_.requirement.map((q) => {
                              q.name = q.role.name;
                              q.role = q.role._id;
                              q.subroles = q.subroles.map((sq) => {
                                sq.name = sq.subrole.name;
                                sq.subrole = sq.subrole._id;
                                return sq;
                              });
                              return q;
                            });
                            if (
                              !dayWeekOff?.includes(rq_.day) &&
                              REQ_[`${rq_.day},${mday}`].some((r) => r.value > 0)
                            ) {
                              if (rosterMonth.getMonth() === new Date().getMonth()) {
                                if (rq_.day >= new Date().getDate()) {
                                  reqfill[`${rq_.day},${mday}`] = true;
                                }
                              } else if (rosterMonth.getMonth() > new Date().getMonth()) {
                                reqfill[`${rq_.day},${mday}`] = true;
                              }
                            }
                            // if (!dayWeekOff.includes(mday)) {
                            //   reqfill[`${rq_.day},${mday}`] = true;
                            // }
                          });
                          setInitialValue({
                            ...initialValue,
                            shift_start: _24toUTC(viewTemplate.start),
                            shift_end: _24toUTC(viewTemplate.end)
                          });
                          // console.log(REQ_);
                          setDaysRequirement({ ...REQ_ });
                          setReqFill({ ...reqfill });
                          setUseTemplate(false);
                          setViewTemplate(false);
                          setBulkRequirements(false);
                        }}
                      >
                        Use
                      </Button>
                      &emsp;
                      <Button
                        onClick={() => setViewTemplate(null)}
                        variant="contained"
                        color="error"
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Stack>
                ) : (
                  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {!isTemplatesLoading &&
                      !isTemplatesError &&
                      AllRequirementTemplates.map((rq, index) => {
                        console.log();
                        return (
                          <ListItem
                            key={`template-${index}-${rq._id}`}
                            className="card_tile"
                            // secondaryAction={
                            //   <Tooltip title="View Template">
                            //     <IconButton onClick={() => setViewTemplate(rq)} edge="start">
                            //       <PreviewIcon edge="end" />
                            //     </IconButton>
                            //   </Tooltip>
                            // }
                            onClick={() => setViewTemplate(rq)}
                            disablePadding
                            sx={{
                              mb: 1,
                              cursor: 'pointer',
                              boxShadow:
                                'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                            }}
                          >
                            <Stack sx={{ p: 1 }}>
                              <Typography>
                                {/* sx={{ color: '#F31275' }} */}
                                <b>{rq.name}</b>
                              </Typography>
                              <Typography sx={{ fontSize: 12 }}>{rq.site.name}</Typography>
                              <Typography>
                                <Typography component="span" sx={{ color: 'green', fontSize: 12 }}>
                                  {_12hourTimeFormat(rq.start)}
                                </Typography>{' '}
                                -{' '}
                                <Typography component="span" sx={{ color: 'red', fontSize: 12 }}>
                                  {_12hourTimeFormat(rq.end)}
                                </Typography>
                              </Typography>
                            </Stack>
                          </ListItem>
                        );
                      })}
                  </List>
                )}
              </Stack>
              <Stack sx={{ display: `${addConstraints ? '' : 'none'}` }}>
                <Stack direction="row" alignItems="center">
                  <IconButton onClick={() => setAddConstraints(false)}>
                    <ArrowBackIcon />
                  </IconButton>
                  &emsp;
                  <Typography sx={{ color: '#0897FF' }}>
                    <b>All Constraints</b>
                  </Typography>
                </Stack>
                <Divider />
                <Box sx={{ py: 1 }}>
                  <Typography sx={{ mb: 1 }}>Allowed Gender</Typography>
                  <FormControl fullWidth>
                    <InputLabel variant="standard" required htmlFor="gender_constraint">
                      Choose Gender
                    </InputLabel>
                    <Select
                      variant="standard"
                      inputProps={{
                        name: 'gender_constraint',
                        id: 'gender_constraint'
                      }}
                      required
                      multiple
                      sx={{ mt: 2 }}
                      {...getFieldProps('gender_constraint')}
                      // error={Boolean(touched.manager_type && errors.manager_type)}
                      // helperText={touched.policy_role && errors.policy_role}
                    >
                      <MenuItem value="" disabled>
                        Choose Gender
                      </MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
            </Stack>
            {!addRequirements && !addConstraints && !viewTemplate && !useTemplate && (
              <Stack
                sx={{
                  position: 'absolute',
                  width: '100%',
                  backgroundColor: 'white',
                  bottom: 0,
                  px: 2,
                  pb: 1.2
                }}
              >
                <Button
                  type="button"
                  onClick={() => {
                    if (edit) {
                      values.shift_start = edit?.start;
                      values.shift_end = edit?.end;
                    }
                    // if (submitRequirements(daysRequirement)) {
                    //   setReqError('Please Fill All the Requirement Before Submit');
                    // } else {
                    handleSubmit();
                    // }
                  }}
                  variant="contained"
                >
                  Submit
                </Button>
              </Stack>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Modal>
  );
}

AddShift.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.object,
  step: PropTypes.bool,
  shiftSite: PropTypes.object,
  rosterMonth: PropTypes.object
  // totalShiftRequirement: PropTypes.number
};

export default memo(AddShift);
