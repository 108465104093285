import { useState, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  Popover,
  FormControl,
  Avatar,
  // Chip,
  MenuItem,
  Divider,
  InputLabel,
  Select,
  Button
} from '@mui/material';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// hooks
import { useRoles } from '../../../hooks/roles';
import { useSiteManagers } from '../../../hooks/site';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

ImportSiteListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  assignManager: PropTypes.func,
  isSelected: PropTypes.array
};

export default function ImportSiteListToolbar({
  numSelected,
  filterName,
  onFilterName,
  assignManager,
  isSelected
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [, setRoleOptions] = useState(); // Roles initial State for select
  const [managerOptions, setManagerOptions] = useState();
  const [assign, setAssign] = useState({
    role: null,
    manager: null
  });
  const cache = useQueryClient();

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  const allRoles = useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
    return ''; // Needs to be changed
  }, [roles, isRolesLoading, isRolesError]);

  useEffect(() => {
    const formattedRoles = [];
    if (allRoles?.length > 0) {
      allRoles.forEach((role) => formattedRoles.push([role._id, role.name]));
      setRoleOptions([...formattedRoles]);
      // console.log('Role Options :', formattedRoles);
    }
  }, [allRoles]);
  /* ================= End Roles =============== */
  /* ===================== Employees ===================== */

  const {
    isLoading,
    isError,
    data
    // fetchNextPage: fetchNextSiteManagers,
    // hasNextPage: hasNextSiteManagers
  } = useSiteManagers(
    null, // search
    assign.role
  ); // getting managers

  const allEmployees = useMemo(() => {
    if (!isLoading && !isError) {
      const listOfEmployees = [];
      if (data?.pages?.length > 0) {
        data.pages.forEach((group) => listOfEmployees.push(group.employees));
      }
      const flattened = listOfEmployees.flat();
      // console.log(flattened);
      return flattened;
    }
    return [];
  }, [data, isLoading, isError]);

  useEffect(() => {
    const formattedEmployees = [];
    if (allEmployees?.length > 0) {
      allEmployees.forEach((emp) =>
        formattedEmployees.push([
          `${emp._id}, ${emp.name}`,
          emp.avater,
          emp.role.name,
          emp?.subrole?.name
        ])
      );
    }
    setManagerOptions([...formattedEmployees]);
  }, [allEmployees]);

  /* ================= End Employees =============== */
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main'
          // bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search Site..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <Stack direction="row">
          <Tooltip title="Assign Manager">
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
              <AddModeratorIcon />
            </IconButton>
          </Tooltip>
          &emsp;
          <Tooltip title="Delete">
            <IconButton>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : // <Tooltip title="Filter list">
      //   <IconButton>
      //     <Icon icon={roundFilterList} />
      //   </IconButton>
      // </Tooltip>
      null}
      <Popover
        id="assign_role_manager_to_employees"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack spacing={1} width="320px" sx={{ p: 2 }}>
          <Stack direction="row" alignItems="center">
            <AddModeratorIcon />
            <Typography sx={{ ml: 1 }}>Assign Manager</Typography>
          </Stack>
          <Divider />
          {/* Manager */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              '& #mui-component-select-site_assign_manager': {
                display: 'flex',
                pl: 1,
                alignItems: 'center'
              }
            }}
          >
            <SupervisedUserCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="site_assign_manager">
                Choose Manager
              </InputLabel>
              <Select
                variant="standard"
                value={assign.manager}
                inputProps={{
                  name: 'site_assign_manager',
                  id: 'site_assign_manager'
                }}
                onChange={(e) => setAssign({ ...assign, manager: e.target.value })}
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Choose Manager
                </MenuItem>
                {managerOptions?.length > 0 ? (
                  managerOptions.map(([key, image]) => (
                    <MenuItem
                      style={{ padding: '0.5rem' }}
                      key={`${'manager_filter'}-${key}`}
                      value={key}
                      data-icon={image}
                    >
                      <Avatar src={image} />
                      <Stack sx={{ ml: 1 }}>
                        <Typography>&nbsp;{key.split(',')[1]}</Typography>
                        <Box display="flex">
                          {/* <Chip
                            fontSize={12}
                            sx={{ height: 18, mb: 0.5 }}
                            size="small"
                            label={empR}
                          />
                          &nbsp;
                          {empSR && (
                            <Chip fontSize={12} sx={{ height: 18 }} size="small" label={empSR} />
                          )} */}
                        </Box>
                      </Stack>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled />
                )}
              </Select>
            </FormControl>
          </Box>
          <br />
          <Box>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              id="employee_apply_filter_button"
              size="medium"
              onClick={() => {
                setAnchorEl(null);
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'This will apply changes to all selected Site!',
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, Proceed!',
                  allowOutsideClick: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    assignManager.mutate({
                      sites: isSelected,
                      manager: assign?.manager.split(',')[0]
                    });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    cache.invalidateQueries('unmanaged-sites');
                  }
                });
              }}
            >
              Assign
            </Button>
          </Box>
        </Stack>
      </Popover>
    </RootStyle>
  );
}
