import PieChartIcon from '@mui/icons-material/PieChart';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

// ----------------------------------------------------------------------

const sidebarConfig = [
  {
    title: 'Payboard',
    path: '/payroll/app',
    icon: <PieChartIcon />
  },
  {
    title: 'PaySlip',
    path: '/payroll/payslip',
    icon: <PieChartIcon />
  },
  {
    title: 'ECR Challan',
    path: '/payroll/ecr-challan',
    icon: <PieChartIcon />
  },
  {
    title: 'Loan',
    path: '/payroll/loan',
    icon: <PieChartIcon />
  },
  {
    title: 'Template',
    path: '/payroll/template',
    icon: <PeopleIcon />
  },
  {
    title: 'Component',
    path: '/payroll/component',
    icon: <PeopleIcon />
  },
  {
    title: 'Settings',
    path: '/payroll/settings',
    icon: <PeopleIcon />
  }
];

export default sidebarConfig;
