import jwtDecode from 'jwt-decode';
import {
  ADMIN_INFO_SUCCEDED,
  ADMIN_INFO_REQUESTED,
  ADMIN_INFO_FAILED,
  ADMIN_LOGGED_OUT
} from '../constants/adminConstants';
import {
  DD_MM_YYYY,
  setDataInStorage,
  getDataFromStorage,
  setEncryptionKey,
  clearStorage
} from '../../utils/functions';
// import AES from 'crypto-js/aes';
/**
 * Action to store admin information in the redux store
 * @param {object} data admin info returned after successfully logging in
 */
export const storeAdminInfo = (data) => async (dispatch) => {
  try {
    let adminInfo;
    const { token } = data.data;
    dispatch({ type: ADMIN_INFO_REQUESTED });
    if (await data) {
      adminInfo = jwtDecode(token);
      const { dob } = adminInfo;
      adminInfo.isMainAdmin = !adminInfo?.admin;
      // console.log(adminInfo);
      if (dob) {
        adminInfo.dob = DD_MM_YYYY(dob).split('-').reverse().join('-');
      }
      setEncryptionKey('admin_key', adminInfo._id + adminInfo.createdAt);
      setDataInStorage('adminAuthToken', token);
      setDataInStorage('adminInfo', adminInfo);
      // localStorage.setItem('adminAuthToken', JSON.stringify(token));
      // localStorage.setItem('adminInfo', JSON.stringify(adminInfo));
    } else {
      throw new Error('No data to store.');
    }
    dispatch({ type: ADMIN_INFO_SUCCEDED, payload: adminInfo });
  } catch (error) {
    console.error('storeAdminInfo ', error);
    dispatch({
      type: ADMIN_INFO_FAILED,
      payload: error.response
    });
  }
};

/**
 * Action to update stored admin info in the redux
 * @param {string or object} key key or an object with key:value of the fields to be updated
 * @param {any} value value of the field to be updated (only if the key passed is of type string)
 */
export const updateAdminInfo = (key, value) => async (dispatch) => {
  // console.log('typeof key: ', typeof key, key);
  try {
    // const adminInfo = JSON.parse(localStorage.getItem('adminInfo'));
    const adminInfo = getDataFromStorage('adminInfo');
    if (typeof key === 'object') {
      let updatedInfo = { ...adminInfo };
      Object.entries(key).forEach(([key, value]) => {
        updatedInfo = { ...updatedInfo, [key]: value };
      });
      setDataInStorage('adminInfo', updatedInfo);
      dispatch({
        type: ADMIN_INFO_SUCCEDED,
        payload: { ...updatedInfo }
      });
    } else if (typeof key === 'string') {
      if (value) {
        // console.log('adminInfo', { ...adminInfo, [key]: value });
        setDataInStorage('adminInfo', { ...adminInfo, [key]: value });

        dispatch({
          type: ADMIN_INFO_SUCCEDED,
          payload: { ...adminInfo, [key]: value }
        });
      } else {
        console.error('Value is not provided for the given key to update admin info');
      }
    }
  } catch (error) {
    console.error('update logo action ', error);
  }
};

/**
 * Action to log out the current admin session
 */
export const logOut = () => (dispatch) => {
  try {
    const keys = JSON.parse(localStorage.getItem('temporaryKeys'));
    keys.forEach((key) => localStorage.removeItem(key));
  } catch (error) {
    console.log('Something bad happened while accessing temporary keys from localStorage');
  }
  localStorage.removeItem('adminAuthToken');
  localStorage.removeItem('adminInfo');
  localStorage.removeItem('temporaryKeys');
  localStorage.removeItem('snappedLocs');
  localStorage.removeItem('fcm_token'); // Remove firebase token
  clearStorage(['adminAuthToken', 'adminInfo', 'admin_key']);
  dispatch({ type: ADMIN_LOGGED_OUT });
  window.location.href = '/login';
};
