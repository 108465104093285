export const lightGradients = [
  {
    gradient: 'linear-gradient(135deg, #ff9a9e 5%, #fecfef 50%)',
    border: '#ff9a9e',
    color: '#ffffff'
  },
  {
    gradient: 'linear-gradient(135deg, #a1c4fd 10%, #c2e9fb 100%)',
    border: '#a1c4fd',
    color: '#333'
  },
  {
    gradient: 'linear-gradient(135deg, #ffecd2 10%, #fcb69f 100%)',
    border: '#ffecd2',
    color: '#333'
  },
  {
    gradient: 'linear-gradient(to right, #d9a7c7, #fffcdc)',
    border: '#d9a7c7',
    color: '#333'
  },
  {
    gradient: 'linear-gradient(to right, #ee9ca7, #ffdde1)',
    border: '#ee9ca7',
    color: '#333'
  },
  {
    gradient: 'linear-gradient(to right, #f7f8f8, #acbb78)',
    border: '#f7f8f8',
    color: '#333'
  },
  {
    gradient: 'linear-gradient(to right, #cac531, #f3f9a7)',
    border: '#cac531',
    color: '#333'
  },
  {
    gradient: 'linear-gradient(to right, #cac531, #f3f9a7)',
    border: '#cac531',
    color: '#333'
  },
  {
    gradient: 'linear-gradient(to right, #a1ffce, #faffd1)',
    border: '#a1ffce',
    color: '#333'
  },
  {
    gradient: 'linear-gradient(to right, #abbaab, #ffffff)',
    border: '#abbaab',
    color: '#333'
  },
  {
    gradient: 'linear-gradient(to right, #70e1f5, #ffd194)',
    border: '#70e1f5',
    color: '#333'
  },
  {
    gradient: 'linear-gradient(to right, #c9ffbf, #ffafbd)',
    border: '#c9ffbf',
    color: '#333'
  },
  {
    gradient: 'linear-gradient(to right, #dad299, #b0dab9)',
    border: '#dad299',
    color: '#333'
  },
  {
    gradient: 'linear-gradient(to right, #f7f8f8, #acbb78)',
    border: '#f7f8f8',
    color: '#333'
  }
  // 'linear-gradient(135deg, #a3bded 10%, #6991c7 100%)',
  // 'linear-gradient(135deg, #13547a 10%, #80d0c7 100%)',
  // 'linear-gradient(135deg, #c79081 10%, #dfa579 100%)'
];

export const colors = [
  // { name: 'brown', value: '#A52A2A' },
  // { name: 'firebrick', value: '#B22222' },
  // { name: 'crimson', value: '#DC143C' },
  // { name: 'coral', value: '#FF7F50' },
  // { name: 'indian red', value: '#CD5C5C' },
  // { name: 'light coral', value: '#F08080' },
  // { name: 'dark salmon', value: '#E9967A' },
  // { name: 'salmon', value: '#FA8072' },
  // { name: 'light salmon', value: '#FFA07A' },
  // { name: 'orange red', value: '#FF4500' },
  // { name: 'dark orange', value: '#FF8C00' },
  // { name: 'orange', value: '#FFA500' },
  { name: 'gold', value: '#FFD700' },
  // { name: 'dark golden rod', value: '#B8860B' },
  // { name: 'golden rod', value: '#DAA520' },
  // { name: 'pale golden rod', value: '#EEE8AA' },
  // { name: 'dark khaki', value: '#BDB76B' },
  // { name: 'khaki', value: '#F0E68C' },
  // { name: 'olive', value: '#808000' },
  // { name: 'yellow', value: '#FFFF00' },
  { name: 'yellow green', value: '#9ACD32' },
  { name: 'dark olive green', value: '#556B2F' },
  // { name: 'olive drab', value: '#6B8E23' },
  // { name: 'lawn green', value: '#7CFC00' },
  // { name: 'chartreuse', value: '#7FFF00' },
  // { name: 'green yellow', value: '#ADFF2F' },
  // { name: 'dark green', value: '#006400' },
  // { name: 'green', value: '#008000' },
  // { name: 'forest green', value: '#228B22' },
  // { name: 'lime', value: '#00FF00' },
  // { name: 'lime green', value: '#32CD32' },
  // { name: 'light green', value: '#90EE90' },
  // { name: 'pale green', value: '#98FB98' },
  // { name: 'dark sea green', value: '#8FBC8F' },
  // { name: 'medium spring green', value: '#00FA9A' },
  // { name: 'spring green', value: '#00FF7F' },
  // { name: 'sea green', value: '#2E8B57' },
  { name: 'medium aqua marine', value: '#66CDAA' },
  // { name: 'medium sea green', value: '#3CB371' },
  // { name: 'light sea green', value: '#20B2AA' },
  { name: 'dark slate gray', value: '#2F4F4F' },
  { name: 'teal', value: '#008080' },
  { name: 'dark cyan', value: '#008B8B' },
  { name: 'aqua', value: '#00FFFF' },
  { name: 'dark turquoise', value: '#00CED1' },
  { name: 'turquoise', value: '#40E0D0' },
  { name: 'medium turquoise', value: '#48D1CC' },
  { name: 'pale turquoise', value: '#AFEEEE' },
  { name: 'aqua marine', value: '#7FFFD4' },
  { name: 'cadet blue', value: '#5F9EA0' },
  { name: 'corn flower blue', value: '#6495ED' },
  { name: 'dodger blue', value: '#1E90FF' },
  { name: 'light blue', value: '#ADD8E6' },
  { name: 'navy', value: '#000080' },
  { name: 'royal blue', value: '#4169E1' },
  { name: 'blue violet', value: '#8A2BE2' },
  { name: 'light cyan', value: '#E0FFFF' },
  { name: 'turquoise', value: '#40E0D0' },
  { name: 'powder blue', value: '#B0E0E6' },
  { name: 'steel blue', value: '#4682B4' },
  { name: 'deep sky blue', value: '#00BFFF' },
  { name: 'sky blue', value: '#87CEEB' },
  { name: 'light sky blue', value: '#87CEFA' },
  { name: 'midnight blue', value: '#191970' },
  { name: 'dark blue', value: '#00008B' },
  { name: 'medium blue', value: '#0000CD' },
  { name: 'blue', value: '#0000FF' },
  { name: 'indigo', value: '#4B0082' },
  { name: 'dark slate blue', value: '#483D8B' },
  { name: 'slate blue', value: '#6A5ACD' },
  { name: 'medium purple', value: '#9370DB' },
  { name: 'medium slate blue', value: '#7B68EE' },
  { name: 'dark magenta', value: '#8B008B' },
  { name: 'dark violet', value: '#9400D3' },
  { name: 'dark orchid', value: '#9932CC' },
  { name: 'medium orchid', value: '#BA55D3' },
  { name: 'purple', value: '#800080' },
  { name: 'thistle', value: '#D8BFD8' },
  { name: 'plum', value: '#DDA0DD' },
  { name: 'violet', value: '#EE82EE' },
  { name: 'magenta / fuchsia', value: '#FF00FF' },
  { name: 'orchid', value: '#DA70D6' },
  { name: 'medium violet red', value: '#C71585' },
  { name: 'pale violet red', value: '#DB7093' },
  { name: 'deep pink', value: '#FF1493' },
  { name: 'hot pink', value: '#FF69B4' },
  { name: 'light pink', value: '#FFB6C1' },
  { name: 'pink', value: '#FFC0CB' },
  { name: 'antique white', value: '#FAEBD7' },
  { name: 'beige', value: '#F5F5DC' },
  { name: 'bisque', value: '#FFE4C4' },
  { name: 'blanched almond', value: '#FFEBCD' },
  { name: 'wheat', value: '#F5DEB3' },
  { name: 'corn silk', value: '#FFF8DC' },
  { name: 'lemon chiffon', value: '#FFFACD' },
  { name: 'light golden rod yellow', value: '#FAFAD2' },
  { name: 'light yellow', value: '#FFFFE0' },
  { name: 'saddle brown', value: '#8B4513' },
  { name: 'sienna', value: '#A0522D' },
  { name: 'chocolate', value: '#D2691E' },
  { name: 'peru', value: '#CD853F' },
  { name: 'sandy brown', value: '#F4A460' },
  { name: 'burly wood', value: '#DEB887' },
  { name: 'tan', value: '#D2B48C' },
  { name: 'rosy brown', value: '#BC8F8F' },
  { name: 'moccasin', value: '#FFE4B5' },
  { name: 'navajo white', value: '#FFDEAD' },
  { name: 'peach puff', value: '#FFDAB9' },
  { name: 'misty rose', value: '#FFE4E1' },
  { name: 'lavender blush', value: '#FFF0F5' },
  { name: 'linen', value: '#FAF0E6' },
  { name: 'old lace', value: '#FDF5E6' },
  { name: 'papaya whip', value: '#FFEFD5' },
  { name: 'sea shell', value: '#FFF5EE' },
  { name: 'mint cream', value: '#F5FFFA' },
  { name: 'slate gray', value: '#708090' },
  { name: 'light slate gray', value: '#778899' },
  { name: 'light steel blue', value: '#B0C4DE' },
  { name: 'lavender', value: '#E6E6FA' },
  { name: 'floral white', value: '#FFFAF0' },
  { name: 'alice blue', value: '#F0F8FF' },
  { name: 'ghost white', value: '#F8F8FF' },
  { name: 'honeydew', value: '#F0FFF0' },
  { name: 'ivory', value: '#FFFFF0' },
  { name: 'azure', value: '#F0FFFF' },
  { name: 'snow', value: '#FFFAFA' }
];
