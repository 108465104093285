import React from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DD_MM_YYYY, htmlToPDF, getCurrentDate } from '../../../utils/functions';

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 'calc(100vw - 35rem)',
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

function ESIStatementForm({ open, handleClose, data }) {
  //   console.log(data);
  const DOB = data?.dob ? data?.dob?.split('T')[0] : '';
  const [YYYY, MM, DD] = DOB.split('-');
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <Stack
          sx={{ p: 2, backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)' }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" sx={{ color: 'white' }}>
            ESI Declaration Form No. - 1
          </Typography>
          <IconButton
            onClick={() => handleClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box sx={{ p: 2, fontSize: 11, overflow: 'auto', height: 'calc(100vh - 3rem)' }}>
          <Box
            id="esi-form-content"
            sx={{
              py: 1,
              px: 1.5,
              border: '1px solid #333',
              borderRadius: 1,
              '& .block-letter': {
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: '#000F55'
              }
            }}
          >
            <Stack sx={{ '& p': { fontSize: 11 } }}>
              <Typography align="center" sx={{ fontWeight: 'bold' }}>
                घोषणा पत्र DECLARATION FORM
              </Typography>
              <Typography variant="subtitles">
                घोषणा पत्र कर्मचारी द्वारा भरा जाएगा । फॉर्म के साथ दो पोस्टकार्ड साइज फोटो भी लगाए
                जाने चाहिए । फॉर्म भरने से पहले पीठ पर दी गई हिदायतो को भली-भांती पढ़ लेना चाहिए ।
                यह फॉर्म निःशुल्क है।
              </Typography>
              <Typography>
                To be filled by employee after reading instructions overleaf. Two Postcard Size
                photographs to be attached with the form. This form is free of cost.
              </Typography>
            </Stack>
            <Grid container sx={{ mt: 1, '& p': { fontSize: 11 } }}>
              <Grid item xs={6}>
                <Stack>
                  <Typography>(क) बीमाकृत व्यक्ति का विवरण</Typography>
                  <Typography>(A) INSURED PERSON'S PARTICULARS</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack>
                  <Typography>(ख) नियोजक के विवरण</Typography>
                  <Typography>(B) EMPLOYERS PARTICULARS</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <TableContainer
                  sx={{
                    mt: 1,
                    '& p,td': {
                      fontSize: 11
                    },
                    '& td': {
                      py: 0,
                      px: 0.5
                    }
                  }}
                  component={Paper}
                >
                  <Table size="small" sx={{ border: '1px solid rgba(241, 243, 244, 1)' }}>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan="3">1. बीमा संख्या / Insurance No.</TableCell>
                        <TableCell colSpan="4">
                          <span className="block-letter">{data?.insurance_no ?? 'xxxxxxxxxx'}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan="3">
                          <Typography>
                            2. नाम ( स्पष्ट अक्षरो मे )
                            <br />
                            Name in block letter
                          </Typography>
                        </TableCell>
                        <TableCell colSpan="4">
                          <span className="block-letter">{data?.name ?? 'xxxxxxxxx'}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan="3">
                          <Typography>
                            3. पिता/पति का नाम
                            <br />
                            Father's/Husband's Name
                          </Typography>
                        </TableCell>
                        <TableCell colSpan="4">
                          <span className="block-letter">{data?.father_name ?? 'xxxxxxxxxx'}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>
                            4. जन्म की तिथि
                            <br />
                            Date of Birth
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            दिन
                            <br />
                            Day
                            <br />
                            <span className="block-letter">{DD ?? 'xx'}</span>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            महीना
                            <br />
                            Month
                            <br />
                            <span className="block-letter">{MM ?? 'xx'}</span>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            वर्ष
                            <br />
                            Year
                            <br />
                            <span className="block-letter">{YYYY ?? 'xxxx'}</span>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            5. वैवाहिक स्थिति
                            <br />
                            Marital Status
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            विवाहित/अविवाहित/विधवा
                            <br />
                            M/U/W{' '}
                            <span className="block-letter">
                              {data?.marital_status ?? 'xxxxxxx'}
                            </span>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan="4" />
                        <TableCell>
                          <Typography>
                            6. लिंग
                            <br />
                            Sex
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            पु.म.
                            <br />
                            M.F. <span className="block-letter">{data?.gender ?? 'xxxxx'}</span>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan="3">
                          <Typography>
                            7. वर्तमन पता / Present Address
                            <br />
                            Pincode
                          </Typography>
                        </TableCell>
                        <TableCell colSpan="3">
                          <Typography>
                            8. स्थायी पता / Permanent Address
                            <br />
                            Pincode
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan="3">
                          <Typography>
                            शाखा कायलय
                            <br />
                            Branch Office
                          </Typography>
                        </TableCell>
                        <TableCell colSpan="3">
                          <Typography>
                            औषधालय
                            <br />
                            Dispensary
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={6}>
                <TableContainer
                  sx={{
                    mt: 1,
                    '& .block-letter': {
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      color: '#000F55'
                    },
                    '& td': {
                      fontSize: 11,
                      p: 0.2
                    }
                  }}
                  component={Paper}
                >
                  <Table size="small" sx={{ border: '1px solid rgba(241, 243, 244, 1)' }}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          9. नियोक्ता कोड संख्या
                          <br />
                          Employer Code No.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          10. नियुक्ति की तारीख
                          <br />
                          Date of Appointment{' '}
                          <span className="block-letter">{DD_MM_YYYY(data?.doj)}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          11. नियोक्ता का नाम और पता/Name and Address of Employer
                          <br />
                          <span className="block-letter">{data?.admin?.name ?? ' xxxxxxxxx'}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          12. यदी पहले नियोजन में रहे हैं तो कृप्या निमनलेखित ब्योरे दीजिये
                          <br />
                          In case of any previous employment please fill up the details as under
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          पिछली बीमा संख्या
                          <br />
                          Previous Ins. No.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          नियोक्ता कोड संख्या
                          <br />
                          Employer's Code No.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          नियोक्ता का नाम और पता
                          <br />
                          Name and Address of Employer
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <br />
                  (C) Details of Nominee u/s 71 of ESI Act 1948/Rule-56(2) of ESI (Central) Rules,
                  1950 for payment of cash benefit in the event of death.
                </Typography>
                <Table sx={{ '& td, th': { fontSize: 11, p: 0 } }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>नाम/Name</TableCell>
                      <TableCell>नातेदारी/Relationship</TableCell>
                      <TableCell>पता/Address</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid sx={{ fontSize: 12 }} item xs={12}>
                <Typography>
                  I hereby declare that the particulars given by me are correct to the best of my
                  knowledge and belief. I undertake to intimate the corporation any changes in the
                  membership of my family within 15 days of such change.
                </Typography>
              </Grid>
            </Grid>
            <Stack
              sx={{ fontSize: 11, '& p': { fontSize: 11 } }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>
                नियोजक के प्रतिहस्ताक्षर
                <br />
                Counter signature by the employer
              </Typography>
              <Typography>
                बिमाकृत व्यक्ति के हस्ताक्षर / अंगूठे का निशान
                <br />
                Signature/T.I. of IP
              </Typography>
            </Stack>
            <Typography sx={{ fontSize: 12 }}>
              सील सहीत हस्ताक्षर <br />
              Signature with Seal
            </Typography>
            <Typography sx={{ fontSize: 11 }}>(D) Family Particulars of Insured person</Typography>
            <br />
            <Table
              sx={{ '& td, th': { fontSize: 11, py: 0.5, px: 0.2, border: '1px solid #333' } }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>
                    Date of Birth / Age as <br />
                    on date of filling form
                  </TableCell>
                  <TableCell>
                    Relationship with <br />
                    the Employee
                  </TableCell>
                  <TableCell colSpan="2">Wheather residing with him/her</TableCell>
                  <TableCell colSpan="2">If 'No' state place of residence</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>हाँ / Yes</TableCell>
                  <TableCell>नहीं / No</TableCell>
                  <TableCell>कस्बा / Town</TableCell>
                  <TableCell>राज्य / State</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <br />
            <Stack
              sx={{ '& p': { fontSize: 12 } }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>
                क.रा.बी निगम अस्थायी पहचान पत्र
                <br />
                ESI Corporation Temporary Identity Card
              </Typography>
              <Typography>
                (नियुक्ति की तारीख से 3 महीने के लिए वैध)
                <br />
                (Valid for 3 month from the date of appointment)
              </Typography>
            </Stack>
            <Grid sx={{ '& th,td': { fontSize: 11, p: 0 } }} container>
              <Grid item xs={8}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        नाम
                        <br />
                        Name <span className="block-letter">{data?.name ?? 'xxxxxxxxxx'}</span>
                      </TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        बीमा संख्या
                        <br />
                        Ins No.
                      </TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>
                        नियुक्ति की तारीख / Date of Appointment{' '}
                        <span className="block-letter">{DD_MM_YYYY(data?.doj)}</span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {' '}
                        शाखा कायलय <br /> Branch Office
                      </TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>
                        औषधालय
                        <br />
                        Dispensary
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        नियोक्ता का कोड नंबर व पता
                        <br />
                        Employer's Code No. & Address
                      </TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      border: '1px solid #333',
                      textAlign: 'center',
                      height: 110,
                      width: 110,
                      fontSize: 11
                    }}
                  >
                    (फ़ोटो के लिए स्थान)
                    <br />
                    (Space for Photograph)
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Typography sx={{ fontSize: 11 }}>
              वैधता
              <br />
              Validity
            </Typography>
            <Stack
              sx={{ '& p': { fontSize: 11 } }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>
                तारिक
                <br />
                Dated
              </Typography>
              <Typography align="center">
                बिमाकृत व्यक्ति के हस्ताक्षर / अंगूठे का निशान
                <br />
                Signature/T.I. of I.P
              </Typography>
              <Typography>
                सील साहित शाखा प्रबंधक के हस्ताक्षर
                <br />
                Signature of B.M. with seal
              </Typography>
            </Stack>
          </Box>
          <br />
          <Box
            id="esi-form-content-2"
            sx={{
              py: 1,
              px: 1.5,
              '& p': { fontSize: 12 },
              border: '1px solid #333',
              borderRadius: 1
            }}
          >
            <Typography sx={{ fontWeight: 'bold', m: 0 }} align="center">
              अनुदेश
              <br />
              INSTRUCTIONS
            </Typography>
            <Table sx={{ '& td': { p: 0, fontSize: 12 } }}>
              <TableBody>
                <TableRow>
                  <TableCell>1.</TableCell>
                  <TableCell>
                    फॉर्म -1 जमा करना ईएसआई (सामान्य) विनियम, 1950 के विनियम 11 और 12 द्वारा शासित
                    है
                    <br />
                    Submition of Form-1 is governed by regulation 11 & 12 of ESI (General)
                    Regulations, 1950
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>2.</TableCell>
                  <TableCell>
                    "परिवार" का अर्थ है बीमित व्यक्ति के सभी या निम्नलिखित में से कोई भी रिश्तेदार
                    अर्थात्:-
                    <br />
                    "Family" means all or any of the following relatives of an Insured Person
                    Namely:-
                    <br />
                    (i) पति या पत्नी (ii) आईपी पर निर्भर नाबालिग वैध या गोद लिया हुआ बच्चा; (iii) एक
                    बच्चा जो पूरी तरह से आईपी की कमाई पर निर्भर है। और कौन है (ए) शिक्षा प्राप्त कर
                    रहा है, जब तक कि वह 21 वर्ष की आयु प्राप्त नहीं कर लेता। (प्रतिबंध अविवाहित
                    बेटी; (iv) एक बच्चा जो किसी शारीरिक या शारीरिक कारणों से दुर्बल है मानसिक
                    असामान्यता या चोट और पूरी तरह से आई.पी. की कमाई पर निर्भर है। जब तक दुर्बलता बनी
                    रहती हैl (v) आश्रित माता-पिता (कृपया धारा 2 . देखें) विवरण के लिए ईएसआई अधिनियम
                    1948 का खंड 11।)
                    <br />
                    (i) a spouse (ii) a minor legitimate or adopted child dependent upon the I.P.;
                    (iii) a child who is wholly dependent on the earnings of the I.P. and who is (a)
                    receiving education, till he or she attains the age of 21 years. (b) an
                    unmarried daughter; (iv) a child who is infirm by reason of any physical or
                    mental abnormality or injury and is wholly dependent on the earnings of the I.P.
                    so long as the infirmity continuesl (v) dependent parents (Please see Section 2
                    clause 11 of the ESI Act 1948 for details.)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>3.</TableCell>
                  <TableCell>
                    पहचान पत्र अहस्तांतरणीय है।
                    <br />
                    Identity Card is Non-transferable.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>4.</TableCell>
                  <TableCell>
                    पहचान पत्र के खो जाने की सूचना तुरंत नियोक्ता/शाखा प्रबंधक को दें।
                    <br />
                    Loss of Identity Card be reported to Employer/Branch Manager immediately.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>5.</TableCell>
                  <TableCell>
                    झूठी सूचना प्रस्तुत करने पर ईएसआई की धारा 84 के तहत दंडात्मक कार्रवाई की जा सकती
                    है अधिनियम 1948।
                    <br />
                    Submission of false information attracts penal action Under Section 84 of ESI
                    Act 1948.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>6.</TableCell>
                  <TableCell>
                    विधिवत भरा हुआ यह फॉर्म 10 दिनों के भीतर संबंधित शाखा कार्यालय में पहुंच जाना
                    चाहिए कर्मचारी की नियुक्ति के संबंध में। देरी करने पर धारा 85 के तहत दंडात्मक
                    कार्रवाई की जा सकती है नियोक्ता के खिलाफ अधिनियम।
                    <br />
                    This form duly filled in must reach the concerned Branch Office within 10 days
                    of appointment of an Employee. Delay attracts penal action under Section 85 of
                    the Act, against employer.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>7.</TableCell>
                  <TableCell>
                    एक बीमित व्यक्ति के रूप में आप और आपके आश्रित परिवार के सदस्य पूर्ण . के हकदार
                    हैं चिकित्सा देखभाल। नकद में अन्य लाभों में शामिल हैं (1) रुग्णता लाभ (2)
                    अस्थायी विकलांगता लाभ (3) स्थायी विकलांगता लाभ (4) आश्रित बेनिफिट और (5)
                    मैटरनिटी बेनिफिट (महिला कर्मचारियों के मामले में) विषय अंशदायी शर्तों की पूर्ति।
                    <br />
                    As an insured person you and your dependent family members are entitled to full
                    medical care. The Other benefits in cash include (1) Sickness Benefit (2)
                    Temporary Disablement benfit (3) Permanent disablement Benefit (4) Dependents
                    benfit and (5) Maternity Benfit (in case of woman employees) subject of
                    fullfillment of contributory condtions.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>8.</TableCell>
                  <TableCell>
                    अधिक जानकारी के लिए कृपया ईएसआईसी की वेबसाइट www.esic.org.in पर संपर्क करें। या
                    संपर्क करें क्षेत्रीय कार्यालय या शाखा कार्यालय।
                    <br />
                    For more details please contact website of ESIC at www.esic.org.in. or contact
                    Regional Office or Branch Office.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <br />
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Box sx={{ border: '1px solid #333', height: 250, width: 500, p: 1 }}>
                <Typography align="center" sx={{ mb: 1 }}>
                  केवल शाखा कार्यालय उपयोग के लिए
                  <br />
                  For Branch Office Use Only
                </Typography>
                <Table sx={{ '& td': { p: 0, fontSize: 12 } }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>1.</TableCell>
                      <TableCell>
                        बीमा संख्या आवंटन की तिथि
                        <br />
                        Date of allotment of Ins. No. :
                      </TableCell>
                      <TableCell>_______________________</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>2.</TableCell>
                      <TableCell>
                        अस्थाई पहचान पत्र जारी करने की तारीख
                        <br />
                        Date of issue of T.I.C. :
                      </TableCell>
                      <TableCell>_______________________</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>3.</TableCell>
                      <TableCell>
                        औषधालय का नाम/संख्या
                        <br />
                        Name/No. of Dispensary :
                      </TableCell>
                      <TableCell>_______________________</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>4.</TableCell>
                      <TableCell colSpan="2">
                        क्या पारस्परिक चिकित्सा व्यवस्था शामिल है। यदि हां, तो कृपया इंगित करें:
                        <br />
                        Whether reciprocal Medical arrangements involved. If yes, please indicate:
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} />
                      <TableCell align="right">
                        शाखा प्रबंधक के हस्ताक्षर
                        <br />
                        Signature of Branch Manager
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Stack>
            <br />
            <Table
              sx={{ '& td, th': { fontSize: 11, py: 0.5, px: 0.2, border: '1px solid #333' } }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>
                    Date of Birth / Age as <br />
                    on date of filling form
                  </TableCell>
                  <TableCell>
                    Relationship with <br />
                    the Employee
                  </TableCell>
                  <TableCell colSpan="2">Wheather residing with him/her</TableCell>
                  <TableCell colSpan="2">If 'No' state place of residence</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>हाँ / Yes</TableCell>
                  <TableCell>नहीं / No</TableCell>
                  <TableCell>कस्बा / Town</TableCell>
                  <TableCell>राज्य / State</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Button
            onClick={() => {
              const _html1 = document.getElementById('esi-form-content');
              const _html2 = document.getElementById('esi-form-content-2');
              html2canvas(_html1).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                // eslint-disable-next-line new-cap
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 5, 5);
                pdf.addPage();
                pdf.setPage(2);
                html2canvas(_html2).then((canvas) => {
                  const imgData = canvas.toDataURL('image/png');
                  pdf.addImage(imgData, 'JPEG', 5, 5);
                  pdf.save(
                    `${`ESI_Form-${data?.name?.replace(' ', '_')}_${getCurrentDate()}`}.pdf`
                  );
                });
              });
              // pdf.output('dataurlnewwindow');
            }}
            sx={{ mt: 2 }}
            variant="contained"
            size="small"
          >
            Download
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default React.memo(ESIStatementForm);
