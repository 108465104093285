import axios from 'axios';
import { baseApiURL } from '../../utils/config';
import { getDataFromStorage } from '../../utils/functions';

export const fetchHeirarchy = async (queryParams) => {
  const [, all, token] = queryParams.queryKey;
  // console.log('Api Called Start');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token ?? getDataFromStorage('adminAuthToken')}`
    },
    params: {
      showAll: all
    }
  };
  // console.log('Api Called');
  const { data } = await axios.get(`${baseApiURL}/common/roles`, config);
  // console.log('roles: ', data);
  // const user = getDataFromStorage('adminInfo');
  // const { heirarchy } = data;
  // only returns roles which are below under current signed in user
  // if (!user?.isMainAdmin && !all) {
  //   const userRoleIndex = heirarchy.findIndex((role) => role._id === user.role._id);
  //   heirarchy.splice(0, userRoleIndex + 1);
  // }
  return data;
};
