/* eslint-disable no-restricted-globals */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { fetchEmployeeTokens } from './api/queries/Queries';
import { updateFirebaseToken } from './api/mutations/adminMutations';
import { setDataInStorage, getDataFromStorage } from './utils/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.TRACKKAR_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// firebase.firestore();

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(firebaseApp);

const fcmServerKey =
  'AAAAsbN6S0Q:APA91bHhHaz4V4yWKwWrdoQNjHR4z_bZW0399Y8XDbOnEn3Py8EJC2YTnUiTenDP5d0k_2EI-axe2JyD6ruHXyva04IjRoKBSpHWm1qE7rPz11E8heA37dRANZB-twYu6GX5i6D_eKfo';

// Add the public key generated from the console here.
// messaging.getToken({ vapidKey: process.env.REACT_APP_VAPID_KEY });

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

export function subscribeTokenToTopic(token, topic) {
  fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `key=${fcmServerKey}`
    })
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        // eslint-disable-next-line no-throw-literal
        throw `Error subscribing to topic: ${response.status} - ${response.text()}`;
      }
      // console.log(`Subscribed to "${topic}"`);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function unsubscribeToTopic(token, topic) {
  fetch(`https://iid.googleapis.com/iid/v1:batchRemove`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `key=${fcmServerKey}`
    }),
    body: JSON.stringify({
      to: `/topics/${topic}`,
      registration_tokens: [token]
    })
  });
}

export const requestForToken = () =>
  getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
    .then(async (currentToken) => {
      if (currentToken) {
        const fcmToken = getDataFromStorage('fcm_token');
        if (fcmToken === undefined) {
          setDataInStorage('fcm_token', currentToken);
        }
        // const info = getDataFromStorage('adminInfo');
        // console.log(currentToken);
        const employeeTokens = await fetchEmployeeTokens();
        // let notificationsTopics;
        // console.log('Employee Tokens:', employeeTokens?.tokens);
        // if (!info?.isMainAdmin) {
        //   notificationsTopics = await fetchNotificationsTopics();
        // }
        // console.log('Notifications Topics:', notificationsTopics?.topics);
        if (!employeeTokens?.tokens?.includes(currentToken)) {
          updateFirebaseToken({
            fcmToken: currentToken
          });
        }
        // const registrationToken = currentToken;
        // if (notificationsTopics?.topics.length > 0) {
        //   setDataInStorage('notification_topics', notificationsTopics?.topics);
        //   notificationsTopics?.topics.forEach((topic) => {
        //     subscribeTokenToTopic(registrationToken, topic);
        //   });
        // }
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      // console.log('An error occurred while retrieving token. ', err);
    });

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export default firebaseApp;
