import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    neutral: {
      main: '#64748B',
      contrastText: '#fff'
    },
    rose: {
      main: '#F31275',
      contrastText: '#fff'
    },
    lpurple: {
      main: '#9B59B6',
      contrastText: '#ff'
    }
  }
});
