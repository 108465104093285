import axios from 'axios';
import { baseApiURL } from '../../utils/config';
// import { getDataFromStorage } from '../../utils/functions';

export const registerAdmin = async (values) => {
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/admin/signup`, values);
    return data;
  }
  throw new Error('No data to send');
};

export const logInAdmin = async (values) => {
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/admin/login`, values);
    return data;
  }
  throw new Error('No data to send');
};

export const logInEmployee = async (values) => {
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/employee/login`, values);
    return data;
  }
  throw new Error('No data to send');
};

export const forgotPassword = async (values) => {
  if (values) {
    const { data } = await axios.post(`${baseApiURL}/forgot-password`, values);
    return data;
  }
  throw new Error('No data to send');
};
