import axios from 'axios';
import { baseApiURL } from '../../utils/config';
import { getDataFromStorage } from '../../utils/functions';

export const getEmployeeProfileById = async (id) => {
  // console.log('Employee ID :', id);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      id
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/employee`, config);
  // console.log('Site By Id',data);
  return data;
};

export const getAdminProfileById = async (id) => {
  // console.log('Admin ID :', id);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      id
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/admin`, config);
  // console.log('Site By Id',data);
  return data;
};

export const getEmployeeAttendanceHistoryById = async (queryParams) => {
  const [, from, to, id] = queryParams.queryKey;
  // console.log('Employee ID :', id);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    },
    params: {
      id,
      from,
      to,
      next: queryParams.pageParam
    }
  };
  const { data } = await axios.get(`${baseApiURL}/common/employee-attendance`, config);
  // console.log('Employee Attendance History By Id', data);
  return data;
};
