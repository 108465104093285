/* eslint-disable no-nested-ternary */
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchMonthAvailability } from '../api/queries/Queries';

export const useMonthAvailability = (site, date, progress, token) => {
  const dispatch = useDispatch();
  return useQuery(['roster-availability', site, date, progress, token], fetchMonthAvailability, {
    enabled: !!site,
    // getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};
