import '../../index.css';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar, Stack } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import PaymentIcon from '@mui/icons-material/Payment';
// components
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import { ACCESS_CODES } from '../../utils/config';
import sidebarConfig from './SidebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { info } = useSelector((state) => state.adminInfo);
  const HR_ACCESS = info?.isMainAdmin
    ? info?.module_access?.includes(ACCESS_CODES.hr)
    : info?.admin?.module_access?.includes(ACCESS_CODES.hr);

  // const user = useContext(UserContext);

  // useEffect(() => {
  //   console.log(user);
  // }, [user]);
  if (info?.role?.priority < info?.site_manager_priority - 1 || info?.isMainAdmin) {
    const idx = sidebarConfig?.admin?.find((sc) => sc.title === 'Attendance');
    if (!idx) {
      sidebarConfig.admin.push({
        title: 'Attendance',
        icon: <PeopleIcon />,
        path: '/dashboard/attendance'
      });
    }
  }
  if (info?.role?.priority < info?.site_manager_priority || info?.isMainAdmin) {
    const inx = sidebarConfig?.admin?.find((sc) => sc.title === 'Sites');
    if (!inx?.children.find((st) => st.title === 'Un-Assigned')) {
      inx?.children?.push({
        title: 'Un-Assigned',
        path: '/dashboard/sites/unassigned'
      });
    }
  }

  if (
    (info?.role?.hr || info?.isMainAdmin) &&
    info?.module_access?.includes(ACCESS_CODES.payroll)
  ) {
    const idx = sidebarConfig?.admin?.find((sc) => sc.title === 'Payroll');
    if (!idx) {
      sidebarConfig.admin.push({
        title: 'Payroll',
        path: '/payroll',
        icon: <PaymentIcon />
      });
    }
  }

  if (HR_ACCESS) {
    const idx = sidebarConfig?.admin[3].children?.find((sc) => sc.title === 'Resumes');
    if (!idx) {
      sidebarConfig.admin[3].children.push({
        title: 'Resumes',
        path: '/dashboard/resumes'
      });
    }
  }

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Stack className="custom_scrollbar">
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo size="large" image={info?.avatar} />
        </Box>
      </Box>

      {/* <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={info?.avatar} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {info?.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {info?.role?.name}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box> */}

      <NavSection
        navConfig={
          info?.isMainAdmin || info?.role?.priority <= info?.site_manager_priority
            ? sidebarConfig.admin
            : sidebarConfig.employee
        }
      />

      <Box sx={{ flexGrow: 1 }} />
    </Stack>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
