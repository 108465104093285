import React, { useMemo, useState } from 'react';
import _ from 'lodash';
// import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
// import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// import Popover from '@mui/material/Popover';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// components
import Page from '../components/Page';
import HierarchyTree from './HeirarchyTree';
import { addSubrole, deleteSubrole } from '../api/mutations/adminMutations';
import { useRoles } from '../hooks/roles';
// utils
import { SWAL_TIME } from '../utils/config';
import { uid } from '../utils/functions';
// redux
import { logOut } from '../redux/actions/adminActions';

export default function HeirarchyProfile() {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [subrole, setSubrole] = useState();
  const [smLevel, setSMLevel] = useState();
  const {
    // isAdminAuthenticated,
    info
  } = useSelector((state) => state.adminInfo);

  function getNodeFromTree(node, nodeId) {
    if (node.nodeId === nodeId) {
      return node;
    }
    if (node.child != null) {
      let result = null;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; result == null && i < node.child.length; i++) {
        result = getNodeFromTree(node.child[i], nodeId);
      }
      return result;
    }
    return null;
  }

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: _roles } = useRoles(true); // gettings roles

  const allRoles = useMemo(() => {
    const hdata = [];
    if (!isRolesLoading && !isRolesError) {
      // console.log(_roles.heirarchy);
      const result = _.groupBy(_roles.heirarchy, (role) => role.priority);
      // const result = _.groupBy(_Heirarchy, (role) => role.priority);
      // Object.keys(result).map((p) => result[p].children.push()
      // console.log(result);
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= Object.keys(result).length; i++) {
        // console.log(hdata);
        result[i].forEach((r) => {
          if (r.is_site_manager) {
            setSMLevel(i - 1);
          }
          if (r.parents.length > 0) {
            const parents = r.parents.map((pr) => pr._id);
            // console.log(i, parents);
            const pIndex = hdata.findIndex((hh) => parents.includes(hh.nodeId));
            // console.log(pIndex);
            const par = getNodeFromTree(hdata[pIndex], parents[parents.length - 1]);
            // console.log(`Par ${1}`, par);
            par?.child?.push({
              nodeId: r._id,
              pts: r.parents,
              parents: [
                {
                  nodeId: uid(),
                  name: r.name,
                  pname: 'Enter Name',
                  siteManager: r.is_site_manager,
                  tracking: r.tracking
                }
              ],
              subroles: r.subroles.map((sb) => ({
                nodeId: sb._id,
                name: sb.name
              })),
              child: []
            });
          } else {
            hdata.push({
              nodeId: r._id,
              pts: r.parents,
              parents: [
                {
                  nodeId: uid(),
                  name: r.name,
                  pname: 'Enter Name',
                  siteManager: r.is_site_manager,
                  tracking: r.tracking
                }
              ],
              subroles: r.subroles,
              child: []
            });
          }
        });
      }
      // console.log('Modified Data:', hdata);
      return hdata;
    }
    return []; // Needs to be changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRolesLoading, isRolesError, _roles]);

  /* ================= End Roles =============== */

  const addSubroleMutation = useMutation(addSubrole, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Subrole Added Successfully`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('heirarchy');
      cache.invalidateQueries('subroles');
    }
  });

  const deleteSubroleMutation = useMutation(deleteSubrole, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Subrole deleted Successfully`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('heirarchy');
      cache.invalidateQueries('subroles');
    }
  });

  return (
    <Page title="Heirarchy | Trackkar">
      <Container>
        <Card sx={{ pb: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignContent="center"
            sx={{ color: 'white' }}
            style={{ backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)' }}
          >
            <Typography sx={{ py: 1, px: 2, fontSize: 'bold', color: 'white' }}>
              Heirarchy
            </Typography>
          </Stack>
          {allRoles.length > 0 && (
            <Stack sx={{ width: '1000px', overflowX: 'scroll' }}>
              <HierarchyTree hData={allRoles} smLevel={smLevel} profile />
            </Stack>
          )}
          {/* <Stack sx={{ px: 2, py: 1 }} direction="row" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip style={{ width: 15, height: 15, backgroundColor: theme.palette.role.main }} />
              <Typography>Role</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip
                style={{ width: 15, height: 15, backgroundColor: theme.palette.subrole.main }}
              />
              <Typography>Subrole</Typography>
            </Stack>
          </Stack>
          <Stack
            className="custom_scrollbar"
            sx={{ p: 2, overflow: 'auto', height: 'calc(100vh - 11rem)' }}
          >
            <ul className="tree">
              <li>
                <a>{info?.admin?.name ?? info.name}</a>
                {!isRolesLoading &&
                  Object.keys(allRoles).map((p) => (
                    <ul key={p}>
                      {allRoles[p].map((role, index) => (
                        <li key={`${p}${index}`}>
                          <a
                            style={{
                              boxShadow: theme.customShadows.bloated,
                              border: `1px solid ${theme.palette.role.main}`,
                              color: theme.palette.role.main
                            }}
                          >
                            {role.name}
                            {info.isMainAdmin && (
                              <AddCircleIcon
                                onClick={(e) =>
                                  setAnchorEl({
                                    ...anchorEl,
                                    id: `add_subrole_${role._id}`,
                                    target: e.currentTarget
                                  })
                                }
                                sx={{
                                  fontSize: 16,
                                  cursor: 'pointer',
                                  color: '#5DADE2',
                                  mt: 0.9,
                                  ml: 2
                                }}
                              />
                            )}
                            <Popover
                              id={`add_subrole_${role._id}`}
                              open={anchorEl.id === `add_subrole_${role._id}`}
                              anchorEl={anchorEl.target}
                              onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                              }}
                            >
                              <Stack sx={{ p: 2 }}>
                                <TextField
                                  sx={{ mb: 2 }}
                                  variant="standard"
                                  value={subrole}
                                  placeholder="Add Subrole"
                                  onChange={(e) => setSubrole(capitalizeEachWord(e.target.value))}
                                />
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    addSubroleMutation.mutate({
                                      role: role._id,
                                      subroles: [subrole]
                                    })
                                  }
                                >
                                  Add
                                </Button>
                              </Stack>
                            </Popover>
                          </a>
                          {role.subroles.length > 0 && (
                            <ul>
                              {role.subroles.map((sb) => (
                                <li key={sb._id}>
                                  <a
                                    style={{
                                      color: theme.palette.subrole.main,
                                      border: `1px solid ${theme.palette.subrole.main}`,
                                      boxShadow: theme.customShadows.bloated
                                    }}
                                  >
                                    {sb.name}
                                    {info?.isMainAdmin && (
                                      <RemoveCircleIcon
                                        onClick={() =>
                                          Swal.fire({
                                            title: `Are you sure ?`,
                                            text: `${sb.name} will be Removed?`,
                                            icon: 'question',
                                            showCancelButton: true,
                                            confirmButtonText: 'Yes, Proceed!',
                                            allowOutsideClick: false
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              deleteSubroleMutation.mutate({
                                                subroles: [sb._id]
                                              });
                                            } else if (
                                              result.dismiss === Swal.DismissReason.cancel
                                            ) {
                                              cache.invalidateQueries('roster');
                                            }
                                          })
                                        }
                                        sx={{
                                          fontSize: 16,
                                          cursor: 'pointer',
                                          color: '#E74C3C',
                                          mt: 0.5,
                                          ml: 2
                                        }}
                                      />
                                    )}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  ))}
              </li>
            </ul>
          </Stack> */}
        </Card>
      </Container>
    </Page>
  );
}
