import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { getEmployeeAttendanceHistoryById } from '../api/queries/profile';

export const useEmployeeAttendanceHistory = (from, to, id) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(
    ['employee-attendence-history', from, to, id],
    getEmployeeAttendanceHistoryById,
    {
      enabled: !!id,
      getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );
};
