/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
// import { filter } from 'lodash';
import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik, FormikProvider, Form } from 'formik';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce } from 'use-debounce';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import { LoadingButton } from '@mui/lab';
// icons
// import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
// import { SiteFeedbackListHead } from '../components/_dashboard/site_feedback';
import NotAvailable from '../components/NotAvailable';
import { PayrollListToolbar } from '../components/_dashboard/payroll';
import usePayrollTemplates from '../hooks/get-payroll-templates';
import classes from './styles/ImportEmployees.module.css';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import PFStatementForm from '../components/_dashboard/payroll/PFStatementForm';
import ESIStatementForm from '../components/_dashboard/payroll/ESIStatementForm';
// hooks
import { usePayroll } from '../hooks/get-payroll';
import { useAllowances } from '../hooks/allowances';
import { useDeductions } from '../hooks/deductions';
import {
  assignPayroll,
  generatePaySlip,
  addLoan,
  runPayroll
} from '../api/mutations/adminMutations';
// utils
import {
  convertUTCtoLocal,
  getCurrentDate,
  calculateLoanInstallment,
  uid,
  setDataInStorage
} from '../utils/functions';
import { SWAL_TIME, SEARCH_DEBOUNCE } from '../utils/config';
// redux
import { logOut } from '../redux/actions/adminActions';

// ---------------------------------------------------------------
const TABLE_HEAD = [];
const BASIC_TABLE_HEAD = [
  { id: '#', label: '#', alignRight: false },
  { id: 'username', label: 'UID', alignRight: false },
  { id: 'name', label: 'Employee', alignRight: false },
  { id: 'department', label: 'Department', alignRight: false },
  { id: 'tag', label: 'Tag', alignRight: false },
  { id: 'gender', label: 'Gender', alignRight: false },
  { id: 'working_days', label: 'Working Days', alignRight: false },
  { id: 'allowed_leaves', label: 'Allowed Leaves', alignRight: false },
  { id: 'leave_taken', label: 'Leave Taken', alignRight: false },
  { id: 'days_payable', label: 'Days Payable', alignRight: false },
  { id: 'ctc', label: 'CTC', alignRight: false },
  { id: 'basic', label: 'Basic', alignRight: false },
  { id: 'hra', label: 'HRA', alignRight: false }
];

const BANK_TABLE_HEAD = [
  { id: 'net_salary', label: 'Net Salary', alignRight: false },
  { id: 'bank_name', label: 'Bank Name', alignRight: false },
  { id: 'branch', label: 'Branch', alignRight: false },
  { id: 'bank_ifsc_code', label: 'Bank IFSC Code', alignRight: false },
  { id: 'account', label: 'Account', alignRight: false },
  { id: 'payroll_settings', label: 'Payroll Settings', alignRight: false },
  { id: 'payslip', label: 'Payslip', alignRight: false }
];

const TABLE_FIRST_HEADER = [];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  //   border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 2,
  p: 4
};

// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// --------------------------------------------------------------

function PayrollOverview() {
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cache = useQueryClient();
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [search] = useDebounce(filterName, SEARCH_DEBOUNCE); // Debounce value 1.2 sec
  // const [viewPaySlip, setViewPaySlip] = useState({});
  // const [changeMonth, setChangeMonth] = useState();
  const [createLoan, setCreateLoan] = useState(null);
  const [createPFForm, setCreatePFForm] = useState(null);
  const [createESIForm, setCreateESIForm] = useState(null);
  const [templateOptions, setTemplateOptions] = useState([]);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [anchorEl, setAnchorEl] = useState({
  //   id: '',
  //   target: ''
  // });
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    site: '',
    employee: '',
    date: new Date()
  });

  const { isSuccess: isAllowancesSuccess, data: _allowances } = useAllowances();
  const { isSuccess: isDeductionsSuccess, data: _deductions } = useDeductions();
  const AVAILABLE_ALLOWANCES = useMemo(() => {
    // console.log(_allowances);
    if (isAllowancesSuccess) {
      return _allowances.allowances;
    }
    return [];
  }, [_allowances, isAllowancesSuccess]);
  const AVAILABLE_DEDUCTIONS = useMemo(() => {
    // console.log(_deductions);
    if (isDeductionsSuccess) {
      return _deductions.deductions;
    }
    return [];
  }, [_deductions, isDeductionsSuccess]);

  const {
    isLoading: isPayrollLoading,
    isError: isPayrollError,
    hasNextPage: hasNextPayroll,
    fetchNextPage: fetchNextPayroll,
    data: payroll
  } = usePayroll(convertUTCtoLocal(filter?.date, true), search);

  const rows = useMemo(() => {
    const listOfPayrolls = [];
    if (!isPayrollLoading && !isPayrollError) {
      if (payroll?.pages?.length > 0) {
        payroll.pages.forEach((group) => {
          listOfPayrolls.push(group.employees);
        });
      }
    }
    const flattened = listOfPayrolls.flat();
    return flattened;
  }, [payroll, isPayrollLoading, isPayrollError]);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const cellStyle = {
    whiteSpace: 'nowrap'
    // p: 0
  };

  // Payroll Components
  const {
    isLoading: isSalaryTemplateLoading,
    isError: isSalaryTemplateError,
    // hasNextPage: hasNextFeedbacks,
    // fetchNextPage: fetchNextFeedbacks,
    data: salaryTemplate
  } = usePayrollTemplates();

  const payrollTemplates = useMemo(() => {
    const listOfSalaryTemplate = [];
    if (!isSalaryTemplateLoading && !isSalaryTemplateError) {
      if (salaryTemplate?.pages?.length > 0) {
        salaryTemplate.pages.forEach((group) => {
          listOfSalaryTemplate.push(group.templates);
        });
      }
    }
    const flattened = listOfSalaryTemplate.flat();
    return flattened;
  }, [salaryTemplate, isSalaryTemplateLoading, isSalaryTemplateError]);

  React.useEffect(() => {
    const formattedTemplates = [];
    if (payrollTemplates?.length > 0) {
      payrollTemplates.forEach((row) => {
        formattedTemplates.push([row._id, row.name]);
      });
      setTemplateOptions([...formattedTemplates]);
    }
  }, [payrollTemplates]);

  // Assign Payroll Mutation
  /* ================= Assign Policy Mutation ============== */
  const assignPayrollMutation = useMutation(assignPayroll, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully assigned payroll`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('payroll');
    }
  });

  /* ================= Generate PaySlip Mutation ============== */
  const generatePaySlipMutation = useMutation(generatePaySlip, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Generating Payslip 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `PaySlip generated successfully!`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('payslips');
    }
  });

  /* ================= Generate PaySlip Mutation ============== */
  const runPayrollMutation = useMutation(runPayroll, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Executing Payroll 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: (data) => {
      const errors = data.errors.map((r) => r?.value);
      if (errors?.length > 0) {
        Swal.fire({
          title: `Payroll Execution Completed!`,
          allowOutsideClick: false,
          showConfirmButton: true,
          html: `<p>${data?.message}</p>
              <h5 style="margin:0.5rem;">Following issues were encountered while executing payroll</h5>
              <ul class=${classes.import__err_ul}>
                ${errors
                  .map(
                    (resErr, index) =>
                      `<li class=${classes.import__err_li}>
                      <span>${(index += 1)}.&nbsp;</span>
                      <span class=${classes.import__err_msg}>
                        ${resErr}
                      </span>
                    </li>`
                  )
                  .join('')}
              </ul>`
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: `Payroll Execution Completed!`,
          allowOutsideClick: false,
          showConfirmButton: true,
          text: data?.message
        });
      }
    },
    onSettled: () => {
      cache.invalidateQueries('payslips');
    }
  });

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const filteredUsers = rows;
  const isUserNotFound = filteredUsers.length === 0;

  const ALLOWANCE_TABLE_HEAD = AVAILABLE_ALLOWANCES.map((alw) => ({
    id: alw._id,
    label: alw.name
  }));
  const DEDUCTION_TABLE_HEAD = AVAILABLE_DEDUCTIONS.map((duc) => ({
    id: duc._id,
    label: duc.name
  }));

  const { mutate } = useMutation(addLoan);

  const loanFormik = useFormik({
    initialValues: {
      amount: '',
      tenure: '',
      interest: ''
    },
    validationSchema: Yup.object({
      amount: Yup.string().required('Amount is required'),
      tenure: Yup.string().required('Tenure is required')
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      const data = {
        employee: createLoan?._id,
        amount: Number(values.amount),
        tenure: Number(values.tenure),
        interest: Number(values.interest),
        today: getCurrentDate()
      };

      console.log('Loan Add Form :', data);

      mutate(data, {
        onError: (error) => {
          setCreateLoan(null);
          // for unauthorized access
          if (error.response && error.response.status === 401) {
            dispatch(logOut());
          }

          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();

          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: msg,
            showConfirmButton: true
            // timer: 2000,
            // timerProgressBar: true
          });

          actions.setStatus({
            errorResponse: msg
          });
          actions.setSubmitting(false);
        },
        onSuccess: (data) => {
          console.log(data);
          actions.setSubmitting(false);
          setCreateLoan(null);
          resetLoanForm();
          Swal.fire({
            icon: 'success',
            title: 'Employee Loan added!',
            text: data.message,
            showConfirmButton: true
            // timer: SWAL_TIME,
            // timerProgressBar: true
          });
        },
        onSettled: () => {
          cache.invalidateQueries('payroll');
        }
      });
    }
  });

  const {
    getFieldProps: getLoanFieldProps,
    touched: loanTouched,
    errors: loanErrors,
    resetForm: resetLoanForm,
    isSubmitting
  } = loanFormik;

  return (
    <Page title="Payroll Overview | Trackkar">
      <Stack direction="row" sx={{ px: 2 }} alignItems="center" justifyContent="space-between">
        <PayrollListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          setFilters={setFilter}
        />
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="center">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiDatePicker
              views={['year', 'month']}
              label="Year and Month"
              minDate={new Date('2012-03-01')}
              disabled
              // maxDate={new Date('2023-06-01')}
              value={filter.date}
              onChange={(newValue) => {
                setFilter({ ...filter, date: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ '& input': { padding: '9.5px 14px' } }}
                  {...params}
                  helperText={null}
                />
              )}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            onClick={() =>
              Swal.fire({
                title: 'Are you sure?',
                text: `Want to run Payroll`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, Proceed!',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                  runPayrollMutation.mutate({
                    payrollRunId: uid(),
                    today: getCurrentDate()
                  });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  cache.invalidateQueries('payslips');
                }
              })
            }
          >
            Run Payroll
          </Button>
        </Stack>
      </Stack>
      <TableContainer
        id="payroll-table-container"
        sx={{ minWidth: 800, height: 'calc(100vh - 160px)' }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {BASIC_TABLE_HEAD.map((hd) => (
                <TableCell sx={cellStyle} key={hd.id}>
                  {hd.label}
                </TableCell>
              ))}
              {ALLOWANCE_TABLE_HEAD.map((hd) => (
                <TableCell sx={cellStyle} key={hd.id}>
                  {hd.label}
                </TableCell>
              ))}
              <TableCell sx={cellStyle}>Special</TableCell>
              <TableCell sx={cellStyle}>Total Allowances</TableCell>
              <TableCell sx={cellStyle}>Gross Salary</TableCell>
              {DEDUCTION_TABLE_HEAD.map((hd) => (
                <TableCell sx={cellStyle} key={hd.id}>
                  {hd.label}
                </TableCell>
              ))}
              <TableCell sx={cellStyle}>Professional Tax</TableCell>
              <TableCell sx={cellStyle}>Loan</TableCell>
              <TableCell sx={cellStyle}>EPF Employee</TableCell>
              <TableCell sx={cellStyle}>Total Deductions</TableCell>
              <TableCell sx={cellStyle}>Bonus</TableCell>
              {BANK_TABLE_HEAD.map((hd) => (
                <TableCell sx={cellStyle} key={hd.id}>
                  {hd.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isPayrollLoading ? (
              <TableCell colSpan="10">
                <NotAvailable type="loading" />
              </TableCell>
            ) : isPayrollError ? (
              <TableCell colSpan="10">
                <NotAvailable type="na" msg="Something Went Wrong!" />
              </TableCell>
            ) : rows?.length > 0 ? (
              filteredUsers.map((row, index) => {
                const isItemSelected = selected.indexOf(row.name) !== -1;
                // const totalBonuses = 0;
                // const defaultTemplate = row?.payroll_templates.filter(
                //   (tep) => tep?.role === row?.role?._id && tep.default === true
                // )[0];
                // const basicSalary = row?.payout_amount
                //   ? Number(
                //       parseFloat(
                //         (row?.payout_amount *
                //           (Number(
                //             row?.payroll?.basic_salary_percentage ??
                //               defaultTemplate?.basic_salary_percentage
                //           ) /
                //             100)) /
                //           12
                //       ).toFixed(0)
                //     )
                //   : 0;
                // const HRA = row?.payout_amount
                //   ? Number(
                //       parseFloat(
                //         (row?.payout_amount *
                //           (Number(row?.payroll?.hra_percentage ?? defaultTemplate?.hra_percentage) /
                //             100)) /
                //           12
                //       ).toFixed(0)
                //     )
                //   : 0;
                // let totalAllowances = HRA;
                // const LOAN =
                //   row?.loan.length > 0
                //     ? calculateLoanInstallment(
                //         row.loan[0].amount,
                //         row.loan[0].tenure,
                //         row.loan[0].interest
                //       )
                //     : 0;
                // let totalDeductions = LOAN;
                // const NET_SALARY = basicSalary + totalAllowances - totalDeductions;
                return (
                  <TableRow
                    hover
                    key={row._id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    {/* <TableCell sx={cellStyle} padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, row.name)}
                          />
                        </TableCell> */}
                    <TableCell sx={cellStyle}>{(index += 1)}</TableCell>
                    <TableCell>
                      <Typography noWrap variant="subtitle2">
                        {row?.username ?? 'XXXXXXXXX'}
                      </Typography>
                    </TableCell>
                    <TableCell sx={cellStyle} align="left">
                      <Stack
                        onClick={() => {
                          setDataInStorage('selected_employee', row);
                          navigate('/employee/profile');
                        }}
                        sx={{ cursor: 'pointer' }}
                        direction="row"
                        alignItems="center"
                        spacing={1}
                      >
                        <Avatar sx={{ width: 26, height: 26 }} alt={row.name} src={row.avatar} />
                        <Box>
                          <Typography variant="subtitle2" noWrap>
                            {row.name}
                          </Typography>
                          <Typography sx={{ color: '#3366FF', fontSize: 11 }}>
                            <b>{row.role.name}</b>&emsp;
                            {row?.subrole ? (
                              <Typography component="span" sx={{ color: '#DC7633', fontSize: 11 }}>
                                <b>{row.subrole.name}</b>
                              </Typography>
                            ) : null}
                          </Typography>
                        </Box>
                      </Stack>
                    </TableCell>
                    <TableCell sx={cellStyle}>{row?.department?.name ?? '-'}</TableCell>
                    <TableCell sx={cellStyle}>{row.tag ?? '-'}</TableCell>
                    <TableCell sx={cellStyle}>{row.gender}</TableCell>
                    <TableCell sx={cellStyle}>{row?.working_days[0]?.days ?? 0}</TableCell>
                    <TableCell sx={cellStyle}>{row?.policy?.total_leaves ?? 0}</TableCell>
                    <TableCell sx={cellStyle}>
                      {row?.leave_taken?.length > 0 ? row?.leave_taken[0]?.leave : 0}
                    </TableCell>
                    <TableCell sx={cellStyle}>{row?.days_payable[0]?.days ?? 0}</TableCell>
                    <TableCell sx={cellStyle}>{row?.payout_amount ?? 'XXXXXXXXX'}</TableCell>
                    <TableCell sx={cellStyle}>{row?.basic_salary}</TableCell>
                    <TableCell sx={cellStyle}>{row?.hra}</TableCell>
                    {/* {console.log(row.allowances)} */}
                    {ALLOWANCE_TABLE_HEAD.map((hd) => {
                      const allowance = row.allowances.find((alw) => alw._id === hd.id);
                      if (allowance) {
                        return (
                          <TableCell key={`${row._id}-${allowance._id}`}>{allowance.amt}</TableCell>
                        );
                      }
                      return (
                        <TableCell sx={cellStyle} key={`${row._id}-${hd.id}`}>
                          -
                        </TableCell>
                      );
                    })}
                    <TableCell>{row?.special_allowance}</TableCell>
                    <TableCell sx={cellStyle}>{row?.total_allowances}</TableCell>
                    <TableCell sx={cellStyle}>{row?.gross_salary}</TableCell>
                    {DEDUCTION_TABLE_HEAD.map((hd) => {
                      const deduction = row.deductions.find((alw) => alw._id === hd.id);
                      if (deduction) {
                        return (
                          <TableCell key={`${row._id}-${deduction._id}`}>
                            {parseFloat(deduction?.amt)?.toFixed(0) ?? 0}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell sx={cellStyle} key={`${row._id}-${hd.id}`}>
                          -
                        </TableCell>
                      );
                    })}
                    <TableCell>{row?.professional_tax}</TableCell>
                    {row?.loan_amt ? (
                      <TableCell>{row?.loan_amt}</TableCell>
                    ) : (
                      <TableCell>
                        <Button onClick={() => setCreateLoan(row)} startIcon={<AddIcon />}>
                          Loan
                        </Button>
                      </TableCell>
                    )}
                    <TableCell sx={cellStyle}>{row?.provident_fund}</TableCell>
                    <TableCell sx={cellStyle}>{row?.total_deductions}</TableCell>
                    <TableCell sx={cellStyle}>{row?.total_bonuses}</TableCell>
                    <TableCell sx={cellStyle}>{row?.net_salary}</TableCell>
                    <TableCell sx={cellStyle}>{row?.bank_details?.bank_name ?? '-'}</TableCell>
                    <TableCell sx={cellStyle}>{row?.bank_details?.bank_branch ?? '-'}</TableCell>
                    <TableCell sx={cellStyle}>{row?.bank_details?.bank_ifsc_code ?? '-'}</TableCell>
                    <TableCell sx={cellStyle}>
                      {row?.bank_details?.bank_account_no ?? '-'}
                    </TableCell>
                    {/* <TableCell sx={cellStyle} align="left">
                    {row.working_days}
                  </TableCell>
                  <TableCell sx={cellStyle} align="left">
                    {row.days_attended}
                  </TableCell>
                  <TableCell sx={cellStyle} align="left">
                    {Object.entries(row?.leaves_taken ?? {}).map(([key, value]) => (
                      <>
                        <Typography noWrap sx={{ fontWeight: 'bold', fontSize: 14 }}>
                          {key}-{Number(key.toString().substring(2, key.length)) + 1}
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={1} key={key}>
                          <Chip
                            size="small"
                            variant="outlined"
                            color="primary"
                            avatar={<Avatar>{value?.sick}</Avatar>}
                            label={`Sick ( ${yearLeave?.sick} )`}
                          />
                          <Chip
                            size="small"
                            variant="outlined"
                            color="secondary"
                            avatar={<Avatar>{value?.casual}</Avatar>}
                            label={`Casual ( ${yearLeave?.casual} )`}
                          />
                        </Stack>
                      </>
                    ))}
                  </TableCell> */}
                    {/* <TableCell sx={cellStyle} align="left">
                    {row.bonus}
                  </TableCell>
                  <TableCell sx={cellStyle} align="left">
                    {row.reimbursement}
                  </TableCell>
                  <TableCell sx={cellStyle} align="left">
                    ₹ {row?.payroll?.basic_salary}
                  </TableCell> */}
                    <TableCell>
                      <FormControl sx={{ minWidth: 180 }}>
                        <InputLabel variant="standard" htmlFor="template_payroll">
                          Choose Template
                        </InputLabel>
                        <Select
                          defaultValue={row?.payroll?._id ?? row?.default_template?._id ?? ''}
                          variant="standard"
                          inputProps={{
                            name: 'template_payroll',
                            id: 'template_payroll'
                          }}
                          sx={{ mt: 2 }}
                          onChange={(event) => {
                            assignPayrollMutation.mutate({
                              employee: row._id,
                              today: getCurrentDate(),
                              payroll: event.target.value
                            });
                          }}
                        >
                          <MenuItem value="" disabled>
                            Choose Template
                          </MenuItem>
                          {row?.payroll_templates?.length > 0 ? (
                            row?.payroll_templates.map((temp) => (
                              <MenuItem
                                style={{ padding: '0.5rem' }}
                                key={`${'payroll-template'}-${temp._id}`}
                                value={temp._id}
                              >
                                {temp.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="" disabled />
                          )}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell sx={cellStyle} align="left">
                      <Button
                        onClick={() =>
                          Swal.fire({
                            title: 'Are you sure?',
                            text: `Want to generate payslip`,
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Yes, Proceed!',
                            allowOutsideClick: false
                          }).then((result) => {
                            if (result.isConfirmed) {
                              generatePaySlipMutation.mutate({
                                employee: row._id,
                                today: getCurrentDate()
                              });
                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                              cache.invalidateQueries('payslips');
                            }
                          })
                        }
                      >
                        Generate
                      </Button>
                    </TableCell>
                    <TableCell sx={cellStyle} align="left">
                      <Button onClick={() => setCreatePFForm(row)}>PF Statement</Button>
                    </TableCell>
                    <TableCell sx={cellStyle} align="left">
                      <Button onClick={() => setCreateESIForm(row)}>ESI Statement</Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NotAvailable type="na" msg="No Employees Available for payroll" />
            )}
          </TableBody>
        </Table>
        {/* Put the scroll bar always on the bottom */}
        <InfiniteScroll
          dataLength={rows?.length}
          next={fetchNextPayroll}
          // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
          // inverse //
          hasMore={hasNextPayroll}
          loader={
            <Stack direction="row" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Stack>
          }
          scrollableTarget="payroll-table-container"
          // endMessage={
          //   <p style={{ textAlign: 'center' }}>
          //     <b>Yay! You have seen it all</b>
          //   </p>
          // }
        />
      </TableContainer>
      <Modal
        keepMounted
        open={createLoan !== null}
        onClose={() => setCreateLoan(null)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <FormikProvider value={loanFormik}>
            <Form noValidate onSubmit={loanFormik.handleSubmit}>
              <Stack spacing={2}>
                <Typography>Name&nbsp;: {createLoan?.name}</Typography>
                <Typography>UID&nbsp;: {createLoan?.username}</Typography>
                <TextField
                  variant="standard"
                  type="text"
                  {...getLoanFieldProps('amount')}
                  helperText={(loanTouched.amount && loanErrors.amount) ?? 'Loan Amount'}
                />
                <TextField
                  type="text"
                  variant="standard"
                  {...getLoanFieldProps('tenure')}
                  sx={{ width: '100%' }}
                  helperText={(loanTouched.tenure && loanErrors.tenure) ?? 'Tenure in months'}
                />
                <TextField
                  type="text"
                  variant="standard"
                  {...getLoanFieldProps('interest')}
                  sx={{ width: '100%' }}
                  helperText={
                    (loanTouched.interest && loanErrors.interest) ?? 'Rate of interest on Loan'
                  }
                />
                <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
      <PFStatementForm
        data={createPFForm}
        open={createPFForm !== null}
        handleClose={() => setCreatePFForm(null)}
      />
      <ESIStatementForm
        data={createESIForm}
        open={createESIForm !== null}
        handleClose={() => setCreateESIForm(null)}
      />
    </Page>
  );
}

export default PayrollOverview;
