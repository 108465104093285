import { useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import { Link as RouterLink } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { deletePayrollTemplate } from '../../../api/mutations/adminMutations';
import { SWAL_TIME } from '../../../utils/config';
import { logOut } from '../../../redux/actions/adminActions';

// ----------------------------------------------------------------------

export default function PayrollTemplateMoreMenu({ template, setEditTemplate }) {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  /* ================= Generate PaySlip Mutation ============== */
  const deleteTemplateMutation = useMutation(deletePayrollTemplate, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Deleting Template 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Template deleted successfully!`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('payroll-templates');
      cache.invalidateQueries('payroll');
    }
  });

  return (
    <>
      <IconButton sx={{ color: 'white' }} ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            Swal.fire({
              title: 'Are you sure?',
              text: `Template Will be Deleted`,
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Yes, Proceed!',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                deleteTemplateMutation.mutate({
                  id: template._id
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                cache.invalidateQueries('payroll-templates');
              }
            });
          }}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          onClick={() => setEditTemplate(template)}
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
