/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import RosterLayout from './layouts/roster';
import ProfileLayout from './layouts/profile';
import SiteLayout from './layouts/site';
import EmployeeLayout from './layouts/employee';
import PayrollLayout from './layouts/payroll';
import AttendanceLayout from './layouts/attendance';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Payroll from './pages/Payroll';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import EDashboard from './pages/EDashboard';
import Map from './pages/Map';
import Stepper from './pages/Stepper';
import Loan from './pages/Loan';
import Policies from './pages/Policies';
import Employees from './pages/Employees';
import Resumes from './pages/Resumes';
import Reinforcement from './pages/Reinforcement';
import ShareRoster from './pages/ShareRoster';
import PayrollSettings from './pages/PayrollSettings';
import SalaryTemplate from './pages/SalaryTemplate';
import SalaryComponent from './pages/SalaryComponent';
import Profile from './pages/Profile';
import Sites from './pages/Sites';
import Leads from './pages/Leads';
import GetResumes from './pages/GetResumes';
import Leaves from './pages/Leaves';
import Queries from './pages/Queries';
// import Templates from './pages/Templates';
import ChangePassword from './pages/ChangePassword';
import VerifyAccount from './pages/VerifyAccount';
import SiteProfile from './pages/SiteProfile';
import HeirarchyProfile from './pages/HeirarchyProfile';
import SitesFeedback from './pages/SitesFeedback';
import SiteFeedback from './pages/SiteFeedback';
import PaySlip from './pages/PaySlip';
// import SiteEmployees from './pages/SiteEmployees';
// import SiteAddMembers from './pages/SiteAddMembers';
import ImportEmployees from './pages/ImportEmployees';
import EmployeeProfile from './pages/EmployeeProfile';
import ImportSites from './pages/ImportSites';
import EmployeeSiteHistory from './pages/EmployeeSiteHistory';
import EmployeeAttendanceHistory from './pages/EmployeeAttendanceHistory';
import EmployeeLeadHistory from './pages/EmployeeLeadHistory';
import EmployeeFeedbackHistory from './pages/EmployeeFeedbackHistory';
import EmployeeQueriesHistory from './pages/EmployeeQueriesHistory';
import NotFound from './pages/Page404';
import Attendance from './pages/Attendance';
import ShiftAttendance from './pages/ShiftAttendance';
import ShiftWiseAttendance from './pages/ShiftWiseAttendance';
// icons
import SiteShift from './pages/SiteShift';
// import ViewShiftMembers from './pages/ViewShiftMembers';
import { getEmployeeProfileById, getAdminProfileById } from './api/queries/profile';
import { ACCESS_CODES } from './utils/config';
import { logOut } from './redux/actions/adminActions';
import ECRChallan from './pages/ECRChallan';

// ----------------------------------------------------------------------
const RosterOverview = React.lazy(() => import('./pages/RosterOverview'));
const Roster = React.lazy(() => import('./pages/Roster'));
const EmployeesWorkHours = React.lazy(() =>
  import('./components/_dashboard/app/EmployeesWorkHours')
);

export default function Router() {
  const dispatch = useDispatch();
  const { isAdminAuthenticated, info } = useSelector((state) => state.adminInfo);
  const PAYROLL_ACCESS = info?.isMainAdmin
    ? info?.module_access?.includes(ACCESS_CODES.payroll)
    : info?.admin?.module_access?.includes(ACCESS_CODES.payroll);

  const {
    isLoading: isProfileLoading,
    isSuccess: isProfileSuccess,
    isError: isProfileError,
    data: profileData
  } = useQuery(
    [`${!info?.isMainAdmin ? 'employee' : 'admin'}-profile`, info?._id],
    () => (!info?.isMainAdmin ? getEmployeeProfileById(info?._id) : getAdminProfileById(info?._id)),
    {
      enabled: isAdminAuthenticated ?? false,
      onError: (error) => {
        // status: 401 unauthorized
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
      }
    }
  );

  useEffect(() => {
    // console.log('Logged In Info', profileData);
    if (profileData) {
      const MODULE_ACCESS = info?.isMainAdmin
        ? !!info?.module_access
        : !!info?.admin?.module_access;
      const LOCAL_MODULE = info?.isMainAdmin ? info?.module_access : info?.admin?.module_access;
      const SERVER_MODULE = info?.isMainAdmin
        ? profileData?.admin?.module_access
        : profileData?.employee?.module_access;
      // Logout Account with No username
      // if (!info?.username || !MODULE_ACCESS || LOCAL_MODULE?.length !== SERVER_MODULE?.length) {
      //   dispatch(logOut());
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData, info]);

  const VERIFIED = React.useMemo(() => {
    if (!isProfileError && !isProfileLoading) {
      // console.log(profileData);
      if (profileData?.isMobileVerified || profileData?.isEmailVerified) {
        return true;
      }
    }
    return true;
  }, [profileData, isProfileLoading, isProfileError]);

  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: '',
          element:
            isAdminAuthenticated && info?.is_setup_completed ? (
              <Navigate to="/dashboard/app" replace />
            ) : (
              <Navigate to="/login" replace />
            )
        },
        { path: 'login', element: <Login /> },
        {
          path: 'reset-password',
          element: <ResetPassword />
        },
        {
          path: 'verify-account',
          element: <VerifyAccount />
        },
        { path: 'register', element: <Register /> },
        {
          path: 'setup',
          element: isAdminAuthenticated ? (
            info?.is_setup_completed || info?.admin ? (
              VERIFIED ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/verify-account" replace />
              )
            ) : (
              <Stepper />
            )
          ) : (
            <Navigate to="/login" replace />
          )
        },
        {
          path: 'dashboard',
          element: <DashboardLayout profile={profileData} />,
          children: [
            { path: '', element: <Navigate to="/dashboard/app" replace /> },
            {
              path: 'app',
              element:
                info?.role?.priority > info?.site_manager_priority ? (
                  <EDashboard
                    userPolicy={profileData?.employee?.policy}
                    shiftAttendance={
                      profileData?.employee?.sites.length > 0 &&
                      !profileData?.employee?.role?.is_site_manager
                    }
                    checkAttendance={isProfileSuccess && isProfileLoading}
                  />
                ) : (
                  <Dashboard
                    userPolicy={profileData?.employee?.policy}
                    shiftAttendance={
                      profileData?.employee?.sites.length > 0 &&
                      !profileData?.employee?.role?.is_site_manager
                    }
                  />
                )
            },
            { path: 'map', element: <Map /> },
            { path: 'roster', element: <RosterOverview /> },
            { path: 'employees', element: <Employees /> },
            { path: 'employees/unassigned', element: <ImportEmployees /> },
            // {
            //   path: 'attendance-history',
            //   element: <EmployeeAttendanceHistory user={profileData?.employee ?? info?._id} />
            // },
            { path: 'attendance', element: <Attendance /> },
            { path: 'policies', element: <Policies /> },
            { path: 'leaves', element: <Leaves /> },
            // { path: 'templates', element: <Templates /> },
            { path: 'queries', element: <Queries /> },
            { path: 'resumes', element: <Resumes /> },
            { path: 'sites', element: <Sites /> },
            { path: 'sites/unassigned', element: <ImportSites /> },
            { path: 'sites/feedback', element: <SitesFeedback /> },
            { path: 'leads', element: <Leads /> },
            { path: 'report', element: <EmployeesWorkHours noShadow large /> },
            { path: 'reinforcement', element: <Reinforcement /> }
          ]
        },
        // {
        //   path: 'hierarchy',
        //   element: isAdminAuthenticated ? <Hierarchy /> : <Navigate to="/login" replace />
        // },
        // {
        //   path: 'privileges',
        //   element: isAdminAuthenticated ? <Privileges /> : <Navigate to="/login" replace />
        // },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: 'payroll',
      element:
        isAdminAuthenticated && PAYROLL_ACCESS ? (
          VERIFIED ? (
            <PayrollLayout profile={profileData} />
          ) : (
            <Navigate to="/verify-account" replace />
          )
        ) : (
          <Navigate to="/login" replace />
        ),
      children: [
        { path: '', element: <Navigate to="/payroll/app" replace /> },
        { path: 'app', element: <Payroll /> },
        { path: 'payslip', element: <PaySlip /> },
        { path: 'ecr-challan', element: <ECRChallan /> },
        { path: 'loan', element: <Loan /> },
        { path: 'template', element: <SalaryTemplate /> },
        { path: 'component', element: <SalaryComponent /> },
        { path: 'settings', element: <PayrollSettings /> }
        // { path: 'change_password', element: <ChangePassword /> },
        // { path: 'heirarchy', element: <HeirarchyProfile /> },
        // { path: 'leaves', element: <Leaves profile userPolicy={profileData?.employee?.policy} /> }
      ]
    },
    {
      path: 'roster',
      element: isAdminAuthenticated ? (
        VERIFIED ? (
          <RosterLayout />
        ) : (
          <Navigate to="/verify-account" replace />
        )
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: '', element: <Navigate to="/roster" replace /> },
        { path: 'app/:site/:date', element: <Roster /> }
      ]
    },
    {
      path: 'profile',
      element: isAdminAuthenticated ? (
        VERIFIED ? (
          <ProfileLayout />
        ) : (
          <Navigate to="/verify-account" replace />
        )
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: '', element: <Navigate to="/profile/app" replace /> },
        { path: 'app', element: <Profile /> },
        { path: 'change_password', element: <ChangePassword /> },
        { path: 'heirarchy', element: <HeirarchyProfile /> },
        { path: 'leaves', element: <Leaves profile userPolicy={profileData?.employee?.policy} /> }
      ]
    },
    {
      path: 'attendance',
      element: isAdminAuthenticated ? (
        VERIFIED ? (
          <AttendanceLayout />
        ) : (
          <Navigate to="/verify-account" replace />
        )
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { element: <Navigate to="/404" replace /> },
        // { element: <Navigate to="/attendance/app" replace /> },
        // { path: 'app', element: <Leaves /> }, // Yet To Build
        { path: 'site/:site', element: <ShiftAttendance /> },
        { path: 'site/:site/:shift', element: <ShiftWiseAttendance /> }
      ]
    },
    {
      path: 'site',
      element: isAdminAuthenticated ? (
        VERIFIED ? (
          <SiteLayout />
        ) : (
          <Navigate to="/verify-account" replace />
        )
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: '', element: <Navigate to="/site/profile" replace /> },
        { path: 'profile', element: <SiteProfile /> },
        // { path: 'employees', element: <SiteEmployees /> },
        { path: 'feedback', element: <SiteFeedback /> },
        // { path: 'add_members', element: <SiteAddMembers /> },
        { path: 'shifts', element: <SiteShift /> }
        // { path: 'shifts/view_members', element: <ViewShiftMembers /> }
        // { path: 'overtime', element: <SiteProfile /> },
        // { path: 'feedback', element:  }
      ]
    },
    {
      path: 'employee',
      element: isAdminAuthenticated ? (
        VERIFIED ? (
          <EmployeeLayout />
        ) : (
          <Navigate to="/verify-account" replace />
        )
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: '', element: <Navigate to="/employee/profile" replace /> },
        { path: 'profile', element: <EmployeeProfile /> },
        { path: 'change_password', element: <ChangePassword employee /> },
        { path: 'site-history', element: <EmployeeSiteHistory /> },
        { path: 'attendance-history', element: <EmployeeAttendanceHistory /> },
        { path: 'lead-history', element: <EmployeeLeadHistory /> },
        { path: 'feedback-history', element: <EmployeeFeedbackHistory /> },
        { path: 'queries-history', element: <EmployeeQueriesHistory /> }
      ]
    },
    {
      path: '/sharing/:edata/:date',
      element: <ShareRoster />
    },
    {
      path: '/sharing/get-resumes/:edata',
      element: <GetResumes />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
