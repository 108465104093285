/* eslint-disable no-nested-ternary */
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
// components
import MenuPopover from '../../components/MenuPopover';
import { logOut } from '../../redux/actions/adminActions';
import { getDataFromStorage } from '../../utils/functions';
import { logoutUser, connectAccounts } from '../../api/mutations/adminMutations';
import { unsubscribeToTopic } from '../../firebaseInit';
// import { fetchNotificationsTopics } from '../../api/queries/Queries';

// ----------------------------------------------------------------------

const EMPLOYEE_MENU_OPTIONS = [
  {
    label: 'Home',
    icon: <HomeIcon sx={{ mr: 2, width: 24, height: 24 }} />,
    linkTo: '/dashboard/app'
  },
  {
    label: 'Profile',
    icon: <PersonIcon sx={{ mr: 2, width: 24, height: 24 }} />,
    linkTo: '/profile/app'
  }
  // {
  //   label: 'Settings',
  //   icon: settings2Fill,
  //   linkTo: '#'
  // }
];

const BELOW_MENU_OPTIONS = [
  {
    label: 'Home',
    icon: <HomeIcon sx={{ mr: 2, width: 24, height: 24 }} />,
    linkTo: '/employee/app'
  },
  {
    label: 'Profile',
    icon: <PersonIcon sx={{ mr: 2, width: 24, height: 24 }} />,
    linkTo: '/profile/app'
  }
  // {
  //   label: 'Settings',
  //   icon: settings2Fill,
  //   linkTo: '#'
  // }
];

const ADMIN_MENU_OPTIONS = [
  {
    label: 'Home',
    icon: <HomeIcon sx={{ mr: 2, width: 24, height: 24 }} />,
    linkTo: '/dashboard/app'
  },
  {
    label: 'Company',
    icon: <PersonIcon sx={{ mr: 2, width: 24, height: 24 }} />,
    linkTo: '/profile/app'
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover({ profile }) {
  const anchorRef = useRef(null);
  const [addCompany, setAddCompany] = useState(false);
  const [companyUID, setCompanyUID] = useState('');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    // isAdminAuthenticated,
    info
  } = useSelector((state) => state.adminInfo);
  const MENU_OPTIONS = info.isMainAdmin
    ? ADMIN_MENU_OPTIONS
    : info?.role?.priority > info?.site_manager_priority
    ? BELOW_MENU_OPTIONS
    : EMPLOYEE_MENU_OPTIONS;
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { mutate: logoutMutation } = useMutation(logoutUser);
  const { mutate: connectMutation } = useMutation(connectAccounts, {
    onMutate: () => {
      setAddCompany(false);
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Sending Connection Request 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    }
  });

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={info?.avatar} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        {!info?.isMainAdmin && (
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="h6" noWrap>
              {info?.admin?.name}
            </Typography>
          </Box>
        )}
        {profile?.admin?.group?.length > 0 ? (
          <>
            {profile?.admin?.group.map((cmp, idx) => (
              <Box key={`group ${idx} - ${cmp._id}`} sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant="subtitle1" noWrap>
                  {cmp?.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                  {cmp?.email}
                </Typography>
              </Box>
            ))}
          </>
        ) : (
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle1" noWrap>
              {info?.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {info?.email}
            </Typography>
          </Box>
        )}

        <Divider sx={{ my: 1 }} />

        {info?.isMainAdmin && (
          <Box sx={{ p: 1, pt: 1.5 }}>
            {addCompany ? (
              <Stack spacing={1}>
                <TextField
                  sx={{ '& input': { py: '8.5px !important' } }}
                  placeholder="Enter Company UID"
                  value={companyUID}
                  onChange={(e) => setCompanyUID(e.target.value)}
                />
                <Button
                  onClick={() => {
                    connectMutation(
                      {
                        uid: companyUID
                      },
                      {
                        onError: (error) => {
                          // for unauthorized access
                          if (error.response && error.response.status === 401) {
                            dispatch(logOut());
                          }

                          const msg = error.response
                            ? error.response.data.message || error.toString()
                            : error.toString();

                          Swal.fire({
                            icon: 'error',
                            title: 'Something went wrong!',
                            text: msg,
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                          });
                        },
                        onSuccess: (data) => {
                          Swal.fire({
                            icon: 'success',
                            // title: 'Site added!',
                            text: data.message,
                            showConfirmButton: true
                          });
                        }
                      }
                    );
                    // setCompanyUID('');
                  }}
                  variant="contained"
                  size="small"
                >
                  Connect
                </Button>
              </Stack>
            ) : (
              <Button
                onClick={() => setAddCompany(true)}
                fullWidth
                color="inherit"
                variant="outlined"
              >
                Add Company
              </Button>
            )}
          </Box>
        )}

        <Divider sx={{ my: 1 }} />

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            <b>ID :</b> {info?.username ?? 'XXXX-XXXXX-XXXX'}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            {/* <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            /> */}
            {option.icon}
            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            onClick={async () => {
              logoutMutation({
                id: info?._id,
                fcm_token: getDataFromStorage('fcm_token')
              });
              const notificationTopics = getDataFromStorage('notification_topics');
              const fmsgToken = getDataFromStorage('fcm_token');
              if (notificationTopics?.length > 0) {
                notificationTopics.forEach((topic) => {
                  unsubscribeToTopic(fmsgToken, topic);
                });
              }
              dispatch(logOut());
            }}
            fullWidth
            color="inherit"
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
