/* eslint-disable no-nested-ternary */
import '../../../index.css';
import { memo, useMemo, useEffect, useState, Fragment } from 'react';
import * as Yup from 'yup';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import { useFormik, FormikProvider, Form } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Grid from '@mui/material/Grid';
import {
  Modal,
  Typography,
  Box,
  TextField,
  Stack,
  Select,
  MenuItem,
  // ListSubheader,
  FormControl,
  // FormControlLabel,
  InputLabel,
  InputAdornment,
  // Checkbox,
  Button,
  IconButton,
  // OutlinedInput,
  // Chip,
  CircularProgress
} from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// icons
import CloseIcon from '@mui/icons-material/Close';
import EmployeeForm from './EmployeeForm';
// config
import { ADD_EMPLOYEE } from '../../../utils/config';
import { addEmployee } from '../../../api/mutations/adminMutations';

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 'calc(100vw - 20rem)',
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

function AddEmployee({ open, onClose, step }) {
  console.log();
  return (
    <Modal
      open={open ?? false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} container>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 2,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          <img src={ADD_EMPLOYEE} width="40" height="40" alt="edit_icon" />
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            Add Employee
          </Typography>
          <IconButton
            onClick={() => onClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <EmployeeForm step={step} formMutation={addEmployee} />
      </Box>
    </Modal>
  );
}

AddEmployee.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  step: PropTypes.number
};

export default memo(AddEmployee);
