import {
  EMPLOYEE_INFO_REQUESTED,
  EMPLOYEE_INFO_FAILED,
  EMPLOYEE_INFO_SUCCEDED,
  EMPLOYEE_LOGGED_OUT
} from '../constants/employeeConstants';
import { getDataFromStorage } from '../../utils/functions';

const token = getDataFromStorage('employeeAuthToken', true);
const info = getDataFromStorage('employeeInfo', true);

const initialState = {
  isEmployeeAuthenticated: !!token,
  info: info || null
};

export const employeeInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_INFO_REQUESTED:
      return { loading: true, ...state };
    case EMPLOYEE_INFO_SUCCEDED:
      return {
        ...state,
        isEmployeeAuthenticated: true,
        loading: false,
        info: action.payload
      };
    case EMPLOYEE_INFO_FAILED:
      return {
        ...state,
        isEmployeeAuthenticated: false,
        loading: false,
        error: action.payload
      };
    case EMPLOYEE_LOGGED_OUT:
      return {
        ...state,
        isEmployeeAuthenticated: false,
        info: null,
        loading: false
      };
    default:
      return state;
  }
};
