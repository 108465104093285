import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DeleteIcon from '@mui/icons-material/Delete';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

UserMoreMenu.propTypes = {
  approveLead: PropTypes.func,
  rejectLead: PropTypes.func,
  deleteLead: PropTypes.func,
  leadStatus: PropTypes.string
};

export default function UserMoreMenu({ approveLead, rejectLead, deleteLead, leadStatus }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {leadStatus === 'Pending' ? (
          <>
            <MenuItem sx={{ color: 'text.secondary' }} onClick={() => approveLead()}>
              <ListItemIcon sx={{ mr: 0 }}>
                <ThumbUpIcon sx={{ color: 'green' }} />
              </ListItemIcon>
              <ListItemText primary="Approve" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>

            <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={() => rejectLead()}>
              <ListItemIcon sx={{ mr: 0 }}>
                <ThumbDownIcon sx={{ color: 'red' }} />
              </ListItemIcon>
              <ListItemText primary="Reject" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </>
        ) : null}

        <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={() => deleteLead()}>
          <ListItemIcon sx={{ mr: 0 }}>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
