/* eslint-disable no-nested-ternary */
import '../../../index.css';
import { memo, useMemo, useEffect, useState, useCallback } from 'react';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
// import { useDispatch } from 'react-redux';
import { useFormik, FormikProvider, Form } from 'formik';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Modal, Typography, Box, Stack, Container, Button, IconButton, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
// icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
// config
import { addSubRole } from '../../../api/mutations/subRole';
import { POLICY, CROWN } from '../../../utils/config';
// hooks
import { useHeirarchy } from '../../../hooks/roles';
// mutations
// import { addPolicy, editPolicy } from '../../../api/mutations/policy';
// utils
import { capitalizeEachWord } from '../../../utils/functions';
// redux
// import { logOut } from '../../../redux/actions/adminActions';
import classes from '../../../pages/styles/Hierarchy.module.css';

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 'calc(100vw - 20rem)',
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

function AddSubRole({ open, onClose, selectedRole, edit }) {
  // const dispatch = useDispatch();
  const cache = useQueryClient();
  selectedRole = edit?.role?._id ?? selectedRole;
  const [itens, setItens] = useState({}); // to store list of sub roles
  // const [subNames, setSubNames] = useState([]);
  // const [selectRolesOptions, setSelectRolesOptions] = useState(); // Roles initial State for select

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data } = useHeirarchy(); // gettings roles

  // const allRoles = useMemo(() => {
  //   if (!isRolesLoading && !isRolesError) {
  //     return roles.heirarchy;
  //   }
  //   return ''; // Needs to be changed
  // }, [roles, isRolesLoading, isRolesError]);

  const allRoles = useMemo(() => {
    const Heirarchy = [];
    if (!isRolesLoading && !isRolesError) {
      if (data?.pages?.length > 0) {
        data.pages.forEach((group) => {
          Heirarchy.push(group.heirarchy);
          // if (group.is_setup_completed) {
          //   navigate('/dashboard/app', { replace: true });
          // }
        });
      }
      const flattened = Heirarchy.flat();
      // console.log(flattened);
      return flattened;
      // eslint-disable-next-line camelcase
      // const { heirarchy, is_setup_completed } = data;
      // // eslint-disable-next-line camelcase
      // if (is_setup_completed) {
      //   navigate('/dashboard/app', { replace: true });
      // }
      // return heirarchy;
    }
    return [];
  }, [data, isRolesLoading, isRolesError]);

  useEffect(() => {
    const formattedRoles = [];
    const items = {}; // adding roles keys for storing sub roles
    if (allRoles?.length > 0) {
      allRoles.forEach((role) => {
        formattedRoles.push([role._id, role.name]);
        items[role._id] = [];
      });
      // setSelectRolesOptions([...formattedRoles]);
      setItens(items);
      // console.log('Add Policy Role Options :', formattedRoles);
    }
  }, [allRoles]);
  /* ================= End Roles =============== */

  //   useEffect(() => {
  //     console.log('Edit Policy :', edit);
  //   }, [edit]);

  // useEffect(() => {
  //   console.log('Itens:', itens);
  // }, [itens]);

  const rows = useMemo(() => itens[selectedRole.id], [itens, selectedRole]);

  const { mutate } = useMutation(addSubRole, {
    onMutate: () => {
      onClose();
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: `${edit ? 'Updating' : 'Adding New'} Subrole 😃`,
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    }
  });

  const handleSubRole = useCallback(() => {
    // handle step-1
    if (itens[selectedRole.id].length > 0) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Dont Worry!, You can change those in your Profile!`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, Add Sub-Roles!'
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            role: selectedRole.id,
            subroles: itens[selectedRole.id].map((item) => item.content)
          };
          // console.log('Adding SubRole:', data);
          mutate(data, {
            onError: (error) => {
              const msg = error?.response?.data?.message || error.toString();

              Swal.fire({
                icon: 'error',
                title: 'Something went wrong !',
                text: msg
              });
            },
            onSuccess: (data) => {
              cache.invalidateQueries('privileges');
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: data.message,
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true
                // history.replace("/privileges"),
              });
            },
            onSettled: () => cache.invalidateQueries('privileges')
          });
        }
      });
    } else {
      Swal.fire({
        title: 'Create heirarchy first!',
        text: 'Hey, please set a heirarchy structure as per your needs. Currently, no heirarchy is defined.',
        icon: 'warning'
      });
    }
  }, [itens, selectedRole, mutate, cache]);

  function hasDuplicates(arr, val) {
    if (arr.includes(val?.toLowerCase())) {
      return true;
    }
    const counts = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= arr.length; i++) {
      if (counts[arr[i]] === undefined) {
        counts[arr[i]] = 1;
      } else {
        return true;
      }
    }
    return false;
  }

  // using formik for form validation, and Yup to design validation Schema
  const formik = useFormik({
    initialValues: {
      tagName: ''
    },
    validationSchema: Yup.object({
      tagName: Yup.string()
        .notRequired()
        .matches(
          /^[a-zA-Z][a-zA-Z0-9\s-]+$/,
          '- Must start with an alphabet - Minimum 2 characters'
        )
        .test(
          'is-duplicate',
          'Duplicate names not allowed, some of the names are already used for previously added roles',
          (value) => {
            const _sub = Object.values(itens)
              .map((t) => t.map((v) => v.content.toLowerCase()))
              .flat()
              .filter((ft) => ft !== undefined);
            if (hasDuplicates(_sub)) {
              return false;
            }
            if (hasDuplicates(_sub, value)) {
              return false;
            }
            return true;
          }
        )
    }),
    onSubmit: (values, actions) => {
      if (values.tagName) {
        itens[selectedRole.id].push({
          id: `${Date.now()}`,
          content: `${capitalizeEachWord(values.tagName)}`
        });
        setItens({ ...itens });
        actions.resetForm();
      }
    }
  });

  const { getFieldProps } = formik;

  // function dragEnd(result, itens, setItens) {
  //   if (!result.destination) return;

  //   const { source, destination } = result;
  //   const copiedItems = itens;
  //   const [removed] = copiedItems.splice(source.index, 1);
  //   copiedItems.splice(destination.index, 0, removed);

  //   setItens(copiedItems);
  // }

  function handleDelete(index) {
    itens[selectedRole.id].splice(index, 1);
    setItens({ ...itens });
    // console.log(itens);
  }

  return (
    <Modal
      open={open ?? false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} container>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 2,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          <img src={POLICY} width="40" height="40" alt="edit_icon" />
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            {edit ? 'Edit' : 'Add'} Tags
          </Typography>
          <IconButton
            onClick={() => onClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate>
            <Container>
              <DragDropContext
              // onDragEnd={(result) => {
              //   dragEnd(result, itens, setItens);
              // }}
              >
                <Stack>
                  <TextField
                    id="tagName"
                    name="tagName"
                    sx={{ mt: 2 }}
                    fullWidth
                    type="text"
                    label="Tag Name"
                    autoComplete="off"
                    autoFocus
                    {...getFieldProps('tagName')}
                    onKeyUp={(event) => {
                      // Number 13 is the "Enter" key on the keyboard
                      if (event.keyCode === 13) {
                        // Cancel the default action, if needed
                        event.preventDefault();
                        formik.handleSubmit();
                        console.log(formik.errors);
                      }
                    }}
                    error={Boolean(formik.touched.subRole && formik.errors.subRole)}
                    // helperText={touched.email && errors.email}
                  />
                  <Typography sx={{ fontSize: 11, color: 'red' }}>
                    {formik.errors.subRole}
                  </Typography>
                  <Typography sx={{ mt: 0.5 }} style={{ fontSize: '0.8rem' }}>
                    e.g. Press 'Enter' to add a Tag
                  </Typography>
                  <Box
                    style={{ border: '2px solid #0897FF', borderRadius: 8 }}
                    sx={{ p: 1, mt: 1 }}
                  >
                    <Typography
                      sx={{
                        mt: -2,
                        px: 2,
                        backgroundColor: 'white',
                        maxWidth: '99.5%',
                        color: '#0897FF'
                      }}
                    >
                      <b>Current Role</b>
                    </Typography>
                    <Box
                      className="custom_scrollbar"
                      sx={{ overflowY: 'auto', height: 'calc(100vh - 280px)' }}
                    >
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <Stack
                            // style={{ height: 'calc(100vh - 280px)' }} // height of column change
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={classes.list}
                          >
                            <Grid className={`${classes.content__container} z-depth-1`}>
                              <Typography className={classes.number} sx={{ fontWeight: 'bold' }}>
                                1
                              </Typography>
                              <Typography
                                align="left"
                                noWrap
                                sx={{ py: 1, px: 2 }}
                                className={classes.role}
                                title={`${selectedRole.name}`}
                              >
                                {selectedRole.name}
                              </Typography>
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  backgroundColor: '#4CAF50',
                                  borderRadius: 0,
                                  boxShadow: 'none'
                                }}
                              >
                                <CheckCircleIcon />
                              </Button>
                              <img src={CROWN} alt="crown" className={classes.crown} />
                            </Grid>
                            {rows.length > 0 ? (
                              rows.map((item, index) => (
                                <Draggable
                                  className={classes.card}
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <Stack
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Grid className={`${classes.content__container} z-depth-1`}>
                                        <Typography className={classes.number}>
                                          {index + 2}
                                        </Typography>
                                        <Typography
                                          sx={{ py: 1 }}
                                          className={classes.role}
                                          title={`${item.content}`}
                                          alt={`${item.content}`}
                                        >
                                          <Typography
                                            component="span"
                                            sx={{ px: 1, display: 'flex', flexWrap: 'none' }}
                                            className={classes.role__text}
                                          >
                                            {item.content}
                                          </Typography>
                                        </Typography>
                                        <Button
                                          variant="contained"
                                          style={{ backgroundColor: '#F44336', borderRadius: 0 }}
                                          onClick={() => handleDelete(index)}
                                        >
                                          <DeleteIcon />
                                        </Button>
                                      </Grid>
                                    </Stack>
                                  )}
                                </Draggable>
                              ))
                            ) : (
                              <Stack
                                direction="row"
                                sx={{ height: 300 }}
                                justifyContent="center"
                                alignItems="center"
                              >
                                Add Tag to View
                              </Stack>
                            )}
                            {provided.placeholder}
                          </Stack>
                        )}
                      </Droppable>
                    </Box>
                  </Box>
                </Stack>
              </DragDropContext>
            </Container>
            <Stack
              sx={{ position: 'absolute', backgroundColor: 'white', bottom: 0, px: 2, pb: 1.2 }}
            >
              <Button
                type="button"
                onClick={() => {
                  handleSubRole();
                  onClose();
                }}
                // disabled={hasDuplicates(subNames)}
                variant="contained"
              >
                Submit
              </Button>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Modal>
  );
}

AddSubRole.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultPolicy: PropTypes.bool,
  selectedRole: PropTypes.object,
  edit: PropTypes.object
};

export default memo(AddSubRole);
