import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
// components
import Page from '../components/Page';
import { createHeirarchy } from '../api/mutations/hierarchy';
import { addSubrole, deleteSubrole, editRole } from '../api/mutations/adminMutations';
// import { useRoles } from '../hooks/roles';
// utils
import { SWAL_TIME } from '../utils/config';
import { uid, setDataInStorage, capitalizeEachWord } from '../utils/functions';
// redux
import { logOut } from '../redux/actions/adminActions';

HierarchyTree.propTypes = {
  finishStep: PropTypes.any,
  hData: PropTypes.any,
  smLevel: PropTypes.number,
  edit: PropTypes.bool
};

export default function HierarchyTree({ finishStep, hData, smLevel, edit, profile }) {
  const dispatch = useDispatch();
  const cache = useQueryClient();
  // const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [subrole, setSubrole] = useState();
  const [siteManagerLevel, setSiteManagerLevel] = useState(smLevel ?? null);
  const [checkName, setCheckName] = useState({}); // To Check Duplicate Roles
  const [checkSubrole, setCheckSubrole] = useState({}); // To Check Duplicate Sub Roles
  const [error, setError] = useState({});
  const {
    // isAdminAuthenticated,
    info
  } = useSelector((state) => state.adminInfo);
  const HDATA = React.useMemo(() => {
    console.log();
    return hData;
  }, [hData]);
  const [heirarchy, setHeirarchy] = useState(
    HDATA ?? [
      {
        nodeId: 0,
        level: 0,
        parents: [
          {
            name: info?.name,
            isAdmin: true
          }
        ],
        pts: [],
        subroles: [],
        child: []
      }
    ]
  );

  // function getNodeFromTree(node, nodeId) {
  //   if (node.nodeId === nodeId) {
  //     return node;
  //   }
  //   if (node.child != null) {
  //     let result = null;
  //     // eslint-disable-next-line no-plusplus
  //     for (let i = 0; result == null && i < node.child.length; i++) {
  //       result = getNodeFromTree(node.child[i], nodeId);
  //     }
  //     return result;
  //   }
  //   return null;
  // }

  const HEIRARCHY = React.useMemo(() => {
    console.log();
    return heirarchy;
  }, [heirarchy]);

  function insertNodeIntoTree(node, nodeId, newNode) {
    // console.log(node, nodeId, newNode);
    if (node.nodeId === nodeId) {
      // get new id
      const n = uid();
      /** Your logic to generate new Id * */
      if (newNode) {
        newNode.nodeId = n;
        newNode.child = [];
        // newNode.pts = node.parents;
        newNode.pts = Array.prototype
          .concat(node.parents, node.pts)
          .filter((fl) => fl?.isAdmin !== true);
        node.child.push(newNode);
      }
    } else if (node.child != null) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < node.child.length; i++) {
        insertNodeIntoTree(node.child[i], nodeId, newNode);
      }
    }
    setHeirarchy([...heirarchy]);
  }

  function insertSubroleIntoTree(node, nodeId, newNode) {
    // console.log(node, nodeId, newNode);
    if (node.nodeId === nodeId) {
      // get new id
      // const n = uid();
      /** Your logic to generate new Id * */
      node.subroles.push(newNode);
    }
    setHeirarchy([...heirarchy]);
  }

  function updateNodeInTree(node, nodeId, newNode, subrole = false) {
    if (node.nodeId === nodeId) {
      if (subrole) {
        node.subroles.forEach((s) => {
          if (s.nodeId === newNode.nodeId) {
            delete s.pname;
            s.name = newNode.name;
            checkSubrole[newNode.nodeId] = newNode.name.toLowerCase();
            setCheckSubrole({ ...checkSubrole });
          }
        });
      } else {
        node.parents.forEach((p) => {
          if (p.nodeId === newNode.nodeId) {
            delete p.pname;
            p.name = newNode.name;
            checkName[newNode.nodeId] = newNode.name.toLowerCase();
            setCheckName({ ...checkName });
          }
        });
      }
    } else if (node.child != null) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < node.child.length; i++) {
        updateNodeInTree(node.child[i], nodeId, newNode);
      }
    }
    setHeirarchy([...heirarchy]);
  }

  function deleteParentFromTree(node, nodeId) {
    if (node.parents != null) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < node.parents.length; i++) {
        const filtered = node.parents.filter((f) => f.nodeId === nodeId);
        if (filtered && filtered.length > 0) {
          node.parents = node.parents.filter((f) => f.nodeId !== nodeId);
          return;
        }
        deleteParentFromTree(node.parents[i], nodeId);
      }
    }
    setHeirarchy([...heirarchy]);
  }

  function deleteNodeFromTree(node, _node, nodeId) {
    deleteParentFromTree(_node, nodeId);
    if (_node.parents.length === 1) {
      if (node.child != null) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < node.child.length; i++) {
          const filtered = node.child.filter((f) => f.nodeId === _node.nodeId);
          if (filtered && filtered.length > 0) {
            node.child = node.child.filter((f) => f.nodeId !== _node.nodeId);
            return;
          }
          deleteNodeFromTree(node.child[i], _node.nodeId);
        }
      }
    }
    setHeirarchy([...heirarchy]);
  }

  function deleteSubroleFromTree(node, nodeId) {
    if (node.subroles != null) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < node.subroles.length; i++) {
        const filtered = node.subroles.filter((f) => f.nodeId === nodeId);
        if (filtered && filtered.length > 0) {
          node.subroles = node.subroles.filter((f) => f.nodeId !== nodeId);
          return;
        }
        deleteNodeFromTree(node.subroles[i], nodeId);
      }
    }
    setHeirarchy([...heirarchy]);
  }

  const checkOccurrence = (array, element) => {
    let counter = 0;
    // eslint-disable-next-line no-restricted-syntax
    array.forEach((aa) => {
      if (aa === element.toLowerCase()) {
        // eslint-disable-next-line no-plusplus
        counter += 1;
      }
    });
    return counter;
  };

  const { mutate: createHeirarchyMutation } = useMutation(createHeirarchy, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Saving your heirarchy structure on Trakkar 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    }
  });

  const handleStep1 = (HEIRARCHY) => {
    const response = {};
    function databaseConversion(tree, level = 1) {
      if (tree.child !== null) {
        tree.child.forEach((node) => {
          if (response[level]?.length > 0) {
            if (!response[level].find((nn) => nn.nodeId === node.nodeId)) {
              node.parents.forEach((pr) => {
                response[level].push({
                  name: pr.name,
                  parents: node.pts,
                  subroles: node.subroles,
                  siteManager: pr.siteManager,
                  hr: pr.hr
                });
              });
            }
          } else {
            response[level] = [];
            node.parents.forEach((pr) => {
              response[level].push({
                name: pr.name,
                parents: node.pts,
                subroles: node.subroles,
                siteManager: pr.siteManager,
                hr: pr.hr
              });
            });
          }
          databaseConversion(node, level + 1);
        });
      }
    }
    // handle step-1
    if (HEIRARCHY.child.length > 0) {
      if (siteManagerLevel > 0) {
        Swal.fire({
          title: 'Are you sure?',
          text: `You won't be able to revert this!`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, save heirarchy!'
        }).then((result) => {
          if (result.isConfirmed) {
            databaseConversion(HEIRARCHY);
            console.log(response);
            createHeirarchyMutation(
              { heirarchy: response },
              {
                onError: (error) => {
                  const msg = error?.response?.data?.message || error.toString();
                  Swal.fire({
                    icon: 'error',
                    title: 'Something went wrong !',
                    text: msg
                  });
                },
                onSuccess: (data) => {
                  Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: data.message,
                    showConfirmButton: false,
                    timer: SWAL_TIME,
                    timerProgressBar: true,
                    willClose: () => {
                      finishStep(1);
                      setDataInStorage('step', 1);
                      window.location.reload();
                    }
                  });
                },
                onSettled: () => {
                  cache.invalidateQueries('roles');
                }
              }
            );
          }
        });
      } else {
        Swal.fire({
          title: 'Please assign a role as site Manager',
          text: 'Site Manager is a role that handles the associated sites',
          icon: 'warning'
        });
      }
    } else {
      Swal.fire({
        title: 'Create heirarchy first!',
        text: 'Hey, please set a heirarchy structure as per your needs. Currently, no heirarchy is defined.',
        icon: 'warning'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // Edit Role Mutation
  const editRoleMutation = useMutation(editRole, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Updated Role`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
      window.location.reload();
    },
    onSettled: () => {
      cache.invalidateQueries('heirarchy');
      cache.invalidateQueries('subroles');
    }
  });

  // Add Subrole Mutation
  const addSubroleMutation = useMutation(addSubrole, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Subrole Added Successfully`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
      window.location.reload();
    },
    onSettled: () => {
      cache.invalidateQueries('heirarchy');
      cache.invalidateQueries('subroles');
    }
  });

  // Delete Subrole Mutation
  const deleteSubroleMutation = useMutation(deleteSubrole, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Subrole deleted Successfully`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
      window.location.reload();
    },
    onSettled: () => {
      cache.invalidateQueries('heirarchy');
      cache.invalidateQueries('subroles');
    }
  });

  // console.log(info);
  const BuildTree = (tree, parent, level = 0) => {
    console.log();
    // const hasChild = tree?.child?.length > 0;
    return (
      <>
        {tree?.map((node) => (
          <li
            style={{
              position: 'relative'
              // float: `${node.parents.length === 1 && level > 0 && 'none'}`
            }}
            key={node.nodeId}
          >
            {node.parents.map((pnode, nidx) => (
              <a
                key={`parent-${nidx}`}
                style={{
                  position: 'relative'
                }}
                href="#"
              >
                <span
                  className="node-header"
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    display: 'block',
                    height: 20,
                    padding: '3px',
                    width: '100%'
                  }}
                >
                  <span style={{ float: 'left' }}>Level {level}</span>
                  {profile && info?.isMainAdmin && (
                    <SaveIcon
                      onClick={() => {
                        editRoleMutation.mutate({
                          type: 'Role',
                          id: node?.nodeId,
                          hr: pnode.hr,
                          name: document.getElementById(`${pnode?.nodeId}-edit`).value
                        });
                      }}
                      style={{
                        float: 'right',
                        marginLeft: '0.3rem',
                        // marginTop: 0.5,
                        cursor: 'pointer',
                        color: 'white',
                        zIndex: 99,
                        fontSize: 16
                      }}
                    />
                  )}
                  {level > 0 && edit && (
                    <CloseIcon
                      onClick={() => {
                        delete checkName[pnode.nodeId];
                        setCheckName({ ...checkName });
                        delete error[pnode.nodeId];
                        setError({ ...error });
                        deleteNodeFromTree(parent, node);
                      }}
                      style={{
                        float: 'right',
                        marginLeft: '0.3rem',
                        // marginTop: 0.5,
                        cursor: 'pointer',
                        color: 'white',
                        zIndex: 99,
                        fontSize: 16
                      }}
                    />
                  )}
                </span>
                {level > 0 ? (
                  <input
                    id={`${pnode?.nodeId}-edit`}
                    style={{ textAlign: 'center', width: '100%' }}
                    onChange={(e) => {
                      updateNodeInTree(node, node.nodeId, {
                        nodeId: pnode?.nodeId,
                        name: e.target.value
                      });
                      if (checkOccurrence(Object.values(checkName), e.target.value) > 1) {
                        error[pnode.nodeId] = 'Duplicate Roles are not allowed';
                        setError({ ...error });
                      } else {
                        delete error[pnode.nodeId];
                        setError({ ...error });
                      }
                    }}
                    defaultValue={pnode?.name}
                    placeholder={pnode?.pname}
                  />
                ) : (
                  <h3 style={{ padding: '5px' }}>{pnode.name}</h3>
                )}
                {error[pnode.nodeId] && (
                  <span
                    style={{
                      display: 'block',
                      width: '100%',
                      backgroudColor: 'white',
                      color: 'red'
                    }}
                  >
                    {error[pnode.nodeId]}
                  </span>
                )}
                {level > 0 && <Divider />}
                {level > 0 && info?.isMainAdmin && (
                  <span style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      sx={{ mx: 0, '& span': { fontSize: 12 } }}
                      control={
                        <Switch
                          checked={pnode.hr}
                          onChange={(e) => {
                            pnode.hr = e.target.checked;
                            setHeirarchy([...heirarchy]);
                          }}
                          size="small"
                        />
                      }
                      label="HR"
                    />
                    {(siteManagerLevel === level || siteManagerLevel === null) && (
                      <FormControlLabel
                        sx={{ mx: 0, '& span': { fontSize: 12 } }}
                        checked={pnode.siteManager}
                        control={
                          <Switch
                            size="small"
                            disabled={!edit}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSiteManagerLevel(level);
                              } else {
                                setSiteManagerLevel(null);
                              }
                              pnode.siteManager = e.target.checked;
                              setHeirarchy([...heirarchy]);
                            }}
                          />
                        }
                        label="Site Manager"
                      />
                    )}
                    <AddCircleIcon
                      onClick={(e) => {
                        if (profile) {
                          setAnchorEl({
                            ...anchorEl,
                            id: `add_subrole_${pnode.nodeId}`,
                            target: e.currentTarget
                          });
                        } else {
                          const n = uid();
                          insertSubroleIntoTree(node, node?.nodeId, {
                            nodeId: n,
                            pname: 'Enter Name',
                            siteManager: false,
                            hr: false
                          });
                        }
                      }}
                      style={{
                        color: '#f38d09',
                        fontSize: 18,
                        marginLeft: '0.4rem',
                        cursor: 'pointer',
                        zIndex: 99
                      }}
                    />
                    {profile && (
                      <Popover
                        id={`add_subrole_${pnode.nodeId}`}
                        open={anchorEl.id === `add_subrole_${pnode.nodeId}`}
                        anchorEl={anchorEl.target}
                        onClose={() => setAnchorEl({ ...anchorEl, id: null, target: null })}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                      >
                        <Stack sx={{ p: 2 }}>
                          <TextField
                            sx={{ mb: 2 }}
                            variant="standard"
                            value={subrole}
                            placeholder="Add Subrole"
                            onChange={(e) => setSubrole(capitalizeEachWord(e.target.value))}
                          />
                          <Button
                            variant="contained"
                            onClick={() => {
                              setAnchorEl({ ...anchorEl, id: null, target: null });
                              addSubroleMutation.mutate({
                                role: node.nodeId,
                                subroles: [subrole]
                              });
                            }}
                          >
                            Add
                          </Button>
                        </Stack>
                      </Popover>
                    )}
                  </span>
                )}
              </a>
            ))}
            {node.subroles.length > 0 && (
              <ul style={{ marginBottom: '0.5rem', textAlign: 'center' }}>
                {node.subroles.map((sub, sdix) => (
                  <li style={{ display: 'inline-block' }} key={`sub-${sdix}`}>
                    <a
                      className="sub-role"
                      style={{
                        position: 'relative'
                      }}
                      href="#"
                    >
                      <span
                        className="sub-header"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          display: 'block',
                          height: 20,
                          padding: '3px',
                          width: '100%'
                        }}
                      >
                        <span style={{ float: 'left' }}>Level {level}</span>
                        {level > 0 && edit && (
                          <CloseIcon
                            onClick={() => {
                              deleteSubroleFromTree(node, sub?.nodeId);
                              delete checkSubrole[sub.nodeId];
                              setCheckSubrole({ ...checkSubrole });
                              delete error[sub.nodeId];
                              setError({ ...error });
                            }}
                            style={{
                              float: 'right',
                              marginLeft: '0.3rem',
                              // marginTop: 0.5,
                              cursor: 'pointer',
                              color: 'white',
                              zIndex: 99,
                              fontSize: 16
                            }}
                          />
                        )}
                        {profile && info?.isMainAdmin && (
                          <DeleteIcon
                            onClick={() =>
                              Swal.fire({
                                title: `Are you sure ?`,
                                text: `${sub.name} will be Removed?`,
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, Proceed!',
                                allowOutsideClick: false
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteSubroleMutation.mutate({
                                    subroles: [sub.nodeId]
                                  });
                                } else if (result.dismiss === Swal.DismissReason.cancel) {
                                  cache.invalidateQueries('roster');
                                }
                              })
                            }
                            style={{
                              float: 'right',
                              marginLeft: '0.3rem',
                              // marginTop: 0.5,
                              cursor: 'pointer',
                              color: 'white',
                              zIndex: 99,
                              fontSize: 16
                            }}
                          />
                        )}
                        {profile && info?.isMainAdmin && (
                          <SaveIcon
                            onClick={() => {
                              editRoleMutation.mutate({
                                type: 'Subrole',
                                id: sub?.nodeId,
                                hr: sub?.hr,
                                name: document.getElementById(`${sub?.nodeId}-edit`).value
                              });
                            }}
                            style={{
                              float: 'right',
                              marginLeft: '0.3rem',
                              // marginTop: 0.5,
                              cursor: 'pointer',
                              color: 'white',
                              zIndex: 99,
                              fontSize: 16
                            }}
                          />
                        )}
                      </span>
                      {level > 0 ? (
                        <input
                          id={`${sub.nodeId}-edit`}
                          style={{ textAlign: 'center', width: '100%' }}
                          onKeyUp={(e) => {
                            // console.log(e.target.value);
                            updateNodeInTree(
                              node,
                              node.nodeId,
                              {
                                nodeId: sub.nodeId,
                                name: e.target.value
                              },
                              true
                            );
                            if (checkOccurrence(Object.values(checkSubrole), e.target.value) > 1) {
                              error[sub.nodeId] = 'Duplicate Sub Roles are not allowed';
                              setError({ ...error });
                            } else {
                              delete error[sub.nodeId];
                              setError({ ...error });
                            }
                          }}
                          defaultValue={sub?.name}
                          placeholder={sub?.pname}
                        />
                      ) : (
                        <h3 style={{ padding: '5px' }}>{sub.name}</h3>
                      )}
                      {error[sub.nodeId] && (
                        <span
                          style={{
                            display: 'block',
                            width: '100%',
                            backgroudColor: 'white',
                            color: 'red'
                          }}
                        >
                          {error[sub.nodeId]}
                        </span>
                      )}
                      {level > 0 && <Divider />}
                      {level > 0 && info?.isMainAdmin && (
                        <span style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
                          <FormControlLabel
                            sx={{ mx: 0, '& span': { fontSize: 12 } }}
                            control={
                              <Switch
                                checked={sub.hr}
                                onChange={(e) => {
                                  sub.hr = e.target.checked;
                                  setHeirarchy([...heirarchy]);
                                }}
                                size="small"
                              />
                            }
                            label="HR"
                          />
                          {(siteManagerLevel === level || siteManagerLevel === null) && (
                            <FormControlLabel
                              sx={{ mx: 0, '& span': { fontSize: 12 } }}
                              checked={node.siteManager}
                              control={
                                <Switch
                                  size="small"
                                  checked={sub.siteManager}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSiteManagerLevel(level);
                                    }
                                    sub.siteManager = e.target.checked;
                                    setHeirarchy([...heirarchy]);
                                  }}
                                />
                              }
                              label="Site Manager"
                            />
                          )}
                        </span>
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            )}
            <br />
            {edit && (
              <AddCircleIcon
                onClick={() => {
                  const n = uid();
                  insertNodeIntoTree(node, node?.nodeId, {
                    parents: [
                      {
                        nodeId: n,
                        pname: 'Enter Name',
                        siteManager: false,
                        tracking: false,
                        hr: false
                      }
                    ],
                    lvl: level + 1,
                    subroles: [],
                    child: []
                  });
                }}
              />
            )}
            {node.child.length > 0 && (
              <ul style={{ marginTop: `${node.subroles.length > 0 ? '3rem' : ''}` }}>
                {BuildTree(node.child, node, level + 1)}
              </ul>
            )}
          </li>
        ))}
      </>
    );
  };

  return (
    <Page title="Heirarchy | Trackkar">
      <Container sx={{ minHeight: 'calc(100vh - 9.5rem)' }}>
        <div className="tree">
          <ul style={{ width: '1000rem', overflow: 'auto' }}>{BuildTree(HEIRARCHY)}</ul>
        </div>
        <Button
          style={{ display: 'none' }}
          id="hierarchy_submit"
          onClick={() => handleStep1(HEIRARCHY[0])}
        >
          Database
        </Button>
      </Container>
    </Page>
  );
}
