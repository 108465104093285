// import { filter } from 'lodash';
import React, { useState, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
// import { styled } from '@mui/material/styles';
import {
  Container,
  Stack,
  Typography,
  Card,
  Button,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  // Popover,
  // TablePagination,
  Chip,
  // Divider,
  LinearProgress
} from '@mui/material';
// import MuiAccordion from '@mui/material/Accordion';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
// import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link as RouterLink } from 'react-router-dom';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// icons
// import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { ShiftListHead, ShiftListToolbar, ShiftMoreMenu } from '../components/_dashboard/shift';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
// hooks
import { useShifts } from '../hooks/get-shifts-query';
// Mutations
import AddShift from '../components/_dashboard/shift/AddShift';
// utils
import { SWAL_TIME } from '../utils/config';
import {
  getDataFromStorage,
  // setDataInStorage,
  _12hourTimeFormat,
  getCurrentDate
} from '../utils/functions';
// redux
import { logOut } from '../redux/actions/adminActions';
import { deleteShift } from '../api/mutations/adminMutations';

// ---------------------------------------------------------------

// const Accordion = styled((props) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   '&:not(:last-child)': {
//     borderBottom: 0
//   },
//   '&:before': {
//     display: 'none'
//   }
// }));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
//   flexDirection: 'row-reverse',
//   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//     transform: 'rotate(90deg)'
//   },
//   '& .MuiAccordionSummary-content': {
//     marginLeft: theme.spacing(1)
//   }
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderTop: '1px solid rgba(0, 0, 0, .125)'
// }));

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'start', label: 'Start', alignRight: false },
  { id: 'end', label: 'End', alignRight: false },
  { id: 'members', label: 'Members', alignRight: false },
  // { id: 'add_members', label: 'Action', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// --------------------------------------------------------------

function Shift() {
  const site = getDataFromStorage('selected_site');
  // let TOTAL_SHIFT_REQUIREMENT = 0;
  const cache = useQueryClient();
  const dispatch = useDispatch();
  const selectedSite = getDataFromStorage('selected_site');
  const { info } = useSelector((state) => state.adminInfo);
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAddShift, setOpenAddShift] = useState(false);
  const [editShift, setEditShift] = useState();
  // const [anchorEl, setAnchorEl] = useState({
  //   id: '',
  //   target: ''
  // });
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    date: ''
  });
  // const [expanded, setExpanded] = React.useState(); // Default 'panel1'
  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  /* ============================= Fetch All Shifts =============================== */

  const {
    isShiftsLoading,
    isShiftsError,
    data: shifts,
    fetchNextPage: fetchNextShifts,
    hasNextPage: hasNextShifts
    // refetch: refetchShifts
  } = useShifts(
    '',
    site?._id,
    filter.From,
    filter.to,
    filter.date.length > 0 ? filter.date : getCurrentDate()
  );

  const rows = useMemo(() => {
    const listOfShifts = [];
    if (!isShiftsLoading && !isShiftsError) {
      if (shifts?.pages?.length > 0) {
        shifts.pages.forEach((group) => listOfShifts.push(group.shifts));
      }
    }
    const flattened = listOfShifts.flat();
    // console.log('All Shifts:', flattened);
    return flattened;
  }, [shifts, isShiftsLoading, isShiftsError]);

  // rows.forEach((shift) => {
  //   TOTAL_SHIFT_REQUIREMENT += shift.requirement;
  // });

  // useEffect(() => {
  //   console.log('All Shifts :', rows);
  //   console.log('Total Shift Requirement :', TOTAL_SHIFT_REQUIREMENT);
  // }, [rows, TOTAL_SHIFT_REQUIREMENT]);

  /* ============================ Fetch All SHifts End ============================== */

  /* ================== Delete Shift ========================== */
  const deleteShiftMutation = useMutation(deleteShift, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: `Successfully Deleted!`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('sites');
    }
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const filteredUsers = rows;

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Shift | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Shifts
          </Typography>
          {info._id === selectedSite.manager._id ? (
            <Button
              variant="contained"
              // component={RouterLink}
              to="#"
              startIcon={<AddIcon />}
              onClick={() => {
                setEditShift(null);
                setOpenAddShift(true);
              }}
            >
              New Shift
            </Button>
          ) : null}
        </Stack>
        <Card>
          <ShiftListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
          />

          <TableContainer
            id="site-shift-table-container"
            sx={{ minWidth: 800, height: 'calc(100vh - 320px)' }}
          >
            <Table stickyHeader>
              <ShiftListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {isShiftsLoading && (
                  <TableRow>
                    <TableCell colSpan="9">
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )}
                {isShiftsError && (
                  <TableRow>
                    <TableCell>Something Went Wrong</TableCell>
                  </TableRow>
                )}
                {!isShiftsLoading &&
                  filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                              <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                            </TableCell> */}
                          <TableCell>{(index += 1)}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          {/* <TableCell align="center"> */}
                          {/* <Chip
                                label={`${row.requirement.reduce((a, r) => a + r.value, 0)}`}
                                onClick={(event) =>
                                  setAnchorEl({
                                    id: `open_requirements_${row._id}`,
                                    target: event.currentTarget
                                  })
                                }
                                icon={<ArrowForwardIosIcon sx={{ fontSize: 14 }} />}
                              /> */}
                          {/* <Popover
                                id={`open_requirements_${row._id}`}
                                open={anchorEl.id === `open_requirements_${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <Stack sx={{ width: 300, p: 1 }}>
                                  <Typography sx={{ mb: 1 }}>
                                    Requirements ( {row.name} )
                                  </Typography>
                                  <Divider sx={{ mb: 1 }} />
                                  <Stack sx={{ height: 150, overflowY: 'auto' }}>
                                    {row.requirement.length > 0
                                      ? row.requirement.map((req, index) => (
                                          <Stack key={`requirement_${req._id}`}>
                                            <Accordion
                                              key={`accordian_${req._id}`}
                                              expanded={expanded === `panel${index}`}
                                              onChange={handleChange(`panel${index}`)}
                                            >
                                              <AccordionSummary
                                                aria-controls="panel1d-content"
                                                id="panel1d-header"
                                              >
                                                <Typography>
                                                  {req.role.name} ( {req.value} )
                                                </Typography>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                {req.subroles.length > 0
                                                  ? req.subroles.map((sub) =>
                                                      sub.value > 0 ? (
                                                        <Stack
                                                          sx={{ px: 1, mt: 1 }}
                                                          key={`_${sub._id}`}
                                                          direction="row"
                                                          justifyContent="space-between"
                                                        >
                                                          <Typography>
                                                            {sub.subrole.name}
                                                          </Typography>
                                                          <Typography>{sub.value ?? 0}</Typography>
                                                        </Stack>
                                                      ) : null
                                                    )
                                                  : null}
                                              </AccordionDetails>
                                            </Accordion>
                                          </Stack>
                                        ))
                                      : 'Requirements Not Added'}
                                  </Stack>
                                </Stack>
                              </Popover> */}
                          {/* </TableCell>
                            <TableCell> */}
                          {/* <Chip
                                label={`${row.available.reduce((s, obj) => s + obj.total, 0)}`}
                                onClick={(event) =>
                                  setAnchorEl({
                                    id: `available_${row._id}`,
                                    target: event.currentTarget
                                  })
                                }
                                icon={<ArrowForwardIosIcon sx={{ fontSize: 14 }} />}
                              /> */}
                          {/* <Popover
                                id={`available_${row._id}`}
                                open={anchorEl.id === `available_${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <Stack sx={{ width: 300, p: 1 }}>
                                  <Typography sx={{ mb: 1 }}>Available ( {row.name} )</Typography>
                                  <Divider sx={{ mb: 1 }} />
                                  <Stack sx={{ height: 160, overflowY: 'auto' }}>
                                    {row.available.length > 0 ? (
                                      row.available.map((av, index) => (
                                        <Stack key={`available_${av.role._id}`}>
                                          <Accordion
                                            key={`accordian_avai_${av.role._id}`}
                                            expanded={
                                              av.subroles.length > 0
                                                ? expanded === `_panel${index}`
                                                : false
                                            }
                                            onChange={handleChange(`_panel${index}`)}
                                          >
                                            <AccordionSummary
                                              aria-controls="panel1d-content"
                                              id="panel1d-header"
                                            >
                                              <Typography>
                                                {av.role.name} ( {av.total ?? 0} )
                                              </Typography>
                                            </AccordionSummary>
                                            {av.subroles.length > 0 ? (
                                              <AccordionDetails>
                                                {av.subroles.map((sub) => (
                                                  <Stack
                                                    sx={{ px: 1, mt: 1 }}
                                                    key={`_${sub.subrole._id}`}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                  >
                                                    <Typography>{sub.subrole.name}</Typography>
                                                    <Typography>{sub.total ?? 0}</Typography>
                                                  </Stack>
                                                ))}
                                              </AccordionDetails>
                                            ) : null}
                                          </Accordion>
                                        </Stack>
                                      ))
                                    ) : (
                                      <Stack
                                        sx={{ height: 160 }}
                                        jusitfyContent="center"
                                        alignItems="center"
                                      >
                                        <Typography>Available Not Found</Typography>
                                      </Stack>
                                    )}
                                  </Stack>
                                </Stack>
                              </Popover> */}
                          {/* </TableCell>
                            <TableCell> */}
                          {/* <Chip
                                label={`${row?.vacancy?.reduce((s, obj) => s + obj.value, 0)}`}
                                onClick={(event) =>
                                  setAnchorEl({
                                    id: `vacancy_${row._id}`,
                                    target: event.currentTarget
                                  })
                                }
                                icon={<ArrowForwardIosIcon sx={{ fontSize: 14 }} />}
                              /> */}
                          {/* <Popover
                                id={`vacancy_${row._id}`}
                                open={anchorEl.id === `vacancy_${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <Stack sx={{ width: 300, p: 1 }}>
                                  <Typography sx={{ mb: 1 }}>Vacant ( {row.name} )</Typography>
                                  <Divider sx={{ mb: 1 }} />
                                  <Stack sx={{ height: 160, overflowY: 'auto' }}>
                                    {row.vacancy.length > 0
                                      ? row.vacancy.map((av, index) => (
                                          <Stack key={`vacancy_${av.role}`}>
                                            <Accordion
                                              key={`accordian_vcan_${av.role}`}
                                              expanded={expanded === `__panel${index}`}
                                              onChange={handleChange(`__panel${index}`)}
                                            >
                                              <AccordionSummary
                                                aria-controls="panel1d-content"
                                                id="panel1d-header"
                                              >
                                                <Typography>
                                                  {av.role.name} ( {av.value ?? 0} )
                                                </Typography>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                {av.subroles.length > 0
                                                  ? av.subroles.map((sub) => (
                                                      <Stack
                                                        sx={{ px: 1, mt: 1 }}
                                                        key={`_${sub._id}`}
                                                        direction="row"
                                                        justifyContent="space-between"
                                                      >
                                                        <Typography>{sub.subrole.name}</Typography>
                                                        <Typography>{sub?.value ?? 0}</Typography>
                                                      </Stack>
                                                    ))
                                                  : null}
                                              </AccordionDetails>
                                            </Accordion>
                                          </Stack>
                                        ))
                                      : 'Vacant Not Found'}
                                  </Stack>
                                </Stack>
                              </Popover> */}
                          {/* </TableCell> */}
                          <TableCell align="left">{_12hourTimeFormat(row.start)}</TableCell>
                          <TableCell align="left">{_12hourTimeFormat(row.end)}</TableCell>
                          <TableCell align="left">
                            <RouterLink
                              style={{ textDecoration: 'none' }}
                              to="/site/shifts/view_members"
                              state={{ shift: row }}
                            >
                              <Chip
                                label={`Show Members ( ${row?.employees?.length} )`}
                                variant="outlined"
                                clickable
                              />
                            </RouterLink>
                          </TableCell>
                          {/* <TableCell>
                              <Button
                                onClick={() => {
                                  setDataInStorage('selected_shift', row._id);
                                  setOpenAddShiftMembers(true);
                                }}
                              >
                                Add Members
                              </Button>
                            </TableCell> */}
                          <TableCell align="right">
                            <ShiftMoreMenu
                              editShift={() => {
                                setEditShift(row);
                                setOpenAddShift(true);
                              }}
                              deleteShift={() => {
                                Swal.fire({
                                  title: 'Are you sure?',
                                  text: `All Employees from shift will be removed!`,
                                  icon: 'question',
                                  showCancelButton: true,
                                  confirmButtonText: 'Yes, Proceed!',
                                  allowOutsideClick: false
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    deleteShiftMutation.mutate({
                                      // relocation: row.relocates[0]._id,
                                      employee: row._id
                                    });
                                    cache.invalidateQueries('shifts');
                                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                                    cache.invalidateQueries('shifts');
                                  }
                                });
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            {/* Put the scroll bar always on the bottom */}
            <InfiniteScroll
              dataLength={rows?.length}
              next={fetchNextShifts}
              // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
              // inverse //
              hasMore={hasNextShifts}
              loader={<h4>Loading...</h4>}
              scrollableTarget="site-shift-table-container"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            />
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
      <AddShift
        edit={editShift}
        roster={false}
        shiftSite={selectedSite}
        // totalShiftRequirement={TOTAL_SHIFT_REQUIREMENT}
        open={openAddShift}
        onClose={() => setOpenAddShift(false)}
      />
    </Page>
  );
}

export default Shift;
