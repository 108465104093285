import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/adminActions';
import { fetchPolicies } from '../api/queries/Queries';

const usePolicies = (roleId, search, from, to) => {
  const dispatch = useDispatch();
  return useInfiniteQuery(['policies', roleId, search, from, to], fetchPolicies, {
    getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }
    }
  });
};

export default usePolicies;
