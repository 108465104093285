import axios from 'axios';
import { baseApiURL } from '../../utils/config';
import { getDataFromStorage } from '../../utils/functions';

const getConfig = () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getDataFromStorage('adminAuthToken')}`
    }
  };
  return config;
};

export const changeAvatar = async (values) => {
  if (values) {
    const { data } = await axios.put(`${baseApiURL}/common/avatar`, values, getConfig());
    return data;
  }
  throw new Error('No data to send');
};

export const updateAdminDetails = async (values) => {
  if (values) {
    // console.log('values: ', JSON.stringify(values, null, '\t'));
    const { data } = await axios.put(`${baseApiURL}/common/admin`, values, getConfig());
    return data;
  }
  throw new Error('No data to send');
};

export const updateEmployeeDetails = async (values) => {
  if (values) {
    // console.log('values: ', JSON.stringify(values, null, '\t'));
    const { data } = await axios.put(`${baseApiURL}/common/employee`, values, getConfig());
    return data;
  }
  throw new Error('No data to send');
};

export const changePassword = async (values) => {
  if (values) {
    // console.log('values: ', JSON.stringify(values, null, '\t'));
    const { data } = await axios.put(`${baseApiURL}/common/password`, values, getConfig());
    return data;
  }
  throw new Error('No data to send');
};
