import { filter } from 'lodash';
// import * as Yup from 'yup';
// import { sentenceCase } from 'change-case';
import { useState, useMemo, useEffect, useRef } from 'react';
// import { useDebounce } from 'debounce';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import { useDebounce } from 'use-debounce';
// import { Link as RouterLink } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// material
import {
  Card,
  Chip,
  Box,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  // TablePagination,
  Select,
  InputLabel,
  OutlinedInput,
  FormControl,
  // TextField,
  LinearProgress,
  CircularProgress,
  MenuItem
} from '@mui/material';
// icons
import InfoIcon from '@mui/icons-material/Info';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
import ContactsIcon from '@mui/icons-material/Contacts';
// import classes from './styles/ImportEmployees.module.css';
// hooks
import { useUnmanagedEmployees } from '../hooks/employees';
import { useRoles } from '../hooks/roles';
import { useSubRoles } from '../hooks/subRoles';
import { useAllDepartments } from '../api/queries/department';
// components
import { ImageZoom } from '../components';
import Page from '../components/Page';
// import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
import { EmployeeMoreMenu } from '../components/_dashboard/employee';
import {
  ImportEmployeeListToolbar,
  ImportEmployeeListHead
} from '../components/_dashboard/import_employee';
import UploadEmployees from '../components/_dashboard/import_employee/UploadEmployees';
// utils
import {
  DD_MM_YYYY,
  // excelToJSON,
  // capitalizeEachWord,
  // getDataFromStorage,
  setDataInStorage
} from '../utils/functions';
import { SWAL_TIME } from '../utils/config';
// Mutations
import AddEmployee from '../components/_dashboard/employee/AddEmployee';
import useSites from '../hooks/get-sites-query';
// import { assignPolicy } from '../api/mutations/policy';
import {
  assignRole,
  assignSite,
  assignManager
  // importEmployees
} from '../api/mutations/adminMutations';
// redux
// import callSocket from '../utils/socket';
import { logOut } from '../redux/actions/adminActions';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'employee', label: 'Employee', alignRight: false },
  { id: 'gender', label: 'Gender', alignRight: false },
  { id: 'doj', label: 'Joining', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'manager', label: 'Manager / Sites', alignRight: false },
  { id: '' }
];

// const WEEK_DAYS = {
//   mon: 'Monday',
//   tue: 'Tuesday',
//   wed: 'Wednesday',
//   thu: 'Thrusday',
//   fri: 'Friday',
//   sat: 'Saturday',
//   sun: 'Sunday'
// };

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ImportEmployees() {
  const [
    page
    // setPage
  ] = useState(0);
  const navigate = useNavigate();
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [imageSrc, setImageSrc] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [
    rowsPerPage
    // setRowsPerPage
  ] = useState(5);
  const [anchorEl, setAnchorEl] = useState({
    id: '',
    target: ''
  });
  const [openAddEmployee, setOpenAddEmployee] = useState(false);

  const dispatch = useDispatch();
  const cache = useQueryClient();
  const selectRoleRef = useRef();
  // const [isImportingEmployees, setIsImportingEmployees] = useState(false);
  const [roleOptions, setRoleOptions] = useState(); // Roles initial State for select
  // const [role, setRole] = useState('');
  // const [siteManager, setSiteManager] = useState();
  const [siteManagerPriority, setSiteManagerPriority] = useState();
  const [_siteManager, _setSiteManager] = useState({});
  const [allAvailableRoles, setAllAvailableRoles] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [siteDict, setSiteDict] = useState({});
  const [siteName, setSiteName] = useState({});
  const [subRoleOptions, setSubRoleOptions] = useState([]);
  const [search] = useDebounce(filterName, 1000); // Debounce value 1 sec

  // -----------------------------------------------------------------
  // const [importCheck, setImportCheck] = useState({
  //   importId: getDataFromStorage('importId') ?? null,
  //   importLength: getDataFromStorage('importLength') ?? null
  // });
  // const [isValidating, setIsValidating] = useState(''); // Upon excel validation
  // const [inMutate, setInMutate] = useState(false);
  const { info } = useSelector((state) => state.adminInfo);
  // ------------------------------------------------------------------

  const [filter, setFilter] = useState({
    doj: '',
    state: '',
    gender: '',
    role: '',
    subRole: '',
    manager: '',
    policy: ''
  });

  useEffect(() => {
    console.log('Filters:', filter);
  }, [filter]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const { isLoading: isDeptLoading, isError: isDeptError, data } = useAllDepartments();

  const allDepartments = useMemo(() => {
    const DEPT_LIST = {};
    if (!isDeptLoading && !isDeptError) {
      data.departments.forEach((dep) => {
        DEPT_LIST[dep.name] = dep._id;
      });
    }
    return DEPT_LIST;
  }, [data, isDeptError, isDeptLoading]);

  /* ===================== Roles ===================== */
  const { isLoading: isRolesLoading, isError: isRolesError, data: roles } = useRoles(); // gettings roles

  // eslint-disable-next-line consistent-return
  const allRoles = useMemo(() => {
    if (!isRolesLoading && !isRolesError) {
      return roles.heirarchy;
    }
  }, [roles, isRolesLoading, isRolesError]);

  useEffect(() => {
    const allAvailableRoles = [];
    const formattedRoles = [];
    // console.log('All Available Roles', allRoles);
    if (allRoles?.length > 0) {
      let priority = 0;
      allRoles.forEach((role) => {
        // console.log('Get Role:', role);
        allAvailableRoles.push([role._id, role.name]);
        if (role.is_site_manager) {
          priority = role.priority;
          setSiteManagerPriority(priority);
        }
        if (priority === 0 || role.priority === priority || info?.isMainAdmin) {
          formattedRoles.push([role._id, role.name]);
        }
      });
    }
    setRoleOptions([...formattedRoles]);
    setAllAvailableRoles([...allAvailableRoles]); // All Available Roles in Heirarchy
  }, [allRoles, info]);

  /* ================= End Roles =============== */

  /* ================== Unemployees ========================== */
  const {
    isLoading: isImportEmployeesLoading,
    isError: isImportEmployeesError,
    data: unemployees,
    fetchNextPage: fetchNextImportemployees,
    hasNextPage: hasNextImportemployees
  } = useUnmanagedEmployees(
    null,
    search,
    null, //  state,
    null, //   policyId
    filter.role.length > 0 ? filter.role.split(',')[0] : undefined, // role
    filter.subRole.length > 0 ? filter.subRole.split(',')[0] : undefined
  );

  // getting Unmanaged employyes

  //   useEffect(() => {
  //     if (unemployeesearch || unemployeedojFilter) {
  //       refetchUnemployees();
  //     }
  //   }, [unemployeesearch, unemployeedojFilter, refetchUnemployees]);

  const rows = useMemo(() => {
    const listOfEmployees = [];
    if (!isImportEmployeesLoading && !isImportEmployeesError) {
      if (unemployees?.pages?.length > 0) {
        unemployees.pages.forEach((group) => listOfEmployees.push(group.employees));
      }
    }
    const flattened = listOfEmployees.flat();
    // console.log('Import Employees:', flattened);
    return flattened;
  }, [unemployees, isImportEmployeesLoading, isImportEmployeesError]);

  const handleClick = (event, name) => {
    setSelectedRole(rows.find((r) => r._id === name).role._id);
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  /* ================= End Unmanaged Employees =============== */

  /* ==================== Import Employees Mutations ================== */
  // const { mutate } = useMutation(importEmployees, {
  //   onMutate: (mutateData) => {
  //     setInMutate(true);
  //     setDataInStorage('importId', '');
  //     setImportCheck({
  //       ...importCheck,
  //       importId: mutateData.importId,
  //       importLength: mutateData.employees.length
  //     });
  //     setDataInStorage('importId', mutateData.importId);
  //     setDataInStorage('importLength', mutateData.employees.length);
  //   }
  // });

  // useEffect(() => {
  //   if (importCheck?.importId?.length > 0) {
  //     setIsValidating('');
  //     // client-side
  //     const socket = callSocket();
  //     const swal = Swal.fire({
  //       icon: 'info',
  //       title: 'Hold on....',
  //       text: 'Onboarding employees :)',
  //       allowOutsideClick: false,
  //       showConfirmButton: false,
  //       html: `<div style="display:block;">
  //               <p style="text-align:center;margin-bottom:10px;"><strong>Starting...</strong><p>
  //               <div style="display:flex;justify-content:center;align-items:center;">
  //                 <div class="custom_progress progress--horizontal-soft">
  //                   <div class="custom_progress__bar"></div>
  //                 </div>
  //                 &emsp;
  //                 <div id="import_completed" class="import_percentage"></div>
  //               </div>
  //               <div class="import_progress">
  //                 <p>Error</p><div id="import_error">0</div>
  //                 <p>Existing</p><div id="import_existing">0</div>
  //                 <p>Uploaded</p><div id="import_inserted">0</div>
  //               </div>
  //               </div>`,
  //       didOpen: () => {
  //         const content = Swal.getHtmlContainer();
  //         const progressBars = content.querySelectorAll('.custom_progress__bar');
  //         function setProgress(progress) {
  //           progressBars.forEach((bar) => {
  //             Object.assign(bar.style, {
  //               transform: `scaleX(${progress / 100})`,
  //               /* standard & vertical */
  //               'background-position-y': `${progress.toString()}%`,
  //               /* horizontal */
  //               'background-position-x': `${progress.toString()}%`
  //             });
  //           });
  //         }
  //         const er = content.querySelector('strong');
  //         socket.on('disconnect', (reason) => {
  //           if (!navigator.onLine) {
  //             er.textContent = `Connection Lost!!, reconnecting...`;
  //           } else if (
  //             reason === 'io server disconnect' ||
  //             reason === 'ping timeout' ||
  //             reason === 'transport error'
  //           ) {
  //             // the disconnection was initiated by the server, you need to reconnect manually
  //             socket.connect();
  //           } else if (reason === 'transport close' && navigator.onLine) {
  //             er.textContent = `Error Uploading Data!!`;
  //             setDataInStorage('importId', '');
  //             setDataInStorage('importLength', '');
  //           }
  //         });
  //         // window.onbeforeunload = () => true;
  //         socket.on('progress', (status) => {
  //           if (
  //             status.type === 'importEmployees' &&
  //             status.user === info._id &&
  //             status.importId === importCheck.importId
  //           ) {
  //             // const $ = content.querySelector.bind(content);
  //             const dataLength = importCheck.importLength;
  //             const sum = status.error + status.existing + status.inserted;
  //             const progress = (sum * 100) / dataLength;
  //             setProgress(progress);
  //             content.querySelector('strong').textContent = `${sum} / ${dataLength}`;
  //             content.querySelector('#import_error').textContent = `${status.error}`;
  //             content.querySelector('#import_existing').textContent = `${status.existing}`;
  //             content.querySelector('#import_inserted').textContent = `${status.inserted}`;
  //             content.querySelector('#import_completed').textContent = `${parseFloat(
  //               progress
  //             ).toFixed(0)}%`;
  //             if (progress === 100) {
  //               socket.disconnect();
  //               setDataInStorage('importId', '');
  //               setDataInStorage('importLength', '');
  //               if (!inMutate) {
  //                 Swal.fire({
  //                   icon: 'success',
  //                   title: 'Successfull',
  //                   html: `<span>Successfully Completed!!<div style="color: red; font-weight: bold;margin: 0.5rem 0">IMPORTANT</div><mark>Please update the profile picture of the added employees with their real faces for our facial-recognition based attendace system to work properly. Otherwise, they won't be able to mark the attendance.</mark><br/><br/>
  //                               Employees can login to their respective accounts by using mobile numbers as their initial password.\nFeel free to change it whenever you want from employee profile.</span>`
  //                 });
  //               }
  //             }
  //           } else {
  //             socket.disconnect();
  //             setDataInStorage('importId', '');
  //             setDataInStorage('importLength', '');
  //             setTimeout(() => {
  //               swal.close();
  //             }, 1000);
  //           }
  //         });
  //       }
  //     });
  //   }
  // }, [importCheck, info, inMutate]);

  // useEffect(() => {
  //   if (isValidating.length > 0) {
  //     Swal.fire({
  //       icon: 'info',
  //       title: 'Hold on....',
  //       text: `${isValidating === 'Validating' ? 'Validating File... 😃' : 'Reading File... 😃'}`,
  //       allowOutsideClick: false,
  //       showConfirmButton: false,
  //       willOpen: () => {
  //         Swal.showLoading();
  //       }
  //     });
  //   }
  // }, [isValidating]);

  // const handleBulkUpload = useCallback(
  //   async (files, roleOptions, subRoleOptions, aRoles) => {
  //     // console.log('All Roles', aRoles);
  //     const roleNames = roleOptions?.map((role) => role[1].toLowerCase());
  //     // const subRoles = subRoleOptions?.map((srole) => srole);
  //     const Dict = {};
  //     // eslint-disable-next-line prefer-destructuring
  //     roleOptions?.forEach((role) => (Dict[role[1].toLowerCase()] = role[0]));
  //     // console.log(files[0]);
  //     // console.log('Dict:', Dict);
  //     const subRoles = {};
  //     subRoleOptions.map((sr) => (subRoles[sr.id] = Object.keys(sr.subroles)));
  //     // console.log('Sub Role Options', subRoleOptions);
  //     // console.log('Subroles', subRoles);
  //     const excelFile = files[0];
  //     if (excelFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
  //       // console.log("Only Excel files please");
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Unsupported Format!',
  //         text: 'Please upload a valid excel document with the proper format provided in the template.'
  //         // timer: 4000,
  //         // timerProgressBar: true
  //       });
  //     } else {
  //       setIsValidating('Reading');
  //       const excelRows = await excelToJSON(excelFile);
  //       const result = excelRows.map((row) => {
  //         const temp = {
  //           // _id: Date.now().toString(),
  //           name: capitalizeEachWord(row.Name?.trim()),
  //           gender: row.Gender?.toString().trim(),
  //           contact: row['Mobile (+91)']?.toString()?.trim(),
  //           password: row['Mobile (+91)']?.toString()?.trim(),
  //           email: row['E-mail (Optional)']?.toLowerCase()?.trim(),
  //           doj: row['Date of joining (DD-MM-YYYY)']
  //             ?.toString()
  //             .split('-')
  //             .reverse()
  //             .join('-')
  //             ?.trim(),
  //           dob: row['Date of birth (DD-MM-YYYY)']
  //             ?.toString()
  //             .split('-')
  //             .reverse()
  //             .join('-')
  //             ?.trim(),
  //           address: capitalizeEachWord(row.Address)?.trim(),
  //           admin: aRoles[0]?.admin,
  //           avatar: AVATAR,
  //           _role: row.Role?.toString()?.toLowerCase().trim(),
  //           subrole: row['Sub Role']?.trim().toLowerCase(),
  //           vehicle_type: row['Vehicle Type']
  //             ? capitalizeEachWord(row['Vehicle Type'])?.trim()
  //             : null
  //         };
  //         return temp;
  //       });
  //       // console.log('Result:', result);
  //       // console.log(roleNames);

  //       const validationErrors = [];
  //       setIsValidating('Validating');
  //       // eslint-disable-next-line no-restricted-syntax
  //       for (const [index, record] of result.entries()) {
  //         // console.log(Dict[record._role.replace(' ', '-')]);
  //         const ro = Dict[record._role.replace(' ', '-')];
  //         setRole(Dict[record._role.replace(' ', '-')]);
  //         // excel file data validationSchema
  //         const validationSchema = Yup.object({
  //           name: Yup.string()
  //             .required('Name is required')
  //             .typeError("Please provide employee's name")
  //             .matches(
  //               /^[a-zA-Z][a-zA-Z\s]*$/,
  //               'Invalid employee name. ( Only alphabets & whitespaces are allowed )'
  //             ),
  //           gender: Yup.string()
  //             .required('Gender is required')
  //             .typeError('Please provide a gender for the employee')
  //             .oneOf(
  //               ['Male', 'Female', 'Other'],
  //               "Only 'Male', 'Female' and 'Other' are allowed as gender"
  //             ),
  //           email: Yup.string()
  //             .notRequired()
  //             .typeError("Please provide employee's valid email address")
  //             .email('Invalid email address.'),
  //           contact: Yup.string()
  //             .required('Mobile number is required')
  //             .typeError("Please provide employee's mobile number without country code")
  //             .matches(/^[6-9]\d{9}$/, 'Invalid mobile number, add number without country code'),
  //           dob: Yup.string()
  //             .required('Date of birth is required')
  //             .typeError("Please provide employee's date of birth in DD-MM-YYYY format")
  //             .matches(
  //               /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/,
  //               'Invalid date of birth, desired format is DD-MM-YYYY'
  //             ),
  //           doj: Yup.string()
  //             .required('Date of joining is required')
  //             .typeError("Please provide employee's date of joining in DD-MM-YYYY format")
  //             .matches(
  //               /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/,
  //               'Invalid date of joining, desired format is DD-MM-YYYY'
  //             ),
  //           address: Yup.string()
  //             .required('Address is required')
  //             .typeError("Please provide employee's address"),
  //           vehicle_type: Yup.string()
  //             .required('Vehicle type is required')
  //             .typeError('Please provide a vehicle type for the employee')
  //             .oneOf(
  //               ['Two-wheeler', 'Four-wheeler'],
  //               "Only 'Two-wheeler' or 'Four-wheeler' is allowed as vehicle type"
  //             ),
  //           _role: Yup.string()
  //             .required('Role is required')
  //             .typeError('Please provide a role for the employee')
  //             .oneOf(roleNames, 'Only Sepcified roles in hirarchy is allowed as Role'),
  //           subrole: Yup.string()
  //             .notRequired()
  //             .typeError('Please provide a sub role for the employee')
  //             .oneOf(
  //               subRoles[ro] ?? [],
  //               `This Sub Role is not valid, Available Sub Roles are: ${subRoles[ro]?.toString()}`
  //             )
  //           // .test(
  //           //   'subrole-requirement-test',
  //           //   `Requirement Must be less then Equal to, Or Increase Current Site requirement`,
  //           //   (value) => {
  //           //     console.log(Yup.ref('_role'));
  //           //     console.log(ro);
  //           //     console.log(subRoles[role]);
  //           //     if (!subRoles[role].includes(value)) {
  //           //       console.log(!subRoles[role].includes(value));
  //           //       // console.log(value);
  //           //       // console.log(subRoleOptions);
  //           //       // console.log(role);
  //           //       return true;
  //           //     }
  //           //     return false;
  //           //   }
  //           // )
  //         });
  //         // eslint-disable-next-line no-await-in-loop
  //         await validationSchema
  //           .validate(record, { abortEarly: false })
  //           .catch((validationError) => {
  //             // console.log("validation-error: ", validationError);
  //             validationErrors.push({
  //               rowNumber: index + 2,
  //               message: validationError.message,
  //               errors: validationError.errors
  //             });
  //           });
  //       }

  //       if (validationErrors.length === 0) {
  //         const employees = [];
  //         const dict = {};
  //         subRoleOptions
  //           .map((sr) => sr.subroles)
  //           .forEach((sr) => {
  //             // eslint-disable-next-line no-restricted-syntax
  //             for (const [key, value] of Object.entries(sr)) {
  //               dict[key] = value;
  //             }
  //           });
  //         // console.log('dict', dict[0]);
  //         result.forEach((res, index) => {
  //           res.role = Dict[res._role];
  //           delete res._role;
  //           const l = aRoles.find((rol) => rol._id === res.role);
  //           res.policy = l.default_policy;
  //           res.subrole = dict[res.subrole];
  //           if (l.priority === 1) {
  //             res.managerModel = 'Admin';
  //             res.managers = [info._id];
  //           }
  //           if (l.priority > info?.role?.priority || info?.isMainAdmin) {
  //             employees.push(res);
  //           }
  //         });
  //         const data = {
  //           employees,
  //           importId: Date.now().toString()
  //         };
  //         // console.log(data);
  //         mutate(data, {
  //           onError: (error) => {
  //             setDataInStorage('importId', '');
  //             setDataInStorage('importLength', '');
  //             // for unauthorized access
  //             if (error.response && error.response.status === 401) {
  //               dispatch(logOut());
  //             }
  //             Swal.fire({
  //               icon: 'error',
  //               title: 'Something went wrong!',
  //               text: error.response
  //                 ? error.response.data.message || error.toString()
  //                 : error.toString()
  //             });
  //           },
  //           onSuccess: (data) => {
  //             // cache.invalidateQueries("unmanaged-employees");
  //             setDataInStorage('importId', '');
  //             setDataInStorage('importLength', '');
  //             if (data?.existing?.length > 0) {
  //               Swal.fire({
  //                 icon: 'warning',
  //                 title: 'Warning',
  //                 text: data.message,
  //                 confirmButtonText: 'Show details'
  //               }).then((result) => {
  //                 /* Read more about isConfirmed, isDenied below */
  //                 if (result.isConfirmed) {
  //                   const errors = data?.existing;
  //                   Swal.fire({
  //                     didRender: (data) => {
  //                       // to remove "," inserted automatically by swal2 after each list item
  //                       const ul = data.querySelector(`.${classes.import__err_ul}`);
  //                       const nodes = ul.childNodes;
  //                       nodes.forEach((node) => {
  //                         if (node.nodeType === Node.TEXT_NODE) {
  //                           node.parentNode.removeChild(node); // removing any text content ohter than list-item
  //                         }
  //                       });
  //                       // removing extra margin from action buttons
  //                       const actions = document.querySelector('.swal2-actions');
  //                       actions.style.marginTop = 0;
  //                     },
  //                     html: `<h5>Following issues were encountered while adding clients</h5>
  //                                 <ul class=${classes.import__err_ul}>
  //                                 ${errors.map(
  //                                   (resErr, index) =>
  //                                     `<li class=${classes.import__err_li}>
  //                                     <span>${(index += 1)}.&nbsp;</span>
  //                                     <span class=${classes.import__err_msg}>${resErr.error}</span>
  //                                     </li>`
  //                                 )}
  //                                 </ul>`
  //                   });
  //                 }
  //               });
  //             } else {
  //               Swal.fire({
  //                 icon: 'success',
  //                 title: 'Successfull',
  //                 html: `<span>${data.message}<div style="color: red; font-weight: bold;margin: 0.5rem 0">IMPORTANT</div><mark>Please update the profile picture of the added employees with their real faces for our facial-recognition based attendace system to work properly. Otherwise, they won't be able to mark the attendance.</mark><br/><br/>
  //                             Employees can login to their respective accounts by using mobile numbers as their initial password.\nFeel free to change it whenever you want from employee profile.</span>`
  //               });
  //             }
  //           },
  //           onSettled: () => {
  //             setIsValidating('');
  //             document.getElementById('import-employees-file').value = null;
  //             cache.invalidateQueries('unmanaged-employees');
  //           }
  //         });
  //       } else {
  //         setIsValidating('');
  //         document.getElementById('import-employees-file').value = null;
  //         Swal.fire({
  //           didRender: (data) => {
  //             // to remove "," inserted automatically by swal2 after each list item
  //             const ul = data.querySelector(`.${classes.import__err_ul}`);
  //             const innerUls = data.querySelectorAll(`.${classes.import__err_inner_ul}`);

  //             const nodes = ul.childNodes;

  //             nodes.forEach((node) => {
  //               if (node.nodeType === Node.TEXT_NODE) {
  //                 node.parentNode.removeChild(node); // removing any text content ohter than list-item
  //               }
  //             });

  //             innerUls.forEach((list) => {
  //               const nodes = list.childNodes;

  //               nodes.forEach((node) => {
  //                 if (node.nodeType === Node.TEXT_NODE) {
  //                   node.parentNode.removeChild(node); // removing any text content ohter than list-item
  //                 }
  //               });
  //             });

  //             // removing extra margin from action buttons
  //             const actions = document.querySelector('.swal2-actions');
  //             actions.style.marginTop = 0;
  //           },
  //           html: `<h6>Fix the following issues in your excel file (see instructions file for more details)</h6>
  //               <ul class=${classes.import__err_ul}>
  //               ${validationErrors.map(
  //                 (resErr) =>
  //                   `<li class=${classes.import__err_li}>
  //                   <span class=${classes.import__err_number}>Row-${resErr.rowNumber}: </span>
  //                   <span class=${classes.import__err_msg}>
  //                   ${resErr.message}
  //                   ${
  //                     resErr?.errors?.length > 1
  //                       ? `
  //                     <ul class=${classes.import__err_inner_ul}>
  //                     ${resErr.errors.map(
  //                       (er) => `
  //                   <li>
  //                   ${er}
  //                   </li>
  //                   `
  //                     )}
  //                     </ul>
  //                     `
  //                       : ''
  //                   }
  //                   </span>
  //                   </li>`
  //               )}
  //               </ul>`
  //         });
  //       }
  //     }
  //   },
  //   [dispatch, mutate, cache]
  // );

  // /* ================= Assign Policy Mutation ============== */
  // const assignPolicyMutation = useMutation(assignPolicy, {
  //   onError: (error) => {
  //     // status: 401 unauthorized
  //     if (error.response && error.response.status === 401) {
  //       dispatch(logOut());
  //     }
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Something went wrong!',
  //       text: error.response ? error.response.data.message || error.toString() : error.toString(),
  //       timer: 2000,
  //       timerProgressBar: true
  //     });
  //   },
  //   onSuccess: () => {
  //     Swal.fire({
  //       icon: 'success',
  //       title: `Successfully assigned policy`,
  //       showConfirmButton: false,
  //       timer: 2000,
  //       timerProgressBar: true
  //     });
  //   },
  //   onSettled: () => {
  //     cache.invalidateQueries('employees');
  //   }
  // });

  /* ===================== Assign Role Mutations ==============  */

  const assignRoleMutation = useMutation(assignRole, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      setSelected([]);
      Swal.fire({
        icon: 'success',
        title: `Successfully assigned role`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('unmanaged-employees');
    }
  });

  /* ===================== Assign Role Mutations END  ==============  */

  /* ===================== Assign Manager Mutations ==============  */

  const assignManagerMutation = useMutation(assignManager, {
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      setSelected([]);
      Swal.fire({
        icon: 'success',
        title: `Successfully assigned manager`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('unmanaged-employees');
    }
  });

  /* ===================== Assign Manager Mutations END ==============  */

  /* ===================== Sites ===================== */

  const {
    isLoading,
    isError,
    // fetchNextPage: fetchNextSites,
    // hasMoreData: hasNextSites,
    data: sites
    // refetch: refetchSites
  } = useSites(
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[0], true) : null, // from
    null, // filter.range?.length > 0 ? convertUTCtoLocal(filter.range[1], true) : null, // to
    null, // filter.date.length > 0 ? filter.date : getCurrentDate(),
    null, // type
    null, // filter.state, // state
    null, // filter.status, // status
    null, // filter.manager, // manager
    null, // filter.role,
    search, // search
    null
  );

  const allSites = useMemo(() => {
    const listOfSites = [];
    if (!isLoading && !isError) {
      if (sites?.pages?.length > 0) {
        sites.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    const flattened = listOfSites.flat();
    // console.log('Sites :', flattened);
    return flattened;
  }, [sites, isLoading, isError]);

  useEffect(() => {
    const formattedSites = [];
    if (allSites?.length > 0) {
      allSites.forEach((site) => {
        if (site.active === 'Active') {
          _siteManager[site._id] = site.manager._id;
          siteDict[site._id] = site.name;
          formattedSites.push([site._id, site.name, site.manager._id]);
        }
      });
      _setSiteManager({ ..._siteManager });
      setSiteDict({ ...siteDict }); // Site Value Names
      setSiteOptions([...formattedSites]);
      // console.log('Role Options :', formattedRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSites]);

  // eslint-disable-next-line consistent-return
  const selectedSite = useMemo(() => {
    if (selected.length > 0) {
      const sel = rows.find((emp) => emp._id === selected[0])?.sites[0] ?? '';
      return sel;
    }
  }, [rows, selected]);

  /* ================= End Sites =============== */

  const { isLoading: isSubRolesLoading, isError: isSubRolesError, data: subRoles } = useSubRoles();

  useEffect(() => {
    const listOfSubRoles = [];
    if (!isSubRolesLoading && !isSubRolesError) {
      if (subRoles?.roles?.length > 0) {
        subRoles.roles.forEach((role) => {
          const d = {};
          role.subroles.filter((s) => (d[s.name.toLowerCase()] = s._id));
          listOfSubRoles.push({
            id: role._id,
            subroles: d
          });
        });
      }
    }
    setSubRoleOptions(listOfSubRoles);
    // console.log('Sub Roles By Role:', listOfSubRoles);
  }, [subRoles, isSubRolesLoading, isSubRolesError]);

  /* ================== End Sub Roles ====================== */

  const { mutate: assignSiteMutation } = useMutation(assignSite, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Adding Members to site 😃',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    },
    onError: (error) => {
      // status: 401 unauthorized
      if (error.response && error.response.status === 401) {
        dispatch(logOut());
      }

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: error.response ? error.response.data.message || error.toString() : error.toString()
        // timer: 2000,
        // timerProgressBar: true
      });
    },
    onSuccess: () => {
      setSelected([]);
      Swal.fire({
        icon: 'success',
        title: `Successfully Added ${selected.length > 1 ? 'Employees' : 'Employee'} to Site`,
        showConfirmButton: false,
        timer: SWAL_TIME,
        timerProgressBar: true
      });
    },
    onSettled: () => {
      cache.invalidateQueries('potential-site-members');
      cache.invalidateQueries('unmanaged-employees');
    }
  });

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Employees | Trackkar">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1.5}>
          <Typography variant="h4" gutterBottom>
            Un-Assigned
            <Typography
              component="span"
              sx={{ height: 20, ml: 1, fontSize: 13, fontWeight: 'bold' }}
            >
              ( {unemployees?.pages[0]?.total ?? 0} )
            </Typography>
          </Typography>
          <Box>
            {!isRolesLoading && !isSubRolesLoading && !isDeptLoading ? (
              <UploadEmployees
                allRoles={allRoles}
                allAvailableRoles={allAvailableRoles}
                subRoleOptions={subRoleOptions}
                allDepartments={allDepartments}
              />
            ) : null}
            {/* <TextField
              sx={{ display: 'none' }}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              id="import-employees-file"
              onChange={(event) =>
                handleBulkUpload(event.target.files, allAvailableRoles, subRoleOptions, allRoles)
              }
              type="file"
            />
            <Button
              variant="contained"
              component="span"
              startIcon={<Icon icon={plusFill} />}
              onClick={() => {
                Swal.fire({
                  title: 'Import employees in bulk',
                  html: `<span>From here you can import all of your employees in bulk, please refer <a href=${TRACKKAR_BULK_UPLOAD_FILES} download style='text-transform: underline'>this</a> for the upload format.</span>`,
                  icon: 'info',
                  showCancelButton: true,
                  confirmButtonText: 'Upload file'
                }).then((result) => {
                  if (result.isConfirmed) {
                    document.getElementById('import-employees-file').click();
                  }
                });
              }}
            >
              Import
            </Button> */}
          </Box>
        </Stack>
        <Card sx={{ boxShadow: 'none' }}>
          <ImportEmployeeListToolbar
            numSelected={selected.length}
            filterName={filterName}
            isSelected={selected}
            selectedRole={selectedRole}
            onFilterName={handleFilterByName}
            setFilters={setFilter}
            filters={filter}
            assignRole={assignRoleMutation}
            assignManager={assignManagerMutation}
            assignSite={assignSiteMutation}
            siteOptions={siteOptions}
            selectedSite={selectedSite}
            siteDict={siteDict}
            siteManager={_siteManager}
            smPriority={siteManagerPriority}
          />
          <TableContainer
            id="import-employees-table-container"
            sx={{
              minWidth: 800,
              minHeight: 'calc(100vh - 275px)',
              maxHeight: 'calc(100vh - 275px)'
            }}
          >
            <Table stickyHeader>
              <ImportEmployeeListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {isImportEmployeesLoading && (
                  <TableRow>
                    <TableCell colSpan="9">
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!isImportEmployeesLoading &&
                  filteredUsers
                    .sort((a, b) => a.role.priority - b.role.priority)
                    .map((row) => {
                      const isItemSelected = selected.indexOf(row?._id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              disabled={
                                (selectedSite
                                  ? selected.length > 0 &&
                                    row.sites.length > 0 &&
                                    !row.sites.includes(selectedSite)
                                  : selected.length > 0 && row.sites.length > 0) ||
                                (selected.length > 0 && selectedRole
                                  ? !(selectedRole === row.role._id)
                                  : false)
                              }
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row?._id)}
                            />
                          </TableCell>
                          {/* <TableCell>{(index += 1)}</TableCell> */}
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar
                                onClick={() => setImageSrc(row.avatar)}
                                alt={row.name}
                                src={row.avatar}
                                sx={{ cursor: 'pointer' }}
                              />
                              <Stack>
                                <Stack direction="row" alignItems="center">
                                  <Tooltip
                                    sx={{ fontSize: '1rem' }}
                                    title={`Visit ${row.name}`}
                                    placement="top"
                                  >
                                    <Typography
                                      noWrap
                                      sx={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setDataInStorage('selected_employee', row);
                                        navigate('/employee/profile');
                                      }}
                                    >
                                      {row.name}
                                    </Typography>
                                  </Tooltip>
                                  &nbsp;
                                  <Chip
                                    size="small"
                                    sx={{ fontSize: 12, height: 18 }}
                                    label={row.gender.charAt(0)}
                                  />
                                  &nbsp;
                                  <InfoIcon
                                    sx={{ cursor: 'pointer', fontSize: 18 }}
                                    onClick={(event) =>
                                      setAnchorEl({
                                        id: `info_${row._id}`,
                                        target: event.currentTarget
                                      })
                                    }
                                  />
                                </Stack>
                                <Box>
                                  <Typography sx={{ color: '#3366FF', fontSize: 11 }}>
                                    <b>{row.role.name}</b>
                                  </Typography>
                                  {row?.subrole ? (
                                    <Typography sx={{ color: '#DC7633', fontSize: 11 }}>
                                      <b>{row.subrole.name}</b>
                                    </Typography>
                                  ) : null}
                                </Box>
                              </Stack>
                              <Popover
                                id={`info_${row._id}`}
                                open={anchorEl.id === `info_${row._id}`}
                                anchorEl={anchorEl.target}
                                onClose={() => setAnchorEl({ id: null, target: null })}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <List>
                                  {row.contact && (
                                    <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <PhoneAndroidIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={`+${row?.country_code} ${row.contact}`}
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  )}
                                  {row.email && (
                                    <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <AlternateEmailIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`${row.email}`} />
                                      </ListItemButton>
                                    </ListItem>
                                  )}
                                  {/* {row.state && (
                                      <ListItem disablePadding>
                                        <ListItemButton>
                                          <ListItemIcon>
                                            <LocationCityIcon />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={`${row?.state ?? 'Not Provided'}`}
                                          />
                                        </ListItemButton>
                                      </ListItem>
                                    )} */}
                                  {row.address && (
                                    <ListItem disablePadding>
                                      <ListItemButton component="a" href="#simple-list">
                                        <ListItemIcon>
                                          <ContactsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`${row.address}`} />
                                      </ListItemButton>
                                    </ListItem>
                                  )}
                                </List>
                              </Popover>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{row.gender}</TableCell>
                          <TableCell align="left">{DD_MM_YYYY(row.doj)}</TableCell>
                          <TableCell>
                            <Stack sx={{ minWidth: '8rem' }}>
                              <FormControl fullWidth>
                                <InputLabel variant="standard" htmlFor="assign_role">
                                  Choose Role
                                </InputLabel>
                                <Select
                                  ref={selectRoleRef}
                                  value={row?.role?._id ?? ''}
                                  variant="standard"
                                  inputProps={{
                                    name: 'assign_role',
                                    id: 'assign_role'
                                  }}
                                  disabled={selected.length > 0}
                                  sx={{ mt: 2 }}
                                  onChange={(event) => {
                                    Swal.fire({
                                      title: 'Are you sure?',
                                      text: "You won't be able to revert this!",
                                      icon: 'question',
                                      showCancelButton: true,
                                      confirmButtonText: 'Yes, Proceed!',
                                      allowOutsideClick: false
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        assignRoleMutation.mutate({
                                          employees: [row._id],
                                          role: event.target.value
                                        });
                                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                                        // selectRoleRef.current.selectedIndex = 0;
                                        cache.invalidateQueries('unmanaged-employees');
                                      }
                                    });
                                  }}
                                >
                                  <MenuItem value="" disabled>
                                    Choose Role
                                  </MenuItem>
                                  {roleOptions?.length > 0 ? (
                                    roleOptions.map(([key, value]) => (
                                      <MenuItem
                                        style={{ padding: '0.5rem' }}
                                        key={`${'assign_role'}-${key}`}
                                        value={key}
                                      >
                                        {value}
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem value="" disabled />
                                  )}
                                </Select>
                              </FormControl>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {row?.role?.priority <= siteManagerPriority ||
                            !row?.role?.parents?.find((pp) => pp.is_site_manager === true) ? (
                              <Stack sx={{ minWidth: '8rem' }}>
                                <FormControl fullWidth>
                                  <InputLabel variant="standard" htmlFor="assign_manager">
                                    Choose Manager
                                  </InputLabel>
                                  <Select
                                    variant="standard"
                                    value=""
                                    inputProps={{
                                      name: 'assign_manager',
                                      id: `assign_manager_${row._id}`
                                    }}
                                    sx={{ mt: 2 }}
                                    disabled={selected.length > 0}
                                    onChange={(event) => {
                                      Swal.fire({
                                        title: 'Are you sure?',
                                        text: "You won't be able to revert this!",
                                        icon: 'question',
                                        showCancelButton: true,
                                        confirmButtonText: 'Yes, Proceed!',
                                        allowOutsideClick: false
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          assignManagerMutation.mutate({
                                            employees: [row._id],
                                            manager: event.target.value.split(',')[0]
                                          });
                                        } else if (
                                          /* Read more about handling dismissals below */
                                          result.dismiss === Swal.DismissReason.cancel
                                        ) {
                                          const select = document.querySelector(
                                            `#assign_manager_${row._id}`
                                          );
                                          select.selectedIndex = 0;
                                          cache.invalidateQueries('unmanaged-employees');
                                        }
                                      });
                                    }}
                                  >
                                    <MenuItem value="" disabled>
                                      Choose Manager
                                    </MenuItem>
                                    {row.options?.length > 0 ? (
                                      row.options.map(([key, image, empR, empSR]) => (
                                        <MenuItem
                                          style={{ padding: '0.5rem' }}
                                          key={`${'assign_manager'}-${key}`}
                                          value={key}
                                        >
                                          <Avatar
                                            onClick={() => setImageSrc(image)}
                                            src={image}
                                            sx={{ cursor: 'pointer' }}
                                          />
                                          <Stack sx={{ ml: 1 }}>
                                            <Typography>&nbsp;{key.split(',')[1]}</Typography>
                                            <Box display="flex">
                                              <Chip
                                                fontSize={12}
                                                sx={{ height: 18, mb: 0.5 }}
                                                size="small"
                                                label={empR}
                                              />
                                              &nbsp;
                                              {empSR && (
                                                <Chip
                                                  fontSize={12}
                                                  sx={{ height: 18 }}
                                                  size="small"
                                                  label={empSR}
                                                />
                                              )}
                                            </Box>
                                          </Stack>
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem value="" disabled />
                                    )}
                                  </Select>
                                </FormControl>
                              </Stack>
                            ) : (
                              <Stack sx={{ minWidth: '8rem' }}>
                                <FormControl fullWidth>
                                  <InputLabel
                                    sx={{ ml: 2, mt: 1 }}
                                    variant="standard"
                                    htmlFor="assign_site"
                                  >
                                    Choose Site
                                  </InputLabel>
                                  <Select
                                    labelId="assign_site_label"
                                    variant="standard"
                                    value={siteName[row._id] ?? row.sites}
                                    multiple
                                    inputProps={{
                                      name: 'assign_site',
                                      id: `assign_site_${row._id}`
                                    }}
                                    renderValue={(selected) => (
                                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                          <Chip
                                            sx={{
                                              color: `${
                                                row?.sites?.includes(value) ? 'green' : 'red'
                                              }`
                                            }}
                                            key={value}
                                            label={siteDict[value]}
                                          />
                                        ))}
                                      </Box>
                                    )}
                                    input={
                                      <OutlinedInput id="assign_site_label" label="Choose Site" />
                                    }
                                    sx={{ mt: 2 }}
                                    disabled={selected.length > 0}
                                    onChange={(event) => {
                                      const {
                                        target: { value }
                                      } = event;
                                      // setSiteName(
                                      //   // On autofill we get a stringified value.
                                      //   typeof value === 'string' ? value.split(',') : value
                                      // );
                                      siteName[row._id] = value;
                                      setSiteName({ ...siteName });
                                    }}
                                  >
                                    <MenuItem value="" disabled>
                                      Choose Site
                                    </MenuItem>
                                    {siteOptions?.length > 0 ? (
                                      siteOptions
                                        // .filter((site) => {
                                        //   if (row.sites.length > 0) {
                                        //     return site[2] === _siteManager[row.sites[0]];
                                        //   }
                                        //   if (siteName[row._id]?.length > 0) {
                                        //     return site[2] === _siteManager[siteName[row._id][0]];
                                        //   }
                                        //   return true;
                                        // })
                                        .map(([key, value]) => (
                                          <MenuItem
                                            style={{ padding: '0.5rem' }}
                                            key={`${'assign_site'}-${key}`}
                                            value={key}
                                            name={value}
                                          >
                                            {value}
                                          </MenuItem>
                                        ))
                                    ) : (
                                      <MenuItem value="" disabled />
                                    )}
                                    <Box value="" sx={{ mt: 1, px: 1 }}>
                                      <Button
                                        sx={{ width: '100%' }}
                                        onClick={() => {
                                          Swal.fire({
                                            title: 'Are you sure?',
                                            text: `${row.name} will get added to Selected Sites!`,
                                            icon: 'question',
                                            showCancelButton: true,
                                            confirmButtonText: 'Yes, Proceed!',
                                            allowOutsideClick: false
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              assignSiteMutation({
                                                sites: siteName[row._id],
                                                employees: [row._id],
                                                isMultiple: true
                                              });
                                            } else if (
                                              result.dismiss === Swal.DismissReason.cancel
                                            ) {
                                              cache.invalidateQueries('unmanaged-employees');
                                            }
                                          });
                                        }}
                                      >
                                        Assign
                                      </Button>
                                    </Box>
                                  </Select>
                                </FormControl>
                              </Stack>
                            )}
                          </TableCell>
                          {/* <TableCell>{`${row.day_off}`}</TableCell> */}
                          <TableCell align="right">
                            {selected.length === 0 && <EmployeeMoreMenu />}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            {/* Put the scroll bar always on the bottom */}
            <InfiniteScroll
              dataLength={rows?.length}
              next={fetchNextImportemployees}
              // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
              // inverse //
              hasMore={hasNextImportemployees}
              loader={
                <Stack direction="row" sx={{ my: 1.5 }} justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Stack>
              }
              scrollableTarget="import-employees-table-container"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            />
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
      <AddEmployee open={openAddEmployee} onClose={() => setOpenAddEmployee(false)} />
      <ImageZoom src={imageSrc} onClose={() => setImageSrc(null)} />
    </Page>
  );
}
