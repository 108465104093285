import React from 'react';
// material
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { alpha, useTheme } from '@mui/material/styles';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PeopleIcon from '@mui/icons-material/People';
import PaymentIcon from '@mui/icons-material/Payment';
import PayrollOverview from './PayrollOverview';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------
const AppFiguresCard = React.lazy(() => import('../components/_dashboard/app/AppFiguresCard'));
function Payroll() {
  const today = new Date();
  const theme = useTheme();
  const { info } = useSelector((state) => state.adminInfo);
  const [payrollDay, setPayrollDay] = React.useState(today);
  // Gradients
  const gradient1 = `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`;
  // const [showHolidays, setShowHolidays] = useState(false);
  // const [reinforcementRequest, setReinforcementRequest] = React.useState(false);

  return (
    <Page title="Payroll | Trackkar">
      <Container maxWidth="xl" sx={{ pb: 2 }}>
        {/* <Stack direction="row" justifyContent="space-between" sx={{ py: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack>
              <MuiDatePicker
                // views={['year', 'month']}
                // label="Year and Month"
                minDate={new Date('2012-03-01')}
                // maxDate={new Date('2023-06-01')}
                value={payrollDay}
                onChange={(newValue) => {
                  setPayrollDay(newValue);
                }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} helperText={null} />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Stack> */}
        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppFiguresCard
              icon={<PeopleIcon />}
              loading={false}
              value={0}
              background={gradient1}
              label="Employees"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppFiguresCard
              loading={false}
              value={12}
              background={gradient1}
              label="Payroll Completed"
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppFiguresCard
              icon={<PaymentIcon />}
              loading={false}
              value={0}
              background={gradient1}
              label="Gross Pay"
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppFiguresCard
              icon={<PaymentIcon />}
              loading={false}
              value={0}
              background={gradient1}
              label="Net Pay"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppFiguresCard
              icon={<PaymentIcon />}
              loading={false}
              value={0}
              background={gradient1}
              label="PF"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppFiguresCard
              icon={<PaymentIcon />}
              loading={false}
              value={0}
              background={gradient1}
              label="ESI"
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppFiguresCard
              icon={<PaymentIcon />}
              loading={false}
              value={0}
              background={gradient1}
              label="PT"
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppFiguresCard
              icon={<PaymentIcon />}
              loading={false}
              value={1}
              background={gradient1}
              label="Payslip Generated"
            />
          </Grid> */}
          <Grid item xs={12}>
            <PayrollOverview />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default React.memo(Payroll);
