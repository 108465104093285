// import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
// import { Icon } from '@iconify/react';
import {
  useSelector
  // useDispatch
} from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  // Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Tooltip,
  IconButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
// import peopleFill from '@iconify/icons-eva/people-fill';
// components
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const {
    // isAdminAuthenticated,
    info
  } = useSelector((state) => state.adminInfo);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Stack>
      <Box sx={{ px: 2.5, py: 3 }}>
        <Stack direction="row" alignItems="center">
          <Tooltip title="Go Back">
            <IconButton component={RouterLink} to="/dashboard/app">
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          &emsp;
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo size="large" image={info?.avatar} />
          </Box>
        </Stack>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={info?.avatar} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {info?.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {info?.role?.name}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={info.isMainAdmin ? sidebarConfig.admin : sidebarConfig.employee} />

      <Box sx={{ flexGrow: 1 }} />
    </Stack>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
