import React from 'react';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2/src/sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
// icons
import PasswordIcon from '@mui/icons-material/Password';
import { changePassword } from '../api/mutations/profile';
// components
import Page from '../components/Page';
import { SWAL_TIME } from '../utils/config';
import { getDataFromStorage } from '../utils/functions';
import { logOut } from '../redux/actions/adminActions';

function ChangePassword({ employee }) {
  const userId = getDataFromStorage('selected_employee');
  const {
    // isAdminAuthenticated,
    info
  } = useSelector((state) => state.adminInfo);
  const dispatch = useDispatch();
  const { mutate: changePasswordMutation } = useMutation(changePassword, {
    onMutate: () => {
      Swal.fire({
        icon: 'info',
        title: 'Hold on....',
        text: 'Updating your password :)',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });
    }
  });

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: SWAL_TIME,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    didRender: (data) => {
      // changing text alignment for alert and adding some padding
      const content = data.querySelector('#swal2-content');
      if (content) {
        content.style.paddingLeft = '1rem';
        content.style.paddingBottom = '.7rem';
        content.style.textAlign = 'left';
      }
    }
  });

  const changePwdFormik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmChangePassword: ''
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Old Password is required'),
      newPassword: Yup.string()
        .required('New password is required')
        .min(6, 'Minimum 6 characters')
        .max(15, 'Maximum 15 characters')
        .matches(
          /^[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{6,15}$/,
          'Password can only contain alphabets, numbers or special characters'
        ),
      confirmChangePassword: Yup.string()
        .required('This field is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    }),
    onSubmit: (values) => {
      const data = {
        id: employee ? userId._id : info?._id,
        admin: employee ? false : info?.isMainAdmin,
        other: !!employee,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      };

      // console.log('Change Profile Password:', data);

      changePasswordMutation(data, {
        onError: (error) => {
          const msg = error.response
            ? error.response.data.message || error.toString()
            : error.toString();
          // server sendign 401 on successfull password change to make the current session unauthorized
          if (error?.response?.status === 401) {
            Toast.fire({
              icon: 'success',
              iconColor: '#fff',
              background: '#16c79a',
              title: `<span style='color: #ffffff; font-weight: bold; padding-left: 10px'>${msg}</span>`,
              timer: SWAL_TIME,
              timerProgressBar: true,
              willClose: () => {
                dispatch(logOut());
              }
            });
          } else {
            Toast.fire({
              background: '#ec4646',
              html: `<h6 style='color: #ffffff; font-weight: bold;'>${msg}</h6>`
            });
          }
        },
        onSuccess: (data) => {
          Toast.fire({
            icon: 'success',
            iconColor: '#fff',
            background: '#16c79a',
            title: `<span style='color: #ffffff; font-weight: bold; padding-left: 10px'>${data.message}</span>`,
            timer: SWAL_TIME,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
            willClose: () => {
              Toast.fire({
                icon: 'info',
                iconColor: '#fff',
                background: '#01DFD7',
                title: `<span style='color: #ffffff; font-weight: bold; padding-left: 10px'>Logging Off...</span>`,
                timer: SWAL_TIME,
                timerProgressBar: true,
                willClose: () => {
                  dispatch(logOut());
                }
              });
            }
          });
        }
      });
    }
  });

  const {
    getFieldProps,
    // values,
    touched,
    errors,
    handleSubmit
  } = changePwdFormik;

  return (
    <Page title="Profile | Trackkar">
      <Container>
        <Card sx={{ p: 3, pt: 1, position: 'relative' }}>
          <Box sx={{ position: 'absolute', display: 'flex', p: 2, right: 0, top: 0 }}>
            {/* <Chip
                label={`Shifts : ${data?.site?.shifts?.lenght ?? 0}`}
                sx={{ mr: 2 }}
                component="a"
                href="#basic-chip"
              />
              <Chip
                label={`Feedback : ${data?.site?.feedback ?? 0}`}
                component="a"
                href="#basic-chip"
              /> */}
          </Box>
          <Stack spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                height: '120px',
                position: 'relative',
                px: 1,
                pb: 2,
                borderBottom: '2px solid grey'
              }}
            >
              <Avatar
                sx={{ width: 90, height: 90, mr: 3 }}
                alt={employee ? userId?.name : info?.name}
                src={employee ? userId.avatar : info?.avatar}
              />
              <Typography sx={{ fontSize: '1rem' }}>
                {employee ? userId?.name : info?.name}
              </Typography>
              <Typography
                marginBottom="0.4rem"
                sx={{ position: 'absolute', fontSize: '0.9rem', right: 0, bottom: 0 }}
              >
                Email :{' '}
                {employee ? userId?.email ?? 'No Email Provided!!' : info?.email ?? 'Unknown'}
              </Typography>
            </Stack>
          </Stack>
          <br />
          <Stack spacing={2}>
            {/* Old Password */}
            {!employee && (
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PasswordIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  variant="standard"
                  fullWidth
                  label="Old Password"
                  type="password"
                  autoComplete="off"
                  {...getFieldProps('oldPassword')}
                  error={Boolean(touched.OldPassword && errors.OldPassword)}
                  helperText={touched.OldPasswod && errors.OldPasswod}
                />
              </Box>
            )}
            {/* New Password */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <PasswordIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField
                variant="standard"
                fullWidth
                label="New Password"
                type="password"
                autoComplete="off"
                {...getFieldProps('newPassword')}
                error={Boolean(touched.NewPassword && errors.NewPassword)}
                helperText={touched.NewPassword && errors.NewPassword}
              />
            </Box>
            {/* Confirm Password */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <PasswordIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField
                variant="standard"
                fullWidth
                label="Confirm Password"
                type="password"
                autoComplete="off"
                {...getFieldProps('confirmChangePassword')}
                error={Boolean(touched.confirmChangePassword && errors.confirmChangePassword)}
                helperText={touched.confirmChangePassword && errors.confirmChangePassword}
              />
            </Box>
          </Stack>
          <br />
          <Button variant="contained" onClick={() => handleSubmit()}>
            Update
          </Button>
        </Card>
      </Container>
    </Page>
  );
}

export default ChangePassword;
