/* eslint-disable no-nested-ternary */
import '../index.css';
import * as React from 'react';
// import * as Yup from 'yup';
import clsx from 'clsx';
import { useQueryClient } from 'react-query';
// import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import Swal from 'sweetalert2/src/sweetalert2';
import InfiniteScroll from 'react-infinite-scroll-component';
import '@sweetalert2/theme-material-ui/material-ui.css';
import PropTypes from 'prop-types';
import {
  Modal,
  Typography,
  Box,
  Stack,
  IconButton,
  Checkbox,
  Button,
  Divider,
  Alert,
  TextField,
  OutlinedInput,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TreeView, TreeItem } from '@mui/lab';
import { useTreeItem } from '@mui/lab/TreeItem';
// import { useTheme } from '@mui/material/styles';
// icons
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { addHoliday } from '../api/mutations/adminMutations';
import useMutationFunc from '../api/mutations/mutation';
// config
import {
  ADD_EMPLOYEE
  // COMPANY_PLACEHOLDER_LOGO
} from '../utils/config';
// hooks
import { useSiteShifts } from '../hooks/roster';
// utils
import { getCurrentDate, convertUTCtoLocal } from '../utils/functions';
import NotAvailable from './NotAvailable';
// redux
// import { logOut } from '../redux/actions/adminActions';
// import INDIAN from '../../../utils/INDIAN_STATES.json'; // importing indian states and uts

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  top: 0,
  right: 0,
  height: '100vh',
  // transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000'
  // borderRadius: 1,
  boxShadow: 24
  // p: 4
};

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250
//     }
//   }
// };

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  margin: '0.5rem',
  height: 40,
  // transition: theme.transitions.create(['box-shadow', 'width'], {
  //   easing: theme.transitions.easing.easeInOut,
  //   duration: theme.transitions.duration.shorter
  // }),
  // '&.Mui-focused': { width: 300, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const CustomContent = React.forwardRef((props, ref) => {
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

CustomContent.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * className applied to the root element.
   */
  className: PropTypes.string,
  /**
   * The icon to display next to the tree node's label. Either a parent or end icon.
   */
  displayIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label. Either an expansion or collapse icon.
   */
  expansionIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label.
   */
  icon: PropTypes.node,
  /**
   * The tree node label.
   */
  label: PropTypes.node,
  /**
   * The id of the node.
   */
  nodeId: PropTypes.string.isRequired
};

const CustomTreeItem = (props) => <TreeItem ContentComponent={CustomContent} {...props} />;

function AddHoliday({ open, onClose, site = null }) {
  // const dispatch = useDispatch();
  const cache = useQueryClient();
  const [filterName, setFilterName] = React.useState();
  const [search] = useDebounce(filterName, 1500);
  const [holidays, setHolidays] = React.useState({
    from: null,
    to: null
  });
  const [allHoliday, setAllHoliday] = React.useState(false);
  const [reason, setReason] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [error, setError] = React.useState('');

  // React.useEffect(() => {
  //   console.log(selected);
  // }, [selected]);

  const {
    isLoading: isSiteShiftsLoading,
    isError: isSiteShiftsError,
    fetchNextPage: fetchNextSiteShifts,
    hasNextPage: hasNextSiteShifts,
    data: siteShifts
  } = useSiteShifts(null, null, search, getCurrentDate(), site);

  const SITE_SHIFTS = React.useMemo(() => {
    const listOfSites = [];
    if (!isSiteShiftsLoading && !isSiteShiftsError) {
      // console.log('Clear Roster List of Sites:', siteShifts);
      if (siteShifts?.pages?.length > 0) {
        siteShifts.pages.forEach((group) => listOfSites.push(group.sites));
      }
    }
    return listOfSites.flat();
  }, [siteShifts, isSiteShiftsLoading, isSiteShiftsError]);

  const handleClick = (event, row, shiftId = null) => {
    const data = selected;
    const sd = data.find((dt) => dt._id === row._id) ?? {};
    if (event.target.checked) {
      if (shiftId) {
        if (Object.keys(sd).length > 0) {
          sd.shifts.push(shiftId);
          setSelected([...data]);
          return;
        }
        data.push({
          _id: row._id,
          shifts: [shiftId]
        });
        setSelected([...data]);
        return;
      }
      data.push({
        _id: row._id,
        shifts: row.shifts.map((sht) => sht._id)
      });
      setSelected([...data]);
      return;
    }
    if (shiftId) {
      const shiftIndex = sd.shifts.findIndex((fi) => fi === shiftId);
      sd.shifts.splice(shiftIndex, 1);
      setSelected([...data]);
      return;
    }
    const siteIndex = data.findIndex((dt) => dt._id === row._id);
    data.splice(siteIndex, 1);
    setSelected([...data]);
  };

  const handleAllClick = (event) => {
    if (event.target.checked) {
      setSelected([]);
      setAllHoliday(true);
    }
    setAllHoliday(false);
  };

  const addHolidayMutation = useMutationFunc(
    addHoliday,
    {
      onSuccess: {
        title: 'Successfully Added!!'
      }
    },
    () => onClose(),
    () => {
      cache.invalidateQueries('sites');
      cache.invalidateQueries('roster');
      cache.invalidateQueries('shifts');
      cache.invalidateQueries('roster-availability');
    },
    null,
    null
  );

  // const [expanded, setExpanded] = React.useState(
  //   Array.from({ length: SITE_SHIFTS?.length }, (v, i) => `${i}`)
  // );

  // For Testing Purpose
  // React.useEffect(() => {
  //   console.log(expanded);
  // }, [expanded]);

  // const handleExpanded = (id) => {
  //   const selectedIndex = expanded.indexOf(id);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(expanded, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(expanded.slice(1));
  //   } else if (selectedIndex === expanded.length - 1) {
  //     newSelected = newSelected.concat(expanded.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       expanded.slice(0, selectedIndex),
  //       expanded.slice(selectedIndex + 1)
  //     );
  //   }
  //   setExpanded(newSelected);
  // };

  return (
    <Modal
      open={open ?? false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            p: 1,
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)'
          }}
        >
          <img src={ADD_EMPLOYEE} width="40" height="40" alt="edit_icon" />
          <Typography
            id="modal-modal-title"
            color="white"
            variant="h6"
            sx={{ ml: 2 }}
            component="h2"
          >
            Add Holidays
          </Typography>
          <IconButton
            onClick={() => onClose()}
            sx={{ right: 0, mr: 3, color: 'white', position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>{' '}
        <Stack
          className="custom_scrollbar"
          sx={{ position: 'relative', height: 'calc(100vh - 6rem)', paddingBottom: 2 }}
        >
          <Alert sx={{ mb: 1 }} severity="info">
            Holidays can't be added for site with no shifts
          </Alert>
          {error && (
            <Alert sx={{ mb: 1 }} severity="error">
              {error}
            </Alert>
          )}
          <Stack sx={{ mx: 2 }} spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="From"
                minDate={new Date()}
                value={holidays.from}
                onChange={(newValue) => {
                  setHolidays({ ...holidays, from: newValue });
                }}
                renderInput={(params) => <TextField variant="standard" {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="To"
                disabled={!holidays?.from}
                minDate={holidays?.from}
                value={holidays.to}
                onChange={(newValue) => {
                  setHolidays({ ...holidays, to: newValue });
                }}
                renderInput={(params) => <TextField variant="standard" {...params} />}
              />
            </LocalizationProvider>
          </Stack>
          <TextField
            id="outlined-multiline-flexible"
            label="Reason"
            variant="standard"
            multiline
            required
            sx={{ mx: 2, mb: 2 }}
            maxRows={6}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography sx={{ px: 2, fontWeight: 'bold' }}>All Sites</Typography>
            {!site ?? <Checkbox onChange={(e) => handleAllClick(e)} />}
          </Stack>
          <Divider />
          <SearchStyle
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            placeholder="Search Sites..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          <Box
            className="custom_scrollbar"
            sx={{ height: 'calc(100vh - 5rem)', overflowY: 'auto' }}
          >
            <TreeView
              aria-label="clear roster navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              // expanded={expanded}
              sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
            >
              {isSiteShiftsLoading ? (
                <NotAvailable type="loading" msg="Loading..." />
              ) : isSiteShiftsError ? (
                <NotAvailable type="na" msg="Something Went Wrong!" />
              ) : !isSiteShiftsLoading && SITE_SHIFTS?.length > 0 ? (
                SITE_SHIFTS.map((row, indx) => {
                  const sel = selected.find((si) => si?._id === row?._id) ?? {};
                  return (
                    <CustomTreeItem
                      nodeId={`${indx}`}
                      key={`add-holiday-${row._id}`}
                      // onClick={() => handleExpanded(`${indx}`)}
                      label={
                        <Typography>
                          <Checkbox
                            disabled={allHoliday || row.shifts.length === 0}
                            checked={sel?._id === row?._id}
                            onChange={(e) => handleClick(e, row)}
                          />
                          {row.name}
                        </Typography>
                      }
                    >
                      {row.shifts.map((shift, inx) => (
                        <CustomTreeItem
                          key={`add-holiday-${shift._id}`}
                          nodeId={`${indx}${inx}`}
                          label={
                            <Typography>
                              <Checkbox
                                checked={
                                  Object.keys(sel).length > 0
                                    ? sel?.shifts?.indexOf(shift?._id) !== -1
                                    : false
                                }
                                onChange={(e) => handleClick(e, row, shift._id)}
                              />
                              {shift.name}
                            </Typography>
                          }
                        />
                      ))}
                    </CustomTreeItem>
                  );
                })
              ) : (
                <NotAvailable type="na" msg="No Data Available" />
              )}
            </TreeView>
          </Box>
          <Box sx={{ m: 1, position: 'fixed', bottom: 0 }}>
            <Button
              onClick={() => {
                onClose();
                if (holidays[0] === null) {
                  setError('Please provide valid dates for holidays');
                } else if (selected.length === 0 && !allHoliday) {
                  setError('Please select aleast one site to add holiday for');
                } else if (reason.length === 0) {
                  setError('Please provide a valid reason for holidays');
                } else {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: `Add Holiday for selected dates!`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Proceed!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      addHolidayMutation({
                        sites: selected,
                        dates: [
                          convertUTCtoLocal(holidays?.from, true),
                          convertUTCtoLocal(holidays?.to, true)
                        ],
                        reason
                      });
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      cache.invalidateQueries();
                    }
                  });
                }
              }}
              sx={{ width: '100%' }}
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </Stack>
        {/* Put the scroll bar always on the bottom */}
        <InfiniteScroll
          dataLength={SITE_SHIFTS?.length}
          next={fetchNextSiteShifts}
          // style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
          // inverse //
          hasMore={hasNextSiteShifts}
          loader={
            <Stack direction="row" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Stack>
          }
          scrollableTarget="site-shifts-container"
          // endMessage={
          //   <p style={{ textAlign: 'center' }}>
          //     <b>Yay! You have seen it all</b>
          //   </p>
          // }
        />
      </Box>
    </Modal>
  );
}

AddHoliday.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  site: PropTypes.string
};

export default React.memo(AddHoliday);
